import { createEvent, createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { accountNavigationRequests } from 'store/account/api/account-navigation';
// import { accountNavigationRequests } from 'shared/api';

const Gate = createGate();
const getCounts = createEvent();

const getRequestsCountFx = createEffect(accountNavigationRequests.getRequestsCount);
const getNotificationsCountFx = createEffect(accountNavigationRequests.getNotificationsCount);

const $requestsCount = createStore<number>(0);
const $notificationsCount = createStore<number>(0);

sample({
	clock: Gate.open,
	target: getCounts,
});

sample({
	clock: getCounts,
	target: [getRequestsCountFx, getNotificationsCountFx],
});

sample({
	clock: getRequestsCountFx.doneData,
	fn: ({ count }) => count,
	target: $requestsCount,
});

sample({
	clock: getNotificationsCountFx.doneData,
	fn: ({ count }) => count,
	target: $notificationsCount,
});

export const model = {
	Gate,
	getCounts,
	$requestsCount,
	$notificationsCount,
};
