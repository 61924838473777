import { CruiseDetailsModel } from 'config/types/cabins/models/cruise-details';
import { createContext, FC, ReactNode, useContext } from 'react';
// import { CruiseDetailsModel } from 'shared/lib/types';

type CruiseInfo = Nullable<{ date?: CruiseDetailsModel.CruiseDateRange }> | undefined;

const CruiseContext = createContext<CruiseInfo>(null);

type CruiseProviderProps = {
	children: ReactNode;
	cruiseInfo: CruiseInfo;
};

export const CruiseContextProvider: FC<CruiseProviderProps> = ({ children, cruiseInfo }) => (
	<CruiseContext.Provider value={cruiseInfo}>{children}</CruiseContext.Provider>
);

/**
 * Контекст информации по круизу. Добавлен для избежания prop drilling
 * Например компоненту необходима информация о дате текущего круиза
 * @see PassengerForm
 *
 * Она используется слишком глубоко в форме бронирования, чтобы прокидывать пропсы,
 * но без даты начала круиза не будет корректно работать
 *
 * Так же используется в форме редактрования заявки (уже с другой моделью круиза)
 */
export const useCruiseInfoContext = () => useContext(CruiseContext);
