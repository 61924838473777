// import { fetcher } from 'shared/api/config';
// import { ACCOUNT_URL } from 'shared/config';
// import { CountryModel } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { CountryModel } from "config/types/cabins/models/country-model";
import { ACCOUNT_URL } from "store/auth/constants";

export const getCountries = () =>
	fetcher<CountryModel[]>({
		url: `${ACCOUNT_URL}/countries`,
		method: 'GET',
	});

export const countriesRequests = {
	getCountries,
};
