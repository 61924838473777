// import { fetcher } from 'shared/api/config';
// import { ACCOUNT_URL } from 'shared/config';
// import { DocumentTypeModel } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { DocumentTypeModel } from "config/types/document-types";
import { ACCOUNT_URL } from "store/auth/constants";

export const getDocumentTypes = () =>
	fetcher<DocumentTypeModel[]>({
		url: `${ACCOUNT_URL}/document-types`,
		method: 'GET',
	});

export const documentTypesRequests = {
	getDocumentTypes,
};
