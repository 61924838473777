import { status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { BOOKABLE_SEA_SHIPS } from 'entities/cruises/lib';
// import { cruisesRequests, ecommerceRequests, GetCruiseInfoRequest } from 'shared/api';
// import { CURRENCY } from 'shared/config/currency';
// import { CruiseCabin, CruiseDetailsModel, EcommerceCruise } from 'shared/lib/types';
// import { getFilledEcommerceObject } from 'shared/lib/utils';
// import { fxErrorCode } from 'shared/model';
import { shipModel } from '../../ships';
import { CruiseCabin } from 'config/types/cabins/models/cruise-cabin-booking';
import { CruiseDetailsModel } from 'config/types/cabins/models/cruise-details';
import { EcommerceCruise } from 'config/types/ecommerce';
import { fxErrorCode } from 'entities/fx-error-code';
import { GetCruiseInfoRequest, cruisesRequests } from 'store/cruise/cruises';
import { ecommerceRequests } from 'store/cruise/ecommerce';
import { getFilledEcommerceObject } from 'utils/analytics/ecommerce';

const getCruise = createEvent<GetCruiseInfoRequest>();
const getCruiseFx = createEffect(cruisesRequests.getInfo);

const getCabins = createEvent<GetCruiseInfoRequest>();
const getCabinsFx = createEffect(cruisesRequests.getCabins);

const $cruise = createStore<Nullable<CruiseDetailsModel.CruiseInfo>>(null);
const $cabins = createStore<Nullable<CruiseCabin.CruiseCabin>>(null);

const $bookingDisable = $cabins.map((state) => state?.bookingDisable || false);

const $isSeaCruise = $cruise.map((state) => state?.type === 'sea');
const $enabledBooking = $cruise.map(
	(state) => state?.type !== 'sea' || BOOKABLE_SEA_SHIPS.includes(Number(state.shipId)),
);

const $status = status({ effect: getCruiseFx });
const $cabinsStatus = status({ effect: getCabinsFx });

const $errorCode = fxErrorCode({ effect: getCruiseFx });
const $cabinsErrorCode = fxErrorCode({ effect: getCabinsFx });

sample({
	clock: getCruise,
	target: getCruiseFx,
});

sample({
	clock: getCruiseFx.doneData,
	target: $cruise,
});

sample({
	clock: getCabins,
	target: getCabinsFx,
});

sample({
	clock: getCabinsFx.doneData,
	target: $cabins,
});

sample({
	clock: $cruise,
	filter: (cruise) => !!cruise?.shipId,
	fn: (cruise) => ({ id: cruise?.shipId as string }),
	target: shipModel.getShip,
});

/* *
 * Destination point from timetable
 */
const $destination = $cruise.map(
	(cruise) => [...(cruise?.timetable?.timetables ?? [])].pop() ?? null,
);

const ecommerce = createEvent();

const ecommerceFx = createEffect(ecommerceRequests.getCruiseInfo);

sample({
	clock: ecommerce,
	source: $cruise,
	fn: (source) => ({ cruiseId: source!.id.toString() }),
	target: ecommerceFx,
});

const pushEcommerce = createEffect(
	(data: EcommerceCruise & { cruiseId?: number; price?: number | null }) => {
		// @ts-ignore
		window.dataLayer.push(
			getFilledEcommerceObject({
				ecommerce: {
					// @ts-ignore
					currencyCode: CURRENCY[data.currency ?? 1].toUpperCase(),
					detail: {
						products: [
							{
								id: data.cruiseId?.toString(),
								name: data.name,
								price: data.price,
								brand: data.brand,
								category: data.category,
								variant: data.variant,
							},
						],
					},
				},
			}),
		);
	},
);

sample({
	clock: ecommerceFx.doneData,
	source: $cruise,
	fn: (source, clock) => ({ ...clock, cruiseId: source?.id, price: source?.price?.min }),
	target: pushEcommerce,
});

export const model = {
	$cruise,
	$cabins,
	$cabinsStatus,
	$bookingDisable,
	$status,
	$destination,
	$errorCode,
	$cabinsErrorCode,
	getCruise,
	getCruiseFx,
	getCabins,
	getCabinsFx,
	ecommerce,
	$isSeaCruise,
	$enabledBooking,
};
