import React, { HTMLAttributes, forwardRef } from 'react';
import classnames from 'classnames';
// import Link from 'next/link';
// import { Typo } from 'shared/ui/atoms';
// import { Icon, IconId } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { IconId } from 'layout/components/icons/svg-sprite';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { Link } from '@tanstack/react-router';
import LinkWrapper from './link-wrapper';

interface TopLinkProps extends HTMLAttributes<HTMLButtonElement> {
	icon?: IconId;
	href?: string;
	target?: string;
	noWrap?: boolean;
	asA?: boolean
}

export const TopLink = forwardRef<HTMLElement, TopLinkProps>(
	({ icon, href, target, className, noWrap, onClick, asA, children, ...buttonProps }, forwardedRef) => {
		const contents = (
			<>
				{icon && <Icon id={icon} />}
				<span>{children}</span>
			</>
		);

		const basicProps = {
			design: 'text-s' as const,
			className: classnames(className, styles.button, noWrap && styles.noWrap),
		};

		if (href) {
			return (
				<LinkWrapper href={href} target={target} asA={asA}>
					<Typo ref={forwardedRef} as="a" onClick={onClick} {...basicProps}>
						{contents}
					</Typo>
				</LinkWrapper>
			);
		}

		return (
			<Typo ref={forwardedRef} as="button" onClick={onClick} {...basicProps} {...buttonProps}>
				{contents}
			</Typo>
		);
	},
);
