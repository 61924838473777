import { FC, memo, PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps extends PropsWithChildren {}

export const Portal: FC<PortalProps> = memo(({ children }) => {
	const [mount, setMount] = useState(false);

	useEffect(() => {
		setMount(true);
	}, []);

	const portalRoot = typeof document !== 'undefined' && document?.getElementById('portal-root');

	if (!portalRoot || !mount) {
		return null;
	}

	return createPortal(children, portalRoot);
});
