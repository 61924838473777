// import { fetcher } from 'shared/api/config';
// import { CRUISES_ENDPOINT } from 'shared/config';
// import { CruiseCabin } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { CRUISES_ENDPOINT } from "config/constants/cruises";
import { CruiseCabin } from "config/types/cabins/models/cruise-cabin-booking";

export interface GetCruiseCabinsRequest {
	id?: string;
}

export const getCabins = ({ id }: GetCruiseCabinsRequest) =>
	fetcher<CruiseCabin.CruiseCabin>({
		url: `${CRUISES_ENDPOINT}/${id}/cabins`,
		method: 'GET',
	});
