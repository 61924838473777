import React, { FC } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useGate, useUnit } from 'effector-react';
import { orderCabinsModel } from 'entities/order';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';

export const ReplacePassengersModal: FC = () => {
	const [closeModal] = useUnit([orderCabinsModel.closeModal]);

	const state = useOverlayTriggerState({});

	const shouldMount = useDelayUnmount(state.isOpen, 600);

	useGate(orderCabinsModel.ModalGate, state);

	if (!shouldMount) {
		return null;
	}

	const footer = (
		<div className={styles.footer}>
			<Button size="large" className={styles.closeButton} onClick={closeModal}>
				Закрыть
			</Button>
		</div>
	);

	return (
		<Modal
			state={state}
			isShown={state.isOpen}
			onClose={closeModal}
			verticalAlign="middle"
			classNameModal={styles.modal}
			classNameModalContent={styles.modal__content}
			classNameFooter={styles.modal__footer}
			footer={footer}
			isDismissable
		>
			<Typo design="headline-xl">Порядок пассажиров в каюте изменен</Typo>
		</Modal>
	);
};
