import { status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { viewportModel } from 'layout/viewport';
import { accountNavigationModel } from 'layout/layouts/header/account-navigation';
import { LoadRequests200Response } from 'store/auth/types/account/load-requests-200-response';
import { RequestCard } from 'store/auth/types/account/request-card';
import { AccountRequestsStatuses } from 'store/auth/constants';
import { newsRequest } from 'store/news';
import { PostCard } from 'config/types/posts';
import { bonusCardModel } from 'entities/bonus-card';
import { accountRequests } from 'store/account/api/requests';
import { headerModel } from 'layout/components/view/header';
import { footerModel } from 'layout/components/view/footer';
// import { accountNavigationModel } from 'features/account-navigation';
// import { headerModel } from 'features/header';
// import { bonusCardModel } from 'entities/bonus-card';
// import { footerModel } from 'entities/footer';
// import { accountRequests, newsRequest } from 'shared/api';
// import { AccountRequestsStatuses } from 'shared/config';
// import { PostCard } from 'shared/lib/types';
// import { RequestCard, LoadRequests200Response } from 'shared/lib/types/account/models';
// import { viewportModel } from 'shared/lib/viewport';

const Gate = createGate();

const getData = createEvent();
const getStaticData = createEvent();

const getSpecialOffers = createEvent();
const getSpecialOffersFx = createEffect(newsRequest.getSpecialOffersKLK);

const $specialOffers = createStore<PostCard[]>([]);
const $specialOffersWithImages = $specialOffers.map(
	(items) => items.filter(({ image }) => Boolean(image)),
	// eslint-disable-next-line
);

/* *
 * Гейты
 */
sample({
	clock: Gate.open,
	target: [getData, bonusCardModel.getBonusCard],
});

/* *
 * Получение счетчиков для ЛК-меню в мобильной версии
 */
sample({
	clock: Gate.open,
	source: viewportModel.$isMob,
	filter: (isMob) => Boolean(isMob),
	target: accountNavigationModel.getCounts,
});

/* *
 * Получение заявок
 */
const getRequestsFx = createEffect(accountRequests.getRequests);

const $requests = createStore<Nullable<LoadRequests200Response>>(null);
const $awaitingRequests = createStore<RequestCard[]>([]);
const $awaitingRequestsStatus = status({ effect: getRequestsFx });
const $awaitingRequestsCount = createStore(0);

sample({
	clock: getData,
	fn: () => ({ perPage: 100 }),
	target: getRequestsFx,
});

sample({
	clock: getRequestsFx.doneData,
	target: $requests,
});

sample({
	clock: $requests,
	fn: (response) =>
		response?.data?.filter(
			(request) =>
				request.status === AccountRequestsStatuses.Prepayment ||
				request.status === AccountRequestsStatuses.Confirmed ||
				request.status === AccountRequestsStatuses.SoldByAgency ||
				request.status === AccountRequestsStatuses.AwaitingPayment,
		) ?? [],
	target: $awaitingRequests,
});

sample({
	clock: $requests,
	fn: (response) => response?.data?.length ?? 0,
	target: $awaitingRequestsCount,
});

/* *
 * Получение спецпредложений КЛК
 */
sample({
	clock: getSpecialOffers,
	target: getSpecialOffersFx,
});

sample({
	clock: getSpecialOffersFx.doneData,
	fn: ({ data }) => data ?? [],
	target: $specialOffers,
});

/* *
 * Получение уведомлений
 */
sample({
	clock: getStaticData,
	fn: () => 1,
	target: [accountNavigationModel.getCounts, getSpecialOffers],
});

/* *
 * Хэдэр / футер
 */
sample({
	clock: getStaticData,
	target: [headerModel.getData, footerModel.getData],
});

export const model = {
	Gate,
	getData,
	getStaticData,
	getSpecialOffers,
	$awaitingRequests,
	$awaitingRequestsStatus,
	$awaitingRequestsCount,
	$specialOffers,
	$specialOffersWithImages,
};
