/* eslint-disable complexity */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useMemo } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useGate, useUnit } from 'effector-react';
import { Error } from 'entities/cabin-booking-cards/ui/cabin-description-modal/ui/cabin-description-modal/error';
import { cartModel } from 'entities/cart';
// import { userRoleModel } from 'entities/order';
// import { model as pricesModel } from 'entities/order/model/prices';
// import { UserRoleModal } from 'entities/order/user-role';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { setAnalytics } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Button, Condition, Skeleton, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { detailedCruiseCabinModel } from '../../model';
import { Content } from './content';
import { Footer } from './footer';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Skeleton } from 'layout/components/special/skeleton';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { useViewport } from 'layout/viewport';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';
import { userRoleModel, pricesModel } from 'entities/order';
import { UserRoleModal } from 'entities/order/user-role';
import { cartWarningModel } from 'entities/cart-warning';

interface CabinDescriptionModalProps {
	cruiseId: number;
	modalToHide?: Element | null
}

export const CabinDescriptionModal = ({ cruiseId, modalToHide }: CabinDescriptionModalProps) => {
	const modalState = useOverlayTriggerState({});
	const successModalState = useOverlayTriggerState({});

	// useGate(detailedCruiseCabinModel.ModalGate, modalState);
	useGate(detailedCruiseCabinModel.SuccessModalGate, successModalState);

	const { isMob } = useViewport();

	const [
		cabinInfo,
		effectStatus,
		cart,
		screen,
		setScreen,
		goToOrdering,
		pricesPreview,
		pricesPreviewStatus,
		initialLoadInfo,
		reloadInfo,
	] = useUnit([
		detailedCruiseCabinModel.$detailedCruiseCabinInfo,
		detailedCruiseCabinModel.$status,
		cartModel.$cart,
		detailedCruiseCabinModel.$screen,
		detailedCruiseCabinModel.setScreen,
		userRoleModel.goToOrdering,
		pricesModel.$pricesPreview,
		pricesModel.$pricesPreviewStatus,
		detailedCruiseCabinModel.initialLoadInfo,
		detailedCruiseCabinModel.reloadInfo,
	]);

	const isAdded = useMemo(() => cart.some(({ id }) => id === cabinInfo?.id), [cart, cabinInfo]);

	const showFirstScreenFooter = isMob && screen === 0;
	const showSecondScreenFooter = !isMob || (isMob && screen === 1);

	const shouldMount = useDelayUnmount(modalState.isOpen, 600);
	const successShouldMount = useDelayUnmount(successModalState.isOpen, 600);

	const state = useOverlayTriggerState({});
	useGate(cartWarningModel.GateWarningModal, state);


	const pending = effectStatus === 'pending';
	const initialPending = effectStatus === 'pending' && !cabinInfo;
	const pricesPreviewPending = pricesPreviewStatus === 'pending';

	let cabinName = ' ';
	if (cabinInfo) {
		cabinName = `№${cabinInfo.name} `;
		//// eslint-disable-next-line unicorn/prefer-at
	} else if (cart.length > 0 && cart[cart.length - 1]) {
		//// eslint-disable-next-line unicorn/prefer-at
		cabinName = `№${cart[cart.length - 1]?.name} `;
	}

	const childrenAge = pricesPreview?.cabins?.find(
		(cabin) => cabin.id === cabinInfo?.id,
	)?.childrenAge;

	//#region Костыль по скрытию модального окна
	const hideElement = (element: Element, hide: boolean) => {
		if ("style" in (element as any))
			(element as any).style.display = hide ? "none" : "block"
	}

	const hideCabinModal = () => {
		modalToHide && hideElement(modalToHide, true)
	}

	const showCabinModal = () => {
		modalToHide && hideElement(modalToHide, false)
	}
	//#endregion

	const handleGoToOrdering = () => {
		// костыль по скрытию модального окна круиза
		hideCabinModal()
		goToOrdering();
		// setAnalytics(ANALYTICS_TARGETS.cruisepage.cruisepage_to_reg);
	};

	useEffect(() => {
		return () => {
			showCabinModal()
		}
	}, [])


	return (
		// <div>CabinDescriptionModal</div>
		<>
			{shouldMount ? (
				<Modal
					isDismissable
					state={modalState}
					isShown={modalState.isOpen}
					className={styles.cabinDescriptionModalWrap}
					classNameModal={styles.cabinDescriptionModal}
					closeButtonClassName={styles.modalCloseButton}
					classNameModalContent={styles.modalContent}
					classNameFooter={styles.modalFooter}
					verticalAlign="bottom"
					footer={
						effectStatus === 'fail' ? undefined : (
							<Footer
								cruiseId={cruiseId}
								modalState={modalState}
								pending={pending}
								initialPending={initialPending}
								showFirstScreenFooter={Boolean(showFirstScreenFooter)}
								showSecondScreenFooter={Boolean(showSecondScreenFooter)}
								cabinInfo={cabinInfo}
								isAdded={isAdded}
								setScreen={setScreen}
							/>
						)
					}
				>
					<>
						<Condition
							value={initialPending}
							then={<Skeleton margin="0 0 16px" width="50%" height={24} />}
							else={
								<Typo className={styles.title} design="headline-m">
									Описание каюты
								</Typo>
							}
						/>
						<Condition
							value={effectStatus !== 'fail'}
							then={
								<Content
									cruiseId={cruiseId}
									cabinInfo={cabinInfo}
									pending={pending}
									initialPending={initialPending}
									initialLoadInfo={initialLoadInfo}
									childrenAge={childrenAge}
									pricesPreviewPending={pricesPreviewPending}
									pricesPreviewStatus={pricesPreviewStatus}
									screen={screen}
									setScreen={setScreen}
								/>
							}
							else={<Error reload={reloadInfo} />}
						/>
					</>
				</Modal>
			) : null}
			{successShouldMount && (
				<Modal
					// isDismissable
					className={styles.modal1050}
					classNameModal={styles.successModal}
					closeButtonClassName={styles.successModal__close}
					isShown={successModalState.isOpen}
					state={successModalState}
				>
					<Typo
						className={styles.successModal__title}
						design={isMob ? 'headline-xl' : 'headline-m'}
					>
						Каюта {cabinName}добавлена в заказ
					</Typo>
					<div className={styles.successModal__buttons}>
						<Button
							btnType="outline"
							size="large"
							onClick={() => {
								showCabinModal()
								successModalState.close();
								modalState.close();
								// setAnalytics(ANALYTICS_TARGETS.cruisepage.cruisepage_cabin_add);
							}}
						>
							Выбрать еще каюту
						</Button>
						{/* <Button size="large" btnType="success" onClick={handleGoToOrdering}> */}
						<Button size="large" onClick={handleGoToOrdering}>
							Перейти к бронированию
						</Button>
						<UserRoleModal />
					</div>
				</Modal>
			)}
		</>
	);
};
