import React, { FC } from 'react';
import { useUnit } from 'effector-react';
// import { profileScreenModel } from 'widgets/account/model';
// import { PromoCardSmall } from 'entities/promo-card';
// import { Button, DesktopView, MobileView, Typo } from 'shared/ui/atoms';
// import { PageableSlider } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { profileScreenModel } from 'entities/account/model';
import { Typo } from 'layout/components/typo/ui';
import { DesktopView, MobileView } from 'layout/layouts/device-view';
import { Button } from 'layout/components/buttons/button';
import { PageableSlider } from 'layout/components/special/pageable-slider';
import { PromoCardSmall } from 'entities/promo-card';

interface SpecialOffersProps {
	onClickAll?: () => void;
	shouldDisplay?: boolean;
}

export const SpecialOffers: FC<SpecialOffersProps> = ({ onClickAll, shouldDisplay = true }) => {
	const specialOffers = useUnit(profileScreenModel.$specialOffersWithImages);

	if (specialOffers.length === 0 || !shouldDisplay) {
		return null;
	}

	return (
		<section className={styles.specialOffers}>
			<header className={styles.specialOffers__head}>
				<Typo design="headline-m" as="h2">
					Спецпредложения для участников Клуба Любителей Круизов
				</Typo>
				<DesktopView>
					<Button
						size="large"
						btnType="text"
						onClick={onClickAll}
						className={styles.specialOffers__headLink}
					>
						Все предложения
					</Button>
				</DesktopView>
			</header>

			<PageableSlider
				Component={PromoCardSmall}
				items={specialOffers}
				className={styles.specialOffers__feed}
				options={undefined}
			/>

			<MobileView>
				<Button
					size="large"
					btnType="outline"
					onClick={onClickAll}
					className={styles.specialOffers__footLink}
				>
					Все предложения
				</Button>
			</MobileView>
		</section>
	);
};
