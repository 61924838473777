// import { fetcher } from 'shared/api/config';
// import {
// 	NEWS_CRUISES_SELECTION_CAT_ID,
// 	NEWS_ENDPOINT,
// 	NEWS_KLK,
// 	NEWS_MAX_PER_PAGE,
// 	NEWS_OPERATOR_NOTIFICATIONS_CAT_ID,
// 	NEWS_POPULAR_ROUTES_CAT_ID,
// 	NEWS_PROMOTIONS_CAT_ID,
// 	NEWS_RECOMMENDATIONS_CAT_ID,
// 	NEWS_SCHEDULE_CHANGES_CAT_ID,
// 	NEWS_SPECIAL_OFFERS_CAT_ID,
// } from 'shared/config';
// import {
// 	LoadPosts200Response,
// 	News,
// 	PostCard,
// 	PostsPageableList,
// 	Recommendations,
// } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { NEWS_CRUISES_SELECTION_CAT_ID, NEWS_ENDPOINT, NEWS_KLK, NEWS_MAX_PER_PAGE, NEWS_OPERATOR_NOTIFICATIONS_CAT_ID, NEWS_POPULAR_ROUTES_CAT_ID, NEWS_PROMOTIONS_CAT_ID, NEWS_RECOMMENDATIONS_CAT_ID, NEWS_SCHEDULE_CHANGES_CAT_ID, NEWS_SPECIAL_OFFERS_CAT_ID } from "config/constants/news";
import { PostsPageableList } from "config/types/core";
import { News, Recommendations } from "config/types/news";
import { LoadPosts200Response, PostCard } from "config/types/posts";

export interface NewsRequest extends Pick<LoadPosts200Response, 'page' | 'perPage'> {
	categoryId?: string | number;
	excludeCategoryId?: string | number;
	showInFeedOnly?: number;
	sortBy?: 'priority';
}

const getNews = (data?: NewsRequest) =>
	fetcher<PostsPageableList<PostCard>>({
		url: NEWS_ENDPOINT,
		method: 'GET',
		body: {
			categoryId: data?.categoryId,
			excludeCategoryId: data?.excludeCategoryId,
			page: data?.page,
			perPage: data?.perPage ?? 10,
			showInFeedOnly: data?.showInFeedOnly,
			sortBy: data?.sortBy,
		},
	});

const getFeedNews = (data?: NewsRequest) =>
	getNews({
		...data,
		showInFeedOnly: 1,
	});

const getCruiseSelections = (data?: NewsRequest) =>
	fetcher<PostsPageableList<PostCard>>({
		url: NEWS_ENDPOINT,
		method: 'GET',
		body: {
			categoryId: NEWS_CRUISES_SELECTION_CAT_ID,
			perPage: data?.perPage ?? 10,
			sortBy: data?.sortBy,
		},
	});

const getPromotionsAndSpecialOffers = () =>
	fetcher<News>({
		url: `${NEWS_ENDPOINT}?categoryId=${NEWS_PROMOTIONS_CAT_ID}`,
		method: 'GET',
		body: {
			perPage: 200,
		},
	});

const getScheduleChanges = (data?: NewsRequest) =>
	fetcher<News>({
		url: `${NEWS_ENDPOINT}?categoryId=${NEWS_SCHEDULE_CHANGES_CAT_ID}`,
		method: 'GET',
		body: {
			page: data?.page,
			perPage: data?.perPage ?? 10,
		},
	});

const getRecommendations = () =>
	fetcher<Recommendations>({
		url: `${NEWS_ENDPOINT}?categoryId=${NEWS_RECOMMENDATIONS_CAT_ID}`,
		method: 'GET',
		body: {
			perPage: 200,
		},
	});

const getSpecialOffers = () =>
	fetcher<Recommendations>({
		url: `${NEWS_ENDPOINT}?categoryId=${NEWS_SPECIAL_OFFERS_CAT_ID}`,
		method: 'GET',
		body: {
			perPage: 200,
		},
	});

const getOperatorNotifications = (page: number) =>
	fetcher<News>({
		url: `${NEWS_ENDPOINT}?categoryId=${NEWS_OPERATOR_NOTIFICATIONS_CAT_ID}&page=${page}`,
		method: 'GET',
	});

const getSpecialOffersKLK = (data?: NewsRequest) =>
	getNews({
		categoryId: data?.categoryId || NEWS_KLK,
		perPage: data?.perPage || NEWS_MAX_PER_PAGE,
	});

const getPopularRoutes = (data?: NewsRequest) =>
	getNews({
		categoryId: NEWS_POPULAR_ROUTES_CAT_ID,
		sortBy: data?.sortBy,
		perPage: data?.perPage ?? NEWS_MAX_PER_PAGE,
	});

export const newsRequest = {
	getCruiseSelections,
	getPromotionsAndSpecialOffers,
	getNews,
	getFeedNews,
	getScheduleChanges,
	getRecommendations,
	getOperatorNotifications,
	getSpecialOffers,
	getSpecialOffersKLK,
	getPopularRoutes,
};
