// import { GetServerSidePropsContext } from 'next/types';
import { createEvent, sample } from 'effector';
import { accountNavigationModel } from 'layout/layouts/header/account-navigation';
import { headerModel } from 'layout/components/view/header';
import { footerModel } from 'layout/components/view/footer';
// import { accountNavigationModel } from 'features/account-navigation';
// import { headerModel } from 'features/header';
// import { footerModel } from 'entities/footer';

// const getData = createEvent<GetServerSidePropsContext>();
// TODO: определить тип данных 
const getData = createEvent<any>();

sample({
	clock: getData,
	target: accountNavigationModel.getCounts,
});

/* *
 * Хэдэр / футер
 */
sample({
	clock: getData,
	target: [headerModel.getData, footerModel.getData],
});

export const model = {
	getData,
};
