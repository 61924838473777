import React, {
	forwardRef,
	HTMLAttributes,
	MouseEventHandler,
	PropsWithChildren,
	useMemo,
} from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { LoadSpinner } from 'layout/components/view/load-spinner';

export type BtnType =
	| 'success'
	| 'primary'
	| 'secondary'
	| 'secondaryWhite'
	| 'text'
	| 'textRed'
	| 'textBlack'
	| 'outline'
	| 'outlineSuccess'
	| 'outlineWhite';
export type SizeType = 'large' | 'middle' | 'small';

const typeMap: Record<BtnType, string> = {
	success: styles.successBtn,
	primary: styles.primaryBtn,
	secondary: styles.secondaryBtn,
	secondaryWhite: styles.secondaryBtnWhite,
	text: styles.textBtn,
	textRed: styles.textRedBtn,
	textBlack: styles.textBlackBtn,
	outline: styles.outlineBtn,
	outlineSuccess: styles.outlineSuccessBtn,
	outlineWhite: styles.outlineWhiteBtn,
};

const sizeMap: Record<SizeType, string> = {
	large: styles.sizeLarge,
	middle: styles.sizeMiddle,
	small: styles.sizeSmall,
};

type LinkProps = HTMLAnchorElement;

export interface ButtonProps
	extends DefaultStyledProps,
		HTMLAttributes<HTMLButtonElement>,
		PropsWithChildren {
	btnType?: BtnType;
	size?: SizeType;
	loading?: boolean;
	disabled?: boolean;
	contextClassName?: string;
	type?: 'button' | 'submit' | 'reset';
	href?: LinkProps['href'];
	target?: LinkProps['target'];
	download?: LinkProps['download'];
	asSpan?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			className,
			contextClassName,
			children,
			style,
			btnType = 'primary',
			size = 'middle',
			loading,
			disabled,
			href,
			target,
			download,
			asSpan,
			...rest
		},
		ref,
	) => {
		const typeClassName = typeMap[btnType];
		const typeSizeClassName = sizeMap[size];
		const complexClassName = classnames(
			styles.basic,
			typeClassName,
			typeSizeClassName,
			{ [styles.isLoading]: loading },
			{ [styles.isDisabled]: disabled },
			className,
		);

		const contents = useMemo(
			() => (
				<div className={classnames(styles.context, contextClassName)}>
					{loading && <LoadSpinner className={styles.loadingSpinner} design="inherit" />}
					{children}
				</div>
			),
			[children, contextClassName, loading],
		);

		if (href) {
			const anchorElement = (
				<a
					className={complexClassName}
					style={style}
					target={target}
					download={download}
					onClick={rest.onClick as MouseEventHandler<HTMLAnchorElement> | undefined}
				>
					{contents}
				</a>
			);

			return !target ? (
				<a href={href}>{anchorElement}</a>
			) : (
				React.cloneElement(anchorElement, { href })
			);
		}

		const Tag = asSpan ? 'span' : 'button';

		return (
			<Tag ref={ref} className={complexClassName} style={style} disabled={disabled} {...rest}>
				{contents}
			</Tag>
		);
	},
);
