import { useOverlayTriggerState } from 'react-stately';
import { useGate } from 'effector-react';
import { model } from './model';

export const useLogoutModal = () => {
	const state = useOverlayTriggerState({});
	useGate(model.Gate, { state });

	return state;
};
