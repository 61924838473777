import { RefObject, useRef, useState } from 'react';
import { scrollToElement } from 'utils/scroll';
// import { scrollToElement } from 'shared/lib/utils';

export type ProfileScreenToken = 'specialOffers';

interface UseProfileScreens {
	ref?: RefObject<HTMLElement>;
}

export const useProfileScreens = ({ ref }: UseProfileScreens) => {
	const timer = useRef(0);

	const [screenState, setScreenState] = useState<'waiting' | 'enter' | 'exit'>('waiting');
	const [currentScreen, setCurrentScreen] = useState<Nullable<ProfileScreenToken>>(null);

	const changeScreen = (screen?: Nullable<ProfileScreenToken>) => {
		window.clearTimeout(timer.current);

		setScreenState('enter');

		if (ref?.current) {
			scrollToElement(ref.current, 100, 'smooth', true);
		}

		timer.current = window.setTimeout(() => {
			setCurrentScreen(screen ?? null);

			timer.current = window.setTimeout(() => {
				setScreenState('exit');

				timer.current = window.setTimeout(() => {
					setScreenState('waiting');
				}, 800);
			}, 100);
		}, 800);
	};

	return {
		screenState,
		currentScreen,
		changeScreen,
	};
};
