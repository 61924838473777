import axios from 'axios';
import { RegisterRequest, RegisterResponse, ResendSMSRequest, VerificationPhoneRequest, VerificationPhoneResponse } from 'config/types/personal';
import { ACCOUNT_URL } from '../constants';
import { API_BASE_URL } from 'config/commonConstants';


export const register = (data: RegisterRequest) => {
	return axios
		.post<RegisterResponse>(`${ACCOUNT_URL}/register/`, data, { baseURL: API_BASE_URL })
		.then((res) => res.data);
}

export const verificationPhone = (data: VerificationPhoneRequest) => {
	return axios
		.post<VerificationPhoneResponse>(`${ACCOUNT_URL}/phone/verify/`, {
			...data,
			source: 3, // этот параметр для проксификации запросов к инфофлоту бэком s-cruises
		}, {
			baseURL: API_BASE_URL,
		})
		.then((res) => res.data);
}

export const resendSMS = (data: ResendSMSRequest) => {
	return axios
		.post<ResendSMSRequest>(`${ACCOUNT_URL}/phone/verify/resend/`, data, { baseURL: API_BASE_URL })
		.then((res) => res.data);
}

export const verificationLoginByPhone = (data: VerificationPhoneRequest) => {
	return axios
		.post<VerificationPhoneResponse>(`${ACCOUNT_URL}/login-by-sms/verify/`, {
			...data,
			source: 3, // этот параметр для проксификации запросов к инфофлоту бэком s-cruises
		}, {
			baseURL: API_BASE_URL,
		})
		.then((res) => res.data);
}
