import { createFileRoute, useLocation, useSearch } from '@tanstack/react-router'
import { Main } from 'layout/layouts/main'
import { OrderCustomer } from 'layout/layouts/widgets/order/order-customer'


export const Route = createFileRoute('/order/customer/')({
    component: () => <OrdersCustomer />,
})


function OrdersCustomer() {
    // const sParams: Record<string, string> = useSearch({ strict: false })
    // // const ctx = useRouteContext({ from: '/login' })
    // //     console.log('route login',ctx)
    // const location = useLocation()

    // console.log('route location', location)
    // const { state, emailToken, error } = sParams
    // console.log(state)

    return <OrderCustomer />
}