import React, { FC, PropsWithChildren, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { Button, Condition, Price, Skeleton, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Skeleton } from 'layout/components/special/skeleton';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';

export interface SummaryTableItemProps extends PropsWithChildren, DefaultStyledProps {
	label: string;
	price?: number;
	priceType?: 'discount';
	isSubitem?: boolean;
	withOffset?: boolean;
	defaultExpanded?: boolean;
	pending?: boolean;
}

export const SummaryTableItem: FC<SummaryTableItemProps> = ({
	label,
	price,
	priceType,
	isSubitem,
	withOffset,
	defaultExpanded = false,
	pending = false,
	children,
}) => {
	const [expanded, setExpanded] = useState(defaultExpanded);

	return (
		<>
			<Typo
				className={classnames(styles.summaryItem, priceType && styles[priceType], {
					[styles.isSubitem]: isSubitem,
					[styles.withOffset]: withOffset,
				})}
				design="text-s"
			>
				<div className={styles.summaryItem__label}>
					{children && (
						<Condition
							value={pending}
							then={<Skeleton as="strong" margin="2px 0 0" width={20} height={20} />}
							else={
								<Button
									btnType="text"
									onClick={() => setExpanded((prev) => !prev)}
									className={classnames(styles.summaryItem__toggle, {
										[styles.isExpanded]: expanded,
									})}
								>
									<Icon id="chevron-down" />
								</Button>
							}
						/>
					)}
					<span>{label}</span>
				</div>

				<Condition
					value={pending}
					then={<Skeleton as="strong" width={68} height={24} />}
					else={
						<Typo as="strong" design="text-m">
							{priceType === 'discount' && '- '}
							{price && <Price total={price} />}
						</Typo>
					}
				/>
			</Typo>

			{children && React.Children.count(children) > 0 && (
				<AnimateHeight
					duration={400}
					height={expanded ? 'auto' : 0}
					contentClassName={styles.summarySublist}
				>
					<ul className={styles.summary}>
						{React.Children.map(children, (child) => {
							if (!React.isValidElement(child)) {
								return null;
							}

							return (
								<li>
									{React.cloneElement(child, {
										...child.props,
										priceType,
										isSubitem: true,
									})}
								</li>
							);
						})}
					</ul>
				</AnimateHeight>
			)}
		</>
	);
};
