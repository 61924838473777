import { FC } from 'react';
import { AriaCalendarGridProps, useCalendarGrid } from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';
// import { Typo } from 'shared/ui/atoms';
import style from './style.module.scss';
import { Typo } from 'layout/components/typo/ui';

interface CalendarWeekProps extends AriaCalendarGridProps {
	state: CalendarState | RangeCalendarState;
}

export const CalendarWeek: FC<CalendarWeekProps> = ({ state, ...props }) => {
	const { headerProps, weekDays } = useCalendarGrid(props, state);

	return (
		<thead {...headerProps} className={style.week}>
			<tr>
				{weekDays.map((day, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<Typo key={`calendar-week-${i}`} as="th" design="text-s">
						{day}
					</Typo>
				))}
			</tr>
		</thead>
	);
};
