import { Declensions } from 'config/commonTypes';
import React, { FC } from 'react';
import { getNumDeclension } from 'utils/string';
// import { Declensions } from 'shared/lib/types';
// import { getNumDeclension } from 'shared/lib/utils';

export interface CruiseDurationLengthProps {
	count?: number;
	declension: Declensions;
}

export const CruiseDurationLength: FC<CruiseDurationLengthProps> = ({ count, declension }) => {
	if (!count) {
		return null;
	}

	return (
		<p>
			{count} {getNumDeclension(count, declension)}
		</p>
	);
};
