import { ParsedUrlQuery } from 'querystring';
import { combineEvents, debounce, reset, status } from 'patronum';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
// import { GetCruisesPaginationRequest, searchRequests } from 'shared/api';
// import {
// 	PAGINATION_CACHE_NAME_SS,
// 	SEARCH_CRUISES_FREE_ONLY_DEFAULT,
// 	SEARCH_CRUISES_FREE_ONLY_KEY,
// 	SEARCH_CRUISES_LIMIT,
// 	SEARCH_CRUISES_SHIP_TYPES,
// 	SEARCH_CRUISES_SHIP_TYPES_KEY,
// 	SEARCH_CRUISES_SHIPS_KEY,
// 	SEARCH_FUTURE_CRUISES_ONLY_DEFAULT,
// 	SEARCH_FUTURE_CRUISES_ONLY_KEY,
// 	SEARCH_SORT_DATE,
// 	SEARCH_SORT_KEY,
// } from 'shared/config';
// import { getSkeletonsArray } from 'shared/lib/skeletons';
// import { CruiseModel, PricesTableParmas, PageableMeta, SearchACKeys } from 'shared/lib/types';
// import {
// 	normalizeISODatesInParams,
// 	scrollToElement,
// 	stringifyParsedUrlQuery,
// } from 'shared/lib/utils';
// import { routerModel } from 'shared/model';
import { getQueryString } from '../lib';
import { SEARCH_CRUISES_SHIP_TYPES_KEY, SEARCH_CRUISES_SHIPS_KEY, SEARCH_CRUISES_FREE_ONLY_KEY, SEARCH_FUTURE_CRUISES_ONLY_KEY, SEARCH_CRUISES_LIMIT, SEARCH_SORT_DATE, SEARCH_CRUISES_FREE_ONLY_DEFAULT, SEARCH_FUTURE_CRUISES_ONLY_DEFAULT, SEARCH_CRUISES_SHIP_TYPES, SEARCH_SORT_KEY, PAGINATION_CACHE_NAME_SS } from 'config/constants/search';
import { CruiseModel, PricesTableParmas } from 'config/types/cabins/models/cruise';
import { PageableMeta } from 'config/types/core';
import { normalizeISODatesInParams, stringifyParsedUrlQuery } from 'utils/object';
import { routerModel } from 'utils/router';
import { scrollToElement } from 'utils/scroll';
import { getSkeletonsArray } from 'utils/skeletons/skeletons';
import { GetCruisesPaginationRequest, searchRequests } from 'store/cruise/search';
import { SearchACKeys } from 'config/types/search';

export type ResetParamsType = {
	[SEARCH_CRUISES_SHIP_TYPES_KEY]: Nullable<string>;
	[SEARCH_CRUISES_SHIPS_KEY]?: Nullable<string>;
	[SEARCH_CRUISES_FREE_ONLY_KEY]?: Nullable<string>;
	[SEARCH_FUTURE_CRUISES_ONLY_KEY]?: Nullable<string>;
};

export type CruisesModelType = ReturnType<typeof cruisesModelFactory>;

export const cruisesModelFactory = (sid?: string) => {
	const getCruises = createEvent<boolean | undefined>({ sid });

	const setParams = createEvent<ParsedUrlQuery>({ sid });
	const setPredefinedParams = createEvent<ParsedUrlQuery>({ sid });
	const resetParams = createEvent<ResetParamsType>({ sid });
	const resetToDefaultParams = createEvent({ sid });
	const resetPredefinedParams = createEvent({ sid });

	const setPagination = createEvent<GetCruisesPaginationRequest>({ sid });
	const setPaginationLimit = createEvent<number>({ sid });
	const resetPagination = createEvent({ sid });

	const loadPaginationCache = createEvent({ sid });
	const resetPaginationCache = createEvent({ sid });

	const $list = createStore<CruiseModel[]>([], { sid });
	const $skeletons = createStore<any[]>(getSkeletonsArray(), { sid });
	const $count = createStore(0, { sid });
	const $tableCabins = createStore<PricesTableParmas>({}, { sid });
	const $targetCruise = createStore<Nullable<string>>(null, { sid });

	const Gate = createGate({ sid });

	const $pagination = createStore<PageableMeta>(
		{
			count: 0,
			offset: 0,
			limit: SEARCH_CRUISES_LIMIT,
			next: null,
			previous: null,
		},
		{ sid },
	);
	const $paginationCache = createStore<Nullable<PageableMeta>>(null, { sid });

	const $sort = createStore(SEARCH_SORT_DATE, { sid });
	const $params = createStore<Nullable<ParsedUrlQuery>>(
		{
			[SEARCH_CRUISES_FREE_ONLY_KEY]: SEARCH_CRUISES_FREE_ONLY_DEFAULT,
			[SEARCH_FUTURE_CRUISES_ONLY_KEY]: SEARCH_FUTURE_CRUISES_ONLY_DEFAULT,
			[SEARCH_CRUISES_SHIP_TYPES_KEY]: SEARCH_CRUISES_SHIP_TYPES.default,
		},
		{ sid },
	).reset(resetToDefaultParams);
	const $predefinedParams = createStore<Nullable<ParsedUrlQuery>>(null, { sid }).reset(
		resetPredefinedParams,
	);

	const setError = createEvent<Nullable<SearchACKeys>>({ sid });
	const $error = createStore<Nullable<SearchACKeys>>(null, { sid });

	const mapParams = (
		params: Nullable<ParsedUrlQuery>,
		predefined: Nullable<ParsedUrlQuery>,
		sort: string,
	) => {
		const mergedParams = normalizeISODatesInParams({
			...params,
			...predefined,
			[SEARCH_SORT_KEY]: sort,
		});

		/* *
		 * Если явно указаны конкретные id круизов,
		 * то иключаем из параметров shipTypesIn
		 */
		if (mergedParams.ids) {
			delete mergedParams[SEARCH_CRUISES_SHIP_TYPES_KEY];
		}

		return { queryParams: stringifyParsedUrlQuery(mergedParams) };
	};

	const getCruiseGetter = () =>
		attach({
			effect: createEffect({
				handler: searchRequests.getCruises,
				sid,
			}),
			source: {
				pagination: $pagination,
				params: $params,
				predefined: $predefinedParams,
				sort: $sort,
			},
			mapParams: (_, { pagination: { limit, offset, forTable }, params, predefined, sort }) => {
				const { queryParams } = mapParams(params, predefined, sort);

				return {
					limit,
					offset,
					forTable,
					params: queryParams,
				};
			},
		});

	const getCruisesWithCachedPagination = () =>
		attach({
			effect: createEffect({
				handler: searchRequests.getCruises,
				sid,
			}),
			source: {
				params: $params,
				predefined: $predefinedParams,
				sort: $sort,
			},
			mapParams: (_, { params, predefined, sort }) => {
				const { queryParams } = mapParams(params, predefined, sort);

				const cacheInstance = sessionStorage.getItem(PAGINATION_CACHE_NAME_SS);

				const parsedCache = cacheInstance ? JSON.parse(cacheInstance) : null;

				return {
					limit: parsedCache.offset + parsedCache.limit,
					offset: 0,
					params: queryParams,
				};
			},
		});

	/* *
	 * Получение круизов
	 */
	const getCruisesFx = getCruiseGetter();
	const getCruisesWithCachedPaginationFx = getCruisesWithCachedPagination();

	const $status = status({ effect: getCruisesFx });

	/* *
	 * Установка роута
	 */
	sample({
		clock: getCruises,
		source: {
			sort: $sort,
			params: $params,
			predefined: $predefinedParams,
		},
		filter: ({ predefined }, clock) => !predefined && !clock,
		fn: ({ params, sort }) => {
			const query = getQueryString(params ?? {}, sort);

			const path = `/search${query ? `?${query}` : ''}`;

			return { url: path, as: path, options: { shallow: true } };
		},
		target: routerModel.push,
	});

	/* *
	 * Установка пагинации
	 */
	sample({
		clock: setPaginationLimit,
		source: $pagination,
		fn: (source, clock) => ({
			...source,
			limit: clock,
		}),
		target: setPagination,
	});

	sample({
		clock: setPagination,
		source: $pagination,
		fn: (source, clock) => ({
			...source,
			...clock,
		}),
		target: $pagination,
	});

	sample({
		clock: resetPagination,
		source: $pagination,
		fn: (source) => ({
			...source,
			offset: 0,
		}),
		target: $pagination,
	});

	const getCruisesWithPagination = combineEvents({
		events: [setPagination, $pagination.updates],
	});

	sample({
		clock: getCruisesWithPagination,
		fn: () => true,
		target: getCruises,
	});

	/* *
	 * Кеширование пагинации
	 */
	const storePaginationCacheFx = createEffect(async (cache: Nullable<PageableMeta>) => {
		await (cache
			? sessionStorage.setItem(PAGINATION_CACHE_NAME_SS, JSON.stringify(cache))
			: sessionStorage.removeItem(PAGINATION_CACHE_NAME_SS));
	});

	sample({
		clock: $pagination,
		filter: (source) => source.offset !== 0,
		target: $paginationCache,
	});

	sample({
		clock: $paginationCache,
		target: storePaginationCacheFx,
	});

	reset({
		clock: resetPaginationCache,
		target: $paginationCache,
	});

	/* *
	 * Обработка роутинга при возврате к поиску
	 */
	const scrollToTargetCruise = createEvent<Nullable<string>>();
	const scrollToTargetCruiseFx = createEffect(async (targetCruise: Nullable<string>) => {
		const targetElement = document.querySelector(`.cruise-${targetCruise}`) as HTMLElement;

		if (targetElement) {
			scrollToElement(targetElement, 100);
		}
	});

	sample({
		clock: combineEvents({
			events: [getCruisesWithCachedPaginationFx.done, $list.updates],
		}),
		source: $targetCruise,
		target: scrollToTargetCruise,
	});

	debounce({
		source: scrollToTargetCruise,
		timeout: 300,
		target: scrollToTargetCruiseFx,
	});

	sample({
		clock: loadPaginationCache,
		source: routerModel.$prevPage,
		filter: (source) =>
			!!source?.includes('/cruise/') && !!sessionStorage.getItem(PAGINATION_CACHE_NAME_SS),
		target: getCruisesWithCachedPaginationFx,
	});

	sample({
		clock: loadPaginationCache,
		source: {
			prevPage: routerModel.$prevPage,
			targetCruise: $targetCruise,
		},
		filter: ({ prevPage }) =>
			!!prevPage?.includes('/cruise/') && !sessionStorage.getItem(PAGINATION_CACHE_NAME_SS),
		fn: ({ targetCruise }) => targetCruise,
		target: scrollToTargetCruise,
	});

	sample({
		clock: loadPaginationCache,
		source: routerModel.$prevPage,
		filter: (source) => !source?.includes('/cruise/'),
		fn: () => null,
		target: storePaginationCacheFx,
	});

	sample({
		clock: routerModel.$prevPage,
		fn: (source) => source?.split('/cruise/')[1] ?? null,
		target: $targetCruise,
	});

	sample({
		clock: Gate.close,
		fn: () => null,
		target: $targetCruise,
	});

	/* *
	 * Установка параметров из query
	 */
	sample({
		clock: setParams,
		fn: (clock) => ({
			[SEARCH_CRUISES_FREE_ONLY_KEY]: SEARCH_CRUISES_FREE_ONLY_DEFAULT,
			[SEARCH_FUTURE_CRUISES_ONLY_KEY]: SEARCH_FUTURE_CRUISES_ONLY_DEFAULT,
			[SEARCH_CRUISES_SHIP_TYPES_KEY]: SEARCH_CRUISES_SHIP_TYPES.default,
			...clock,
		}),
		target: $params,
	});

	sample({
		clock: resetParams,
		fn: (clock) => (clock || {}) as ParsedUrlQuery,
		target: $params,
	});

	/* *
	 * Установка предустановленных параметров
	 */
	sample({
		clock: setPredefinedParams,
		source: $predefinedParams,
		fn: (source, clock = {}) => {
			const newSource = Array.isArray(source) ? {} : source;

			return { ...newSource, ...clock };
		},
		target: $predefinedParams,
	});

	/* *
	 * Выборка данных
	 */
	sample({
		clock: getCruises,
		target: getCruisesFx,
	});

	sample({
		clock: getCruisesFx.doneData,
		source: $pagination,
		fn: (source, { count }) => ({
			...source,
			count,
		}),
		target: $pagination,
	});

	sample({
		clock: getCruisesWithCachedPaginationFx.doneData,
		fn: (clock) => {
			const cacheInstance = sessionStorage.getItem(PAGINATION_CACHE_NAME_SS);

			return cacheInstance ? JSON.parse(cacheInstance) : clock;
		},
		target: [$pagination, resetPaginationCache],
	});

	sample({
		clock: getCruisesFx.doneData,
		source: {
			list: $list,
			pagination: $pagination,
		},
		filter: (_, { results }) => Boolean(results),
		fn: ({ list, pagination }, { results }) => {
			if (pagination?.offset) {
				return [...list, ...results];
			}

			return results;
		},
		target: $list,
	});

	sample({
		clock: getCruisesFx.doneData,
		filter: ({ formatted }) => Boolean(formatted),
		fn: ({ formatted }) => formatted.pricesTable,
		target: $tableCabins,
	});

	sample({
		clock: getCruisesWithCachedPaginationFx.doneData,
		fn: ({ results }) => results,
		target: $list,
	});

	sample({
		clock: getCruisesWithCachedPaginationFx.doneData,
		filter: (response) => !!response.formatted,
		fn: ({ formatted }) => formatted.pricesTable,
		target: $tableCabins,
	});

	sample({
		clock: $list,
		fn: ({ length }) => length,
		target: $count,
	});

	sample({
		clock: $count,
		fn: (count) => getSkeletonsArray(count),
		target: $skeletons,
	});

	/* *
	 * Обработка ошибок
	 */
	sample({
		clock: setError,
		target: $error,
	});

	sample({
		clock: [getCruisesFx.done, getCruisesWithCachedPaginationFx.done],
		fn: () => null,
		target: setError,
	});

	sample({
		clock: [getCruisesFx.fail, getCruisesWithCachedPaginationFx.fail],
		fn: () => 'AC3' as const,
		target: setError,
	});

	return {
		$list,
		$tableCabins,
		$count,
		$pagination,
		$sort,
		$params,
		$predefinedParams,
		$status,
		$skeletons,
		$error,
		getCruises,
		getCruisesFx,
		setParams,
		setPredefinedParams,
		resetParams,
		resetPredefinedParams,
		setPagination,
		setPaginationLimit,
		resetPagination,
		getCruiseGetter,
		loadPaginationCache,
		setError,
		Gate,
		$targetCruise,
		resetToDefaultParams,
	};
};

export const model = cruisesModelFactory();
