// import { fetcher } from 'shared/api/config';
// import { CRUISES_ENDPOINT } from 'shared/config';
// import { DetailedCruiseCabin } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { CRUISES_ENDPOINT } from "config/constants/cruises";
import { DetailedCruiseCabin } from "config/types/cabins";

export interface GetDetailedCruiseCabinRequest {
	cruiseId: number;
	cabinId: number;
	passengersTypes?: string[];
	signal?: AbortSignal;
}

const getDetailedCruiseCabinInfo = ({
	cruiseId,
	cabinId,
	passengersTypes,
	signal,
}: GetDetailedCruiseCabinRequest) =>
	fetcher<DetailedCruiseCabin.Cabin>({
		url: `${CRUISES_ENDPOINT}/${cruiseId}/cabins/${cabinId}${
			passengersTypes?.length ? `?passengersTypes=${passengersTypes?.join(',')}` : ''
		}`,
		method: 'GET',
		signal,
	});

export const detailedCruiseCabinRequest = {
	getDetailedCruiseCabinInfo,
};
