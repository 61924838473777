import { useRef } from 'react';
import { useMenu } from 'react-aria';
import { TreeProps, useTreeState } from 'react-stately';
import { MenuItem } from '../menu-item';
import { MenuSection } from '../menu-section';
import styles from './styles.module.scss';

interface MenuProps<T extends object> extends TreeProps<T> {}

export const Menu = <T extends object>({ ...props }: MenuProps<T>) => {
	const state = useTreeState(props);

	const ref = useRef<Nullable<HTMLUListElement>>(null);
	const { menuProps } = useMenu(props, state, ref);

	return (
		<ul {...menuProps} className={styles.menu} ref={ref}>
			{[...state.collection].map((item) => {
				if (item.type === 'section') {
					return <MenuSection key={item.key} section={item} state={state} />;
				}

				return <MenuItem key={item.key} item={item} state={state} />;
			})}
		</ul>
	);
};
