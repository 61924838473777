import { fetcher } from 'api/setupAxios';
import { SHIPS_ENDPOINT, SHIPS_HANDBOOK_ENDPOINT } from 'config/constants/ships';
import { ShipFilter, ShipsResponse } from 'config/types/ships';
import { ParsedUrlQuery } from 'querystring';
import { stringifyParsedUrlQuery } from 'utils/object';
// import { fetcher } from 'shared/api/config';
// import { SHIPS_ENDPOINT, SHIPS_HANDBOOK_ENDPOINT } from 'shared/config';
// import { ShipFilter, ShipsResponse } from 'shared/lib/types';
// import { stringifyParsedUrlQuery } from 'shared/lib/utils';

export interface GetShipsParams extends ParsedUrlQuery {
	typeOfRoute?: string;
	shipIn?: string;
	class?: string;
	services?: string;
}

export interface GetShipsRequest {
	page?: number;
	params?: GetShipsParams;
}

export const getShips = ({ page = 1, params = {} }: GetShipsRequest) => {
	const query = stringifyParsedUrlQuery(params);
	const queryTrail = query ? `?${query}` : '';

	return fetcher<ShipsResponse>({
		url: `${SHIPS_ENDPOINT}${queryTrail}`,
		method: 'GET',
		body: {
			page,
		},
	});
};

export const getShipsFiltersHandbook = (params?: Nullable<GetShipsParams>) =>
	fetcher<ShipFilter[]>({
		url: `${SHIPS_HANDBOOK_ENDPOINT}?${stringifyParsedUrlQuery(params)}`,
		method: 'GET',
		body: {},
	});
