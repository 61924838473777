import React, { useMemo, FC, CSSProperties } from 'react';
import classnames from 'classnames';
// import { TypoTagNameType } from 'shared/ui/atoms/typo';
import styles from './style.module.scss';
import { TypoTagNameType } from 'layout/components/typo/ui';

interface SkeletonProps {
	as?: TypoTagNameType;
	display?: CSSProperties['display'];
	position?: CSSProperties['position'];
	width?: CSSProperties['width'];
	height?: CSSProperties['height'];
	margin?: CSSProperties['margin'];
	radius?: CSSProperties['borderRadius'];
	top?: CSSProperties['top'];
	delay?: number;
	className?: string;
	aspectRatio?: number;
}

export const Skeleton: FC<SkeletonProps> = ({
	as: Tag = 'span',
	display,
	position,
	width,
	height,
	margin,
	radius,
	top,
	delay,
	className,
	aspectRatio,
}) => {
	const css = useMemo(
		() => ({
			display,
			position,
			width,
			height: aspectRatio ? 'auto' : height,
			margin,
			top,
			borderRadius: radius,
			animationDelay: `${delay}ms`,
		}),
		[display, position, width, height, margin, delay, top, radius, aspectRatio],
	);

	return (
		<Tag className={classnames(className, styles.skeleton)} style={css}>
			{aspectRatio && (
				<span className={styles.spacer} style={{ paddingBottom: `${aspectRatio}%` }} />
			)}
		</Tag>
	);
};
