import { createFileRoute, useLocation } from '@tanstack/react-router'

export const Route = createFileRoute('/')({
  component: Home,
})


function Home() {
  const location = useLocation()

  return (
    // location.pathname == "/"
    // ? <div>Домашняя</div>
    // : 
    null)
}