import React, { FC, PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';
import { getLabelText } from '../lib';
import style from './style.module.scss';

export type ChipRecordProps = ChipRecordWithLabelProps | ChipRecordWithChildrenProps;

interface ChipRecordCommonProps {
	theme?: 'white';
	align?: 'baseline';
	inverse?: boolean;
}

interface ChipRecordWithChildrenProps extends PropsWithChildren, ChipRecordCommonProps {}

interface ChipRecordWithLabelProps extends ChipRecordCommonProps {
	value?: ReactNode | number;
	label?: string | [string, string, string];
}

const isPropsWithChildren = (props: ChipRecordProps): props is ChipRecordWithChildrenProps =>
	(props as ChipRecordWithChildrenProps).children !== undefined;

export const ChipRecord: FC<ChipRecordProps> = (props) => {
	const { theme, align, inverse } = props;

	if (isPropsWithChildren(props)) {
		const { children } = props;

		return (
			<p
				className={classnames(
					style.chipRecord,
					theme && style[theme],
					align && style[align],
					inverse && style.inverse,
				)}
			>
				{children}
			</p>
		);
	}

	const { value, label } = props;
	if (!value) {
		return null;
	}

	const labelText = getLabelText(value, label);

	return (
		<p
			className={classnames(
				style.chipRecord,
				theme && style[theme],
				align && style[align],
				inverse && style.inverse,
			)}
		>
			<strong>{value}</strong>
			{labelText && <span>{labelText}</span>}
		</p>
	);
};
