// import { emailMask } from 'shared/ui/organisms/form/lib/masks';
import { emailMask } from 'layout/components/inputs/form/masks';
import { CustomerFormValues } from './types';

export const getMaskProps = (name?: keyof CustomerFormValues) => {
	switch (name) {
		case 'phone':
			return {
				mask: '+####################',
			};
		case 'email':
			return emailMask;
		default: {
			return {
				mask: 'a',
				maskOptions: {
					tokens: {
						a: {
							pattern: /^[\d\sa-zа-яё\-]*$/i,
							multiple: true,
						},
					},
				},
			};
		}
	}
};
