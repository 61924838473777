import { ParsedUrlQuery } from 'querystring';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { Ship } from 'store/auth/types/account/ship';
import { accountRequests } from 'store/account/api/requests';
import { FilterUnit } from 'config/types/core';
import { isEmptyObject } from 'utils/object';
// import { accountRequests } from 'shared/api';
// import { FilterUnit } from 'shared/lib/types';
// import { Ship } from 'shared/lib/types/account/models';
// import { isEmptyObject } from 'shared/lib/utils';

const $availableShips = createStore<Nullable<Ship[]>>(null);
const getAvailableShips = createEvent();
const getAvailableShipsFx = createEffect(accountRequests.getAvailableShips);

sample({
	source: $availableShips,
	clock: getAvailableShips,
	filter: (source) => !(source && source.length),
	target: getAvailableShipsFx,
});

sample({
	clock: getAvailableShipsFx.doneData,
	target: $availableShips,
});

const $searchParams = createStore<Nullable<ParsedUrlQuery>>({
	status: 'active',
	sort: 'desc',
});
const setInitialSearchParams = createEvent<ParsedUrlQuery>();
const setSearchParams = createEvent<FilterUnit>();

sample({
	source: $searchParams,
	clock: setInitialSearchParams,
	fn: (source, clock) => (source ? { ...source, ...clock } : clock),
	target: $searchParams,
});

sample({
	clock: setSearchParams,
	source: $searchParams,
	filter: (_, clock) => !isEmptyObject(clock),
	fn: (source, { key, value }) => {
		const params = { ...source };

		if (!value) {
			delete params[key as keyof typeof params];
		} else {
			params[key as keyof typeof params] = value;
		}

		return params;
	},
	target: $searchParams,
});

export const model = {
	$availableShips,
	getAvailableShips,
	$searchParams,
	setInitialSearchParams,
	setSearchParams,
};
