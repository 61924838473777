/* eslint-disable import/no-extraneous-dependencies */
import React, { InputHTMLAttributes, useRef, useState } from 'react';
import { useId } from '@react-aria/utils';
import classnames from 'classnames';
import { Mask, MaskOptions } from 'maska';
import { useAutoScrollOnInput } from './lib';
import styles from './styles.module.scss';
import { IconId } from 'layout/components/icons/svg-sprite';
import { Typo } from 'layout/components/typo/ui';
import { Icon } from 'layout/components/icons/icon';

export interface InputInterface extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'size'> {
	error?: JSX.Element;
	icon?: IconId;
	theme?: 'normal' | 'secondary';
	size?: 'md' | 'lg';
	withLabel?: boolean;
	isLoading?: boolean;
	classNameWrap?: string;
	mask?: string | string[];
	maskOptions?: MaskOptions;
	prefix?: string;
	secondPlaceholder?: string;
	isRequired?: boolean;
}

export const InputMultiRow = React.forwardRef<HTMLTextAreaElement, InputInterface>(
	(
		{
			error,
			icon,
			theme = 'normal',
			size = 'md',
			withLabel,
			isLoading,
			className,
			classNameWrap,
			prefix,
			secondPlaceholder,
			onFocus,
			onBlur,
			onChange,
			placeholder,
			children,
			mask,
			maskOptions = {},
			value,
			isRequired,
			...props
		},
		ref,
	) => {
		const ariaId = useId();
		const [hasValue, setHasValue] = useState(false);
		const maskInstance = useRef(new Mask({ mask, ...maskOptions }));

		const setActive = (node: HTMLTextAreaElement) =>
			setHasValue(node.value.length > 0 || document.activeElement === node);

		const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
			setHasValue(true);
			if (onFocus) {
				onFocus(e);
			}
		};

		const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
			setActive(e.target);
			if (onBlur) {
				onBlur(e);
			}
		};

		const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
			setActive(e.target);
			if (onChange) {
				onChange(e);
			}
		};

		const { wrapRef, inputRef } = useAutoScrollOnInput();

		return (
			<label
				className={classnames(
					classNameWrap,
					styles.input,
					size && styles[size],
					theme && styles[theme],
					error && styles.error,
					icon && styles.withIcon,
					withLabel && styles.withLabel,
					secondPlaceholder && styles.secondPlaceholder,
				)}
			>
				<div className={styles.input__wrap}>
					{withLabel && placeholder && (
						<Typo
							design="button-m-s"
							className={classnames(
								styles.input__label,
								{
									[styles.isRequired]: props.required || isRequired,
									[styles.isActive]: hasValue,
								},
								theme && styles[theme],
							)}
						>
							<span>{placeholder}</span>
						</Typo>
					)}
					<div
						ref={wrapRef}
						className={styles.autogrow}
						data-replicated={mask ? maskInstance.current.masked(String(value)) : value}
					>
						<textarea
							ref={(node) => {
								if (node) {
									setActive(node);

									if (typeof ref === 'function') {
										ref(node);
									}

									if (inputRef) {
										inputRef.current = node;
									}
								}
							}}
							className={classnames(styles.textarea, className)}
							onFocus={handleFocus}
							onBlur={handleBlur}
							onChange={handleChange}
							placeholder={!withLabel ? placeholder : secondPlaceholder}
							value={mask ? maskInstance.current.masked(String(value)) : value}
							aria-invalid={Boolean(error)}
							aria-errormessage={error ? ariaId : undefined}
							{...props}
						/>
					</div>
					{icon && <Icon id={icon} className={styles.input__icon} />}
				</div>
				{children && (
					<Typo design="input-text-s" className={styles.input__desc}>
						{children}
					</Typo>
				)}
				{error && (
					<Typo
						id={ariaId}
						design="input-text-s"
						className={classnames(styles.input__error, 'errormessage')}
					>
						{error}
					</Typo>
				)}
			</label>
		);
	},
);
