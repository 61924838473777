import { ResendSMSRequest, VerificationPhoneRequest } from "config/types/personal";
import { createEffect, createEvent, sample } from "effector";
import { createForm } from "effector-react-form";
import { personalRequests } from "store/auth/api";
import { verificationModel } from "utils/verification";
import { model as authorizationModel } from '../../login/model'
import { message } from "config/constants/message";
import { getFailCode } from "api";
import { routerModel } from "utils/router";
import { AUTH_REDIRECT_MAP } from "config/commonConstants";


const submitVerificationForm = createEvent<Omit<VerificationPhoneRequest, 'verifyToken'>>();
const verifyFx = createEffect(personalRequests.verificationPhone);
const verifyLoginFx = createEffect(personalRequests.verificationLoginByPhone);

const resend = createEvent();
const resendFx = createEffect(personalRequests.resendSMS);

const form = createForm<Omit<VerificationPhoneRequest, 'verifyToken'>>({
	initialValues: {
		code: '',
	},
	onSubmit: ({ values }) => {
		const res = submitVerificationForm(values)
		console.log('error onSubmit verify-phone', res)
		return res
	},
});

const handleVerifyAnalyticsFx = createEffect(() => ({})
	// setAnalytics(ANALYTICS_TARGETS.signup.signup_success),
);

sample({
	source: {
		verifyToken: verificationModel.$verifyToken,
		isSmsLogin: authorizationModel.$isLoginSms,
	},

	clock: submitVerificationForm,
	filter: ({ isSmsLogin }) => !isSmsLogin,
	fn: ({ verifyToken }, { code }) => {
		console.log('error sanple submitVerificationForm', {
			verifyToken,
			code,
		} as VerificationPhoneRequest)
		return ({
			verifyToken,
			code,
		} as VerificationPhoneRequest)
	}
	,
	target: verifyFx,
});

sample({
	source: {
		verifyToken: verificationModel.$verifyToken,
		isSmsLogin: authorizationModel.$isLoginSms,
		captcha: verificationModel.$captchaToken,
	},

	clock: submitVerificationForm,
	filter: ({ isSmsLogin }) => isSmsLogin,
	fn: ({ verifyToken, captcha }, { code }) => {
		console.log('error submitVerificationForm with captcha', {
			captcha,
			verifyToken,
			code,
		} as VerificationPhoneRequest)
		return ({
			captcha,
			verifyToken,
			code,
		} as VerificationPhoneRequest)
	}
	,
	target: verifyLoginFx,
});

const handleAuthoriseAnalyticsFx = createEffect(() => ({})
	// setAnalytics(ANALYTICS_TARGETS.signin.signin_success),
);

sample({
	clock: verifyLoginFx.doneData,
	source: {
		prevPage: routerModel.$prevPage,
		redirectTarget: routerModel.$authRedirectTarget,
		isSmsLogin: authorizationModel.$isLoginSms,
	},
	filter: ({ isSmsLogin }) => isSmsLogin,
	fn: ({ prevPage, redirectTarget }) => {
		console.log("error verifyLoginFx", prevPage, redirectTarget)
		if (redirectTarget) {
			return redirectTarget;
		}

		if (prevPage && AUTH_REDIRECT_MAP.hasOwnProperty(prevPage)) {
			return AUTH_REDIRECT_MAP[prevPage];
		}

		return prevPage || '/';
	},
	// target: [routerModel.push, routerModel.setAuthRedirectTarget, handleAuthoriseAnalyticsFx],
	target: [routerModel.pushAsA, routerModel.setAuthRedirectTarget, handleAuthoriseAnalyticsFx],
});

sample({
	clock: [verifyFx.failData, verifyLoginFx.failData],
	fn: (res) => {
		console.log("error verify fail", {
			field: 'formError',
			error: message.profile.verifyPhone[getFailCode(res) || 'error'],
		})
		return ({
			field: 'formError',
			error: message.profile.verifyPhone[getFailCode(res) || 'error'],
		})
	},
	target: form.setOrDeleteError,
});

sample({
	source: verificationModel.$verifyToken,
	clock: resend,
	fn: (verifyToken) =>
	({
		verifyToken,
	} as ResendSMSRequest),
	target: resendFx,
});

sample({
	clock: resendFx.doneData,
	fn: ({ verifyToken }) => verifyToken,
	target: verificationModel.$verifyToken,
});

/**
 * Обработка аналитики
 */
sample({
	clock: verifyFx.doneData,
	target: handleVerifyAnalyticsFx,
});

export const model = {
	form,
	resend,
	verifyFx,
	verifyLoginFx,
	$resendPending: resendFx.pending,
	$verifyPending: verifyFx.pending,
};
