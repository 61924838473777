import { fetcher } from "api/setupAxios";
import { ACCOUNT_URL } from "../constants";


export const logout = () =>
	fetcher({
		method: 'POST',
		body: {},
		url: `${ACCOUNT_URL}/logout/`,
		authorization: true,
	});
