import { useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useUnit } from 'effector-react';
import { orderCabinsModel } from 'entities/order';
// import { PreviewPassenger } from 'shared/lib/types';
// import { Button } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { DiscountsBlock } from './discounts-block';
import styles from './styles.module.scss';
import { PreviewPassenger } from 'config/types/prices';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';

interface DiscountsProps {
	className?: string;
}

export const Discounts = ({ className }: DiscountsProps) => {
	const cabins = useUnit(orderCabinsModel.$cabins);
	const [isExpanded, setIsExpanded] = useState(true);
	const passengers = cabins
		.flatMap((cabin) => cabin.passengers)
		.filter((passenger): passenger is PreviewPassenger => passenger !== undefined);

	const allDiscounts = passengers
		.flatMap(
			({ discounts, firstName, lastName, middleName }, index) =>
				discounts?.map((item) => ({
					...item,
					passenger:
						[lastName, firstName, middleName].filter(Boolean).join(' ') || `Tурист ${index + 1}`,
				})) || [],
		)
		.map(({ title, value, price, id, passenger }) => ({
			id,
			amount: Number(price),
			discount: value?.toString(),
			title,
			passenger,
		}));

	const hasDiscounts = !!allDiscounts.length;

	if (!hasDiscounts) {
		return null;
	}

	return (
		<div>
			<div className={styles.buttonWrapper}>
				<Button
					className={styles.button}
					contextClassName={styles.button__content}
					btnType="textBlack"
					onClick={() => setIsExpanded((isOpen) => !isOpen)}
				>
					Скидки
					<Icon
						id="chevron-down"
						width={20}
						height={20}
						className={classnames(styles.button__icon, {
							[styles.button__iconUp]: isExpanded,
						})}
					/>
				</Button>
			</div>

			<AnimateHeight height={isExpanded ? 'auto' : 0} duration={400}>
				<div className={styles.wrapper}>
					<DiscountsBlock selectedDiscounts={allDiscounts} />
				</div>
			</AnimateHeight>
		</div>
	);
};
