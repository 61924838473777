import { createEvent, createStore, sample } from 'effector';

interface VerificationItem {
	type: 'phone' | 'email';
	value: string;
}

const reset = createEvent();
const $verificationItem = createStore<Nullable<VerificationItem>>(null).reset(reset);
const $encodedVerificationItemValue = $verificationItem.map((item) => {
	if (!item || !item.value) {
		return null;
	}

	return item.value;
});
const $verifyToken = createStore<Nullable<string>>(null);

const $captchaToken = createStore<Nullable<string>>(null);
const setCaptchaToken = createEvent<string>();

sample({
	clock: setCaptchaToken,
	target: $captchaToken,
});

export const verificationModel = {
	$verifyToken,
	$verificationItem,
	$captchaToken,
	$encodedVerificationItemValue,
	reset,
	setCaptchaToken,
};
