import { createFileRoute, useLocation, useSearch } from '@tanstack/react-router';
import { useUnit } from 'effector-react';
import Authorization from 'layout/components/auth/Authorization';
import { useCheckAuth } from 'layout/components/auth/load-profile';
import { profileModel } from 'layout/components/auth/profile';
import { useEffect } from 'react';
import 'shared/styles/index.scss';
import { routerModel } from 'utils/router';

export const Route = createFileRoute('/login')({
    context(ctx) {
        console.log('route login func', ctx)
    },
    component: Auth,
})


function Auth() {
    const sParams: Record<string, string> = useSearch({ strict: false })
    const location = useLocation()

    const { state, emailToken, error } = sParams

    const profile = useCheckAuth()

    if (profile.authorized) {
        routerModel.pushAsA("/")
    }



    return (profile.hasToken && profile.loading) || profile.authorized
        ? <p>Загрузка...</p>
        : (
            <Authorization
                initialState={state as string | undefined}
                emailToken={emailToken as string | undefined}
                error={Array.isArray(error) ? error[0] : error}
            />
        )
}