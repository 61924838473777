import { createFileRoute, useLocation, useSearch } from '@tanstack/react-router'
import { Main } from 'layout/layouts/main'
import { OrderConfirmation } from 'layout/layouts/widgets/order/order-confirmation'

export const Route = createFileRoute('/order/confirmation/')({
  component: () => <Confirmation />,
})

function Confirmation() {
  // const sParams: Record<string, string> = useSearch({ strict: false })
  // // const ctx = useRouteContext({ from: '/login' })
  // //     console.log('route login',ctx)
  // const location = useLocation()

  // console.log('route location', location)
  // const { state, emailToken, error } = sParams
  // console.log(state)

  return (<OrderConfirmation />)
}
