import { ProfileModel, ProfileUpdateModel } from "config/types/personal";
import { ACCOUNT_URL } from "../constants";
import { fetcher } from "api/setupAxios";


export const getProfile = () =>
	fetcher<ProfileModel>({
		url: `${ACCOUNT_URL}/profile/`,
		authorization: true,
	});

export interface UpdateProfileParams {
	data: ProfileUpdateModel;
}

export const updateProfile = ({ data }: UpdateProfileParams) =>
	fetcher({
		url: `${ACCOUNT_URL}/profile/`,
		body: data,
		authorization: true,
		method: 'PUT',
	});
