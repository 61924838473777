// import { fetcher } from 'shared/api/config';
// import { REQUEST_ENDPOINT, REQUEST_BITRIX_ENDPOINT } from 'shared/config';
// import { CreateRequestRequest, CreateRequest201Response } from 'shared/lib/types/prices';

import { fetcher } from "api/setupAxios";
import { REQUEST_BITRIX_ENDPOINT, REQUEST_ENDPOINT } from "config/constants/order";
import { CreateRequestRequest, CreateRequest201Response } from "config/types/prices";

export const request = (body: CreateRequestRequest) =>
	fetcher<CreateRequest201Response>({
		url: `${REQUEST_ENDPOINT}`,
		method: 'POST',
		body,
		authorization: true,
	});

export const requestBitrix = (body: CreateRequestRequest) =>
	fetcher<CreateRequest201Response>({
		url: `${REQUEST_BITRIX_ENDPOINT}`,
		method: 'POST',
		body,
		authorization: true,
	});
