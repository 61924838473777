import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useUnit } from 'effector-react';
// import { Surcharge } from 'features/edit-cart-cabin/ui/surcharge';
// import { CabinHeader } from 'entities/cabin';
import { CartItem, cartModel } from 'entities/cart';
import { orderCabinsModel } from 'entities/order';
// import { Button, Skeleton } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { Place } from '../place';

import { ReplacePassengersModal } from '../replace-passengers-modal';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Skeleton } from 'layout/components/special/skeleton';
import { Surcharge } from '../surcharge';
import { CabinHeader } from 'entities/cabin';

interface CabinProps extends CartItem {
	allowRemove?: boolean;
}

export const Cabin = ({ places, id, name, category, status, allowRemove }: CabinProps) => {
	const [updatePassengerType, cart, cabins] = useUnit([
		cartModel.updatePassengerType,
		cartModel.$cart,
		orderCabinsModel.$cabins,
	]);

	const cabin = cabins.find(({ id: currentId }) => currentId === id);
	const cartCabin = cart.find(({ id: currentId }) => currentId === id);

	const [isExpanded, setIsExpanded] = useState<boolean>(true);

	const cabinRef = useRef<HTMLDivElement>(null);

	const handleExpand = () => {
		if (isExpanded && cabinRef.current) {
			cabinRef.current.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}

		setIsExpanded((prev) => !prev);
	};

	if (!cabin || !cartCabin) {
		return <Skeleton className={styles.skeleton} height={700} width="100%" />;
	}
	const realPassengers = cabin.passengers?.filter(({ type }) => `${type}` !== '3');
	const occupiedPlaces = realPassengers?.length;
	const totalPlaces = cartCabin.places.main.length;

	return (
		<div className={styles.cabin} ref={cabinRef}>
			<CabinHeader
				changePlaceType={updatePassengerType}
				places={places}
				id={id}
				name={name}
				category={category}
				status={status}
				size="lg"
				isEditCartCabin
				allowRemove={allowRemove}
			/>
			<div>
				<AnimateHeight height={isExpanded ? 'auto' : 0} duration={400}>
					<div className={styles.places}>
						{cabin.passengers &&
							cabin.passengers.map((passenger, index) => (
								<Place
									// eslint-disable-next-line react/no-array-index-key
									key={index}
									index={index}
									cabinId={cabin.id}
									childrenAge={cabin.childrenAge}
									childrenDiscount={cabin.childrenDiscount}
									surcharge={cabin.surcharge as number}
									occupiedPlaces={occupiedPlaces}
									totalPlaces={totalPlaces}
									{...passenger}
								/>
							))}
					</div>
				</AnimateHeight>
				{occupiedPlaces !== 1 && totalPlaces && Boolean(cabin.surcharge) && (
					<Surcharge
						places={occupiedPlaces}
						total={totalPlaces}
						surcharge={cabin.surcharge as number}
					/>
				)}

				<Button
					className={styles.button}
					contextClassName={styles.button__content}
					btnType="text"
					onClick={handleExpand}
				>
					{isExpanded ? 'Свернуть' : 'Развернуть'}
					<Icon
						id="chevron-down"
						width={20}
						height={20}
						className={classnames(styles.button__icon, {
							[styles.button__iconUp]: isExpanded,
						})}
					/>
				</Button>
			</div>
			<ReplacePassengersModal />
		</div>
	);
};
