/* eslint-disable import/no-extraneous-dependencies */
import { useRef } from 'react';
import {
	CalendarDate,
	getLocalTimeZone,
	isSameDay,
	isSameMonth,
	today,
} from '@internationalized/date';
import classnames from 'classnames';
import { useCalendarCell } from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';
// import { Typo } from 'shared/ui/atoms';
import { useScrollToCellIntoView } from '../lib';
import style from './style.module.scss';
import { Typo } from 'layout/components/typo/ui';

interface CalendarCellProps {
	date: CalendarDate;
	state: CalendarState | RangeCalendarState;
	currentMonth: CalendarDate;
	variant?: 'large';
}

export const CalendarCell = ({ state, date, currentMonth, variant }: CalendarCellProps) => {
	const ref = useRef<Nullable<HTMLDivElement>>(null);
	const { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, formattedDate } =
		useCalendarCell({ date }, state, ref);

	const isOutsideMonth = !isSameMonth(currentMonth, date);

	const { highlightedRange } = state as RangeCalendarState;
	const isSelectionStart = highlightedRange ? isSameDay(date, highlightedRange.start) : isSelected;
	const isSelectionEnd = highlightedRange ? isSameDay(date, highlightedRange.end) : isSelected;

	const isToday = isSameDay(date, today(getLocalTimeZone()));

	const inRange = highlightedRange?.end &&
		highlightedRange?.end?.compare(date) >= 0 && highlightedRange?.start?.compare(date) < 0;

	const designToken = variant === 'large' ? 'text-l' : 'input-text-s';
	const hiddenCell = variant !== 'large' && isOutsideVisibleRange && !inRange;

	useScrollToCellIntoView({
		ref,
		enable: variant === 'large',
		isToday,
		isSelectionStart: isSelectionStart && !isOutsideMonth,
	});

	return (
		<Typo as="td" design={designToken} {...cellProps}>
			<div
				{...buttonProps}
				// tabIndex={undefined} решает баг https://infoflot.jetbrains.space/p/infoflot/issues/2415
				tabIndex={undefined}
				ref={ref}
				hidden={hiddenCell}
				className={classnames(style.day, variant && style[variant], {
					[style.isRange]: isSelected || inRange,
					[style.isSelected]: isSelectionStart || isSelectionEnd,
					[style.isSelectionStart]: isSelectionStart,
					[style.isSelectionEnd]: isSelectionEnd,
					[style.isDisabled]: isDisabled,
					[style.isOutsideVisibleRange]: isOutsideVisibleRange || isOutsideMonth,
				})}
			>
				<span>{formattedDate}</span>
			</div>
		</Typo>
	);
};
