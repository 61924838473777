import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { getJWT } from 'api';
import {model as loadProfileModel} from './model'
import { profileModel } from '../profile';
import { useIsomorphicLayoutEffect } from 'utils/hooks/use-isomorphic-layout-effect';

export const useLoadProfileFromLocalStorage = () => {
	const loadProfile = useUnit(loadProfileModel.loadProfile);

	useEffect(() => {
		const access = getJWT();

		if (access) {
			loadProfile({ access });
		}
	}, [loadProfile]);
};

export const useCheckAuth = () => {
	const access = getJWT();
	const profile = useUnit(profileModel.$profile);
	const status = useUnit(loadProfileModel.$status);
	const errorCode = useUnit(loadProfileModel.$errorCode);
	

	const [hasToken, setHasToken] = useState<Nullable<boolean>>(null);

	useIsomorphicLayoutEffect(() => {
		setHasToken(access !== null);
	}, [access]);

	return {
		hasToken,
		authorized: Boolean(profile) && !errorCode,
		loading: status === 'pending' || status === 'initial',
		errorCode,
	};
};
