import { AxiosResponse } from 'axios';
import { status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { personalRequests } from 'store/auth/api';
import { getFailCode, setJWT } from 'api';
import { profileModel } from '../profile';


const loadProfile = createEvent<{
	access: string;
	refresh?: string;
}>();

const loadProfileFx = createEffect(personalRequests.getProfile);
const setJWTFx = createEffect(setJWT);

const $status = status({
	effect: loadProfileFx,
	defaultValue: 'pending',
});
const $errorCode = createStore<Nullable<AxiosResponse['data']>>(null);

sample({
	clock: loadProfile,
	target: setJWTFx,
});

sample({
	clock: setJWTFx.done,
	target: loadProfileFx,
});

sample({
	clock: loadProfile,
	fn: ({ access }) => access,
	target: profileModel.$accessToken,
});

sample({
	clock: loadProfileFx.doneData,
	target: profileModel.$profile,
});

sample({
	clock: loadProfileFx.done,
	fn: () => null,
	target: $errorCode,
});

sample({
	clock: loadProfileFx.failData,
	fn: (error) => getFailCode(error),
	target: $errorCode,
});

sample({
	clock: status({ effect: loadProfileFx }),
	target: profileModel.$profileStatus,
});

export const model = {
	loadProfile,
	$status,
	$errorCode,
};
