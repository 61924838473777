import React from 'react';
import { Item } from 'react-stately';
import { useUnit } from 'effector-react';
import styles from './../styles.module.scss';
import { convertToString } from 'entities/cabin-booking-cards/ui/cabin-description-modal/lib';
import { requestModel } from 'entities/order';
import { getPlaceTypes, ADULT_PLACE_TYPES } from 'config/constants/cabin';
import { message } from 'config/constants/message';
import { Select } from 'layout/components/inputs/selects/select';
import { Skeleton } from 'layout/components/special/skeleton';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { Price } from 'layout/components/view/price';
// import { getPlaceTypes, ADULT_PLACE_TYPES } from 'shared/config';
// import { message } from 'shared/lib/message';
// import { Alert, Price, Skeleton, Typo } from 'shared/ui/atoms';
// import { Select } from 'shared/ui/molecules';

interface TypeControlsProps {
	total?: number;
	type?: number;
	hasChild?: boolean;
	pending?: boolean;
	toggleChild: () => void;
	changeType: (type: number) => void;
	adultOnly?: boolean;
	childrenAge?: number;
	childrenDiscount?: boolean;
}

export const TypeControls = ({
	type,
	total,
	changeType,
	pending,
	childrenAge,
	childrenDiscount,
	adultOnly = false,
}: TypeControlsProps) => {
	const bitrixRequest = useUnit(requestModel.requestBitrix);

	if (pending) {
		return <Skeleton height={56} width={256} />;
	}

	const placeTypes = getPlaceTypes(childrenAge, childrenDiscount);
	const availableTypes = adultOnly ? ADULT_PLACE_TYPES : placeTypes;

	return (
		<div>
			<div className={styles.typeControls}>
				{type !== undefined && total !== undefined && (
					<Select
						name="place"
						label={availableTypes[convertToString(type)]}
						selectedKeys={new Set([convertToString(type)])}
						isRequired={false}
						customValue={<Price as="span" total={total} />}
						className={styles.select}
						classNameTrigger={styles.selectTrigger}
						alwaysShowChevron
						onSelectionChange={(keys) => {
							const keysArray = keys instanceof Set ? ([...keys] as string[]) : [keys];
							const value = keysArray.length ? keysArray[0] : null;
							if (value) {
								changeType(Number(value));
								bitrixRequest();
							}
						}}
					>
						{Object.keys(availableTypes).map((key) => (
							<Item key={key}>{availableTypes[key]}</Item>
						))}
					</Select>
				)}
			</div>
			{`${type}` === '2' ||
				(`${type}` === '5' && (
					<div className={styles.alertWrapper}>
						{`${type}` === '2' && (
							<Alert design="text" type="warning" className={styles.alert}>
								<Typo design="text-s">{message.order.passengerTypeDiscount.child}</Typo>
							</Alert>
						)}
						{`${type}` === '5' && (
							<Alert design="text" type="warning" className={styles.alert}>
								<Typo design="text-s">{message.order.passengerTypeDiscount.pensioner}</Typo>
							</Alert>
						)}
					</div>
				))}
		</div>
	);
};
