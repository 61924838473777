import React, { memo } from 'react';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-react-form';
// import { OrderStepControls } from 'features/order/order-step-controls';
// import { OrderRoutes } from 'shared/config';
import { model as guestData } from '../../model/guest-data';
import styles from '../styles.module.scss';
import { OrderRoutes } from 'config/constants/order';
import { OrderStepControls } from 'layout/components/order/order-step-controls';

export const OrderCustomerControls = memo(() => {
	const [formState] = useUnit([guestData.form.$form]);
	const { submit } = useForm({
		form: guestData.form,
		resetUnmount: false,
	});

	const prevStep = 1;
	const prevHref = OrderRoutes.Tourists;

	return (
		<OrderStepControls
			prevStep={prevStep}
			nextStepText="Продолжить"
			prevHref={prevHref}
			className={styles.customerControls}
			nextHref={OrderRoutes.Confirmation}
			nextStepDisabled={formState.hasError}
			nextStepOnClick={submit}
		/>
	);
});
