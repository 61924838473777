/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useEffect, useRef } from 'react';
import { createCalendar, today, getLocalTimeZone, CalendarDate } from '@internationalized/date';
import { useRangeCalendar } from 'react-aria';
import { useRangeCalendarState } from 'react-stately';
// import { DEFAULT_MONTHS_COUNT } from 'shared/config';
// import { Scroller, ScrollerCallbackFn } from 'shared/ui/molecules';
import { CalendarGridList } from './calendar-grid-list';
import type { RangeCalendarProps } from './range-calendar';
import style from './style.module.scss';
import { DEFAULT_MONTHS_COUNT } from 'config/constants/search';
import { ScrollerCallbackFn, Scroller } from 'layout/components/special/scroller';

interface RangeCalendarMobileProps extends RangeCalendarProps {
	onScroll?: ScrollerCallbackFn;
	months?: number;
	minMonth?: CalendarDate;
	maxMonth?: CalendarDate;
}

const todayCalendarDate = today(getLocalTimeZone());

export const RangeCalendarMobile: FC<RangeCalendarMobileProps> = ({
	dateSelector,
	onScroll,
	months = DEFAULT_MONTHS_COUNT,
	...props
}) => {
	const ref = useRef<HTMLDivElement>(null);

	const {
		minMonth = todayCalendarDate,
		maxMonth = todayCalendarDate.add({ months: DEFAULT_MONTHS_COUNT }),
	} = props;

	const state = useRangeCalendarState({
		...props,
		locale: 'ru-RU',
		createCalendar,
		visibleDuration: { months },
	});

	useEffect(() => {
		if (state.highlightedRange) {
			props.onChange?.(state.highlightedRange);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(state.highlightedRange)]);

	const minValueStamp = useRef(minMonth.toString());

	useEffect(() => {
		const newMinValueStamp = minMonth.toString();

		if (minValueStamp.current !== newMinValueStamp) {
			state.focusSectionStart();
			state.focusPreviousPage();

			setTimeout(() => {
				ref?.current?.parentElement?.scrollIntoView({ block: 'start' });
			}, 10);

			minValueStamp.current = newMinValueStamp;
		}
	}, [minMonth, maxMonth, state]);

	const { calendarProps } = useRangeCalendar(props, state, ref);

	return (
		<Scroller className={style.calendarScrollable} onScroll={onScroll}>
			<div ref={ref} {...calendarProps}>
				<CalendarGridList months={months} state={state} />
			</div>
		</Scroller>
	);
};
