/* eslint-disable import/no-extraneous-dependencies */
import { ReactNode, useEffect, useRef } from 'react';
import { AriaMenuItemProps } from '@react-aria/menu';
import classnames from 'classnames';
import { useButton, useMenuTrigger } from 'react-aria';
import { MenuTriggerProps, useMenuTriggerState } from 'react-stately';
// import { Popover } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { MultiSelectProps } from 'shared/ui/molecules/select/lib';
import { Menu } from '../menu';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Popover } from 'layout/components/special/popover';
import { MultiSelectProps } from 'layout/components/inputs/selects/select/lib';

interface MenuButtonProps extends MenuTriggerProps, AriaMenuItemProps, MultiSelectProps<object> {
	label: ReactNode;
	className?: string;
	classNamePopover?: string;
	hiddenChevron?: boolean;
	fixedPosition?: boolean;
	active?: boolean;
}

export const MenuButton = ({
	label,
	className,
	classNamePopover,
	hiddenChevron,
	fixedPosition,
	active,
	...props
}: MenuButtonProps) => {
	const state = useMenuTriggerState(props);

	const ref = useRef<HTMLButtonElement>(null);
	const popoverRef = useRef(null);

	const { menuTriggerProps, menuProps } = useMenuTrigger<object>({}, state, ref);
	const { buttonProps } = useButton(menuTriggerProps, ref);

	useEffect(() => {
		if (fixedPosition && active === false) {
			state.close();
		}
	}, [fixedPosition, active, state]);

	return (
		<>
			<button
				ref={ref}
				className={classnames(styles.menuButton, { [styles.isExpanded]: state.isOpen }, className)}
				{...buttonProps}
			>
				{label}
				{!hiddenChevron && <Icon id="chevron-down" className={styles.icon} />}
			</button>
			{state.isOpen && (
				<Popover
					state={state}
					triggerRef={ref}
					popoverRef={popoverRef}
					className={classNamePopover}
					fixedPosition={fixedPosition}
					offset={12}
					placement="bottom start"
					isNonModal
				>
					<Menu {...props} {...menuProps}>
						{props.children}
					</Menu>
				</Popover>
			)}
		</>
	);
};
