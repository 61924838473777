import { combineEvents, status } from 'patronum';
import { createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { cartModel } from 'entities/cart';
import { PreviewPassenger, PreviewCabin, GetPricePreviewRequestCabinsInner, GetPricePreviewRequestCabinsInnerPassengersInnerTypeEnum, GetPricePreviewRequestCabinsInnerPassengersInner, PreviewPassengerInCabin } from 'config/types/prices';
import { modalFactory } from 'layout/components/modal';
import { isValidISO } from 'utils/date';
import { sessionStorageFactory } from 'utils/storage';
// import {
// 	GetPricePreviewRequestCabinsInner,
// 	GetPricePreviewRequestCabinsInnerPassengersInner,
// 	GetPricePreviewRequestCabinsInnerPassengersInnerTypeEnum,
// 	PreviewCabin,
// 	PreviewPassenger,
// 	PreviewPassengerInCabin,
// } from 'shared/lib/types';
// import { isValidISO, sessionStorageFactory } from 'shared/lib/utils';
// import { modalFactory } from 'shared/model';

interface UpdatePassengerParams {
	passenger: PreviewPassenger;
	passengerIndex: number;
	cabinId: PreviewCabin['id'];
}

interface SelectDiscountParams {
	discount: string;
	passengerIndex: number;
	cabinId: PreviewCabin['id'];
}

interface CabinWithSelectedDiscounts extends PreviewCabin {
	passengers?: PreviewPassenger[];
}

const Gate = createGate();

const $cabins = createStore<CabinWithSelectedDiscounts[]>([]);
const { openModal, closeModal, ModalGate } = modalFactory();
const changePassengerOrder = createEvent();

const updatePassenger = createEvent<UpdatePassengerParams>();
const getPrice = createEvent();
const changeType = createEvent<{ index: number; type: Nullable<number>; cabinId: number }>();
const selectDiscount = createEvent<SelectDiscountParams>();
const checkBirthday = createEvent<boolean>();

const { parseDataFromSession, parseDataFromSessionFx, removeFromSessionFx } = sessionStorageFactory<
	CabinWithSelectedDiscounts[]
>('orderCabins', $cabins);
const $parseDataFromSessionStatus = status({ effect: parseDataFromSessionFx });

sample({
	clock: cartModel.$cart,
	filter: (cart) => cart.length === 0,
	target: removeFromSessionFx,
});

sample({
	clock: cartModel.$activeCruise,
	target: removeFromSessionFx,
});

sample({
	clock: Gate.open,
	target: [parseDataFromSession, cartModel.getCartPrices],
});

sample({
	clock: changePassengerOrder,
	target: openModal,
});

/**
 * Если в session storage были сохранены каюты, делаем запрос цены с ними
 */
sample({
	source: {
		cruiseId: cartModel.$activeCruise,
		cabins: $cabins,
	},
	clock: parseDataFromSessionFx.doneData,
	filter: (_, parsedCabins) => Array.isArray(parsedCabins) && parsedCabins.length > 0,
	fn: ({ cruiseId, cabins }, parsedCabins) => {
		const cabinsForRequest = parsedCabins.length > 0 ? parsedCabins : cabins;

		return {
			cruiseId: `${cruiseId}`,
			body: {
				cabins: cabinsForRequest.map(({ id, passengers }) => ({
					id,
					passengers,
				})) as GetPricePreviewRequestCabinsInner[],
			},
		};
	},
	target: cartModel.getCartPricesFx,
});

sample({
	source: {
		cruiseId: cartModel.$activeCruise,
		cabins: $cabins,
	},
	clock: getPrice,
	fn: ({ cruiseId, cabins }) => ({
		cruiseId: `${cruiseId}`,
		body: {
			cabins: cabins.map(({ id, passengers }) => ({
				id,
				passengers,
			})) as GetPricePreviewRequestCabinsInner[],
		},
	}),
	target: cartModel.getCartPricesFx,
});

/**
 * Обновление стора с каютами, при изменении стора с ценой
 * Срабатывает только если были загружены каюты из session storage
 */
sample({
	source: {
		cabins: $cabins,
		parseDataStatus: $parseDataFromSessionStatus,
	},
	clock: cartModel.$totalPrice,
	filter: ({ cabins, parseDataStatus }) => Boolean(cabins) && parseDataStatus === 'done',
	fn: ({ cabins: source }, price) => {
		const cabins = price?.cabins || [];

		/* *
		 * Добавляю данные к пассажирам,
		 * если они были добавлены ранее на фронте
		 */
		if (source && source.length) {
			return cabins.map((cabin) => {
				const sourceCabin = source.find((sourceCab) => sourceCab.id === cabin.id);

				return {
					...cabin,
					passengers: cabin.passengers?.map((passenger, passengerIndex) => {
						const sourcePassengers = sourceCabin?.passengers;
						const sourcePassengerByIndex = sourcePassengers && sourcePassengers[passengerIndex];

						// При изменении порядка на беке - игнорим данные в сторадже, чтобы форма не была заполнена неактуальными данными
						const didOrderChange =
							passenger.placePrev !== null && passenger.placePrev !== passengerIndex;

						if (didOrderChange) {
							return passenger;
						}

						return {
							placePrev: passenger.placePrev,
							placeType: passenger.placeType,
							type: passenger.type,
							firstName: sourcePassengerByIndex?.firstName ?? passenger.firstName,
							middleName: sourcePassengerByIndex?.middleName || passenger.middleName,
							lastName: sourcePassengerByIndex?.lastName || passenger.lastName,
							birthday: sourcePassengerByIndex?.birthday || passenger.birthday,
							citizenship: sourcePassengerByIndex?.citizenship || passenger.citizenship,
							gender: sourcePassengerByIndex?.gender || passenger.gender,
							passportSeries: sourcePassengerByIndex?.passportSeries || passenger.passportSeries,
							passportNumber: sourcePassengerByIndex?.passportNumber || passenger.passportNumber,
							documentType: sourcePassengerByIndex?.documentType || passenger.documentType,
							passportIssued: sourcePassengerByIndex?.passportIssued || passenger.passportIssued,
							passportIssuedDate:
								sourcePassengerByIndex?.passportIssuedDate || passenger.passportIssuedDate,
							children: sourcePassengerByIndex?.children || passenger.children,
							default: passenger.default,
							total: passenger.total,
							discounts: passenger.discounts,
							availableDiscounts: passenger.availableDiscounts,
							excursions: sourcePassengerByIndex?.excursions ?? passenger.excursions ?? [],
							selectedDiscounts:
								sourcePassengerByIndex?.selectedDiscounts ?? passenger.selectedDiscounts ?? [],
						} as PreviewPassenger;
					}),
				};
			});
		}

		return cabins;
	},
	target: $cabins,
});

sample({
	source: {
		cabins: $cabins,
		parseDataStatus: $parseDataFromSessionStatus,
	},
	clock: cartModel.$totalPrice,
	filter: ({ cabins, parseDataStatus }, newData) => {
		let isChangedOrder = false;
		if (!newData?.cabins) {
			return isChangedOrder;
		}
		newData.cabins.forEach((newCabin) => {
			newCabin.passengers?.forEach((passenger, passengerIndex) => {
				if (passenger.placePrev !== null && passenger.placePrev !== passengerIndex) {
					isChangedOrder = true;
				}
			});
		});

		return Boolean(cabins) && parseDataStatus === 'done' && isChangedOrder;
	},
	target: changePassengerOrder,
});

const getPassenger = (
	cabins: CabinWithSelectedDiscounts[],
	{ cabinId, passengerIndex }: Omit<UpdatePassengerParams, 'passenger'>,
) => {
	const index = cabins.findIndex(({ id }) => id === cabinId);

	if (index === -1 || !cabins[index]) {
		return null;
	}

	const cabin = { ...cabins[index] };

	if (!Array.isArray(cabin.passengers) || passengerIndex >= cabin.passengers.length) {
		return null;
	}

	return cabin.passengers[passengerIndex];
};

const updatePassengerFn = (
	cabins: CabinWithSelectedDiscounts[],
	{ cabinId, passenger, passengerIndex }: UpdatePassengerParams,
) => {
	const index = cabins.findIndex(({ id }) => id === cabinId);

	// Ничего не меняем, если нет каюты с таким id
	if (index === -1 || !cabins[index]) {
		return cabins;
	}

	const cabin = { ...cabins[index] };

	// Ничего не меняем, если нет пассажиров или невалидный индекс
	if (!Array.isArray(cabin.passengers) || passengerIndex >= cabin.passengers.length) {
		return cabins;
	}

	const updatedCabins = [...cabins];
	const updatedPassenger = [...cabin.passengers];
	const oldPassenger = cabin.passengers[passengerIndex];

	const newValues: PreviewPassenger = {
		...oldPassenger,
		...passenger,
		children:
			oldPassenger.children || passenger.children
				? { ...oldPassenger.children, ...passenger.children }
				: undefined,
	};

	updatedPassenger.splice(passengerIndex, 1, newValues);

	cabin.passengers = [...updatedPassenger];
	updatedCabins.splice(index, 1, cabin);

	return updatedCabins;
};

const checkBirthdayFn = (
	cabins: CabinWithSelectedDiscounts[],
	{ cabinId, passenger, passengerIndex }: UpdatePassengerParams,
) => {
	const index = cabins.findIndex(({ id }) => id === cabinId);
	const cabin = { ...cabins[index] };

	if (
		index === -1 ||
		!cabins[index] ||
		!Array.isArray(cabin.passengers) ||
		passengerIndex >= cabin.passengers.length
	) {
		return false;
	}

	if (!isValidISO(passenger.birthday)) {
		return false;
	}

	return cabin.passengers[passengerIndex].birthday !== passenger.birthday;
};

sample({
	source: {
		cabins: $cabins,
		cruiseId: cartModel.$activeCruise,
	},
	clock: changeType,
	fn: ({ cabins, cruiseId }, { cabinId, type, index }) => {
		const cabinIndex = cabins.findIndex(({ id }) => id === cabinId);

		let updatedCabins = cabins.map(({ id, passengers }) => ({
			id,
			passengers: passengers?.map(({ selectedDiscounts, type: passengerType, ...passenger }) => ({
				...passenger,
				type: passengerType as GetPricePreviewRequestCabinsInnerPassengersInnerTypeEnum | undefined,
				selectedDiscounts: selectedDiscounts || [],
			})) as GetPricePreviewRequestCabinsInnerPassengersInner[],
		}));

		const cabin = updatedCabins[cabinIndex];

		if (cabin && cabin.passengers) {
			updatedCabins = [
				...updatedCabins.slice(0, cabinIndex),
				{
					...cabin,
					passengers: [
						...cabin.passengers.slice(0, index),
						{
							...cabin.passengers[index],
							type,
							children: type === 1 ? cabin.passengers[index].children : {},
						},
						...cabin.passengers.slice(index + 1),
					].filter(
						(passenger): passenger is GetPricePreviewRequestCabinsInnerPassengersInner =>
							passenger.type !== null,
					),
				},
				...updatedCabins.slice(cabinIndex + 1),
			].map(({ id, passengers }) => ({
				id,
				passengers,
			}));
		}

		return {
			cruiseId: `${cruiseId}`,
			body: { cabins: updatedCabins as GetPricePreviewRequestCabinsInner[] },
		};
	},
	target: [cartModel.getCartPricesFx, cartModel.$updatedCabins],
});

sample({
	clock: updatePassenger,
	source: $cabins,
	fn: checkBirthdayFn,
	target: checkBirthday,
});

sample({
	source: $cabins,
	clock: updatePassenger,
	fn: updatePassengerFn,
	target: $cabins,
});

const updatePrices = createEvent();
const updatePricesWithCabinsUpdates = combineEvents({ events: [$cabins.updates, updatePrices] });

sample({
	clock: checkBirthday,
	filter: (changed) => changed,
	target: updatePrices,
});

sample({
	source: {
		cruiseId: cartModel.$activeCruise,
	},
	clock: updatePricesWithCabinsUpdates,
	fn: ({ cruiseId }, [cabins]) => ({
		cruiseId: `${cruiseId}`,
		body: { cabins: cabins as GetPricePreviewRequestCabinsInner[] },
	}),
	target: cartModel.getCartPricesFx,
});

const mapPassengers = (cabins: PreviewCabin[]) => {
	const result: PreviewPassengerInCabin[][] = [];

	cabins.forEach(({ id: cabinId, passengers }) => {
		if (passengers) {
			result.push(
				passengers.map((data, passengerIndex) => ({
					cabinId,
					passengerIndex,
					data,
				})),
			);
		}
	});

	return result.flat() as PreviewPassengerInCabin[];
};

const $passengers = $cabins.map(mapPassengers);

/* *
 * Draft
 */
const $draft = createStore<PreviewCabin[]>([]);
const updatePassengerInDraft = createEvent<UpdatePassengerParams & { updateMainStore?: boolean }>();

const saveDraft = createEvent();
const restoreDraft = createEvent();

sample({
	clock: $cabins,
	target: $draft,
});

sample({
	clock: saveDraft,
	source: $draft,
	target: $cabins,
});

sample({
	clock: restoreDraft,
	source: $cabins,
	target: $draft,
});

sample({
	source: $draft,
	clock: updatePassengerInDraft,
	filter: (_, { updateMainStore }) => !updateMainStore,
	fn: updatePassengerFn,
	target: $draft,
});

sample({
	source: $draft,
	clock: updatePassengerInDraft,
	filter: (_, { updateMainStore }) => !!updateMainStore,
	fn: updatePassengerFn,
	target: $cabins,
});

sample({
	source: $cabins,
	clock: selectDiscount,
	fn: (source, { cabinId, passengerIndex, discount }) => {
		const passenger = getPassenger(source, {
			cabinId,
			passengerIndex,
		});

		if (!passenger) {
			return source;
		}

		const selectedDiscounts = passenger.selectedDiscounts || [];
		const isSelectedDiscount = selectedDiscounts.includes(discount);
		const newSelectedDiscounts = isSelectedDiscount
			? selectedDiscounts.filter((value) => value !== discount)
			: [...selectedDiscounts, discount];

		return updatePassengerFn(source, {
			cabinId,
			passengerIndex,
			passenger: {
				...passenger,
				selectedDiscounts: newSelectedDiscounts,
			},
		});
	},
	target: $cabins,
});

const $draftPassengers = $draft.map(mapPassengers);

export const model = {
	Gate,
	$cabins,
	$passengers,
	$draft,
	ModalGate,
	closeModal,
	getPrice,
	$draftPassengers,
	updatePassenger,
	changeType,
	updatePassengerInDraft,
	saveDraft,
	restoreDraft,
	updatePrices,
	selectDiscount,
	parseDataFromSession,
};
