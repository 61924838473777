import classnames from 'classnames';

import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Button } from 'layout/components/buttons/button/button';
import { State } from '../model__';

export const ScreenSwitcher = ({
	state,
	toState,
	isModalMode,
}: {
	state: State;
	toState: (state: State) => void;
	isModalMode?: boolean;
}) => {
	if (state !== 'register' && state !== 'authorization') {
		return null;
	}

	return (
		<div className={styles.switcher}>
			<Typo design={isModalMode ? 'text-l' : 'text-s'}>
				{state === 'register' ? 'Уже есть аккаунт?' : 'Нет аккаунта?'}
			</Typo>
			<Button
				className={styles.button}
				contextClassName={classnames(
					styles.button__context,
					isModalMode && styles.button__context_desing,
				)}
				size="large"
				btnType="text"
				onClick={() => {
					toState(state === 'register' ? 'authorization' : 'register');
					// setAnalytics(
					// 	state === 'register'
					// 		? ANALYTICS_TARGETS.signup.signup_have_account
					// 		: ANALYTICS_TARGETS.signin.signin_new_account,
					// );
				}}
			>
				{state === 'register' ? 'Войти' : 'Зарегистрироваться'}
			</Button>
		</div>
	);
};
