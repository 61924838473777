import { DEVELOPMENT_MODE, ISR_REGENERATION_TIME } from 'config/commonConstants';
import { allSettled, fork } from 'effector';
import { useGate, useUnit } from 'effector-react';
import { cruiseModel } from 'entities/cruises/model';
import { CartControlButton, cartControlModel } from 'layout/components/cruise/cart-control';
import { Main } from 'layout/layouts/main';
import { Cruise, cruiseContentModel } from 'layout/layouts/widgets/cruise';
import React, { FC, useEffect, useState } from 'react'
import { getCabins } from 'store/cruise/cruises/cabins';

// import { staticPropsFactory } from 'store/ssr';
import styles from 'entities/cabin-booking-cards/ui/cabin-description-modal/ui/cabin-description-modal-footer/styles.module.scss'
import { Footer } from 'entities/cabin-booking-cards/ui/cabin-description-modal/ui/cabin-description-modal/footer';
import { CruiseDetailsModel } from 'config/types/cabins/models/cruise-details';
import { CabinDescriptionModal, detailedCruiseCabinModel } from 'entities/cabin-booking-cards';
import { profileModel } from 'layout/components/auth/profile';
import { cartModel } from 'entities/cart';
import { detailedShipCabinModel } from 'entities/cabin2';
import { GetDetailedShipCabinRequest, detailedShipCabinRequest } from 'store/cruise/cruises/cabins/index';
import { CruiseCabin } from 'config/types/cabins/models/cruise-cabin-booking';
import { getPlaces } from 'entities/cabin-booking-cards/ui/cabin-description-modal/lib/get-places';
import { Typo } from 'layout/components/typo/ui';

interface ICardProps {
  cruiseId: string
  cabinId: string
}

// const getData = async () => {
//   const scope = fork();

//   // await allSettled(cruiseContentModel.getData, {
//   allSettled(cruiseContentModel.getData, {
//     scope,
//     // params: ctx,
//   }).then(d => {
//     console.log('getData', d)
//   })



// }

// нужно пойти от обратного. получаем каюты по данным о круизе, затем смотрим какие нам нужны массивы, грузим их
// по маршрутам инфофлота можно понять какие компоненты где что отрисовывают. можно отсоединить их от данных 
// (а лучше правильно вовремя заполнить данные и пользоваться встроенным стором)

let initGlobal = false

const Card: FC<ICardProps> = (props) => {
  const [init, setInit] = useState<boolean>(false)
  const [cruise, getCruise, getCabins, cabins] = useUnit([cruiseModel.$cruise, cruiseModel.getCruise, cruiseModel.getCabins, cruiseModel.$cabins])
  const [getDetailedCruiseCabinInfo, cruiseCabinInfo, status] = useUnit([detailedCruiseCabinModel.getDetailedCruiseCabinInfo, detailedCruiseCabinModel.$detailedCruiseCabinInfo, detailedCruiseCabinModel.$status])

  const profile = useUnit(profileModel.$profile);
  const authorized = Boolean(profile);

  useEffect(() => {
    if (props.cruiseId != undefined && props.cabinId != undefined && !(initGlobal || init)) {
      console.log('request', props.cruiseId, props.cabinId)
      setInit(true)
      initGlobal = true
      getCruise({ id: props.cruiseId })
      getDetailedCruiseCabinInfo({ cabinId: Number(props.cabinId), cruiseId: Number(props.cruiseId) })
    }
  }, [props.cruiseId, props.cabinId])

  useEffect(() => {
    if (init)
      initGlobal = false
  }, [init])

  /*
  
  // const [cruiseInfo, setCruiseInfo] = useState<Nullable<CruiseDetailsModel.CruiseInfo>>(null)
  // const [cabinInfo, setCabinInfo] = useState<Nullable<any>>(null)

  // detailedCruiseCabinModel?.initialLoadInfo(
  //   // {cabinId: props.cabinId, cruiseId: props.cruiseId}
  //   {
  //     cruiseId: Number(props.cruiseId),
  //     cabinId: Number(props.cabinId)
  //   }
  // )
  // detailedCruiseCabinModel.initialLoadInfo()
  //getDetailedCruiseCabinInfo({cabinId: props.cabinId, cruiseId: props.cruiseId})

  // const sss = cruiseModel.getCruise({ id: props.cruiseId })

  // const [initCabin] = useUnit([ detailedCruiseCabinModel.initialLoadInfo])
  // const [getDetailedCruiseCabinInfo, ] = useUnit([detailedCruiseCabinModel.getDetailedCruiseCabinInfo, ])
  // const [getCruiseContentData] = useUnit([ cruiseContentModel.getData])
  // const [getCruiseData,] = useUnit([cruiseContentModel.getData,])

  // useEffect(() => {
  //   const crInfo = cruiseModel.$cruise.getState()
  //   console.log('cruiseModel.getCruise useeffect', crInfo)
  //   setCruiseInfo(crInfo)
  // }, [cruiseModel.$cruise])

  // useEffect(() => {
  //   const crInfo = detailedCruiseCabinModel
  //   console.log('detailedCruiseCabinModel useeffect', crInfo)
  //   setCabinInfo(crInfo)
  // }, [detailedCruiseCabinModel])

  // const [add, remove, cart, bookingDisable, cruise] = useUnit([
  // 	model.addToCart,
  // 	model.removeFromCart,
  // 	cartModel.$cart,
  // 	cruiseModel.$bookingDisable,
  // 	cartModel.$activeCruise,
  // ]);

  // const isAdded = useMemo(
  // 	() => cart.some(({ id }) => id === cabin.id && cabin.cruiseId === cruise),
  // 	[cart, cabin.id, cabin.cruiseId, cruise],
  // );


  // const fastBookingModel = authorized ? bookingSupportModel : bookingSupportGuestModel;
  // const fastBooking = useUnit(fastBookingModel.openModal);

  // const { fullView, status, disabled } = props;
  // const isDisabled = status === 'disabled' || disabled;
  */

  /**
   * Добавляем в корзину, если ещё не добавлено. Удаляем, если добавлено.
   */
  // const handleClick = () => {
  // 	if (bookingDisable) {
  // 		fastBooking();

  // 		return;
  // 	}
  // 	if (!isAdded) {
  // 		add(cabin);
  // 		onClickAdd?.();
  // 	} else {
  // 		remove(cabin);
  // 	}
  // };

  // const tooltipText = isAdded ? 'Убрать из заказа' : 'Добавить в заказ';
  // const cartButton = (
  // 	<CartButton {...props} isAdded={isAdded} disabled={isDisabled} onClick={handleClick} />
  // );

  // if (fullView || isDisabled) {
  // 	return cartButton;
  // }
  // ------



  // useEffect(() => {
  //   getCruises().then(data => {
  //     console.log('cabins', data)
  //   })
  // }, [])

  // cruiseContentModel.getData({ id: props.cruiseId })
  // const getCruise = useUnit(cruiseModel.getCruise)
  // cruiseModel.getCruise({id: props.cruiseId})
  // const cruise = useUnit(cruiseModel.$cruise);
  // useGate(cruiseContentModel.Gate, { params: { id: `${cruise?.id}` } });
  // useGate(cruiseContentModel.Gate, { params: { id: `${props.cruiseId}` } });
  // getData()

  // const getStaticProps = staticPropsFactory(
  //   cruiseContentModel.getData,
  //   ISR_REGENERATION_TIME,
  //   cruiseModel.$status,
  //   cruiseContentModel.$redirect as any,
  // )

  // console.log('getStaticProps',getStaticProps)



  // для теста http://localhost:3000/cruises/410420

  return (
    <>
      {
        DEVELOPMENT_MODE
          ? <div>
            <div>==== DEBUG INFO ====</div>
            <div>ID: {props.cruiseId}  CABIN: {props.cabinId}</div>
            <div>NAME: {cruise?.beautifulName}</div>
            <div>CABINS: main: {cruiseCabinInfo?.places.main} Additional: {cruiseCabinInfo?.places.additional}</div>
            <div>auth tel: {profile?.phone}</div>
            <div>cabin info: {cruiseCabinInfo?.name}</div>
            <div>==== DEBUG INFO ====</div>
          </div>
          : null
      }
      {
        cruiseCabinInfo && cruise
          ? <CartControlButton
            className={styles.button}
            fullView
            cabin={{
              id: cruiseCabinInfo.id,
              cruiseId: cruise.id,
              name: cruise.name ?? "",
              category: cruiseCabinInfo.category,
              places: getPlaces(cruiseCabinInfo),
              status: cruiseCabinInfo.status,
              isBonusPayment: false,
            }}
            status={cruiseCabinInfo.status}
            disabled={status == "pending" || (!getPlaces(cruiseCabinInfo)?.additional?.length && !getPlaces(cruiseCabinInfo)?.main?.length)}
            onClickAdd={() => {
              // console.log("added to orders")
            }
              // setAnalytics(ANALYTICS_TARGETS.cruisepage.cruisepage_fullcard_add_to_card)
            }
          />
          : <Typo design="text-m">
            Загрузка...
          </Typo>

      }
    </>
  )
}

export default Card