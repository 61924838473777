// import { SearchFieldProps } from 'shared/lib/types';

import { SearchFieldProps } from "config/types/search";

export const emptySearchFields: Array<SearchFieldProps> = [
	{
		order: 0,
		keys: ['dateStartGte', 'dateStartLte'],
		type: 'date',
		icon: 'month',
		label: 'Дата отправления',
		placeholder: 'Не важно',
	},
	{
		order: 1,
		keys: ['lengthDaysMin', 'lengthDaysMax'],
		type: 'range',
		icon: 'calendar',
		label: 'Продолжительность',
		placeholder: 'Не важно',
		options: [],
	},
	{
		order: 2,
		keys: 'startCityIn',
		type: 'select',
		icon: 'location',
		label: 'Город отправления',
		placeholder: 'Не важно',
		multiple: true,
		options: [],
	},
	{
		order: 3,
		keys: 'routeIn',
		type: 'select',
		icon: 'flag',
		label: 'Популярные маршруты',
		placeholder: 'Не важно',
		multiple: true,
		options: [],
	},
	{
		order: 4,
		keys: 'shipIn',
		type: 'select',
		icon: 'frigate',
		label: 'Теплоход',
		placeholder: 'Не важно',
		multiple: true,
		options: [],
	},
];

export const search: Array<SearchFieldProps> = [
	{
		order: 0,
		keys: ['dateStart', 'dateEnd'],
		type: 'date',
		icon: 'month',
		label: 'Дата отправления',
		placeholder: 'Не важно',
		options: {
			min: '2023-02-07T00:00',
			max: '2024-11-07T00:00',
		},
	},
	{
		order: 1,
		keys: ['lengthDaysMin', 'lengthDaysMax'],
		type: 'range',
		icon: 'calendar',
		label: 'Продолжительность',
		placeholder: 'Не важно',
		options: [
			{
				min: 0,
				label: 'Любая',
			},
			{
				min: 0,
				max: 5,
				label: 'Менее 5 дней',
			},
			{
				min: 5,
				max: 7,
				label: 'От 5 до 7 дней',
			},
			{
				min: 8,
				max: 14,
				label: 'От 8 до 14 дней',
			},
			{
				min: 14,
				label: 'Более 14 дней',
			},
		],
	},
	{
		order: 2,
		keys: 'startCities',
		type: 'select',
		icon: 'location',
		label: 'Город отправления',
		placeholder: 'Не важно',
		multiple: true,
		options: [
			{
				id: null,
				label: 'Россия',
				children: [
					{
						id: 762,
						label: 'Москва',
					},
					{
						id: 763,
						label: 'Санкт-Петербург',
					},
					{
						id: 764,
						label: 'Воронеж',
					},
					{
						id: 765,
						label: 'Петрозаводск',
					},
				],
			},
		],
	},
	{
		order: 3,
		keys: 'routes',
		type: 'select',
		icon: 'flag',
		label: 'Популярные маршруты',
		placeholder: 'Не важно',
		multiple: true,
		options: [
			{
				id: 4,
				label: 'Золотое кольцо России',
			},
			{
				id: 76,
				label: 'Между Москвой и Санкт-Петербургом',
			},
			{
				id: 2,
				label: 'Золотая коллекция круизов',
			},
			{
				id: 3,
				label: 'Круизы выходного дня',
			},
			{
				id: 83,
				label: 'Майские праздники',
			},
			{
				id: 6,
				label: 'Валаам или Кижи',
			},
			{
				id: 7,
				label: 'Карелия',
			},
			{
				id: 21,
				label: 'Байкал',
			},
			{
				id: 11,
				label: 'На Соловецкие острова',
			},
			{
				id: 78,
				label: 'Экспедиционные круизы',
			},
			{
				id: 84,
				label: 'Обь и Иртыш',
			},
			{
				id: 41,
				label: 'Круизы в/из Санкт-Петербурга',
			},
			{
				id: 17,
				label: 'Круизы в Казань',
			},
			{
				id: 18,
				label: 'Круизы в/из Москвы',
			},
			{
				id: 96,
				label: 'Круизы в Ростов-на-Дону',
			},
			{
				id: 97,
				label: 'Круизы в Астрахань',
			},
			{
				id: 13,
				label: 'Круизы в Ростов-на-Дону и Астрахань',
			},
			{
				id: 12,
				label: 'Круизы в Пермь и/или Уфу',
			},
			{
				id: 77,
				label: 'Круизы из Нижнего Новгорода',
			},
			{
				id: 10,
				label: 'Московская кругосветка',
			},
			{
				id: 67,
				label: 'Татарстан',
			},
			{
				id: 42,
				label: 'Реки Сибири',
			},
			{
				id: 9,
				label: 'Вятка',
			},
			{
				id: 31,
				label: 'Волхов (до Старой Ладоги)',
			},
			{
				id: 22,
				label: 'Енисей',
			},
			{
				id: 14,
				label: 'Кама',
			},
			{
				id: 8,
				label: 'Ока',
			},
		],
	},
	{
		order: 4,
		keys: 'ships',
		type: 'select',
		icon: 'frigate',
		label: 'Теплоход',
		placeholder: 'Не важно',
		multiple: true,
		options: [
			{
				id: null,
				label: 'Теплоходы круизной компании "Созвездие"',
				children: [
					{
						id: 970,
						label: 'Огни большого города',
					},
					{
						id: 963,
						label: 'Маленький принц',
					},
					{
						id: 498,
						label: 'Лунная соната',
					},
					{
						id: 478,
						label: 'Лебединое озеро',
					},
					{
						id: 83,
						label: 'Симфония севера (Дмитрий Фурманов)',
					},
					{
						id: 4,
						label: 'Н.А. Некрасов',
					},
					{
						id: 38,
						label: 'Александр Бенуа',
					},
					{
						id: 571,
						label: 'Северная сказка',
					},
				],
			},
			{
				id: null,
				label: 'Проект "Экспедиции "Созвездия"',
				children: [
					{
						id: 462,
						label: 'Империя',
					},
				],
			},
			{
				id: null,
				label: 'Генеральный агент',
				children: [
					{
						id: 1,
						label: 'Две столицы',
					},
					{
						id: 487,
						label: 'Капитан Пушкарев',
					},
					{
						id: 3,
						label: 'Космонавт Гагарин',
					},
					{
						id: 64,
						label: 'Александр Невский',
					},
				],
			},
		],
	},
];

export const searchFieldsSea: Array<SearchFieldProps> = [
	{
		order: 0,
		keys: ['dateStart', 'dateEnd'],
		type: 'date',
		icon: 'month',
		label: 'Дата отправления',
		placeholder: 'Не важно',
		options: {
			min: '2023-02-07T00:00',
			max: '2024-11-07T00:00',
		},
	},
	{
		order: 1,
		keys: ['lengthDaysMin', 'lengthDaysMax'],
		type: 'range',
		icon: 'calendar',
		label: 'Продолжительность',
		placeholder: 'Не важно',
		options: [
			{
				min: 0,
				label: 'Любая',
			},
			{
				min: 0,
				max: 5,
				label: 'Менее 5 дней',
			},
			{
				min: 5,
				max: 7,
				label: 'От 5 до 7 дней',
			},
			{
				min: 8,
				max: 14,
				label: 'От 8 до 14 дней',
			},
			{
				min: 14,
				label: 'Более 14 дней',
			},
		],
	},
	{
		order: 2,
		keys: 'startCities',
		type: 'select',
		icon: 'location',
		label: 'Город отправления',
		placeholder: 'Не важно',
		multiple: true,
		options: [
			{
				id: null,
				label: 'Россия',
				children: [
					{
						id: 762,
						label: 'Сочи',
					},
				],
			},
			{
				id: null,
				label: 'Египет',
				children: [
					{
						id: 768,
						label: 'Айн-Сохна',
					},
					{
						id: 769,
						label: 'Сафага',
					},
				],
			},
			{
				id: null,
				label: 'ОАЭ',
				children: [
					{
						id: 765,
						label: 'Дубай',
					},
				],
			},
			{
				id: null,
				label: 'Турция',
				children: [
					{
						id: 771,
						label: 'Фетхие',
					},
				],
			},
		],
	},
	{
		order: 3,
		keys: 'routes',
		type: 'select',
		icon: 'flag',
		label: 'Популярные маршруты',
		placeholder: 'Не важно',
		multiple: true,
		options: [
			{
				id: 24,
				label: 'Новогодние скидки',
			},
			{
				id: 34,
				label: 'Майские праздники',
			},
			{
				id: 44,
				label: 'Черноморские круизы',
			},
			{
				id: 54,
				label: 'Турция',
			},
			{
				id: 64,
				label: 'Красное море',
			},
			{
				id: 74,
				label: 'ОАЭ и Персидский залив',
			},
			{
				id: 84,
				label: 'Египет',
			},
		],
	},
	{
		order: 4,
		keys: 'ships',
		type: 'select',
		icon: 'frigate',
		label: 'Теплоход',
		placeholder: 'Не важно',
		multiple: true,
		options: [
			{
				id: null,
				label: '«Созвездие» — круизы по турецкой ривьере',
				children: [
					{
						id: 870,
						label: 'Гранд Адмирал',
					},
				],
			},
			{
				id: null,
				label: 'Astoria',
				children: [
					{
						id: 462,
						label: 'Astoria Grande',
					},
				],
			},
			{
				id: null,
				label: 'MSC',
				children: [
					{
						id: 121,
						label: 'MSC Opera',
					},
					{
						id: 487,
						label: 'MSC Splendida',
					},
					{
						id: 64,
						label: 'MSC World Europa',
					},
				],
			},
		],
	},
];

export const getSearchFieldsMock = ({ shipTypesIn = '0,1,2' }) => {
	switch (shipTypesIn) {
		case '22,44,3,50,969':
			return searchFieldsSea;
		case '11,12,33,40':
			return searchFieldsSea;
		case '0,1,2':
		default:
			return search;
	}
};
