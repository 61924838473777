// import { DetailedCruiseCabin } from 'shared/lib/types';

import { DetailedCruiseCabin } from "config/types/cabins";

export const getPlaces = (cabin: DetailedCruiseCabin.Cabin) => {
	try {
		const { places, price } = cabin;

		const types: string[] = price?.passengersPrice?.map(
			({ passengerType }: DetailedCruiseCabin.PassengersPrice) => `${passengerType}`,
		);

		const mainPlaces = Array(places.main).fill({ type: null });
		const additionalPlaces = Array(places.additional).fill({ type: null });

		types.slice(0, places.main).forEach((type, index) => {
			mainPlaces[index] = { type };
		});

		types.slice(places.main).forEach((type, index) => {
			additionalPlaces[index] = { type };
		});

		return {
			main: mainPlaces,
			additional: additionalPlaces,
		};
	}
	catch (error) {
		return {
			main: [],
			additional: [],
		};

	}
};
