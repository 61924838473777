/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { EffectState } from 'patronum/status';
// import { GetDetailedCruiseCabinRequest } from 'shared/api';
// import { message } from 'shared/lib/message';
// import { DetailedCruiseCabin } from 'shared/lib/types';
// import { Button, Condition, DesktopView, MobileView, Typo } from 'shared/ui/atoms';
import { ScreenType } from '../../model/model';
import { CabinInfo, CabinInfoSkeleton } from '../cabin-info';
import { Passengers, PassengersSkeleton } from '../passengers';
import styles from './styles.module.scss';
import { message } from 'config/constants/message';
import { DetailedCruiseCabin } from 'config/types/cabins';
import { Button } from 'layout/components/buttons/button';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { DesktopView, MobileView } from 'layout/layouts/device-view';
import { GetDetailedCruiseCabinRequest } from 'store/cruise/cruises/cabins/index';

interface ContentProps {
	cruiseId: number;
	initialPending: boolean;
	pricesPreviewPending: boolean;
	pending: boolean;
	cabinInfo: Nullable<DetailedCruiseCabin.Cabin>;
	pricesPreviewStatus: EffectState;
	initialLoadInfo: (payload: GetDetailedCruiseCabinRequest) => void;
	childrenAge?: number;
	screen: ScreenType;
	setScreen: (screen: ScreenType) => void;
}

export const Content = ({
	cruiseId,
	initialPending,
	cabinInfo,
	pending,
	pricesPreviewPending,
	pricesPreviewStatus,
	initialLoadInfo,
	childrenAge,
	screen,
	setScreen,
}: ContentProps) => (
	<div>Content</div>
	// <>
	// 	<DesktopView>
	// 		<div className={styles.content}>
	// 			<Condition
	// 				value={initialPending}
	// 				then={<CabinInfoSkeleton />}
	// 				else={cabinInfo ? <CabinInfo {...cabinInfo} /> : <></>}
	// 			/>
	// 			<Condition
	// 				value={pending || pricesPreviewPending}
	// 				then={<PassengersSkeleton />}
	// 				else={
	// 					cabinInfo && pricesPreviewStatus !== 'fail' ? (
	// 						<Passengers {...cabinInfo} childrenAge={childrenAge} cruiseId={cruiseId} />
	// 					) : (
	// 						<div className={styles.error}>
	// 							<Typo design="text-m" raw={message.cabinInfo.error} />
	// 							{cabinInfo && (
	// 								<Button
	// 									type="button"
	// 									btnType="text"
	// 									onClick={() =>
	// 										initialLoadInfo({
	// 											cruiseId,
	// 											cabinId: cabinInfo.id,
	// 										})
	// 									}
	// 								>
	// 									Обновить
	// 								</Button>
	// 							)}
	// 						</div>
	// 					)
	// 				}
	// 			/>
	// 		</div>
	// 	</DesktopView>

	// 	<MobileView>
	// 		<div className={styles.content}>
	// 			{screen === 0 && (
	// 				<div className={styles.cabinInfoWrapper}>
	// 					<Condition
	// 						value={initialPending}
	// 						then={<CabinInfoSkeleton />}
	// 						else={
	// 							cabinInfo ? (
	// 								<CabinInfo handleScreen={(value) => setScreen(value)} {...cabinInfo} />
	// 							) : (
	// 								<></>
	// 							)
	// 						}
	// 					/>
	// 				</div>
	// 			)}

	// 			{screen === 1 && (
	// 				<div className={styles.passengersWrapper}>
	// 					<Condition
	// 						value={pending}
	// 						then={<PassengersSkeleton />}
	// 						else={
	// 							cabinInfo ? (
	// 								<Passengers
	// 									handleScreen={setScreen}
	// 									childrenAge={childrenAge}
	// 									cruiseId={cruiseId}
	// 									{...cabinInfo}
	// 								/>
	// 							) : (
	// 								<></>
	// 							)
	// 						}
	// 					/>
	// 				</div>
	// 			)}
	// 		</div>
	// 	</MobileView>
	// </>
);
