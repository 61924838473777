import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

export interface SummaryTableProps extends PropsWithChildren {}

export const SummaryTable: FC<SummaryTableProps> = ({ children }) => (
	<ul className={styles.summary}>
		{React.Children.map(children, (child) => {
			if (!React.isValidElement(child)) {
				return null;
			}

			return <li>{child}</li>;
		})}
	</ul>
);
