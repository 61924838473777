export const PLACE_TYPES: Record<string, string> = {
	0: 'Взрослый',
	1: 'Взрослый + ребенок без места',
	2: 'Ребенок от 0 до 15 лет',
};

export const CHILD_PLACE_TYPES = 2;
export const ADULT_PLACE_TYPES = { ...PLACE_TYPES };
delete ADULT_PLACE_TYPES[CHILD_PLACE_TYPES];

export const getPlaceTypes = (maxChildAge?: number, showChild?: boolean) =>
	({
		0: 'Взрослый',
		1: 'Взрослый + ребенок без места',
		...(showChild || Boolean(maxChildAge)
			? { 2: `Ребенок${maxChildAge !== undefined ? ` от 0 до ${maxChildAge || 15} лет` : ''}` }
			: {}),
		3: 'Свободное место',
	} as Record<string, string>);
