export const objectToFormData = (object: Record<string, any>) => {
	const data = new FormData();

	for (const key in object) {
		if (object.hasOwnProperty(key)) {
			data.append(key, object[key]);
		}
	}

	return data;
};
