import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useGate, useUnit } from 'effector-react';
// import { getModel } from 'features/edit-cart-cabin/model';
// import { Discounts } from 'features/edit-cart-cabin/ui/discounts';
import { cartModel } from 'entities/cart';
import { cruiseModel } from 'entities/cruises/model';
import { orderCabinsModel, userRoleModel } from 'entities/order';
// import { PassengerForm } from 'entities/passengers';
// import { PLACES } from 'entities/passengers/passengers-card/lib';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { CruiseContextProvider } from 'shared/lib/contexts/cruise-context/cruise-context';
// import { message } from 'shared/lib/message';
// import { PreviewPassenger } from 'shared/lib/types';
// import { setAnalytics } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Alert, Button, Typo, Price } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { TypeControls } from './ui';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { message } from 'config/constants/message';
import { PreviewPassenger } from 'config/types/prices';
import { getModel } from 'entities/edit-cart-cabin/model';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { Price } from 'layout/components/view/price';
import { useViewport } from 'layout/viewport';
import { Discounts } from '../discounts';
import { CruiseContextProvider } from 'config/contexts/cruise-context/cruise-context';
import { PassengerForm } from 'entities/passengers';
import { PLACES } from 'entities/passengers/passengers-card/lib';

interface PlaceProps extends PreviewPassenger {
	index: number;
	cabinId: number;
	isMain?: boolean;
	occupiedPlaces?: number;
	totalPlaces?: number;
	selectedDiscounts?: string[];
	childrenAge?: number;
	childrenDiscount?: boolean;
	surcharge?: number;
}

export const Place = ({
	placeType,
	total,
	type,
	index,
	availableDiscounts,
	discounts,
	cabinId,
	occupiedPlaces,
	totalPlaces,
	selectedDiscounts = [],
	surcharge,
	default: defaultPrice,
	childrenAge,
	childrenDiscount,
}: PlaceProps) => {
	const model = useMemo(
		() =>
			getModel({
				index,
				cabinId,
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[index, cabinId],
	);
	const [isExpanded, setIsExpanded] = useState(false);

	const {
		form,
		$pattern,
		$isProfileData,
		Gate,
		$documentTypesValidation,
		$birthdayErrorVisible,
		$isAlonePassenger,
	} = model;
	const [
		values,
		setFromProfile,
		pattern,
		changeType,
		pending,
		isProfileData,
		isGuest,
		cruise,
		documentTypeValidationScheme,
		birthdayErrorVisible,
		isAlonePassenger,
	] = useUnit([
		form.$values,
		model.setFromProfile,
		$pattern,
		orderCabinsModel.changeType,
		cartModel.pricePending,
		$isProfileData,
		userRoleModel.$isGuest,
		cruiseModel.$cruise,
		$documentTypesValidation,
		$birthdayErrorVisible,
		$isAlonePassenger,
	]);

	useGate(Gate);

	const hasChild = `${type}` === '1';
	const { isMob } = useViewport();

	const localChangeType = useCallback(
		(newType: Nullable<number>) => {
			changeType({
				cabinId,
				index,
				type: newType,
			});
		},
		[cabinId, changeType, index],
	);

	// const addChild = useCallback(() => {
	// 	localChangeType(1);
	// 	setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_add_child);
	// }, [localChangeType]);

	const toggleChild = useCallback(() => {
		localChangeType(type && +type === 1 ? 0 : 1);
	}, [localChangeType, type]);

	const removeChild = useCallback(() => {
		localChangeType(0);
	}, [localChangeType]);

	const removePassenger = useCallback(() => {
		localChangeType(null);
		// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_leave_place);
	}, [localChangeType]);

	const addPassenger = useCallback(() => {
		localChangeType(0);
		// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_add_tourist);
	}, [localChangeType]);

	const placeName = useMemo(() => PLACES.find(({ id }) => id === placeType)?.name, [placeType]);

	const button = (
		<div className={styles.buttonWrapper}>
			<Button
				className={styles.button}
				contextClassName={styles.button__content}
				btnType="outline"
				onClick={() => setIsExpanded((isOpen) => !isOpen)}
			>
				{isExpanded ? 'Свернуть данные туриста' : 'Внести данные туриста'}
				<Icon
					id="chevron-down"
					width={20}
					height={20}
					className={classnames(styles.button__icon, {
						[styles.button__iconUp]: isExpanded,
					})}
				/>
			</Button>
		</div>
	);

	const surchargeAlert = occupiedPlaces === 1 && !!surcharge && index === 0 && (
		<div>
			<Typo design="text-s" className={styles.surchargeText}>
				Доплата за 1-местное размещение
			</Typo>
			<Typo design="input-text-m">
				+ <Price total={surcharge} as="span" design="input-text-m" />
			</Typo>
		</div>
	);

	if (`${type}` === '3') {
		return (
			<div className={classnames(styles.place, styles.place_empty)}>
				<header className={styles.header}>
					<div className={styles.info}>
						<div className={styles.info__item}>
							<div className={styles.info__place}>
								<Typo as="span" design="text-m">
									Место
								</Typo>
								<Typo as="span" design="headline-s">
									{placeName}
								</Typo>
							</div>
						</div>
						<Typo design="button-m-s" className={styles.info__buttonFree}>
							Свободно
						</Typo>
					</div>
					<Button onClick={addPassenger}>
						Добавить туриста <Icon id="plus" width={20} height={20} />
					</Button>
				</header>
				{/* {Boolean(total) && occupiedPlaces && totalPlaces && (
					<Surcharge places={occupiedPlaces} total={totalPlaces} surcharge={total as number} />
				)} */}
			</div>
		);
	}

	return (
		<div className={styles.place} key={`${index}${cabinId}`}>
			<header className={styles.header}>
				<div className={styles.info}>
					<div className={styles.info__item}>
						<div className={styles.info__place}>
							<Typo as="span" design="text-m">
								Место
							</Typo>
							<Typo as="span" design="headline-s">
								{placeName}
							</Typo>
						</div>
						{!isMob && button}
					</div>
					{/* <PlacePrice defaultPrice={defaultPrice} total={total} pending={pending} /> */}
					<TypeControls
						type={type}
						total={total}
						toggleChild={toggleChild}
						hasChild={hasChild}
						changeType={localChangeType}
						pending={pending}
						childrenAge={childrenAge}
						childrenDiscount={childrenDiscount}
						adultOnly={index === 0}
					/>
					{isMob && button}
					{isMob && occupiedPlaces === 1 && !!surcharge && index === 0 && (
						<Alert design="text">{surchargeAlert}</Alert>
					)}
				</div>
				{index > 0 && (
					<Button onClick={removePassenger} btnType="text" className={styles.removePassengerBtn}>
						Удалить место <Icon id="trash" width={20} height={20} />
					</Button>
				)}
				{!isMob && surchargeAlert}
			</header>
			{birthdayErrorVisible && !pending && (
				<Alert className={styles.birthdayAlert} type="error">
					{isAlonePassenger
						? message.form.validation.birthday.aloneAdult
						: message.form.validation.birthday.adult}
				</Alert>
			)}
			<div className={classnames(styles.passenger, isExpanded && styles.passenger_isExpanded)}>
				<AnimateHeight height={isExpanded ? 'auto' : 0} duration={400}>
					<CruiseContextProvider
						cruiseInfo={{
							date: cruise?.date,
						}}
					>
						<PassengerForm
							size="md"
							className={styles.form}
							form={form}
							setFromProfile={setFromProfile}
							child={hasChild}
							pattern={pattern}
							documentType={values.documentType || ''}
							removeChild={removeChild}
							pending={pending}
							isProfileData={isProfileData}
							toggleDisabled={isGuest}
							documentTypeValidationScheme={documentTypeValidationScheme}
							hasBirthdayError={birthdayErrorVisible}
						/>
						{/* <GuestPhoneForm /> */}
					</CruiseContextProvider>
				</AnimateHeight>
				{/* {!hasChild && (
					<Condition
						value={pending}
						then={<Skeleton height={20} width={153} />}
						else={
							<Button className={styles.addChild} btnType="text" onClick={addChild}>
								<Icon width={20} height={20} id="plus" /> Добавить ребенка
							</Button>
						}
					/>
				)} */}
			</div>
			<Discounts
				cabinId={cabinId}
				index={index}
				availableDiscounts={availableDiscounts}
				discounts={discounts}
				pending={pending}
				selectedDiscounts={selectedDiscounts}
			/>
		</div>
	);
};
