import React, { useMemo } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { Item } from 'react-stately';
import { useUnit } from 'effector-react';
import { Form } from 'effector-react-form';
import { useError, useForm } from 'effector-react-form';
import styles from '../styles.module.scss';
// import { countriesModel } from 'entities/countries';
import { DOCUMENT_TYPES_MASK_TOKENS, documentTypesModel } from 'entities/document-types';
import { userRoleModel } from 'entities/order';
// import { MIN_BIRTHDAY_DATE, MIN_YEAR } from 'shared/config';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { useCruiseInfoContext } from 'shared/lib/contexts/cruise-context/cruise-context';
// import { dateToCalendarDate, deltaDate, setAnalytics } from 'shared/lib/utils';
// import {
// 	Button,
// 	Input,
// 	InputDate,
// 	inputDateOnBlurValueParser,
// 	inputDateOnChangeValueParser,
// 	inputDateValueParser,
// 	Radio,
// 	ToggleIos,
// 	Typo,
// 	Alert,
// } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { RadioGroup, Select } from 'shared/ui/molecules';
// import { Form as FormWrapper, FormField } from 'shared/ui/organisms/form';
// import { nameMask, passportIssuedMask } from 'shared/ui/organisms/form/lib/masks';
// import { ValidationOptions } from 'shared/ui/organisms/form/lib/validation';
import { ChildrenFormValidationParams, PassengerFormValidationParams } from '../lib';
import { ANALYTICS_TARGETS } from 'config/constants';
import { MIN_BIRTHDAY_DATE, MIN_YEAR } from 'config/constants/order';
import { useCruiseInfoContext } from 'config/contexts/cruise-context/cruise-context';
import { countriesModel } from 'store/countries';
import { dateToCalendarDate, deltaDate } from 'utils/date';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { FormField } from 'layout/components/inputs/form/field';
import { ValidationOptions } from 'layout/components/inputs/form/lib/validation';
import { nameMask, passportIssuedMask } from 'layout/components/inputs/form/masks';
import { Input, inputDateValueParser, inputDateOnBlurValueParser, inputDateOnChangeValueParser } from 'layout/components/inputs/input';
import { InputDate } from 'layout/components/inputs/input/input-date';
import { Radio } from 'layout/components/inputs/radio';
import { RadioGroup } from 'layout/components/inputs/radio-group';
import { Select } from 'layout/components/inputs/selects/select';
import { ToggleIos } from 'layout/components/special/toggle-ios';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { Form as FormWrapper } from 'layout/components/inputs/form/form';
// import { FormWrapper } from 'layout/layouts/widgets/quiality-control/ui/form-wrapper';

interface PassengerFormProps {
	form: Form<any, any>;
	documentType: number | '';
	child: boolean;
	childButton?: React.ReactNode;
	setFromProfile: (selected: boolean) => void;
	pattern: string;
	className?: string;
	validation?: PassengerFormValidationParams;
	childValidation?: ChildrenFormValidationParams;
	removeChild?: () => void;
	size?: 'lg' | 'md';
	pending?: boolean;
	isProfileData?: boolean;
	toggleDisabled?: boolean;
	documentTypeValidationScheme?: Nullable<ValidationOptions>;
	minChildBornDate?: string;
	showAgeNotification?: boolean;
	showChildAgeNotification?: boolean;
	hasBirthdayError?: boolean;
}

export const PassengerForm = ({
	form,
	setFromProfile,
	pattern,
	documentType,
	child,
	childButton,
	className,
	validation,
	removeChild,
	size = 'lg',
	pending,
	isProfileData,
	toggleDisabled = false,
	childValidation,
	documentTypeValidationScheme,
	minChildBornDate = MIN_BIRTHDAY_DATE,
	showAgeNotification,
	showChildAgeNotification,
	hasBirthdayError,
}: PassengerFormProps) => {
	const { handleSubmit, controller, setValue } = useForm({ form });
	const { error, isShowError } = useError({
		form,
		name: 'formError',
	});

	const [countries, documentTypes, isGuest] = useUnit([
		countriesModel.$countries,
		documentTypesModel.$documentTypes,
		userRoleModel.$isGuest,
	]);

	const cruiseInfo = useCruiseInfoContext();

	const todayCalendarDate = dateToCalendarDate(new Date());
	const minCalendarDate = dateToCalendarDate(new Date(minChildBornDate));
	const minCalendarYear = minCalendarDate?.year || MIN_YEAR;
	const minChildBirthdayDate = useMemo(
		() =>
			dateToCalendarDate(
				deltaDate({
					toDate: new Date(cruiseInfo?.date?.start || MIN_BIRTHDAY_DATE),
					days: 0,
					months: 0,
					years: -6,
				}),
			),
		[cruiseInfo],
	);

	// if (pending) {
	// 	return (
	// 		<div	>
	// 			<Skeleton className={styles.fromProfile} height={22} width={240} />
	// 			<Skeleton height={size === 'md' ? 224 : 376} width="100%" />
	// 			{child && (
	// 				<Skeleton className={styles.child} height={size === 'md' ? 84 : 140} width="100%" />
	// 			)}
	// 		</div>
	// 	);
	// }

	const childrenFormValidation = child ? childValidation : undefined;

	return (
		<div>
			{!isGuest && (
				<ToggleIos
					isSelected={isProfileData}
					className={styles.fromProfile}
					onChange={(isSelected) => {
						setFromProfile(isSelected);

						if (isSelected) {
							// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_fillin_data_on);
						} else {
							// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_fillin_data_off);
						}
					}}
					isDisabled={toggleDisabled}
					size="lg"
				>
					<Typo className={styles.fromProfile__text} design="headline-xs">
						Заполнить данными заказчика
					</Typo>
				</ToggleIos>
			)}

			{showAgeNotification && (
				<Alert type="warning" design="text-large" iconId="info" className={styles.alert}>
					Возраст ребенка зависит от оператора круиза
				</Alert>
			)}

			<FormWrapper error={error} isShowError={isShowError} handleSubmit={handleSubmit}>
				<div className={classnames(styles.form, styles[`form_${size}`], className)}>
					<FormField
						secondPlaceholder="Иванов"
						validation={validation?.lastName}
						controller={controller}
						form={form}
						name="lastName"
						{...nameMask}
					>
						<Input
							placeholder="Фамилия"
							size={size}
							withLabel
							required={validation?.lastName?.required}
							maxLength={255}
						/>
					</FormField>
					<FormField
						secondPlaceholder="Иван"
						validation={validation?.firstName}
						controller={controller}
						form={form}
						name="firstName"
						{...nameMask}
					>
						<Input
							placeholder="Имя"
							size={size}
							withLabel
							required={validation?.firstName?.required}
							maxLength={255}
						/>
					</FormField>
					<FormField
						secondPlaceholder="Иванович"
						controller={controller}
						form={form}
						name="middleName"
						{...nameMask}
					>
						<Input placeholder="Отчество" size={size} withLabel maxLength={255} />
					</FormField>

					<FormField
						form={form}
						controller={controller}
						name="birthday"
						validation={validation?.birthday}
						valueParser={(value) => inputDateValueParser(value, true)}
						onChangeValueParser={(changedValue) =>
							inputDateOnBlurValueParser(changedValue, {
								minValue: minCalendarDate,
								maxValue: todayCalendarDate,
							})
						}
						onBlurValueParser={(blurValue) =>
							inputDateOnBlurValueParser(blurValue, {
								minValue: minCalendarDate,
								maxValue: todayCalendarDate,
							})
						}
						className={styles.passport__selector}
					>
						<InputDate
							hasError={hasBirthdayError}
							placeholder="Дата рождения"
							secondPlaceholder="00.00.0000"
							placement="bottom left"
							size={size}
							withLabel
							datepicker={{
								minValue: minCalendarDate,
								maxValue: todayCalendarDate,
							}}
							calendar={{
								dateSelector: {
									minYear: minCalendarYear,
									maxYear: 'today',
								},
							}}
							required={validation?.birthday?.required}
							withoutTimezone
						/>
					</FormField>
					<FormField
						validation={validation?.gender}
						controller={controller}
						form={form}
						name="gender"
						valueParser={String}
					>
						<RadioGroup
							isRequired={validation?.gender?.required}
							showCaption
							className={styles.genderWrapper}
							label="Пол"
						>
							<Radio value="1">
								<Typo design="description-m">Мужской</Typo>
							</Radio>
							<Radio value="2">
								<Typo design="description-m">Женский</Typo>
							</Radio>
						</RadioGroup>
					</FormField>
					<FormField
						valueAlias="selectedKeys"
						valueParser={(value) => (value ? [`${value}`] : [])}
						onChangeAlias="onSelectionChange"
						onChangeValueParser={(keys) => {
							if (keys instanceof Set && keys.size) {
								return Number([...keys][0]);
							}

							return '';
						}}
						controller={controller}
						form={form}
						validation={validation?.documentType}
						name="documentType"
					>
						<Select
							size={size}
							className={styles.selector}
							label="Тип документа"
							selectionMode="single"
							isRequired={validation?.documentType?.required}
						>
							{documentTypes.map(({ id, name }) => (
								<Item key={`${id}`} aria-label={name}>
									{name}
								</Item>
							))}
						</Select>
					</FormField>
					<AnimateHeight
						className={styles.passportData}
						height={documentType !== '' ? 'auto' : 0}
						duration={400}
					>
						<div
							className={classnames(
								styles.passportDataForm,
								styles[`passportDataForm_${size}`],
								className,
							)}
						>
							<FormField
								valueAlias="selectedKeys"
								valueParser={(value) => (value ? [`${value}`] : [])}
								onChangeAlias="onSelectionChange"
								onChangeValueParser={(keys) => {
									if (keys instanceof Set && keys.size) {
										return [...keys][0].toString();
									}

									return null;
								}}
								validation={validation?.citizenship}
								isRequired={Boolean(validation?.citizenship?.required)}
								controller={controller}
								form={form}
								name="citizenship"
							>
								<Select
									size={size}
									className={styles.selector}
									isRequired
									label="Гражданство"
									selectionMode="single"
									searchPlaceholder="Поиск значения"
									withSearch
								>
									{countries.map(({ id, name }) => (
										<Item key={`${id}`} aria-label={name}>
											{name}
										</Item>
									))}
								</Select>
							</FormField>
							<FormField
								validation={documentTypeValidationScheme}
								controller={controller}
								secondPlaceholder={pattern}
								form={form}
								name="passportData"
							>
								<Input
									disabled={documentType === ''}
									mask={pattern}
									placeholder="Серия и номер документа"
									size={size}
									withLabel
									required={validation?.passportData?.required}
									maskOptions={{
										tokens: DOCUMENT_TYPES_MASK_TOKENS,
									}}
									masked
								/>
							</FormField>
							<div className={styles.passportIssued}>
								<FormField
									disabled={documentType === ''}
									secondPlaceholder="Не заполнено"
									controller={controller}
									form={form}
									maxLength={255}
									name="passportIssued"
									validation={validation?.passportIssued}
									{...passportIssuedMask}
								>
									<Input
										placeholder="Кем выдан"
										size={size}
										withLabel
										required={validation?.passportIssued?.required}
									/>
								</FormField>
							</div>
							<FormField
								form={form}
								controller={controller}
								name="passportIssuedDate"
								validation={validation?.passportIssuedDate}
								valueParser={inputDateValueParser}
								onChangeValueParser={(changedValue) =>
									inputDateOnBlurValueParser(changedValue, {
										minValue: minCalendarDate,
										maxValue: todayCalendarDate,
									})
								}
								onBlurValueParser={(blurValue) =>
									inputDateOnBlurValueParser(blurValue, {
										minValue: minCalendarDate,
										maxValue: todayCalendarDate,
									})
								}
								className={styles.passport__selector}
							>
								<InputDate
									placeholder="Дата выдачи"
									secondPlaceholder="00.00.0000"
									placement="bottom left"
									size="md"
									withLabel
									datepicker={{
										minValue: minCalendarDate,
										maxValue: todayCalendarDate,
									}}
									calendar={{
										dateSelector: {
											minYear: 1902,
											maxYear: 'today',
										},
									}}
									disabled={documentType === ''}
									required={validation?.passportIssuedDate?.required}
								/>
							</FormField>
						</div>
					</AnimateHeight>
					<AnimateHeight className={styles.childWrap} height={child ? 'auto' : 0} duration={400}>
						<div className={styles.child}>
							<div className={styles.child__head}>
								<Typo design="headline-m">Данные о ребенке</Typo>
								<Button
									onClick={() => {
										// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_child_removed);

										return (
											removeChild?.() ||
											setValue({
												field: 'child',
												value: false,
											})
										);
									}}
									size="middle"
									btnType="text"
									type="button"
								>
									<Icon id="trash" /> Удалить
								</Button>
							</div>

							{showChildAgeNotification && (
								<Alert type="warning" design="text-large" iconId="info" className={styles.alert}>
									Возраст ребенка зависит от оператора круиза
								</Alert>
							)}

							<div className={styles.child__form}>
								<div className={styles.child__name}>
									<FormField
										controller={controller}
										form={form}
										name="childName"
										secondPlaceholder="Иванов Иван Иванович"
										validation={childrenFormValidation?.name}
									>
										<Input
											placeholder="ФИО ребенка"
											size={size}
											maxLength={750}
											required={childrenFormValidation?.name?.required}
											withLabel
										/>
									</FormField>
								</div>
								<FormField
									form={form}
									controller={controller}
									name="childBirthday"
									validation={childrenFormValidation?.birthday}
									valueParser={inputDateValueParser}
									onChangeValueParser={inputDateOnChangeValueParser}
									onBlurValueParser={(blurValue) =>
										inputDateOnBlurValueParser(blurValue, {
											minValue: minChildBirthdayDate,
											maxValue: todayCalendarDate,
										})
									}
									className={styles.passport__selector}
								>
									<InputDate
										placeholder="Дата рождения"
										secondPlaceholder="00.00.0000"
										placement="bottom left"
										size="md"
										withLabel
										datepicker={{
											minValue: minChildBirthdayDate,
											maxValue: todayCalendarDate,
										}}
										calendar={{
											dateSelector: {
												minYear: new Date().getFullYear() - 6,
												maxYear: 'today',
											},
										}}
									/>
								</FormField>
								<FormField
									controller={controller}
									form={form}
									name="childDocument"
									validation={childrenFormValidation?.document}
								>
									<Input
										mask="SSSSSSSSSSSSSSSSSSSSSSSSS"
										placeholder="Свидетельство о рождении"
										size={size}
										maxLength={255}
										withLabel
										required={childrenFormValidation?.document?.required}
										maskOptions={{
											tokens: DOCUMENT_TYPES_MASK_TOKENS,
										}}
									/>
								</FormField>
							</div>
						</div>
					</AnimateHeight>
					<div className={styles.childButton}>{!child && childButton}</div>
				</div>
			</FormWrapper>
		</div>
	);
};
