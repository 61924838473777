import React, { FC, PropsWithChildren, memo } from 'react';
import classnames from 'classnames';
// import { usePopover } from 'shared/lib/hooks';
// import { useViewport } from 'shared/lib/viewport';
// import { Popover } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { useViewport } from 'layout/viewport';
import { usePopover } from 'utils/hooks/use-popover';
import { Popover } from '../popover';

export interface TooltipProps extends PropsWithChildren {
	as?: 'div' | 'span' | 'li' | 'a';
	trigger: React.ReactElement;
	className?: string;
	classNamePopover?: string;
	placement?: 'bottom' | 'left' | 'top';
	notUseOnFocus?: boolean;
	isDismissable?: boolean;
	isClickable?: boolean;
}

export const Tooltip: FC<TooltipProps> = memo(
	({
		className,
		classNamePopover,
		trigger,
		children,
		as: Tag = 'span',
		placement = 'bottom',
		notUseOnFocus = false,
		isDismissable = true,
		isClickable = false,
	}) => {
		const { isMob } = useViewport();

		const {
			triggerRef,
			popoverRef,
			overlayProps,
			hoverProps,
			focusProps,
			pressProps,
			shouldMount,
			state,
		} = usePopover({ isDismissable, isClickable });

		return (
			<>
				<Tag
					ref={triggerRef}
					{...pressProps}
					{...(isMob ? {} : hoverProps)}
					{...(notUseOnFocus ? {} : { ...focusProps, tabIndex: 0 })}
					className={className}
				>
					{trigger}
				</Tag>
				{children && shouldMount && (
					<Popover
						isNonModal
						placement={placement}
						state={state}
						triggerRef={triggerRef}
						popoverRef={popoverRef}
						className={classnames(classNamePopover, styles.tooltip, {
							[styles.isActive]: state.isOpen,
							[styles.left]: placement === 'left',
						})}
						{...overlayProps}
						fixedPosition
					>
						{children}
					</Popover>
				)}
			</>
		);
	},
);
