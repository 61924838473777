import React from 'react';

export const NotIncludedIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" stroke="#ffaf02">
		<path
			d="M16.0025 2.6665L19.5161 6.18009H25.8201V12.484L29.3337 15.9976L25.8201 19.5156V25.8196H19.5161L16.0025 29.3332L12.4846 25.8196H6.18057V19.5156L2.66699 15.9976L6.18057 12.484V6.18009H12.4846L16.0025 2.6665Z"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.333 15.9976H20.6663"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.0029 11.3335V20.6668"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
