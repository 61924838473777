import React, { FC, memo, PropsWithChildren, useRef } from 'react';
import classnames from 'classnames';
import { useGate, useUnit } from 'effector-react';
// import { CartModal } from 'features/cart-widget/ui/cart-modal';
import { CartItem, cartModel } from 'entities/cart';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { useModalState } from 'shared/lib/hooks';
// import { setAnalytics } from 'shared/lib/utils';
// import { Icon } from 'shared/ui/icons';
// import { Modal } from 'shared/ui/molecules';
import { model } from '../model';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { Icon } from 'layout/components/icons/icon';
import { Modal } from 'layout/components/modal';
import { useModalState } from 'utils/hooks/use-modal-state';
import { CartModal } from './cart-modal';
import { OverlayTriggerState } from 'react-stately';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { API_BASE_URL, API_GOOGLE_RECAPCHA_PUBLIC_KEY } from 'config/commonConstants';

export interface CartWidgetWAPProps {
	buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>,
	ref: React.RefObject<HTMLButtonElement>,
	shouldMount: boolean,
	state: OverlayTriggerState,
	cart: CartItem[]
}

export const CartWidgetWAP: FC<PropsWithChildren<CartWidgetWAPProps>> = memo(({ buttonProps, ref, shouldMount, state, cart, children }) => {
	// const { executeRecaptcha } = useGoogleReCaptcha();
	// console.log('CartWidgetWAP', executeRecaptcha ? 'yes recapcha' : 'no recapcha', API_GOOGLE_RECAPCHA_PUBLIC_KEY, API_BASE_URL)

	return (
		<>

			{shouldMount && (
				<Modal
					state={state}
					isShown={state.isOpen}
					className={styles.modalWrap}
					classNameModal={styles.modal}
					closeButtonClassName={styles.closeButton}
					classNameModalContent={styles.modalContent}
					verticalAlign="bottom"
					closeIconId="closer"
					isDismissable
				>
					{children ?? null}
				</Modal>
			)}
		</>
	);
});
