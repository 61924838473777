/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from 'react';
import { CalendarDate } from '@internationalized/date';
import { useDateFormatter } from 'react-aria';
import { CalendarState, Item, RangeCalendarState } from 'react-stately';
// import { Select } from 'shared/ui/molecules/select';
import { getYear } from './lib';
import style from '../style.module.scss';
import { Select } from 'layout/components/inputs/selects/select';

export interface YearDropdownProps {
	state: CalendarState | RangeCalendarState;
	minYear?: number | 'today';
	maxYear?: number | 'today';
	portal?: HTMLElement;
}

export const YearDropdown = ({ state, minYear, maxYear, portal }: YearDropdownProps) => {
	const [value, setValue] = useState(getYear(maxYear, 20) - state.focusedDate.year);

	const formatter = useDateFormatter({
		year: 'numeric',
		timeZone: state.timeZone,
	});

	const years = useMemo(() => {
		const min = getYear(minYear, -20);
		const max = getYear(maxYear, 20);

		const tmpYears: { value: CalendarDate; formatted: string }[] = [];
		for (let i = min - state.focusedDate.year; i <= max - state.focusedDate.year; i++) {
			const date = state.focusedDate.add({ years: i });
			tmpYears.push({
				value: date,
				formatted: formatter.format(date.toDate(state.timeZone)),
			});
		}

		return tmpYears.reverse();
	}, [minYear, maxYear, state.focusedDate, state.timeZone, formatter]);

	const onChange = (key: string) => {
		const index = Number(key);
		setValue(index);
		const date = years[index].value;
		state.setFocusedDate(date);
	};

	useEffect(() => {
		setValue(getYear(maxYear, 20) - state.focusedDate.year);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.focusedDate.year]);

	return (
		<Select
			className={style.calendar__year}
			classNameTrigger={style.calendar__yearTrigger}
			classNamePopover={style.calendar__yearPopover}
			alwaysShowChevron
			selectedKeys={Number.isInteger(value) ? [`${value}`] : []}
			onSelectionChange={(keys) => {
				if (keys instanceof Set && keys.size) {
					onChange([...keys][0].toString());
				}
			}}
			selectPortal={portal}
		>
			{years.map((year, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<Item key={i}>{year.formatted}</Item>
			))}
		</Select>
	);
};
