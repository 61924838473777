import { ParsedUrlQuery } from 'querystring';
import { combineEvents, status } from 'patronum';
import { createEffect, createEvent, createStore, sample, attach } from 'effector';
import { GetShipsRequest, shipsRequests } from 'store/cruise/ships';
import { ShipCardModel, ShipsResponseMeta } from 'config/types/ships';
import { SHIPS_LIMIT, SHIPS_TYPE_KEY, SHIPS_TYPES_RUS } from 'config/constants/ships';
import { normalizeISODatesInParams, stringifyParsedUrlQuery } from 'utils/object';
import { routerModel } from 'utils/router';
import { getSkeletonsArray } from 'utils/skeletons/skeletons';
// import { GetShipsRequest, shipsRequests } from 'shared/api/requests/ships';
// import { SHIPS_LIMIT, SHIPS_TYPE_KEY, SHIPS_TYPES_RUS } from 'shared/config';
// import { getSkeletonsArray } from 'shared/lib/skeletons';
// import { ShipCardModel, ShipsResponseMeta } from 'shared/lib/types';
// import { normalizeISODatesInParams, stringifyParsedUrlQuery } from 'shared/lib/utils';
// import { routerModel } from 'shared/model';

export type ShipsModelACs = 'AC2' | 'AC3';
export type ShipsModelType = typeof model;

const getShips = createEvent<GetShipsRequest>();
const getMoreShips = createEvent<GetShipsRequest>();

const setParams = createEvent<ParsedUrlQuery>();
const setInitialParams = createEvent<ParsedUrlQuery>();
const setInitialPage = createEvent<number>();
const setTypeOfRoute = createEvent<string>();
const setHistory = createEvent<GetShipsRequest>();

const resetParams = createEvent();
const resetPagination = createEvent();

const $list = createStore<ShipCardModel[]>([]);
const $skeletons = createStore<number[]>(getSkeletonsArray());
const $count = createStore(0);

const $params = createStore<Nullable<ParsedUrlQuery>>(null).reset(resetParams);
const $typeOfRoute = createStore<Nullable<string>>(null);

const $pagination = createStore<ShipsResponseMeta>({
	total: 0,
	perPage: SHIPS_LIMIT,
	currentPage: 1,
	lastPage: 1,
});

const createGetShipFx = () =>
	attach({
		effect: createEffect(shipsRequests.getShips),
		source: {
			params: $params,
			typeOfRoute: $typeOfRoute,
		},
		mapParams: ({ page }: GetShipsRequest, { typeOfRoute, params }) => ({
			page,
			params: normalizeISODatesInParams({
				...params,
				[SHIPS_TYPE_KEY]: typeOfRoute ?? SHIPS_TYPES_RUS,
			}),
		}),
	});

const getShipsFx = createGetShipFx();
const getMoreShipsFx = createGetShipFx();

const $getShipsStatus = status({ effect: getShipsFx });
const $getMoreShipsStatus = status({ effect: getMoreShipsFx });

const setError = createEvent<Nullable<ShipsModelACs>>();
const $error = createStore<Nullable<ShipsModelACs>>(null);

/* *
 * Получение списка теплоходов
 */
sample({
	clock: getShips,
	target: getShipsFx,
});

sample({
	clock: getMoreShips,
	target: getMoreShipsFx,
});

/* *
 * Установка/сброс пагинации
 */
sample({
	clock: [getShipsFx.doneData, getMoreShipsFx.doneData],
	fn: ({ total = 0, perPage = SHIPS_LIMIT, currentPage = 0, lastPage = 0 }) => ({
		total,
		perPage,
		currentPage,
		lastPage,
	}),
	target: $pagination,
});

sample({
	clock: resetPagination,
	source: $pagination,
	fn: (source) => ({
		...source,
		currentPage: 1,
	}),
	target: $pagination,
});

/* *
 * Установка роута
 */
sample({
	clock: [getShips, getMoreShips],
	target: setHistory,
});

sample({
	clock: setHistory,
	source: {
		params: $params,
	},
	fn: ({ params = {} }, { page = 1 }) =>
		stringifyParsedUrlQuery({ ...params, ...(page > 1 ? { page: String(page) } : {}) }),
	target: routerModel.pushQuery,
});

/* *
 * Установка параметров
 */
sample({
	clock: setTypeOfRoute,
	source: $typeOfRoute,
	fn: (source, clock) => clock || source,
	target: $typeOfRoute,
});

sample({
	clock: setInitialParams,
	source: $params,
	fn: (source, clock = {}) => {
		const params = { ...source, ...clock };

		delete params.page;
		delete params.typeOfRoute;

		return params;
	},
	target: $params,
});

sample({
	clock: setParams,
	fn: (clock = {}) => {
		delete clock.page;
		delete clock.typeOfRoute;

		return clock;
	},
	target: $params,
});

const getInitial = combineEvents({
	events: [setInitialPage, setTypeOfRoute, setInitialParams, $typeOfRoute.updates, $params.updates],
});

sample({
	clock: getInitial,
	fn: ([page]) => ({ page }),
	target: getShips,
});

/* *
 * Список
 */
sample({
	clock: getShipsFx.doneData,
	filter: ({ items }) => Boolean(items),
	fn: ({ items = [] }) => items,
	target: $list,
});

sample({
	clock: getMoreShipsFx.doneData,
	source: $list,
	filter: (_, { items }) => Boolean(items),
	fn: (list, { currentPage = 1, items = [] }) => {
		if (currentPage > 1) {
			return [...list, ...items];
		}

		return items;
	},
	target: $list,
});

/* *
 * Счетчик и скелетоны
 */
sample({
	clock: $list,
	fn: ({ length }) => length,
	target: $count,
});

sample({
	clock: $count,
	fn: (count) => getSkeletonsArray(count),
	target: $skeletons,
});

/* *
 * Обработка ошибок
 */
sample({
	clock: setError,
	target: $error,
});

sample({
	clock: [getShipsFx.done, getMoreShipsFx.done],
	fn: () => null,
	target: setError,
});

sample({
	clock: [getShipsFx.fail, getMoreShipsFx.fail],
	fn: () => 'AC3' as const,
	target: setError,
});

export const model = {
	$list,
	$count,
	$skeletons,
	$getShipsStatus,
	$getMoreShipsStatus,
	$pagination,
	$params,
	$typeOfRoute,
	$error,
	getShips,
	getMoreShips,
	setParams,
	setInitialParams,
	setTypeOfRoute,
	setInitialPage,
	resetParams,
	resetPagination,
};
