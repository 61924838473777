import React, { FC } from 'react';
import { useUnit } from 'effector-react';
// import { profileModel } from 'entities/profile';
// import { PHONE_CLEANUP } from 'shared/lib/constants';
// import { Skeleton, Typo } from 'shared/ui/atoms';
// import { ContactsList, ContactsListItem } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { PHONE_CLEANUP } from 'config/constants';
import { profileModel } from 'layout/components/auth/profile';
import { Skeleton } from 'layout/components/special/skeleton';
import { Typo } from 'layout/components/typo/ui';

interface ProfileOfficeProps {
	isLoading?: boolean;
}

export const ProfileOffice: FC<ProfileOfficeProps> = ({ isLoading }) => {
	const profile = useUnit(profileModel.$profile);

	if (isLoading) {
		return (
			<section className={styles.office}>
				<ProfileOfficeSkeleton />
			</section>
		);
	}

	if (!profile?.office?.id) {
		return null;
	}

	return (
		<section className={styles.office}>
			<Typo design="headline-m">Ваш офис</Typo>
			{/* TODO отключено пока за ненадобностью */}
			{/* <ContactsList>
				{profile?.office?.address && (
					<ContactsListItem icon="location">{profile.office.address}</ContactsListItem>
				)}
				{profile?.office?.phone && (
					<ContactsListItem icon="phone">
						<a href={`tel:${profile.office.phone.replace(PHONE_CLEANUP, '')}`}>
							{profile.office.phone}
						</a>
					</ContactsListItem>
				)}
			</ContactsList> */}
		</section>
	);
};

export const ProfileOfficeSkeleton = () => (
	<div className={styles.office__skeleton}>
		<Skeleton className={styles.office__skeletonHead} />
		<ul>
			<li>
				<Skeleton display="inline-block" width="100%" height={16} />
				<Skeleton display="inline-block" width="40%" height={16} />
			</li>
			<li>
				<Skeleton display="inline-block" width={120} height={16} />
			</li>
		</ul>
	</div>
);
