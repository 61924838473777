import { getFailCode } from "api";
import { message } from "config/constants/message";
import { CheckPhoneRequest } from "config/types/personal";
import { createEffect, createEvent, createStore, sample } from "effector";
import { createForm } from "effector-react-form";
import { personalRequests } from "store/auth/api";
import { verificationModel } from "utils/verification";


const $resetToken = createStore<Nullable<string>>(null);

const checkPhoneCode = createEvent<Omit<CheckPhoneRequest, 'resetSmsToken'>>();
const checkPhoneCodeFx = createEffect(personalRequests.checkPhone);

const handleBadResponse = createEvent<number | undefined>();

const checkPhoneForm = createForm<Omit<CheckPhoneRequest, 'resetSmsToken'>>({
	initialValues: {
		code: '',
	},
	onSubmit: ({ values }) => checkPhoneCode(values),
});

sample({
	source: verificationModel.$verifyToken,
	filter: Boolean,
	clock: checkPhoneCode,
	fn: (resetSmsToken, { code }) => ({
		resetSmsToken,
		code,
	}),
	target: checkPhoneCodeFx,
});

sample({
	clock: checkPhoneCodeFx.doneData,
	fn: ({ resetToken }) => resetToken,
	target: $resetToken,
});

sample({
	clock: checkPhoneCodeFx.failData,
	fn: (error) => getFailCode(error),
	target: handleBadResponse,
});

sample({
	clock: handleBadResponse,
	fn: (code) => ({
		field: 'formError',
		error: message.profile.checkPhone[code || 'error'],
	}),
	target: checkPhoneForm.setOrDeleteError,
});

export const checkPhoneCodeFormModel = {
	checkPhoneForm,
	$resetToken,
	$checkPhonePending: checkPhoneCodeFx.pending,
};
