import React, { memo } from 'react';
// import Link from 'next/link';
import { useUnit } from 'effector-react';
// import { profileModel } from 'entities/profile';
// import { AccountRoutes } from 'shared/config';
// import { Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { profileModel } from 'layout/components/auth/profile';
import { Typo } from 'layout/components/typo/ui';
import { AccountRoutes } from 'store/auth/constants';
import { Link } from '@tanstack/react-router';

export const ProfileSubscriptions = memo(() => {
	const profile = useUnit(profileModel.$profile);

	if (profile?.emailNotificationsEnabled && profile?.smsNotificationsEnabled) {
		return (
			<Typo className={styles.profile__subscriptions} design="input-text-s">
				Вы подписаны на уведомления, информационные сообщения и&nbsp;материалы рекламного характера.
			</Typo>
		);
	}

	return (
		<Typo className={styles.profile__subscriptions} design="input-text-s">
			Вы не подписаны на уведомления, информационные сообщения и&nbsp;материалы рекламного
			характера.
			<br />
			Подпишитесь в разделе{' '}
			<Link href={AccountRoutes.PersonalData}>
				<a>Личные данные</a>
			</Link>
		</Typo>
	);
});
