import React, { FC } from 'react';
// import { ValueOf } from 'next/constants';
// import { useRouter } from 'next/router';
// import { OrderRoutes } from 'shared/config';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { OrderRoutes } from 'config/constants/order';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { useLocation } from '@tanstack/react-router';
import { ValueOf } from 'config/commonTypes';

interface BackButtonProps {}

export const BackButton: FC<BackButtonProps> = () => {
	// const { asPath } = useRouter();
	const location = useLocation();
	const asPath = location.pathname
	const pathname = asPath.split('?')[0] as ValueOf<typeof OrderRoutes>;

	if (pathname === OrderRoutes.Tourists) {
		return null;
	}

	const routesArray = Object.values(OrderRoutes);
	const currentIndex = routesArray.indexOf(pathname);

	return (
		<Button
			btnType="text"
			size="middle"
			href={routesArray[currentIndex - 1]}
			className={styles.backButton}
		>
			<Icon id="chevron-left" width={20} height={20} />
			<Typo design="button-m-s">Назад</Typo>
		</Button>
	);
};
