import { status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { ecommerceRequests } from 'store/cruise/ecommerce';
import { EcommerceShip } from 'config/types/ecommerce';
import { getFilledEcommerceObject } from 'utils/analytics/ecommerce';
import { GetShipInfoRequest, shipsRequests } from 'store/cruise/ships';
import { ShipModel } from 'config/types/ships';
import { fxErrorCode } from 'entities/fx-error-code';
// import { ecommerceRequests } from 'shared/api';
// import { GetShipInfoRequest, shipsRequests } from 'shared/api/requests/ships';
// import { CURRENCY } from 'shared/config/currency';
// import { EcommerceShip } from 'shared/lib/types';
// import { ShipModel } from 'shared/lib/types/ships';
// import { getFilledEcommerceObject } from 'shared/lib/utils';
// import { fxErrorCode } from 'shared/model';

const getShip = createEvent<GetShipInfoRequest>();
const getShipFx = createEffect(shipsRequests.getInfo);

const $ship = createStore<Nullable<ShipModel.ShipInfo>>(null);
const $hasInfo = createStore(true);
const $status = status({ effect: getShipFx });
const $errorCode = fxErrorCode({ effect: getShipFx });

sample({
	clock: getShip,
	target: getShipFx,
});

sample({
	clock: getShipFx.doneData,
	target: $ship,
});

sample({
	clock: $ship,
	fn: (ship) =>
		Boolean(
			(ship?.mainInfo && ship.mainInfo.length > 0) ||
				(ship?.touristProduct && ship?.touristProduct.length > 0) ||
				(ship?.crew && ship?.crew.length > 0),
		),
	target: $hasInfo,
});

const ecommerce = createEvent();

const ecommerceFx = createEffect(ecommerceRequests.getShipInfo);

sample({
	clock: ecommerce,
	source: $ship,
	fn: (source) => ({ shipId: source!.id.toString() }),
	target: ecommerceFx,
});

const pushEcommerce = createEffect((data: EcommerceShip & { shipId?: number; name?: string }) => {
	// @ts-ignore
	window.dataLayer.push(
		getFilledEcommerceObject({
			ecommerce: {
				// @ts-ignore
				currencyCode: CURRENCY[data.currency ?? 1].toUpperCase(),
				detail: {
					products: [
						{
							id: data.shipId?.toString(),
							name: data.name,
							brand: data.brand,
						},
					],
				},
			},
		}),
	);
});

sample({
	clock: ecommerceFx.doneData,
	source: $ship,
	fn: (source, clock) => ({ ...clock, shipId: source?.id, name: source?.name }),
	target: pushEcommerce,
});

export const model = {
	$ship,
	$status,
	$hasInfo,
	$errorCode,
	getShip,
	getShipFx,
	ecommerce,
};
