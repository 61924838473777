import { status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { bonusCardRequests } from 'store/account/api/bonus-card';
import { GetBonusCard200Response } from 'store/auth/types/account/get-bonus-card-200-response';
import { getFailCode } from 'api';
// import { bonusCardRequests } from 'shared/api/requests';
// import { GetBonusCard200Response } from 'shared/lib/types/account/models';
// import { getFailCode } from 'shared/lib/utils';

const getBonusCard = createEvent();
const getBonusCardFx = createEffect(bonusCardRequests.getBonusCard);

const $card = createStore<Nullable<GetBonusCard200Response>>(null);
const $status = status({ effect: getBonusCardFx });
const $errorCode = createStore<Nullable<number>>(null);

sample({
	clock: getBonusCard,
	target: getBonusCardFx,
});

sample({
	clock: getBonusCardFx.doneData,
	target: $card,
});

sample({
	clock: getBonusCardFx.done,
	fn: () => null,
	target: $errorCode,
});

sample({
	clock: getBonusCardFx.failData,
	fn: (error) => getFailCode(error) ?? null,
	target: $errorCode,
});

const $onboarded = createStore<Nullable<boolean>>(null);
const $onboardingSuccess = createStore<boolean>(false);

const setOnboarded = createEvent<boolean>();

const onboardingBonusCard = createEvent();
const onboardingBonusCardFx = createEffect(bonusCardRequests.sendBonusCardOnboarding);

sample({
	clock: setOnboarded,
	target: $onboarded,
});

sample({
	clock: getBonusCardFx.doneData,
	source: $onboarded,
	filter: (source) => source === null,
	fn: (_, { isOnboarded }) => Boolean(isOnboarded),
	target: setOnboarded,
});

sample({
	clock: onboardingBonusCard,
	source: $onboardingSuccess,
	filter: (source) => !source,
	target: onboardingBonusCardFx,
});

sample({
	clock: onboardingBonusCardFx.done,
	fn: () => true,
	target: $onboardingSuccess,
});

const registerBonusCard = createEvent();
const registerBonusCardFx = createEffect(bonusCardRequests.sendBonusCardRegister);

const $registerStatus = status({ effect: registerBonusCardFx });
const $registerErrorCode = createStore<Nullable<number>>(null);

sample({
	clock: registerBonusCard,
	target: registerBonusCardFx,
});

sample({
	clock: registerBonusCardFx.done,
	fn: () => false,
	target: [setOnboarded, getBonusCard],
});

sample({
	clock: registerBonusCardFx.done,
	fn: () => null,
	target: $registerErrorCode,
});

sample({
	clock: registerBonusCardFx.failData,
	fn: (error) => getFailCode(error) ?? null,
	target: $registerErrorCode,
});

export const model = {
	$card,
	$status,
	$errorCode,
	getBonusCard,
	$registerErrorCode,
	$registerStatus,
	registerBonusCard,
	$onboarded,
	setOnboarded,
	onboardingBonusCard,
};
