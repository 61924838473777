import { FC, ReactNode } from 'react';
// import { Checkbox, Price, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Checkbox } from 'layout/components/inputs/checkbox';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';

interface DiscountsCheckboxProps {
	onChange: () => void;
	isSelected: boolean;
	title: ReactNode;
	amount: number;
}

export const DiscountsCheckbox: FC<DiscountsCheckboxProps> = ({
	isSelected,
	onChange,
	title,
	amount,
}) => (
	<Checkbox isSelected={isSelected} onChange={onChange}>
		<div className={styles.checkbox}>
			<Typo design="description-m">{title}</Typo>
			<Typo design="headline-s" className={styles.checkbox__price}>
				- <Price total={amount} bold />
			</Typo>
		</div>
	</Checkbox>
);
