import React, { memo, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-react-form';
import { requestModel } from 'entities/order';
// import { Input, Alert } from 'shared/ui/atoms';
// import { FormField } from 'shared/ui/organisms';
import {
	CustomerFormKeys,
	customerFormValidationScheme,
	getMaskProps,
	onChangeTrimValue,
} from '../../lib';
import { model } from '../../model/guest-data';
import { Adress } from './adress';
import { Offices } from './offices';
import { PassportData } from './passport-data';
import { Reffer } from './referrer';
import styles from './styles.module.scss';
import { FormField } from 'layout/components/inputs/form/field';
import { Input } from 'layout/components/inputs/input';
import { Alert } from 'layout/components/view/alert';

export const OrderCustomerForm = memo(() => {
	const { form } = model;
	const { controller } = useForm({
		form,
		resetUnmount: false,
	});
	const [isExpanded, setIsExpanded] = useState(false);
	const [bitrixRequest] = useUnit([requestModel.requestBitrix]);

	const getFieldProps = (name: CustomerFormKeys) => ({
		form,
		controller,
		name,
		validation: customerFormValidationScheme[name],
		size: 'lg' as const,
		withLabel: true,
	});

	const getTextFieldProps = () => ({
		maxLength: 255,
		onChangeValueParser: onChangeTrimValue,
		...getMaskProps(),
	});

	const onBlurHandler = () => {
		bitrixRequest();
	};

	return (
		<div className={styles.form}>
			<div className={classnames(styles.personal, isExpanded && styles.personal_isExpanded)}>
				<div className={styles.grid}>
					<FormField
						placeholder="Фамилия"
						secondPlaceholder="Введите фамилию"
						onBlurCapture={onBlurHandler}
						{...getFieldProps('lastName')}
						{...getTextFieldProps()}
					>
						<Input isRequired />
					</FormField>
					<FormField
						placeholder="Имя"
						secondPlaceholder="Введите имя"
						onBlurCapture={onBlurHandler}
						{...getFieldProps('firstName')}
						{...getTextFieldProps()}
					>
						<Input isRequired />
					</FormField>
					<FormField
						placeholder="Отчество"
						secondPlaceholder="Введите отчество"
						onBlurCapture={onBlurHandler}
						{...getFieldProps('middleName')}
						{...getTextFieldProps()}
					>
						<Input />
					</FormField>
				</div>

				<div className={styles.grid}>
					<FormField
						placeholder="Телефон"
						secondPlaceholder="+70000000000"
						masked
						onBlurCapture={onBlurHandler}
						{...getFieldProps('phone')}
						{...getMaskProps('phone')}
					>
						<Input isRequired />
					</FormField>
					<FormField
						placeholder="E-mail"
						secondPlaceholder="user@mail.com"
						maxLength={250}
						onBlurCapture={onBlurHandler}
						{...getFieldProps('email')}
						{...getMaskProps('email')}
					>
						<Input />
					</FormField>
				</div>
				<div>
					<Alert className={styles.alert} type="warning">
						<span>
							Для оформления договора и оплаты будут необходимы паспортные данные Заказчика.
							<br />
							Их можно добавить позже или{' '}
							<button className={styles.alert__button} onClick={() => setIsExpanded(true)}>
								заполнить сейчас
							</button>
						</span>
					</Alert>
					<AnimateHeight height={isExpanded ? 'auto' : 0} duration={400}>
						<PassportData />
						<Adress />
					</AnimateHeight>
				</div>
				{/* <Offices /> */}
				<Reffer />
			</div>
		</div>
	);
});
