export const getSrcset = (url: string) => {
	const decodedUrl = decodeURI(url);

	return {
		sizes:
			'(max-width: 639px) 640vw, (max-width: 1023px) 1024vw, (max-width: 1239px) 1240vw, 1304vw',
		srcSet: `/_next/image?url=${decodedUrl}&w=360&q=75 360w, /_next/image?url=${decodedUrl}&w=640&q=75 640w, /_next/image?url=${decodedUrl}&w=1024&q=75 1024w, /_next/image?url=${decodedUrl}&w=1240&q=75 1240w, /_next/image?url=${decodedUrl}&w=1304&q=75 1304w`,
		src: `/_next/image?url=${decodedUrl}&w=1304&q=75 1304w`,
		dummy: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
	};
};
