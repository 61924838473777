// import { SEARCH_CRUISES_LIMIT } from 'shared/config';
// import { CruiseModel, CruiseTableCabins, PageableList } from 'shared/lib/types';

import { SEARCH_CRUISES_LIMIT } from "config/constants/search";
import { CruiseModel, CruiseTableCabins } from "config/types/cabins/models/cruise";
import { PageableList } from "config/types/core";

export const cruisePreviewA: CruiseModel = {
	id: 401_334,
	type: 'river',
	images: [
		{ src: '/mock/pic-01.jpg', alt: '' },
		{ src: '/mock/pic-01.jpg', alt: '' },
		{ src: '/mock/pic-01.jpg', alt: '' },
	],
	company: null,
	popular: false,
	date: {
		gte: '2023-09-10T13:00:00+00:00',
		lte: '2023-09-12T09:00:00+00:00',
	},
	length: {
		days: 3,
		nights: 2,
	},
	route: {
		short: 'Ростов-на-Дону — Волгоград — Москва',
		full: 'Ростов-на-Дону — Волгоград – Углич – Мышкин – Кузино – Кижи – Мандроги – Валаам – Санкт–Петербург — Москва',
	},
	price: {
		gte: 29_848.0,
		lte: 46_683.0,
	},
	defaultPrice: 32_800,
	ship: {
		id: 91,
		name: 'Дмитрий Фурманов',
		type: 22,
		new: true,
	},
	cabins: {
		free: 114,
		types: [
			{
				id: 15_827,
				name: 'Делюкс (средняя палуба)',
				mainPrice: 30_504,
				default: 32_800,
			},
			{
				id: 877,
				name: '2-местная (средняя палуба)',
				mainPrice: 38_502,
				default: 41_400,
			},
			{
				id: 874,
				name: '1-местная (шлюпочная палуба)',
				mainPrice: 36_735,
				default: 39_500,
			},
			{
				id: 15_828,
				name: 'Делюкс (шлюпочная палуба)',
				mainPrice: 47_709,
				default: 51_300,
			},
			{
				id: 876,
				name: '2-местная (шлюпочная палуба)',
				mainPrice: 38_502,
				default: 41_400,
			},
			{
				id: 3698,
				name: 'Делюкс (главная палуба)',
				mainPrice: 44_082,
				default: 47_400,
			},
			{
				id: 873,
				name: 'Люкс',
				mainPrice: 62_403,
				default: 67_100,
			},
			{
				id: 875,
				name: '1-местная (средняя палуба)',
				mainPrice: 47_709,
				default: 51_300,
			},
			{
				id: 879,
				name: '3-местная 2-ярусная (нижняя палуба)',
				mainPrice: 41_478,
				default: 44_600,
			},
		],
	},
	startCity: {
		id: 27,
		name: 'Ростов-на-Дону',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	endCity: {
		id: 6,
		name: 'Москва',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	startPort: {
		id: 12,
		name: 'Речной вокзал',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	endPort: {
		id: 14,
		name: 'Речной вокзал',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	weekend: true,
	oneWay: true,
	timetables: [
		{
			id: 2_808_914,
			date: '2023-09-10 13:00:00',
			timetablePlace: 'Ростов-на-Дону',
			city: {
				id: 27,
				name: 'Ростов-на-Дону',
				country: {
					id: 1,
					name: 'Россия',
				},
			},
		},
		{
			id: 2_808_915,
			date: '2023-09-11 12:00:00',
			timetablePlace: 'Волгоград',
			city: {
				id: 6,
				name: 'Волгоград',
				country: {
					id: 1,
					name: 'Россия',
				},
			},
		},
		{
			id: 2_808_916,
			date: '2023-09-12 09:00:00',
			timetablePlace: 'Москва',
			city: {
				id: 6,
				name: 'Москва',
				country: {
					id: 1,
					name: 'Россия',
				},
			},
		},
	],
	suggestions: [
		{ id: 124, title: '-20% при предоплате', type: 1, priority: 1 },
		{ id: 125, title: '-15% раннее бронирование', type: 1, priority: 1 },
		{ id: 126, title: '-25% спецпредложение', type: 1, priority: 1 },
	],
	isRussianGroup: false,
	regions: [
		{
			id: 1,
			name: 'Россия',
		},
	],
	rivers: [
		{
			id: 8,
			name: 'Волга',
		},
	],
	popularRoute: [
		{
			id: 401_334,
			name: 'Круизы за баллы КЛК',
			description: '',
			icon: '',
			showIconAndDescription: false,
		},
	],
};

export const cruisePreviewB: CruiseModel = {
	id: 401_473,
	type: 'river',
	images: [
		{ src: '/mock/pic-02.jpg', alt: '' },
		{ src: '/mock/pic-02.jpg', alt: '' },
		{ src: '/mock/pic-02.jpg', alt: '' },
	],
	company: null,
	popular: false,
	date: {
		gte: '2023-09-10T13:00:00+00:00',
		lte: '2023-09-12T09:00:00+00:00',
	},
	length: {
		days: 3,
		nights: 2,
	},
	route: {
		short: 'Ростов-на-Дону — Москва',
		full: 'Москва – Углич – Ярославль',
	},
	price: {
		gte: 31_213.0,
		lte: 48_776.0,
	},
	defaultPrice: 34_300,
	ship: {
		id: 91,
		name: 'Дмитрий Фурманов',
		type: 22,
		new: false,
	},
	cabins: {
		free: 9,
		types: [
			{
				id: 875,
				name: '1-местная (средняя палуба)',
				mainPrice: 31_899,
				default: 34_300,
			},
			{
				id: 877,
				name: '2-местная (средняя палуба)',
				mainPrice: 40_269,
				default: 43_300,
			},
			{
				id: 874,
				name: '1-местная (шлюпочная палуба)',
				mainPrice: 38_409,
				default: 41_300,
			},
			{
				id: 876,
				name: '2-местная (шлюпочная палуба)',
				mainPrice: 49_848,
				default: 53_600,
			},
			{
				id: 879,
				name: '3-местная 2-ярусная (нижняя палуба)',
				mainPrice: 40_269,
				default: 43_300,
			},
		],
	},
	startCity: {
		id: 27,
		name: 'Ростов-на-Дону',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	endCity: {
		id: 6,
		name: 'Москва',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	startPort: {
		id: 12,
		name: 'Речной вокзал',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	endPort: {
		id: 14,
		name: 'Речной вокзал',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	weekend: true,
	oneWay: true,
	timetables: [
		{
			id: 2_808_914,
			date: '2023-09-10 13:00:00',
			timetablePlace: 'Ростов-на-Дону',
			city: {
				id: 27,
				name: 'Ростов-на-Дону',
				country: {
					id: 1,
					name: 'Россия',
				},
			},
		},
		{
			id: 2_808_915,
			date: '2023-09-11 12:00:00',
			timetablePlace: 'Москва',
			city: {
				id: 6,
				name: 'Москва',
				country: {
					id: 1,
					name: 'Россия',
				},
			},
		},
	],
	suggestions: [{ id: 124, title: '-20% при предоплате', type: 1, priority: 1 }],
	isRussianGroup: false,
	regions: [
		{
			id: 1,
			name: 'Россия',
		},
	],
	rivers: [
		{
			id: 8,
			name: 'Волга',
		},
	],
	popularRoute: [
		{
			id: 401_473,
			name: 'Круизы за баллы КЛК',
			description: '',
			icon: '',
			showIconAndDescription: false,
		},
	],
};

export const cruisePreviewC: CruiseModel = {
	id: 401_654,
	type: 'river',
	name: '«Рекордный круиз»: закрытие навигации 2022!',
	images: [
		{ src: '/mock/pic-03.jpg', alt: '' },
		{ src: '/mock/pic-03.jpg', alt: '' },
		{ src: '/mock/pic-03.jpg', alt: '' },
	],
	company: null,
	popular: false,
	date: {
		gte: '2023-09-10T13:00:00+00:00',
		lte: '2023-09-12T09:00:00+00:00',
	},
	length: {
		days: 4,
		nights: 3,
	},
	route: {
		short: 'Москва — Кижи — Валаам — Санкт-Петербург',
		full: 'Москва – Углич – Мышкин – Кузино – Кижи – Мандроги – Валаам – Санкт–Петербург',
	},
	price: {
		gte: 48_412.0,
		lte: 75_803.0,
	},
	defaultPrice: 53_200,
	ship: {
		id: 91,
		name: 'Дмитрий Фурманов',
		type: 22,
		new: true,
	},
	cabins: {
		free: 35,
		types: [
			{
				id: 15_827,
				name: 'Делюкс (средняя палуба)',
				mainPrice: 49_476,
				default: 53_200,
			},
			{
				id: 877,
				name: '2-местная (средняя палуба)',
				mainPrice: 62_589,
				default: 67_300,
			},
			{
				id: 15_828,
				name: 'Делюкс (шлюпочная палуба)',
				mainPrice: 59_613,
				default: 64_100,
			},
			{
				id: 876,
				name: '2-местная (шлюпочная палуба)',
				mainPrice: 77_469,
				default: 83_300,
			},
			{
				id: 3698,
				name: 'Делюкс (главная палуба)',
				mainPrice: 62_589,
				default: 67_300,
			},
		],
	},
	startCity: {
		id: 27,
		name: 'Москва',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	endCity: {
		id: 6,
		name: 'Санкт-Петербург',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	startPort: {
		id: 12,
		name: 'Речной вокзал',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	endPort: {
		id: 14,
		name: 'Речной вокзал',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	weekend: true,
	oneWay: true,
	timetables: [
		{
			id: 2_808_914,
			date: '2023-09-10 13:00:00',
			timetablePlace: 'Москва',
			city: {
				id: 27,
				name: 'Москва',
				country: {
					id: 1,
					name: 'Россия',
				},
			},
		},
		{
			id: 2_808_915,
			date: '2023-09-11 12:00:00',
			timetablePlace: 'Кижи',
			city: {
				id: 6,
				name: 'Кижи',
				country: {
					id: 1,
					name: 'Россия',
				},
			},
		},
		{
			id: 2_808_916,
			date: '2023-09-12 09:00:00',
			timetablePlace: 'Валаам',
			city: {
				id: 6,
				name: 'Валаам',
				country: {
					id: 1,
					name: 'Россия',
				},
			},
		},
		{
			id: 2_808_916,
			date: '2023-09-13 09:00:00',
			timetablePlace: 'Санкт-Петербург',
			city: {
				id: 6,
				name: 'Санкт-Петербург',
				country: {
					id: 1,
					name: 'Россия',
				},
			},
		},
	],
	suggestions: [
		{ id: 124, title: '-20% при предоплате', type: 1, priority: 1 },
		{ id: 125, title: '-15% раннее бронирование', type: 1, priority: 1 },
		{ id: 126, title: '-25% спецпредложение', type: 1, priority: 1 },
	],
	isRussianGroup: false,
	regions: [
		{
			id: 1,
			name: 'Россия',
		},
	],
	rivers: [
		{
			id: 8,
			name: 'Волга',
		},
	],
	popularRoute: [
		{
			id: 401_654,
			name: 'Круизы за баллы КЛК',
			description: '',
			icon: '',
			showIconAndDescription: false,
		},
	],
};

export const cruisePreviewD: CruiseModel = {
	id: 401_123,
	type: 'sea',
	images: [
		{ src: '/mock/pic-04.jpg', alt: '' },
		{ src: '/mock/pic-04.jpg', alt: '' },
		{ src: '/mock/pic-04.jpg', alt: '' },
	],
	popular: false,
	date: {
		gte: '2023-09-10T13:00:00+00:00',
		lte: '2023-09-12T09:00:00+00:00',
	},
	length: {
		days: 4,
		nights: 3,
	},
	route: {
		short:
			'Неаполь (Италия) – Генуя (Италия) – Марсель (Франция) – Барселона (Испания) – Ла Гулет (Тунис) – Палермо (Италия) – Неаполь (Италия)',
		full: 'Неаполь (Италия) – Генуя (Италия) – Марсель (Франция) – Барселона (Испания) – Ла Гулет (Тунис) – Палермо (Италия) – Неаполь (Италия)',
	},
	price: {
		gte: 115_000.0,
		lte: 300_000.0,
	},
	defaultPrice: 0,
	ship: {
		id: 91,
		name: 'MSC Grandiosa',
		type: 22,
		new: true,
	},
	cabins: {
		free: 0,
		types: [
			{
				id: 15_827,
				name: 'Делюкс (средняя палуба)',
				mainPrice: 215_000,
				default: 0,
			},
			{
				id: 877,
				name: '2-местная (средняя палуба)',
				mainPrice: 200_000,
				default: 0,
			},
			{
				id: 15_828,
				name: 'Делюкс (шлюпочная палуба)',
				mainPrice: 165_000,
				default: 0,
			},
			{
				id: 876,
				name: '2-местная (шлюпочная палуба)',
				mainPrice: 185_000,
				default: 0,
			},
			{
				id: 3698,
				name: 'Делюкс (главная палуба)',
				mainPrice: 300_000,
				default: 0,
			},
		],
	},
	startCity: {
		id: 27,
		name: 'Санкт-Петербург',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	endCity: {
		id: 6,
		name: 'Санкт-Петербург',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	startPort: {
		id: 12,
		name: 'Речной вокзал',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	endPort: {
		id: 14,
		name: 'Речной вокзал',
		country: {
			id: 1,
			name: 'Россия',
		},
	},
	weekend: true,
	oneWay: true,
	timetables: [
		{
			id: 2_808_914,
			date: '2023-09-10 13:00:00',
			timetablePlace: 'Неаполь',
			city: {
				id: 2,
				name: 'Неаполь',
				country: {
					id: 12,
					name: 'Италия',
				},
			},
		},
		{
			id: 2_808_915,
			date: '2023-09-11 12:00:00',
			timetablePlace: 'Генуя',
			city: {
				id: 3,
				name: 'Генуя',
				country: {
					id: 12,
					name: 'Италия',
				},
			},
		},
		{
			id: 2_808_916,
			date: '2023-09-12 09:00:00',
			timetablePlace: 'Марсель',
			city: {
				id: 4,
				name: 'Марсель',
				country: {
					id: 13,
					name: 'Франция',
				},
			},
		},
		{
			id: 2_808_917,
			date: '2023-09-13 09:00:00',
			timetablePlace: 'Барселона',
			city: {
				id: 5,
				name: 'Барселона',
				country: {
					id: 14,
					name: 'Испания',
				},
			},
		},
		{
			id: 2_808_918,
			date: '2023-09-13 09:00:00',
			timetablePlace: 'Ла Гулет',
			city: {
				id: 6,
				name: 'Ла Гулет',
				country: {
					id: 15,
					name: 'Тунис',
				},
			},
		},
		{
			id: 2_808_919,
			date: '2023-09-14 13:00:00',
			timetablePlace: 'Палермо',
			city: {
				id: 2,
				name: 'Палермо',
				country: {
					id: 12,
					name: 'Италия',
				},
			},
		},
		{
			id: 2_808_920,
			date: '2023-09-15 13:00:00',
			timetablePlace: 'Неаполь',
			city: {
				id: 2,
				name: 'Неаполь',
				country: {
					id: 12,
					name: 'Италия',
				},
			},
		},
	],
	suggestions: [],
	isRussianGroup: false,
	regions: [
		{
			id: 1,
			name: 'Россия',
		},
	],
	rivers: [
		{
			id: 8,
			name: 'Волга',
		},
	],
	popularRoute: [
		{
			id: 401_123,
			name: 'Круизы за баллы КЛК',
			description: '',
			icon: '',
			showIconAndDescription: false,
		},
	],
};

export const getCruisesMock = ({
	limit = SEARCH_CRUISES_LIMIT,
	offset = 0,
}): PageableList<CruiseModel, CruiseTableCabins> => {
	const count = 24;
	const next = offset + limit < count ? `?offset=${offset + limit}` : null;
	const previous = offset > 0 ? `?offset=${offset - limit}` : null;
	const overlimit = Math.min(count - (offset + limit), 0);

	return {
		count,
		limit,
		offset,
		next,
		previous,
		results: new Array<any>(limit + overlimit).fill(null).map((_, i) => {
			const id = i + offset + count;

			if (i % 4 === 1) {
				return { ...cruisePreviewB, id };
			}
			if (i % 4 === 2) {
				return { ...cruisePreviewC, id };
			}
			if (i % 4 === 3) {
				return { ...cruisePreviewD, id };
			}

			return { ...cruisePreviewA, id };
		}),
		formatted: {
			pricesTable: {},
		},
	};
};
