/* eslint-disable react/no-array-index-key */
import React, { FC, memo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
// import { useRouter } from 'next/router';
import { useUnit } from 'effector-react';
// import { CruiseFeature } from 'entities/cruise-feature';
import { cruiseModel } from 'entities/cruises/model';
// import { OrderRoutes } from 'shared/config';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { setAnalytics } from 'shared/lib/utils';
// import { Condition, MoreToggle, Skeleton, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { useLocation, useRouter } from '@tanstack/react-router';
import { ANALYTICS_TARGETS } from 'config/constants';
import { OrderRoutes } from 'config/constants/order';
import { MoreToggle } from 'layout/components/buttons/more-toggle';
import { Skeleton } from 'layout/components/special/skeleton';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { CruiseFeature } from 'entities/cruise-feature';

export interface RequestCruiseFeaturesProps {}

export const RequestCruiseFeatures: FC<RequestCruiseFeaturesProps> = memo(() => {
	const [expanded, setExpanded] = useState(false);
	const [cruise, cruiseStatus] = useUnit([cruiseModel.$cruise, cruiseModel.$status]);

	const items = cruise?.includedInPrice?.items ?? [];

	// const router = useRouter();
	const location = useLocation()
	const pathname = location.pathname.split('?')[0];

	if (pathname.includes(OrderRoutes.Confirmation)) {
		return null;
	}

	if (cruiseStatus !== 'pending' && items.length === 0) {
		return null;
	}

	return (
		<div className={styles.box}>
			<div className={styles.head}>
				<Typo design="headline-xs" freezeDesktop>
					Входит в стоимость
				</Typo>

				<Condition
					value={cruiseStatus === 'pending'}
					then={<Skeleton margin="4px 0 1px" width={60} height={12} />}
					else={
						<MoreToggle
							className={styles.head__toggle}
							onClick={() => {
								setExpanded((prev) => !prev);

								if (!expanded) {
									// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_whats_included);
								}
							}}
							isExpanded={expanded}
						/>
					}
				/>
			</div>

			<AnimateHeight height={expanded ? 'auto' : 0} duration={400}>
				<div className={styles.content}>
					{items?.map((item, index) => (
						<CruiseFeature
							key={`${index}-${encodeURI(item.title ?? '').slice(0, 255)}`}
							{...item}
						/>
					))}
				</div>
			</AnimateHeight>
		</div>
	);
});
