import React, { PropsWithChildren, forwardRef, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { TabsTheme } from './tabs';
import styles from './styles.module.scss';

export interface TabNavProps extends PropsWithChildren {
	active: Nullable<number>;
	theme?: TabsTheme;
	className?: string;
	classNameNav?: string;
}

export const TabsNav = forwardRef<HTMLElement, TabNavProps>(
	({ active, className, classNameNav, children, theme }, forwardedRef) => {
		const ref = useRef<Nullable<HTMLElement>>(null);

		const [reachEnd, setReachEnd] = useState(false);
		const [reachStart, setReachStart] = useState(true);

		const handleScroll = (node?: HTMLElement) => {
			if (!node) {
				return;
			}

			setReachEnd(node.scrollWidth - node.scrollLeft === node.offsetWidth);
			setReachStart(node.scrollLeft === 0);
		};

		useEffect(() => {
			const scroller = ref?.current;

			if (scroller && active !== null) {
				const element = [...scroller.querySelectorAll<HTMLElement>('button, a')][active];
				const offset = Math.max(0, element?.offsetLeft || 0);

				if ('scrollBehavior' in document.documentElement.style) {
					scroller.scrollTo({
						left: offset,
						behavior: 'smooth',
					});
				} else {
					scroller.scrollTo(offset, 0);
				}
			}
		}, [active]);

		return (
			<nav
				ref={forwardedRef}
				className={classnames(className, theme && styles[`tabs__head_${theme}`])}
			>
				<div className={classnames(styles.fade, { [styles.isActive]: !reachStart })} />
				<div className={classnames(styles.fade, { [styles.isActive]: !reachEnd })} />
				<div
					role="tablist"
					ref={(node) => {
						if (node) {
							ref.current = node;
							handleScroll(node as HTMLElement);
						}
					}}
					onScrollCapture={(e) => {
						handleScroll(e.target as HTMLElement);
					}}
					className={classnames(classNameNav, theme && styles[`tabs__nav_${theme}`])}
				>
					{children}
				</div>
			</nav>
		);
	},
);
