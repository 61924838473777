export const ACCOUNT_URL = '/api/account/v1';
export const REQUESTS_URL = `${ACCOUNT_URL}/requests`;

export const BONUS_CARD = '/images/account/card@2x.jpg';
export const BONUS_CARD_GOLD_STATUS = 350_000;
export const BONUS_CARD_WELCOME_BONUS = 1000;

export const PRINT_BONUS_CARD_BG = '/images/account/print-bonus-card.png';

export const JOIN_TO_BONUS_PROGRAM_BANNER = '/images/account/club-banner@2x.jpg';
export const JOIN_TO_BONUS_PROGRAM_BANNER_MOB = '/images/account/club-banner-mob@2x.jpg';

export const PROFILE_CRUISES_BANNER = '/images/account/profile-cruises@2x.jpg';
export const PROFILE_CRUISES_BANNER_MOB = '/images/account/profile-cruises-mob@2x.jpg';
export const PROFILE_INVITE_FRIEND = '/images/account/invite-friend.png';
export const PROFILE_SHARE_POINTS = '/images/account/share-points.png';

export const PROMOCODE_FRIEND = '/images/account/promocode-friend.png';

export const DEFAULT_MOSCOW_OFFICE_ID = 1;
export const DEFAULT_CITIZENSHIP_ID = 235;

export enum AccountRequestsStatuses {
	Canceled = 0,
	Return = 1,
	AwaitingReturn = 2,
	AwaitingConfirmation = 10,
	WaitingList = 11,
	CancellationRequest = 12,
	InProcessing = 13,
	Confirmed = 20,
	Prepayment = 21,
	Paid = 30,
	SoldByAgency = 31,
	ForFree = 32,
	AwaitingPayment = 33,
}

export enum AccountRoutes {
	Profile = '/account/profile',
	MyRequests = '/account/my-requests',
	MyRequestsActive = '/account/my-requests?status=active',
	MyBonuses = '/account/my-bonuses',
	PersonalData = '/account/personal',
	Notifications = '/account/notifications',
}

export const PROFILE_TYPES = {
	TYPE_RETAIL: 0,
	TYPE_AGENCY: 1,
	TYPE_INDIVIDUAL: 2,
	TYPE_SELF_EMPLOYED: 3,
	TYPE_STAFF: 4,
};
