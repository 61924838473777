import { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';

interface KlkBonusProps {
	children: ReactNode;
	className?: string;
}
export const KlkBonus = ({ children, className }: KlkBonusProps) => (
	<div className={classnames(styles.bonus, className)}>
		<Icon className={styles.bonus__icon} id="klk" />
		<Typo design="input-text-m">{children}</Typo>
	</div>
);
