export const MONTHS_SHORT = [
	'янв',
	'фев',
	'мар',
	'апр',
	'май',
	'июн',
	'июл',
	'авг',
	'сен',
	'окт',
	'ноя',
	'дек',
];

export const SECONDS_IN_YEAR = 60 * 60 * 24 * 365;
