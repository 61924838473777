import { FC, useMemo, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useUnit } from 'effector-react';
import { orderCabinsModel } from 'entities/order';
// import { PreviewPassengerAvailableDiscount, PreviewPassengerDiscount } from 'shared/lib/types';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { DiscountsCheckbox } from './discounts-checkbox';
import styles from './styles.module.scss';
import { PreviewPassengerAvailableDiscount, PreviewPassengerDiscount } from 'config/types/prices';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';

interface DiscountsProps {
	availableDiscounts?: PreviewPassengerAvailableDiscount[];
	discounts?: PreviewPassengerDiscount[];
	pending?: boolean;
	cabinId: number;
	index: number;
	selectedDiscounts: string[];
}

export const Discounts: FC<DiscountsProps> = ({
	availableDiscounts,
	discounts,
	pending,
	cabinId,
	selectedDiscounts,
	index,
}) => {
	const selectDiscount = useUnit(orderCabinsModel.selectDiscount);

	const [availableDiscountsList, availableDiscountsRest] = useMemo(() => {
		const array = availableDiscounts ?? [];

		return [array.slice(0, 3), array.slice(3)];
	}, [availableDiscounts]);

	const [expanded, setExpanded] = useState(false);

	// if (pending) {
	// 	return <Skeleton width="70%" height={100} />;
	// }

	return (
		<div className={styles.wrapper}>
			{discounts && discounts.length > 0 && (
				<div className={styles.block}>
					<Typo design="headline-s">Применённые скидки</Typo>
					{discounts.map(({ title, value, type, id }) => {
						if (!title) {
							return null;
						}

						return (
							<div key={id + title} className={styles.discount}>
								<Icon id="check" width={20} height={20} />
								{Boolean(value) && (
									<Typo className={styles.percent} design="headline-s">
										{value}
										{type && ` ${type}`}
									</Typo>
								)}
								<Typo className={styles.text} design="text-s">
									{title}
								</Typo>
							</div>
						);
					})}
				</div>
			)}

			{availableDiscounts && availableDiscounts.length > 0 && (
				<div className={styles.block}>
					<div className={styles.info}>
						<Typo design="headline-s">Дополнительные скидки</Typo>
						<Typo design="text-s">
							Отметьте пункты на которые вы сможете предоставить подтверждающие документы.
							Окончательная цена за место изменится после проверки менеджером.
						</Typo>
					</div>

					<div className={styles.availableDiscountsColumn}>
						<div className={styles.availableDiscountsList}>
							{availableDiscountsList.map(({ id, title, amount, typeAlias, discount }) => {
								if (!amount || !typeAlias || !title) {
									return null;
								}

								return (
									<DiscountsCheckbox
										key={`${cabinId}-${id}-${title}`}
										isSelected={selectedDiscounts.includes(title)}
										onChange={() => {
											selectDiscount({
												discount: title,
												cabinId,
												passengerIndex: index,
											});
										}}
										title={
											<>
												{discount ? <>{discount}%&nbsp;&mdash; </> : null}
												{title}
											</>
										}
										amount={amount}
									/>
								);
							})}
						</div>

						{availableDiscountsRest.length > 0 && (
							<>
								<AnimateHeight height={expanded ? 'auto' : 0} duration={400}>
									<div
										className={classnames(
											styles.availableDiscountsList,
											styles.availableDiscountsList_foldable,
										)}
									>
										{availableDiscountsRest.map(({ id, title, amount, typeAlias, discount }) => {
											if (!amount || !title || !typeAlias) {
												return null;
											}

											return (
												<DiscountsCheckbox
													key={`${cabinId}-${id}-${title}`}
													isSelected={selectedDiscounts.includes(title)}
													onChange={() => {
														selectDiscount({
															discount: title,
															cabinId,
															passengerIndex: index,
														});
													}}
													title={
														<>
															{discount ? <>{discount}%&nbsp;&mdash; </> : null}
															{title}
														</>
													}
													amount={amount}
												/>
											);
										})}
									</div>
								</AnimateHeight>

								<Button
									size="middle"
									btnType="text"
									onClick={() => setExpanded((prev) => !prev)}
									className={classnames(styles.moreAvailableDiscountsBtn, {
										[styles.isExpanded]: expanded,
									})}
								>
									<Typo as="span" design="button-l">
										{expanded ? 'Свернуть' : 'Показать все'}
									</Typo>
									<Icon id="chevron-down" width={20} height={20} />
								</Button>
							</>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
