import { createContext, FC, ReactNode, useContext } from 'react';

type PriceFormatInfo = Nullable<{ trunc?: number }> | undefined;

const PriceFormatContext = createContext<PriceFormatInfo>(null);

type PriceFormatProviderProps = {
	children: ReactNode;
	priceFormat: PriceFormatInfo;
};

export const PriceFormatContextProvider: FC<PriceFormatProviderProps> = ({
	children,
	priceFormat,
}) => <PriceFormatContext.Provider value={priceFormat}>{children}</PriceFormatContext.Provider>;

export const usePriceFormatContext = () => useContext(PriceFormatContext);
