import React, { FC, RefObject, ReactNode, memo, useEffect, useState } from 'react';
import classnames from 'classnames';
// import { NAV_SCROLL_OFFSET_MOB } from 'shared/config';
// import { Condition, MobileView, Price, Skeleton, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { NAV_SCROLL_OFFSET_MOB } from 'config/commonConstants';
import { Skeleton } from 'layout/components/special/skeleton';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';
import { MobileView } from 'layout/layouts/device-view';

export interface RequestTotalProps {
	total?: number;
	pending?: boolean;
	asideRef?: RefObject<HTMLElement>;
	controls?: ReactNode;
}

export const RequestTotalSticky: FC<RequestTotalProps> = memo(
	({ total, controls, pending, asideRef }) => {
		const aside = asideRef?.current;
		const [active, setActive] = useState(false);

		useEffect(() => {
			const handleScroll = () => {
				if (!aside) {
					return;
				}

				const { top } = aside.getBoundingClientRect();

				setActive(top - NAV_SCROLL_OFFSET_MOB >= 0);
			};

			handleScroll();
			window.addEventListener('scroll', handleScroll);

			return () => window.removeEventListener('scroll', handleScroll);
		}, [aside]);

		const showTotal = pending || Boolean(total);

		return (
			<MobileView>
				<div className={classnames(styles.stickyPanel, { [styles.isActive]: active })}>
					{showTotal && (
						<Typo className={styles.stickyPanel__total} design="headline-m" freezeDesktop>
							<span>Итого</span>
							<Condition
								value={pending}
								then={<Skeleton className={styles.stickyPanel__skeleton} width={98} height={19} />}
								else={
									total !== undefined ? (
										<Price as="strong" bold trunc={0} total={total} />
									) : undefined
								}
							/>
						</Typo>
					)}

					{controls}
				</div>
			</MobileView>
		);
	},
);
