// import { CURRENCY } from 'shared/config/currency';
// import { IconId } from 'shared/ui/icons';
// import { CurrencyType } from '../../types';

import { CURRENCY } from "config/constants/currency";
import { CurrencyType } from "config/types/cruise/models/cruise";
import { IconId } from "layout/components/icons/svg-sprite";

export const getCurrency = (defaultCurrency: IconId, currency?: CurrencyType): IconId => {
	const currentCurrency = currency ? CURRENCY[currency] : 0;

	switch (currentCurrency) {
		case 'eur':
			return 'euro' as IconId;
		default:
			return defaultCurrency;
	}
};
