import { combineEvents, status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { cartModel } from 'entities/cart';
import { orderCabinsModel } from 'entities/order/cabins';
import { PricesPreviews, PreviewPassengerDiscount, PreviewPassengerAvailableDiscount } from 'config/types/prices';
import { GetPricesPreviewRequest, pricesRequests } from 'store/cruise/prices';
// import { GetPricesPreviewRequest, pricesRequests } from 'shared/api/requests/prices';
// import {
// 	PreviewPassengerAvailableDiscount,
// 	PreviewPassengerDiscount,
// 	PricesPreviews,
// } from 'shared/lib/types';

const $pricesPreview = createStore<Nullable<PricesPreviews>>(null);

const getPricesPreview = createEvent<GetPricesPreviewRequest>();
const getPricesPreviewFx = createEffect(pricesRequests.getPricesPreview);

const $pricesPreviewStatus = status({ effect: getPricesPreviewFx });
const $getPricesPreviewStatus = status({ effect: cartModel.getCartPricesFx });

sample({
	clock: getPricesPreview,
	target: getPricesPreviewFx,
});

sample({
	clock: [getPricesPreviewFx.doneData, cartModel.getCartPricesFx.doneData],
	target: $pricesPreview,
});

/* *
 * Список скидок по всем каютам и пассажирам
 * type === 3 - это пассажир-призрак, то есть место свободно
 */
const $pricesPreviewDiscounts = createStore<PreviewPassengerDiscount[]>([]);

sample({
	clock: $pricesPreview,
	filter: Boolean,
	fn: ({ cabins }) => {
		if (cabins) {
			const passengers = cabins
				.flatMap((cabin) => cabin.passengers ?? [])
				.filter(({ type }) => type !== 3);

			return passengers.flatMap((passenger) => passenger.discounts ?? []);
		}

		return [];
	},
	target: $pricesPreviewDiscounts,
});

/* *
 * Список доступных скидок по всем каютам и пассажирам
 * type === 3 - это пассажир-призрак, то есть место свободно
 */
const $pricesPreviewAvailableDiscounts = createStore<PreviewPassengerAvailableDiscount[]>([]);

const onSelectDiscount = combineEvents({
	events: [orderCabinsModel.selectDiscount, orderCabinsModel.$cabins.updates],
});

sample({
	clock: [$pricesPreview.updates, onSelectDiscount],
	source: {
		cabins: orderCabinsModel.$cabins,
		prices: $pricesPreview,
	},
	filter: ({ prices }) => Boolean(prices),
	fn: ({ cabins, prices }) => {
		if (prices?.cabins) {
			const passengers = prices.cabins
				.flatMap((cabin) => cabin.passengers ?? [])
				.filter(({ type }) => type !== 3);

			/* *
			 * Все доступные скидки
			 * return passengers.flatMap((passenger) => passenger.availableDiscounts ?? []);
			 */

			/* *
			 * Только выбранные у пассажиров
			 */
			const passengersInCart = cabins
				.flatMap((cabin) => cabin.passengers ?? [])
				.filter(({ type }) => type !== 3);

			return passengers.flatMap((passenger, passengerIndex) => {
				const selectedDiscountsByPassenger =
					passengersInCart[passengerIndex]?.selectedDiscounts ?? [];

				return (
					passenger.availableDiscounts?.filter(
						({ title }) => title && selectedDiscountsByPassenger.includes(title),
					) ?? []
				);
			});
		}

		return [];
	},
	target: $pricesPreviewAvailableDiscounts,
});

const $pricesPreviewAvailableDiscountsMerged = $pricesPreviewAvailableDiscounts.map((list) => {
	const map = list.reduce<Map<number, PreviewPassengerAvailableDiscount>>((result, item) => {
		if (item.id) {
			const prev = result.get(item.id);

			if (!prev) {
				result.set(item.id, item);
			} else {
				result.set(item.id, {
					...prev,
					amount: (prev.amount ?? 0) + (item.amount ?? 0),
				});
			}
		}

		return result;
	}, new Map());

	return [...map].map(([_, value]) => value);
});

const $pricesPreviewAvailableDiscountsSum = createStore(0);

sample({
	clock: $pricesPreviewAvailableDiscounts,
	fn: (clock) =>
		clock.reduce((result, { amount }) => {
			result += amount ?? 0;

			return result;
		}, 0),
	target: $pricesPreviewAvailableDiscountsSum,
});

export const model = {
	$pricesPreview,
	$pricesPreviewStatus,
	$getPricesPreviewStatus,
	getPricesPreview,
	$pricesPreviewDiscounts,
	$pricesPreviewAvailableDiscounts,
	$pricesPreviewAvailableDiscountsMerged,
	$pricesPreviewAvailableDiscountsSum,
};
