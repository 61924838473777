import React, { memo } from 'react';
import { useUnit } from 'effector-react';
import { pricesModel } from 'entities/order';
// import { Typo, Price, Alert } from 'shared/ui/atoms';
import { ConfirmationLabel } from '../confirmation-block-wrapper';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { Price } from 'layout/components/view/price';

export const PriceInfo = memo(() => {
	const prices = useUnit(pricesModel.$pricesPreview);

	return (
		<div>
			<ConfirmationLabel label="Стоимость" />
			<div className={styles.totalPanel}>
				<Typo className={styles.totalPrice} design="headline-m" freezeDesktop>
					{prices?.total && (
						<>
							<span>Итого</span>
							<Price as="strong" total={prices.total} bold />
						</>
					)}
				</Typo>
				<Alert className={styles.alert}>
					Окончательный расчет скидок — после уточнения данных пассажиров
				</Alert>
			</div>
		</div>
	);
});
