import React, { FC, memo } from 'react';
import classnames from 'classnames';
// import { DAYS_DECLENSION, NIGHTS_DECLENSION } from 'shared/lib/constants';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { formatDate } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Typo, TypoDesignToken } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { getDayTime } from '../lib';
import { CruiseDurationLength } from './cruise-duration-length';
import styles from './styles.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { DAYS_DECLENSION, NIGHTS_DECLENSION } from 'config/constants';
import { Icon } from 'layout/components/icons/icon';
import { TypoDesignToken, Typo } from 'layout/components/typo/ui';
import { useViewport } from 'layout/viewport';
import { formatDate } from 'utils/date';

export interface CruiseDurationProps extends DefaultStyledProps {
	dateStart?: string;
	dateEnd?: string;
	days?: number;
	nights?: number | boolean;
	displayTime?: boolean;
	sizeVariant?: 'large' | 'x-large' | 'xl-large';
	shortFormat?: boolean;
	hideTime?: boolean;
}

export const CruiseDuration: FC<CruiseDurationProps> = memo(
	({
		dateStart,
		dateEnd,
		days,
		nights,
		sizeVariant,
		shortFormat = false,
		displayTime = true,
		hideTime = false,
		className,
	}) => {
		const { isMob } = useViewport();
		const displayNights = typeof nights === 'number' && nights > 0;

		let designToken: TypoDesignToken =
			sizeVariant === 'large' && !isMob ? 'description-m' : 'description-features';

		if (sizeVariant === 'x-large') {
			designToken = displayNights ? 'description-m' : 'text-m';
		}

		const dateFormatTpl = shortFormat ? 'D MMM YYYY' : 'D MMMM YYYY';

		return (
			<Typo
				className={classnames(styles.duration, sizeVariant && styles[sizeVariant], className, {
					[styles.withNights]: typeof nights === 'number' && nights > 0,
				})}
				design={designToken}
			>
				<div className={styles.date}>
					<div>{formatDate(dateStart, dateFormatTpl)}</div>
					{displayTime && <div className={styles.time}>{getDayTime(dateStart, hideTime)}</div>}
				</div>
				{dateEnd && (
					<>
						{days && days > 0 && (
							<div className={styles.days}>
								<CruiseDurationLength count={days} declension={DAYS_DECLENSION} />
								{displayNights && (
									<CruiseDurationLength count={nights} declension={NIGHTS_DECLENSION} />
								)}
								<Icon id="arrow-right" />
							</div>
						)}
						<div className={styles.date}>
							<div>{formatDate(dateEnd, dateFormatTpl)}</div>
							{displayTime && <div className={styles.time}>{getDayTime(dateEnd, hideTime)}</div>}
						</div>
					</>
				)}
			</Typo>
		);
	},
);
