// /* eslint-disable import/no-extraneous-dependencies */
// /* eslint-disable unicorn/consistent-destructuring */
import { useState } from 'react';
import { MenuTriggerState, useMenuTriggerState } from '@react-stately/menu';

import type { OverlayTriggerProps } from '@react-types/overlays';
import type {
	AsyncLoadable,
	CollectionBase,
	FocusableProps,
	InputBase,
	LabelableProps,
	MultipleSelection,
	TextInputBase,
	Validation,
} from '@react-types/shared';
import {
	FilterFn,
	MultiSelectListState,
	useMultiSelectListState,
} from './use-multi-select-list-state';

export interface MultiSelectProps<T>
	extends CollectionBase<T>,
		AsyncLoadable,
		Omit<InputBase, 'isReadOnly'>,
		Validation,
		LabelableProps,
		TextInputBase,
		MultipleSelection,
		FocusableProps,
		OverlayTriggerProps {
	/**
	 * Whether the menu should automatically flip direction when space is limited.
	 * @default true
	 */
	shouldFlip?: boolean;
	/** Select name */
	name?: string;
	/** The filter function used to determine if a option should be included in the combo box list. */
	defaultFilter?: FilterFn;
	/** The filter function used to determine if a option should be included in the combo box list. */
	checkCollectionSize?: boolean;
}

export interface MultiSelectState<T> extends MultiSelectListState<T>, MenuTriggerState {
	/** Whether the select is currently focused. */
	isFocused: boolean;
	/** Sets whether the select is focused. */
	setFocused(isFocused: boolean): void;
}

export function useMultiSelectState<T extends {}>(props: MultiSelectProps<T>): MultiSelectState<T> {
	const [isFocused, setFocused] = useState(false);
	const { checkCollectionSize = true } = props;

	const triggerState = useMenuTriggerState(props);
	const listState = useMultiSelectListState({
		...props,
		onSelectionChange: (keys) => {
			if (props.onSelectionChange != null) {
				if (keys === 'all') {
					// This may change back to "all" once we will implement async loading of additional
					// items and differentiation between "select all" vs. "select visible".
					props.onSelectionChange(new Set(listState.collection.getKeys()));
				} else {
					props.onSelectionChange(keys);
				}
			}

			// Multi select stays open after item selection
			if (props.selectionMode === 'single') {
				triggerState.close();
			}
		},
	});

	return {
		...listState,
		...triggerState,
		close() {
			triggerState.close();
		},
		open() {
			// Don't open if the originalCollection is empty.
			if (listState.originalCollection.size !== 0 || !checkCollectionSize) {
				triggerState.open();
			}
		},
		toggle(focusStrategy) {
			// Don't open if the originalCollection is empty.
			if (listState.originalCollection.size !== 0 || !checkCollectionSize) {
				triggerState.toggle(focusStrategy);
			}
		},
		isFocused,
		setFocused,
	};
}
