import { FC } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useGate } from 'effector-react';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { Modal } from 'shared/ui/molecules';
import { userRoleModel } from '../../model';
import { UnauthorizedOrderAttempt } from './unauthorized-order-attempt';
import styles from './styles.module.scss';
import { Modal } from 'layout/components/modal';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';

interface UserRoleModalProps { }

export const UserRoleModal: FC<UserRoleModalProps> = () => {
	const state = useOverlayTriggerState({});
	const { isOpen } = state;

	useGate(userRoleModel.ModalGate, state);

	const shouldMount = useDelayUnmount(isOpen, 600);

	if (!shouldMount) {
		return null;
	}

	return (
		<Modal
			isDismissable
			state={state}
			isShown={isOpen}
			classNameModal={styles.userRoleModal}
			verticalAlign="middle"
			className={styles.zIndex1051}
		>
			<UnauthorizedOrderAttempt />
		</Modal>
	);
};
