import React, { FC, PropsWithChildren, ReactElement } from 'react';
import classnames from 'classnames';
import { AriaRadioGroupProps, useRadioGroup } from 'react-aria';
import { useRadioGroupState } from 'react-stately';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { Typo } from 'shared/ui/atoms';
import { RadioGroupContext } from './lib/context';
import style from './style.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { Typo } from 'layout/components/typo/ui';

export interface RadioGroupProps
	extends AriaRadioGroupProps,
		PropsWithChildren,
		DefaultStyledProps {
	className?: string;
	classNameOptions?: string;
	error?: JSX.Element;
	showCaption?: boolean;
	theme?: 'secondary';
	columnOptionsList?: boolean;
	customLabel?: ReactElement;
}

export const RadioGroup: FC<RadioGroupProps> = ({ theme, error, ...props }) => {
	const {
		children,
		className,
		classNameOptions,
		label,
		description,
		errorMessage,
		validationState,
		showCaption,
		customLabel,
		isRequired,
		columnOptionsList,
	} = props;
	const state = useRadioGroupState(props);
	const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } = useRadioGroup(
		props,
		state,
	);

	const isShowError = error || (errorMessage && validationState === 'invalid');

	return (
		<fieldset
			className={classnames(style.radioGroup, theme && style[theme], className)}
			{...radioGroupProps}
		>
			{label && showCaption && !customLabel && (
				<Typo
					design="description-features"
					{...labelProps}
					className={classnames(
						labelProps.className,
						style.radioGroup__label,
						isRequired && style.radioGroup_isRequired,
					)}
				>
					{label}
				</Typo>
			)}
			{customLabel && customLabel}
			<RadioGroupContext.Provider value={state}>
				<div
					className={classnames(classNameOptions, style.options, {
						[style.isColumn]: columnOptionsList,
					})}
				>
					{children}
				</div>
			</RadioGroupContext.Provider>
			{description && <div {...descriptionProps}>{description}</div>}
			{isShowError && (
				<Typo
					design="input-text-s"
					aria-radio-errormessage="true"
					{...errorMessageProps}
					className={classnames(style.radioGroup__error, 'errormessage')}
				>
					<>{error || errorMessage}</>
				</Typo>
			)}
		</fieldset>
	);
};
