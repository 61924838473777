export const cardTypes: Record<number, string> = {
	0: 'Стандартный статус',
	1: 'Золотой статус',
};

export const cardStatuses = {
	0: 'Не выдана',
	1: 'Запрошена по почте',
	2: 'Выслана по почте',
	3: 'Выдана',
};
