import React from 'react';
import classNames from 'classnames';
import { OverlayTriggerState } from 'react-stately';
import { getPlaces } from 'entities/cabin-booking-cards/ui/cabin-description-modal/lib/get-places';
import { ScreenType } from 'entities/cabin-booking-cards/ui/cabin-description-modal/model/model';
import { CabinDescriptionModalFooter } from 'entities/cabin-booking-cards/ui/cabin-description-modal/ui/cabin-description-modal-footer';
// import { DetailedCruiseCabin } from 'shared/lib/types';
// import { Button, CartButton } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { DetailedCruiseCabin } from 'config/types/cabins';
import { Button } from 'layout/components/buttons/button';
import { CartButton } from 'layout/components/buttons/cart-button';
import { Icon } from 'layout/components/icons/icon';

interface FooterProps {
	cruiseId: number;
	modalState: OverlayTriggerState;
	showSecondScreenFooter: boolean;
	showFirstScreenFooter: boolean;
	isAdded: boolean;
	pending: boolean;
	initialPending: boolean;
	cabinInfo: Nullable<DetailedCruiseCabin.Cabin>;
	setScreen: (payload: ScreenType) => void;
}

export const Footer = React.memo(
	({
		cruiseId,
		modalState,
		showFirstScreenFooter,
		showSecondScreenFooter,
		pending,
		initialPending,
		isAdded,
		cabinInfo,
		setScreen,
	}: FooterProps) => (
		<div>Footer</div>
		// <>
		// 	{showSecondScreenFooter && (
		// 		<CabinDescriptionModalFooter
		// 			id={cabinInfo?.id}
		// 			name={cabinInfo?.name}
		// 			category={cabinInfo?.category}
		// 			cruiseId={cruiseId}
		// 			places={cabinInfo ? getPlaces(cabinInfo) : undefined}
		// 			status={cabinInfo?.status}
		// 			pending={pending}
		// 			initialPending={initialPending}
		// 			isBonusPayment={Boolean(cabinInfo?.isBonusPayment)}
		// 		/>
		// 	)}
		// 	{showFirstScreenFooter && (
		// 		<div
		// 			className={classNames(
		// 				styles.buttonWrapper,
		// 				modalState.isOpen && styles.buttonWrapperActive,
		// 			)}
		// 		>
		// 			{!isAdded && (
		// 				<Button
		// 					size="large"
		// 					className={styles.button}
		// 					onClick={() => setScreen(1)}
		// 					disabled={initialPending}
		// 				>
		// 					<Icon id="cart" width={20} height={20} />
		// 					Добавить в заказ
		// 				</Button>
		// 			)}
		// 			{isAdded && (
		// 				<CartButton
		// 					onClick={() => setScreen(1)}
		// 					className={styles.button}
		// 					status=""
		// 					isAdded
		// 					fullView
		// 					changeIconOnHover={false}
		// 				/>
		// 			)}
		// 		</div>
		// 	)}
		// </>
	),
);
