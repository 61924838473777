import React, { FC } from 'react';
import { SvgSprite } from './svg-sprite';

export type ExcursionTypesId =
	| 'bus-excursion'
	| 'bus-walk-excursion'
	| 'water-excursion'
	| 'walk-excursion';

export const ExcursionTypesIcons: FC = () => (
	<SvgSprite>
		<symbol id="bus-excursion" viewBox="0 0 20 20">
			<path
				d="M3.75 9.5835H16.25V14.1668C16.25 14.6271 15.8769 15.0002 15.4167 15.0002H4.58333C4.1231 15.0002 3.75 14.6271 3.75 14.1668V9.5835Z"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<path
				d="M3.75 3.33333C3.75 2.8731 4.1231 2.5 4.58333 2.5H15.4167C15.8769 2.5 16.25 2.8731 16.25 3.33333V9.58333H3.75V3.33333Z"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<path
				d="M6.25 17.5C5.55962 17.5 5 16.9404 5 16.25V15H7.5V16.25C7.5 16.9404 6.94037 17.5 6.25 17.5Z"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.75 17.5C13.0596 17.5 12.5 16.9404 12.5 16.25V15H15V16.25C15 16.9404 14.4404 17.5 13.75 17.5Z"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M2.5 5V6.66667" stroke="#94A0A9" strokeWidth="1.5" strokeLinecap="round" />
			<path d="M17.5 5V6.66667" stroke="#94A0A9" strokeWidth="1.5" strokeLinecap="round" />
			<path
				d="M6.33464 12.5003C6.33464 12.5463 6.29733 12.5837 6.2513 12.5837C6.20528 12.5837 6.16797 12.5463 6.16797 12.5003C6.16797 12.4543 6.20528 12.417 6.2513 12.417C6.29733 12.417 6.33464 12.4543 6.33464 12.5003Z"
				fill="#94A0A9"
				stroke="#94A0A9"
				strokeWidth="1.5"
			/>
			<path
				d="M13.8346 12.5003C13.8346 12.5463 13.7973 12.5837 13.7513 12.5837C13.7053 12.5837 13.668 12.5463 13.668 12.5003C13.668 12.4543 13.7053 12.417 13.7513 12.417C13.7973 12.417 13.8346 12.4543 13.8346 12.5003Z"
				fill="#94A0A9"
				stroke="#94A0A9"
				strokeWidth="1.5"
			/>
			<path
				d="M12.918 2.5L9.16797 6.66667"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M15.8333 2.9165L13.75 5.4165"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</symbol>
		<symbol id="bus-walk-excursion" viewBox="0 0 20 20">
			<path
				d="M18.5 15.0002H13.0833C12.6231 15.0002 12.25 14.6271 12.25 14.1668V9.5835H18.5"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<path
				d="M18.5 2.5H13.0833C12.6231 2.5 12.25 2.8731 12.25 3.33333V9.58333H18.5"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<path
				d="M14.75 17.5C14.0596 17.5 13.5 16.9404 13.5 16.25V15H16V16.25C16 16.9404 15.4404 17.5 14.75 17.5Z"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M11 5V6.66667" stroke="#94A0A9" strokeWidth="1.5" strokeLinecap="round" />
			<path
				d="M14.8327 12.4998C14.8327 12.5459 14.7954 12.5832 14.7493 12.5832C14.7033 12.5832 14.666 12.5459 14.666 12.4998C14.666 12.4538 14.7033 12.4165 14.7493 12.4165C14.7954 12.4165 14.8327 12.4538 14.8327 12.4998Z"
				fill="#94A0A9"
				stroke="#94A0A9"
				strokeWidth="1.5"
			/>
			<path
				d="M5.5 9C4.67158 9 4 8.32842 4 7.5C4 6.67157 4.67158 6 5.5 6C6.32842 6 7 6.67157 7 7.5C7 8.32842 6.32842 9 5.5 9Z"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeMiterlimit="2"
			/>
			<path
				d="M9 12.2909L7.69253 10.6364L6.27274 10L6.8404 12.5455L5.25067 14.2L4 17"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeMiterlimit="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.45311 9.837L7.68072 14.1303L9.51883 16.583"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeMiterlimit="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6 10L5 11.5L4 12"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeMiterlimit="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</symbol>
		<symbol id="water-excursion" viewBox="0 0 20 20">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.5 8.4905L10 5.8335L17.5 8.4905L15.2778 14.1668H4.72221L2.5 8.4905Z"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.41797 3.33301H14.5846L14.5836 7.45638L10.0013 5.83301L5.41797 7.45626V3.33301Z"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<path
				d="M10 3.33317V1.6665"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 9.99984V6.6665"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.16797 16.667L5.6263 18.3337L7.08464 16.667L8.54297 18.3337L10.0013 16.667L11.4596 18.3337L12.918 16.667L14.3763 18.3337L15.8346 16.667"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</symbol>
		<symbol id="walk-excursion" viewBox="0 0 20 20">
			<path
				d="M11.2513 6.24967C12.4019 6.24967 13.3346 5.31692 13.3346 4.16634C13.3346 3.01575 12.4019 2.08301 11.2513 2.08301C10.1007 2.08301 9.16797 3.01575 9.16797 4.16634C9.16797 5.31692 10.1007 6.24967 11.2513 6.24967Z"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeMiterlimit="2"
			/>
			<path
				d="M6 10.5L7.91797 8.33333L10.0007 7.5L9.16797 10.8333L11.5 13L13.3346 16.6667"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeMiterlimit="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.0007 7.5L8.33256 13.3338L5.8349 16.6665"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeMiterlimit="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.5 18.3335H16.6667"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeMiterlimit="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 7.5L11.6667 10L13.3333 10.8333"
				stroke="#94A0A9"
				strokeWidth="1.5"
				strokeMiterlimit="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</symbol>
	</SvgSprite>
);
