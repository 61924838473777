import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import SimpleBar, { Props } from 'simplebar-react';
import styles from './styles.module.scss';
import 'simplebar-react/dist/simplebar.min.css';

interface ScrollerProps extends Omit<Props, 'onScroll'> {
	height?: string;
	width?: string;
	theme?: 'gray' | 'blue';
	showOnHover?: boolean;
	onScroll?: ScrollerCallbackFn;
}

export type ScrollerCallbackFn = ({ y, x }: { y: number; x: number }, reachedEnd?: boolean) => void;

export const Scroller = ({
	height,
	children,
	width,
	className,
	theme = 'gray',
	showOnHover = true,
	onScroll,
	...props
}: ScrollerProps) => {
	const ref = useRef<any>();

	useEffect(() => {
		if (!onScroll) {
			return;
		}

		const wrapper = ref?.current?.contentWrapperEl;
		const content = ref?.current?.contentEl;

		const scrollHandler = () => {
			const scrollY = wrapper?.scrollTop ?? 0;
			const scrollX = wrapper?.scrollLeft ?? 0;

			const scrollHeight = content && wrapper ? content.offsetHeight - wrapper.offsetHeight : 0;

			onScroll({ y: scrollY, x: scrollX }, scrollY >= scrollHeight);
		};

		scrollHandler();
		wrapper?.addEventListener('scroll', scrollHandler);

		const resizeObserver = new ResizeObserver(scrollHandler);
		resizeObserver.observe(wrapper);

		return () => {
			wrapper?.removeEventListener('scroll', scrollHandler);
			resizeObserver.unobserve(wrapper);
		};
	}, [onScroll]);

	return (
		<SimpleBar
			ref={ref}
			style={{ height, width }}
			className={classNames(
				styles.scroller,
				styles[theme],
				showOnHover && styles.showOnHover,
				className,
			)}
			{...props}
			autoHide={false}
		>
			{children}
		</SimpleBar>
	);
};
