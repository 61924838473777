// import { SendCallbackRequest } from 'shared/api';
// import { message } from 'shared/lib/message';
// import { ValidationOptions } from 'shared/ui/organisms/form/lib/validation';

import { message } from "config/constants/message";
import { ValidationOptions } from "layout/components/inputs/form/lib/validation";
import { SendCallbackRequest } from "store/support";

export type SendCallbackRequestKey = keyof Omit<SendCallbackRequest, 'captcha'>;

export const callbackValidationScheme: Record<SendCallbackRequestKey, ValidationOptions> = {
	name: {
		required: false,
		validateType: 'max250',
		messages: {
			validateType: message.form.validation.format.max250,
		},
	},
	phone: {
		required: true,
		messages: {
			required: message.form.validation.required,
			validateType: 'Неверный формат телефона',
		},
	},
	agreeProcessData: {
		required: true,
		messages: {
			required: 'Подтвердите согласие на обработку данных, чтобы заказать обратный звонок.',
		},
	},
};
