import { FC, useRef } from 'react';
import classnames from 'classnames';
import { ListState } from 'react-stately';
import type { Node } from '@react-types/shared';
import { SelectionMode } from '@react-types/shared';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { SelectGroupOptionMeta, useGroupedOption } from 'shared/ui/molecules/select/lib';
import style from './style.module.scss';
import { SelectGroupOptionMeta, useGroupedOption } from 'layout/components/inputs/selects/select/lib';
import { Typo } from 'layout/components/typo/ui';
import { Icon } from 'layout/components/icons/icon';

export interface OptionProps {
	item: Node<unknown>;
	meta: SelectGroupOptionMeta;
	state: ListState<unknown>;
	selectionMode?: SelectionMode;
	count?: number;
	classNameModalGroupOption?: string;
}

export const GroupedOption: FC<OptionProps> = (props) => {
	const ref = useRef(null);
	const { item, state, selectionMode, meta, count, classNameModalGroupOption } = props;
	const { optionProps, isSelected } = useGroupedOption({ key: item.key, meta }, state, ref);
	const isCheckbox = selectionMode === 'multiple';

	return (
		<Typo
			{...optionProps}
			ref={ref}
			className={classnames(style.option, classNameModalGroupOption, {
				[style.isCheckbox]: isCheckbox,
			})}
			design="description-m"
		>
			<span>{item.rendered}</span>
			{count && <small>{count}</small>}
			{(isSelected || isCheckbox) && <Icon id={isCheckbox ? 'check' : 'done-bold'} />}
		</Typo>
	);
};
