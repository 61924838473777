import React, { FC, memo } from 'react';
// import { Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';

interface ConfirmationBlockWrapperProps {
	label: string;
}

export const ConfirmationLabel: FC<ConfirmationBlockWrapperProps> = memo(({ label }) => (
	<Typo className={styles.label} design="headline-s" freezeDesktop>
		{label}
	</Typo>
));
