import React, { FC, memo } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useEvent, useGate } from 'effector-react';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { useViewport } from 'shared/lib/viewport';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { model } from '../model';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';
import { useViewport } from 'layout/viewport';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';

export interface CartWarningProps {}

export const CartWarning: FC<CartWarningProps> = memo(() => {
	const { isMob } = useViewport();

	const state = useOverlayTriggerState({});
	const shouldMount = useDelayUnmount(state.isOpen, 600);

	const cancelCartWarning = useEvent(model.cancelCartWarning);
	const clearCartAndCreateNew = useEvent(model.clearCartAndCreateNew);

	useGate(model.GateWarningModal, state);

	if (!shouldMount) {
		return null;
	}

	return (
		<Modal
			state={state}
			isShown={state.isOpen}
			className={styles.cartWarningModal}
			classNameModal={styles.cartWarningModal__box}
			classNameModalContent={styles.cartWarningModal__content}
			closeButtonClassName={styles.cartWarningModal__closeButton}
			isDismissable
			withCrossButton={false}
		>
			<div className={styles.cartWarning}>
				<Typo design={isMob ? 'headline-xl' : 'headline-m'}>Текущий заказ будет очищен</Typo>
				<Typo design="text-s" className={styles.cartWarning__text}>
					<p>
						У вас в заказе уже добавлены каюты из другого круиза, если вы хотите добавить новый –
						прежний заказ будет очищен.
					</p>
				</Typo>
				<div className={styles.cartWarning__footer}>
					<Button size="large" btnType="outline" onClick={clearCartAndCreateNew}>
						Очистить и создать новый
					</Button>
					<Button size="large" btnType="primary" onClick={cancelCartWarning}>
						Отменить
					</Button>
				</div>
			</div>
		</Modal>
	);
});
