import React, { FC, memo } from 'react';
import classnames from 'classnames';
// import Image from 'next/image';
// import { getQueryString } from 'entities/cruises/lib';
// import {
// 	PROFILE_CRUISES_BANNER,
// 	PROFILE_CRUISES_BANNER_MOB,
// 	FREE_ONLY_CHECKED,
// 	BONUS_PAYMENTS_CHECKED,
// } from 'shared/config';
// import { Button, Typo, DesktopView, MobileView, Skeleton } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { DesktopView, MobileView } from 'layout/layouts/device-view';
import { PROFILE_CRUISES_BANNER, PROFILE_CRUISES_BANNER_MOB } from 'store/auth/constants';
import { Typo } from 'layout/components/typo/ui';
import { Button } from 'layout/components/buttons/button';
import { Skeleton } from 'layout/components/special/skeleton';
import { getQueryString } from 'entities/cruises/lib';
import { BONUS_PAYMENTS_CHECKED, FREE_ONLY_CHECKED } from 'config/constants/search';

interface PremiumCruisesBannerProps {
	isLoading?: boolean;
	onClickButton?: () => void;
}

export const PremiumCruisesBanner: FC<PremiumCruisesBannerProps> = memo(
	({ isLoading, onClickButton }) => {
		if (isLoading) {
			return (
				<section className={classnames(styles.cruisesBanner, { [styles.isLoading]: isLoading })}>
					<Skeleton className={styles.cruisesBanner__skeleton} />
				</section>
			);
		}

		return (
			<section className={styles.cruisesBanner}>
				<DesktopView>
					<img src={PROFILE_CRUISES_BANNER}
					// objectFit="cover" layout="fill" 
					/>
				</DesktopView>
				<MobileView>
					<img src={PROFILE_CRUISES_BANNER_MOB}
					// objectFit="cover" layout="fill" 
					/>
				</MobileView>
				<Typo className={styles.cruisesBanner__title} design="headline-l">
					Подберите премиальный круиз из&nbsp;доступных предложений
				</Typo>
				<Button
					href={`/search?${getQueryString({ ...BONUS_PAYMENTS_CHECKED, ...FREE_ONLY_CHECKED })}`}
					size="large"
					btnType="outlineWhite"
					className={styles.cruisesBanner__button}
					onClick={onClickButton}
				>
					Подобрать круиз
				</Button>
			</section>
		);
	},
);
