import { ParsedUrlQuery } from 'querystring';
import { createEffect, createEvent, createStore, sample } from 'effector';


export interface ProfilatorParams {
	utm_source: string | string[];
	utm_medium: string | string[];
	utm_campaign: string | string[];
	utm_content: string | string[];
	utm_term: string | string[];
	referrer: string;
	clientId: Nullable<number>;
}

const setProfilator = createEvent<{ referrer: string; query: ParsedUrlQuery }>();

const $profilator = createStore<Nullable<ProfilatorParams>>(null);

const parseProfilator = () => JSON.parse(sessionStorage.getItem('UTM') || 'null');

const loadProfilatorFX = createEffect(parseProfilator);
const loadProfilatorSS = createEvent();

sample({
	clock: loadProfilatorSS,
	target: loadProfilatorFX,
});

sample({
	clock: loadProfilatorFX.doneData,
	target: $profilator,
});

sample({
	clock: setProfilator,
	fn: ({ referrer, query }) => {
		return null
		// const clientId = getClientYandexId();

		// return {
		// 	utm_source: query.utm_source ? query.utm_source : '',
		// 	utm_medium: query.utm_medium ? query.utm_medium : '',
		// 	utm_campaign: query.utm_campaign ? query.utm_campaign : '',
		// 	utm_content: query.utm_content ? query.utm_content : '',
		// 	utm_term: query.utm_term ? query.utm_term : '',
		// 	referrer,
		// 	clientId,
		// };
	},
	target: $profilator,
});

const syncbitrixLeadIdSSFx = createEffect(
	(bitrixLeadId: Nullable<ProfilatorParams | undefined>) => {
		sessionStorage.setItem('UTM', JSON.stringify(bitrixLeadId));
	},
);

sample({
	source: $profilator,
	target: syncbitrixLeadIdSSFx,
});

export const model = {
	setProfilator,
	$profilator,
	loadProfilatorSS,
	loadProfilatorFX,
};
