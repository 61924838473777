export const NEWS_ENDPOINT = '/api/content/v1/posts';
export const NEWS_ENABLE_MOCK = false;
export const NEWS_BREADCRUMBS = [
	{ text: 'Главная', href: '/' },
	{ text: 'Новости', href: '/news' },
];

export const NEWS_DEFAULT_PER_PAGE = 10;
export const NEWS_MAX_PER_PAGE = 100;

export const NEWS_CRUISES_SELECTION_CAT_ID = 1;
export const NEWS_PROMOTIONS_CAT_ID = 2;
export const NEWS_SCHEDULE_CHANGES_CAT_ID = 3;
export const NEWS_RECOMMENDATIONS_CAT_ID = 4;
export const NEWS_OPERATOR_NOTIFICATIONS_CAT_ID = 5;
export const NEWS_KLK = 6;
export const NEWS_SPECIAL_OFFERS_CAT_ID = 7;
export const NEWS_POPULAR_ROUTES_CAT_ID = 8;

export const NEWS_CRUISES_SELECTION_SUBCATS = {
	rus: 1,
	rivers: 2,
	sea: 3,
};

export const NEWS_POPULAR_ROUTES_SUBCATS = {
	rus: 6,
	rivers: 7,
	sea: 8,
};
