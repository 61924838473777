import { MutableRefObject, useEffect, useRef } from 'react';
import { useClientRect } from 'utils/hooks/use-client-rect';
// import { useClientRect } from 'shared/lib/hooks';

export const useTotalPanelOffset = (totalPanelRef: MutableRefObject<Nullable<HTMLElement>>) => {
	const contentRef = useRef<Nullable<HTMLDivElement>>(null);
	const totalPanelRect = useClientRect(totalPanelRef);

	useEffect(() => {
		const content = contentRef.current;
		if (content && totalPanelRect?.height) {
			content.style.setProperty('--total-panel-offset', `${totalPanelRect.height}px`);
		}
	}, [contentRef, totalPanelRect]);

	return contentRef;
};
