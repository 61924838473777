// import { TIMEZONE_DEFAULT } from 'shared/config';
// import { MDASH } from 'shared/lib/constants';
// import { formatDate } from 'shared/lib/utils';

import { TIMEZONE_DEFAULT } from "config/commonConstants";
import { MDASH } from "config/constants";
import { formatDate } from "utils/date";

export const getDayTime = (date?: string, hideTime?: boolean) => {
	if (hideTime) {
		return formatDate(date, `dd, ${MDASH}`, TIMEZONE_DEFAULT, true);
	}

	return formatDate(date, 'dd, HH:mm', TIMEZONE_DEFAULT, true);
};
