export const toggleTextEllipsis = (node?: Nullable<HTMLElement>) => {
	if (!node) {
		return false;
	}

	const ellipsis = node.scrollWidth > node.offsetWidth;

	node.classList.toggle('ellipsis', ellipsis);

	return ellipsis;
};
