import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import { useGate, useUnit } from 'effector-react';
// import { AccountLogout, AccountNavigation } from 'features/account-navigation';
// import { loadProfileModel } from 'features/load-profile';
// import { PremiumCruisesBanner } from 'entities/premium-cruises-banner';
// import { SpecialOffers } from 'entities/special-offers';
// import { SpecialOffersScreen } from 'entities/special-offers-screen';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { useProfileScreens } from 'shared/lib/hooks';
// import { setAnalytics } from 'shared/lib/utils';
// import { MobileView } from 'shared/ui/atoms';
// import { profileScreenModel } from '../../model';
import { ProfileBonuses } from './profile-bonuses';
import { ProfileHead } from './profile-head';
import { ProfileOffice } from './profile-office';
import { ProfileRequests } from './profile-requests';
import { ProfileSubscriptions } from './profile-subscriptions';
import { ProfileVerification } from './profile-verification';
import styles from './styles.module.scss';
import { loadProfileModel } from '../auth/load-profile';
import { AccountLogout, AccountNavigation } from 'layout/layouts/header/account-navigation';
import { profileScreenModel } from 'entities/account/model';
import { useProfileScreens } from 'utils/hooks/use-profile-screen';
import { MobileView } from 'layout/layouts/device-view';
import { SpecialOffers } from 'entities/special-offers';
import { PremiumCruisesBanner } from 'entities/premium-cruises-banner';
import { SpecialOffersScreen } from 'entities/special-offers-screen';

export const Profile = memo(() => {
	const [awaitingRequests, requestsStatus] = useUnit([
		profileScreenModel.$awaitingRequests,
		profileScreenModel.$awaitingRequestsStatus,
	]);
	const displayRequests = awaitingRequests.length > 0 || requestsStatus === 'pending';

	const status = useUnit(loadProfileModel.$status);
	const isLoading = status === 'pending' || requestsStatus === 'pending';

	useGate(profileScreenModel.Gate);

	const ref = useRef<HTMLElement>(null);
	const { currentScreen, screenState, changeScreen } = useProfileScreens({ ref });

	const handleClickPremiumCruisesBanner = () => {}
		// setAnalytics(ANALYTICS_TARGETS.account.account_pick_cruise);

	return (
		<section ref={ref} className={classnames(styles.profile, styles[screenState])}>
			{!currentScreen && (
				<>
					<ProfileHead title="Профиль" />

					<ProfileBonuses isLoading={isLoading} />

					<MobileView>
						<aside className={styles.profile__insertion}>
							<ProfileVerification />
							<AccountNavigation />
						</aside>
					</MobileView>

					{displayRequests && <ProfileRequests isLoading={isLoading} />}

					<ProfileOffice isLoading={isLoading} />

					{!displayRequests && (
						<>
							<SpecialOffers
								onClickAll={() => {
									changeScreen('specialOffers');
									// setAnalytics(ANALYTICS_TARGETS.account.account_all_offers);
								}}
							/>
							<PremiumCruisesBanner
								isLoading={isLoading}
								onClickButton={handleClickPremiumCruisesBanner}
							/>
						</>
					)}

					<ProfileSubscriptions />

					<MobileView>
						<aside className={styles.profile__logout}>
							<AccountLogout />
						</aside>
					</MobileView>
				</>
			)}
			{currentScreen === 'specialOffers' && (
				<>
					<SpecialOffersScreen onClickBack={() => changeScreen()} />
					<PremiumCruisesBanner
						isLoading={isLoading}
						onClickButton={handleClickPremiumCruisesBanner}
					/>
				</>
			)}
		</section>
	);
});