import { useUnit } from 'effector-react';
// import { Button } from 'shared/ui/atoms';
import { model } from '../../model';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';

export const LogoutFooter = () => {
	const [logout, closeModal] = useUnit([model.logout, model.closeModal]);

	return (
		<div className={styles.logoutFooter}>
			<Button className={styles.agreeButton} size="large" onClick={logout}>
				Да, выйти
			</Button>
			<Button size="large" onClick={closeModal} btnType="text">
				Отменить
			</Button>
		</div>
	);
};
