interface PrintProps {
	beforeAction?: () => void;
	delay?: number;
}

export const print = ({ beforeAction, delay = 100 }: PrintProps) =>
	new Promise<void>((resolve) => {
		if (beforeAction) {
			beforeAction();
		}

		const afterPrintHandler = () => {
			resolve();
			window.removeEventListener('afterprint', afterPrintHandler);
		};

		setTimeout(() => {
			window.addEventListener('afterprint', afterPrintHandler);
			window.print();
		}, delay);
	});
