export const stringifyObject = (obj: Record<string, any>) => {
	for (const prop in obj) {
		if (typeof obj[prop] === 'object') {
			obj[prop] = stringifyObject(obj[prop]);
		} else {
			obj[prop] = obj[prop]?.toString();
		}
	}

	return obj;
};
