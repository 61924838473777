import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { OverlayTriggerState } from 'react-stately';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { Modal } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';
import { Modal } from 'layout/components/modal';

interface AccountModalProps {
	state: OverlayTriggerState;
	footer?: ReactElement;
	verticalAlign?: 'top' | 'middle' | 'bottom';
	className?: string;
	classNameModal?: string;
	classNameModalContent?: string;
	classNameFooter?: string;
	children?: (close?: OverlayTriggerState['close']) => ReactElement;
}

export const AccountModal = ({
	state,
	footer,
	verticalAlign,
	className,
	classNameModal,
	classNameModalContent,
	classNameFooter,
	children,
}: AccountModalProps) => {
	const shouldMount = useDelayUnmount(state.isOpen, 600);

	if (!shouldMount) {
		return null;
	}

	return (
		<Modal
			state={state}
			isShown={state.isOpen}
			className={className}
			classNameModal={classnames(styles.reactModal, classNameModal)}
			classNameModalContent={classNameModalContent}
			closeButtonClassName={styles.modalCloseButton}
			classNameFooter={classNameFooter}
			verticalAlign={verticalAlign}
			footer={footer}
		>
			{children && React.cloneElement(children(state.close))}
		</Modal>
	);
};
