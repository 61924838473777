import React, { FC, memo } from 'react';
import classnames from 'classnames';
// import Image from 'next/image';
import { cardTypes, useCardHolderName } from 'entities/bonus-card/lib';
// import { BONUS_CARD, BONUS_CARD_GOLD_STATUS } from 'shared/config';
// import { DISCOUNT_POINTS } from 'shared/lib/constants';
// import { ProfileModel } from 'shared/lib/types';
// import { GetBonusCard200Response } from 'shared/lib/types/account/models';
// import { dischargeNumber, getNumDeclension } from 'shared/lib/utils';
// import { toggleTextEllipsis } from 'shared/lib/utils/dom';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { GetBonusCard200Response } from 'store/auth/types/account/get-bonus-card-200-response';
import { ProfileModel } from 'config/types/personal';
import { BONUS_CARD, BONUS_CARD_GOLD_STATUS } from 'store/auth/constants';
import { Typo } from 'layout/components/typo/ui';
import { toggleTextEllipsis } from 'utils/dom/toggle-text-ellipsis';
import { Icon } from 'layout/components/icons/icon';
import { dischargeNumber } from 'utils/number';
import { getNumDeclension } from 'utils/string';
import { DISCOUNT_POINTS } from 'config/constants';

interface BonusCardProps extends GetBonusCard200Response, Pick<ProfileModel, 'passportData'> {}

export const BonusCard: FC<BonusCardProps> = memo(
	({ passportData, cardNumber, cardType, cardBalance = 0, cardUntilGold = 0 }) => {
		const { lastName, firstName, middleName, initialLetter } = useCardHolderName({ passportData });

		const statusProgress = ((BONUS_CARD_GOLD_STATUS - cardUntilGold) / cardUntilGold) * 100;
		const isGoldStatus = cardType === 1 || cardUntilGold <= 0;

		return (
			<figure className={styles.bonusCard}>
				{/* <Image src={BONUS_CARD} objectFit="cover" layout="fill" /> */}
				<img src={BONUS_CARD} />

				<div className={styles.bonusCard__head}>
					<div className={styles.bonusCard__initial}>
						<span>{initialLetter}</span>
					</div>
					{lastName && firstName && (
						<Typo className={styles.bonusCard__name} design="button-l">
							<span
								ref={(node) => {
									toggleTextEllipsis(node);
								}}
							>
								{lastName}
							</span>
							{firstName} {middleName}
						</Typo>
					)}
					<Icon className={styles.bonusCard__clubIcon} id="klk" width={51} height={40} />
				</div>

				<div className={styles.bonusCard__info}>
					<div className={styles.bonusCard__scores}>
						<Typo design="headline-xl">{dischargeNumber(cardBalance)}</Typo>
						<Typo design="text-m">{getNumDeclension(cardBalance, DISCOUNT_POINTS)}</Typo>
					</div>

					{!isGoldStatus && (
						<>
							<div className={styles.bonusCard__progressBar}>
								<div
									className={styles.bonusCard__progressBarTrack}
									style={{ width: `${statusProgress > 0 ? Math.max(1, statusProgress) : 0}%` }}
								/>
							</div>

							<Typo className={styles.bonusCard__progress} design="description-features">
								до Золотого статуса &mdash; {dischargeNumber(cardUntilGold)}{' '}
								{getNumDeclension(cardUntilGold, DISCOUNT_POINTS)}
							</Typo>
						</>
					)}

					<div className={styles.bonusCard__footer}>
						<Typo
							className={classnames(styles.bonusCard__status, { [styles.gold]: cardType === 1 })}
							design="description-features"
						>
							<Icon id="diamond" width={12} height={12} />
							<span>{cardTypes[cardType ?? 0]}</span>
						</Typo>

						{cardNumber && (
							<Typo className={styles.bonusCard__cardNumber} design="headline-m">
								#&nbsp;{Number(cardNumber) ? dischargeNumber(Number(cardNumber)) : cardNumber}
							</Typo>
						)}
					</div>
				</div>
			</figure>
		);
	},
);
