import { debounce, status } from 'patronum';
import { OverlayTriggerState } from 'react-stately';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { cartModel } from 'entities/cart';
import { DetailedCruiseCabin } from 'config/types/cabins';
import { GetPricePreviewRequestCabinsInnerPassengersInnerTypeEnum } from 'config/types/prices';
import { modalFactory } from 'layout/components/modal';
import { GetDetailedCruiseCabinRequest } from 'store/cruise/cruises/cabins/index';
import { detailedCruiseCabinRequest } from 'store/cruise/cruises/cabins/index';
import { pricesModel } from 'entities/order';
// import { model as pricesModel } from 'entities/order/model/prices';
// import { detailedCruiseCabinRequest, GetDetailedCruiseCabinRequest } from 'shared/api';
// import {
// 	DetailedCruiseCabin,
// 	GetPricePreviewRequestCabinsInnerPassengersInnerTypeEnum,
// } from 'shared/lib/types';
// import { modalFactory } from 'shared/model';

export enum ScreenType {
	InfoScreen,
	PassengersScreen,
}

const { ModalGate, openModal } = modalFactory();
const {
	ModalGate: SuccessModalGate,
	openModal: openSuccessModal,
	closeModal: closeSuccessModal,
} = modalFactory();

const $screen = createStore<ScreenType>(0);
const setScreen = createEvent<ScreenType>();

const $lastParams = createStore<Nullable<GetDetailedCruiseCabinRequest>>(null);
const reloadInfo = createEvent();
const initialLoadInfo = createEvent<GetDetailedCruiseCabinRequest>();
const getDetailedCruiseCabinInfo = createEvent<GetDetailedCruiseCabinRequest>();
const getDetailedCruiseCabinInfoFx = createEffect(
	detailedCruiseCabinRequest.getDetailedCruiseCabinInfo,
);

const $detailedCruiseCabinInfo = createStore<Nullable<DetailedCruiseCabin.Cabin>>(null);
const $setOpenModal = createStore<Nullable<Pick<OverlayTriggerState, 'setOpen' | 'isOpen'>>>(null);
const $status = status({ effect: getDetailedCruiseCabinInfoFx });

const Gate = createGate<{ types: string[]; cruiseId: number; cabinId: number }>();

const $alonePassenger = createStore<boolean>(false);
const setAlonePassenger = createEvent<boolean>();
const changeType = createEvent<{ index: number; value: Nullable<string> }>();

/**
 * Установка нужного экрана (используется на мобильной версии)
 */
sample({
	clock: setScreen,
	target: $screen,
});

/**
 * При начальной загрузке всегда устанавливается первый экран
 */
sample({
	clock: initialLoadInfo,
	fn: () => 0,
	target: setScreen,
});

/**
 * При начальной загрузке сохраняем параметры,
 * чтобы была возможность повтроной загрузки при получении ошибки
 */
sample({
	clock: initialLoadInfo,
	target: $lastParams,
});

sample({
	source: $lastParams,
	filter: Boolean,
	clock: reloadInfo,
	target: initialLoadInfo,
});

/**
 * Информация о доплате
 */
sample({
	clock: $detailedCruiseCabinInfo,
	source: initialLoadInfo,
	filter: (_, clock) => Boolean(clock),
	fn: ({ cruiseId, cabinId }, cabinInfo) => {
		const passengers = cabinInfo?.price?.passengersPrice.map((elem) => ({
			type: +elem.passengerType as GetPricePreviewRequestCabinsInnerPassengersInnerTypeEnum,
		}));

		return {
			cruiseId: String(cruiseId),
			body: {
				cabins: [
					{
						id: cabinId,
						passengers: passengers ?? [],
					},
				],
			},
		};
	},
	// TODO: commented undifined
	target: pricesModel.getPricesPreview,
});

/**
 * Начальная загрузка информации о каюте.
 * Если каюта уже добавлена в корзину, загружается с такими же параметрами, как в корзине
 * Иначе с параметрами по умолчанию
 */
sample({
	source: cartModel.$cart,
	clock: initialLoadInfo,
	fn: (cart, cabin) => {
		const added = cart.find(({ id }) => cabin.cabinId === id);
		const passengersTypes = added
			? ([...added.places.main, ...added.places.additional]
				.map(({ type }) => type)
				.filter((type) => type !== null) as string[])
			: undefined;

		return {
			cabinId: cabin.cabinId,
			cruiseId: cabin.cruiseId,
			passengersTypes,
		};
	},
	target: getDetailedCruiseCabinInfo,
});

/**
 * При изменении данных каюты запрашивается пересчёт цены
 */
sample({
	source: Gate.state,
	clock: changeType,
	fn: ({ cruiseId, cabinId, types }, { index, value }) => {
		const passengersTypes = [...types];

		if (value !== null) {
			passengersTypes.splice(index, 1, value);
		} else {
			passengersTypes.splice(index, 1);
		}

		return {
			cruiseId,
			cabinId,
			passengersTypes,
		};
	},
	target: getDetailedCruiseCabinInfo,
});

/**
 * Установка "Одинарного размещения"
 */
sample({
	source: setAlonePassenger,
	target: $alonePassenger,
});

/**
 * Запрос пересчёта цены при изменении "Одинарного размещения"
 */
sample({
	source: Gate.state,
	clock: setAlonePassenger,
	fn: ({ cruiseId, cabinId, types }, alone) => {
		const passengersTypes = alone ? types.slice(0, 1) : undefined;

		return {
			cruiseId,
			cabinId,
			passengersTypes,
		};
	},
	target: getDetailedCruiseCabinInfo,
});

sample({
	clock: ModalGate.open,
	fn: ({ setOpen, isOpen }) => ({
		setOpen,
		isOpen,
	}),
	target: $setOpenModal,
});

sample({
	source: ModalGate.state,
	clock: getDetailedCruiseCabinInfo,
	filter: (modal) => modal !== null && !modal.isOpen,
	fn: (source) => () => source?.setOpen(true),
	target: openModal,
});

sample({
	clock: getDetailedCruiseCabinInfo,
	target: getDetailedCruiseCabinInfoFx,
});

sample({
	clock: getDetailedCruiseCabinInfoFx.doneData,
	target: $detailedCruiseCabinInfo,
});

/**
 * Изменение значение тогла при изменении количества пустых мест
 */
sample({
	clock: $detailedCruiseCabinInfo,
	filter: Boolean,
	fn: (cabinInfo) =>
		cabinInfo?.price?.passengersPrice?.filter(({ passengerType }) => `${passengerType}` !== '3')
			.length === 1,

	target: $alonePassenger,
});

/**
 * Триггер на очистку даннхы при закрытии модалки
 */
const clearModalData = createEvent<OverlayTriggerState>();

debounce({
	source: ModalGate.state,
	timeout: 400,
	target: clearModalData,
});

/**
 * Очистка данных при закрытии модального окна
 */
sample({
	clock: clearModalData,
	source: ModalGate.state,
	filter: ({ isOpen }) => !isOpen,
	fn: () => null,
	target: $detailedCruiseCabinInfo,
});

/**
 * Очистка состояния тогла при закрытии модального окна
 */
sample({
	clock: clearModalData,
	source: ModalGate.state,
	filter: ({ isOpen }) => !isOpen,
	fn: () => false,
	target: $alonePassenger,
});

export const model = {
	ModalGate,
	SuccessModalGate,
	Gate,
	getDetailedCruiseCabinInfo,
	$detailedCruiseCabinInfo,
	$setOpenModal,
	$status,
	changeType,
	setAlonePassenger,
	$alonePassenger,
	initialLoadInfo,
	$screen,
	openSuccessModal,
	getDetailedCruiseCabinInfoFx,
	closeSuccessModal,
	setScreen,
	reloadInfo,
};
