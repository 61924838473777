import { createEffect, createStore, Event, sample } from 'effector';
import type { Form } from 'effector-react-form';

export function formWithSessionStorageFactory<Values extends object>({
	form,
	name,
	parseEvent,
	resetEvent,
}: {
	form: Form<Values>;
	parseEvent: Event<unknown>[];
	resetEvent?: Event<any>[];
	name: string;
}) {
	const $snapshot = createStore<Nullable<Values>>(null);
	const parseFormDataFx = createEffect(async () => {
		const data = sessionStorage.getItem(name);

		return data && data.length > 0 ? (JSON.parse(data) as Values) : null;
	});

	const saveFormDataFx = createEffect((values: Values) => {
		sessionStorage.setItem(name, JSON.stringify(values));
	});

	const removeFormDataFx = createEffect(() => {
		sessionStorage.removeItem(name);
	});

	if (resetEvent) {
		sample({
			clock: resetEvent,
			target: [form.reset, removeFormDataFx],
		});
	}

	sample({
		clock: form.onChangeFieldBrowser,
		source: {
			formState: form.$form,
			values: form.$values,
		},
		filter: ({ formState }) => !formState.submitted,
		fn: ({ values }) => values,
		target: saveFormDataFx,
	});

	sample({
		clock: parseEvent,
		target: parseFormDataFx,
	});

	sample({
		clock: parseFormDataFx.doneData,
		filter: Boolean,
		target: [form.setValues, $snapshot],
	});

	return { snapshot: $snapshot };
}
