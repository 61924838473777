import { isServer } from "config/env";


export const aborting = <T, R>(request: (params: T) => R) => {
	if (isServer) {
		return request;
	}

	let abortController = new AbortController();

	return (params: T) => {
		abortController.abort();
		abortController = new AbortController();

		return request({
			...params,
			signal: abortController.signal,
		});
	};
};
