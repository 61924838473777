// import { fetcher } from 'shared/api/config';
// import { ACCOUNT_URL } from 'shared/config';
// import { ReferralModel } from 'shared/lib/types/account/models';

import { fetcher } from "api/setupAxios";
import { ACCOUNT_URL } from "store/auth/constants";
import { ReferralModel } from "store/auth/types/account/get-referrals-200-response-inner";

export interface GetReferralsRequest {
	officeId?: number | string;
}

export const getReferrals = (body: GetReferralsRequest) =>
	fetcher<ReferralModel[]>({
		url: `${ACCOUNT_URL}/referrals`,
		method: 'GET',
		body,
	});

export const referralsRequests = {
	getReferrals,
};
