// import { CruiseDetailsModel, PreviewPassengerInCabin } from 'shared/lib/types';
// import { getAge } from 'shared/lib/utils';

import { CruiseDetailsModel } from "config/types/cabins/models/cruise-details";
import { PreviewPassengerInCabin } from "config/types/prices";
import { getAge } from "utils/date";

export const getExcursionsTotalPrice = (
	tourists: PreviewPassengerInCabin[],
	excursions: CruiseDetailsModel.Excursion[],
	cruiseStartDate?: string,
) => {
	let result = 0;

	tourists?.forEach(({ data }) => {
		const isAdult = !data.birthday || getAge(data.birthday, cruiseStartDate) >= 16;

		data.excursions?.forEach((item) => {
			const prices = excursions.find(({ id }) => id === item)?.prices;

			result += prices ? prices[isAdult ? 'adult' : 'child'] : 0;
		});
	});

	return result;
};
