import { createEvent, createStore, sample } from 'effector';
// import { getExcursionsTotalPrice } from 'features/order/order-excursion-selector/lib/get-excursions-total-price';
import { cruiseModel, cruiseTimetablesModel } from 'entities/cruises/model';
import { orderCabinsModel } from 'entities/order';
import { getExcursionsTotalPrice } from '../order-excursion-selector/lib';

/* *
 * Сброс всех туристов в экскурсии
 */
const removeAllFromExcursion = createEvent<number>();

sample({
	clock: removeAllFromExcursion,
	source: orderCabinsModel.$cabins,
	fn: (source, excursionId) => {
		const newCabins = [...source];

		return newCabins.map((cabin) => {
			const passengers = cabin.passengers ?? [];

			return {
				...cabin,
				passengers: passengers.map((passenger) => {
					const excs = passenger.excursions ?? [];

					return {
						...passenger,
						excursions: excs.filter((id) => id !== excursionId),
					};
				}),
			};
		});
	},
	target: [orderCabinsModel.$cabins, orderCabinsModel.updatePrices],
});

/* *
 * Сброс всех туристов во всех экскурсиях
 */
const cancelAllExcursions = createEvent();

sample({
	clock: cancelAllExcursions,
	source: orderCabinsModel.$cabins,
	fn: (source) => {
		const newCabins = [...source];

		return newCabins.map((cabin) => {
			const passengers = cabin.passengers ?? [];

			return {
				...cabin,
				passengers: passengers.map((passenger) => ({
					...passenger,
					excursions: [],
				})),
			};
		});
	},
	target: [orderCabinsModel.$cabins, orderCabinsModel.updatePrices],
});

/* *
 * Список всех экскурсий со счетчиком
 */
const $excursions = cruiseTimetablesModel.$paidTimetables.map(
	(timetables) => timetables.flatMap((item) => item.excursions),
	// eslint-disable-next-line
);

const $additionalExcursionsCount = $excursions.map(
	(excursions) => excursions.filter(({ isIncluded }) => !isIncluded).length,
);

/* *
 * Список экскурсий с ценами (для сайдбара)
 */
interface ExcursionWithPrice {
	id: number;
	title: string;
	value: number;
	touristsCount: number;
}

const $excursionsWithPrices = createStore<ExcursionWithPrice[]>([]);

sample({
	clock: [$excursions, orderCabinsModel.$passengers],
	source: {
		passengers: orderCabinsModel.$passengers,
		excursions: $excursions,
		cruise: cruiseModel.$cruise,
	},
	fn: ({ passengers, excursions, cruise }) => {
		const allExcursions = excursions.map((item) => {
			const tourists = passengers.filter(({ data }) => !!data?.excursions?.includes(item.id));
			const price = getExcursionsTotalPrice(tourists, [item], cruise?.date.start);

			return {
				id: item.id,
				title: item.name.replace('– ', ''),
				value: price,
				touristsCount: tourists.length,
			};
		});

		return allExcursions.filter(({ value }) => value > 0);
	},
	target: $excursionsWithPrices,
});

/* *
 * Счетчики суммы, количества туристов, количества выбранных экскурсий
 */
const $total = orderCabinsModel.$passengers.map((passengers) => {
	let tourists = 0;
	const set = new Set();

	passengers.forEach(({ data }) => {
		const excursions = data.excursions ?? [];

		excursions.forEach((id) => set.add(id));

		if (excursions.length > 0) {
			tourists += 1;
		}
	});

	return {
		excursions: set.size,
		tourists,
	};
});

const $totalPrice = createStore(0);

sample({
	clock: [$excursions, orderCabinsModel.$passengers],
	source: {
		passengers: orderCabinsModel.$passengers,
		excursions: $excursions,
		cruise: cruiseModel.$cruise,
	},
	fn: ({ passengers, excursions, cruise }) =>
		getExcursionsTotalPrice(passengers, excursions, cruise?.date.start),
	target: $totalPrice,
});

export const model = {
	$total,
	$totalPrice,
	$excursions,
	$additionalExcursionsCount,
	$excursionsWithPrices,
	removeAllFromExcursion,
	cancelAllExcursions,
};
