/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import classnames from 'classnames';
import { CruiseFeature } from 'entities/cruise-feature';
// import { CruiseDetailsModel } from 'shared/lib/types';
// import { Typo } from 'shared/ui/atoms';
// import { Pictures } from 'shared/ui/organisms';
import { IncludedIcon } from './included-icon';
import { NotIncludedIcon } from './not-included-icon';
import styles from './styles.module.scss';
import { CruiseDetailsModel } from 'config/types/cabins/models/cruise-details';
import { Pictures } from 'layout/components/cruise/cruise-ship-text-block';
import { Typo } from 'layout/components/typo/ui';

export interface CruiseFeaturesProps extends CruiseDetailsModel.Included {
	itIsIncludes?: boolean;
	withoutPictures?: boolean;
	withoutTitle?: boolean;
	theme?: 'white' | 'grey';
}

export const CruiseFeatures: FC<CruiseFeaturesProps> = ({
	items,
	pictures,
	itIsIncludes,
	withoutPictures,
	withoutTitle,
	theme,
}) => {
	if (!items || items.length === 0) {
		return null;
	}

	return (
		<article
			className={classnames(
				styles.cruiseFeatures,
				theme && styles[`cruiseFeatures_theme-${theme}`],
				withoutTitle && styles.cruiseFeatures_withoutTitle,
			)}
		>
			<Typo className={styles.cruiseFeatures__head} design="headline-m">
				<span>{itIsIncludes ? 'В стоимость тура входит' : 'Оплачивается отдельно'}</span>
				{itIsIncludes ? <IncludedIcon /> : <NotIncludedIcon />}
			</Typo>

			<div className={styles.cruiseFeatures__items}>
				{items?.map((item, index) => (
					<CruiseFeature key={`${index}-${encodeURI(item.title ?? '').slice(0, 255)}`} {...item} />
				))}
			</div>

			{!withoutPictures && pictures && pictures.length > 0 && (
				<Pictures pictures={pictures} hasThumbnails={false} />
			)}
		</article>
	);
};
