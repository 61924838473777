import { combineEvents } from 'patronum';
import { OverlayTriggerState } from 'react-stately';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
// import { detailedCruiseCabinModel } from 'entities/cabin-booking-cards2';
import { CartItem, cartModel } from 'entities/cart';
import { detailedCruiseCabinModel } from 'entities/cabin-booking-cards';

const GateWarningModal = createGate<OverlayTriggerState>();

const showCartWarning = createEvent<CartItem>();
const cancelCartWarning = createEvent();
const clearCartAndCreateNew = createEvent();

const executeFx = createEffect((callback: () => void) => callback());

const resetCartItem = createEvent();
const $cartItem = createStore<Nullable<CartItem>>(null).reset(resetCartItem);

sample({
	clock: showCartWarning,
	target: $cartItem,
});

sample({
	clock: showCartWarning,
	source: GateWarningModal.state,
	fn: ({ open }) => open,
	target: executeFx,
});

sample({
	clock: cancelCartWarning,
	source: GateWarningModal.state,
	fn: ({ close }) => close,
	target: [resetCartItem, executeFx],
});

sample({
	clock: clearCartAndCreateNew,
	source: $cartItem,
	filter: Boolean,
	fn: (cartItem) => [cartItem],
	target: cartModel.$cart,
});

sample({
	clock: clearCartAndCreateNew,
	source: $cartItem,
	filter: Boolean,
	fn: ({ cruiseId }) => cruiseId,
	target: cartModel.$activeCruise,
});

const clearCartAndCreateNewDone = combineEvents({
	events: [clearCartAndCreateNew, cartModel.$activeCruise.updates],
});

sample({
	clock: clearCartAndCreateNewDone,
	target: cancelCartWarning,
});

sample({
	clock: cancelCartWarning,
	target: detailedCruiseCabinModel.closeSuccessModal,
});

sample({
	clock: showCartWarning,
	target: detailedCruiseCabinModel.closeSuccessModal,
});

export const model = {
	GateWarningModal,
	showCartWarning,
	cancelCartWarning,
	clearCartAndCreateNew,
};
