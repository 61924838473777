import React, { memo, useRef, useState } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classnames from 'classnames';
// import { useViewport } from 'shared/lib/viewport';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { SliderProps } from 'shared/ui/molecules/slider/types';
import { ThumbnailsPanel } from '../thumbnails-panel';
import styles from './styles.module.scss';
import { SliderProps } from '../../types';
import { useViewport } from 'layout/viewport';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';

const defaultOptions = {
	type: 'slide',
	perMove: 1,
	perPage: 1,
	arrows: false,
	breakpoints: {},
};

export const Slider = memo(
	({
		items,
		options,
		Component,
		className,
		wrapClassName,
		trackWrapClassName,
		trackClassName,
		slideClassName,
		tag = 'div',
		hasControls,
		hasThumbnails,
		controlsClassName,
		prevControlIcon = 'chevron-left',
		prevControlClassName,
		nextControlIcon = 'chevron-right',
		nextControlClassName,
		captions,
		onPrevClick,
		onNextClick,
		withoutFullWidth = false,
		...rest
	}: SliderProps) => {
		const ref = useRef<Nullable<Splide>>(null);
		const [active, setActive] = useState<number>(0);

		const { isMob } = useViewport();

		return (
			<div
				className={classnames(
					wrapClassName,
					styles.wrapper,
					hasThumbnails && styles.wrapper_hasThumbnails,
				)}
			>
				<Splide
					onMove={(_, index) => setActive(index)}
					tag={tag}
					ref={ref}
					hasTrack={false}
					options={{
						...defaultOptions,
						...options,
						fixedWidth: isMob && withoutFullWidth ? '70%' : undefined,
						arrows: hasControls,
					}}
					className={classnames(className, styles.slider, {
						[styles.oneSlideSlider]: options?.perPage
							? options?.perPage === 1
							: defaultOptions.perPage === 1,
					})}
					{...rest}
				>
					<div className={trackWrapClassName}>
						<SplideTrack className={trackClassName}>
							{items &&
								items.map((item, index) => (
									<SplideSlide key={item?.id || index} className={slideClassName}>
										<Component {...item} index={index} />
									</SplideSlide>
								))}
						</SplideTrack>
					</div>
					{hasControls && (
						<div className={classnames('splide__arrows', controlsClassName, styles.sliderControls)}>
							<button
								className={classnames(
									'splide__arrow splide__arrow--prev',
									prevControlClassName,
									styles.sliderControl,
								)}
								onClick={onPrevClick}
							>
								{prevControlIcon && <Icon id={prevControlIcon} />}
							</button>
							<button
								className={classnames(
									'splide__arrow splide__arrow--next',
									nextControlClassName,
									styles.sliderControl,
								)}
								onClick={onNextClick}
							>
								{nextControlIcon && <Icon id={nextControlIcon} />}
							</button>
						</div>
					)}
				</Splide>
				{captions && (
					<Typo design="text-s" className={styles.captions}>
						<span className={styles.counter}>{`${active + 1} / ${items?.length}`}</span>
						<span className={styles.caption}>{`${captions[active] || ''}`}</span>
					</Typo>
				)}
				{hasThumbnails && (
					<ThumbnailsPanel
						items={items}
						Component={Component}
						active={active}
						goTo={(index: number) => {
							ref.current!.go(index);
						}}
					/>
				)}
			</div>
		);
	},
);
