import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppRoutes } from 'config/old_routes/Router';
// Import the generated route tree
import Card from 'layout/components/cards/card/card';
import RouterProviderWrapper from 'layout/components/wrappers/RouterProviderWrapper/RouterProviderWrapper';
// import { createRouter, RouterProvider } from '@tanstack/react-router';
// import { routeTree } from './routeTree.gen'

// // Create a new router instance
// const router = createRouter({ routeTree })

// // Register the router instance for type safety
// declare module '@tanstack/react-router' {
//   interface Register {
//     router: typeof router
//   }
// }

// attachLogger();

const store = {
  title: "example",
  content: "content of block",
}

const inHeader = ReactDOM.createRoot(
  document.getElementById('app-root') as HTMLElement
);

// const inFooter = ReactDOM.createRoot(
//   document.getElementById('app-footer') as HTMLElement
// );

// const inCard = ReactDOM.createRoot(
//   // document.getElementsByClassName('modal-cabin-button')?.[0] as HTMLElement ?? null
//   document.getElementById('app-card') as HTMLElement ?? null
// );

//!TODO как-то передать параметр внутрь RouteProvider о том, в каком месте он запущен (в каком div) и в маршрутах в зависимости от параметра рендерить
// "homepage": "/local/templates/main/react-app",
inHeader.render(
  <React.StrictMode>
    {/* <App place='auth' store={store} /> */}
    <App />

    {/* <RouterProviderWrapper router={router} place="header" /> */}
  </React.StrictMode>
);
// inFooter.render(
//   <React.StrictMode>
//     {/* <App place='standart' store={store} /> */}
//     <RouterProvider router={router}  />
//     {/* <RouterProviderWrapper router={router} place="footer" /> */}
//   </React.StrictMode>
// );
// inCard.render(
//   <React.StrictMode>
//     {/* <App place='standart' store={store} /> */}
//     {/* <RouterProvider router={router} context={{place: "card", place2: "footer" }}  /> */}
//     {/* <RouterProviderWrapper router={router} place="card" /> */}
//     {/* <Card /> */}
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
