import React, { FC, memo, PropsWithChildren, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useUnit } from 'effector-react';
import { cruiseModel } from 'entities/cruises/model';
import { CruiseDuration } from 'entities/cruises/ui';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { setAnalytics } from 'shared/lib/utils';
// import { MoreToggle, Skeleton, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { MoreToggle } from 'layout/components/buttons/more-toggle';
import { Skeleton } from 'layout/components/special/skeleton';
import { Typo } from 'layout/components/typo/ui';

export interface CruiseProps extends PropsWithChildren {}

export const Cruise: FC<CruiseProps> = memo(({ children }) => {
	const [expanded, setExpanded] = useState(false);
	const [cruise, cruiseStatus] = useUnit([cruiseModel.$cruise, cruiseModel.$status]);
	const pending = cruiseStatus === 'pending';

	if (pending) {
		return (
			<div className={styles.cruise}>
				{children}
				<Skeleton width="100%" height={40} />
				<Skeleton width={96} height={18} />
				<Skeleton width="80%" height={20} />
			</div>
		);
	}

	const routeHeadline = cruise?.type === 'sea' ? cruise?.route?.full : cruise?.route?.short;

	return (
		<div className={styles.cruise}>
			{children}

			{cruise && (
				<CruiseDuration
					dateStart={cruise.date.start}
					dateEnd={cruise.date.end}
					days={cruise.length.days}
					nights={cruise.type === 'sea' && cruise.length.nights}
				/>
			)}

			{routeHeadline && <Typo design="headline-s">{routeHeadline}</Typo>}

			{cruise?.route?.full && (
				<div className={styles.fullRoute}>
					<MoreToggle
						onClick={() => {
							setExpanded((prev) => !prev);

							if (!expanded) {
								// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_full_route);
							}
						}}
						isExpanded={expanded}
						label="Полный маршрут"
					/>
					<AnimateHeight height={expanded ? 'auto' : 0} duration={400}>
						<Typo className={styles.fullRoute__content} design="text-s">
							{cruise.route.full}
						</Typo>
					</AnimateHeight>
				</div>
			)}
		</div>
	);
});
