import { createFileRoute, useLocation, useSearch } from '@tanstack/react-router'
import { Main } from 'layout/layouts/main'
import { OrderTourists } from 'layout/layouts/widgets/order/order-tourists'

export const Route = createFileRoute('/order/')({
  component: () => <Orders />,
})

function Orders() {
  // const sParams: Record<string, string> = useSearch({ strict: false })
  // // const ctx = useRouteContext({ from: '/login' })
  // //     console.log('route login',ctx)
  // const location = useLocation()

  // const { state, emailToken, error } = sParams
  
  return <OrderTourists />
}