// import { FC, Key, PropsWithChildren } from 'react';
import { FC, PropsWithChildren } from 'react';
import { Key } from '@react-types/shared';
import classnames from 'classnames';
import { ListState } from 'react-stately';
import { SelectionMode } from '@react-types/shared';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import style from './style.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Icon } from 'layout/components/icons/icon';

export interface FakeOptionProps extends PropsWithChildren {
	state: ListState<unknown>;
	childKeys: Array<Key>;
	selectionMode?: SelectionMode;
	count?: number;
}

export const FakeOption: FC<FakeOptionProps> = ({
	state,
	childKeys,
	selectionMode,
	children,
	count,
}) => {
	const isCheckbox = selectionMode === 'multiple';
	const manager = state.selectionManager;
	const isSelected = childKeys.every((k) => manager.isSelected(k));

	const handleClick = () => {
		if (selectionMode === 'multiple') {
			const set = new Set(manager.selectedKeys);

			childKeys.forEach((k) => {
				if (k && isSelected) {
					set.delete(k);
				}
				if (k && !isSelected) {
					set.add(k);
				}
			});

			manager.setSelectedKeys(set);
		}
	};

	return (
		<Typo
			className={classnames(style.option, {
				[style.isCheckbox]: isCheckbox,
			})}
			design="description-m"
			aria-selected={isSelected}
			onClick={handleClick}
		>
			<span>{children}</span>
			{count && <small>{count}</small>}
			{(isSelected || isCheckbox) && <Icon id={isCheckbox ? 'check' : 'done-bold'} />}
		</Typo>
	);
};
