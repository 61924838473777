import { RefObject } from 'react';
import { useButton, useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { useDelayUnmount } from './use-delay-unmount';

interface UseModalStateProps {
	triggerRef: RefObject<HTMLButtonElement>;
	modalRef?: RefObject<HTMLDivElement>;
}

export const useModalState = ({ triggerRef }: UseModalStateProps) => {
	const state = useOverlayTriggerState({});
	const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'menu' }, state, triggerRef);
	const { buttonProps } = useButton(triggerProps, triggerRef);

	const shouldMount = useDelayUnmount(state.isOpen, 600);

	return {
		state,
		buttonProps,
		overlayProps,
		shouldMount,
	};
};
