/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable complexity */
import React, { InputHTMLAttributes, MutableRefObject, useState } from 'react';
import { useId } from '@react-aria/utils';
import classnames from 'classnames';
import { Mask, MaskOptions } from 'maska';
import styles from './styles.module.scss';
import { IconId } from 'layout/components/icons/svg-sprite';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';

export interface InputInterface extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
	error?: JSX.Element;
	icon?: IconId;
	iconLeft?: IconId;
	moveIcon?: boolean;
	theme?: 'normal' | 'secondary';
	size?: 'sm' | 'md' | 'lg';
	withLabel?: boolean;
	isLoading?: boolean;
	classNameWrap?: string;
	classNameIcon?: string;
	mask?: string | string[];
	maskOptions?: MaskOptions;
	prefix?: string;
	secondPlaceholder?: string;
	isRequired?: boolean;
	masked?: boolean;
	wrapRef?: MutableRefObject<Nullable<HTMLDivElement>>;
}

export const Input = React.forwardRef<HTMLInputElement, InputInterface>(
	(
		{
			type = 'text',
			error,
			icon,
			iconLeft,
			theme = 'normal',
			size = 'md',
			withLabel,
			isLoading,
			className,
			classNameWrap,
			classNameIcon,
			prefix,
			secondPlaceholder,
			onFocus,
			onBlur,
			onChange,
			placeholder,
			children,
			mask,
			maskOptions = {},
			value,
			isRequired,
			masked,
			moveIcon,
			wrapRef,
			...props
		},
		ref,
	) => {
		const ariaId = useId();
		const [hasValue, setHasValue] = useState(false);

		const maskInstance = new Mask({
			mask,
			...maskOptions,
		});

		const setActive = (node: HTMLInputElement) =>
			setHasValue(node.value.length > 0 || document.activeElement === node);

		const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
			setHasValue(true);
			if (onFocus) {
				onFocus(e);
			}
		};

		const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
			setActive(e.target);
			if (onBlur) {
				onBlur(e);
			}
		};

		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			setActive(e.target);
			if (onChange) {
				e.target.value = masked
					? maskInstance.masked(e.target.value)
					: maskInstance.unmasked(e.target.value);
				onChange(e);
			}
		};

		if (type === 'file') {
			return null;
		}

		return (
			<label
				className={classnames(
					classNameWrap,
					styles.input,
					size && styles[size],
					theme && styles[theme],
					error && styles.error,
					icon && styles.withIcon,
					withLabel && styles.withLabel,
					iconLeft && styles.withIconLeft,
					moveIcon && iconLeft && size !== 'sm' && styles.moveIcon,
					secondPlaceholder && styles.secondPlaceholder,
					{
						[styles.moveIconActive]: hasValue,
					},
				)}
			>
				<div ref={wrapRef} className={styles.input__wrap}>
					<input
						ref={(node) => {
							if (node) {
								setActive(node);

								if (typeof ref === 'function') {
									ref(node);
								}
							}
						}}
						type={type}
						className={className}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onChange={handleChange}
						onInput={handleChange}
						placeholder={!withLabel ? placeholder : secondPlaceholder}
						value={mask ? maskInstance.masked(String(value)) : value}
						aria-invalid={Boolean(error)}
						aria-errormessage={error ? ariaId : undefined}
						{...props}
					/>
					{icon && (
						<Icon
							id={icon}
							className={classnames(
								styles.input__icon,
								{
									[styles.isChevron]: icon.includes('chevron'),
								},
								classNameIcon,
							)}
						/>
					)}
					{iconLeft && (
						<Icon
							id={iconLeft}
							className={classnames(styles.input__icon, styles.input__icon_left)}
						/>
					)}
					{withLabel && placeholder && (
						<Typo
							design="button-m-s"
							className={classnames(
								styles.input__label,
								{
									[styles.isRequired]: props.required || isRequired,
									[styles.isActive]: hasValue,
								},
								theme && styles[theme],
							)}
						>
							<span>{placeholder}</span>
						</Typo>
					)}
				</div>
				{children && (
					<Typo design="input-text-s" className={styles.input__desc}>
						{children}
					</Typo>
				)}
				{error && (
					<Typo
						id={ariaId}
						design="input-text-s"
						className={classnames(styles.input__error, 'errormessage')}
					>
						{error}
					</Typo>
				)}
			</label>
		);
	},
);
