import { getInfo } from './info';
import { getPlan } from './plan';
import { getShips, getShipsFiltersHandbook } from './search';

export type { GetShipInfoRequest } from './info';
export type { GetPlanRequest } from './plan';
export type { GetShipsRequest, GetShipsParams } from './search';

export const shipsRequests = {
	getPlan,
	getInfo,
	getShips,
	getShipsFiltersHandbook,
};
