import { status } from 'patronum';
import { createEvent, createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { bonusCardModel } from 'entities/bonus-card';
import { bonusesRequests, GetBonusHistoryRequest } from 'store/account/api/bonuses';
import { GetBonusCardHistory200Response } from 'store/auth/types/account/get-bonus-card-history-200-response';
import { RequestWaitingBonus } from 'store/auth/types/account/request-waiting-bonus';
// import { bonusesRequests, GetBonusHistoryRequest } from 'shared/api';
// import {
// 	GetBonusCardHistory200Response,
// 	RequestWaitingBonus,
// } from 'shared/lib/types/account/models';

const BonusesGate = createGate();
const BonusHistoryGate = createGate();

const getBonusHistory = createEvent<GetBonusHistoryRequest>();
const loadMoreBonusHistory = createEvent();
const getWaitingBonusHistory = createEvent();

const getBonusHistoryFx = createEffect(bonusesRequests.getBonusHistory);
const loadMoreBonusHistoryFx = createEffect(bonusesRequests.getBonusHistory);
const getWaitingBonusHistoryFx = createEffect(bonusesRequests.getWaitingBonusHistory);

const $bonushistory = createStore<GetBonusCardHistory200Response>({});
const $waitingBonusHistory = createStore<RequestWaitingBonus[]>([]);
const $bonusHistoryStatus = status({ effect: getBonusHistoryFx });
const $waitingBonusHistoryStatus = status({ effect: getWaitingBonusHistoryFx });

sample({
	clock: BonusesGate.open,
	fn: () => ({}),
	target: [getBonusHistory, bonusCardModel.getBonusCard],
});

sample({
	clock: BonusHistoryGate.open,
	fn: () => ({}),
	target: [getBonusHistory, getWaitingBonusHistory],
});

/**
 * Bonus history
 */
sample({
	clock: getBonusHistory,
	target: getBonusHistoryFx,
});

sample({
	clock: getBonusHistoryFx.doneData,
	target: $bonushistory,
});

sample({
	source: $bonushistory,
	clock: loadMoreBonusHistory,
	fn: ({ page }) => ({ page: page ? page + 1 : 1 }),
	target: loadMoreBonusHistoryFx,
});

sample({
	source: $bonushistory,
	clock: loadMoreBonusHistoryFx.doneData,
	fn: (source, clock) => ({
		...clock,
		data: [...(source?.data ?? []), ...(clock.data ?? [])],
	}),
	target: $bonushistory,
});

/**
 * Waiting bonus history
 */
sample({
	clock: getWaitingBonusHistory,
	target: getWaitingBonusHistoryFx,
});

sample({
	clock: getWaitingBonusHistoryFx.doneData,
	target: $waitingBonusHistory,
});

export const model = {
	BonusesGate,
	BonusHistoryGate,
	getBonusHistory,
	loadMoreBonusHistory,
	$bonushistory,
	$waitingBonusHistory,
	$bonusHistoryStatus,
	$waitingBonusHistoryStatus,
};
