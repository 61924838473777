import { MutableRefObject, useEffect } from 'react';
import { getScrollParent } from '@react-aria/utils';
import { isVisibleInScrollArea } from 'utils/dom/is-visible-in-scroll-area';
import { setTimeoutWithCleanup } from 'utils/timer';
// import { setTimeoutWithCleanup } from 'shared/lib/utils';
// import { isVisibleInScrollArea } from 'shared/lib/utils/dom';

interface UseScrollToCellIntoViewArgs {
	ref?: MutableRefObject<Nullable<HTMLDivElement>>;
	enable?: boolean;
	isToday?: boolean;
	isSelectionStart?: boolean;
}

export const useScrollToCellIntoView = ({
	ref,
	enable,
	isToday,
	isSelectionStart,
}: UseScrollToCellIntoViewArgs) => {
	useEffect(() => {
		const element = ref?.current;

		if (!element || !enable) {
			return;
		}

		const scrollIntoView = (behavior?: ScrollBehavior) => {
			const isVisible = isVisibleInScrollArea(
				element,
				element.closest<HTMLElement>('[data-simplebar]'),
			);

			if (!isVisible) {
				element.scrollIntoView({ block: 'center', behavior });
			}
		};

		if (isSelectionStart) {
			return setTimeoutWithCleanup(() => {
				scrollIntoView('smooth');
			}, 600);
		}

		if (isToday) {
			return setTimeoutWithCleanup(() => {
				scrollIntoView('smooth');
			}, 600);
		}

		// eslint-disable-next-line
	}, []);
};

export const scrollIntoView = (target: Element) => {
	const root = document.scrollingElement || document.documentElement;
	while (target && target !== root) {
		// Find the parent scrollable element and adjust the scroll position if the target is not already in view.
		const scrollable = getScrollParent(target);

		if (
			scrollable !== document.documentElement &&
			scrollable !== document.body &&
			scrollable !== target
		) {
			const scrollableTop = scrollable.getBoundingClientRect().top;
			const targetTop = target.getBoundingClientRect().top;

			if (targetTop > scrollableTop + target.clientHeight) {
				scrollable.scrollTop += targetTop - scrollableTop;
			}

			if (targetTop < scrollableTop + target.clientHeight) {
				scrollable.scrollTop += targetTop - scrollableTop;
			}
		}

		// @ts-ignore
		target = scrollable.parentElement;
	}
};
