import React, { FC } from 'react';
// import { Typo } from 'shared/ui/atoms';
import styles from './order-status.module.scss';
import { Typo } from 'layout/components/typo/ui';

interface OrderStatusProps {
	statusName?: string;
}

export const OrderStatus: FC<OrderStatusProps> = ({ statusName }) => (
	<div className={styles.status}>
		<Typo className={styles.label} design="text-s" freezeDesktop>
			Статус заявки
		</Typo>
		<Typo design="headline-m">{statusName}</Typo>
	</div>
);
