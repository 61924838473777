/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-use-before-define */
// import { HTMLAttributes, Key, RefObject, useEffect } from 'react';
import { HTMLAttributes, RefObject, useEffect } from 'react';
import { focusSafely } from '@react-aria/focus';
import { isFocusVisible, PressProps, useHover, usePress } from '@react-aria/interactions';
import { getItemId, listData, OptionAria } from '@react-aria/listbox';
import { SelectableItemAria, SelectableItemOptions } from '@react-aria/selection';
import { isMac, isAppleDevice, isWebKit, mergeProps, useSlotId } from '@react-aria/utils';
import { getItemCount } from '@react-stately/collections';
import { ListState } from '@react-stately/list';
import { Key, PressEvent } from '@react-types/shared';

interface SelectGroupOptionProps {
	key: Key;
	meta: SelectGroupOptionMeta;
	'aria-label'?: string;
}

export interface SelectGroupOptionMeta {
	isParent: boolean;
	group: Array<{ key: Key; isParent: boolean }>;
}

interface Event {
	altKey: boolean;
	ctrlKey: boolean;
	metaKey: boolean;
}

const isNonContiguousSelectionModifier = (e: Event) => (isAppleDevice() ? e.altKey : e.ctrlKey);

const isCtrlKeyPressed = (e: Event) => {
	if (isMac()) {
		return e.metaKey;
	}

	return e.ctrlKey;
};

// Modified version of github.com/adobe/react-spectrum/blob/main/packages/@react-aria/listbox/src/useOption.ts
export function useGroupedOption<T>(
	props: SelectGroupOptionProps,
	state: ListState<T>,
	ref: RefObject<HTMLElement>,
): OptionAria {
	const { key, meta } = props;

	const data = listData.get(state)!;

	const isDisabled = state.disabledKeys.has(key);
	const isFocused = state.selectionManager.focusedKey === key;
	const isSelected = state.selectionManager.isSelected(key);

	const { shouldFocusOnHover, shouldUseVirtualFocus, isVirtualized } = data;

	const labelId = useSlotId();
	const descriptionId = useSlotId();

	const optionProps: HTMLAttributes<HTMLElement> = {
		role: 'option',
		'aria-disabled': isDisabled,
		'aria-selected': state.selectionManager.selectionMode !== 'none' ? isSelected : undefined,
	};

	// Safari with VoiceOver on macOS misreads options with aria-labelledby or aria-label as simply "text".
	// We should not map slots to the label and description on Safari and instead just have VoiceOver read the textContent.
	// https://bugs.webkit.org/show_bug.cgi?id=209279
	if (!(isMac() && isWebKit())) {
		optionProps['aria-label'] = props['aria-label'];
		optionProps['aria-labelledby'] = labelId;
		optionProps['aria-describedby'] = descriptionId;
	}

	if (isVirtualized) {
		optionProps['aria-posinset'] = state.collection.getItem(key)!.index! + 1;
		optionProps['aria-setsize'] = getItemCount(state.collection);
	}

	const { itemProps, isPressed, allowsSelection, hasAction } = useSelectableRootItem(
		{
			selectionManager: state.selectionManager,
			key,
			meta,
			ref,
			isVirtualized,
			shouldUseVirtualFocus,
			isDisabled,
		},
		state,
	);

	const { hoverProps } = useHover({
		isDisabled: isDisabled || !shouldFocusOnHover,
		onHoverStart() {
			if (!isFocusVisible()) {
				state.selectionManager.setFocused(true);
				state.selectionManager.setFocusedKey(key);
			}
		},
	});

	return {
		optionProps: {
			...optionProps,
			...mergeProps(itemProps || {}, hoverProps),
			id: getItemId(state, key),
		},
		labelProps: {
			id: labelId,
		},
		descriptionProps: {
			id: descriptionId,
		},
		isFocused,
		isSelected,
		isDisabled,
		isPressed,
		allowsSelection,
		hasAction,
		isFocusVisible: true // для совместимости
	};
}

// Modified version of https://github.com/adobe/react-spectrum/blob/main/packages/@react-aria/selection/src/useSelectableItem.ts
export function useSelectableRootItem<T>(
	options: SelectableItemOptions & { meta: SelectGroupOptionMeta },
	state: ListState<T>,
): Pick<SelectableItemAria, 'itemProps' | 'isPressed' | 'allowsSelection' | 'hasAction'> {
	const {
		selectionManager: manager,
		key,
		meta,
		ref,
		isVirtualized,
		shouldUseVirtualFocus,
		isDisabled,
	} = options;

	const childKeys = meta.group.filter(({ isParent }) => !isParent).map((item) => item.key);

	const onSelect = (e: PointerEvent | PressEvent) => {
		if (meta.isParent) {
			if (manager.selectionMode === 'multiple') {
				if (manager.isSelected(key)) {
					const newSet = new Set(manager.selectedKeys);
					newSet.delete(key);
					childKeys.forEach((k) => k && newSet.delete(k));
					manager.setSelectedKeys(newSet);
				} else {
					manager.setSelectedKeys(new Set([...manager.selectedKeys, key, ...childKeys]));
				}
			}

			return;
		}

		if (e.pointerType === 'keyboard' && isNonContiguousSelectionModifier(e)) {
			manager.toggleSelection(key);
		} else {
			if (manager.selectionMode === 'none') {
				return;
			}

			if (manager.selectionMode === 'single') {
				if (manager.isSelected(key) && !manager.disallowEmptySelection) {
					manager.toggleSelection(key);
				} else {
					manager.replaceSelection(key);
				}
			} else if (e && e.shiftKey) {
				manager.extendSelection(key);
			} else if (
				manager.selectionBehavior === 'toggle' ||
				(e && (isCtrlKeyPressed(e) || e.pointerType === 'touch' || e.pointerType === 'virtual'))
			) {
				// if touch or virtual (VO) then we just want to toggle, otherwise it's impossible to multi select because they don't have modifier keys
				manager.toggleSelection(key);
			} else {
				manager.replaceSelection(key);
			}
		}
	};

	useEffect(() => {
		if (meta.isParent) {
			const everyIsSelected = childKeys.every((k) => manager.isSelected(k));

			if (everyIsSelected) {
				manager.setSelectedKeys(new Set([...manager.selectedKeys, key]));
			} else {
				const newSet = new Set(manager.selectedKeys);
				newSet.delete(key);
				manager.setSelectedKeys(newSet);
			}
		}
	}, [meta.isParent, manager, childKeys, key]);

	// Focus the associated DOM node when this item becomes the focusedKey
	const isFocused = key === manager.focusedKey;

	useEffect(() => {
		if (
			isFocused &&
			manager.isFocused &&
			!shouldUseVirtualFocus &&
			document.activeElement !== ref.current
		) {
			focusSafely(ref.current!);
		}
	}, [
		ref,
		isFocused,
		manager.focusedKey,
		manager.childFocusStrategy,
		manager.isFocused,
		shouldUseVirtualFocus,
	]);

	// Set tabIndex to 0 if the element is focused, or -1 otherwise so that only the last focused
	// item is tabbable.  If using virtual focus, don't set a tabIndex at all so that VoiceOver
	// on iOS 14 doesn't try to move real DOM focus to the item anyway.
	let itemProps: SelectableItemAria['itemProps'] & { 'data-key'?: Key } = {};
	if (!shouldUseVirtualFocus) {
		itemProps = {
			tabIndex: isFocused ? 0 : -1,
			onFocus(e) {
				if (e.target === ref.current) {
					manager.setFocusedKey(key);
				}
			},
		};
	}

	const allowsSelection = !isDisabled && manager.canSelectItem(key);
	const allowsActions = !isDisabled;
	const hasPrimaryAction =
		allowsActions && (manager.selectionBehavior === 'replace' ? !allowsSelection : manager.isEmpty);
	const hasSecondaryAction =
		allowsActions && allowsSelection && manager.selectionBehavior === 'replace';
	const hasAction = hasPrimaryAction || hasSecondaryAction;

	const itemPressProps: PressProps = {
		// On touch, it feels strange to select on touch down, so we special case this.
		onPressStart: (e) => {
			if (e.pointerType !== 'touch' && e.pointerType !== 'virtual') {
				onSelect(e);
			}
		},
		onPress: (e) => {
			if (e.pointerType === 'touch' || e.pointerType === 'virtual') {
				onSelect(e);
			}
		},
	};

	if (!isVirtualized) {
		itemProps['data-key'] = key;
	}

	itemPressProps.preventFocusOnPress = shouldUseVirtualFocus;
	const { pressProps, isPressed } = usePress(itemPressProps);

	return {
		itemProps: mergeProps(itemProps, allowsSelection ? pressProps : {}),
		isPressed,
		allowsSelection,
		hasAction,
	};
}
