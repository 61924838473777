import React, { memo } from 'react';
import { Item } from 'react-stately';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-react-form';
// import { countriesModel } from 'entities/countries';
import { DOCUMENT_TYPES_MASK_TOKENS, documentTypesModel } from 'entities/document-types';
// import { MIN_BIRTHDAY_DATE } from 'shared/config';
// import { dateToCalendarDate } from 'shared/lib/utils';
// import {
// 	Input,
// 	InputDate,
// 	inputDateOnBlurValueParser,
// 	inputDateValueParser,
// 	Radio,
// } from 'shared/ui/atoms';
// import { RadioGroup, Select } from 'shared/ui/molecules';
// import { FormField, FormSelect } from 'shared/ui/organisms';
import { customerFormValidationScheme } from '../../lib';
import { model } from '../../model/guest-data';
import styles from './styles.module.scss';
import { MIN_BIRTHDAY_DATE } from 'config/constants/order';
import { countriesModel } from 'store/countries';
import { dateToCalendarDate } from 'utils/date';
import { FormField } from 'layout/components/inputs/form/field';
import { FormSelect } from 'layout/components/inputs/form/select';
import { inputDateValueParser, inputDateOnBlurValueParser, Input } from 'layout/components/inputs/input';
import { Radio } from 'layout/components/inputs/radio';
import { RadioGroup } from 'layout/components/inputs/radio-group';
import { Select } from 'layout/components/inputs/selects/select';
import { InputDate } from 'layout/components/inputs/input/input-date';

export const PassportData = memo(() => {
	const { form, resetDocumentData, $documentType, $documentTypePattern, $documentTypesValidation } =
		model;
	const { controller } = useForm({ form });

	const [
		countries,
		documentTypes,
		docType,
		docTypePattern,
		handleDocumentTypeChange,
		documentTypesValidation,
	] = useUnit([
		countriesModel.$countries,
		documentTypesModel.$documentTypes,
		$documentType,
		$documentTypePattern,
		resetDocumentData,
		$documentTypesValidation,
	]);

	const todayCalendarDate = dateToCalendarDate(new Date());
	const minCalendarDate = dateToCalendarDate(new Date(MIN_BIRTHDAY_DATE));

	return (
		<div className={styles.passport}>
			<div className={styles.passport__grid}>
				<FormSelect
					form={form}
					controller={controller}
					validation={customerFormValidationScheme.citizenship}
					name="citizenship"
					size="lg"
					selectionMode="single"
					theme="divider"
					label="Гражданство"
					className={styles.passport__selector}
				>
					<Select className={styles.offices__select} searchPlaceholder="Поиск значения" withSearch>
						{countries.map(({ id, name }) => (
							<Item key={id.toString()}>{name}</Item>
						))}
					</Select>
				</FormSelect>

				<FormField
					form={form}
					controller={controller}
					name="birthday"
					validation={customerFormValidationScheme.birthday}
					valueParser={(value) => inputDateValueParser(value, true)}
					onChangeValueParser={(changedValue) =>
						inputDateOnBlurValueParser(changedValue, {
							minValue: minCalendarDate,
							maxValue: todayCalendarDate,
						})
					}
					onBlurValueParser={(blurValue) =>
						inputDateOnBlurValueParser(blurValue, {
							minValue: minCalendarDate,
							maxValue: todayCalendarDate,
						})
					}
					className={styles.passport__selector}
				>
					<InputDate
						placeholder="Дата рождения"
						secondPlaceholder="00.00.0000"
						placement="bottom left"
						size="lg"
						withLabel
						datepicker={{
							minValue: minCalendarDate,
							maxValue: todayCalendarDate,
						}}
						calendar={{
							dateSelector: {
								minYear: 1902,
								maxYear: 'today',
							},
						}}
						withoutTimezone
					/>
				</FormField>
			</div>

			<div className={styles.passport__document}>
				<FormField
					form={form}
					controller={controller}
					validation={customerFormValidationScheme.gender}
					name="gender"
					wrap="nowrap"
				>
					<RadioGroup className={styles.passport__gender} label="Пол" showCaption>
						<Radio value="2">Женский</Radio>
						<Radio value="1">Мужской</Radio>
					</RadioGroup>
				</FormField>

				<FormField
					valueAlias="selectedKeys"
					valueParser={(value) => (value ? [`${value}`] : [])}
					onChangeAlias="onSelectionChange"
					onChangeValueParser={(keys) => {
						if (keys instanceof Set && keys.size) {
							return Number([...keys][0]);
						}
						handleDocumentTypeChange();

						return 0;
					}}
					controller={controller}
					form={form}
					validation={customerFormValidationScheme?.documentType}
					name="documentType"
				>
					<Select
						className={styles.selector}
						label="Тип документа"
						selectionMode="single"
						isRequired={customerFormValidationScheme?.documentType?.required}
					>
						{documentTypes.map(({ id, name }) => (
							<Item key={`${id}`} aria-label={name}>
								{name}
							</Item>
						))}
					</Select>
				</FormField>

				<FormField
					form={form}
					controller={controller}
					validation={documentTypesValidation}
					name="documentData"
					placeholder="Серия и номер документа"
					size="lg"
					wrap="nowrap"
					masked
					mask={docTypePattern}
					maskOptions={{
						tokens: DOCUMENT_TYPES_MASK_TOKENS,
					}}
					disabled={!docType}
					withLabel
				>
					<Input />
				</FormField>
			</div>
		</div>
	);
});
