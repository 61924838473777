/* eslint-disable import/no-extraneous-dependencies */
import { CalendarDateTime } from '@internationalized/date';
import { DateValue } from '@react-types/datepicker';
import { DatePickerStateOptions } from 'react-stately';
import { getCalendarDateFromEuDateString, formatDate } from 'utils/date';
// import { formatDate, getCalendarDateFromEuDateString } from 'shared/lib/utils';

export const inputDateStringValueToCalendarValue = (stringValue: string) => {
	if (stringValue.length < 10) {
		return;
	}

	return getCalendarDateFromEuDateString(stringValue);
};

export const inputDateCalendarValueToStringValue = (
	calendarValue: DateValue,
	withoutTimezone = false,
) => {
	const datepickerDateValue = calendarValue.toString();
	const datepickerFormatedValue = formatDate(
		datepickerDateValue,
		undefined,
		undefined,
		withoutTimezone,
	);

	return typeof datepickerFormatedValue === 'string' ? datepickerFormatedValue : null;
};

export const inputDateCalendarDateConstraint = (
	calendarDate?: CalendarDateTime,
	options?: Pick<DatePickerStateOptions<DateValue>, 'minValue' | 'maxValue'>,
) => {
	if (!calendarDate) {
		return;
	}

	const { minValue, maxValue } = options ?? {};

	if (minValue && calendarDate.compare(minValue) < 0) {
		return minValue as CalendarDateTime;
	}

	if (maxValue && calendarDate.compare(maxValue) > 0) {
		return maxValue as CalendarDateTime;
	}

	return calendarDate;
};

export const inputDateValueParser = (value: Nullable<string>, withoutTimezone = false) => {
	if (!value) {
		return value;
	}

	if (value.length < 10) {
		return value;
	}

	return formatDate(value, undefined, undefined, withoutTimezone) ?? value;
};

export const inputDateOnChangeValueParser = (value: string): string => {
	const date = getCalendarDateFromEuDateString(value);

	if (!date || value.length < 10) {
		return value;
	}

	return date.toString();
};

export const inputDateOnBlurValueParser = (
	value: string,
	options?: Pick<DatePickerStateOptions<DateValue>, 'minValue' | 'maxValue'>,
): string => {
	const date = getCalendarDateFromEuDateString(value);
	const constrainedDate = inputDateCalendarDateConstraint(date, options);

	if (value.length < 10 || !date || !constrainedDate) {
		return '';
	}

	return constrainedDate.toString();
};
