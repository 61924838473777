import { FC, useRef } from 'react';
import classnames from 'classnames';
import { useOption } from 'react-aria';
import { ListState } from 'react-stately';
import type { Node } from '@react-types/shared';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import style from './style.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Icon } from 'layout/components/icons/icon';

export interface OptionProps {
	item: Node<unknown>;
	state: ListState<unknown>;
	isCheckbox?: boolean;
}

export const Option: FC<OptionProps> = (props) => {
	const ref = useRef<HTMLLIElement>(null);
	const { item, state, isCheckbox } = props;
	const { optionProps, isSelected } = useOption({ key: item.key }, state, ref);

	return (
		<Typo
			{...optionProps}
			ref={ref}
			className={classnames(style.option, {
				[style.isCheckbox]: isCheckbox,
			})}
			design="description-m"
			as="li"
		>
			{item.rendered}
			{(isSelected || isCheckbox) && <Icon id={isCheckbox ? 'check' : 'done-bold'} />}
		</Typo>
	);
};
