/*
 * Copyright 2022 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';
import { useLayoutEffect } from '@react-aria/utils';
import { FocusScope, useIsSSR } from 'react-aria';
import ReactDOM from 'react-dom';

export interface OverlayProps {
	/**
	 * The container element in which the overlay portal will be placed.
	 * @default document.body
	 */
	portalContainer?: Element;
	/** The overlay to render in the portal. */
	children: ReactNode;
	/**
	 * Disables default focus management for the overlay, including containment and restoration.
	 * This option should be used very carefully. When focus management is disabled, you must
	 * implement focus containment and restoration to ensure the overlay is keyboard accessible.
	 */
	disableFocusManagement?: boolean;
}

export const OverlayContext =
	React.createContext<
		Nullable<{ contain: boolean; setContain: Dispatch<SetStateAction<boolean>> }>
	>(null);

/**
 * A container which renders an overlay such as a popover or modal in a portal,
 * and provides a focus scope for the child elements.
 */
export function Overlay(props: OverlayProps) {
	const isSSR = useIsSSR();
	const {
		portalContainer = isSSR ? null : document.body,
		children,
		disableFocusManagement,
	} = props;
	const [contain, setContain] = useState(false);
	const contextValue = useMemo(() => ({ contain, setContain }), [contain, setContain]);

	if (!portalContainer) {
		return null;
	}

	const contents = !disableFocusManagement ? (
		<OverlayContext.Provider value={contextValue}>
			<FocusScope restoreFocus contain={contain}>
				{children}
			</FocusScope>
		</OverlayContext.Provider>
	) : (
		<OverlayContext.Provider value={contextValue}>{children}</OverlayContext.Provider>
	);

	return ReactDOM.createPortal(contents, portalContainer);
}

/** @private */
export function useOverlayFocusContain() {
	const ctx = useContext(OverlayContext);
	const setContain = ctx?.setContain;
	useLayoutEffect(() => {
		setContain?.(true);
	}, [setContain]);
}
