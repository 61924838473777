// import { fetcher } from 'shared/api/config';
// import { ACCOUNT_URL } from 'shared/config';
// import { GetBonusCard200Response } from 'shared/lib/types/account/models';

import { fetcher } from "api/setupAxios";
import { ACCOUNT_URL } from "store/auth/constants";
import { GetBonusCard200Response } from "store/auth/types/account/get-bonus-card-200-response";

export const getBonusCard = () =>
	fetcher<GetBonusCard200Response>({
		url: `${ACCOUNT_URL}/bonus-card`,
		method: 'GET',
		authorization: true,
	});

export const sendBonusCardRegister = () =>
	fetcher<GetBonusCard200Response>({
		url: `${ACCOUNT_URL}/bonus-card/register`,
		method: 'POST',
		authorization: true,
	});

export const sendBonusCardOnboarding = () =>
	fetcher<GetBonusCard200Response>({
		url: `${ACCOUNT_URL}/bonus-card/onboard`,
		method: 'POST',
		authorization: true,
	});

export const bonusCardRequests = {
	getBonusCard,
	sendBonusCardRegister,
	sendBonusCardOnboarding,
};
