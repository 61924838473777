import { and } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
// import { GuestFormValues } from 'widgets/order/order-confirmation/model/confirmation';
// import { countriesModel } from 'entities/countries';
// import { documentTypesModel, documentTypesValidationFactory } from 'entities/document-types';
// import { officesModel } from 'entities/offices';
import { userRoleModel } from 'entities/order';
// import { parsePassportData, profileModel } from 'entities/profile';
// import { referralsModel } from 'entities/referrals';
// import { DEFAULT_MOSCOW_OFFICE_ID, OrderRoutes } from 'shared/config';
// import { scrollToElement, trimFactory } from 'shared/lib/utils';
// import { routerModel } from 'shared/model';
// import { getValidation } from 'shared/ui/organisms/form/lib/validation';
import { CustomerFormKeys, customerFormValidationScheme, CustomerFormValues } from '../lib';
import { OrderRoutes } from 'config/constants/order';
import { profileModel, parsePassportData } from 'layout/components/auth/profile';
import { getValidation } from 'layout/components/inputs/form/lib/validation';
import { DEFAULT_MOSCOW_OFFICE_ID } from 'store/auth/constants';
import { trimFactory } from 'utils/form';
import { routerModel } from 'utils/router';
import { scrollToElement } from 'utils/scroll';
import { GuestFormValues } from '../../order-confirmation/model/confirmation';
import { countriesModel } from 'store/countries';
import { documentTypesModel, documentTypesValidationFactory } from 'entities/document-types';
import { officesModel } from 'entities/offices';
import { referralsModel } from 'entities/referrals';

const Gate = createGate();
const GateCustomer = createGate();

/**
 * Задаем изначальные значения формы только когда оба стора будут заполнены
 */
const $guestValues = createStore<Nullable<GuestFormValues>>(null);

const $setInitialAuthorizationValues = and(Gate.status, profileModel.$profile);

const resetGuestData = createEvent();

/**
 * Флаг $isInitialSettingOfValues нужен для определения -
 * изначальное ли это задание дефолтных значений или нет.
 * Нужен, чтобы при переходе между шагами не перетирать данные формы.
 */
const setIsInitialSettingOfValues = createEvent();
const $isInitialSettingOfValues = createStore(true).reset(resetGuestData);

sample({
	clock: setIsInitialSettingOfValues,
	fn: () => false,
	target: $isInitialSettingOfValues,
});

/* *
 * Подготовка справочников
 */
sample({
	clock: Gate.open,
	target: [
		countriesModel.fetchCountries,
		documentTypesModel.fetchDocumentTypes,
		officesModel.fetchOffices,
	],
});

/* *
 * Форма
 */
const form = createForm<CustomerFormValues>({
	initialValues: {
		lastName: '',
		firstName: '',
		middleName: '',
		phone: '',
		email: '',
		officeId: '35',
		citizenship: null,
		birthday: null,
		gender: null,
		documentType: null,
		documentData: null,
		referrer: null,
		isCallCenter: '35',
		building: '',
		buildingNumber: '',
		city: '',
		country: '',
		district: '',
		office: '',
		raw: '',
		region: '',
		street: '',
		zip: '',
	},
	validate: ({ values }) => {
		const errors: Partial<Record<CustomerFormKeys, string>> = {};

		Object.entries(values).forEach(([key, value]) => {
			const validate = getValidation(customerFormValidationScheme[key as CustomerFormKeys]);
			errors[key as CustomerFormKeys] = validate(value);
		});

		return errors;
	},
	onSubmit: () => {
		routerModel.push(OrderRoutes.Confirmation);
	},
});

/**
 * Стор с данными заказчика
 */
const $customerData = createStore<Nullable<CustomerFormValues>>(null).reset(resetGuestData);
const $customerBitrixData = createStore<Nullable<CustomerFormValues>>(null).reset(resetGuestData);

sample({
	clock: form.submit,
	source: form.$values,
	target: $customerData,
});

sample({
	clock: form.$values,
	target: $customerBitrixData,
});

const $prepearedCustomerData = $customerData.map((customer) => {
	if (!customer) {
		return null;
	}

	const passportFields = customer?.documentData ? parsePassportData(customer.documentData) : {};

	return {
		...customer,
		...passportFields,
	};
});

const $prepearedCustomerBitrixData = $customerBitrixData.map((customer) => {
	if (!customer) {
		return null;
	}

	const passportFields = customer?.documentData ? parsePassportData(customer.documentData) : {};

	return {
		...customer,
		...passportFields,
	};
});

/* *
 * Триминг
 */
trimFactory(form, ['firstName', 'middleName', 'lastName', 'phone', 'email']);

/* *
 * Установка начальных значений
 */
sample({
	clock: [$setInitialAuthorizationValues],
	source: {
		values: form.$values,
		profile: profileModel.$profile,
		isInitialSettingOfValues: $isInitialSettingOfValues,
	},
	filter: ({ isInitialSettingOfValues }, clock) => isInitialSettingOfValues && clock,
	// eslint-disable-next-line complexity
	fn: ({ values, profile }) => {
		const { passportData, phone, email, mailingAddress } = profile ?? {};

		const officeId = values?.officeId;

		const citizenship = passportData?.citizenship || values?.citizenship;

		return {
			lastName: passportData?.lastName || '',
			firstName: passportData?.firstName || '',
			middleName: passportData?.middleName || '',
			phone: phone ? `+${phone}` : '',
			email: email ?? '',
			officeId: officeId?.toString() ?? null,
			isCallCenter: '35',
			citizenship: citizenship?.toString() ?? null,
			birthday: passportData?.birthday ?? null,
			gender: passportData?.gender?.toString() ?? null,
			documentType: passportData?.documentType ?? null,
			documentData:
				[passportData?.passportSeries, passportData?.passportNumber].filter(Boolean).join(' ') ??
				null,
			referrer: values?.referrer ?? null,
			building: mailingAddress?.building || '',
			buildingNumber: mailingAddress?.buildingNumber || '',
			city: mailingAddress?.city || '',
			country: mailingAddress?.country || '',
			district: mailingAddress?.district || '',
			office: mailingAddress?.office || '',
			raw: mailingAddress?.raw || '',
			region: mailingAddress?.region || '',
			street: mailingAddress?.street || '',
			zip: mailingAddress?.zip || '',
		};
	},
	target: [form.setValues, setIsInitialSettingOfValues],
});

/**
 * Повторная установка значений,
 * если форма уже была инициализированна в этом инстансе приложения,
 * то восстанавливаем значения формы из $customerData
 */
const parseCustomerForm = () => JSON.parse(sessionStorage.getItem('customerForm') || 'null');

const resetCustomerFormSS = createEvent();
const resetCustomerFormSSFx = createEffect(() => {
	sessionStorage.removeItem('customerForm');
});

const loadCustomerFormFx = createEffect(parseCustomerForm);
const loadCustomerFormSS = createEvent();
const syncCustomerFormSSFx = createEffect((customerForm: Nullable<CustomerFormValues>) => {
	sessionStorage.setItem('customerForm', JSON.stringify(customerForm));
});

sample({
	clock: resetCustomerFormSS,
	target: resetCustomerFormSSFx,
});

sample({
	clock: $customerData,
	filter: (data) => !!data && !!data.lastName,
	fn: (data) => data,
	target: syncCustomerFormSSFx,
});

sample({
	clock: Gate.open,
	target: loadCustomerFormSS,
});

sample({
	clock: loadCustomerFormSS,
	target: loadCustomerFormFx,
});

sample({
	clock: loadCustomerFormFx.doneData,
	target: $customerData,
});

sample({
	source: $customerData,
	clock: Gate.open,
	filter: (source) => Boolean(source),
	fn: (source) => source as CustomerFormValues,
	target: form.setValues,
});

/* *
 * Тип документа
 */
const resetDocumentData = createEvent();

const $documentType = form.$values.map(({ documentType }) => Number(documentType));
const $documentTypePattern = createStore('');

const { $scheme: $documentTypesValidation } = documentTypesValidationFactory({
	$pattern: $documentTypePattern,
	form,
	field: 'documentData',
	required: false,
});

sample({
	source: {
		type: $documentType,
		types: documentTypesModel.$documentTypes,
	},
	filter: ({ type }) => Boolean(type),
	fn: ({ type, types }) => types.find(({ id }) => id === type)?.pattern || '',
	target: $documentTypePattern,
});

sample({
	clock: resetDocumentData,
	fn: () => ({
		field: 'documentData',
		value: null,
	}),
	target: form.setValue,
});

sample({
	clock: resetDocumentData,
	source: form.$errorsInline,
	filter: (errors) => Object.keys(errors).includes('documentData'),
	fn: () => ({ field: 'documentData' }),
	target: form.setOrDeleteError,
});

/* *
 * Справочник реферралов
 */
const $officeId = form.$values.map(({ officeId }) => officeId);

sample({
	source: $officeId,
	clock: [$officeId, Gate.open],
	fn: (officeId) => ({
		officeId: officeId ?? DEFAULT_MOSCOW_OFFICE_ID.toString(),
	}),
	target: referralsModel.fetchReferralTypes,
});

/**
 * Сбрасываем сторы при начале оформления заказа,
 * чтобы в моделе не осталось старых значений - кейс,
 * когда пользователь изменил личные данные в личном кабинете
 */
sample({
	clock: userRoleModel.goToOrdering,
	target: resetGuestData,
});

const scrollFx = createEffect(() => {
	setTimeout(() => {
		const input = document.querySelector('[aria-errormessage]');
		const radio = document.querySelector('[aria-radio-errormessage]');
		const select = document.querySelector('[aria-select-errormessage]');

		if (input) {
			return scrollToElement(input as HTMLElement, 30);
		}

		if (radio) {
			return scrollToElement(radio as HTMLElement, 140);
		}

		if (select) {
			return scrollToElement(select as HTMLElement, 120);
		}
	}, 100);
});

sample({
	clock: form.submit,
	target: scrollFx,
});

export const model = {
	Gate,
	GateCustomer,
	resetGuestData,
	form,
	$guestValues,
	resetDocumentData,
	$documentType,
	$documentTypePattern,
	$customerData,
	loadCustomerFormSS,
	resetCustomerFormSS,
	$prepearedCustomerData,
	$prepearedCustomerBitrixData,
	$documentTypesValidation,
};
