import React, { FC, memo, useState } from 'react';
import classnames from 'classnames';
// import Image from 'next/image';
import { useCardHolderName } from 'entities/bonus-card/lib';
// import { PRINT_BONUS_CARD_BG } from 'shared/config';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { ProfileModel } from 'shared/lib/types';
// import { GetBonusCard200Response } from 'shared/lib/types/account/models';
// import { dischargeNumber, print, setAnalytics } from 'shared/lib/utils';
// import { Button, Logo, Portal } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { GetBonusCard200Response } from 'store/auth/types/account/get-bonus-card-200-response';
import { ProfileModel } from 'config/types/personal';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Logo } from 'layout/components/view/logo';
import { PRINT_BONUS_CARD_BG } from 'store/auth/constants';
import { dischargeNumber } from 'utils/number';
import { print } from 'utils/print';
import { Portal } from 'layout/components/portal';

interface PrintBonusCardProps extends GetBonusCard200Response, Pick<ProfileModel, 'passportData'> { }

export const PrintBonusCard: FC<PrintBonusCardProps> = memo(({ passportData, cardNumber }) => {
	const { lastName, firstName, middleName } = useCardHolderName({ passportData });
	const [mountPrint, setMountPrint] = useState(false);

	const handlePrint = () => {
		const { body } = document;

		const printing = async () => {
			await print({
				beforeAction: () => {
					setMountPrint(true);
					body.classList.add('print-only-portal');
				},
			});
		};

		printing().then(() => {
			setMountPrint(false);
			body.classList.remove('print-only-portal');
		});

		// setAnalytics(ANALYTICS_TARGETS.account.account_bonus_print);
	};

	return (
		<>
			<Button size="large" btnType="text" onClick={handlePrint}>
				Распечатать бонусную карту
				<Icon id="download" width={20} height={20} />
			</Button>
			{mountPrint && (
				<Portal>
					<div className={classnames(styles.printBonusCard, 'print-page')}>
						<div className={styles.printBonusCard__head}>
							<Logo size="lg" />
						</div>
						<div className={styles.printBonusCard__content}>
							<div className={styles.printBonusCard__card}>
								<img
									src={PRINT_BONUS_CARD_BG}
								// objectFit="cover" 
								// layout="fill" 
								/>

								<div className={styles.printBonusCard__cardHead}>
									<Icon id="klk" width={48} height={37} />
									Клуб любителей круизов
								</div>

								<div className={styles.printBonusCard__cardInfo}>
									<div className={styles.printBonusCard__cardHolder}>
										{lastName} {firstName} {middleName}
									</div>
									{cardNumber && (
										<div className={styles.printBonusCard__cardNumber}>
											Номер карты <strong>{dischargeNumber(Number(cardNumber))}</strong>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</Portal>
			)}
		</>
	);
});
