import { MaskOptions } from 'maska';

export const emailPattern = /^[\w+.А-я\-]+@([\d.A-Za-zА-я\-]+\.)[\d.A-Za-zА-я\-]+$/;
export const emailSymbolPattern = /[\w+.@А-я\-]/;

export const emailMask = {
	mask: 'a',
	maskOptions: {
		tokens: {
			a: {
				pattern: emailSymbolPattern,
				transform: (char) => char.toLowerCase(),
				multiple: true,
			},
		},
	} as MaskOptions,
};

export const namePattern = /^[\d\sA-Za-zА-яё-]*$/;

export const nameMask = {
	mask: 'a',
	maskOptions: {
		tokens: {
			a: {
				pattern: namePattern,
				multiple: true,
			},
		},
	} as MaskOptions,
};

const passportIssuedPattern = /^[\d\s,./A-Za-zА-яё-]*$/;
export const passportIssuedMask = {
	mask: 'a',
	maskOptions: {
		tokens: {
			a: {
				pattern: passportIssuedPattern,
				multiple: true,
			},
		},
	} as MaskOptions,
};
