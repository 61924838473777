import { ParsedUrlQuery } from 'querystring';
import dayjs from 'dayjs';
import 'dayjs/plugin/timezone'
import { TIMEZONE_DEFAULT } from 'config/commonConstants';

export const normalizeISODatesInParams = (obj?: Nullable<ParsedUrlQuery>): ParsedUrlQuery => {
	if (!obj) {
		return {};
	}

	return Object.entries(obj).reduce<ParsedUrlQuery>((acc, [key, value]) => {
		if (key.includes('date')) {
			if (typeof value === 'string') {
				acc[key] = getDateStringValue(key, value);
			}

			if (Array.isArray(value)) {
				acc[key] = value.map((item) => getDateStringValue(key, item));
			}
		} else {
			acc[key] = value;
		}

		return acc;
	}, {});
};

export const stringifyParsedUrlQuery = (obj?: Nullable<ParsedUrlQuery>) => {
	if (!obj) {
		return '';
	}

	const map = Object.entries(obj).reduce((acc, [key, value]) => {
		if (value) {
			const isArray = Array.isArray(value);
			const setValue = isArray
				? value.filter((item) => !item.includes('fake-parent')).join(',')
				: value;

			acc.set(key, setValue);
		}

		return acc;
	}, new Map());

	return [...map].map(([key, value]) => `${key}=${value}`).join('&');
};

export const handleArraysInParsedUrlQuery = (obj?: Nullable<ParsedUrlQuery>) => {
	if (!obj) {
		return {};
	}

	return Object.entries(obj).reduce<ParsedUrlQuery>((acc, [key, value]) => {
		acc[key] = typeof value === 'string' ? value.split(',') : value;

		return acc;
	}, {});
};

export const covertArraysInParsedUrlQuery = (obj?: Nullable<ParsedUrlQuery>) => {
	if (!obj) {
		return {};
	}

	return Object.entries(obj).reduce<ParsedUrlQuery>((acc, [key, value]) => {
		acc[key] = Array.isArray(value) ? value.join(',') : value;

		return acc;
	}, {});
};

const attachTime = (key: string, value: string) => {
	const isLte = key.toLowerCase().includes('lte');

	if (isLte) {
		return value.includes('T23:59:00') ? value : `${value}T23:59:59`;
	}

	return value.includes('T00:00:00') ? value : `${value}T00:00:00`;
};

const getDateStringValue = (key: string, value: string) =>
	dayjs(attachTime(key, value)).tz(TIMEZONE_DEFAULT).toISOString().split('.')[0];
