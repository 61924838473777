import React, { FC } from 'react';
// import { Slider } from '../slider';
// import { SliderProps } from '../slider/types';
import styles from './styles.module.scss';
import { Slider } from '../slider';
import { SliderProps } from '../slider/types';

interface PageableSliderProps extends Pick<SliderProps, 'items' | 'Component' | 'options'> {
	className?: string;
}

export const PageableSlider: FC<PageableSliderProps> = React.memo(
	({ Component, items, options, className }) => (
		<div className={className}>
			<Slider
				Component={Component}
				items={items}
				options={{
					gap: 16,
					perPage: 3,
					omitEnd: true,
					breakpoints: {
						1024: {
							perPage: 1,
							omitEnd: true,
						},
					},
					...options,
				}}
				className={styles.pageableSlider}
				trackWrapClassName={styles.pageableSliderWrap}
				trackClassName={styles.pageableSliderTrack}
				controlsClassName={styles.pageableSliderControls}
				hasControls
			/>
		</div>
	),
);
