import { sample } from 'effector';
import { createGate } from 'effector-react';
// import { countriesModel } from 'entities/countries';
import { documentTypesModel } from 'entities/document-types';
import { countriesModel } from 'store/countries';

const Gate = createGate();

sample({
	clock: Gate.open,
	target: [countriesModel.fetchCountries, documentTypesModel.fetchDocumentTypes],
});

export const model = {
	Gate,
};
