import React, { Key, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { Placement } from '@react-types/overlays';
import classnames from 'classnames';
import { useButton, useFilter } from 'react-aria';
// import { ControlsTheme } from 'shared/lib/types';
// import { getNumDeclension } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Input } from 'shared/ui/atoms/input';
// import { IconId } from 'shared/ui/icons';
// import { ListBox, ListBoxProps } from 'shared/ui/molecules/list-box';
// import { SelectHead, SelectWrap, SelectPopover } from '../select-head';
import { MultiSelectProps, HiddenMultiSelect, useMultiSelect, useMultiSelectState } from './lib';
import style from './style.module.scss';
import { IconId } from 'layout/components/icons/svg-sprite';
import { useViewport } from 'layout/viewport';
import { getNumDeclension } from 'utils/string';
import { Input } from '../../input';
import { SelectHead, SelectPopover, SelectWrap } from '../select-head';
import { ListBox, ListBoxProps } from 'layout/components/view/list-box';
import { ControlsTheme } from 'config/commonConstants';

export interface SelectClassnamesProps {
	className?: string;
	classNameTrigger?: string;
	classNamePopover?: string;
	classNameModalContent?: string;
	classNameModalFooter?: string;
	classNameModalHeader?: string;
	classNameModalGroupOption?: string;
	classNameModalIsOpen?: string;
}

export interface SelectProps<T extends object> extends MultiSelectProps<T>, SelectClassnamesProps {
	searchPlaceholder?: string;
	withSearch?: boolean;
	size?: 'sm' | 'md' | 'lg';
	viewType?: ListBoxProps['viewType'];
	placement?: Placement;
	theme?: ControlsTheme;
	icon?: IconId;
	isRequired?: boolean;
	customValue?: ReactNode;
	alwaysShowChevron?: boolean;
	error?: JSX.Element;
	errorMessage?: ReactElement;
	moveIcon?: boolean;
	textValuePostfix?: [string, string, string];
	placeholderPrimaryColor?: boolean;
	footer?: ReactNode;
	labelPosition?: 'left' | 'top';
	onReset?: () => void;
	onOpenChange?: (isOpen: boolean) => void;
	mobileModalMode?: boolean;
	mobileModalFixedSize?: boolean;
	selectPortal?: HTMLElement;
	resetSelectValue?: boolean;
	emptyValue?: string;
}

export const Select = <T extends object>(props: SelectProps<T>) => {
	const {
		label,
		name,
		placeholder,
		emptyValue,
		searchPlaceholder,
		className,
		classNameTrigger,
		classNamePopover,
		classNameModalContent,
		classNameModalFooter,
		classNameModalHeader,
		classNameModalGroupOption,
		classNameModalIsOpen,
		isDisabled,
		isRequired,
		resetSelectValue,
		selectionMode = 'single',
		withSearch,
		theme,
		moveIcon = false,
		size = 'lg',
		placement = 'bottom left',
		icon,
		customValue,
		alwaysShowChevron,
		error,
		errorMessage,
		textValuePostfix,
		placeholderPrimaryColor,
		viewType,
		footer = null,
		labelPosition,
		onReset,
		onOpenChange,
		mobileModalMode,
		mobileModalFixedSize,
		selectPortal,
	} = props;

	const ref = useRef(null);
	const inputRef = useRef(null);

	const { isMob } = useViewport();
	const modalMode = Boolean(mobileModalMode && isMob);

	const { contains } = useFilter({ sensitivity: 'base' });
	const state = useMultiSelectState({
		...props,
		defaultFilter: contains,
		selectionMode,
		checkCollectionSize: !errorMessage,
		onOpenChange: (isOpen) => {
			if (!isOpen && state.setInputValue) {
				state.setInputValue('');
			}
			onOpenChange?.(isOpen);
		},
	});

	const { isOpen, isFocused, selectedItems, setSelectedKeys, disabledKeys } = state;
	const { labelProps, triggerProps, valueProps, menuProps, inputProps } = useMultiSelect(
		{
			...props,
			inputRef,
		},
		state,
		ref,
	);
	const { buttonProps } = useButton(triggerProps, ref);

	const getValueText = () => {
		const texts = selectedItems?.map(({ rendered }) => rendered);

		if (!texts || texts.length === 0) {
			return placeholder;
		}

		if (texts.length === 1) {
			// eslint-disable-next-line react/jsx-no-useless-fragment
			return <>{texts}</>;
		}

		const frags = [`Выбрано ${texts.length}`];

		if (textValuePostfix) {
			frags.push(getNumDeclension(texts.length, textValuePostfix));
		}

		return frags.join(' ');
	};

	const value = getValueText();

	const handleReset = () => {
		setSelectedKeys(new Set<Key>());
		onReset?.();
	};

	const [innerScrollReachedEnd, setInnerScrollReachedEnd] = useState(false);
	const [innerScrollReachedStart, setInnerScrollReachedStart] = useState(true);

	const [prevValue, setPrevValue] = useState(new Set<Key>());
	useEffect(() => {
		const { selectedKeys } = state;

		if (isOpen) {
			setPrevValue(selectedKeys);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	useEffect(() => {
		if (!resetSelectValue) {
			return;
		}
		setSelectedKeys(new Set<Key>());
	}, [resetSelectValue, setSelectedKeys]);

	const searchInput = withSearch ? (
		<Input
			{...inputProps}
			ref={inputRef}
			classNameWrap={classnames(style.select__searchInput, {
				[style.modalMode]: modalMode,
			})}
			placeholder={searchPlaceholder ?? ''}
			icon="search"
			size="md"
		/>
	) : undefined;

	return (
		<SelectWrap
			size={size}
			theme={theme}
			className={className}
			isFocused={isOpen || isFocused}
			isDisabled={isDisabled}
			isInvalid={!!error}
			error={error}
		>
			<SelectHead
				ref={ref}
				label={label}
				value={customValue ?? value}
				size={size}
				theme={theme}
				icon={icon}
				buttonProps={buttonProps}
				moveIcon={moveIcon}
				labelProps={labelProps}
				valueProps={valueProps}
				isPlaceholder={selectedItems === null || selectedItems?.length === 0}
				isRequired={isRequired}
				isDisabled={isDisabled}
				isOpen={isOpen}
				className={classNameTrigger}
				classNameModalIsOpen={classNameModalIsOpen}
				onReset={handleReset}
				alwaysShowChevron={alwaysShowChevron}
				placeholderPrimaryColor={placeholderPrimaryColor}
				placeholder={placeholder}
				emptyValue={emptyValue}
				labelPosition={labelPosition}
			/>
			<SelectPopover
				state={state}
				triggerRef={ref}
				placement={placement}
				className={classNamePopover}
				classNameModalContent={classNameModalContent}
				classNameModalFooter={classNameModalFooter}
				classNameModalHeader={classnames(style.select__modalHeader, classNameModalHeader)}
				modalMode={modalMode}
				modalTitle={label}
				modalFixedSize={mobileModalFixedSize}
				modalScrollReachedEnd={innerScrollReachedEnd}
				modalScrollReachedStart={innerScrollReachedStart}
				modalHeader={modalMode ? searchInput : undefined}
				onReset={handleReset}
				key={[...disabledKeys].join(',')}
				portal={selectPortal}
				onClose={() => {
					setSelectedKeys(prevValue);
				}}
			>
				{!modalMode && searchInput}
				<ListBox
					{...menuProps}
					state={state}
					selectionMode={selectionMode}
					viewType={viewType}
					withSearch={withSearch}
					classNameModalGroupOption={classNameModalGroupOption}
					withScroll={!modalMode || (modalMode && mobileModalFixedSize)}
					errorMessage={errorMessage}
					autoSize={modalMode && mobileModalFixedSize}
					onScroll={({ y }, reachedEnd) => {
						setInnerScrollReachedEnd(Boolean(reachedEnd));
						setInnerScrollReachedStart(y === 0);
					}}
				/>
				{footer}
			</SelectPopover>
			<HiddenMultiSelect state={state} triggerRef={ref} label={label} name={name} />
		</SelectWrap>
	);
};
