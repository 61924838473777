/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import classnames from 'classnames';
// import { Scroller } from 'shared/ui/molecules';
import { SliderProps } from '../../types';
import styles from './styles.module.scss';
import { Scroller } from 'layout/components/special/scroller';

interface ThumbnailsPanelProps extends Pick<SliderProps, 'items' | 'Component'> {
	goTo: (index: number) => void;
	active?: number;
}

export const ThumbnailsPanel = ({ items, Component, goTo, active = 0 }: ThumbnailsPanelProps) => {
	const ref = useRef<HTMLDivElement>(null);

	return (
		<div ref={ref} className={styles.wrapper}>
			<Scroller theme="blue" showOnHover={false} className={styles.thumbnailsScroll}>
				<div className={styles.panel}>
					{items &&
						items.map((item, index) => (
							<div
								key={index}
								className={classnames(
									styles.thumbnail,
									active === index && styles.thumbnail_active,
								)}
								onMouseEnter={() => goTo(index)}
							>
								<Component key={item} {...item} index={index} />
							</div>
						))}
				</div>
			</Scroller>
		</div>
	);
};
