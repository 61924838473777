import { useEffect, useState } from 'react';
// import { useRouter } from 'next/router';
import { useUnit } from 'effector-react';
import { profilatorModel } from '.';


export const useUtmToLocalStorage = () => {
	const [isFirstTime, setIsFirstTime] = useState(false);
	const [setProfilator, profilator] = useUnit([
		profilatorModel.setProfilator,
		profilatorModel.$profilator,
	]);

	// const { query } = useRouter();

	useEffect(() => {
		if (!isFirstTime && !profilator) {
			setIsFirstTime(true);
			const { referrer } = document;
			// setProfilator({ referrer, query });
		}
	}, [isFirstTime,
		null,
		// query, 
		profilator, setProfilator]);
};
