import { combineEvents, condition } from 'patronum';
import { createEffect, createEvent, sample } from 'effector';
import type { CruiseCabin } from 'config/types/cabins/models/cruise-cabin-booking';
import { cartModel } from 'entities/cart';
import type { CartItem } from 'entities/cart';
import { USER_METRICS_COOKIE_NAME } from 'config/constants/ecommerce';
import { ecommerceRequests } from 'store/cruise/ecommerce';
import { getFilledEcommerceObject } from 'utils/analytics/ecommerce';
import { detailedCruiseCabinModel } from 'entities/cabin-booking-cards';
import { getCookie } from 'utils/cookie';
import { cartWarningModel } from 'entities/cart-warning';
// import { cartWarningModel } from 'features/cart-warning';
// import { detailedCruiseCabinModel } from 'entities/cabin-booking-cards';
// import type { CartItem } from 'entities/cart';
// import { cartModel } from 'entities/cart';
// import { ecommerceRequests } from 'shared/api';
// import { USER_METRICS_COOKIE_NAME } from 'shared/config';
// import { CURRENCY } from 'shared/config/currency';
// import type { CruiseCabin } from 'shared/lib/types';
// import { getCookie, getFilledEcommerceObject } from 'shared/lib/utils';

type Optional<T> = {
	[Property in keyof T]+?: T[Property];
};

export interface AddToCartParams extends Omit<CartItem, 'places'> {
	places: CruiseCabin.Places | CartItem['places'];
}

interface RemoveFromCartParams extends Pick<CartItem, 'id'> {}

interface UpdateCartParams
	extends Optional<Omit<AddToCartParams, 'id'>>,
		Pick<AddToCartParams, 'id'> {}

const addToCart = createEvent<AddToCartParams>();
const removeFromCart = createEvent<RemoveFromCartParams>();
const updateCart = createEvent<UpdateCartParams>();

const addedToCart = combineEvents({ events: [addToCart, cartModel.$cart.updates] });

sample({
	clock: addedToCart,
	target: detailedCruiseCabinModel.openSuccessModal,
});

const getPlaces = (places: AddToCartParams['places']) => {
	if (typeof places.main === 'number' && typeof places.additional === 'number') {
		return {
			main: Array(places.main).fill({ type: '0' }),
			additional: Array(places.additional).fill({ type: null }),
		};
	}

	return places as CartItem['places'];
};

const addPlaceToCart = createEvent<AddToCartParams>();
const addPlaceToCartWithDialog = createEvent<AddToCartParams>();

/**
 * Проверяет возможность добавления в корзину
 */
condition({
	source: addToCart,
	if: ({ cruiseId }) => {
		const activeCruiseId = cartModel.$activeCruise.getState();

		return !activeCruiseId || cruiseId === activeCruiseId;
	},
	then: addPlaceToCart,
	else: addPlaceToCartWithDialog,
});

sample({
	clock: addPlaceToCartWithDialog,
	fn: (cabin) => ({
		...cabin,
		places: getPlaces(cabin.places),
	}),
	target: cartWarningModel.showCartWarning,
});

/**
 * Добавление в корзину, если места с таким id еще нет в корзине
 */
// TODO: включить типизацию (найти что не так)
sample({
	source: cartModel.$cart,
	clock: addPlaceToCart,
	filter: (cart: any, { id }: any) => !cart.some((place: any) => place.id === id),
	fn: (cart: any, cabin: any) => [
		...cart,
		{
			...cabin,
			places: getPlaces(cabin.places),
		},
	],
	target: [cartModel.$cart, cartModel.getCartPricesWithUpdates],
} as any); // 

const ecommerceFx = createEffect(
	async ({ price, cart }: { price?: number; quantity?: number; cart: CartItem[] }) => {
		const info = await ecommerceRequests.getCruiseInfo({ cruiseId: cart[0].cruiseId.toString() });

		// @ts-ignore
		window.dataLayer.push(
			getFilledEcommerceObject({
				ecommerce: {
					// @ts-ignore
					currencyCode: CURRENCY[info.currency ?? 1].toUpperCase(),
					add: {
						products: [
							{
								id: cart[0].cruiseId?.toString(),
								name: info.name,
								price,
								brand: info.brand,
								category: info.category,
								variant: info.variant,
							},
						],
					},
				},
			}),
		);
	},
);

const addBasketMetricsFx = createEffect(ecommerceRequests.addMetricsEvent);

const addBasketMetrics = createEvent();

sample({
	clock: addBasketMetrics,
	fn: () => ({
		userId: getCookie(USER_METRICS_COOKIE_NAME) ?? '',
		eventType: 'basket_add',
	}),
	target: addBasketMetricsFx,
});

sample({
	clock: combineEvents({
		events: [cartModel.$activeCruise.updates, cartModel.updateCabinStatusesWithNewPrice],
	}),
	source: {
		total: cartModel.$totalPrice,
		cart: cartModel.$cart,
	},
	fn: ({ total, cart }) => ({
		price: total?.total,
		cart,
	}),
	target: [ecommerceFx, addBasketMetrics],
});

/**
 * Открытие модального окна, сообщающего об успехе, при добавлении в корзину
 */
sample({
	clock: addToCart,
	source: {
		parent: detailedCruiseCabinModel.ModalGate.state,
		success: detailedCruiseCabinModel.SuccessModalGate.state,
		activeCruise: cartModel.$activeCruise,
	},
	filter: ({ parent, activeCruise }, { cruiseId }) =>
		parent.isOpen && (!activeCruise || cruiseId === activeCruise),
	fn:
		({ success }) =>
		() =>
			success?.setOpen(true),
	target: detailedCruiseCabinModel.openSuccessModal,
});

/**
 * Если каюта уже добавлена в корзину и был произведён пересчёт цен, данные в корзине обновляются
 */
sample({
	source: cartModel.$cart,
	clock: detailedCruiseCabinModel.getDetailedCruiseCabinInfoFx.doneData,
	filter: (cart, { id }) => cart.some((cabin) => cabin.id === id),
	fn: (cart, cabin) => ({
		id: cabin.id,
		places: getPlaces(cabin.places),
	}),
	target: updateCart,
});

sample({
	clock: cartWarningModel.clearCartAndCreateNew,
	target: detailedCruiseCabinModel.openSuccessModal,
});

/**
 * Установка активного круиза
 */
sample({
	clock: addPlaceToCart,
	fn: ({ cruiseId }) => cruiseId,
	target: cartModel.$activeCruise,
});

/**
 * Удаление места из корзины
 */
// TODO: включить типизацию (найти что не так)
sample({
	source: cartModel.$cart,
	clock: removeFromCart,
	fn: (cart: any, { id }: any) => cart.filter((place: any) => place.id !== id),
	target: [cartModel.$cart, cartModel.getCartPricesWithUpdates],
} as any);

/**
 * Обновление уже добавленной каюты
 */
sample({
	source: cartModel.$cart,
	clock: updateCart,
	filter: (cart, { id }) => cart.some((cabin) => cabin.id === id),
	fn: (cart, newValuesCabin) => {
		const newCart = [...cart];
		const index = cart.findIndex((cabin) => cabin.id === newValuesCabin.id);
		const oldValues = newCart[index];
		newCart[index] = {
			...oldValues,
			...newValuesCabin,
			places: newValuesCabin.places ? getPlaces(newValuesCabin.places) : oldValues.places,
		};

		return newCart;
	},
	target: cartModel.$cart,
});

export const model = {
	addToCart,
	removeFromCart,
	updateCart,
};
