import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';

interface ErrorsBlockProps {
	error: string;
	className?: string;
}

export interface FormErrorMessageModel {
	title?: string;
	text?: string;
}

function safelyParseJSON(json: string) {
	let parsed;

	try {
		parsed = JSON.parse(json);
		// eslint-disable-next-line no-empty
	} catch {}

	return parsed;
}

export const ErrorsBlock = ({ error, className }: ErrorsBlockProps) => {
	const errorsBlocks: FormErrorMessageModel[] | undefined = useMemo(
		() => safelyParseJSON(error),
		[error],
	);

	return (
		<Alert className={className} type="error">
			<div className={styles.wrapper}>
				{errorsBlocks &&
					Array.isArray(errorsBlocks) &&
					errorsBlocks.map((block) => (
						<div className={styles.block} key={block.text}>
							{block.title && <Typo design="headline-xs">{block.title}</Typo>}
							<Typo design="text-s">{block.text}</Typo>
						</div>
					))}
				{!errorsBlocks && <Typo design="text-s" raw={error} />}
			</div>
		</Alert>
	);
};
