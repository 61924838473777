import React, { FC, useEffect, useRef } from 'react';
import cn from 'classnames';
import { AriaCheckboxProps, useCheckbox, useFocusRing, VisuallyHidden } from 'react-aria';
import { useToggleState } from 'react-stately';
import style from './style.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { Typo } from 'layout/components/typo/ui';

interface CheckboxProps extends AriaCheckboxProps, DefaultStyledProps {
	error?: JSX.Element;
	theme?: 'secondary';
	resetSelectValue?: boolean;
}

export const Checkbox: FC<CheckboxProps> = (props) => {
	const {
		className,
		children,
		isIndeterminate,
		resetSelectValue,
		error,
		theme,
		value,
		isDisabled,
	} = props;
	const ref = useRef(null);
	const state = useToggleState({ ...props, defaultSelected: !!value });
	const { focusProps, isFocusVisible } = useFocusRing();
	const { inputProps } = useCheckbox(props, state, ref);

	const isSelected = state.isSelected && !isIndeterminate;
	const checkedClassName = isSelected ? style.input__checked : null;
	const focusedClassName = isFocusVisible ? style.input__focused : null;
	const disabledClassName = isDisabled ? style.input__disabled : null;

	useEffect(() => {
		if (resetSelectValue) {
			state.setSelected(false);
		}
	}, [state, resetSelectValue]);

	return (
		<>
			<label
				className={cn(className, style.label, theme && style[theme], isDisabled && 'disabled')}
			>
				<VisuallyHidden>
					<input {...inputProps} {...focusProps} ref={ref} />
				</VisuallyHidden>
				<span
					role="checkbox"
					className={cn(style.input, checkedClassName, focusedClassName, disabledClassName)}
					aria-hidden="true"
				/>
				<span>{children}</span>
			</label>
			{error && (
				<Typo
					design="input-text-s"
					className={cn(style.error, theme && style[theme], 'errormessage')}
				>
					{error}
				</Typo>
			)}
		</>
	);
};
