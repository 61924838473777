import { Typo } from 'layout/components/typo/ui';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Condition } from 'layout/components/switchers/condition';
import { useUnit } from 'effector-react';
import { KlkBonus } from 'layout/components/view/klk-bonus';
import { routerModel } from 'utils/router';

export const SuccessRegister = () => {
	// const [prevPage, authRedirectTarget, goToOrdering] = useUnit([
	const [prevPage, authRedirectTarget] = useUnit([
		routerModel.$prevPage,
		routerModel.$authRedirectTarget,
		// TODO: вернуть эту опцию. это, вероятно, продолжение оформление заказа после регистрации
		// userRoleModel.transitionToOrdering,
	]);
	const fromBooking = prevPage?.includes('order');

	return (
		<div className={styles.successRegister}>
			<div className={styles.successRegister__head}>
				<Typo design="headline-xl">Поздравляем!</Typo>
				<Typo design="headline-s">
					Вы успешно зарегистрированы <br /> в системе Созвездие.
				</Typo>
			</div>
			<div className={styles.successRegister__bonus}>
				<Typo design="headline-s">
					В качестве бонуса вы добавлены в бонусную систему <br /> Клуба Любителей Круизов!
				</Typo>
				<div className={styles.bonuses}>
					<KlkBonus className={styles.bonus}>
						Получайте баллы <br /> за покупки круизов
					</KlkBonus>
					<KlkBonus>
						Ищите круизы <br /> с иконкой КЛК
					</KlkBonus>
					<KlkBonus>
						Тратьте баллы
						<br />
						на путешествия
					</KlkBonus>
				</div>
			</div>
			{/* <Condition
				value={Boolean(authRedirectTarget)}
				then={
					<Button onClick={goToOrdering} className={styles.successRegister__button} size="large">
						Продолжить бронирование
					</Button>
				}
				else={
					<Button href={prevPage || '/'} className={styles.successRegister__button} size="large">
						Продолжить {fromBooking && 'бронирование'}
					</Button>
				}
			/> */}
		</div>
	);
};
