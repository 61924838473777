import React, { FC, ReactNode, RefObject, memo } from 'react';
import { useUnit } from 'effector-react';
import { bonusCardModel } from 'entities/bonus-card';
import { pricesModel } from 'entities/order/index';
// import { DISCOUNT_POINTS } from 'shared/lib/constants';
// import { dischargeNumber, getNumDeclension } from 'shared/lib/utils';
// import { Condition, Price, Skeleton, StrokedPrice, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { RequestTotalSticky } from './request-total-sticky';
import styles from './styles.module.scss';
import { DISCOUNT_POINTS } from 'config/constants';
import { Icon } from 'layout/components/icons/icon';
import { Skeleton } from 'layout/components/special/skeleton';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';
import { StrokedPrice } from 'layout/components/view/stroked-price';
import { dischargeNumber } from 'utils/number';
import { getNumDeclension } from 'utils/string';

export interface RequestTotalProps {
	asideRef?: RefObject<HTMLElement>;
	excursionsTotal?: number;
	controls?: ReactNode;
}

export const RequestTotal: FC<RequestTotalProps> = memo(
	({ asideRef, excursionsTotal, controls }) => {
		const [prices, pricesStatus, bonusCard] = useUnit([
			pricesModel.$pricesPreview,
			pricesModel.$getPricesPreviewStatus,
			bonusCardModel.$card,
		]);
		const pending = pricesStatus === 'pending';
		const { default: defaultPrice, discount, total } = prices ?? {};

		return (
			<>
				<div className={styles.box}>
					{discount !== 0 && (
						<div className={styles.point}>
							<Typo design="text-s">Цена без скидки</Typo>
							<Condition
								value={pending}
								then={
									<Skeleton className={styles.skeleton} margin="2px 0 0" width={48} height={10} />
								}
								else={
									<StrokedPrice design="text-s">
										{defaultPrice && dischargeNumber(defaultPrice)}
									</StrokedPrice>
								}
							/>
						</div>
					)}

					<div className={styles.point}>
						<Typo design="headline-s">Итого к оплате</Typo>
						<Condition
							value={pending}
							then={
								<Skeleton className={styles.skeleton} margin="3px 0 0" width={98} height={16} />
							}
							else={total ? <Price design="headline-m" bold total={total} /> : undefined}
						/>
					</div>

					{/* <Typo className={styles.bonuses} design="description-features">
					<Condition
						value={pending}
						then={<Skeleton display="inline-block" width={154} height={10} />}
						else={
							bonus ? (
								<>
									от {dischargeNumber(bonus)} {getNumDeclension(bonus, DISCOUNT_POINTS)} после
									круиза
								</>
							) : undefined
						}
					/>
				</Typo> */}
					<Typo className={styles.bonuses} design="description-features">
						<Condition
							value={pending}
							then={<Skeleton display="inline-block" width={233} height={10} />}
							else={
								prices?.bonus ? (
									<div className={styles.bonus}>
										<div className={styles.bonus__card}>
											{bonusCard?.cardNumber && (
												<>
													<Icon id="klk" width={20} height={20} /> {`№ ${bonusCard?.cardNumber}`}
												</>
											)}
										</div>
										<div className={styles.bonus__point}>
											+ {dischargeNumber(prices.bonus)}{' '}
											{getNumDeclension(prices.bonus, DISCOUNT_POINTS)} после круиза
										</div>
									</div>
								) : undefined
							}
						/>
					</Typo>
				</div>

				<RequestTotalSticky
					asideRef={asideRef}
					controls={controls}
					total={excursionsTotal ?? total}
					pending={excursionsTotal ? false : pending}
				/>
			</>
		);
	},
);
