import {
	forwardRef,
	ButtonHTMLAttributes,
	DOMAttributes,
	ReactNode,
	useEffect,
	useRef,
	useMemo,
} from 'react';
import classnames from 'classnames';
import { VisuallyHidden } from 'react-aria';
import { FocusableElement } from '@react-types/shared';
// import { ControlsTheme } from 'shared/lib/types';
// import { toggleTextEllipsis } from 'shared/lib/utils/dom';
// import { Typo } from 'shared/ui/atoms';
// import { Icon, IconId } from 'shared/ui/icons';
import style from './style.module.scss';
import { IconId } from 'layout/components/icons/svg-sprite';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { ControlsTheme } from 'config/commonConstants';
import { toggleTextEllipsis } from 'utils/dom/toggle-text-ellipsis';

interface SelectHeadProps {
	buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
	labelProps?: DOMAttributes<FocusableElement>;
	valueProps?: DOMAttributes<FocusableElement>;
	label?: ReactNode;
	value?: ReactNode;
	icon?: IconId;
	isPlaceholder?: boolean;
	isRequired?: boolean;
	isDisabled?: boolean;
	isOpen?: boolean;
	className?: string;
	classNameModalIsOpen?: string;
	size?: 'sm' | 'md' | 'lg';
	theme?: ControlsTheme;
	onReset?: () => void;
	alwaysShowChevron?: boolean;
	moveIcon?: boolean;
	placeholderPrimaryColor?: boolean;
	placeholder?: string;
	labelPosition?: 'top' | 'left';
	emptyValue?: string;
}

export const SelectHead = forwardRef<HTMLButtonElement, SelectHeadProps>(
	(
		{
			buttonProps,
			labelProps,
			valueProps,
			label,
			value,
			icon,
			isPlaceholder,
			isRequired,
			isDisabled,
			isOpen,
			className,
			classNameModalIsOpen,
			size = 'lg',
			theme = 'secondary',
			emptyValue = '',
			onReset,
			alwaysShowChevron,
			moveIcon = false,
			placeholderPrimaryColor,
			placeholder,
			labelPosition,
		},
		ref,
	) => {
		const valueRef = useRef<Nullable<HTMLElement>>(null);

		useEffect(() => {
			if (!valueRef.current) {
				return;
			}

			const observer = new ResizeObserver(([entry]) => {
				toggleTextEllipsis(entry.target as HTMLElement);
			});

			observer.observe(valueRef.current);

			return () => {
				if (valueRef.current) {
					observer.unobserve(valueRef.current);
				}
			};
		}, []);

		const valueSelected = value !== placeholder;
		const iconNearValue = useMemo(
			() => moveIcon && value && valueSelected,
			[moveIcon, value, valueSelected],
		);

		return (
			<>
				<button
					ref={ref}
					className={classnames(
						className,
						style.selectHead,
						style[theme],
						(isOpen || valueSelected) && classNameModalIsOpen,
						{
							[style.isDisabled]: isDisabled,
							[style.isSmall]: size === 'sm',
						},
					)}
					{...buttonProps}
				>
					{icon && !iconNearValue && <Icon id={icon} className={style.selectHead__icon} />}
					<div
						className={classnames(
							style.selectHead__text,
							labelPosition === 'left' && style.selectHead__text_horizontal,
						)}
					>
						{size === 'lg' || size === 'md' ? (
							<Typo
								as="label"
								{...labelProps}
								className={classnames(style.selectHead__label, isRequired && style.isRequired)}
								design="button-m-s"
							>
								{label}
							</Typo>
						) : (
							<VisuallyHidden>
								<label {...labelProps}>{label}</label>
							</VisuallyHidden>
						)}
						<Typo
							{...valueProps}
							ref={(node) => {
								valueRef.current = node;
								toggleTextEllipsis(node);
							}}
							design="button-m-s"
							className={classnames(style.selectHead__value, {
								[style.isPlaceholder]: isPlaceholder && !placeholderPrimaryColor,
								[style.singlePlaceholder]: size === 'md',
							})}
						>
							{icon && iconNearValue && <Icon id={icon} className={style.selectHead__icon} />}
							{valueSelected ? value : emptyValue}
						</Typo>
					</div>
					<Icon
						id="chevron-down"
						className={classnames(style.selectHead__arrow, isOpen && style.isOpen)}
					/>
				</button>
				{!alwaysShowChevron && !isPlaceholder && onReset && (
					<button type="button" className={style.selectHead__reset} onClick={onReset}>
						<Icon id="close" />
					</button>
				)}
			</>
		);
	},
);
