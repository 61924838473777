import { sample } from 'effector';
import { getUserName, profileModel } from 'layout/components/auth/profile';
import { callbackFactory } from 'layout/components/special/callback';
// import { callbackFactory } from 'features/callback';
// import { getUserName, profileModel } from 'entities/profile';

const callbackModel = callbackFactory();

sample({
	clock: callbackModel.Gate.open,
	source: profileModel.$profile,
	fn: (profile) => ({
		name: getUserName(
			profile?.passportData?.lastName,
			profile?.passportData?.firstName,
			profile?.passportData?.middleName,
		),
		phone: profile?.phone ?? '',
		agreeProcessData: true,
	}),
	target: callbackModel.form.setValues,
});

sample({
	clock: callbackModel.Gate.close,
	target: callbackModel.resetForm,
} as any); // TODO: пришлось отключить типизицию, тк ругалось на clock

export const model = {
	...callbackModel,
};
