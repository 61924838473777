import React from 'react';

export const IncludedIcon = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" stroke="#28ce25">
		<path
			d="M16.0015 2.6665L19.5151 6.18009H25.8191V12.484L29.3327 15.9976L25.8191 19.5156V25.8196H19.5151L16.0015 29.3332L12.4836 25.8196H6.17959V19.5156L2.66602 15.9976L6.17959 12.484V6.18009H12.4836L16.0015 2.6665Z"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.75 12.75L14.2188 19.25L11.25 16.2955"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
