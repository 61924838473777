import { Typo } from 'layout/components/typo/ui';
import styles from './styles.module.scss';

export const SuccessAuthorization = () => (
	<div className={styles.successRegister}>
		<div className={styles.successRegister__head}>
			<Typo design="headline-m">
				Вы успешно авторизированны <br /> в системе Созвездие.
			</Typo>
		</div>
	</div>
);
