import { AxiosResponse } from 'axios';
import { combineEvents, delay, reset, status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { Cruise } from 'store/auth/types/account/cruise';
import { GetPricePreview200Response, GetPricePreviewRequest, PreviewCabin } from 'config/types/prices';
import { modalFactory } from 'layout/components/modal';
import { ecommerceRequests } from 'store/cruise/ecommerce';
import { getFilledEcommerceObject } from 'utils/analytics/ecommerce';
import { DetailedCruiseCabin } from 'config/types/cabins';
import { CruiseCabin } from 'config/types/cabins/models/cruise-cabin-booking';
import { CruiseDetailsModel } from 'config/types/cabins/models/cruise-details';
import { aborting, getFailCode } from 'api';
import { GetPricesPreviewRequest, pricesRequests } from 'store/cruise/prices';
import { cruisesRequests } from 'store/cruise/cruises';
import { abortingFactory } from 'entities/aborting';
import { detailedCruiseCabinRequest } from 'store/cruise/cruises/cabins/index';
// import {
// 	cruisesRequests,
// 	detailedCruiseCabinRequest,
// 	ecommerceRequests,
// 	pricesRequests,
// } from 'shared/api';
// import { GetPricesPreviewRequest } from 'shared/api/requests/prices';
// import { CURRENCY } from 'shared/config/currency';
// import {
// 	CruiseCabin,
// 	CruiseDetailsModel,
// 	DetailedCruiseCabin,
// 	GetPricePreview200Response,
// 	GetPricePreviewRequest,
// } from 'shared/lib/types';
// import { Cruise } from 'shared/lib/types/account/cruise';
// import { PreviewCabin } from 'shared/lib/types/account/preview-cabin';
// import { aborting, getFailCode, getFilledEcommerceObject } from 'shared/lib/utils';
// import { abortingFactory, modalFactory } from 'shared/model';

export interface CartItemPlace {
	type: string | null;
}

type PlacesWithPassengersTypes<T> = {
	[Property in keyof T]: CartItemPlace[];
};

export interface CartItem
	extends Pick<DetailedCruiseCabin.Cabin, 'id' | 'name' | 'status' | 'isBonusPayment'> {
	places: PlacesWithPassengersTypes<CruiseCabin.Places>;
	cruiseId: number;
	category?: DetailedCruiseCabin.Cabin['category'];
}

interface CartCabinPrice extends Pick<DetailedCruiseCabin.Cabin, 'id' | 'price'> {}

interface TypeUpdateParams {
	id: number;
	passengers: { type: number }[];
}

const Gate = createGate();
const loadFromLs = createEvent();
const $updatedCabins = createStore<GetPricesPreviewRequest>({ cruiseId: '', body: { cabins: [] } });

const resetCart = createEvent();
const resetWithDelay = createEvent();

const $cart = createStore<CartItem[]>([]);

/* *
 * Адаптер корзины к GetPricePreviewRequest
 */
const $cartCabins = $cart.map<GetPricePreviewRequest['cabins']>(
	(cart) =>
		cart.map((cartItem) => {
			const { main, additional = [] } = cartItem.places;
			const places = [main, additional].flat().filter(({ type }) => Boolean(type));

			return {
				id: cartItem.id,
				passengers: places.map(({ type }) => ({
					type: Number(type),
				})),
			};
		}),
	// eslint-disable-next-line
);

const $activeCruise = createStore<Nullable<Cruise['id']>>(null);
const $activeCruiseInfo = createStore<Nullable<CruiseDetailsModel.CruiseInfo>>(null);

const $prices = createStore<CartCabinPrice[]>([]);
const $totalPrice = createStore<Nullable<GetPricePreview200Response>>(null);

const getCruiseInfoFx = createEffect(cruisesRequests.getInfo);

const getCartPrices = createEvent();
const getCartPricesFx = createEffect(aborting(pricesRequests.getPricesPreview));

const $errorCode = createStore<Nullable<AxiosResponse['data']>>(null);

const updatePassengerType = createEvent<TypeUpdateParams>();
const emptyCartDialog = createEvent();
const updateCabinStatuses = createEvent();

const getCartPricesWithUpdates = combineEvents({
	events: [getCartPrices, $cart.updates],
});

const { ModalGate, openModal, closeModal } = modalFactory();

// Прерывание запроса статусов кают при вызове пересчета цен
const { $abortSignal } = abortingFactory({ trigger: getCartPricesFx });

const ecommerceFx = createEffect(
	async ({ cart, total }: { cart: CartItem[]; total: Nullable<GetPricePreview200Response> }) => {
		const info = await ecommerceRequests.getCruiseInfo({ cruiseId: cart[0].cruiseId.toString() });

		// @ts-ignore
		window.dataLayer.push(
			getFilledEcommerceObject({
				ecommerce: {
					// @ts-ignore
					currencyCode: CURRENCY[info.currency ?? 1].toUpperCase(),
					remove: {
						products: [
							{
								id: cart[0].cruiseId?.toString(),
								name: info.name,
								price: total?.total,
								brand: info.brand,
								category: info.category,
								variant: info.variant,
							},
						],
					},
				},
			}),
		);
	},
);

sample({
	clock: emptyCartDialog,
	target: openModal,
});

sample({
	clock: resetCart,
	target: closeModal,
});

delay({
	source: resetWithDelay,
	timeout: 1200,
	target: resetCart,
});

const clearCart = createEvent();

sample({
	clock: resetCart,
	source: { cart: $cart, total: $totalPrice },
	target: [ecommerceFx, clearCart],
});

reset({
	clock: clearCart,
	target: [$activeCruise, $activeCruiseInfo, $prices, $totalPrice, $cart],
});

sample({
	source: $cart,
	clock: $totalPrice,
	filter: (_, price) => Boolean(price && price.cabins),
	fn: (cart, price) => {
		const cabins = price?.cabins as PreviewCabin[];
		const newCart: CartItem[] = cabins
			.map(({ id, passengers }) => {
				const oldValues = cart.find((cabin) => cabin.id === id);
				if (!oldValues || !passengers) {
					return null;
				}
				const { places } = oldValues;
				const newPlaces: PlacesWithPassengersTypes<CruiseCabin.Places> = {
					main: [],
					additional: [],
				};

				newPlaces.main = places.main.map((place, index) => ({
					type:
						index < passengers.length &&
						passengers[index].type !== null &&
						passengers[index].type !== undefined
							? `${passengers[index].type}`
							: null,
				}));

				newPlaces.additional = places.additional.map((place, index) => ({
					type:
						index + places.main.length < passengers.length &&
						passengers[index].type !== null &&
						passengers[index].type !== undefined
							? `${passengers[index + places.main.length].type}`
							: null,
				}));

				return {
					...oldValues,
					places: newPlaces,
				};
			})
			.filter((cabin) => cabin !== null) as CartItem[];

		return cart.map((item) => {
			const newCartItem = newCart.find((newItem) => newItem.id === item.id);

			return newCartItem ?? item;
		});
	},
	target: $cart,
});

// При пересчете цены так же необходимо обновить статусы кают
const updateCabinStatusesWithNewPrice = combineEvents({
	events: [$totalPrice.updates, $cart.updates],
});

// Получение актуальных статусов для каждой из кают в корзине
const updateCabinStatusesFx = createEffect(
	({ cart, signal }: { cart: CartItem[]; signal?: AbortSignal }) =>
		Promise.all(
			cart.map(({ cruiseId, places, id }) => {
				const passengersTypes = [...places.main, ...places.additional]
					.map(({ type }) => type)
					.filter((value): value is string => value !== null);

				return detailedCruiseCabinRequest.getDetailedCruiseCabinInfo({
					cabinId: id,
					cruiseId,
					passengersTypes,
					signal,
				});
			}),
		).then((cabinsInDetail: DetailedCruiseCabin.Cabin[]) =>
			cart.map((cabin, i) => ({
				...cabin,
				status: cabinsInDetail[i].status,
			})),
		),
);

sample({
	clock: updateCabinStatusesWithNewPrice,
	target: updateCabinStatuses,
});

sample({
	source: {
		cart: $cart,
		signal: $abortSignal,
	},
	fn: ({ cart, signal }) => ({
		cart,
		signal: signal || undefined,
	}),
	clock: updateCabinStatuses,
	target: updateCabinStatusesFx,
});

sample({
	clock: updateCabinStatusesFx.doneData,
	target: $cart,
});

sample({
	source: {
		cart: $cartCabins,
		cruiseId: $activeCruise,
	},
	clock: [getCartPrices, getCartPricesWithUpdates, updatePassengerType, $activeCruise],
	filter: ({ cruiseId }) => Boolean(cruiseId),
	fn: ({ cart, cruiseId }, updated) => {
		const cabins = cart.map(({ passengers, id }) => {
			const updatedPassengers =
				updated && typeof updated !== 'number' && !Array.isArray(updated) && id === updated.id
					? updated.passengers
					: passengers;

			return {
				id,
				passengers: updatedPassengers,
			};
		});

		return {
			cruiseId: `${cruiseId}`,
			body: { cabins },
		};
	},
	target: [getCartPricesFx, $updatedCabins],
});

sample({
	clock: getCartPricesFx.doneData,
	target: $totalPrice,
});

sample({
	clock: getCartPricesFx.doneData,
	fn: () => null,
	target: $errorCode,
});

sample({
	clock: getCartPricesFx.failData,
	fn: (error) => getFailCode(error),
	target: $errorCode,
});

sample({
	clock: $activeCruise,
	filter: Boolean,
	fn: (id) => ({ id: `${id}` }),
	target: getCruiseInfoFx,
});

sample({
	clock: getCruiseInfoFx.doneData,
	target: $activeCruiseInfo,
});

// Загружает корзину из sessionStorage при открытии Gate
const parseCart = () => JSON.parse(sessionStorage.getItem('cart') || '[]');
const parseCartCruise = () => JSON.parse(sessionStorage.getItem('cartCruise') || 'null');

const loadFromLSFx = createEffect(parseCart);
const loadCruiseFromLSFx = createEffect(parseCartCruise);

sample({
	clock: Gate.open,
	target: loadFromLs,
});

sample({
	clock: loadFromLs,
	target: [loadFromLSFx, loadCruiseFromLSFx],
});

sample({
	clock: loadFromLSFx.doneData,
	target: $cart,
});

sample({
	clock: loadCruiseFromLSFx.doneData,
	target: $activeCruise,
});

const $parseCartStatus = status({ effect: loadFromLSFx });

// Записывает текущую корзину в sessionStorage
const syncWithLSFx = createEffect((cart: CartItem[]) => {
	sessionStorage.setItem('cart', JSON.stringify(cart));
});
const syncCruiseWithLSFx = createEffect((activeCruise: Nullable<Cruise['id']>) => {
	sessionStorage.setItem('cartCruise', JSON.stringify(activeCruise));
});

sample({
	source: $cart,
	target: syncWithLSFx,
});

sample({
	source: $activeCruise,
	target: syncCruiseWithLSFx,
});

// Очищаем activeCruise, если корзина пуста
sample({
	clock: $cart,
	filter: (cart) => cart.length === 0,
	fn: () => null,
	target: $activeCruise,
});

export const model = {
	Gate,
	ModalGate,
	loadFromLs,
	$cart,
	$activeCruise,
	$activeCruiseInfo,
	$prices,
	$totalPrice,
	getCartPrices,
	getCartPricesWithUpdates,
	getCartPricesFx,
	updatePassengerType,
	emptyCartDialog,
	closeModal,
	updateCabinStatusesWithNewPrice,
	reset: resetCart,
	resetWithDelay,
	$updatedCabins,
	loadFromLSFx,
	loadCruiseFromLSFx,
	$cartCabins,
	pricePending: getCartPricesFx.pending,
	statusesPending: updateCabinStatusesFx.pending,
	$parseCartStatus,
	$errorCode,
};
