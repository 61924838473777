import classnames from 'classnames';
import { useGate } from 'effector-react';
import { forwardRef, PropsWithChildren, ReactElement } from 'react';

import { API_BASE_URL, API_GOOGLE_RECAPCHA_PUBLIC_KEY } from 'config/commonConstants';
import { CabinDeleteConfirm, CabinDeleteSuccess, CartEmptyAlert, cartModel, EmptyCartSuccess, useCartLocalStorageSync } from 'entities/cart';
import { CartWarning } from 'entities/cart-warning';
import { useUtmToLocalStorage } from 'layout/components/auth/load-profilator-data';
import { useLoadProfileFromLocalStorage } from 'layout/components/auth/load-profile';
import { SvgCommonSprite } from 'layout/components/icons/svg-sprite';
import { ViewportHelper } from 'layout/viewport';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import style from './style.module.scss';

interface MainProps extends PropsWithChildren {
	intro?: ReactElement;
	title?: string;
	withBackground?: boolean;
	fixedHeader?: boolean;
	footerBottomPadding?: boolean;
}

export const Main = forwardRef<HTMLElement, MainProps>(
	(
		{ children, intro, title, withBackground = false, fixedHeader = false, footerBottomPadding },
		ref,
	) => {
		useLoadProfileFromLocalStorage();
		useUtmToLocalStorage();
		useGate(cartModel.Gate);
		useCartLocalStorageSync();

		// const { executeRecaptcha } = useGoogleReCaptcha();
		// console.log('Main', executeRecaptcha ? 'yes recapcha' : 'no recapcha', API_GOOGLE_RECAPCHA_PUBLIC_KEY, API_BASE_URL)

		return (
			<>
				<SvgCommonSprite />
				<ViewportHelper />
				{intro && <aside className={style.intro}>{intro}</aside>}
				<main
					ref={ref}
					className={classnames(style.main, { [style.withBackground]: withBackground }, 'main')}
				>
					{children}
				</main>
				<CartWarning />
				<CartEmptyAlert />
				<CabinDeleteConfirm />
				<CabinDeleteSuccess />
				<EmptyCartSuccess />
			</>
		);
	},
);
