/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef } from 'react';
import { createCalendar } from '@internationalized/date';
import { DateValue } from '@react-types/datepicker';
import { AriaCalendarProps, useButton, useCalendar, useLocale } from 'react-aria';
import { useCalendarState } from 'react-stately';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { CalendarGrid } from './calendar-grid';
import { MonthDropdown } from './month-dropdown';
import { YearDropdown, YearDropdownProps } from './year-dropdown';
import style from './style.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';

export interface CalendarProps extends AriaCalendarProps<DateValue> {
	dateSelector?: Omit<YearDropdownProps, 'state'>;
}

export const Calendar = ({ dateSelector, ...props }: CalendarProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const prevRef = useRef<Nullable<HTMLButtonElement>>(null);
	const nextRef = useRef<Nullable<HTMLButtonElement>>(null);
	const { locale } = useLocale();
	const state = useCalendarState({
		...props,
		/**
		 * minValue: undefined исправляет баг https://infoflot.jetbrains.space/p/infoflot/issues/2520
		 */
		minValue: undefined,
		locale,
		createCalendar,
	});

	const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);
	const { buttonProps: prevProps } = useButton(prevButtonProps, prevRef);
	const { buttonProps: nextProps } = useButton(nextButtonProps, nextRef);

	const portal = ref.current ?? undefined;

	return (
		<div ref={ref} className={style.calendar} {...calendarProps}>
			<div className={style.header}>
				<button {...prevProps} className={style.arrow}>
					<Icon id="chevron-left" width={20} height={20} />
				</button>
				{!dateSelector && (
					<Typo as="h2" design="headline-xs" className={style.caption}>
						{title}
					</Typo>
				)}
				{dateSelector && (
					<>
						<MonthDropdown state={state} portal={portal} />
						<YearDropdown {...dateSelector} state={state} portal={portal} />
					</>
				)}
				<button {...nextProps} className={style.arrow}>
					<Icon id="chevron-right" width={20} height={20} />
				</button>
			</div>
			<CalendarGrid state={state} />
		</div>
	);
};
