export namespace Sale {
	export enum Color {
		Red = 'red',
		Blue = 'blue',
		Transparent = 'transparent',
	}

	export enum Design {
		Primary = 'primary',
		Transparent = 'transparent',
	}
}

export const isSaleColor = (color?: Nullable<string>): color is Sale.Color => {
	if (!color) {
		return false;
	}

	return Object.values(Sale.Color).includes(color as Sale.Color);
};
