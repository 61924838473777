import { cloneDeep } from 'lodash';

export const getFilledEcommerceObject = (obj: any) => {
	const result = cloneDeep(obj);

	const fill = (newObj: any) => {
		for (const key in newObj) {
			if (typeof newObj[key] === 'object') {
				fill(newObj[key]);
			} else if (newObj[key] === '-' || !newObj[key]) {
				newObj[key] = 'Не заполнено в букинге';
			}
		}
	};

	fill(result);

	return result;
};
