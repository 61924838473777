import React, { FC, memo } from 'react';
import { useUnit } from 'effector-react';
// import { getPassportName, profileModel } from 'entities/profile';
// import { Condition, Skeleton, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { profileModel, getPassportName } from 'layout/components/auth/profile';
import { Skeleton } from 'layout/components/special/skeleton';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';

export interface CustomerProps {}

export const Customer: FC<CustomerProps> = memo(() => {
	const [profile, status] = useUnit([profileModel.$profile, profileModel.$profileStatus]);
	const pending = status === 'pending';

	if (!pending && !profile) {
		return null;
	}

	const { lastName, firstName, middleName } = getPassportName(profile?.passportData);

	return (
		<div className={styles.customer}>
			<Typo className={styles.label} design="text-s" freezeDesktop>
				Заказчик
			</Typo>

			<Typo className={styles.name} design="headline-s" freezeDesktop>
				<Condition
					value={pending}
					then={
						<>
							<Skeleton width={172} height={16} margin="2px 0" />
							<Skeleton width={142} height={16} margin="2px 0" />
						</>
					}
					else={
						<>
							{lastName}
							<br />
							{firstName} {middleName}
						</>
					}
				/>
			</Typo>

			<Typo className={styles.contacts} design="text-m" freezeDesktop>
				<Condition
					value={pending}
					then={
						<>
							<Skeleton width={109} height={16} margin="4px 0" />
							<Skeleton width={97} height={16} margin="4px 0" />
						</>
					}
					else={
						<>
							{profile?.phone && <span className={styles.phone}>{profile.phone}</span>}
							{profile?.email && <span className={styles.email}>{profile.email}</span>}
						</>
					}
				/>
			</Typo>
		</div>
	);
});
