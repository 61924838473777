import React, { FC, memo } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';

export interface LoadSpinnerProps extends DefaultStyledProps {
	design?: 'default' | 'inherit';
	size?: number;
}

export const LoadSpinner: FC<LoadSpinnerProps> = memo(
	({ className, design = 'default', size = 16 }) => {
		const circumference = 2 * Math.PI * (size / 2);

		return (
			<span
				className={classnames(className, styles.loadSpinner, styles[design])}
				style={{ width: size, height: size }}
			>
				<svg viewBox={`0 0 ${size} ${size}`}>
					<circle
						cx={size / 2}
						cy={size / 2}
						r={size / 2 - 1}
						strokeDashoffset={circumference}
						strokeDasharray={circumference}
					/>
				</svg>
			</span>
		);
	},
);
