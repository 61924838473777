import React, { memo } from 'react';
import { useGate } from 'effector-react';
// import { OrderLayout } from 'widgets/layouts/order';
import { guestDataModel } from '../model';
import { OrderCustomerControls } from './order-customer-controls';
import { OrderCustomerForm } from './order-customer-form';
import { OrderLayout } from 'layout/layouts/order';

export const OrderCustomer = memo(() => {
	useGate(guestDataModel.Gate);

	return (
		<OrderLayout controls={<OrderCustomerControls />}>
			<OrderCustomerForm />
		</OrderLayout>
	);
});
