import React, { PropsWithChildren, ReactNode, useState } from 'react';
import classnames from 'classnames';
import { Button } from 'layout/components/buttons/button';
import { Portal } from 'layout/components/portal';
import { print } from 'utils/print';
// import { print } from 'shared/lib/utils';
// import { Button, Portal } from 'shared/ui/atoms';

interface PrintProps extends PropsWithChildren {
	trigger: ReactNode;
	whiteBody?: boolean;
	modalClose?: () => void;
	handleClose?: () => void;
}

export const Print = ({
	children,
	trigger,
	whiteBody = true,
	modalClose,
	handleClose,
}: PrintProps) => {
	const [mountPrint, setMountPrint] = useState(false);
	const handlePrint = () => {
		const { body } = document;
		const printing = async () => {
			modalClose?.();

			if (modalClose) {
				await setTimeout(() => {}, 4000);
			}

			await print({
				beforeAction: () => {
					setMountPrint(true);
					body.classList.add('print-only-portal');

					if (whiteBody) {
						body.classList.add('print-only-portal_white');
					}
				},
			});
		};

		printing()
			.then(() => {
				setMountPrint(false);
				body.classList.remove('print-only-portal');
				body.classList.remove('print-only-portal_white');
			})
			.finally(() => {
				handleClose?.();
			});
	};

	return (
		<>
			<Button size="large" btnType="text" onClick={handlePrint}>
				{trigger}
			</Button>
			{mountPrint && (
				<Portal>
					<div className={classnames('print-page')}>{children}</div>
				</Portal>
			)}
		</>
	);
};
