// import { FilterFieldProps } from 'shared/lib/types';

import { FilterFieldProps } from "config/types/search";

export const searchFilters: Array<FilterFieldProps> = [
	{
		order: 0,
		keys: 'freeOnly',
		type: 'toggle',
		label: 'Только со свободными местами',
	},
	{
		order: 1,
		keys: 'visaFree',
		type: 'toggle',
		label: 'Только круизы без визы',
	},
	{
		order: 2,
		keys: 'oneWay',
		type: 'toggle',
		label: 'Круизы только в одну сторону',
	},
	{
		order: 3,
		type: 'checkbox-group',
		caption: null,
		items: [
			{
				name: 'weekend',
				label: 'Круиз выходного дня',
			},
			{
				name: 'instantConfirmation',
				label: 'Мгновенное подтверждение',
			},
		],
	},
	{
		order: 4,
		keys: ['priceGte', 'priceLte'],
		type: 'range',
		label: 'Цена за одного человека без учёта скидок',
		min: {
			value: 659,
		},
		max: {
			value: 122_890,
		},
	},
	{
		order: 5,
		type: 'checkbox-group',
		caption: null,
		items: [
			{
				name: 'bonusPayments',
				label: 'Оплата баллами Клуба любителей круиза',
			},
			{
				name: 'cruiseTour',
				label: 'Круизный тур',
			},
		],
	},
	{
		order: 6,
		keys: 'shipStarsIn',
		type: 'checkbox-group',
		caption: 'Класс теплохода',
		items: [
			{
				id: 1,
				label: 'Эконом',
			},
			{
				id: 2,
				label: 'Стандарт',
			},
			{
				id: 3,
				label: 'Комфорт',
			},
			{
				id: 4,
				label: 'Комфорт +',
			},
			{
				id: 5,
				label: 'Премиум',
			},
			{
				id: 6,
				label: 'Люкс',
			},
		],
	},
	{
		order: 7,
		keys: 'companyIn',
		type: 'checkbox-group',
		caption: 'Круизная компания',
		items: [
			{
				id: 1,
				label: '«Созвездие»',
			},
			{
				id: 2,
				label: 'Astoria',
			},
			{
				id: 3,
				label: 'MSC',
			},
		],
	},
	{
		order: 8,
		keys: 'servicesIn',
		type: 'checkbox-group',
		caption: 'Услуги на борту',
		items: [
			{
				id: 11,
				label: 'Пункт проката',
			},
			{
				id: 14,
				label: 'Музыкальные выступления ',
			},
			{
				id: 15,
				label: 'Wi-Fi ',
			},
			{
				id: 16,
				label: 'Бесшумные мультиформатные дискотеки в наушниках',
			},
			{
				id: 18,
				label: 'Утренняя зарядка',
			},
			{
				id: 19,
				label: 'Вечерняя дискотека ',
			},
			{
				id: 54,
				label: 'Пакет напитков',
			},
			{
				id: 56,
				label: 'Рестораны «Джаз» и «Блюз»',
			},
			{
				id: 75,
				label: 'Титан с горячей водой',
			},
			{
				id: 9,
				label: 'Мини-гольф на солнечной палубе',
			},
			{
				id: 31,
				label: 'Концертный зал',
			},
			{
				id: 77,
				label: 'Шкаф',
			},
			{
				id: 85,
				label: 'Фен',
			},
		],
	},
	{
		order: 9,
		keys: 'regionIn',
		type: 'checkbox-group',
		caption: 'Регионы',
		quickSearch: true,
		groupped: true,
		items: [
			{
				id: 1,
				label: 'Россия',
				group: 'Россия',
			},
			{
				id: 6,
				label: 'Беларусь',
				group: 'Беларусь',
			},
			{
				id: 10,
				label: 'Америка',
				group: 'Америка',
			},
		],
	},
	{
		order: 10,
		keys: 'suggestionIn',
		type: 'checkbox-group',
		caption: 'Акционные предложения',
		items: [
			{
				id: 2,
				label: 'Дети бесплатно',
			},
			{
				id: 1,
				label: 'Скидка пенсионерам',
			},
			{
				id: 8,
				label: 'Сезонная скидка',
			},
			{
				id: 6,
				label: 'Скидка группам ',
			},
			{
				id: 3,
				label: 'Скидка молодоженам',
			},
		],
	},
	{
		order: 11,
		keys: 'startPortIn',
		type: 'checkbox-group',
		caption: 'Порт отправления',
		quickSearch: true,
		groupped: true,
		items: [
			{
				id: 1,
				label: 'Северный речной вокзал (СРВ)',
				group: 'Россия',
			},
			{
				id: 1690,
				label: 'Речной порт',
				group: 'Беларусь',
			},
			{
				id: 1671,
				label: 'Причал «пр. Обуховской обороны» (бывш. Речной вокзал)',
				group: 'Россия',
			},
			{
				id: 1674,
				label: 'Речной вокзал',
				group: 'Россия',
			},
		],
	},
	{
		order: 12,
		keys: 'endPortIn',
		type: 'checkbox-group',
		caption: 'Порт прибытия',
		quickSearch: true,
		groupped: true,
		items: [
			{
				id: 1,
				label: 'Северный речной вокзал (СРВ)',
				group: 'Россия',
			},
			{
				id: 1689,
				label: 'Речной порт',
				group: 'Беларусь',
			},
			{
				id: 1671,
				label: 'Причал «пр. Обуховской обороны» (бывш. Речной вокзал)',
				group: 'Россия',
			},
			{
				id: 1674,
				label: 'Речной вокзал',
				group: 'Россия',
			},
			{
				id: 8,
				label: 'Речной вокзал',
				group: 'Россия',
			},
		],
	},
	{
		order: 13,
		type: 'checkbox-group',
		caption: 'Группа',
		items: [
			{
				name: 'russianService',
				label: 'Русскоязычный сервис на борту',
			},
			{
				name: 'russianGroup',
				label: 'Есть русская группа',
			},
			{
				name: 'infoflotGroup',
				label: 'Есть русская группа от Инфофлота',
			},
		],
	},
	{
		order: 14,
		keys: 'timetableCitiesIn',
		type: 'checkbox-group',
		caption: 'Пункты на маршруте',
		quickSearch: true,
		groupped: true,
		items: [
			{
				id: 154,
				label: 'Тверь',
				group: 'Россия',
			},
			{
				id: 95,
				label: 'Москва',
				group: 'Россия',
			},
			{
				id: 1596,
				label: 'Брест',
				group: 'Беларусь',
			},
			{
				id: 1604,
				label: 'Кобрин',
				group: 'Беларусь',
			},
			{
				id: 1597,
				label: 'Ляховичи',
				group: 'Беларусь',
			},
			{
				id: 1598,
				label: 'Дубое',
				group: 'Беларусь',
			},
			{
				id: 568,
				label: 'Пинск',
				group: 'Беларусь',
			},
			{
				id: 1601,
				label: 'Стахово',
				group: 'Беларусь',
			},
			{
				id: 1602,
				label: 'Туров',
				group: 'Беларусь',
			},
			{
				id: 569,
				label: 'Дорошевичи',
				group: 'Беларусь',
			},
			{
				id: 1605,
				label: 'Новоселки',
				group: 'Беларусь',
			},
			{
				id: 1759,
				label: 'Наровля',
				group: 'Беларусь',
			},
			{
				id: 570,
				label: 'Мозырь',
				group: 'Беларусь',
			},
			{
				id: 128,
				label: 'Санкт-Петербург',
				group: 'Россия',
			},
			{
				id: 970,
				label: 'Буэнос-Айрес',
				group: 'Аргентина',
			},
			{
				id: 951,
				label: 'Рио-де-Жанейро',
				group: 'Бразилия',
			},
			{
				id: 959,
				label: 'Бузиос',
				group: 'Бразилия',
			},
			{
				id: 960,
				label: 'Ильябела',
				group: 'Бразилия',
			},
			{
				id: 968,
				label: 'Монтевидео',
				group: 'Уругвай',
			},
			{
				id: 37,
				label: 'Городец',
				group: 'Россия',
			},
			{
				id: 76,
				label: 'Кострома',
				group: 'Россия',
			},
			{
				id: 1938,
				label: 'Ярославль',
				group: 'Россия',
			},
			{
				id: 103,
				label: 'Нижний Новгород',
				group: 'Россия',
			},
			{
				id: 164,
				label: 'Углич',
				group: 'Россия',
			},
			{
				id: 124,
				label: 'Рыбинск',
				group: 'Россия',
			},
			{
				id: 58,
				label: 'Калязин',
				group: 'Россия',
			},
			{
				id: 162,
				label: 'Тутаев',
				group: 'Россия',
			},
			{
				id: 66,
				label: 'Кинешма',
				group: 'Россия',
			},
			{
				id: 81,
				label: 'Кузино',
				group: 'Россия',
			},
			{
				id: 36,
				label: 'Горицы',
				group: 'Россия',
			},
			{
				id: 64,
				label: 'Кижи',
				group: 'Россия',
			},
			{
				id: 91,
				label: 'Мандроги',
				group: 'Россия',
			},
			{
				id: 20,
				label: 'Валаам',
				group: 'Россия',
			},
			{
				id: 1933,
				label: 'Нижний Новгород',
				group: 'Россия',
			},
		],
	},
	{
		order: 15,
		keys: 'riversIn',
		type: 'checkbox-group',
		caption: 'Реки / озёра',
		quickSearch: true,
		items: [
			{
				id: 20,
				label: 'Волга',
			},
			{
				id: 21,
				label: 'Лена',
			},
			{
				id: 22,
				label: 'Амур',
			},
			{
				id: 23,
				label: 'Енисей',
			},
		],
	},
];

export const getSearchFiltersMock = () => searchFilters;
