import { ControllerHof, Form } from 'effector-react-form';
import { getValidation, ValidationOptions } from './validation';

export const getPropsFromController = ({
	form,
	name,
	validation,
	controller,
}: {
	form: Form;
	name: string;
	validation?: ValidationOptions;
	controller: ControllerHof;
}) => {
	const { input, error, isShowError } = controller({
		name: form.getName(name),
		validate: validation ? getValidation(validation) : undefined,
	})();

	return {
		...input,
		error: error && isShowError ? <div>{error}</div> : undefined,
	};
};
