// import { Skeleton } from 'shared/ui/atoms';
import { Skeleton } from 'layout/components/special/skeleton';
import styles from './styles.module.scss';

export const CartCabinSkeleton = () => (
	<div className={styles.skeleton}>
		<Skeleton height={48} />
		<Skeleton height={22} width="50%" />
		<div className={styles.passengers}>
			<Skeleton height={56} />
			<Skeleton height={56} />
		</div>
		<Skeleton height={24} width="40%" />
	</div>
);
