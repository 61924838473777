import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { routerModel } from 'utils/router';
import { RouterProps, useRouteContext, useRouter, useRouterState } from '@tanstack/react-router';


export const RouterComponent = () => {
	const router = useRouter()

	// const routerContext = useRouteContext({from: router.state.location.pathname as any})

	const [setRouter, routeChangeComplete] = useUnit([
		routerModel.setRouter,
		routerModel.routeChangeComplete,
	]);

	useEffect(() => {
		setRouter({
			router: router
		} as RouterProps);

		// TODO: привязать сюда события роутера. пока неизвестно зачем, но, возможно, где-то в инфофлоте они используются
		// router.events.on('routeChangeComplete', routeChangeComplete);

		return () => {
			// router.events.off('routeChangeComplete', routeChangeComplete);
		};
	}, [routeChangeComplete, router, setRouter]);

	return null;
};
