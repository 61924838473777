// import { fetcher } from 'shared/api/config';
// import { ACCOUNT_URL } from 'shared/config';

import { fetcher } from "api/setupAxios";
import { ACCOUNT_URL } from "store/auth/constants";

interface Response {
	count: number;
}

const getRequestsCount = () =>
	fetcher<Response>({
		url: `${ACCOUNT_URL}/requests/count-all`,
		method: 'GET',
		authorization: true,
	});

const getNotificationsCount = () =>
	fetcher<Response>({
		url: '/api/content/v1/notifications-count',
		method: 'GET',
	});

export const accountNavigationRequests = {
	getRequestsCount,
	getNotificationsCount,
};
