
import { Form } from 'layout/components/inputs/form/form';
import { model } from '../../model';
import styles from './styles.module.scss';
import { Input } from 'layout/components/inputs/input';
import { useUnit } from 'effector-react';
import { useError, useForm } from 'effector-react-form';
import { FormField } from 'layout/components/inputs/form/field';

export const RecoverPasswordFrom = () => {
	const { form, $pending } = model;
	const pending = useUnit($pending);
	const { controller, handleSubmit } = useForm({ form });
	const { error, isShowError } = useError({
		form,
		name: 'formError',
	});

	return (
		<Form
			title="Восстановление пароля"
			text="Введите е-mail или номер телефона, указанный при регистрации"
			handleSubmit={(event) => {
				handleSubmit(event);
				// setAnalytics(ANALYTICS_TARGETS.signin.signin_pass_request);
			}}
			buttonContent="Отправить"
			pending={pending}
			formClassName={styles.form}
			error={error}
			isShowError={isShowError}
		>
			<FormField
				validation={{ required: true }}
				required
				controller={controller}
				form={form}
				name="login"
			>
				<Input
					size="lg"
					placeholder="Логин"
					secondPlaceholder="Е-майл или номер телефона"
					withLabel
				/>
			</FormField>
		</Form>
	);
};
