import axios from 'axios';

import { LoginRequest, LoginResponse, LoginSmsRequest } from '../types/login';
import { RegisterResponse } from '../types/register';
import { ACCOUNT_URL } from '../constants';
import { API_BASE_URL } from 'config/commonConstants';



export const login = (data: LoginRequest) =>
	axios
		.post<LoginResponse>(`${ACCOUNT_URL}/login/`, data, { baseURL: API_BASE_URL })
		.then((res) => res.data);

export const loginBySms = (data: LoginSmsRequest) => {
	// console.log("api: loginBySms")
	return axios
		.post<RegisterResponse>(`${ACCOUNT_URL}/login-by-sms/`, data, { baseURL: API_BASE_URL })
		.then((res) => res.data);
}