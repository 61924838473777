import React, { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';
import { TabButton } from './tab-button';
import { TabsNav } from './tabs-nav';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';

export type TabsTheme = 'default' | 'dashed' | 'primary' | 'grey';

export interface TabsProps extends PropsWithChildren {
	navClassName?: string;
	tabButtonClassName?: string;
	className?: string;
	theme?: TabsTheme;
	commonTabsContent?: ReactNode;
	contentClassName?: string;
	tabsNavText?: string;
	handleChangeTab?: (number: number) => void;
	hideActiveTab?: boolean;
	defaultActiveIndex?: number;
}

export const Tabs: FC<TabsProps> = ({
	className,
	navClassName,
	tabButtonClassName,
	contentClassName,
	children,
	theme = 'dashed',
	tabsNavText,
	commonTabsContent,
	handleChangeTab,
	hideActiveTab,
	defaultActiveIndex = 0,
}) => {
	const [active, setActive] = useState(defaultActiveIndex);

	useEffect(() => {
		setActive(defaultActiveIndex);
	}, [defaultActiveIndex]);

	return (
		<div className={classnames(className, styles.tabs)}>
			<TabsNav className={navClassName} theme={theme} active={active}>
				{tabsNavText && (
					<Typo as="div" design="headline-s" className={styles.tabs__nav_text}>
						{tabsNavText}
					</Typo>
				)}
				{React.Children.map(children, (child, index) => {
					if (!React.isValidElement(child)) {
						return null;
					}

					if (hideActiveTab && active === index) {
						return null;
					}

					return (
						<TabButton
							id={child.props.id}
							isActive={active === index}
							onClick={() => {
								setActive(index);

								if (handleChangeTab) {
									handleChangeTab(index);
								}

								// if (child.props.analyticsTarget) {
								// 	setAnalytics(child.props.analyticsTarget);
								// }
							}}
							href={child.props.href}
							size={child.props.size}
							theme={theme}
							className={tabButtonClassName}
						>
							{child.props.label}
						</TabButton>
					);
				})}
			</TabsNav>

			{commonTabsContent}

			<div className={classnames(theme && styles[`tabs__content_${theme}`], contentClassName)}>
				{React.Children.toArray(children)[active]}
			</div>
		</div>
	);
};
