import { status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { CartItem, cabinDeleteModel, cartModel } from 'entities/cart';
import { getFailCode } from 'api';
import { ANALYTICS_TARGETS } from 'config/constants';
import { USER_METRICS_COOKIE_NAME } from 'config/constants/ecommerce';
import { message } from 'config/constants/message';
import { CreateRequest201Response, GetPricePreview200Response } from 'config/types/prices';
import { ecommerceRequests } from 'store/cruise/ecommerce';
import { getFilledEcommerceObject } from 'utils/analytics/ecommerce';
import { requestRequests } from 'store/order';
import { getCookie } from 'utils/cookie';
// import { ecommerceRequests } from 'shared/api';
// import { requestRequests } from 'shared/api/requests/order';
// import { USER_METRICS_COOKIE_NAME } from 'shared/config';
// import { CURRENCY } from 'shared/config/currency';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { message } from 'shared/lib/message';
// import { CreateRequest201Response, GetPricePreview200Response } from 'shared/lib/types';
// import { getCookie, getFailCode, getFilledEcommerceObject, setAnalytics } from 'shared/lib/utils';

const request = createEvent();
const requestFx = createEffect(requestRequests.request);
const requestBitrix = createEvent();
const requestBitrixFx = createEffect(requestRequests.requestBitrix);

const $requestError = createStore<Nullable<string>>(null);
const $requestStatus = status({ effect: requestFx });
const $requestResponse = createStore<Nullable<CreateRequest201Response>>(null);

const handleAnalyticsBookingSuccessFx = createEffect(() => { }
	// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_booking_success),
);

const addBasketMetricsFx = createEffect(ecommerceRequests.addMetricsEvent);

const addBasketMetrics = createEvent();

sample({
	clock: addBasketMetrics,
	fn: () => ({
		userId: getCookie(USER_METRICS_COOKIE_NAME) ?? '',
		eventType: 'request_add',
	}),
	target: addBasketMetricsFx,
});

const ecommerceFx = createEffect(
	async ({
		cart,
		total,
		requestResponse,
	}: {
		cart: CartItem[];
		total: Nullable<GetPricePreview200Response>;
		requestResponse: CreateRequest201Response;
	}) => {
		const info = await ecommerceRequests.getCruiseInfo({ cruiseId: cart[0].cruiseId.toString() });

		// @ts-ignore
		window.dataLayer.push(
			getFilledEcommerceObject({
				ecommerce: {
					// @ts-ignore
					currencyCode: CURRENCY[info.currency ?? 1].toUpperCase(),
					purchase: {
						actionField: {
							id: requestResponse.number,
						},
						products: [
							{
								id: cart[0].cruiseId?.toString(),
								name: info.name,
								price: total?.total,
								brand: info.brand,
								category: info.category,
								variant: info.variant,
							},
						],
					},
				},
			}),
		);
	},
);

sample({
	clock: requestFx.fail,
	fn: () => null,
	target: $requestResponse,
});

sample({
	clock: requestFx.failData,
	fn: (error) => {
		const code = getFailCode(error) || 'error';

		return message.requests.createRequest[code];
	},
	target: $requestError,
});

sample({
	clock: requestFx.done,
	fn: () => null,
	target: $requestError,
});

sample({
	clock: requestFx.doneData,
	target: $requestResponse,
});

sample({
	clock: $requestResponse,
	source: {
		cart: cartModel.$cart,
		total: cartModel.$totalPrice,
	},
	filter: (_, source) => !!source,
	fn: ({ cart, total }, requestResponse) => ({
		cart,
		total,
		requestResponse: requestResponse as CreateRequest201Response,
	}),
	target: ecommerceFx,
});

sample({
	clock: request,
	target: addBasketMetrics,
});

sample({
	clock: cabinDeleteModel.resetCabinId,
	target: requestBitrix,
});
// Обработка аналитики
sample({
	clock: requestFx.doneData,
	target: handleAnalyticsBookingSuccessFx,
});

export const model = {
	request,
	$requestStatus,
	$requestError,
	$requestResponse,
	requestFx,
	requestBitrix,
	requestBitrixFx,
};
