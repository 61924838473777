export const padForNum = (string: number | string, pad = '0000'): string =>
	(pad + string).slice(pad.length * -1);

export const dischargeNumber = (number: number, delimiter = ' ', trunc?: number): string => {
	const string = trunc !== undefined ? number.toFixed(trunc) : number.toString();

	return string.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${delimiter}`);
};

export const toFixedNumber = (n: number, digits: number, base: number = 10) => {
	const pow = base ** digits;

	return Math.round(n * pow) / pow;
};

export const normalize = (val: number, max: number, min: number, limit: boolean) => {
	const value = (val - min) / (max - min);

	return limit ? Math.max(0, Math.min(value, 1)) : value;
};

export const limit = (num: number) => Math.min(1, Math.max(0, num));

export const getRandomArbitrary = (min: number, max: number) => Math.random() * (max - min) + min;
