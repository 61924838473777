// import { fetcher } from 'shared/api/config';
// import { getTextPageMock } from 'shared/api/mock/text-page';
// import {
// 	CONTENT_PAGE_ENDPOINT,
// 	CONTENT_PAGE_ENABLE_MOCK,
// 	PAGES_TOURIST_CAT_ID,
// 	PAGES_ABOUT_CAT_ID,
// 	PAGES_COMPANIES_CAT_ID,
// 	FOOTER_MENU_PAGES_CAT_IDS,
// 	PAGES_CORPORATE_CAT_ID,
// } from 'shared/config';
// import { LoadPages200Response, PostModel } from 'shared/lib/types/posts';

import { fetcher } from "api/setupAxios";
import { CONTENT_PAGE_ENABLE_MOCK, CONTENT_PAGE_ENDPOINT, FOOTER_MENU_PAGES_CAT_IDS, PAGES_ABOUT_CAT_ID, PAGES_COMPANIES_CAT_ID, PAGES_CORPORATE_CAT_ID, PAGES_TOURIST_CAT_ID } from "config/constants/content";
import { LoadPages200Response, PostModel } from "config/types/posts";
import { getTextPageMock } from "mocks/text-page";

export interface GetTextPageRequest {
	slug?: string;
	_token?: string;
	v?: string;
}

export interface GetTextPageListRequest {
	categoryId?: number | string;
	perPage?: number;
	sortBy?: 'priority';
}

const getTextPageList = (params?: GetTextPageListRequest) =>
	fetcher<LoadPages200Response>({
		url: `${CONTENT_PAGE_ENDPOINT}`,
		method: 'GET',
		body: params,
	});

const getTextPage = async (
	{ slug, _token, v }: GetTextPageRequest,
	needMock = CONTENT_PAGE_ENABLE_MOCK,
) => {
	if (needMock) {
		await new Promise((resolve) => {
			setTimeout(resolve, 300);
		});

		return slug ? getTextPageMock(slug) : null;
	}

	return fetcher<PostModel>({
		url: `${CONTENT_PAGE_ENDPOINT}/${slug}`,
		method: 'GET',
		body: {
			_token,
			v,
		},
	});
};

const getAboutPageList = () => getTextPageList({ categoryId: PAGES_ABOUT_CAT_ID });
const getForTouristPageList = () => getTextPageList({ categoryId: PAGES_TOURIST_CAT_ID });
const getForCompaniesPageList = () => getTextPageList({ categoryId: PAGES_COMPANIES_CAT_ID });
const getForCorporatePageList = () => getTextPageList({ categoryId: PAGES_CORPORATE_CAT_ID });

const getFooterMenu = (params: GetTextPageListRequest = {}) =>
	getTextPageList({ ...params, categoryId: FOOTER_MENU_PAGES_CAT_IDS });

export const textPageRequests = {
	getTextPage,
	getTextPageList,
	getAboutPageList,
	getForTouristPageList,
	getForCompaniesPageList,
	getForCorporatePageList,
	getFooterMenu,
};
