/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, memo } from 'react';
import classnames from 'classnames';
// import { Button, Condition, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { Button } from '../button';

export interface MoreToggleProps {
	className?: string;
	onClick?: () => void;
	isExpanded?: boolean;
	label?: string;
	labelExpanded?: string;
	theme?: 'gray' | 'default';
	size?: 'small' | 'middle' | 'large';
}

export const MoreToggle: FC<MoreToggleProps> = memo(
	({
		className,
		onClick,
		isExpanded,
		label = 'Показать',
		labelExpanded = 'Свернуть',
		theme = 'gray',
		size = 'small',
	}) => {
		const text = isExpanded ? labelExpanded : label;

		return (
			<Button
				size={size}
				btnType="text"
				onClick={onClick}
				className={classnames(className, styles.toggle, styles[theme], styles[size], {
					[styles.isExpanded]: isExpanded,
				})}
			>
				<Condition
					value={size === 'small'}
					then={
						<Typo as="span" design="description-features">
							{text}
						</Typo>
					}
					else={<>{text}</>}
				/>
				<Icon id={size === 'small' ? 'chevron-sm-down' : 'chevron-down'} />
			</Button>
		);
	},
);
