import { ReactNode } from 'react';
import { getNumDeclension } from 'utils/string';
// import { getNumDeclension } from 'shared/lib/utils';

export const getLabelText = (
	value?: ReactNode | number,
	label?: string | [string, string, string],
) => {
	if (Array.isArray(label)) {
		if (typeof value === 'number') {
			return getNumDeclension(value, label);
		}

		return label.join(' ');
	}

	return label ?? null;
};
