import React from 'react';
import classnames from 'classnames';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { setAnalytics } from 'shared/lib/utils';
// import { Button, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { Button } from 'layout/components/buttons/button';
import { Typo } from 'layout/components/typo/ui';

interface OrderIsEmptyProps {
	onClick?: () => void;
	className?: string;
}

export const OrderIsEmpty = ({ onClick, className }: OrderIsEmptyProps) => (
	// <div>OrderIsEmpty</div>
	<div className={classnames(className, styles.emptyContent)}>
		<Typo design="headline-s">У вас нет кают в заказе</Typo>
		<Typo className={styles.emptyContent__text} design="description-m">
			Выберите каюты чтобы продолжить оформление круиза
		</Typo>
		<Button
			href="/"
			onClick={() => {
				onClick?.();
				// setAnalytics(ANALYTICS_TARGETS.header.header_cart_cabin);
			}}
			btnType="text"
		>
			Перейти к выбору кают
		</Button>
	</div>
);
