import { createEvent, sample, split } from 'effector';
import { logoutModel } from 'layout/components/auth/logout';
import { routerModel } from 'utils/router';
// import { logoutModel } from 'features/logout';
// import { routerModel } from 'shared/model';

const handleProfileMenuAction = createEvent<string>();
const handleProfileMenuActionAsA = createEvent<string>();

const goToPage = createEvent<string>();
const goToPageAsA = createEvent<string>();

sample({
	clock: goToPage,
	fn: (key) => `/account/${key}`,
	target: routerModel.push,
});

sample({
	clock: goToPageAsA,
	fn: (key) => `/account/${key}`,
	target: routerModel.pushAsA,
});

split({
	source: handleProfileMenuAction,
	match: {
		logout: (key) => key === 'logout',
		goTo: (key) => key !== 'logout',
	},
	cases: {
		logout: logoutModel.showModal,
		goTo: goToPage,
	},
});

split({
	source: handleProfileMenuActionAsA,
	match: {
		logout: (key) => key === 'logout',
		goTo: (key) => key !== 'logout',
	},
	cases: {
		logout: logoutModel.showModal,
		goTo: goToPageAsA,
	},
});

export const model = {
	handleProfileMenuAction,
	handleProfileMenuActionAsA,
};
