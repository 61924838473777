import React, { KeyboardEvent, useEffect, useRef } from 'react';
import { PositionType } from './input-range';
import { useViewport } from 'layout/viewport';
import { SEARCH_CRUISES_SHIP_TYPES, SEARCH_CRUISES_SHIP_TYPES_KEY, SEARCH_CRUISES_SHIPS, SEARCH_CRUISES_SHIPS_KEY } from 'config/constants/search';
// import { Params } from 'react-router-dom';

export const useAutoScrollOnInput = (allowAutoScroll = true) => {
	const wrapRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<Nullable<HTMLTextAreaElement>>(null);

	const { isMob } = useViewport();

	useEffect(() => {
		const textarea = wrapRef?.current;

		if (isMob && textarea) {
			const resizeObserver = new ResizeObserver(() => {
				const closestModal = textarea.closest('.reactModal');

				if (allowAutoScroll && closestModal && document.activeElement === inputRef?.current) {
					closestModal.scrollTop += 20;
				}
			});

			resizeObserver.observe(textarea);

			return () => {
				resizeObserver.unobserve(textarea);
			};
		}
	}, [isMob, allowAutoScroll]);

	return {
		wrapRef,
		inputRef,
	};
};

export const correctValue = ({
	e,
	values,
	min,
	max,
	pos,
}: {
	e: React.FocusEvent<HTMLInputElement, Element> | KeyboardEvent<HTMLInputElement>;
	values: string[];
	min: number;
	max: number;
	pos: PositionType;
}) => {
	const correctEvent = {
		...e,
		target: { ...e.target } as EventTarget & HTMLInputElement,
	};

	let value = (e.target as EventTarget & HTMLInputElement).value.replace(/\s/gm, '');
	if (value === '') {
		value = pos === 0 ? `${min}` : `${max}`;
		correctEvent.target.value = value;

		return correctEvent;
	}

	const valueNum = Number(value);
	const isValid = (pos ? -1 : 1) * (Number(values[1 - pos]) - valueNum) > 0;

	if (!isValid) {
		correctEvent.target.value = values[1 - pos];
	}

	return correctEvent;
};

// export const getCurrencyRange = (query: NextParsedUrlQuery, withEuro = false) => {
//  исправлен тип query (добавлено описание Params в файл src/types.d.ts)
export const getCurrencyRange = (query: Params<string>, withEuro = false) => {
	if (!withEuro) {
		return false;
	}

	return (
		(query[SEARCH_CRUISES_SHIP_TYPES_KEY] === SEARCH_CRUISES_SHIP_TYPES.turkey &&
			query[SEARCH_CRUISES_SHIPS_KEY] === SEARCH_CRUISES_SHIPS.turkey) ||
		(query[SEARCH_CRUISES_SHIP_TYPES_KEY] === SEARCH_CRUISES_SHIP_TYPES.egypt &&
			query[SEARCH_CRUISES_SHIPS_KEY] === SEARCH_CRUISES_SHIPS.egypt)
	);
};
