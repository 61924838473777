export const ruLang = {
	au: 'Австралия',
	at: 'Австрия',
	az: 'Азербайджан',
	ax: 'Аландские о-ва',
	al: 'Албания',
	dz: 'Алжир',
	as: 'Американское Самоа',
	ai: 'Ангилья',
	ao: 'Ангола',
	ad: 'Андорра',
	aq: 'Антарктида',
	ag: 'Антигуа и Барбуда',
	ar: 'Аргентина',
	am: 'Армения',
	aw: 'Аруба',
	af: 'Афганистан',
	bs: 'Багамы',
	bd: 'Бангладеш',
	bb: 'Барбадос',
	bh: 'Бахрейн',
	by: 'Беларусь',
	bz: 'Белиз',
	be: 'Бельгия',
	bj: 'Бенин',
	bm: 'Бермудские о-ва',
	bg: 'Болгария',
	bo: 'Боливия',
	bq: 'Бонэйр, Синт-Эстатиус и Саба',
	ba: 'Босния и Герцеговина',
	bw: 'Ботсвана',
	br: 'Бразилия',
	io: 'Британская территория в Индийском океане',
	bn: 'Бруней-Даруссалам',
	bf: 'Буркина-Фасо',
	bi: 'Бурунди',
	bt: 'Бутан',
	vu: 'Вануату',
	va: 'Ватикан',
	gb: 'Великобритания',
	hu: 'Венгрия',
	ve: 'Венесуэла',
	vg: 'Виргинские о-ва (Великобритания)',
	vi: 'Виргинские о-ва (США)',
	um: 'Внешние малые о-ва (США)',
	tl: 'Восточный Тимор',
	vn: 'Вьетнам',
	ga: 'Габон',
	ht: 'Гаити',
	gy: 'Гайана',
	gm: 'Гамбия',
	gh: 'Гана',
	gp: 'Гваделупа',
	gt: 'Гватемала',
	gn: 'Гвинея',
	gw: 'Гвинея-Бисау',
	de: 'Германия',
	gg: 'Гернси',
	gi: 'Гибралтар',
	hn: 'Гондурас',
	hk: 'Гонконг (САР)',
	gd: 'Гренада',
	gl: 'Гренландия',
	gr: 'Греция',
	ge: 'Грузия',
	gu: 'Гуам',
	dk: 'Дания',
	je: 'Джерси',
	dj: 'Джибути',
	dm: 'Доминика',
	do: 'Доминиканская Республика',
	eg: 'Египет',
	zm: 'Замбия',
	eh: 'Западная Сахара',
	zw: 'Зимбабве',
	il: 'Израиль',
	in: 'Индия',
	id: 'Индонезия',
	jo: 'Иордания',
	iq: 'Ирак',
	ir: 'Иран',
	ie: 'Ирландия',
	is: 'Исландия',
	es: 'Испания',
	it: 'Италия',
	ye: 'Йемен',
	cv: 'Кабо-Верде',
	kz: 'Казахстан',
	kh: 'Камбоджа',
	cm: 'Камерун',
	ca: 'Канада',
	qa: 'Катар',
	ke: 'Кения',
	cy: 'Кипр',
	kg: 'Киргизия',
	ki: 'Кирибати',
	cn: 'Китай',
	kp: 'КНДР',
	cc: 'Кокосовые о-ва',
	co: 'Колумбия',
	km: 'Коморы',
	cg: 'Конго - Браззавиль',
	cd: 'Конго - Киншаса',
	cr: 'Коста-Рика',
	ci: 'Кот-д’Ивуар',
	cu: 'Куба',
	kw: 'Кувейт',
	cw: 'Кюрасао',
	la: 'Лаос',
	lv: 'Латвия',
	ls: 'Лесото',
	lr: 'Либерия',
	lb: 'Ливан',
	ly: 'Ливия',
	lt: 'Литва',
	li: 'Лихтенштейн',
	lu: 'Люксембург',
	mu: 'Маврикий',
	mr: 'Мавритания',
	mg: 'Мадагаскар',
	yt: 'Майотта',
	mo: 'Макао (САР)',
	mw: 'Малави',
	my: 'Малайзия',
	ml: 'Мали',
	mv: 'Мальдивы',
	mt: 'Мальта',
	ma: 'Марокко',
	mq: 'Мартиника',
	mh: 'Маршалловы Острова',
	mx: 'Мексика',
	mz: 'Мозамбик',
	md: 'Молдова',
	mc: 'Монако',
	mn: 'Монголия',
	ms: 'Монтсеррат',
	mm: 'Мьянма (Бирма)',
	na: 'Намибия',
	nr: 'Науру',
	np: 'Непал',
	ne: 'Нигер',
	ng: 'Нигерия',
	nl: 'Нидерланды',
	ni: 'Никарагуа',
	nu: 'Ниуэ',
	nz: 'Новая Зеландия',
	nc: 'Новая Каледония',
	no: 'Норвегия',
	bv: 'о-в Буве',
	im: 'о-в Мэн',
	nf: 'о-в Норфолк',
	cx: 'о-в Рождества',
	sh: 'о-в Св. Елены',
	pn: 'о-ва Питкэрн',
	tc: 'о-ва Тёркс и Кайкос',
	hm: 'о-ва Херд и Макдональд',
	ae: 'ОАЭ',
	om: 'Оман',
	ky: 'Острова Кайман',
	ck: 'Острова Кука',
	pk: 'Пакистан',
	pw: 'Палау',
	ps: 'Палестинские территории',
	pa: 'Панама',
	pg: 'Папуа — Новая Гвинея',
	py: 'Парагвай',
	pe: 'Перу',
	pl: 'Польша',
	pt: 'Португалия',
	pr: 'Пуэрто-Рико',
	kr: 'Республика Корея',
	re: 'Реюньон',
	ru: 'Россия',
	rw: 'Руанда',
	ro: 'Румыния',
	sv: 'Сальвадор',
	ws: 'Самоа',
	sm: 'Сан-Марино',
	st: 'Сан-Томе и Принсипи',
	sa: 'Саудовская Аравия',
	mk: 'Северная Македония',
	mp: 'Северные Марианские о-ва',
	sc: 'Сейшельские Острова',
	bl: 'Сен-Бартелеми',
	mf: 'Сен-Мартен',
	pm: 'Сен-Пьер и Микелон',
	sn: 'Сенегал',
	vc: 'Сент-Винсент и Гренадины',
	kn: 'Сент-Китс и Невис',
	lc: 'Сент-Люсия',
	rs: 'Сербия',
	sg: 'Сингапур',
	sx: 'Синт-Мартен',
	sy: 'Сирия',
	sk: 'Словакия',
	si: 'Словения',
	us: 'Соединенные Штаты',
	sb: 'Соломоновы Острова',
	so: 'Сомали',
	sd: 'Судан',
	sr: 'Суринам',
	sl: 'Сьерра-Леоне',
	tj: 'Таджикистан',
	th: 'Таиланд',
	tw: 'Тайвань',
	tz: 'Танзания',
	tg: 'Того',
	tk: 'Токелау',
	to: 'Тонга',
	tt: 'Тринидад и Тобаго',
	tv: 'Тувалу',
	tn: 'Тунис',
	tm: 'Туркменистан',
	tr: 'Турция',
	ug: 'Уганда',
	uz: 'Узбекистан',
	ua: 'Украина',
	wf: 'Уоллис и Футуна',
	uy: 'Уругвай',
	fo: 'Фарерские о-ва',
	fm: 'Федеративные Штаты Микронезии',
	fj: 'Фиджи',
	ph: 'Филиппины',
	fi: 'Финляндия',
	fk: 'Фолклендские о-ва',
	fr: 'Франция',
	gf: 'Французская Гвиана',
	pf: 'Французская Полинезия',
	tf: 'Французские Южные территории',
	hr: 'Хорватия',
	cf: 'Центрально-Африканская Республика',
	td: 'Чад',
	me: 'Черногория',
	cz: 'Чехия',
	cl: 'Чили',
	ch: 'Швейцария',
	se: 'Швеция',
	sj: 'Шпицберген и Ян-Майен',
	lk: 'Шри-Ланка',
	ec: 'Эквадор',
	gq: 'Экваториальная Гвинея',
	er: 'Эритрея',
	sz: 'Эсватини',
	ee: 'Эстония',
	et: 'Эфиопия',
	gs: 'Южная Георгия и Южные Сандвичевы о-ва',
	za: 'Южно-Африканская Республика',
	ss: 'Южный Судан',
	jm: 'Ямайка',
	jp: 'Япония',
	ac: 'о-в Асенсьон',
	xk: 'Косово',
	searchPlaceholder: 'Поиск',
};

export const validationError = {
	IS_POSSIBLE: 0,
	INVALID_COUNTRY_CODE: 1,
	TOO_SHORT: 2,
	TOO_LONG: 3,
	IS_POSSIBLE_LOCAL_ONLY: 4,
	INVALID_LENGTH: 5,
};
