import React, { FC, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useListBoxSection } from 'react-aria';
import type { Node } from '@react-types/shared';
import { SelectionMode } from '@react-types/shared';
// import { Button, GroupedOption } from 'shared/ui/atoms';
// import { FakeOption } from 'shared/ui/atoms/option/fake-option';
// import { MultiSelectListState } from 'shared/ui/molecules/select/lib';
// import { Icon } from '../../icons';
import style from './style.module.scss';
import { MultiSelectListState } from 'layout/components/inputs/selects/select/lib';
import { GroupedOption } from './grouped-option';
import { FakeOption } from './fake-option';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';

export interface OptionGroupProps {
	section: Node<unknown>;
	state: MultiSelectListState<unknown>;
	selectionMode?: SelectionMode;
	classNameModalGroupOption?: string;
}

export const OptionGroup: FC<OptionGroupProps> = ({
	section,
	state,
	classNameModalGroupOption,
	selectionMode,
}) => {
	const [expanded, setExpanded] = useState(true);

	const { itemProps, groupProps } = useListBoxSection({
		heading: section.rendered,
		'aria-label': section['aria-label'],
	});

	const nodes = [...section.childNodes];
	const originalNodes = [...(state.originalCollection.getItem(section.key)?.childNodes ?? [])];
	const headNode = nodes[0];
	const childNodes = nodes.slice(1);
	const groupKeys = originalNodes.map(({ key }) => ({ key, isParent: key === headNode.key }));
	const withFakeParent = headNode.key.toString().includes('fake-parent');

	return (
		<li {...itemProps} className={style.groupWrap}>
			<div className={style.head}>
				{headNode && !withFakeParent && (
					<GroupedOption
						item={headNode}
						meta={{ isParent: true, group: groupKeys }}
						state={state}
						selectionMode={selectionMode}
						count={childNodes.length}
					/>
				)}
				{headNode && withFakeParent && (
					<FakeOption
						state={state}
						selectionMode={selectionMode}
						childKeys={childNodes.map(({ key }) => key)}
						count={childNodes.length}
					>
						{headNode.rendered}
					</FakeOption>
				)}
				<Button
					size="middle"
					btnType="text"
					onClick={() => setExpanded((prev) => !prev)}
					className={classnames(style.toggle, {
						[style.isExpanded]: expanded,
					})}
				>
					<Icon id="chevron-down" className={style.toggle__icon} />
				</Button>
			</div>
			<AnimateHeight height={expanded ? 'auto' : 0} duration={400}>
				<ul {...groupProps} className={style.group}>
					{childNodes.map((item, i) => (
						<GroupedOption
							key={item.key}
							item={item}
							classNameModalGroupOption={classNameModalGroupOption}
							meta={{ isParent: false, group: groupKeys }}
							state={state}
							selectionMode={selectionMode}
						/>
					))}
				</ul>
			</AnimateHeight>
		</li>
	);
};
