import React, { FC } from 'react';
// import { Typo } from 'shared/ui/atoms';
import { BackButton } from './back-button';
import { ProgressBar } from './progress-bar';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';

interface OrderLayoutHeadlineProps {}

export const OrderLayoutHeadline: FC<OrderLayoutHeadlineProps> = () => (
	<div className={styles.headline}>
		<BackButton />
		<Typo design="headline-xl" as="h1">
			Заявка на бронирование
		</Typo>
		<ProgressBar />
	</div>
);
