export const isVisibleInScrollArea = (
	element?: Nullable<HTMLElement>,
	scrollableParent?: Nullable<HTMLElement>,
) => {
	if (!element || !scrollableParent) {
		return null;
	}

	const { top: elementTop, bottom: elementBottom } = element?.getBoundingClientRect() ?? {};
	const { height: parentHeight } = scrollableParent?.getBoundingClientRect() ?? {};

	return elementTop >= 0 && parentHeight && elementBottom <= parentHeight;
};
