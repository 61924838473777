import { MutableRefObject, useEffect, useState } from 'react';

export const useClientRect = (ref: MutableRefObject<Nullable<HTMLElement>>) => {
	const [clientRect, setClientRect] = useState<Nullable<DOMRect>>(null);

	useEffect(() => {
		const element = ref.current;

		if (element) {
			const handleResize = () => {
				setClientRect(element.getBoundingClientRect());
			};

			const observer = new ResizeObserver(handleResize);
			observer.observe(element);

			return () => {
				observer.unobserve(element);
			};
		}
	}, [ref]);

	return clientRect;
};
