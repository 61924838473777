import { createEvent, createStore, sample } from "effector";
import { createGate } from "effector-react";
import { errorModel } from "utils/error";
import { verificationModel } from "utils/verification";
import { recoverPasswordModel } from "../recover/recover-password";
import { verificationPhoneModel } from "../verify/verify-phone";
import {model as authorizationModel} from './model'
import { loadProfileModel } from "../load-profile";


const Gate = createGate<{ emailToken?: string; state: Nullable<string>; error?: string }>();
const ErrorGate = createGate();

export const states = [
	'register',
	'verify',
	'authorization',
	'recover',
	'login-confirmation',
	'reset-password',
	'success-register',
	'success-reset-password',
	'success-authorization',
] as const;

export type State = typeof states[number];

function isValidStateName(value: Nullable<string>): value is State {
	if (!value) {
		return false;
	}

	return states.includes(value as State);
}

const $state = createStore<Nullable<State>>(null);
const $errorCode = createStore<Nullable<string>>(null);
const toState = createEvent<State>();

sample({
	source: Gate.state,
	clock: Gate.open,
	fn: ({ error }) => error || null,
	target: $errorCode,
});

sample({
	clock: ErrorGate.close,
	target: errorModel.reset,
});

sample({
	clock: $errorCode,
	filter: Boolean,
	fn: (code) => `login-fail.${code}`,
	target: errorModel.setError,
});

sample({
	clock: Gate.open,
	target: verificationModel.reset,
});

sample({
	source: Gate.state,
	filter: ({ emailToken }) => Boolean(emailToken),
	fn: () => 'reset-password' as const,
	target: $state,
});

sample({
	source: Gate.state,
	filter: ({ state }) => state !== undefined,
	fn: ({ state }) => (isValidStateName(state) ? state : ('authorization' as State)),
	target: $state,
});

sample({
	source: Gate.state,
	filter: ({ emailToken }) => Boolean(emailToken),
	fn: ({ emailToken }) => emailToken || null,
	target: recoverPasswordModel.$resetToken,
});

sample({
	clock: toState,
	target: $state,
});

sample({
	source: $state,
	filter: (state) => state !== 'login-confirmation',
	clock: verificationModel.$verifyToken,
	fn: () => 'verify' as const,
	target: toState,
});

sample({
	clock: recoverPasswordModel.$submitted,
	fn: () => 'login-confirmation' as const,
	target: toState,
});

sample({
	clock: recoverPasswordModel.$resetToken,
	fn: () => 'reset-password' as const,
	target: toState,
});

sample({
	clock: verificationPhoneModel.verifyFx.doneData,
	fn: () => 'success-register' as const,
	target: toState,
});

sample({
	clock: verificationPhoneModel.verifyLoginFx.doneData,
	fn: () => 'success-authorization' as const,
	target: toState,
});

sample({
	clock: [
		verificationPhoneModel.verifyFx.doneData,
		verificationPhoneModel.verifyLoginFx.doneData,
		authorizationModel.authoriseFx.doneData,
	],
	// @ts-ignore
	filter: (clock) => !clock.agent,
	fn: ({ accessToken, refreshToken }) => ({
		access: accessToken,
		refresh: refreshToken,
	}),
	target: loadProfileModel.loadProfile,
});

sample({
	clock: recoverPasswordModel.resetPasswordFx.doneData,
	fn: () => 'success-reset-password' as const,
	target: toState,
});

export const model = {
	Gate,
	ErrorGate,
	$state,
	toState,
};
