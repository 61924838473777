import { createFileRoute, useParams } from '@tanstack/react-router';
import { CabinDescriptionModal } from 'entities/cabin-booking-cards';
import Card from 'layout/components/cards/card/card';
import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const Route = createFileRoute('/cruises/$cruiseId')({
    component: Cruise,
})

interface IRenderCardData { element: Element | null, rootElement: NodeListOf<Element> | null, cabinId: string, routeId: string }

function DeleteOldElements(activeModal: NodeListOf<Element>) {
    const buttonOrderOriginal: NodeListOf<Element> | null = activeModal?.length ? (activeModal.item(0)?.querySelectorAll(".btn.js-info-link-one-cabins.w-100") ?? null) : null
    const buttonOrderQuestion: NodeListOf<Element> | null = activeModal?.length ? (activeModal.item(0)?.querySelectorAll(".btn.btn-out.popup-form") ?? null) : null

    if (buttonOrderOriginal?.length) {
        buttonOrderOriginal[0].remove()
    }
    // удаляем ненужный элемент 2
    if (buttonOrderQuestion?.length) {
        buttonOrderQuestion[0].remove()
    }
}

function FindCardPlacement(routeId: string, cabinId: string): IRenderCardData {
    // modal fade js-cabin-modal show

    // ищем на странице элементы встраивания
    const activeModal: NodeListOf<Element> | null = document?.querySelectorAll(".modal.fade.js-cabin-modal[aria-modal^='true']") ?? null
    const appCardWrapper: Element | null = activeModal?.length
        ? (activeModal.item(0)?.getElementsByClassName("app-card")?.length
            ? (activeModal.item(0).getElementsByClassName("app-card").item(0) ?? null)
            : null)
        : null

    return {
        element: appCardWrapper,
        rootElement: activeModal,
        cabinId: cabinId,
        routeId: routeId,
    }
}

let observer: MutationObserver | null = null
const activeModalWindowSelector = ".modal.fade.js-cabin-modal.show"

function Cruise() {
    const { cruiseId } = useParams({ from: '/cruises/$cruiseId' })
    const [cruiseCabin, setCruiseCabin] = useState<string | null>(null)
    const [cabinData, setCabinData] = useState<IRenderCardData | null>(null)
    const [modalElementWithCabin, setModalElementWithCabin] = useState<Element | null>(null)

    const crId = isNumber(cruiseId)
        ? cruiseId
        : cruiseId.split('_')[cruiseId.split('_').length - 1]


    // ищет компонент с данными на странице и когда находит, считывает данные и записывает их в cruiseCabin
    // после того как нашел, дальнейший поиск не прекращается
    const createMutationObserver = (selector: string) => {
        const observer = new MutationObserver(mutations => {

            if (document.querySelector(selector)) {
                const activeModalElement = document.querySelector(selector)
                activeModalElement && setModalElementWithCabin(activeModalElement)
                const cabinIdDiv: any = document.querySelectorAll(".js-cabin-modal[aria-modal^='true']")[0]
                const cabinId = cabinIdDiv?.dataset?.id ?? ""

                if (cruiseCabin != cabinId) {
                    setCruiseCabin(cabinId)
                }
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }


    useEffect(() => {
        // запускаем поиск динамических данных на стрнице круиза
        createMutationObserver(activeModalWindowSelector)
    }, [])


    useEffect(() => {
        if (crId?.length && cruiseCabin?.length) {
            // когда нашли компонент с данными о круизе (или он изменился), считали и записали данные оттуда, рендерим свой компонент вместо старого
            const foundedElements = FindCardPlacement(crId, cruiseCabin)
            setCabinData(foundedElements)
            foundedElements.rootElement && DeleteOldElements(foundedElements.rootElement)
        }
    }, [cruiseCabin])

    return (<>
        {cabinData?.element ? createPortal(<Card cruiseId={cabinData.routeId} cabinId={cabinData.cabinId} />, cabinData.element) : null}
        {
            crId && !Number.isNaN(Number(crId) && modalElementWithCabin)
                ? <CabinDescriptionModal cruiseId={Number(crId)} modalToHide={modalElementWithCabin} />
                : null

        }
    </>
    )
}