import React, { PropsWithChildren, ReactNode, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
// import { ValueOf } from 'next/constants';
// import { useRouter } from 'next/router';
import { useGate, useUnit } from 'effector-react';
// import { orderPageModel } from 'widgets/order';
// import { confirmationModel } from 'widgets/order/order-confirmation/model';
import { RequestCruiseFeatures } from 'entities/order/request-cruise-features';
import { cartModel, OrderIsEmpty } from 'entities/cart';
import { RequestCruiseDetails } from 'entities/order/request-cruise-details';
import { RequestSummary, AfterConfirmation } from 'entities/order/request-summary';
import { RequestTotal } from 'entities/order/request-total';
// import { OrderRoutes } from 'shared/config';
// import { useViewport } from 'shared/lib/viewport';
// import { DesktopView, MobileView } from 'shared/ui/atoms';
import { OrderLayoutHeadline } from './order-layout-headline';
import styles from './styles.module.scss';
import { useLocation, useRouter } from '@tanstack/react-router';
import { ValueOf } from 'config/commonTypes';
import { OrderRoutes } from 'config/constants/order';
import { DesktopView, MobileView } from 'layout/layouts/device-view';
import { orderPageModel } from 'layout/layouts/widgets/order';
import { confirmationModel } from 'layout/layouts/widgets/order/order-confirmation/model';
import { useViewport } from 'layout/viewport';

interface OrderLayoutProps extends PropsWithChildren {
	sidebar?: ReactNode;
	controls?: ReactNode;
	excursionsTotal?: number;
	closeSidebar?: boolean;
}

export const OrderLayout = ({
	children,
	closeSidebar,
	sidebar,
	controls,
	excursionsTotal,
}: OrderLayoutProps) => {
	const asideRef = useRef<HTMLElement>(null);
	const endBlockRef = useRef<HTMLDivElement>(null);
	const ref = useRef<HTMLDivElement>(null);
	const [isActive, setIsActive] = useState(false);
	useGate(orderPageModel.Gate);
	const confirmationFormValues = useUnit(confirmationModel.form.$values);
	const { bonusPaymentCount } = confirmationFormValues ?? {};
	const anchor = ref?.current;
	// const { asPath } = useRouter();
	const location = useLocation();
	const asPath = location.pathname
	const pathname = asPath.split('?')[0] as ValueOf<typeof OrderRoutes>;
	const isConfirmationPage = pathname === OrderRoutes.Confirmation;
	const { vh } = useViewport();
	const [cart, status] = useUnit([cartModel.$cart, cartModel.$parseCartStatus]);

	useEffect(() => {
		const handleScroll = () => {
			if (!anchor) {
				return;
			}

			const { bottom } = anchor.getBoundingClientRect();

			setIsActive(bottom - vh > 0);
		};

		handleScroll();

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [anchor, vh]);

	if (cart.length === 0 && status !== 'initial') {
		return <OrderIsEmpty className={styles.empty} />;
	}

	return (
		<div className={styles.layout}>
			<div ref={ref} className={classnames(styles.main, closeSidebar && styles.main_lg)}>
				<OrderLayoutHeadline />
				{children}
				<DesktopView>
					<div className={classnames(styles.controls, isActive && styles.controls_isActive)}>
						{controls}
					</div>
				</DesktopView>
			</div>

			{!closeSidebar ? (
				<aside ref={asideRef} className={styles.sidebar}>
					{!isConfirmationPage && <RequestCruiseDetails />}
					<RequestSummary />
					<RequestTotal asideRef={asideRef} controls={controls} excursionsTotal={excursionsTotal} />
					<AfterConfirmation
						bonusWrittenOf={
							bonusPaymentCount && bonusPaymentCount > 0 ? bonusPaymentCount : undefined
						}
					/>
					<RequestCruiseFeatures />
					{sidebar}
				</aside>
			) : (
				<MobileView>
					<div ref={endBlockRef}>
						<RequestTotal
							asideRef={endBlockRef}
							controls={controls}
							excursionsTotal={excursionsTotal}
						/>
					</div>
				</MobileView>
			)}

			<MobileView>{controls}</MobileView>
		</div>
	);
};
