import { ProfileModel } from "config/types/personal";


export const parsePassportData = (data: string) => {
	const tokens = data.split(' ').reverse();
	const passportNumber = tokens[0];
	const passportSeries = tokens.slice(1).reverse().join(' ');

	return {
		passportSeries,
		passportNumber,
	};
};

export const getUserName = (
	lastName?: Nullable<string>,
	firstName?: Nullable<string>,
	middleName?: Nullable<string>,
) => {
	const name = [];

	if (lastName) {
		name.push(lastName);
	}
	if (firstName) {
		name.push(firstName);
	}
	if (middleName) {
		name.push(middleName);
	}

	return name.join(' ');
};

export const getPassportName = (
	passportData?: Pick<ProfileModel['passportData'], 'lastName' | 'firstName' | 'middleName'>,
) => {
	let { lastName, firstName, middleName } = passportData || {};

	middleName = lastName && firstName ? middleName ?? '' : middleName ?? 'Отчество';
	lastName = lastName ?? 'Фамилия';
	firstName = firstName ?? 'Имя';

	return { lastName, firstName, middleName };
};
