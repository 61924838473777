import React, { memo } from 'react';
import { Item } from 'react-stately';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-react-form';
import { referralsModel } from 'entities/referrals';
// import { Select } from 'shared/ui/molecules';
// import { FormSelect } from 'shared/ui/organisms';
import { customerFormValidationScheme } from '../../lib';
import { model } from '../../model/guest-data';
import styles from './styles.module.scss';
import { FormSelect } from 'layout/components/inputs/form/select';
import { Select } from 'layout/components/inputs/selects/select';

export const Reffer = memo(() => {
	const { form } = model;
	const { controller } = useForm({ form });
	const [referralTypes] = useUnit([referralsModel.$referralTypes]);

	return (
		<div className={styles.passport__grid}>
			<FormSelect
				form={form}
				controller={controller}
				validation={customerFormValidationScheme.referrer}
				name="referrer"
				size="lg"
				wrap="nowrap"
				selectionMode="single"
				theme="divider"
				label="Откуда вы о нас узнали?"
				className={styles.passport__selector}
			>
				<Select className={styles.offices__select} searchPlaceholder="Поиск значения" withSearch>
					{referralTypes.map(({ id, name }) => (
						<Item key={id.toString()}>{name}</Item>
					))}
				</Select>
			</FormSelect>
		</div>
	);
});
