// import { ProfileModel } from 'shared/lib/types';

import { ProfileModel } from "config/types/personal";

interface UseCardHolderNameProps {
	passportData?: ProfileModel['passportData'];
}

export const useCardHolderName = ({ passportData }: UseCardHolderNameProps) => {
	let { lastName, firstName, middleName } = passportData || {};

	const initialLetter = lastName ? lastName.slice(0, 1) : 'К';

	middleName = lastName && firstName ? middleName ?? '' : middleName ?? 'Отчество';
	lastName = lastName ?? 'Фамилия';
	firstName = firstName ?? 'Имя';

	return {
		lastName,
		firstName,
		middleName,
		initialLetter,
	};
};
