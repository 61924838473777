import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { cartModel } from 'entities/cart/index';

export const useCartLocalStorageSync = () => {
	const loadFromLs = useUnit(cartModel.loadFromLs);

	useEffect(() => {
		window.addEventListener('storage', loadFromLs);

		return () => {
			window.removeEventListener('storage', loadFromLs);
		};
	}, [loadFromLs]);
};
