export * from './axios';
export * from './aborting';
export * from './jwt';
export * from './refresh-token';

/*
 * Отсечение слэшей
 */
export const withoutTrailingSlash = (url: string) => url.replace(/\/$/, '');
export const withoutLeadingSlash = (url: string) => url.replace(/^\//, '');

export const trimSlash = (url: string) => withoutLeadingSlash(withoutTrailingSlash(url));
