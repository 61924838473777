// import { fetcher } from 'shared/api/config';
// import { ACCOUNT_URL } from 'shared/config';
// import { OfficeModel } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { OfficeModel } from "config/types/offices";
import { ACCOUNT_URL } from "store/auth/constants";

export interface GetOfficesParams {
	onlyVisitable?: number;
	includeNonBooking?: number;
}

export const getOffices = () =>
	fetcher<OfficeModel[]>({
		url: `${ACCOUNT_URL}/offices`,
		method: 'GET',
	});

export const getVisitOffices = (params: GetOfficesParams) =>
	fetcher<OfficeModel[]>({
		url: `${ACCOUNT_URL}/offices`,
		method: 'GET',
		body: { ...params },
	});

export const officesRequests = {
	getOffices,
	getVisitOffices,
};
