/* eslint-disable react/jsx-no-useless-fragment */
import React, { ReactNode } from 'react';
import { InputInterface } from '../input/input';
import { getPropsFromController } from './lib/form-controller';

interface FormFieldProps extends InputInterface {
	controller: any;
	form: any;
	validation?: any;
	children: ReactNode;
	name: string;
	wrap?: 'nowrap';
	valueParser?: (value: any) => any;
	onChangeValueParser?: (value: any) => any;
	onBlurValueParser?: (value: any) => any;
	valueAlias?: string;
	onChangeAlias?: string;
}

export const FormField = ({
	controller,
	form,
	name,
	validation,
	wrap,
	children,
	valueParser,
	onChangeValueParser,
	valueAlias = 'value',
	onChangeAlias = 'onChange',
	...props
}: FormFieldProps) => {
	const input = getPropsFromController({
		form,
		controller,
		name,
		validation,
	});
	// @ts-ignore
	input[valueAlias] = valueParser ? valueParser(input.value) : input.value || '';

	let { onChange, onBlur } = input;

	if (props.onBlurValueParser) {
		const oldOnBlur = onBlur;
		onBlur = (e) => {
			onChange(e.target.value);
			oldOnBlur(e);
		};
	}

	if (onChangeValueParser) {
		const oldOnChange = onChange;
		onChange = (value) => {
			oldOnChange(onChangeValueParser(value));
		};
	}

	// @ts-ignore
	input[onChangeAlias] = onChange;
	input.onBlur = onBlur;

	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				...props,
				...input,
			});
		}

		return child;
	});

	switch (wrap) {
		case 'nowrap':
			return <>{childrenWithProps}</>;
		default:
			return <div>{childrenWithProps}</div>;
	}
};
