export const SEARCH_CRUISES_ENDPOINT = '/api/search/v1/cruises';
export const SEARCH_CRUISES_LIMIT = 10;
export const SEARCH_CRUISES_ENABLE_MOCK = false;

export const SEARCH_CRUISES_FREE_ONLY_KEY = 'freeOnly';
export const SEARCH_CRUISES_FREE_ONLY_DEFAULT = '1';
export const SEARCH_FUTURE_CRUISES_ONLY_KEY = 'futureCruises';
export const SEARCH_FUTURE_CRUISES_ONLY_DEFAULT = '1';
export const SEARCH_CRUISES_SHIP_TYPES_KEY = 'shipTypesIn';
export const SEARCH_CRUISES_SHIPS_KEY = 'shipIn';
export const SEARCH_CRUISES_ROUTE_KEY = 'routeIn';
export const SEARCH_DEFAULT_ACTIVE_KEYS = [
	SEARCH_CRUISES_SHIP_TYPES_KEY,
	SEARCH_CRUISES_FREE_ONLY_KEY,
	SEARCH_FUTURE_CRUISES_ONLY_KEY,
];

export enum SearchCruiseParams {
	Turkey = 'turkey',
	Sochi = 'sochi',
	Egypt = 'egypt',
	China = 'china',
	Baikal = 'baikal',
	Enisey = 'enisey',
	East = 'east',
	Lux = 'lux',
	Irtish = 'irtish',
	SolovIslads = 'solov',
	Lena = 'lena',
	Default = 'default',
}

export interface SearchSelectCruisesParams {
	id: SearchCruiseParams;
	name: string;
	isRoute?: boolean;
	isShips?: boolean;
}

export const SEARCH_SELECT_CRUISES = [
	{
		id: SearchCruiseParams.Irtish,
		name: 'Круизы по Оби, Иртышу и Томи',
		isRoute: true,
	},

	{
		id: SearchCruiseParams.Egypt,
		name: 'Круизы по Египту',
	},
	{
		id: SearchCruiseParams.China,
		name: 'Круизы по Китаю',
	},
	{
		id: SearchCruiseParams.Lux,
		name: 'Речные круизы класса «Люкс»',
		isShips: true,
	},
	{
		id: SearchCruiseParams.Turkey,
		name: 'Круизы по Турецкой Ривьере',
	},
	{
		id: SearchCruiseParams.Baikal,
		name: 'Экспедиции по Байкалу',
		isRoute: true,
	},
	{
		id: SearchCruiseParams.Enisey,
		name: 'Экспедиции по Енисею',
		isRoute: true,
	},
	{
		id: SearchCruiseParams.Lena,
		name: 'Экспедиции по Лене',
		isRoute: true,
	},
	{
		id: SearchCruiseParams.East,
		name: 'Экспедиции по Дальнему Востоку',
		isRoute: true,
	},

	{
		id: SearchCruiseParams.SolovIslads,
		name: 'Круизы на Соловецкие острова',
		isRoute: true,
	},
];

export const SEARCH_CRUISES_SHIP_TYPES: Record<string, string> = {
	default: '0,1,2',
	sochi: '22',
	turkey: '50',
	egypt: '12',
	china: '33',
};

export const SEARCH_CRUISES_SHIPS: Record<string, string> = {
	default: '',
	sochi: '969',
	turkey: '975',
	egypt: '992,997',
	china: '999',
	lux: '927,995,540',
};

export const SEARCH_CRUISES_ROUTS: Record<string, string> = {
	default: '',
	baikal: '21',
	enisey: '22',
	east: '109',
	solov: '11',
	irtish: '84',
	lena: '23',
};

export const EGYPT_ADDITIONAL_SHIP = 991;

export const SEARCH_HANDBOOKS_ENDPOINT = '/api/search/v1/handbooks/horizontal';
export const SEARCH_HANDBOOKS_ENABLE_MOCK = false;
export const SEARCH_FILTERED_HANDBOOKS_KEYS = [
	['dateStartGte', 'dateStartLte'],
	['lengthDaysMin', 'lengthDaysMax'],
	'startCityIn',
	'routeIn',
	'shipIn',
];

export const SEARCH_FILTER_HANDBOOKS_ENDPOINT = '/api/search/v1/handbooks/vertical';
export const SEARCH_FILTER_HANDBOOKS_ENABLE_MOCK = false;
export const SEARCH_FILTERED_FILTER_KEYS = [
	['priceStartGte', 'priceStartLte'],
	'shipStarsIn',
	'companyIn',
	'servicesIn',
	'suggestionIn',
	'startPortIn',
	'endPortIn',
	'timetableCitiesIn',
	'riverIn',
];

export const SEARCH_SORT_KEY = 'orderBy';
export const SEARCH_SORT_DATE = 'startDate';
export const SEARCH_SORT_PRICE = 'startPrice';

export const RELATED_CRUISES_LIMIT = 5;
export const FREE_ONLY_CHECKED = {
	[SEARCH_CRUISES_FREE_ONLY_KEY]: SEARCH_CRUISES_FREE_ONLY_DEFAULT,
};

export const CRUISES_FROM_SOCHI = {
	[SEARCH_CRUISES_SHIP_TYPES_KEY]: SEARCH_CRUISES_SHIP_TYPES.sochi,
	[SEARCH_CRUISES_SHIPS_KEY]: SEARCH_CRUISES_SHIPS.sochi,
};

export const CRUISES_TURKEY = {
	[SEARCH_CRUISES_SHIP_TYPES_KEY]: SEARCH_CRUISES_SHIP_TYPES.turkey,
	[SEARCH_CRUISES_SHIPS_KEY]: SEARCH_CRUISES_SHIPS.turkey,
};

export const CRUISES_EGYPT = {
	[SEARCH_CRUISES_SHIP_TYPES_KEY]: SEARCH_CRUISES_SHIP_TYPES.egypt,
	[SEARCH_CRUISES_SHIPS_KEY]: SEARCH_CRUISES_SHIPS.egypt,
};

export const BONUS_PAYMENTS_CHECKED = {
	bonusPayments: '1',
};

export const DEFAULT_MONTHS_COUNT = 1;

export const PAGINATION_CACHE_NAME_SS = 'paginationCache';
