// import { ShipModel } from 'shared/lib/types/ships';

import { ShipModel } from "config/types/ships";

const icon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 6C4 5.17155 4.67158 4.5 5.5 4.5H18.5C19.3285 4.5 20 5.17155 20 6V11.5H4V6Z" stroke="#94A0A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 17.5V19.5" stroke="#94A0A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 17.5V19.5" stroke="#94A0A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 9H7C6.17155 9 5.5 9.67155 5.5 10.5V11.5H11.5V10.5C11.5 9.67155 10.8285 9 10 9Z" stroke="#94A0A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17 9H14C13.1716 9 12.5 9.67155 12.5 10.5V11.5H18.5V10.5C18.5 9.67155 17.8285 9 17 9Z" stroke="#94A0A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 13C2 12.1715 2.67158 11.5 3.5 11.5H20.5C21.3285 11.5 22 12.1715 22 13V17.5H2V13Z" stroke="#94A0A9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const photos = [
	{
		src: 'https://booking.infoflot.com/Uploads/0/a/b/0ab3a7859dd9c6bc736714d2a546666732b50e6b.jpg',
		alt: 'Ресепшн',
		position: 1,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/c/0/9/c098157d937e1d2bbf6037c20e620f6bdf47d537.png',
		alt: 'Ресторан «Джаз»',
		position: 2,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/5/e/4/5e44c8842f7010f4aeb27160beef9f438259c448.png',
		alt: 'Бар',
		position: 3,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/0/f/7/0f7ec9594b5b92f3d5d3f3f086e217e41ff88d75.jpg',
		alt: 'Ресторан «Джаз»',
		position: 4,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/4/b/7/4b79ad5ee5c06c57a7224a04a83ead864b5318c6.jpg',
		alt: 'Ресторан «Джаз»',
		position: 5,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/6/a/2/6a2ba79fec3e4ab0e3cea5d1809f82664a6389b9.jpg',
		alt: 'Ресторан «Блюз»',
		position: 6,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/a/c/e/acec3a5bbd283fa4be912962f91907d30c385ba9.jpg',
		alt: 'Ресторан «Блюз»',
		position: 7,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/e/7/3/e731e0989168d0001fdc2173f50770a857a379aa.png',
		alt: 'Кофейня',
		position: 8,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/5/5/d/55dadde233a485492fa6e7ec7f41e2db7bce1529.jpg',
		alt: 'Кофейня',
		position: 9,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/f/6/5/f65f4f0fb47e8848d91dfd26508e45302872e866.jpg',
		alt: 'Концертный зал',
		position: 10,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/d/d/0/dd0cccfb7ea78cf3d4749a0e9edc313c979351b5.png',
		alt: 'Театр',
		position: 11,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/2/1/d/21d1dd6fbbb932c7de0bce45381b36babf9b0d19.jpg',
		alt: 'Библиотека',
		position: 12,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/4/6/9/469db5e9d3b36aa7a978c863ff300b2058f31533.jpg',
		alt: 'Сувенирный киоск',
		position: 13,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/8/a/2/8a2b8c5ed835c4be9dc5ea4ef46d9e2ed8874a77.png',
		alt: 'Детский клуб',
		position: 14,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/0/9/0/0902ee380c47b5537b10bcdc0a55f9ab94dd7582.png',
		alt: 'Мастерская «Самоделкино»',
		position: 15,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/1/4/2/14284ca62edf25dd44da907cfe941344e30f2b02.jpg',
		alt: 'Мини-гольф',
		position: 16,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/7/3/e/73e46b4439583db00137544cb49d49fb5e3bdf51.png',
		alt: 'Солнечная палуба',
		position: 17,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/1/2/7/127923eb970040b4bd382dee08d52a5115bec701.jpg',
		alt: 'Солнечная палуба',
		position: 18,
	},
	{
		src: 'https://booking.infoflot.com/Uploads/f/0/e/f0e6dae69ca51f362a4211a7672527f68d6088a5.jpg',
		alt: 'Солнечная палуба',
		position: 19,
	},
];

export const shipMock: ShipModel.ShipInfo = {
	id: 498,
	name: 'Лунная соната',
	isNew: false,
	stars: {
		id: null,
		icon: null,
		label: null,
	},
	seo: {
		metaTitle: '',
		metaDescription: '',
	},
	type: null,
	specifications: [
		{
			title: 'палуб',
			value: '5',
			priority: 400,
		},
		{
			title: 'пассажиров',
			value: '300',
			priority: 300,
		},
		{
			title: 'ширина',
			value: '16 м',
			priority: 200,
		},
		{
			title: 'длина',
			value: '129.1 м',
			priority: 100,
		},
	],
	photos,
	crew: [
		{
			name: 'Быков Алексей Анатольевич',
			position: 'Капитан',
			phone: null,
			photo: {
				alt: null,
				src: 'https://booking.infoflot.com/Uploads/b/9/1/b91fd010da03c808137b7eab5e69c42d492328b1.jpg',
			},
		},
		{
			name: 'Жигачев Виктор Сергеевич',
			position: 'Директор круиза',
			phone: null,
			photo: {
				alt: null,
				src: 'https://booking.infoflot.com/Uploads/b/9/1/b91fd010da03c808137b7eab5e69c42d492328b1.jpg',
			},
		},
		{
			name: 'Чернова Елена Вячеславовна',
			position: 'Директор ресторана',
			phone: null,
			photo: {
				alt: null,
				src: 'https://booking.infoflot.com/Uploads/b/9/1/b91fd010da03c808137b7eab5e69c42d492328b1.jpg',
			},
		},
	],
	services: [
		{
			title: 'Пункт проката',
			description:
				' Пункт проката - За дополнительную плату предлагаются:  прокат биноклей, пляжных полотенец и велосипедов.',
			icon,
			benefit: false,
		},
		{
			title: 'Медицинский пункт',
			description: '',
			icon: null,
			benefit: false,
		},
	],
	mainInfo: [
		{
			title: 'о теплоходе',
			icon: null,
			text: '<p>«Лунная соната» — большой теплоход-пансионат компании «Созвездие». Соотношение числа членов экипажа к числу гостей: 1:3. Здесь каждый найдет себе занятие по душе: от игры в мини-гольф до визита в Мюзик-холл.</p>',
			design: null,
			type: 'mainInfo',
			pictures: photos.map(({ src }) => ({ src })).slice(0, 1),
		},
		{
			title: 'размещение',
			icon,
			text: '<p><div class="alert">Формат питания в круизе может быть изменен по рекомендациям и предписаниям, действующим на дату осуществления самого круиза.</div> В каютах теплохода могут с комфортом разместиться до 300 гостей.&nbsp;На средней палубе&nbsp;модернизированы&nbsp;36 кают: со сменой планировки, дизайна, мебели и отделки.&nbsp;Увеличена площадь санузла, где выделена отдельная зона для душевой. Каюты относятся к&nbsp; категории А2 комфорт.&nbsp;Часть кают оборудована балконами. Каюты категории &laquo;Люкс&raquo; и &laquo;Полулюкс&raquo; с балконами названы в честь знаменитых&nbsp;музыкантов.</p>',
			design: 'accordion',
			type: 'mainInfo',
		},
		{
			title: 'питание',
			icon,
			text: '<p>На всех теплоходах компании «Созвездие» действует единая <a href="https://www.infoflot.com/news/new5552/?roistat_visit=8373109">концепция питания (читать подробности)</a>. Если у вас имеются особые требования к питанию или противопоказания на определённые продукты, сообщите об этом нашему персоналу, и блюдо будет приготовлено индивидуально. По запросу в ресторане есть диетическое и безглютеновое меню. На борту теплохода вы сможете насладиться вкусной выпечкой и ароматным кофе Jardin Supremo (арабика из Колумбии), приготовленным профессиональным бариста. </p>',
			design: 'accordion_expanded',
			type: 'mainInfo',
			pictures: photos.map(({ src }) => ({ src })).slice(0, 4),
		},
		{
			title: 'для детей',
			icon: null,
			text: '<p>На теплоходе действует специальное предложение <a href="https://www.infoflot.com/news/new8028/?roistat_visit=8373109">«Дети бесплатно».</a> Работают квалифицированные аниматоры. Для детей и подростков проводят развлекательные программы, детский час, мастер классы и квесты. Каждому ребенку до 10 лет при регистрации выдается подарок. Бесплатно по запросу на борту предлагаются детские кроватки и постельное белье, в ресторане – отдельно разработанное меню, блюда которого подаются в детской посуде, также есть специальные стульчики для малышей от одного до трех лет. </p>',
			design: 'accordion',
			type: 'mainInfo',
		},
		{
			title: 'развлечения и услуги',
			icon: null,
			text: '<p>К услугам&nbsp;туристов&nbsp;на борту полный спектр развлечений: бары, танцевальные вечера&nbsp;под живую музыку, мастер-классы, занятия йогой, камерные музыкальные программы,&nbsp;вечерние представления, детский клуб.&nbsp;На теплоходе&nbsp;вас ждет не только разнообразный, но и полезный отдых. Профессиональные инструкторы каждое утро проводят утреннюю зарядку и организуют занятия йогой и пилатесом. На палубе стоят тренажеры, на которых вы можете позаниматься в любое время. А в ресторане и баре вам предложат фиточай и кислородный коктейль.</p>',
			design: 'accordion',
			type: 'mainInfo',
		},
	],
	touristProduct: [],
	suggestions: [
		{
			id: 1,
			priority: 1,
			label: '15%',
			title: 'При оплате 100%',
			description: '<p>Описание предложения</p>',
			dateStart: '2023-01-26T14:55:13',
			dateEnd: '2023-01-26T14:55:13',
		},
		{
			id: 2,
			priority: 1,
			label: '15%',
			title: 'При оплате 100%',
			description: '<p>Описание предложения</p>',
			dateStart: '2023-01-26T14:55:13',
			dateEnd: '2023-01-26T14:55:13',
		},
		{
			id: 3,
			priority: 1,
			label: '15%',
			title: 'При оплате 100%',
			description: '<p>Описание предложения</p>',
			dateStart: '2023-01-26T14:55:13',
			dateEnd: '2023-01-26T14:55:13',
		},
		{
			id: 4,
			priority: 1,
			label: '15%',
			title: 'При оплате 100%',
			description: '<p>Описание предложения</p>',
			dateStart: null,
			dateEnd: null,
		},
		{
			id: 5,
			priority: 1,
			label: '15%',
			title: 'При оплате 100%',
			description: '<p>Описание предложения</p>',
			dateStart: null,
			dateEnd: null,
		},
		{
			id: 6,
			priority: 1,
			label: '15%',
			title: 'При оплате 100%',
			description: '<p>Описание предложения</p>',
			dateStart: null,
			dateEnd: null,
		},
	],
};
