import React, { FC } from 'react';
import { SvgSprite } from './svg-sprite';

export type PaymentStatusIconId =
	| 'awaiting-confirmation'
	| 'confirmed'
	| 'awaiting-payment'
	| 'prepayment'
	| 'return'
	| 'in-processing'
	| 'awaiting-return'
	| 'paid'
	| 'cancellation-request'
	| 'waiting-list'
	| 'for-free'
	| 'canceled'
	| 'canceled-2'
	| 'payment-time';

export const PaymentStatusIcons: FC = () => (
	<SvgSprite>
		<symbol id="awaiting-confirmation" viewBox="0 0 12 12">
			<path
				d="M6 1V2M8.5 1.67L8 2.536M10.33 3.5L9.464 4M11 6H10M10.33 8.5L9.464 8M8.5 10.33L8 9.464M6 11V10M3.5 10.33L4 9.464M1.67 8.5L2.536 8M1 6H2M1.67 3.5L2.536 4M3.5 1.67L4 2.536"
				stroke="#BCB2FF"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</symbol>
		<symbol id="confirmed" viewBox="0 0 12 12">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.75297 2.64107C10.1768 2.99513 10.2334 3.62576 9.87938 4.04963L6.34398 8.28214C5.95003 8.75377 5.22791 8.76184 4.82353 8.29913L2.65806 5.82133C2.29462 5.40547 2.33712 4.77374 2.75297 4.4103C3.16882 4.04687 3.80056 4.08936 4.16399 4.50522L5.5593 6.10177L8.34442 2.76748C8.69847 2.34361 9.3291 2.28702 9.75297 2.64107Z"
				fill="#299D37"
			/>
		</symbol>
		<symbol id="awaiting-payment" viewBox="0 0 12 12">
			<g clipPath="url(#clip0_3844_74707)">
				<path
					d="M5.86839 3H5.83239C5.59239 3 5.40039 3.192 5.40039 3.432V6.264C5.40039 6.474 5.50839 6.672 5.69439 6.78L8.18439 8.274C8.38839 8.394 8.65239 8.334 8.77239 8.13C8.80252 8.08142 8.82256 8.02727 8.83132 7.97078C8.84009 7.91429 8.83739 7.85661 8.82339 7.80118C8.8094 7.74576 8.78439 7.69371 8.74985 7.64815C8.71532 7.6026 8.67197 7.56445 8.62239 7.536L6.30039 6.156V3.432C6.30039 3.192 6.10839 3 5.86839 3Z"
					fill="currentColor"
				/>
				<circle cx="6" cy="6" r="5.5" stroke="currentColor" />
			</g>
			<defs>
				<clipPath id="clip0_3844_74707">
					<rect width="12" height="12" fill="white" />
				</clipPath>
			</defs>
		</symbol>
		<symbol id="prepayment" viewBox="0 0 12 12">
			<path
				d="M1 3.33333C1 2.97971 1.1317 2.64057 1.36612 2.39052C1.60054 2.14048 1.91848 2 2.25 2H9.75C10.0815 2 10.3995 2.14048 10.6339 2.39052C10.8683 2.64057 11 2.97971 11 3.33333V6.66667H1V3.33333ZM8.1875 4C8.10462 4 8.02513 4.03512 7.96653 4.09763C7.90792 4.16014 7.875 4.24493 7.875 4.33333V5C7.875 5.08841 7.90792 5.17319 7.96653 5.2357C8.02513 5.29821 8.10462 5.33333 8.1875 5.33333H9.4375C9.52038 5.33333 9.59987 5.29821 9.65847 5.2357C9.71708 5.17319 9.75 5.08841 9.75 5V4.33333C9.75 4.24493 9.71708 4.16014 9.65847 4.09763C9.59987 4.03512 9.52038 4 9.4375 4H8.1875ZM1 8V8.66667C1 9.02029 1.1317 9.35943 1.36612 9.60948C1.60054 9.85952 1.91848 10 2.25 10H9.75C10.0815 10 10.3995 9.85952 10.6339 9.60948C10.8683 9.35943 11 9.02029 11 8.66667V8H1Z"
				fill="#0171AD"
			/>
		</symbol>
		<symbol id="return" viewBox="0 0 12 12">
			<path
				d="M3.4 7.33333L1 4.66667L3.4 2M1 4.66667H7.6C8.23652 4.66667 8.84697 4.94762 9.29706 5.44772C9.74714 5.94781 10 6.62609 10 7.33333C10 8.04058 9.74714 8.71885 9.29706 9.21895C8.84697 9.71905 8.23652 10 7.6 10H7"
				stroke="#ED3300"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</symbol>
		<symbol id="in-processing" viewBox="0 0 12 12">
			<path
				d="M6.34713 2.66512H5.65504C5.59173 2.66512 5.53993 2.71535 5.53993 2.77675V6.61814C5.53993 6.65442 5.5572 6.68791 5.58741 6.70884L7.96585 8.39302C8.01765 8.4293 8.08959 8.41954 8.127 8.3693L8.53852 7.82512C8.57737 7.77349 8.56585 7.70372 8.51406 7.66884L6.46224 6.23023V2.77675C6.46224 2.71535 6.41044 2.66512 6.34713 2.66512ZM9.50975 3.71442L11.7659 4.24884C11.8378 4.26558 11.9083 4.21256 11.9083 4.1414L11.9198 1.88791C11.9198 1.79442 11.809 1.7414 11.7342 1.8L9.46658 3.51768C9.44944 3.53053 9.43639 3.54782 9.42894 3.56755C9.42148 3.58728 9.41991 3.60865 9.42442 3.6292C9.42893 3.64975 9.43933 3.66865 9.45443 3.68373C9.46952 3.6988 9.4887 3.70944 9.50975 3.71442ZM11.9227 7.91581L11.1069 7.64372C11.0784 7.63427 11.0473 7.63598 11.0201 7.6485C10.993 7.66101 10.972 7.68334 10.9616 7.7107C10.9342 7.78186 10.9054 7.85163 10.8752 7.9214C10.6191 8.50884 10.2522 9.03768 9.78313 9.49116C9.31927 9.94239 8.76954 10.3021 8.16441 10.5502C7.53754 10.8073 6.8637 10.9392 6.1831 10.9381C5.49533 10.9381 4.82913 10.8084 4.20179 10.5502C3.59666 10.3021 3.04693 9.94239 2.58307 9.49116C2.11544 9.03768 1.74853 8.50884 1.49098 7.9214C1.2274 7.31314 1.09236 6.65978 1.09385 6C1.09385 5.33302 1.22767 4.68558 1.49386 4.07721C1.74997 3.48977 2.11688 2.96093 2.58595 2.50744C3.04981 2.05621 3.59954 1.69655 4.20467 1.44837C4.82913 1.19023 5.49677 1.06047 6.18454 1.06047C6.87232 1.06047 7.53851 1.19023 8.16585 1.44837C8.77098 1.69655 9.32071 2.05621 9.78457 2.50744C9.93133 2.65116 10.0695 2.80047 10.1961 2.95814L11.0565 2.30512C9.92414 0.901397 8.16153 -0.00139373 6.18166 1.61517e-06C2.73415 0.00139696 -0.0342131 2.71675 0.000319588 6.0614C0.0348522 9.34744 2.79027 12 6.18454 12C8.85363 12 11.127 10.3591 11.9932 8.06093C12.0148 8.00233 11.9831 7.93674 11.9227 7.91581Z"
				fill="#80CAFF"
			/>
		</symbol>
		<symbol id="awaiting-return" viewBox="0 0 12 12">
			<path
				d="M1 2.60156V5.80684C1 5.80684 1 7.40948 4.73949 7.40948C5.05574 7.40948 5.34582 7.39826 5.61079 7.37742M8.47898 2.60156V5.80684"
				stroke="#ED3300"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.73949 10.6158C1 10.6158 1 9.01319 1 9.01319V5.80792M10.9716 8.8353C10.6415 7.99659 9.8861 7.41055 9.00785 7.41055C8.0687 7.41055 7.27059 8.08099 6.98158 9.01319M4.73949 1C8.47898 1 8.47898 2.60264 8.47898 2.60264C8.47898 2.60264 8.47898 4.20528 4.73949 4.20528C1 4.20528 1 2.60264 1 2.60264C1 2.60264 1 1 4.73949 1Z"
				stroke="#ED3300"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.0789 8.83546H10.9715C11.0704 8.83546 11.15 8.75533 11.15 8.65703V7.64844M7.05469 10.2597C7.38483 11.0984 8.14021 11.6844 9.01845 11.6844C9.9576 11.6844 10.7557 11.014 11.0447 10.0818"
				stroke="#ED3300"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.94805 10.2578H7.05538C7.00806 10.2578 6.96267 10.2766 6.92921 10.3101C6.89575 10.3435 6.87695 10.3889 6.87695 10.4362V11.4448"
				stroke="#ED3300"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</symbol>
		<symbol id="paid" viewBox="0 0 12 12">
			<circle cx="6" cy="6" r="6" fill="#299D37" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.99888 4.00246C9.22419 4.24544 9.20986 4.62507 8.96688 4.85039L6.50933 7.1292C6.08943 7.51856 5.44106 7.52065 5.01866 7.134L4.03633 6.23478C3.7919 6.01103 3.77513 5.6315 3.99888 5.38708C4.22262 5.14265 4.60215 5.12588 4.84658 5.34963L5.76096 6.18664L8.15095 3.97046C8.39394 3.74515 8.77356 3.75948 8.99888 4.00246Z"
				fill="white"
			/>
		</symbol>
		<symbol id="cancellation-request" viewBox="0 0 12 12">
			<path
				d="M1.48112 10C1.29436 10 1.15631 9.91667 1.06699 9.75C0.97767 9.58333 0.97767 9.41667 1.06699 9.25L5.57369 1.25C5.66301 1.08333 5.80512 1 6 1C6.19488 1 6.33699 1.08333 6.42631 1.25L10.933 9.25C11.0223 9.41667 11.0223 9.58333 10.933 9.75C10.8437 9.91667 10.7056 10 10.5189 10H1.48112ZM6 4.5C5.86196 4.5 5.74633 4.54783 5.65311 4.6435C5.55956 4.7395 5.51279 4.85833 5.51279 5V6.5C5.51279 6.64167 5.55956 6.76033 5.65311 6.856C5.74633 6.952 5.86196 7 6 7C6.13804 7 6.25384 6.952 6.34738 6.856C6.4406 6.76033 6.48721 6.64167 6.48721 6.5V5C6.48721 4.85833 6.4406 4.7395 6.34738 4.6435C6.25384 4.54783 6.13804 4.5 6 4.5ZM6 8.5C6.13804 8.5 6.25384 8.452 6.34738 8.356C6.4406 8.26033 6.48721 8.14167 6.48721 8C6.48721 7.85833 6.4406 7.73967 6.34738 7.644C6.25384 7.548 6.13804 7.5 6 7.5C5.86196 7.5 5.74633 7.548 5.65311 7.644C5.55956 7.73967 5.51279 7.85833 5.51279 8C5.51279 8.14167 5.55956 8.26033 5.65311 8.356C5.74633 8.452 5.86196 8.5 6 8.5Z"
				fill="#ED3300"
			/>
		</symbol>
		<symbol id="waiting-list" viewBox="0 0 12 12">
			<path
				d="M3 6.9C3.25 6.9 3.4624 6.8124 3.6372 6.6372C3.8124 6.4624 3.9 6.25 3.9 6C3.9 5.75 3.8124 5.5376 3.6372 5.3628C3.4624 5.1876 3.25 5.1 3 5.1C2.75 5.1 2.5376 5.1876 2.3628 5.3628C2.1876 5.5376 2.1 5.75 2.1 6C2.1 6.25 2.1876 6.4624 2.3628 6.6372C2.5376 6.8124 2.75 6.9 3 6.9ZM6 6.9C6.25 6.9 6.4624 6.8124 6.6372 6.6372C6.8124 6.4624 6.9 6.25 6.9 6C6.9 5.75 6.8124 5.5376 6.6372 5.3628C6.4624 5.1876 6.25 5.1 6 5.1C5.75 5.1 5.5376 5.1876 5.3628 5.3628C5.1876 5.5376 5.1 5.75 5.1 6C5.1 6.25 5.1876 6.4624 5.3628 6.6372C5.5376 6.8124 5.75 6.9 6 6.9ZM9 6.9C9.25 6.9 9.4624 6.8124 9.6372 6.6372C9.8124 6.4624 9.9 6.25 9.9 6C9.9 5.75 9.8124 5.5376 9.6372 5.3628C9.4624 5.1876 9.25 5.1 9 5.1C8.75 5.1 8.5376 5.1876 8.3628 5.3628C8.1876 5.5376 8.1 5.75 8.1 6C8.1 6.25 8.1876 6.4624 8.3628 6.6372C8.5376 6.8124 8.75 6.9 9 6.9ZM6 12C5.17 12 4.39 11.8424 3.66 11.5272C2.93 11.2124 2.295 10.785 1.755 10.245C1.215 9.705 0.7876 9.07 0.4728 8.34C0.1576 7.61 0 6.83 0 6C0 5.17 0.1576 4.39 0.4728 3.66C0.7876 2.93 1.215 2.295 1.755 1.755C2.295 1.215 2.93 0.7874 3.66 0.4722C4.39 0.1574 5.17 0 6 0C6.83 0 7.61 0.1574 8.34 0.4722C9.07 0.7874 9.705 1.215 10.245 1.755C10.785 2.295 11.2124 2.93 11.5272 3.66C11.8424 4.39 12 5.17 12 6C12 6.83 11.8424 7.61 11.5272 8.34C11.2124 9.07 10.785 9.705 10.245 10.245C9.705 10.785 9.07 11.2124 8.34 11.5272C7.61 11.8424 6.83 12 6 12ZM6 10.8C7.34 10.8 8.475 10.335 9.405 9.405C10.335 8.475 10.8 7.34 10.8 6C10.8 4.66 10.335 3.525 9.405 2.595C8.475 1.665 7.34 1.2 6 1.2C4.66 1.2 3.525 1.665 2.595 2.595C1.665 3.525 1.2 4.66 1.2 6C1.2 7.34 1.665 8.475 2.595 9.405C3.525 10.335 4.66 10.8 6 10.8Z"
				fill="#313546"
			/>
		</symbol>
		<symbol id="for-free" viewBox="0 0 12 12">
			<path
				d="M10.5455 5.5V9.1C10.5455 9.33869 10.4497 9.56761 10.2792 9.7364C10.1087 9.90518 9.87747 10 9.63636 10H2.36364C2.12253 10 1.8913 9.90518 1.72081 9.7364C1.55032 9.56761 1.45455 9.33869 1.45455 9.1V5.5C1.33399 5.5 1.21838 5.45259 1.13313 5.3682C1.04789 5.28381 1 5.16935 1 5.05V3.7C1 3.46131 1.09578 3.23239 1.26627 3.0636C1.43675 2.89482 1.66799 2.8 1.90909 2.8H3.35C3.29863 2.65543 3.2725 2.50325 3.27273 2.35C3.27273 1.99196 3.4164 1.64858 3.67213 1.39541C3.92786 1.14223 4.2747 1 4.63636 1C5.09091 1 5.49091 1.225 5.74091 1.558V1.5535L6 1.9L6.25909 1.5535V1.558C6.50909 1.225 6.90909 1 7.36364 1C7.7253 1 8.07214 1.14223 8.32787 1.39541C8.5836 1.64858 8.72727 1.99196 8.72727 2.35C8.7275 2.50325 8.70137 2.65543 8.65 2.8H10.0909C10.332 2.8 10.5632 2.89482 10.7337 3.0636C10.9042 3.23239 11 3.46131 11 3.7V5.05C11 5.16935 10.9521 5.28381 10.8669 5.3682C10.7816 5.45259 10.666 5.5 10.5455 5.5ZM2.36364 9.1H5.54545V5.5H2.36364V9.1ZM9.63636 9.1V5.5H6.45455V9.1H9.63636ZM4.63636 1.9C4.51581 1.9 4.4002 1.94741 4.31495 2.0318C4.22971 2.11619 4.18182 2.23065 4.18182 2.35C4.18182 2.46935 4.22971 2.58381 4.31495 2.6682C4.4002 2.75259 4.51581 2.8 4.63636 2.8C4.75692 2.8 4.87253 2.75259 4.95778 2.6682C5.04302 2.58381 5.09091 2.46935 5.09091 2.35C5.09091 2.23065 5.04302 2.11619 4.95778 2.0318C4.87253 1.94741 4.75692 1.9 4.63636 1.9ZM7.36364 1.9C7.24308 1.9 7.12747 1.94741 7.04222 2.0318C6.95698 2.11619 6.90909 2.23065 6.90909 2.35C6.90909 2.46935 6.95698 2.58381 7.04222 2.6682C7.12747 2.75259 7.24308 2.8 7.36364 2.8C7.48419 2.8 7.5998 2.75259 7.68505 2.6682C7.77029 2.58381 7.81818 2.46935 7.81818 2.35C7.81818 2.23065 7.77029 2.11619 7.68505 2.0318C7.5998 1.94741 7.48419 1.9 7.36364 1.9ZM1.90909 3.7V4.6H5.54545V3.7H1.90909ZM6.45455 3.7V4.6H10.0909V3.7H6.45455Z"
				fill="#299D37"
			/>
		</symbol>
		<symbol id="canceled" viewBox="0 0 12 12">
			<path
				d="M3.5 11C3.225 11 2.98967 10.9022 2.794 10.7065C2.598 10.5105 2.5 10.275 2.5 10V3.5H2V2.5H4.5V2H7.5V2.5H10V3.5H9.5V10C9.5 10.275 9.40217 10.5105 9.2065 10.7065C9.0105 10.9022 8.775 11 8.5 11H3.5ZM8.5 3.5H3.5V10H8.5V3.5ZM4.5 9H5.5V4.5H4.5V9ZM6.5 9H7.5V4.5H6.5V9ZM3.5 3.5V10V3.5Z"
				fill="#BDC6D2"
			/>
		</symbol>
		<symbol id="canceled-2" viewBox="0 0 20 20">
			<path
				d="M3.75 4.16797V18.3346H16.25V4.16797H3.75Z"
				stroke="#0171AD"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<path
				d="M8.33301 8.33203V13.7487"
				stroke="#0171AD"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.667 8.33203V13.7487"
				stroke="#0171AD"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.66699 4.16797H18.3337"
				stroke="#0171AD"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.66699 4.16797L8.03741 1.66797H11.9908L13.3337 4.16797H6.66699Z"
				stroke="#0171AD"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
		</symbol>
		<symbol id="payment-time" viewBox="0 0 12 12">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.25 6C10.25 8.34721 8.34721 10.25 6 10.25C3.65279 10.25 1.75 8.34721 1.75 6C1.75 3.65279 3.65279 1.75 6 1.75C8.34721 1.75 10.25 3.65279 10.25 6ZM11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM5.62539 3.00039V6.25039V6.62539H6.00039H8.00039V5.87539H6.37539V3.00039H5.62539Z"
			/>
		</symbol>
	</SvgSprite>
);
