import { ImageProps } from 'config/commonTypes';
import React from 'react';
import { Img } from '../img';
// import Image from 'next/image';
// import { ImageProps } from 'shared/lib/types';

interface SlideImageProps extends ImageProps {
	index: number;
	className?: string;
}
export const SlideImage = ({ src, alt, className }: SlideImageProps) => (
	<picture className={className}>
		{src && <Img
			src={src} alt={alt}
			// layout="fill" objectFit="cover"
		/>}
	</picture>
);
