// import { getOffsetRecursively } from '../dom';

import { getOffsetRecursively } from "utils/dom/get-offset-recursively";

export const scrollToElement = (
	target: HTMLElement,
	offset: number = 0,
	behavior: ScrollBehavior = 'smooth',
	onlyTop = false,
) => {
	const position = Math.max(0, getOffsetRecursively(target).top - offset);
	const popup = document.querySelector('.popup');

	let resolver: Nullable<(value?: unknown) => void> = null;

	const promise = new Promise((resolve) => {
		resolver = resolve;
	});

	const onScroll = () => {
		if (!resolver) {
			return;
		}

		const positionReached = window.scrollY.toFixed(0) === position.toFixed(0);
		const pageEndReached = window.innerHeight + window.scrollY >= document.body.scrollHeight;

		if (positionReached || pageEndReached) {
			window.removeEventListener('scroll', onScroll);
			resolver();
		}
	};

	window.addEventListener('scroll', onScroll);

	if ('scrollBehavior' in document.documentElement.style) {
		if (onlyTop && position > window.scrollY) {
			return;
		}

		(popup || window).scrollTo({
			top: position,
			behavior,
		});
	} else if (popup) {
		popup.scrollTop = position;
	} else {
		window.scrollTo(0, position);
	}

	return promise;
};
