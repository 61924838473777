import React from 'react';

export interface ConditionProps {
	value?: boolean;
	then?: React.ReactElement;
	else?: React.ReactElement;
	children?: React.ReactElement;
}

export const Condition = ({ children, value, ...props }: ConditionProps) => {
	if (props?.then && props?.else) {
		if (value) {
			return props.then;
		}

		return props?.else || null;
	}

	if (!value) {
		return null;
	}

	return children || null;
};
