import React, { FC, memo, useEffect } from 'react';
import { useUnit } from 'effector-react';
// import { LoginWidget } from 'widgets/login';
import { userRoleModel } from 'entities/order/model';
// import { OrderRoutes } from 'shared/config';
// import { routerModel } from 'shared/model';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { OrderRoutes } from 'config/constants/order';
import { Icon } from 'layout/components/icons/icon';
import { routerModel } from 'utils/router';
import { LoginWidget } from 'layout/layouts/widgets/login';
import Authorization from 'layout/components/auth/Authorization';

export interface UnauthorizedOrderAttemptProps {
	inCartWidget?: boolean;
}

export const UnauthorizedOrderAttempt: FC<UnauthorizedOrderAttemptProps> = memo(
	({ inCartWidget }) => {
		const [onBack, setAuthRedirectTarget] = useUnit([
			userRoleModel.resetUserRoleDialog,
			routerModel.setAuthRedirectTarget,
		]);

		useEffect(() => {
			setAuthRedirectTarget(OrderRoutes.Tourists);
		}, [setAuthRedirectTarget]);

		return (
			<div className={styles.dialog}>
				{inCartWidget && (
					<button className={styles.backBtn} type="button" onClick={onBack}>
						<Icon id="chevron-left" width={20} height={20} />
					</button>
				)}
				{/* <LoginWidget initialState="register" className={styles.loginWrapper} isModalMode /> */}
				<Authorization initialState="register" className={styles.loginWrapper} isModalMode />
			</div>
		);
	},
);
