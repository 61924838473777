export const selectProps = (obj: Record<string, any>, keys: string[]) => {
	const newObj: Record<string, any> = {};
	keys.forEach((key) => {
		if (obj.hasOwnProperty(key)) {
			newObj[key] = convertBadValues(obj[key]);
		}
	});

	return newObj;
};

export const convertBadValues = (value: any) => {
	if ((typeof value === 'string' && value.length === 0) || typeof value === 'undefined') {
		return null;
	}

	return value;
};
