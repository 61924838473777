import { OfficeModel } from 'config/types/offices';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { officesRequests } from 'store/account/api/offices';
// import { officesRequests } from 'shared/api';
// import { OfficeModel } from 'shared/lib/types/offices';

const $offices = createStore<OfficeModel[]>([]);
const fetchOffices = createEvent();
const fetchOfficesFx = createEffect(officesRequests.getOffices);

sample({
	clock: fetchOffices,
	target: fetchOfficesFx,
});

sample({
	clock: fetchOfficesFx.doneData,
	target: $offices,
});

export const model = {
	$offices,
	fetchOffices,
};
