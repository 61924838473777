import React, { memo, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { Item } from 'react-stately';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-react-form';
// import { getAvailableCharactersParams } from 'widgets/personal-data/ui/mailing-address/lib';
// import { countriesModel } from 'entities/countries';
// import { Input, Button } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { Select } from 'shared/ui/molecules';
// import { FormField } from 'shared/ui/organisms';
import { model } from '../../model/guest-data';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { FormField } from 'layout/components/inputs/form/field';
import { Input } from 'layout/components/inputs/input';
import { Select } from 'layout/components/inputs/selects/select';
import { countriesModel } from 'store/countries';
import { getAvailableCharactersParams } from 'layout/layouts/widgets/personal-data/ui/mailing-address/lib';

export const Adress = memo(() => {
	const { form } = model;
	const { controller } = useForm({ form });
	const [isExpanded, setIsExpanded] = useState(false);
	const [countries] = useUnit([countriesModel.$countries]);

	return (
		<div className={styles.adress}>
			<Button
				className={styles.addressButton}
				btnType="outline"
				onClick={() => setIsExpanded((isOpen) => !isOpen)}
			>
				Внести адрес проживания
				<Icon
					id="chevron-down"
					width={20}
					height={20}
					className={classnames(styles.addressButton__icon, {
						[styles.addressButton__iconUp]: isExpanded,
					})}
				/>
			</Button>
			<AnimateHeight height={isExpanded ? 'auto' : 0} duration={400}>
				<div className={classnames(styles.grid, styles.adress__form)}>
					<FormField
						onChangeAlias="onSelectionChange"
						onChangeValueParser={(keys) => (keys instanceof Set && keys.size ? [...keys][0] : null)}
						valueAlias="selectedKeys"
						valueParser={(value) => (value ? [value] : [])}
						controller={controller}
						form={form}
						name="country"
					>
						<Select size="lg" label="Страна" selectionMode="single" className={styles.select}>
							{countries.map(({ name }) => (
								<Item key={`${name}`} aria-label={name}>
									{name}
								</Item>
							))}
						</Select>
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="zip"
						{...getAvailableCharactersParams('zip')}
						maxLength={9}
					>
						<Input placeholder="Индекс" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="region"
						maxLength={150}
						{...getAvailableCharactersParams('region')}
					>
						<Input placeholder="Область" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="district"
						maxLength={150}
						{...getAvailableCharactersParams('district')}
					>
						<Input placeholder="Район" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="city"
						maxLength={150}
						{...getAvailableCharactersParams('city')}
					>
						<Input placeholder="Город / Населённый пункт" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="street"
						maxLength={150}
						{...getAvailableCharactersParams('street')}
					>
						<Input placeholder="Улица" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="building"
						maxLength={50}
						{...getAvailableCharactersParams('building')}
					>
						<Input placeholder="Дом" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="buildingNumber"
						maxLength={50}
						{...getAvailableCharactersParams('buildingNumber')}
					>
						<Input placeholder="Корпус / Строение" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="office"
						maxLength={20}
						{...getAvailableCharactersParams('office')}
					>
						<Input placeholder="Квартира / Номер офиса" withLabel size="lg" />
					</FormField>
				</div>
			</AnimateHeight>
		</div>
	);
});
