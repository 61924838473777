// import { fetcher } from 'shared/api';
// import {
// 	ECOMMERCE_CRUISES_ENDPOINT,
// 	ECOMMERCE_SHIPS_ENDPOINT,
// 	METRICS_ENDPOINT,
// } from 'shared/config/ecommerce';
// import { EcommerceCruise, EcommerceShip } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { ECOMMERCE_CRUISES_ENDPOINT, ECOMMERCE_SHIPS_ENDPOINT, METRICS_ENDPOINT } from "config/constants/ecommerce";
import { EcommerceCruise, EcommerceShip } from "config/types/ecommerce";

export interface GetCruiseEcommerceRequest {
	cruiseId: string;
}

export const getCruiseInfo = async ({ cruiseId }: GetCruiseEcommerceRequest) =>
	fetcher<EcommerceCruise>({
		url: `${ECOMMERCE_CRUISES_ENDPOINT}/${cruiseId}/ecommerce`,
		method: 'GET',
	});

export interface GetShipEcommerceRequest {
	shipId: string;
}

export const getShipInfo = async ({ shipId }: GetShipEcommerceRequest) =>
	fetcher<EcommerceShip>({
		url: `${ECOMMERCE_SHIPS_ENDPOINT}/${shipId}/ecommerce`,
		method: 'GET',
	});

// Метрики для проверки яндекс метрики

export interface GetUserResponse {
	userId: string;
}

export const getMetricsUser = async () =>
	fetcher<GetUserResponse>({
		url: `${METRICS_ENDPOINT}/user`,
		method: 'GET',
	});

export interface PostMetricsEventsRequest {
	userId: string;
	eventType: string;
}

export const addMetricsEvent = async (body: PostMetricsEventsRequest) =>
	fetcher({
		url: `${METRICS_ENDPOINT}/event`,
		method: 'POST',
		body,
	});
