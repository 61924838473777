// import { fetcher } from 'shared/api/config';
// import { getDetailedCabinData } from 'shared/api/mock/cabins';
// import { SHIPS_ENDPOINT } from 'shared/config';
// import { DetailedCabin } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { SHIPS_ENDPOINT } from "config/constants/ships";
import { DetailedCabin } from "config/types/cabins";
import { getDetailedCabinData } from "mocks/cabins";

export interface GetDetailedShipCabinRequest {
	shipId: number;
	cabinId: number;
}

const getDetailedShipCabinInfo = async (
	{ shipId, cabinId }: GetDetailedShipCabinRequest,
	needMock = false,
) => {
	if (needMock) {
		await new Promise((resolve) => {
			setTimeout(resolve, 300);
		});

		return getDetailedCabinData();
	}

	return fetcher<DetailedCabin>({
		url: `${SHIPS_ENDPOINT}/${shipId}/cabins/${cabinId}`,
		method: 'GET',
	});
};

export const detailedShipCabinRequest = {
	getDetailedShipCabinInfo,
};
