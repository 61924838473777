import React, { useMemo } from 'react';
import { DateValue } from '@react-types/datepicker';
import { useDateFormatter } from 'react-aria';
import { CalendarState, Item, RangeCalendarState } from 'react-stately';
// import { capitalizeFirstLetter } from 'shared/lib/utils';
// import { Select } from 'shared/ui/molecules/select';
import style from '../style.module.scss';
import { Select } from 'layout/components/inputs/selects/select';
import { capitalizeFirstLetter } from 'utils/string';

interface MonthDropdownProps {
	state: CalendarState | RangeCalendarState;
	minYear?: number | 'today';
	minValue?: DateValue;
	portal?: HTMLElement;
}

export const MonthDropdown = ({ state, minYear, minValue, portal }: MonthDropdownProps) => {
	const formatter = useDateFormatter({
		month: 'long',
		timeZone: state.timeZone,
	});

	const months = useMemo(() => {
		const tmpMonths: string[] = [];
		for (let i = 1; i <= 12; i++) {
			const date = state.focusedDate.set({ month: i });
			tmpMonths.push(capitalizeFirstLetter(formatter.format(date.toDate(state.timeZone))));
		}

		return tmpMonths;
	}, [formatter, state.focusedDate, state.timeZone]);

	const onChange = (key: string) => {
		const month = Number(key);
		const date = state.focusedDate.set({ month });
		state.setFocusedDate(date);
	};

	const limitMonth = typeof minYear === 'number' && minYear === state.focusedDate.year;
	const minMonth = limitMonth && minValue ? minValue.month - 1 : 0;

	return (
		<Select
			className={style.calendar__year}
			classNameTrigger={style.calendar__yearTrigger}
			classNamePopover={style.calendar__monthPopover}
			alwaysShowChevron
			selectedKeys={state.focusedDate.month ? [`${state.focusedDate.month}`] : []}
			onSelectionChange={(keys) => {
				if (keys instanceof Set && keys.size) {
					onChange([...keys][0].toString());
				}
			}}
			selectPortal={portal}
		>
			{months.slice(minMonth, months.length).map((month, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<Item key={minMonth + i + 1}>{month}</Item>
			))}
		</Select>
	);
};
