import { ParsedUrlQuery } from 'querystring';
import { status } from 'patronum';
// import { GetServerSidePropsContext } from 'next/types';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { LoadRequests200Response } from 'store/auth/types/account/load-requests-200-response';
import { accountRequests } from 'store/account/api/requests';
import { requestsFilterModel } from 'layout/components/special/requests-filter';
import { AccountRoutes } from 'store/auth/constants';
import { routerModel } from 'utils/router';
import { filterObjectKeys } from 'utils/object';
import { headerModel } from 'layout/components/view/header';
import { footerModel } from 'layout/components/view/footer';
import { REQUEST_FILTERS } from 'config/constants/requests';
// import { headerModel } from 'features/header';
// import { requestsFilterModel } from 'features/requests-filter/model';
// import { footerModel } from 'entities/footer';
// import { accountRequests } from 'shared/api';
// import { REQUEST_FILTERS, AccountRoutes } from 'shared/config';
// import { LoadRequests200Response } from 'shared/lib/types/account/models';
// import { filterObjectKeys } from 'shared/lib/utils';
// import { routerModel } from 'shared/model';

const Gate = createGate<ParsedUrlQuery>();

// TODO: подобрать тип данных вместо any
// const getData = createEvent<GetServerSidePropsContext>();
const getData = createEvent<any>();

const $requests = createStore<Nullable<LoadRequests200Response>>(null);
const getRequests = createEvent<ParsedUrlQuery>();
const getRequestsFx = createEffect(accountRequests.getRequests);
const $status = status({ effect: getRequestsFx });

const setQueryParams = createEvent<ParsedUrlQuery>();

sample({
	clock: Gate.open,
	target: [requestsFilterModel.setInitialSearchParams, getRequests],
});

sample({
	clock: getRequests,
	fn: (clock) => ({ filters: clock }),
	target: getRequestsFx,
});

sample({
	clock: getRequestsFx.doneData,
	target: $requests,
});

sample({
	clock: getRequests,
	target: requestsFilterModel.getAvailableShips,
});

const loadMoreRequests = createEvent<number>();
const loadMoreRequestsFx = createEffect(accountRequests.getRequests);

sample({
	source: requestsFilterModel.$searchParams,
	clock: loadMoreRequests,
	fn: (filters, page) => ({
		filters: { ...filters, page: page.toString() },
	}),
	target: loadMoreRequestsFx,
});

sample({
	clock: loadMoreRequestsFx.doneData,
	source: $requests,
	fn: (source, clock) => ({
		...clock,
		data: [...(source?.data ?? []), ...(clock.data ?? [])],
	}),
	target: $requests,
});

const $initialPageRequest = createStore<boolean>(false);
const setPageRequest = createEvent<boolean>();

sample({
	clock: Gate.status,
	target: $initialPageRequest,
});

sample({
	clock: setPageRequest,
	target: $initialPageRequest,
});

/* *
 * Хэдэр / футер
 */
sample({
	clock: getData,
	target: [headerModel.getData, footerModel.getData],
});

/**
 * Добавление фильтров в качестве параметров строки
 */
sample({
	source: requestsFilterModel.$searchParams,
	clock: setQueryParams,
	fn: (searchParams, params) => {
		const newParams: ParsedUrlQuery = { ...searchParams, ...params };

		const search = new URLSearchParams();

		Object.entries(newParams).forEach(([key, value]) => {
			search.append(key, `${value}`);
		});

		const query = search.toString();

		const path = `${AccountRoutes.MyRequests}${query ? `?${query}` : ''}`;

		return { url: path, as: path, options: { shallow: true } };
	},
	target: routerModel.push,
});

sample({
	clock: routerModel.routeChangeComplete,
	filter: (url) => url.includes(AccountRoutes.MyRequests),
	fn: (url) => {
		const query = new URLSearchParams(url.toString().split('?')[1]?.split('#')[0]).entries();
		const params = Object.fromEntries(query);

		return filterObjectKeys(params, REQUEST_FILTERS);
	},
	target: getRequests,
});

export const model = {
	$requests,
	getRequests,
	loadMoreRequests,
	$status,
	Gate,
	$initialPageRequest,
	setPageRequest,
	getData,
	setQueryParams,
};
