import React, { FC, memo } from 'react';
import { useUnit } from 'effector-react';
// import { ConfirmationLabel } from 'widgets/order/order-confirmation/ui/confirmation-block-wrapper';
import { cartModel } from 'entities/cart';
import { CABINS_DECLENSION, SEATS_DECLENSION } from 'config/constants';
import { getNumDeclension } from 'utils/string';
import { ChipRecord } from 'layout/components/view/chip-record';
import { ChipRecords } from 'layout/components/view/chip-records';
import { ConfirmationLabel } from 'layout/layouts/widgets/order/order-confirmation/ui/confirmation-block-wrapper';
// import { CABINS_DECLENSION, SEATS_DECLENSION } from 'shared/lib/constants';
// import { getNumDeclension } from 'shared/lib/utils';
// import { ChipRecord } from 'shared/ui/atoms';
// import { ChipRecords } from 'shared/ui/molecules';

export interface RequestSummaryProps {
	className?: string;
}

export const RequestCartCounters: FC<RequestSummaryProps> = memo(({ className }) => {
	const cart = useUnit(cartModel.$cart);

	const cabinsAmount = cart.length;

	const touristsAmount = cart.reduce(
		(acc, { places }) => {
			acc.main += places.main.filter(({ type }) => `${type}` !== '3').length;
			acc.additional += places?.additional?.filter(({ type }) => `${type}` !== '3')?.length ?? 0;

			return acc;
		},
		{
			main: 0,
			additional: 0,
		},
	);

	return (
		<div>
			<ConfirmationLabel label="Туристы" />
			<ChipRecords className={className}>
				<ChipRecord value={cabinsAmount} label={CABINS_DECLENSION} />
				<ChipRecord>
					<strong>{touristsAmount.main}</strong>{' '}
					{getNumDeclension(touristsAmount.main, SEATS_DECLENSION)}{' '}
					{touristsAmount.additional > 0 && (
						<>
							+ <strong>{touristsAmount.additional}</strong> доп
						</>
					)}
				</ChipRecord>
			</ChipRecords>
		</div>
	);
});
