// import { fetcher } from 'shared/api/config';
// import { ACCOUNT_URL } from 'shared/config';
// import {
// 	BonusRecoveryRequest,
// 	GetBonusCardHistory200Response,
// 	RequestWaitingBonus,
// } from 'shared/lib/types/account/models';

import { fetcher } from "api/setupAxios";
import { ACCOUNT_URL } from "store/auth/constants";
import { BonusRecoveryRequest } from "store/auth/types/account/bonus-recovery-request";
import { GetBonusCardHistory200Response } from "store/auth/types/account/get-bonus-card-history-200-response";
import { RequestWaitingBonus } from "store/auth/types/account/request-waiting-bonus";

export interface GetBonusHistoryRequest {
	page?: number;
	perPage?: number;
}

const getBonusHistory = ({ page, perPage = 10 }: GetBonusHistoryRequest) =>
	fetcher<GetBonusCardHistory200Response>({
		url: `${ACCOUNT_URL}/bonus-card/history`,
		authorization: true,
		method: 'GET',
		body: {
			page,
			perPage,
		},
	});

const getWaitingBonusHistory = () =>
	fetcher<RequestWaitingBonus[]>({
		url: `${ACCOUNT_URL}/requests/waiting-bonus`,
		authorization: true,
		method: 'GET',
	});

export interface SendBonusSupportRequest {
	message: string;
	type: string;
}

const sendBonusSupport = ({ message, type }: SendBonusSupportRequest) =>
	fetcher({
		url: `${ACCOUNT_URL}/profile/support`,
		authorization: true,
		method: 'POST',
		body: {
			message,
			type,
		},
	});

const bonusRecovery = (params: BonusRecoveryRequest) =>
	fetcher({
		url: `${ACCOUNT_URL}/bonus-card/recovery`,
		authorization: true,
		method: 'POST',
		body: {
			...params,
		},
	});

export const bonusesRequests = {
	getBonusHistory,
	getWaitingBonusHistory,
	sendBonusSupport,
	bonusRecovery,
};
