import React, { FormEvent, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useUnit } from 'effector-react';
import { verificationModel } from './model';

export const useReCaptchaVerify = (
	handleSubmit: (e: React.SyntheticEvent<HTMLFormElement>, captchaToken?: string) => void,
) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const setToken = useUnit(verificationModel.setCaptchaToken);

	const handleReCaptchaVerify = useCallback(
		async (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			if (!executeRecaptcha) {

				return;
			}
			try {
				const token = await executeRecaptcha('form');
				setToken(token);

				handleSubmit(e);
			} catch (error_) {
				console.error(error_);
			}
		},
		[executeRecaptcha, handleSubmit, setToken],
	);

	return handleReCaptchaVerify;
};
