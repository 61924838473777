import { BELARUS_PHONES, PHONES } from 'config/constants/contacts';
import { useUnit } from 'effector-react';
import { localizationModel } from './model';
// import { BELARUS_PHONES, PHONES } from 'shared/config';
// import { localizationModel } from 'shared/lib/localization/model';

export const useLocalPhones = () => {
	const isBelarus = useUnit(localizationModel.$isBelarus);

	return isBelarus ? BELARUS_PHONES : PHONES;
};
