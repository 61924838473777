export const isTouchDevice = (): boolean => {
	try {
		document.createEvent('TouchEvent');

		return true;
	} catch {
		return (
			typeof window !== 'undefined' &&
			('ontouchstart' in window || !!window.navigator.maxTouchPoints)
		);
	}
};
