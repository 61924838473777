// import { fetcher } from 'shared/api/config';
// import { REQUESTS_URL } from 'shared/config';

import { fetcher } from "api/setupAxios";
import { REQUESTS_URL } from "store/auth/constants";

export interface SendBookingSupportPersonal {
	officeId: Nullable<string>;
	phone: Nullable<string>;
	email: Nullable<string>;
	firstName: Nullable<string>;
	lastName: Nullable<string>;
}

export interface SendBookingSupportRequest {
	cruiseId: number;
	adultCount: string;
	childrenCount: string;
	cabinCategories: string[];
	comment: string;
	captcha: Nullable<string>;
	agreeProcessData: boolean;
}

const sendBookingSupport = ({
	data,
	authorized,
}: {
	data: SendBookingSupportRequest & Partial<SendBookingSupportPersonal>;
	authorized?: boolean;
}) => {
	const body: Record<string, any> = { ...data };
	delete body.agreeProcessData;

	return fetcher<any>({
		url: `${REQUESTS_URL}/short`,
		method: 'POST',
		authorization: authorized,
		body,
	});
};

export const bookingRequests = {
	sendBookingSupport,
};
