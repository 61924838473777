import { isServer } from 'config/env';
import { createEffect, createStore, sample, Unit } from 'effector';
// import { isServer } from 'shared/lib/env';

export const abortingFactory = ({ trigger }: { trigger: Unit<any> }) => {
	if (isServer) {
		return {
			$abortSignal: createStore(null),
		};
	}

	const $abortController = createStore<AbortController>(new AbortController());
	const $abortSignal = $abortController.map(({ signal }) => signal);
	const abortFx = createEffect((abortController: AbortController) => {
		abortController.abort();

		return new AbortController();
	});

	// Вызов прерывания
	sample({
		source: $abortController,
		clock: trigger,
		target: abortFx,
	});

	// Обновление контроллера
	sample({
		clock: abortFx.doneData,
		target: $abortController,
	});

	return {
		$abortSignal,
	};
};
