import { getFailCode } from "api";
import { NOT_DIGITS } from "config/constants";
import { message } from "config/constants/message";
import { RecoverByEmailRequest, RecoverByPhoneRequest } from "config/types/personal";
import { combine, createEffect, createEvent, createStore, sample, split } from "effector";
import { createForm } from "effector-react-form";
import { recoverPasswordByEmail, recoverPasswordByPhone } from "store/auth/api/recover-password";
import { verificationModel } from "utils/verification";

interface FormValues {
	login: string;
}

const handleSubmit = createEvent<FormValues>();

const recoverByPhone = createEvent<FormValues>();
const recoverByEmail = createEvent<FormValues>();
const recoverByPhoneFx = createEffect(recoverPasswordByPhone);
const recoverByEmailFx = createEffect(recoverPasswordByEmail);

const handleBadResponse = createEvent<number | undefined>();

const $submitted = createStore(false);

const $login = createStore<Nullable<string>>(null).reset(handleSubmit);
const $loginType = createStore<Nullable<'phone' | 'email'>>(null);

const form = createForm<FormValues>({
	initialValues: {
		login: '',
	},
	onSubmit: ({ values }) => handleSubmit(values),
});

sample({
	source: handleSubmit,
	fn: ({ login }) => {
		const loginType = login.includes('@') ? 'email' : 'phone';

		return loginType === 'phone' ? login.replace(NOT_DIGITS, '').slice(0, 20) : login;
	},
	target: $login,
});

sample({
	source: $login,
	filter: Boolean,
	fn: (login) => (login.includes('@') ? 'email' : 'phone'),
	target: $loginType,
});

split({
	clock: $login,
	source: form.$values,
	match: $loginType,
	cases: {
		phone: recoverByPhone,
		email: recoverByEmail,
	},
});

sample({
	source: verificationModel.$captchaToken,
	clock: recoverByPhone,
	filter: Boolean,
	fn: (captcha, { login }) =>
		({
			phone: login.replace(NOT_DIGITS, '').slice(0, 20),
			captcha,
		} as RecoverByPhoneRequest),
	target: recoverByPhoneFx,
});

sample({
	source: verificationModel.$captchaToken,
	clock: recoverByEmail,
	filter: Boolean,
	fn: (captcha, { login }) =>
		({
			email: login,
			captcha,
		} as RecoverByEmailRequest),
	target: recoverByEmailFx,
});

sample({
	clock: [recoverByEmailFx.doneData, recoverByPhoneFx.doneData],
	fn: () => true,
	target: $submitted,
});

sample({
	clock: [recoverByEmailFx.failData, recoverByPhoneFx.failData],
	fn: (error) => getFailCode(error),
	target: handleBadResponse,
});

sample({
	clock: handleBadResponse,
	fn: (code) => ({
		field: 'formError',
		error: message.profile.resetPassword[code || 'error'],
	}),
	target: form.setOrDeleteError,
});

sample({
	clock: recoverByPhoneFx.doneData,
	fn: ({ resetSmsToken }) => resetSmsToken,
	target: verificationModel.$verifyToken,
});

const $pending = combine(recoverByEmailFx.pending, recoverByPhoneFx.pending, (a, b) => a || b);

export const requestFormModel = {
	form,
	$login,
	$loginType,
	$submitted,
	$pending,
	recoverByEmail,
	$recoverByEmailPending: recoverByEmailFx.pending,
	recoverByPhone,
	$recoverByPhonePending: recoverByPhoneFx.pending,
};
