import { createEvent, createStore, sample } from 'effector';

const ERROR_CODES = ['logout-fail', 'login-fail.ac3', 'cruise.ac2', 'fail'] as const;
export type ErrorCode = typeof ERROR_CODES[number];

function isErrorCode(code: string): code is ErrorCode {
	// @ts-ignore
	return ERROR_CODES.includes(code);
}

const reset = createEvent();
const $error = createStore<Nullable<ErrorCode>>(null).reset(reset);

const setError = createEvent<string>();

sample({
	clock: setError,
	fn: (code) => {
		if (!isErrorCode(code)) {
			return 'fail' as ErrorCode;
		}

		return code;
	},
	target: $error,
});

export const model = {
	reset,
	$error,
	setError,
};
