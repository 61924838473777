import React, { FC } from 'react';
import { useList } from 'effector-react';
// import { profileScreenModel } from 'widgets/account/model';
import { PromoCardSmall } from 'entities/promo-card';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { profileScreenModel } from 'entities/account/model';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';

interface SpecialOffersScreenProps {
	onClickBack?: () => void;
}

export const SpecialOffersScreen: FC<SpecialOffersScreenProps> = ({ onClickBack }) => {
	const specialOffers = useList(profileScreenModel.$specialOffersWithImages, (item) => (
		<PromoCardSmall {...item} />
	));

	return (
		<section className={styles.specialOffersScreen}>
			<header className={styles.specialOffersScreen__head}>
				<button type="button" onClick={onClickBack} className={styles.specialOffers__headBack}>
					<Icon id="chevron-left" width={20} height={20} />
				</button>
				<Typo design="headline-l" as="h1">
					Спецпредложения для участников Клуба Любителей Круизов
				</Typo>
			</header>
			<div className={styles.specialOffersScreen__feed}>{specialOffers}</div>
		</section>
	);
};
