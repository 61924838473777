import { getFailCode } from "api";
import { AUTH_REDIRECT_MAP } from "config/commonConstants";
import { OLD_INFOFLOT_URL } from "config/constants/contacts";
import { message } from "config/constants/message";
import { LoginRequest, LoginSmsRequest, RegisterResponse } from "config/types/personal";
import { createEffect, createEvent, createStore, sample } from "effector";
import { createForm } from "effector-react-form";
import { getValidation, ValidationOptions } from "layout/components/inputs/form/lib/validation";
import { personalRequests } from "store/auth/api";
import { routerModel } from "utils/router";
import { verificationModel } from "utils/verification";


const authoriseFx = createEffect(personalRequests.login);
const handleOnSubmit = createEvent<Omit<LoginRequest, 'loginType'>>();
const handlePhoneCodeOnSubmit = createEvent<Omit<LoginSmsRequest, 'captcha'>>();
const phoneSmsFx = createEffect(personalRequests.loginBySms);
const handleGoodResponse = createEvent<RegisterResponse>();
const $isLoginSms = createStore<boolean>(false);

const openLinkFx = createEffect(() => {
	const a = document.createElement('a');
	a.style.display = 'none';
	document.body.append(a);
	a.href = OLD_INFOFLOT_URL;
	a.target = '_blank';
	a.click();
	window.URL.revokeObjectURL(OLD_INFOFLOT_URL);
	a.remove();
});

const form = createForm<Omit<LoginRequest, 'loginType'>>({
	initialValues: {
		login: '',
	},
	onSubmit: ({ values }) => {
		if (values.password) {
			handleOnSubmit(values);
		} else {
			handlePhoneCodeOnSubmit({ phone: values.login.replace('+', '') });
		}
	},
});

sample({
	source: verificationModel.$captchaToken,
	clock: handlePhoneCodeOnSubmit,
	fn: (captcha, clock) => {
		return ({ captcha, ...clock } as LoginSmsRequest)
	},
	target: phoneSmsFx,
});

sample({
	clock: handlePhoneCodeOnSubmit,
	fn: (values) => {
		return ({
			type: 'phone' as const,
			value: values.phone,
		})
	},
	target: verificationModel.$verificationItem,
});

sample({
	clock: handleGoodResponse,
	fn: (response) => response.verifyToken,
	target: [verificationModel.$verifyToken, $isLoginSms],
});

const handleBadResponse = createEvent<number | undefined>();

const handleAuthoriseAnalyticsFx = createEffect(() => ({})
	// setAnalytics(ANALYTICS_TARGETS.signin.signin_success),
);

sample({
	source: phoneSmsFx.doneData,
	target: [handleGoodResponse],
});

sample({
	clock: handleOnSubmit,
	fn: (data) => {
		const loginType = data.login.includes('@') ? 'email' : 'phone';
		return {
			...data,
			loginType,
			login: loginType === 'phone' ? data.login.replace('+', '') : data.login,
		} as LoginRequest;
	},
	target: authoriseFx,
});

sample({
	clock: authoriseFx.doneData,
	source: {
		prevPage: routerModel.$prevPage,
		redirectTarget: routerModel.$authRedirectTarget,
	},
	filter: (_, auth) => !auth.agent,
	fn: ({ prevPage, redirectTarget }) => {
		if (redirectTarget) {
			return redirectTarget;
		}

		if (prevPage && AUTH_REDIRECT_MAP.hasOwnProperty(prevPage)) {
			return AUTH_REDIRECT_MAP[prevPage];
		}

		return prevPage || '/';
	},
	target: [routerModel.push, routerModel.setAuthRedirectTarget],
});

sample({
	clock: authoriseFx.doneData,
	filter: (auth) => auth.agent,
	target: openLinkFx,
});

sample({
	clock: [authoriseFx.failData, phoneSmsFx.failData],
	fn: (error) => getFailCode(error),
	target: handleBadResponse,
});

sample({
	clock: handleBadResponse,
	fn: (code) => ({
		field: 'formError',
		error: message.profile.authorization[code || 'error'],
	}),
	target: form.setOrDeleteError,
});

/* *
 * Забираем authRedirectTarget из сессии,
 * если было установлено перед редиректом
 */
sample({
	clock: [handleOnSubmit, handlePhoneCodeOnSubmit],
	source: routerModel.$authRedirectTarget,
	fn: (authRedirectTarget) => {
		const value = sessionStorage.getItem('authRedirectTarget') ?? authRedirectTarget;
		sessionStorage.removeItem('authRedirectTarget');

		return value;
	},
	target: [routerModel.$authRedirectTarget, routerModel.clearAuthRedirectTargetFx],
});

/* *
 * Переключение схемы валидации логина
 */
interface SetValidationValues {
	validation: ValidationOptions;
	name: string;
}
const setValidation = createEvent<SetValidationValues>();
const setValidationPassword = createEvent<SetValidationValues>();
const $loginFieldState = form.$fieldsInline.map((state) => state.login ?? null);
const $passwordFieldState = form.$fieldsInline.map((state) => state.password ?? null);

sample({
	clock: setValidation,
	filter: ({ fieldState }, newOptions) => Boolean(fieldState) && Boolean(newOptions.validation),
	source: { fieldState: $loginFieldState },
	fn: ({ fieldState }, newOptions) => ({
		field: newOptions.name,
		state: {
			...fieldState,
			validate: getValidation(newOptions.validation),
		},
	}),
	target: form.setFieldState,
});

sample({
	clock: setValidationPassword,
	// filter: ({ fieldState }, newOptions) => Boolean(fieldState),
	source: { fieldState: $passwordFieldState },
	fn: ({ fieldState }, newOptions) => ({
		field: newOptions.name,
		state: {
			...fieldState,
			validate: getValidation(newOptions.validation),
		},
	}),
	target: form.setFieldState,
});

/**
 * Обработка аналитики
 */
sample({
	clock: authoriseFx.doneData,
	target: handleAuthoriseAnalyticsFx,
});

export const model = {
	form,
	authoriseFx,
	$pending: authoriseFx.pending,
	$phoneCodePending: phoneSmsFx.pending,
	setValidation,
	$isLoginSms,
	setValidationPassword,
};
