import React, { FC, memo } from 'react';
import { useUnit } from 'effector-react';
// import { orderExcursionsModel } from 'features/order/model';
import { cartModel } from 'entities/cart';
import { pricesModel } from 'entities/order/index';
// import { Alert, Typo } from 'shared/ui/atoms';
import { RequestCartCounters } from '../../request-cart-counters';
import { SummaryTable, SummaryTableItem } from './summary-table';
import styles from './styles.module.scss';
import { orderExcursionsModel } from 'layout/components/order/model';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';

export interface RequestSummaryProps {}

export const RequestSummary: FC<RequestSummaryProps> = memo(() => {
	const [cart, prices, pricesStatus, discountsList, excursions, excursionsTotal] = useUnit([
		cartModel.$cart,
		pricesModel.$pricesPreview,
		pricesModel.$getPricesPreviewStatus,
		pricesModel.$pricesPreviewDiscounts,
		orderExcursionsModel.$excursionsWithPrices,
		orderExcursionsModel.$totalPrice,
	]);

	const fewerTouristsThanPlaces = cart.reduce(
		(acc, { places }) => places.main.some(({ type }) => !type || type === '3') || acc,
		false,
	);

	return (
		<div className={styles.box}>
			<Typo design="headline-m">Детали заявки</Typo>

			<div className={styles.content}>
				<div className={styles.records}>
					<RequestCartCounters />

					<div className={styles.cabins}>
						<Typo design="description-features">
							<span className={styles.cabins__title}>Каюты:</span>
							{cart.map((el) => el.name).join(', ')}
						</Typo>
					</div>
				</div>

				<SummaryTable>
					<SummaryTableItem
						label="Стоимость круиза"
						price={(prices?.default ?? 0) - (prices?.surcharge ?? 0)}
						pending={pricesStatus === 'pending'}
					/>
					{fewerTouristsThanPlaces && (
						<SummaryTableItem
							label="Доплата за размещение"
							price={prices?.surcharge ?? 0}
							pending={pricesStatus === 'pending'}
						/>
					)}
					{excursions.length > 0 && (
						<SummaryTableItem
							label="Стоимость доп. экскурсий"
							price={excursionsTotal}
							defaultExpanded
						>
							{excursions.map(({ id, value, title }) => (
								<SummaryTableItem key={id} label={title} price={value} />
							))}
						</SummaryTableItem>
					)}
					{prices?.discount && prices.discount > 0 && (
						<SummaryTableItem
							label="Сумма скидок"
							price={prices.discount}
							priceType="discount"
							defaultExpanded
							withOffset
						>
							{discountsList.map(({ id = 0, price, title }, index) => {
								if (!(title && price)) {
									return null;
								}

								// eslint-disable-next-line react/no-array-index-key
								return <SummaryTableItem key={`${id}-${index}`} label={title} price={price} />;
							})}
						</SummaryTableItem>
					)}
				</SummaryTable>
			</div>

			<Alert design="text-large" type="info-green" iconId="info">
				Окончательный расчет скидок&nbsp;&mdash; после уточнения данных пассажиров
			</Alert>
		</div>
	);
});
