/* eslint-disable import/no-extraneous-dependencies */
import { FocusEvent, HTMLAttributes, InputHTMLAttributes, RefObject, useMemo } from 'react';
import { useCollator } from '@react-aria/i18n';
import { setInteractionModality } from '@react-aria/interactions';
import { useField } from '@react-aria/label';
import { AriaListBoxOptions } from '@react-aria/listbox';
import { useMenuTrigger } from '@react-aria/menu';
import { ListKeyboardDelegate, useTypeSelect } from '@react-aria/selection';
import { chain, filterDOMProps, mergeProps, useId } from '@react-aria/utils';

import type { AriaButtonProps } from '@react-types/button';
import type { AriaSelectProps } from '@react-types/select';
import { useTextField } from 'react-aria';
import type {
	MultiSelectProps as MultiSelectStateProps,
	MultiSelectState,
} from './use-multi-select-state';

type MultiSelectProps<T> = Omit<AriaSelectProps<T>, 'onSelectionChange'> & {
	disallowEmptySelection?: boolean;
	onSelectionChange?: MultiSelectStateProps<T>['onSelectionChange'];
	/** The ref for the input element. */
	inputRef: RefObject<HTMLInputElement>;
};

interface MultiSelectAria<T> {
	/** Props for the label element. */
	labelProps: HTMLAttributes<HTMLElement>;
	/** Props for the combo box input element. */
	inputProps: InputHTMLAttributes<HTMLInputElement>;
	/** Props for the popup trigger element. */
	triggerProps: AriaButtonProps;
	/** Props for the element representing the selected value. */
	valueProps: HTMLAttributes<HTMLElement>;
	/** Props for the popup. */
	menuProps: AriaListBoxOptions<T>;
}

export function useMultiSelect<T>(
	props: MultiSelectProps<T>,
	state: MultiSelectState<T>,
	ref: RefObject<HTMLElement>,
): MultiSelectAria<T> {
	const { disallowEmptySelection, isDisabled, onKeyUp, onFocus, onBlur, onKeyDown, inputRef } =
		props;

	const collator = useCollator({ usage: 'search', sensitivity: 'base' });
	const delegate = useMemo(
		() => new ListKeyboardDelegate(state.collection, state.disabledKeys, null as never, collator),
		[state.collection, state.disabledKeys, collator],
	);

	const { menuTriggerProps, menuProps } = useMenuTrigger(
		{
			isDisabled,
			type: 'listbox',
		},
		state,
		ref,
	);

	const triggerOnKeyDown = (e: KeyboardEvent) => {
		// Select items when trigger has focus - imitating default `<select>` behaviour.
		// In multi selection mode it does not make sense.
		if (state.selectionMode === 'single') {
			switch (e.key) {
				case 'ArrowLeft': {
					// prevent scrolling containers
					e.preventDefault();

					// TODO: включить типизацию. ругается на то, что может быть undefined
					const key =
						state.selectedKeys.size > 0
							? delegate.getKeyAbove(state.selectedKeys.values().next().value as any)
							: delegate.getFirstKey();

					if (key) {
						state.setSelectedKeys([key]);
					}
					break;
				}
				case 'ArrowRight': {
					// prevent scrolling containers
					e.preventDefault();


					// TODO: включить типизацию. ругается на то, что может быть undefined
					const key =
						state.selectedKeys.size > 0
							? delegate.getKeyBelow(state.selectedKeys.values().next().value as any)
							: delegate.getFirstKey();

					if (key) {
						state.setSelectedKeys([key]);
					}
					break;
				}

				// no default
			}
		}
	};

	// Typeahead functionality - imitating default `<select>` behaviour.
	const { typeSelectProps } = useTypeSelect({
		keyboardDelegate: delegate,
		selectionManager: state.selectionManager,
		onTypeSelect(key) {
			state.setSelectedKeys([key]);
		},
	});

	const { labelProps, fieldProps } = useField({
		...props,
		labelElementType: 'span',
	});

	const { inputProps } = useTextField(
		{
			...props,
			autoComplete: 'off',
			value: state.inputValue,
			onChange: state.setInputValue,
			onBlur,
			onFocus,
		},
		inputRef,
	);

	typeSelectProps.onKeyDown = typeSelectProps.onKeyDownCapture;
	delete typeSelectProps.onKeyDownCapture;

	const domProps = filterDOMProps(props, { labelable: true });
	const triggerProps = mergeProps(typeSelectProps, menuTriggerProps, fieldProps);

	const valueId = useId();

	return {
		labelProps: {
			...labelProps,
			onClick: () => {
				if (!isDisabled) {
					ref.current?.focus();

					// Show the focus ring so the user knows where focus went
					setInteractionModality('keyboard');
				}
			},
		},
		triggerProps: mergeProps(domProps, {
			...triggerProps,
			onKeyDown: chain(triggerProps.onKeyDown, triggerOnKeyDown, onKeyDown),
			onKeyUp,
			'aria-labelledby': [
				triggerProps['aria-labelledby'],
				triggerProps['aria-label'] && !triggerProps['aria-labelledby'] ? triggerProps.id : null,
				valueId,
			]
				.filter(Boolean)
				.join(' '),
			onFocus(e: FocusEvent) {
				if (state.isFocused) {
					return;
				}

				if (onFocus) {
					onFocus(e);
				}

				state.setFocused(true);
			},
			onBlur(e: FocusEvent) {
				if (state.isOpen) {
					return;
				}

				if (onBlur) {
					onBlur(e);
				}

				state.setFocused(false);
			},
		}),
		valueProps: {
			id: valueId,
		},
		menuProps: {
			...(menuProps as AriaListBoxOptions<T>),
			disallowEmptySelection,
			autoFocus: state.focusStrategy || true,
			shouldSelectOnPressUp: true,
			shouldFocusOnHover: true,
			onBlur: (e) => {
				if (e.currentTarget.contains(e.relatedTarget as Node)) {
					return;
				}

				if (onBlur) {
					onBlur(e);
				}
				state.setFocused(false);
			},
			// onFocus: menuProps.onFocus as never,
			'aria-labelledby': [
				fieldProps['aria-labelledby'],
				triggerProps['aria-label'] && !fieldProps['aria-labelledby'] ? triggerProps.id : null,
			]
				.filter(Boolean)
				.join(' '),
		},
		inputProps: mergeProps(inputProps, {
			role: 'filter',
			'aria-expanded': menuTriggerProps['aria-expanded'],
			'aria-controls': state.isOpen ? menuProps.id : undefined,
			'aria-autocomplete': 'list',
			// This disable's iOS's autocorrect suggestions, since the combo box provides its own suggestions.
			autoCorrect: 'off',
			// This disable's the macOS Safari spell check auto corrections.
			spellCheck: 'false',
		}),
	};
}
