import { debounce, status } from 'patronum';
import { OverlayTriggerState } from 'react-stately';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { DetailedCabin } from 'config/types/cabins';
import { Photo } from 'config/types/ships';
import { GetDetailedShipCabinRequest, detailedShipCabinRequest } from 'store/cruise/cruises/cabins/index';
// import { detailedShipCabinRequest, GetDetailedShipCabinRequest } from 'shared/api';
// import { DetailedCabin, Photo } from 'shared/lib/types';

const ModalGate = createGate<OverlayTriggerState>();

const getDetailedShipCabinInfo = createEvent<GetDetailedShipCabinRequest>();
const getDetailedShipCabinInfoFx = createEffect(detailedShipCabinRequest.getDetailedShipCabinInfo);
const setOpenFx = createEffect((cb: () => void) => cb());

const $detailedShipCabinInfo = createStore<Nullable<DetailedCabin>>(null);
const $setOpenModal = createStore<Nullable<Pick<OverlayTriggerState, 'setOpen'>>>(null);
const $status = status({ effect: getDetailedShipCabinInfoFx });

const CabinGate = createGate<{ photos: Photo[] }>();
const $cabinPhotos = createStore<Nullable<Photo[]>>(null);

sample({
	clock: CabinGate.state,
	fn: ({ photos }) => photos,
	target: $cabinPhotos,
});

sample({
	clock: ModalGate.open,
	filter: (source) => Boolean(source),
	fn: ({ setOpen }) => ({ setOpen }),
	target: $setOpenModal,
});

sample({
	source: $setOpenModal,
	clock: getDetailedShipCabinInfo,
	fn: (source) => () => source?.setOpen(true),
	target: setOpenFx,
});

sample({
	clock: getDetailedShipCabinInfo,
	target: getDetailedShipCabinInfoFx,
});

sample({
	clock: getDetailedShipCabinInfoFx.doneData,
	source: $cabinPhotos,
	fn: (photos, cabin) => {
		if (!cabin?.photos.length) {
			return { ...cabin, photos } as DetailedCabin;
		}

		return cabin;
	},
	target: $detailedShipCabinInfo,
});

const clearModalData = createEvent<OverlayTriggerState>();

debounce({
	source: ModalGate.state,
	timeout: 1000,
	target: clearModalData,
});

sample({
	clock: clearModalData,
	source: ModalGate.state,
	filter: ({ isOpen }) => !isOpen,
	fn: () => null,
	target: $detailedShipCabinInfo,
});

export const model = {
	ModalGate,
	getDetailedShipCabinInfo,
	$detailedShipCabinInfo,
	$setOpenModal,
	$status,
	CabinGate,
};
