import axios from 'axios';

/**
 * Get status code from axios error
 * @param {Error} error
 * @returns {number}
 */

export const getFailCode = (error: Error) => {
	if (axios.isAxiosError(error) && error.response?.status) {
		return error.response.status;
	}
};

/**
 * Get data from axios error
 * @param {Error} error
 * @returns {object}
 */

export const getFailData = <T extends object = object>(error: Error): T | undefined => {
	if (axios.isAxiosError(error) && error.response?.data) {
		return error.response.data;
	}
};
