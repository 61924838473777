import { useOverlayTriggerState } from 'react-stately';
import { useGate, useUnit } from 'effector-react';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { useViewport } from 'shared/lib/viewport';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { cabinDeleteModel } from '../../model';
import styles from '../empty-alert/styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';
import { useViewport } from 'layout/viewport';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';

export const CabinDeleteSuccess = () => {
	const [closeModal, cabinInfo] = useUnit([
		cabinDeleteModel.closeRemoveModal,
		cabinDeleteModel.$cabinInfo,
	]);
	const state = useOverlayTriggerState({});

	const shouldMount = useDelayUnmount(state.isOpen, 600);
	useGate(cabinDeleteModel.removeModalGate, state);

	const { isMob } = useViewport();

	// return <div>CabinDeleteSuccess</div>
	return shouldMount ? (
		<Modal
			classNameModal={styles.modal}
			classNameModalContent={styles.content}
			closeButtonClassName={styles.close}
			state={state}
			isShown={state.isOpen}
		>
			<Typo design={isMob ? 'headline-xl' : 'headline-m'} className={styles.title}>
				{`Каюта ${cabinInfo?.name}`}
				<br /> удалена&nbsp;из заказа
			</Typo>
			<div className={styles.buttons}>
				<Button onClick={closeModal} size="large">
					Закрыть
				</Button>
			</div>
		</Modal>
	) : null;
};
