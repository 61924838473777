import { combine, createEvent, createStore, sample } from 'effector';
import { Breakpoint } from 'utils/viewport';


export interface ViewportData {
	vw: number;
	vh: number;
	sb: number;
	out: number;
	bp?: Breakpoint;
	isMob?: boolean;
	isTablet?: boolean;
	deviceType?: 'mobile' | 'tablet' | 'desktop';
}

export const $vw = createStore(0);
export const $vh = createStore(0);
export const $sb = createStore(0);
export const $out = createStore(0);
export const $bp = createStore<Nullable<Breakpoint>>(null);
export const $isMob = createStore<Nullable<boolean>>(null);
export const $isTablet = createStore<Nullable<boolean>>(null);
export const $device = createStore<string>('desktop');

export const $viewport = combine({
	vw: $vw,
	vh: $vh,
	sb: $sb,
	out: $out,
	bp: $bp,
	isMob: $isMob,
	isTablet: $isTablet,
	deviceType: $device,
});

const update = createEvent<ViewportData>();

sample({
	clock: update,
	fn: ({ vw }) => vw,
	target: $vw,
});

sample({
	clock: update,
	fn: ({ vh }) => vh,
	target: $vh,
});

sample({
	clock: update,
	fn: ({ sb }) => sb,
	target: $sb,
});

sample({
	clock: update,
	fn: ({ out }) => out,
	target: $out,
});

sample({
	clock: update,
	fn: ({ bp }) => bp || null,
	target: $bp,
});

sample({
	clock: update,
	fn: ({ isMob }) => isMob || false,
	target: $isMob,
});

sample({
	clock: update,
	fn: ({ isTablet }) => isTablet || false,
	target: $isTablet,
});

export const viewportModel = {
	$vw,
	$vh,
	$sb,
	$out,
	$bp,
	$isMob,
	$isTablet,
	$viewport,
	$device,
	update,
};
