import { getFailCode } from 'api';
import axios from 'axios';
import { ANALYTICS_TARGETS } from 'config/constants';
import { message } from 'config/constants/message';
import { RegisterRequest, RegisterResponse } from 'config/types/personal';
import { createEffect, createEvent, createStore, sample, split } from 'effector';
import { createForm } from 'effector-react-form';
import { FormErrorMessageModel } from 'layout/components/inputs/form/errors-block';
import { personalRequests } from 'store/auth/api';
import { verificationModel } from 'utils/verification';


const registerFx = createEffect(personalRequests.register);
const handleOnSubmit = createEvent<Omit<RegisterRequest, 'captcha'>>();

const form = createForm<Omit<RegisterRequest, 'captcha'>>({
	initialValues: {
		firstName: '',
		phone: '',
		lastName: '',
		middleName: '',
		agreeProcessData: true,
		agreeSendMessages: true,
	},
	onSubmit: ({ values }) =>
		handleOnSubmit({
			...values,
			phone: values.phone.replace('+', ''),
		}),
});

const $formErrors = createStore<FormErrorMessageModel[]>([]);

const handleGoodResponse = createEvent<RegisterResponse>();
const handleFieldError = createEvent<Error>();
const handleFormError = createEvent<Error>();

const handleRegisterAnalyticsFx = createEffect(() => ({})
	// setAnalytics(ANALYTICS_TARGETS.signup.signup_sms_sent),
);

sample({
	source: verificationModel.$captchaToken,
	clock: handleOnSubmit,
	fn: (captcha, clock) => ({ captcha, ...clock } as RegisterRequest),
	target: registerFx,
});

sample({
	clock: handleOnSubmit,
	fn: (values) => ({
		type: 'phone' as const,
		value: values.phone,
	}),
	target: verificationModel.$verificationItem,
});

sample({
	source: registerFx.doneData,
	target: handleGoodResponse,
});

split({
	source: registerFx.failData,
	match: (response) => (getFailCode(response) === 400 ? 'field' : 'form'),
	cases: {
		field: handleFieldError,
		form: handleFormError,
	},
});

sample({
	clock: handleFieldError,
	fn: () => ({
		field: 'phone',
		error: message.profile.registration[400],
	}),
	target: form.setOrDeleteError,
});

sample({
	clock: handleFormError,
	fn: (response) => {
		const errors: { code: string; field: string }[] = axios.isAxiosError(response)
			? response.response?.data.errors
			: undefined;

		const errorsMessages = errors
			.map((error) => {
				const messageData = message.profile.registration[error.code];
				const errorMessage =
					typeof messageData === 'object' && !('text' in messageData || 'title' in messageData)
						? (messageData as Record<string, string>)[error.field]
						: messageData;

				return errorMessage;
			})
			.filter(Boolean);

		return errorsMessages.length > 0 ? errorsMessages : [message.profile.registration.AC11];
	},
	target: $formErrors,
});

sample({
	source: $formErrors,
	fn: (errors) => ({
		field: 'formError',
		error: JSON.stringify(errors),
	}),
	target: form.setOrDeleteError,
});

sample({
	clock: handleGoodResponse,
	fn: (response) => response.verifyToken,
	target: verificationModel.$verifyToken,
});

/**
 * Обработка аналитики
 */
sample({
	source: registerFx.doneData,
	target: handleRegisterAnalyticsFx,
});

export const model = {
	form,
	$pending: registerFx.pending,
};
