/* eslint-disable react/no-array-index-key */
import React from 'react';
import classnames from 'classnames';
import { Item } from 'react-stately';
import {
	convertToString,
	getPrice,
} from 'entities/cabin-booking-cards/ui/cabin-description-modal/lib';
// import { getPlaceTypes, ADULT_PLACE_TYPES } from 'shared/config';
// import { TOURISTS_GENITIVE_DECLENSION } from 'shared/lib/constants';
// import { message } from 'shared/lib/message';
// import { DetailedCruiseCabin } from 'shared/lib/types';
// import { getNumDeclension } from 'shared/lib/utils';
// import { Alert, Button, Condition, Price, Skeleton, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { Select } from 'shared/ui/molecules';

import styles from './styles.module.scss';
import { TOURISTS_GENITIVE_DECLENSION } from 'config/constants';
import { message } from 'config/constants/message';
import { DetailedCruiseCabin } from 'config/types/cabins';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Select } from 'layout/components/inputs/selects/select';
import { Skeleton } from 'layout/components/special/skeleton';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { Price } from 'layout/components/view/price';
import { getNumDeclension } from 'utils/string';
import { ADULT_PLACE_TYPES, getPlaceTypes } from 'config/constants/cabin';

export interface PassengerPrice {
	type: number;
	total: number;
}

interface CabinPlacesProps {
	price?: DetailedCruiseCabin.Price;
	places?: DetailedCruiseCabin.Places;
	passengerPrices: PassengerPrice[];
	changeType: (params: { index: number; value: Nullable<string> }) => void;
	totalPlaces: number;
	surcharge?: number;
	className?: string;
	surchargePending?: boolean;
	childrenAge?: number;
	childrenDiscount?: boolean;
}

export const CabinPlaces = ({
	price,
	places,
	passengerPrices,
	changeType,
	totalPlaces,
	surcharge,
	surchargePending,
	childrenAge,
	childrenDiscount,
	className,
}: CabinPlacesProps) => {
	const placeTypes = getPlaceTypes(childrenAge, childrenDiscount);

	return (
		<div className={classnames(styles.places, className)}>
			{passengerPrices.map((passenger, index) => {
				const adultOnly = index === 0;
				const availableTypes = adultOnly ? ADULT_PLACE_TYPES : placeTypes;

				return (
					<div key={`passenger-price-${index}-${passenger.total}-${passenger.type}`}>
						<div className={styles.selectWrapper}>
							<Select
								name="place"
								label={availableTypes[convertToString(passenger.type)]}
								selectedKeys={new Set([convertToString(passenger.type)])}
								isRequired={false}
								customValue={
									<Typo design="headline-s">
										<Price total={passenger.total} bold />
									</Typo>
								}
								className={styles.select}
								classNameTrigger={styles.selectTrigger}
								alwaysShowChevron
								onSelectionChange={(keys) => {
									const keysArray = keys instanceof Set ? ([...keys] as string[]) : [keys];
									const value = keysArray.length ? keysArray[0] : null;
									if (value) {
										changeType({
											index,
											value,
										});
									}
								}}
							>
								{Object.keys(availableTypes).map((key) => (
									<Item key={key}>{availableTypes[key]}</Item>
								))}
							</Select>

							{!!index && (
								<button
									onClick={() =>
										changeType({
											index,
											value: null,
										})
									}
									className={styles.buttonClose}
								>
									<Icon id="close" width={12} height={12} />
								</button>
							)}
						</div>
						{`${passenger.type}` === '2' && (
							<Alert design="text" type="warning" className={styles.alert}>
								<Typo design="text-s">{message.order.passengerTypeDiscount.child}</Typo>
							</Alert>
						)}
						{`${passenger.type}` === '5' && (
							<Alert design="text" type="warning" className={styles.alert}>
								<Typo design="text-s">{message.order.passengerTypeDiscount.pensioner}</Typo>
							</Alert>
						)}
					</div>
				);
			})}

			{totalPlaces > passengerPrices.length && (
				<Button
					className={styles.addPassengerButton}
					btnType="text"
					size="large"
					onClick={() => {
						changeType({
							index: passengerPrices.length,
							value: '0',
						});
					}}
				>
					Добавить туриста
					<Icon id="plus" width={20} height={20} />
				</Button>
			)}

			{!!places?.main && places?.main > passengerPrices.length && (
				<Condition
					value={!surchargePending}
					then={
						// eslint-disable-next-line react/jsx-no-useless-fragment
						<>
							{!!surcharge && (
								<Alert>
									<Typo design="text-s" className={styles.infoMessage}>
										<span>
											{`В каюте ${places.main} места. Доплата за размещение ${
												passengerPrices.length
											} ${getNumDeclension(passengerPrices.length, TOURISTS_GENITIVE_DECLENSION)}
								в каюте – `}
										</span>

										<Typo as="span" design="headline-s" className={styles.infoMessage_price}>
											{getPrice(surcharge)}
											<Icon id="rur-bold" className="currency" />
										</Typo>
									</Typo>
								</Alert>
							)}
						</>
					}
					else={<Skeleton height={70} radius={8} />}
				/>
			)}
		</div>
	);
};
