import { PAGES_ABOUT_CAT_ID, PAGES_COMPANIES_CAT_ID, PAGES_CORPORATE_CAT_ID, PAGES_MAX_PER_PAGE, PAGES_TOURIST_CAT_ID } from 'config/constants/content';
import { PageCard } from 'config/types/posts';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { textPageRequests } from 'store/other/text-page';
// import { textPageRequests } from 'shared/api';
// import {
// 	PAGES_ABOUT_CAT_ID,
// 	PAGES_COMPANIES_CAT_ID,
// 	PAGES_CORPORATE_CAT_ID,
// 	PAGES_MAX_PER_PAGE,
// 	PAGES_TOURIST_CAT_ID,
// } from 'shared/config';
// import { PageCard } from 'shared/lib/types';

const getData = createEvent();
const getFooterMenuFx = createEffect(textPageRequests.getFooterMenu);

const $pageList = createStore<PageCard[]>([]);

const $abousPages = createStore<PageCard[]>([]);
const $touristsPages = createStore<PageCard[]>([]);
const $companiesPages = createStore<PageCard[]>([]);
const $corporatePages = createStore<PageCard[]>([]);

const $abousHeaderPages = createStore<PageCard[]>([]);
const $touristsHeaderPages = createStore<PageCard[]>([]);
const $companiesHeaderPages = createStore<PageCard[]>([]);
const $corporateHeaderPages = createStore<PageCard[]>([]);

sample({
	clock: getData,
	fn: () => ({
		perPage: PAGES_MAX_PER_PAGE,
		sortBy: 'priority' as const,
	}),
	target: getFooterMenuFx,
});

sample({
	clock: getFooterMenuFx.doneData,
	fn: ({ data }) => data ?? [],
	target: $pageList,
});

sample({
	clock: $pageList,
	fn: (data) =>
		data.filter(
			({ category, showInFooter }) => category?.id === PAGES_ABOUT_CAT_ID && showInFooter === true,
		) ?? [],
	target: $abousPages,
});

sample({
	clock: $pageList,
	fn: (data) =>
		data.filter(
			({ category, showInFooter }) =>
				category?.id === PAGES_TOURIST_CAT_ID && showInFooter === true,
		) ?? [],
	target: $touristsPages,
});

sample({
	clock: $pageList,
	fn: (data) =>
		data.filter(
			({ category, showInFooter }) =>
				category?.id === PAGES_COMPANIES_CAT_ID && showInFooter === true,
		) ?? [],
	target: $companiesPages,
});

sample({
	clock: $pageList,
	fn: (data) =>
		data.filter(
			({ category, showInFooter }) =>
				category?.id === PAGES_CORPORATE_CAT_ID && showInFooter === true,
		) ?? [],
	target: $corporatePages,
});

sample({
	clock: $pageList,
	fn: (data) =>
		data.filter(
			({ category, showInHeader }) => category?.id === PAGES_ABOUT_CAT_ID && showInHeader === true,
		) ?? [],
	target: $abousHeaderPages,
});

sample({
	clock: $pageList,
	fn: (data) =>
		data.filter(
			({ category, showInHeader }) =>
				category?.id === PAGES_TOURIST_CAT_ID && showInHeader === true,
		) ?? [],
	target: $touristsHeaderPages,
});

sample({
	clock: $pageList,
	fn: (data) =>
		data.filter(
			({ category, showInHeader }) =>
				category?.id === PAGES_COMPANIES_CAT_ID && showInHeader === true,
		) ?? [],
	target: $companiesHeaderPages,
});

sample({
	clock: $pageList,
	fn: (data) =>
		data.filter(
			({ category, showInHeader }) =>
				category?.id === PAGES_CORPORATE_CAT_ID && showInHeader === true,
		) ?? [],
	target: $corporateHeaderPages,
});

export const model = {
	getData,
	getFooterMenuFx,
	$abousPages,
	$touristsPages,
	$companiesPages,
	$corporatePages,
	$abousHeaderPages,
	$touristsHeaderPages,
	$companiesHeaderPages,
	$corporateHeaderPages,
};
