export const getOffsetRecursively = (
	el: HTMLElement,
	ref?: HTMLElement,
): { top: number; left: number } => {
	let top = 0;
	let left = 0;

	while (el && (ref ? el !== ref : true)) {
		top += Number.parseInt(el.offsetTop.toString());
		left += Number.parseInt(el.offsetLeft.toString());

		el = el.offsetParent as HTMLElement;
	}

	return { top, left };
};
