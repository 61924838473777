import { Declensions } from "config/commonTypes";

export const DAYS_DECLENSION: Declensions = ['день', 'дня', 'дней'];
export const NIGHTS_DECLENSION: Declensions = ['ночь', 'ночи', 'ночей'];
export const HOURS_DECLENSION: Declensions = ['час', 'часа', 'часов'];
export const MINUTES_DECLENSION: Declensions = ['минута', 'минуты', 'минут'];
export const CABINS_DECLENSION: Declensions = ['каюта', 'каюты', 'кают'];
export const CRUISES_DECLENSION: Declensions = ['круиз', 'круиза', 'круизов'];
export const SHIPS_DECLENSION: Declensions = ['теплоход', 'теплохода', 'теплоходов'];
export const SEATS_DECLENSION: Declensions = ['место', 'места', 'мест'];
export const DECKS_DECLENSION: Declensions = ['палуба', 'палубы', 'палуб'];
export const PASSENGERS_DECLENSION: Declensions = ['пассажир', 'пассажира', 'пассажиров'];
export const PASSENGERS_GENITIVE_DECLENSION: Declensions = [
	'пассажира',
	'пассажиров',
	'пассажиров',
];
export const TOURISTS_DECLENSION: Declensions = ['турист', 'туриста', 'туристов'];
export const TOURISTS_GENITIVE_DECLENSION: Declensions = ['туриста', 'туристов', 'туристов'];
export const HUMAN_DECLENSION: Declensions = ['человек', 'человека', 'человек'];
export const NOTIFICATIONS_DECLENSION: Declensions = ['уведомление', 'уведомления', 'уведомлений'];
export const REQUESTS_DECLENSION: Declensions = ['заявка', 'заявки', 'заявок'];
export const REQUESTS_ACCUSATIVE_DECLENSION: Declensions = ['заявку', 'заявки', 'заявок'];
export const NEWS_DECLENSION: Declensions = ['новость', 'новости', 'новостей'];
export const EXCURSIONS_DECLENSION: Declensions = ['экскурсия', 'экскурсии', 'экскурсий'];
export const EXCURSIONS_ACCUSATIVE_DECLENSION: Declensions = [
	'экскурсию',
	'экскурсии',
	'экскурсий',
];
export const FOUND_DECLENSION: Declensions = ['найден', 'найдено', 'найдено'];
export const FOUND_DECLENSION_FEMININE: Declensions = ['найдена', 'найдено', 'найдено'];
export const REST_DECLENSION_FEMININE: Declensions = ['осталась', 'осталось', 'осталось'];
export const VACANT_DECLENSION_FEMININE: Declensions = ['свободна', 'свободны', 'свободно'];
export const AVAILABLE_DECLENSION: Declensions = ['доступен', 'доступны', 'доступно'];
export const AVAILABLE_DECLENSION_FEMININE: Declensions = ['доступна', 'доступны', 'доступно'];
export const BOOKED_DECLENSION: Declensions = ['забронирован', 'забронированы', 'забронировано'];
export const BOOKED_DECLENSION_FEMININE: Declensions = [
	'забронирована',
	'забронированы',
	'забронировано',
];
export const BONUS_DECLENSION: Declensions = ['бонус', 'бонуса', 'бонусов'];
export const DISCOUNT_POINTS: Declensions = ['балл', 'балла', 'баллов'];
export const DISCOUNT_POINTS_ACCUSATIVE_DECLENSION: Declensions = ['балла', 'баллов', 'баллов'];
export const CATEGORIES_DECLENSION: Declensions = ['категория', 'категории', 'категорий'];
export const SUGGESTION_DECLENSION: Declensions = ['предложение', 'предложения', 'предложений'];

export const SECONDS_DECLENSION: Declensions = ['секунда', 'секунды', 'секунд'];
export const SECONDS_ACCUSATIVE_DECLENSION: Declensions = ['секунду', 'секунды', 'секунд'];

export const DAYS_PLURAL = 'дни';
export const NIGHTS_PLURAL = 'ночи';
export const CABINS_PLURAL = 'каюты';
export const CRUISES_PLURAL = 'круизы';
export const SEATS_PLURAL = 'места';
export const DECKS_PLURAL = 'палубы';
export const PASSENGERS_PLURAL = 'пассажиры';
