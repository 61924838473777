import {
	FC,
	PropsWithChildren,
	ReactElement,
	RefObject,
	useRef,
	useState,
	SyntheticEvent,
} from 'react';
import classnames from 'classnames';
import { AriaModalOverlayProps, mergeProps, Overlay, useDialog } from 'react-aria';
import { OverlayTriggerState } from 'react-stately';
// import { Icon, IconId } from 'shared/ui/icons';
// import { Scroller } from '../scroller';
import { useModalOverlay, useModalScrollListeners } from './lib';
import styles from './style.module.scss';
import { IconId } from '../icons/svg-sprite';
import { Icon } from '../icons/icon';
import { Scroller } from '../special/scroller';

export interface ModalProps extends AriaModalOverlayProps {
	state: OverlayTriggerState;
	isShown?: boolean;
	modalRef?: RefObject<HTMLDivElement>;
	className?: string;
	classNameModal?: string;
	classNameModalContent?: string;
	closeButtonClassName?: string;
	classNameFooter?: string;
	classNameHeader?: string;
	scrollerClassName?: string;
	verticalAlign?: 'top' | 'middle' | 'bottom';
	footer?: ReactElement;
	footerShadow?: boolean;
	footerShadowActive?: boolean;
	header?: ReactElement;
	headerShadow?: boolean;
	headerShadowActive?: boolean;
	withCrossButton?: boolean;
	withScroll?: boolean;
	closeIconId?: IconId;
	onClose?: (e: SyntheticEvent<EventTarget>) => void;
	onOverlayClick?(e: SyntheticEvent<EventTarget>): void;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
	state,
	children,
	isShown,
	className,
	classNameModal,
	closeButtonClassName,
	scrollerClassName,
	verticalAlign,
	footer,
	footerShadow,
	footerShadowActive,
	header,
	headerShadow,
	headerShadowActive,
	withCrossButton = true,
	classNameHeader,
	classNameFooter,
	classNameModalContent,
	withScroll,
	closeIconId = 'close',
	onClose,
	onOverlayClick,
	...props
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const wrapRef = useRef<HTMLDivElement>(null);
	const gridRef = useRef<HTMLDivElement>(null);
	const modalRef = props.modalRef || ref;

	const { modalProps, underlayProps } = useModalOverlay(props, state, modalRef);
	const { dialogProps } = useDialog({}, modalRef);

	const [isActive, setIsActive] = useState(false);

	const portal = document.getElementById('portal-root');

	const { reachedEnd } = useModalScrollListeners({
		scrollerRef: wrapRef,
		containerRef: gridRef,
	});

	if (!portal) {
		return null;
	}

	return (
		<Overlay portalContainer={portal}>
			<div
				ref={wrapRef}
				className={classnames(className, 'reactModal', styles.underlay, {
					[styles.isShown]: isShown,
					[styles.isActive]: isActive,
				})}
				{...mergeProps({ onPointerDown: onOverlayClick }, underlayProps)}
			>
				<div ref={gridRef} className={styles.underlay__grid}>
					<div
						className={classnames(styles.underlay__cell, verticalAlign && styles[verticalAlign])}
					>
						<div
							{...mergeProps(modalProps, dialogProps)}
							className={classnames(classNameModal, styles.reactModal)}
							onAnimationEndCapture={() => {
								setIsActive(!!isShown);
							}}
							ref={modalRef}
						>
							{withCrossButton && (
								<button
									type="button"
									aria-label="Закрыть диалоговое окно"
									className={classnames(styles.closeButton, closeButtonClassName)}
									onClick={(e) => (onClose ? onClose(e) : state.close())}
								>
									<Icon id={closeIconId} />
								</button>
							)}
							{header && (
								<div
									className={classnames(
										styles.reactModal__header,
										{ [styles.withShadow]: headerShadow && headerShadowActive },
										classNameHeader,
									)}
								>
									{header}
								</div>
							)}
							<div className={classnames(styles.reactModal__content, classNameModalContent)}>
								{withScroll ? (
									<Scroller className={classnames(styles.modalScroll, scrollerClassName)}>
										{children}
									</Scroller>
								) : (
									children
								)}
							</div>
							{footer && (
								<div
									className={classnames(
										styles.reactModal__footer,
										{ [styles.withShadow]: (footerShadow && !reachedEnd) || footerShadowActive },
										classNameFooter,
									)}
								>
									{footer}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Overlay>
	);
};
