import React from 'react';
import { useOverlayTriggerState } from 'react-stately';
// import Link from 'next/link';
import { useGate, useUnit } from 'effector-react';
// import { detailedRequest } from 'widgets/account/model';
// import { confirmationModel } from 'widgets/order/order-confirmation/model';
// import { CruiseInfo } from 'widgets/order/order-confirmation/ui/cruise-info';
// import { CustomerInfo } from 'widgets/order/order-confirmation/ui/customer-info';
// import { OrderStatus } from 'widgets/order/order-status';
import { cartModel } from 'entities/cart';
import { pricesModel, requestModel, userRoleModel } from 'entities/order/model';
import { RequestCartCounters } from 'entities/order/request-cart-counters';
import { OrderShipInfo } from 'entities/order/request-cruise-details';
// import { AccountRequestsStatuses, AccountRoutes } from 'shared/config';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { useLocalPhones } from 'shared/lib/localization';
// import { dischargeNumber } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Alert, Button, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { Modal } from 'shared/ui/molecules';
// import { requestsStatuses } from 'shared/ui/molecules/request-status/lib';
// import { Print } from 'shared/ui/organisms/print';
import { model } from '../model';
import styles from './styles.module.scss';
import { Link } from '@tanstack/react-router';
import { detailedRequest } from 'entities/account/model';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Modal } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { confirmationModel } from 'layout/layouts/widgets/order/order-confirmation/model';
import { CustomerInfo } from 'layout/layouts/widgets/order/order-confirmation/ui/customer-info';
import { OrderStatus } from 'layout/layouts/widgets/order/order-status';
import { useViewport } from 'layout/viewport';
import { AccountRequestsStatuses, AccountRoutes } from 'store/auth/constants';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';
import { dischargeNumber } from 'utils/number';
import { requestsStatuses } from 'layout/components/view/request-status/lib';
import { useLocalPhones } from 'utils/hooks/localization';
import { Print } from 'layout/components/special/print';
import { CruiseInfo } from 'layout/layouts/widgets/order/order-confirmation/ui/cruise-info';

interface SuccessModalProps {
	status: AccountRequestsStatuses;
	number?: string;
}

export const SuccessModal = ({ status, number }: SuccessModalProps) => {
	const { isMob } = useViewport();
	const state = useOverlayTriggerState({});
	const shouldMount = useDelayUnmount(state.isOpen, 600);
	const [price, closeModal, isGuest, reset, requestResponse, openDoc, docUrl, docStatus, docFails] =
		useUnit([
			pricesModel.$pricesPreview,
			confirmationModel.closeModal,
			userRoleModel.$isGuest,
			cartModel.resetWithDelay,
			requestModel.$requestResponse,
			detailedRequest.openDoc,
			detailedRequest.$openDocUrl,
			detailedRequest.$openDocStatus,
			detailedRequest.$openDocFails,
		]);

	useGate(model.ModalGate, state);

	const statusName = requestsStatuses.find(({ id }) => id === status)?.label;
	const failCode = requestResponse?.confirmation && docFails[requestResponse.confirmation];

	const localPhones = useLocalPhones();

	return shouldMount ? (
		<Modal
			verticalAlign="bottom"
			className={styles.modalWrap}
			classNameModal={styles.modal}
			closeButtonClassName={styles.modalCloseBtn}
			state={state}
			isShown={state.isOpen}
			onClose={closeModal}
		>
			<Typo className={styles.title} design="headline-xl">
				Бронирование завершено
			</Typo>
			<div className={styles.content}>
				<div className={styles.mainInfo}>
					<div>
						<Typo design="text-m">
							Поздравляем!{' '}
							<Typo as="span" design="headline-s">
								Заявка {number ? `№${number}` : ''}
							</Typo>{' '}
							успешно создана.
						</Typo>
						<Typo design="text-m" className={styles.mainInfo__status}>
							Статус заявки{' '}
							<Typo as="span" design="headline-s">
								{statusName}
							</Typo>
						</Typo>
						<Typo design="text-m">
							{isGuest ? (
								'Наши сотрудники свяжутся с вами по деталям заявки в самое ближайшее время.'
							) : (
								<>
									Вы можете продолжить оформление, добавить доп. услуги, отслеживать статус и
									оплатить заявку в{' '}
									<Link href={AccountRoutes.MyRequestsActive}>
										<a className={styles.redirect} onClick={reset}>
											Личном кабинете.
										</a>
									</Link>
								</>
							)}
						</Typo>
					</div>

					<Alert className={styles.alert} type="warning">
						<Typo design="input-text-s">
							Срок оплаты забронированного тура составляет в среднем 3-5 дней после подтверждения
							заявки и зависит от выбранного Вами круиза. Время подтверждения заявки 1-3 рабочих
							дня.
						</Typo>
					</Alert>

					{isGuest ? (
						<Typo design="text-m">
							Информация о заявке отправлена <br />
							по вашим контактным данным
						</Typo>
					) : (
						<Typo design="text-m">Информация о заявке отправлена по вашим контактным данным.</Typo>
					)}
				</div>
				{requestResponse?.confirmation && (
					<Button
						size="large"
						btnType="text"
						onClick={() => openDoc(requestResponse.confirmation ?? '')}
						loading={docStatus === 'pending'}
						disabled={failCode === 404}
					>
						{(docStatus !== 'pending' || requestResponse.confirmation !== docUrl) && (
							<Icon id="pdf" width={20} height={20} />
						)}
						Детали заявки
					</Button>
				)}
				{!requestResponse?.confirmation && (
					<Print
						trigger={
							<>
								<Icon id="pdf" width={20} height={20} /> Детали заявки
							</>
						}
						modalClose={isMob ? state.close : undefined}
						handleClose={isMob ? closeModal : undefined}
					>
						<div className={styles.printWrapper}>
							<Typo design="headline-l">{`Заявка на бронирование №${number}`}</Typo>
							<CustomerInfo />
							<OrderStatus statusName={statusName} />
							<CruiseInfo defaultExpandedRoute>
								<OrderShipInfo sizeVariant="large" />
							</CruiseInfo>
							<RequestCartCounters />
							{price?.total && (
								<Typo design="headline-m">
									<span className={styles.total}>Итого</span> {dischargeNumber(price.total)} ₽
								</Typo>
							)}
						</div>
					</Print>
				)}

				<div className={styles.phone}>
					<Typo design="text-m">
						В случае возникновения вопросов, звоните по бесплатному телефону:
					</Typo>
					<Typo design="headline-m">{localPhones[0].value}</Typo>
				</div>
			</div>
		</Modal>
	) : null;
};
