import React, { FC } from 'react';
import { useUnit } from 'effector-react';
// import { loadProfileModel } from 'features/load-profile';
// import { profileModel } from 'entities/profile';
// import { AccountRoutes } from 'shared/config';
// import { Button, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { loadProfileModel } from 'layout/components/auth/load-profile';
import { profileModel } from 'layout/components/auth/profile';
import { Button } from 'layout/components/buttons/button';
import { Typo } from 'layout/components/typo/ui';
import { AccountRoutes } from 'store/auth/constants';

interface ProfileHeadProps {
	title?: string;
}

export const ProfileHead: FC<ProfileHeadProps> = ({ title }) => {
	const [profile, status] = useUnit([profileModel.$profile, loadProfileModel.$status]);
	const hasPassportData =
		Boolean(profile?.passportData?.firstName && profile?.passportData?.lastName) ||
		status === 'pending';

	return (
		<header className={styles.profile__header}>
			<Typo className={styles.profile__headerMain} design="text-m">
				<Typo as="h1" design="headline-l">
					{title}
				</Typo>
				{!hasPassportData && (
					<p>Заполните данные профиля в разделе &laquo;Личные&nbsp;данные&raquo;</p>
				)}
			</Typo>
			{!hasPassportData && (
				<Button
					size="large"
					btnType="outline"
					href={AccountRoutes.PersonalData}
					className={styles.profile__headerButton}
				>
					Заполнить личные данные
				</Button>
			)}
		</header>
	);
};
