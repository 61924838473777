import { LinkType, PhoneType, SocialLinkType } from "config/commonTypes";

export const OLD_INFOFLOT_URL = 'https://old.infoflot.com/';

enum LINKS {
	Vk = 'https://vk.com/infoflot',
	Tg = 'https://t.me/infoflotinfo',
	Whatsapp = 'https://api.whatsapp.com/send/?phone=79919862365&text&type=phone_number&app_absent=0',
	Ok = 'https://ok.ru/infoflot?roistat_visit=8373109',
	Youtube = 'https://www.youtube.com/channel/UCFp_p-7nqnL1jSAoWJlnnQA',
}

export const PHONES: PhoneType[] = [
	{
		label: 'Для туристов',
		value: '8 (800) 707-44-18',
		type: 'for-tourists',
	},
	{
		label: 'Для турагентств',
		value: '8 (800) 707-50-35',
		type: 'for-travel-agencies',
	},
];

export const BELARUS_PHONES: PhoneType[] = [
	{
		label: 'Для туристов',
		value: '+375291297115',
		type: 'for-tourists',
	},
	{
		label: 'Для турагентств',
		value: '+375291297115',
		type: 'for-travel-agencies',
	},
];

export const BONUS_PROGRAMM_PHONE = '8 (495) 129-11-02';

export const KLK_PHONE = '8(800)301-91-22';
export const KLK_MAIL = 'bonus@infoflot.com';
export const KLK_RULES_LINK = '/bonus/rules';

export const SOCIAL_LINKS: SocialLinkType[] = [
	{
		icon: 'social-tg',
		value: LINKS.Tg,
		name: 'telegram',
	},
	{
		icon: 'social-vk',
		value: LINKS.Vk,
		name: 'vk',
	},
	{
		icon: 'social-ok',
		value: LINKS.Ok,
		name: 'ok',
	},
	{
		icon: 'social-yt',
		value: LINKS.Youtube,
		name: 'youtube',
	},
];

export const HEADER_LINKS: SocialLinkType[] = [
	{
		icon: 'whatsapp-header',
		value: LINKS.Whatsapp,
		name: 'whatsapp',
	},
	{
		icon: 'telegram-header',
		value: LINKS.Tg,
		name: 'telegram',
	},
	{
		icon: 'vk-header',
		value: LINKS.Vk,
		name: 'vk',
	},
	{
		icon: 'ok-header',
		value: LINKS.Ok,
		name: 'ok',
	},
];
export const FOOTER_LINKS: LinkType[] = [
	{
		href: '/quality-control',
		label: 'Служба контроля качества',
	},
];
