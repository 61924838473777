import React from 'react'
import { model } from '../model';
import { Form } from 'layout/components/inputs/form/form';
import { encodePhone } from 'utils/string';
import { Typo } from 'layout/components/typo/ui';
import { SMS_VERIFICATION_RESEND_TIMEOUT, SMS_VERIFICATION_RESEND_TIMEOUT_TEXT } from 'config/commonConstants';
import { Countdown } from 'layout/components/special/countdown';
import { Button } from 'layout/components/buttons/button';
import { FormField } from 'layout/components/inputs/form/field';
import { Input } from 'layout/components/inputs/input';
import { useUnit } from 'effector-react';
import { useError, useForm } from 'effector-react-form';
import { verificationModel } from 'utils/verification';

export const VerificationPhoneForm = () => {
	const { form, resend, $verifyPending, $resendPending } = model;
	const [verifyPending, resendPending] = useUnit([$verifyPending, $resendPending]);
	const { controller, handleSubmit } = useForm({ form });
	const { error, isShowError } = useError({
		form,
		name: 'formError',
	});
	const phone = useUnit(verificationModel.$encodedVerificationItemValue);
	console.log('error verification-phone-form', isShowError ? 'true' : 'false', error,)
	const onClickResend = useUnit(resend);

	const handleClickResend = () => {
		onClickResend();
		// setAnalytics(ANALYTICS_TARGETS.signup.signup_new_code_request);
	};

	return (
		<Form
			title="Введите код"
			error={error}
			isShowError={isShowError}
			text={
				<>
					Вам был отправлен код подтверждения{' '}
					{phone && (
						<>
							на номер <Typo design="headline-s">{encodePhone(`+${phone}`)}</Typo>
						</>
					)}
				</>
			}
			buttonContent="Продолжить"
			pending={verifyPending}
			handleSubmit={handleSubmit}
			footer={
				<Countdown
					timeout={SMS_VERIFICATION_RESEND_TIMEOUT}
					text={SMS_VERIFICATION_RESEND_TIMEOUT_TEXT}
					design="input-text-m"
				>
					<Button loading={resendPending} onClick={handleClickResend} size="large" btnType="text">
						Получить новый код
					</Button>
				</Countdown>
			}
		>
			<FormField
				maxLength={250}
				placeholder="Код из SMS"
				size="lg"
				withLabel
				type="password"
				required
				controller={controller}
				form={form}
				name="code"
				validation={{ required: true }}
			>
				<Input />
			</FormField>
		</Form>
	);
};
