/* eslint-disable import/no-extraneous-dependencies */
import { Key, useMemo } from 'react';
import { ListState, ListCollection, useListState } from '@react-stately/list';
// import { useControlledState } from '@react-stately/utils';
import { Collection, CollectionBase, MultipleSelection, Node } from '@react-types/shared';
import { useControlledState } from '@react-stately/utils';

export interface MultiSelectListProps<T> extends CollectionBase<T>, MultipleSelection {
	/** The filter function used to determine if a option should be included in the combo box list. */
	defaultFilter?: FilterFn;
	/** The value of the ComboBox input (controlled). */
	inputValue?: string;
	/** The default value of the ComboBox input (uncontrolled). */
	defaultInputValue?: string;
	/** Handler that is called when the ComboBox input value changes. */
	onInputChange?: (value: string) => void;
}

export interface MultiSelectListState<T> extends ListState<T> {
	/** An original (non filtered) collection of items in the list. */
	originalCollection: Collection<Node<T>>;
	/** The keys for the currently selected items. */
	selectedKeys: Set<Key>;
	/** Sets the selected keys. */
	setSelectedKeys(keys: Iterable<Key>): void;
	/** The value of the currently selected items. */
	selectedItems: Node<T>[] | null;
	/** The type of selection. */
	selectionMode: MultipleSelection['selectionMode'];
	/** The current value of the combo box input. */
	inputValue: string;
	/** Sets the value of the combo box input. */
	setInputValue(value: string): void;
}

export type FilterFn = (textValue: string, inputValue: string) => boolean;

export function useMultiSelectListState<T extends {}>(
	props: MultiSelectListProps<T>,
): MultiSelectListState<T> {
	const { items, defaultFilter, defaultInputValue, onInputChange } = props;
	const {
		collection,
		disabledKeys,
		selectionManager,
		selectionManager: { setSelectedKeys, selectedKeys, selectionMode },
	} = useListState(props);

	const [inputValue, setInputValue] = useControlledState(
		props.inputValue,
		defaultInputValue ?? null,
		(value) => {
			if (onInputChange && value) {
				onInputChange(value);
			}
		},
	);

	const filteredCollection = useMemo(() => {
		if (items != null || !defaultFilter) {
			return collection;
		}

		if (!inputValue || inputValue.length < 2) {
			return collection;
		}

		return filterCollection(collection, inputValue, defaultFilter);
	}, [items, defaultFilter, collection, inputValue]);

	const missingKeys: Key[] = [];

	const selectedItems: Node<T>[] | null =
		selectedKeys.size !== 0
			? [...selectedKeys]
					.map((key) => {
						// !TODO: тут добавлен "!", но это может привести к ошибке. надо проверять. 
						const item = collection.getItem(key)!;

						if (!item) {
							missingKeys.push(key);
						}

						return item;
					})
					// Remove undefined values when some keys are not present in the collection
					.filter(Boolean)
			: null;
//Node<T>[] | null;
	if (missingKeys.length) {
		// eslint-disable-next-line no-console
		console.warn(
			`Select: Keys "${missingKeys.join(
				', ',
			)}" passed to "selectedKeys" are not present in the collection.`,
		);
	}

	return {
		collection: filteredCollection,
		originalCollection: collection,
		disabledKeys,
		selectionManager,
		selectionMode,
		selectedKeys,
		setSelectedKeys: setSelectedKeys.bind(selectionManager),
		selectedItems,
		inputValue: inputValue ?? '',
		setInputValue,
	};
}

function filterCollection<T extends object>(
	collection: Collection<Node<T>>,
	inputValue: string,
	filter: FilterFn,
): Collection<Node<T>> {
	return new ListCollection(filterNodes(collection, inputValue, filter));
}

function filterNodes<T>(
	nodes: Iterable<Node<T>>,
	inputValue: string,
	filter: FilterFn,
	excludeKey?: Key,
): Iterable<Node<T>> {
	const filteredNode = [];

	for (const node of nodes) {
		if (node.type === 'section' && node.hasChildNodes) {
			const sectionLabel = node['aria-label'];

			if (sectionLabel && filter(sectionLabel, inputValue)) {
				filteredNode.push({ ...node });
			} else {
				const filtered = filterNodes(node.childNodes, inputValue, filter, node.nextKey ?? undefined);
				if ([...filtered].length > 1) {
					filteredNode.push({ ...node, childNodes: filtered });
				}
			}
		} else if (
			node.type !== 'section' &&
			(filter(node.textValue, inputValue) || node.key === excludeKey)
		) {
			filteredNode.push({ ...node });
		}
	}

	return filteredNode;
}
