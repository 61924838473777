export const CONTENT_PAGE_ENDPOINT = '/api/content/v1/pages';
export const CONTENT_PAGE_ENABLE_MOCK = false;
export const CONTENT_PAGE_BREADCRUMBS = [{ text: 'Главная', href: '/' }];

export const PAGES_MAX_PER_PAGE = 100;

export const PAGES_ABOUT_CAT_ID = 1;
export const PAGES_TOURIST_CAT_ID = 2;
export const PAGES_COMPANIES_CAT_ID = 4;
export const PAGES_CORPORATE_CAT_ID = 6;

export const FOOTER_MENU_PAGES_CAT_IDS = '1,2,4,6';
