import { OverlayTriggerState } from 'react-stately';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { resetJWT } from 'api';
import { personalRequests } from 'store/auth/api';
import { profileModel } from '../profile';
import { errorModel } from 'utils/error';
import { routerModel } from 'utils/router';
// import { cartModel } from 'entities/cart';
// import { errorModel } from 'entities/error';
// import { profileModel } from 'entities/profile';
// import { personalRequests } from 'shared/api';
// import { resetJWT } from 'shared/lib/utils';
// import { routerModel } from 'shared/model';

const Gate = createGate<{ state: OverlayTriggerState }>();
const showModal = createEvent();
const closeModal = createEvent();
const $modalVisible = createStore<boolean>(false);
const $modalState = createStore<Nullable<OverlayTriggerState>>(null);
const $modalIsOpen = $modalState.map((state) => (state ? state.isOpen : null));

const logout = createEvent();
const clearLocalStorageFx = createEffect(resetJWT);
const logoutFx = createEffect(personalRequests.logout);

sample({
	source: Gate.state,
	fn: ({ state }) => state,
	target: $modalState,
});

sample({
	source: $modalState,
	clock: $modalVisible,
	fn: (source, clock) => ({
		setOpen: source.setOpen,
		visible: clock,
	}),
	filter: Boolean,
	target: createEffect(
		({ setOpen, visible }: { setOpen: (open: boolean) => void; visible: boolean }) => {
			setOpen(visible);
		},
	),
});

sample({
	clock: $modalIsOpen,
	filter: (clock) => clock === false,
	target: closeModal,
});

sample({
	clock: showModal,
	fn: () => true,
	target: $modalVisible,
});

sample({
	clock: closeModal,
	fn: () => false,
	target: $modalVisible,
});

sample({
	clock: logout,
	target: [logoutFx, closeModal],
});

sample({
	clock: logoutFx.doneData,
	fn: () => null,
	target: [profileModel.$accessToken, profileModel.$profile, clearLocalStorageFx],
});


// !TODO: вернуть как было! (добавить cartModel чтобы этот участок кода можно было раскомментить)
// sample({
// 	clock: logoutFx.doneData,
// 	target: [errorModel.reset, cartModel.reset],
// });

sample({
	clock: logoutFx.doneData,
	source: routerModel.$router,
	// filter: (router) => !!router && router.pathname.includes('order'),
	filter: (router) => !!router && router?.router?.state?.location?.pathname?.includes('order'),
	fn: () => '/',
	target: routerModel.push,
});

sample({
	clock: logoutFx.failData,
	fn: () => 'logout-fail' as const,
	target: errorModel.setError,
});

export const model = {
	showModal,
	closeModal,
	$modalVisible,
	logout,
	Gate,
	logoutFx,
	$pending: logoutFx.pending,
};
