import { Fragment } from 'react';
import classNames from 'classnames';
// import { usePriceFormatContext } from 'shared/lib/contexts/price-format-context';
// import { usePopover } from 'shared/lib/hooks';
// import { CurrencyType } from 'shared/lib/types';
// import { dischargeNumber, getCurrency } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Typo, TypoDesignToken, TypoTagNameType } from 'shared/ui/atoms/typo';
// import { Icon } from 'shared/ui/icons';
// import { Modal } from 'shared/ui/molecules';
// import { Button } from '../button';
// import { Popover } from '../popover';
// import { QuestionButton } from '../question-button';
import styles from './styles.module.scss';
import { Typo, TypoDesignToken, TypoTagNameType } from 'layout/components/typo/ui';
import { usePopover } from 'utils/hooks/use-popover';
import { useViewport } from 'layout/viewport';
import { dischargeNumber } from 'utils/number';
import { Icon } from 'layout/components/icons/icon';
import { Popover } from 'layout/components/special/popover';
import { Modal } from 'layout/components/modal';
import { Button } from 'layout/components/buttons/button';
import { usePriceFormatContext } from 'config/contexts/price-format-context';
import { getCurrency } from 'utils/currency';
import { QuestionButton } from 'layout/components/buttons/question-button';
import { CurrencyType } from 'config/types/cruise/models/cruise';

interface PriceProps {
	total: number;
	bold?: boolean;
	className?: string;
	design?: TypoDesignToken;
	as?: TypoTagNameType;
	trunc?: number;
	freezeDesktop?: boolean;
	hideCurrency?: boolean;
	currency?: CurrencyType;
	withInfo?: boolean;
}

// eslint-disable-next-line complexity
export const Price = ({
	total,
	bold = false,
	trunc: propsTrunc,
	as,
	design,
	freezeDesktop,
	hideCurrency = false,
	className,
	currency,
	withInfo = false,
}: PriceProps) => {
	const { triggerRef, popoverRef, overlayProps, hoverProps, focusProps, shouldMount, state } =
		usePopover({});
	const { isMob } = useViewport();

	const desktopProps = !isMob && withInfo ? { ...hoverProps, ...focusProps } : {};
	const mobileProps = isMob && withInfo ? { onClick: state.open } : {};
	const defaultTag = className ? 'div' : Fragment;
	const Tag = as || defaultTag;

	const tagProps = className ? { className } : {};

	const priceFormat = usePriceFormatContext();
	const trunc = propsTrunc ?? priceFormat?.trunc ?? 0;

	if (!design) {
		return (
			<Tag {...tagProps}>
				{dischargeNumber(total, ' ', trunc)}
				{!hideCurrency && (
					<Icon id={getCurrency(bold ? 'rur-bold' : 'rur', currency)} className="currency" />
				)}
			</Tag>
		);
	}

	return (
		<>
			<Typo
				as={as}
				design={design}
				className={classNames(className, styles.mainPrice)}
				freezeDesktop={freezeDesktop}
				{...desktopProps}
				{...mobileProps}
			>
				{dischargeNumber(total, ' ', trunc)}
				{!hideCurrency && (
					<Icon id={getCurrency(bold ? 'rur-bold' : 'rur', currency)} className="currency" />
				)}
				{withInfo && <QuestionButton className={styles.questionButton} ref={triggerRef} />}
				{!isMob && shouldMount && withInfo && (
					<Popover
						isNonModal
						placement="bottom"
						state={state}
						triggerRef={triggerRef}
						offset={10}
						popoverRef={popoverRef}
						className={classNames(styles.popover, styles.textStyles, {
							[styles.isActive]: state.isOpen,
						})}
						{...overlayProps}
					>
						<Typo design="text-s">
							Стоимость указана для одного человека при размещении на основном месте. Окончательная
							цена, в том числе с учетом применения скидки, будет указана после выбора каюты в
							процессе бронирования.
						</Typo>
					</Popover>
				)}
			</Typo>
			{isMob && shouldMount && withInfo && (
				<Modal
					verticalAlign="bottom"
					state={state}
					isShown={state.isOpen}
					className={styles.modalWrap}
					classNameModalContent={styles.reactModal}
					isDismissable
				>
					<Typo design="text-m">
						Стоимость указана для одного человека при размещении на основном месте. Окончательная
						цена, в том числе с учетом применения скидки, будет указана после выбора каюты в
						процессе бронирования.
					</Typo>
					<Button onClick={state.toggle} size="large" className={styles.modalButton}>
						Закрыть
					</Button>
				</Modal>
			)}
		</>
	);
};
