/* eslint-disable react/no-array-index-key */
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { dischargeNumber } from 'utils/number';
import styles from './styles.module.scss';
// import { dischargeNumber } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';

export interface DiscountProps {
	amount?: number;
	title?: string;
	discount?: string;
	id?: number;
	passenger?: string;
}

export interface DiscountsBlockProps {
	selectedDiscounts: DiscountProps[];
}

export const DiscountsBlock = ({ selectedDiscounts }: DiscountsBlockProps) => {
	if (!selectedDiscounts.length) {
		return null;
	}

	const newDiscounts = [...new Set(selectedDiscounts.map((discount) => discount.passenger))].map(
		(passenger) => {
			const discounts = selectedDiscounts.filter((item) => item.passenger === passenger);
			const total = discounts.reduce((acc, currentValue) => acc + (currentValue.amount || 0), 0);

			return {
				passenger,
				total,
				discounts,
			};
		},
	);

	return (
		<>
			{newDiscounts.map(({ passenger, total, discounts }) => (
				<div className={styles.discount}>
					<div className={styles.discount__header}>
						<Typo design="headline-s">{passenger}</Typo>
						<Typo className={styles.greenText} design="headline-s">
							- {dischargeNumber(total, ' ', 2)} <Icon id="rur-bold" className="currency" />
						</Typo>
					</div>

					{discounts.map(({ discount, amount, title, id }, index) => {
						if (!amount || !discount || !title) {
							return null;
						}

						return (
							<div key={`${passenger}-${id}-${index}`} className={styles.discount__row}>
								<Typo className={styles.greenText} design="headline-s">
									{discount}%
								</Typo>
								<Typo design="text-s">{title}</Typo>
							</div>
						);
					})}
				</div>
			))}
		</>
	);
};
