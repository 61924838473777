import React, { FC } from 'react';
// import Image from 'next/image';
// import Link from 'next/link';
// import { ShortNews } from 'shared/lib/types';
// import { formatDate } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { ShortNews } from 'config/types/news';
import { Link } from '@tanstack/react-router';
import { Typo } from 'layout/components/typo/ui';
import { formatDate } from 'utils/date';

interface PromoCardProps extends ShortNews {
	isSmallCard?: boolean;
	showOnlyTitle?: boolean;
}

export const PromoCard: FC<PromoCardProps> = React.memo(
	({
		id,
		cardTitle,
		cardDescription,
		image,
		publishStartDate,
		link,
		showOnlyTitle,
		isSmallCard,
	}) => (
		<Link href={link || `/news/${id}`}>
			<a className={styles.promoWrap}>
				<div className={styles.promoImage}>
					{image?.src && (
						<img src={image.src} alt={image.alt}
						// objectFit="cover" layout="fill" quality={90} 
						/>
					)}
				</div>
				<div className={styles.promoInfo}>
					{!isSmallCard && !showOnlyTitle && (
						<Typo design="button-m-s" className={styles.promoPublishedData}>
							{formatDate(publishStartDate, 'DD MMMM YYYY')}
						</Typo>
					)}

					<Typo design="headline-s">{cardTitle}</Typo>

					{!showOnlyTitle && cardDescription && (
						<Typo design={isSmallCard ? 'text-s' : 'button-m-s'} className={styles.promoInfoDesc}>
							{cardDescription}
						</Typo>
					)}
				</div>
			</a>
		</Link>
	),
);

export const PromoCardSmall: FC<PromoCardProps> = React.memo((props) => (
	<PromoCard {...props} isSmallCard />
));
