export const TIMETABLES_NONAME_COUNTRY_ID = 244;
export const CRUISE_CARD_FEW_CABINS_LIMIT = 10;

export const EXTRAORDINARY_CRUISES_CATEGORY = 1;
export const EXTRAORDINARY_CRUISES_SUBCATEGORY = 12;
export const GENERAL_EXTRAORDINARY_NEWS = 320;

export const CRUISES_ENDPOINT = '/api/content/v1/cruises';

export const CRUISES_BREADCRUMBS = [
	{ text: 'Главная', href: '/' },
	{ text: 'Новости', href: '/news' },
];
