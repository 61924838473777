import { Link } from '@tanstack/react-router'
import React, { FC, PropsWithChildren } from 'react'

interface ILinkWrapperProps {
    asA?: boolean
    href?: string;
    target?: string;
}

const LinkWrapper: FC<PropsWithChildren<ILinkWrapperProps>> = (props) => {
    return (
        props.asA
            ? <a href={props.href} target={props.target}>
                {props.children ?? null}
            </a>
            : <Link to={props.href} target={props.target}>
                {props.children ?? null}
            </Link>
    )
}

export default LinkWrapper