import React, { HTMLAttributes, MouseEventHandler } from 'react';
import classnames from 'classnames';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';

export interface CartButtonProps extends HTMLAttributes<HTMLButtonElement> {
	status: string;
	isAdded?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined//() => void;
	fullView?: boolean;
	changeIconOnHover?: boolean;
	disabled?: boolean;
}

export const CartButton = ({
	status,
	onClick,
	className,
	fullView = false,
	isAdded = false,
	changeIconOnHover = true,
	disabled,
}: CartButtonProps) => (
	<button
		onClick={onClick}
		className={classnames(
			styles.cartButton,
			styles[isAdded ? 'added' : status],
			fullView && styles.cartButton_full,
			className,
		)}
		disabled={disabled}
	>
		<Icon
			className={classnames(changeIconOnHover && styles.icon)}
			id={isAdded ? 'check' : 'cart'}
			width={20}
			height={20}
			style={{ marginRight: '5px' }}
		/>
		{changeIconOnHover && (
			<div className={styles.iconHover}>
				<Icon id="closer" width={20} height={20}
					style={{ marginRight: '5px' }}
				/>
			</div>
		)}
		{/* {fullView && (isAdded ? 'Каюта добавлена в заказ' : 'Добавить в заказ')} */}
		{fullView && (isAdded ? 'Каюта добавлена в заказ' : 'Бронировать сейчас')}
	</button>
);
