export const MEAL_TYPES = [
	{
		id: 0,
		name: 'Без питания',
	},
	{
		id: 1,
		name: 'Завтрак',
	},
	{
		id: 2,
		name: 'Обед',
	},
	{
		id: 3,
		name: 'Ужин',
	},
	{
		id: 4,
		name: '2-х разовое (завтрак+обед)',
	},
	{
		id: 5,
		name: '2-х разовое (завтрак+ужин)',
	},
	{
		id: 6,
		name: '2-х разовое (обед+ужин)',
	},
	{
		id: 7,
		name: '3-х разовое',
	},
	{
		id: 8,
		name: '4-х разовое',
	},
	{
		id: 9,
		name: 'Банкет',
	},
	{
		id: 10,
		name: 'Включено',
	},
];

export const PLACES = [
	{
		id: 0,
		name: 'Основное нижнее',
	},
	{
		id: 1,
		name: 'Основное верхнее',
	},
	{
		id: 2,
		name: 'Дополнительное нижнее',
	},
	{
		id: 3,
		name: 'Дополнительное верхнее',
	},
];

export const PLACE_TYPES = [
	{
		id: 0,
		name: 'Взрослый',
	},
	{
		id: 1,
		name: 'Взрослый + ребенок без места',
	},
	{
		id: 2,
		name: 'Ребенок от 0 до 15',
	},
	{
		id: 3,
		name: 'Свободно',
	},
	{
		id: 4,
		name: 'Одноместное размещение',
	},
];

export const GENDER = [
	{
		id: 1,
		name: 'Мужской',
	},
	{
		id: 2,
		name: 'Женский',
	},
];
