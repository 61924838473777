import { combineEvents, delay } from 'patronum';
import { createEvent, createStore, sample } from 'effector';
// import { ecommerceRequests } from 'shared/api';
// import { CURRENCY } from 'shared/config/currency';
// import { GetPricePreview200Response } from 'shared/lib/types';
// import { getFilledEcommerceObject } from 'shared/lib/utils';
// import { modalFactory } from 'shared/model';
import { CartItem, model as cartModel } from './cart-model';
import { getFilledEcommerceObject } from 'utils/analytics/ecommerce';
import { modalFactory } from 'layout/components/modal';
import { GetPricePreview200Response } from 'config/types/prices';
import { ecommerceRequests } from 'store/cruise/ecommerce';

const { ModalGate, openModal, closeModal } = modalFactory();

const openCabinModal = createEvent<Pick<CartItem, 'id' | 'name'>>();
const resetCabinId = createEvent();
const $cabinInfo = createStore<Nullable<Pick<CartItem, 'id' | 'name'>>>(null).reset(resetCabinId);
const removeFromCartWithConfirm = createEvent();

sample({
	clock: openCabinModal,
	target: [$cabinInfo, openModal],
});

const ecommerce = async ({
	cart,
	total,
}: {
	cart: CartItem[];
	total: Nullable<GetPricePreview200Response>;
}) => {
	const info = await ecommerceRequests.getCruiseInfo({ cruiseId: cart[0].cruiseId.toString() });

	// @ts-ignore
	window.dataLayer.push(
		getFilledEcommerceObject({
			ecommerce: {
				// @ts-ignore
				currencyCode: CURRENCY[info.currency ?? 1].toUpperCase(),
				remove: {
					products: [
						{
							id: cart[0].cruiseId?.toString(),
							name: info.name,
							price: total?.total,
							brand: info.brand,
							category: info.category,
							variant: info.variant,
						},
					],
				},
			},
		}),
	);
};

sample({
	source: {
		cart: cartModel.$cart,
		cabin: $cabinInfo,
		total: cartModel.$totalPrice,
	},
	// TODO: венруть типизацию
	clock: removeFromCartWithConfirm,
	fn: ({ cart, cabin, total }: any) => {
		const newCart = cart.filter((place: any) => place.id !== cabin?.id);
		if (!newCart.length) {
			ecommerce({
				cart,
				total,
			});
		}

		return newCart;
	},
	target: [cartModel.$cart, cartModel.getCartPricesWithUpdates, closeModal],
} as any);

const {
	ModalGate: removeModalGate,
	openModal: openRemoveModal,
	closeModal: closeRemoveModal,
} = modalFactory();

const {
	ModalGate: removeAllModalGate,
	openModal: openRemoveAllModal,
	closeModal: closeRemoveAllModal,
} = modalFactory();

sample({
	source: {
		cart: cartModel.$cart,
		cabin: $cabinInfo,
	},
	clock: removeFromCartWithConfirm,
	filter: ({ cart, cabin }) => !!cart.filter((place) => place.id !== cabin?.id).length,
	target: openRemoveModal,
});

sample({
	source: {
		cart: cartModel.$cart,
		cabin: $cabinInfo,
	},
	clock: removeFromCartWithConfirm,
	filter: ({ cart, cabin }) => !cart.filter((place) => place.id !== cabin?.id).length,
	target: openRemoveAllModal,
});

const clearCart = combineEvents({ events: [cartModel.emptyCartDialog, cartModel.reset] });

sample({
	clock: clearCart,
	target: openRemoveAllModal,
});

delay({ source: closeRemoveModal, timeout: 600, target: resetCabinId });

export const model = {
	ModalGate,
	openCabinModal,
	closeModal,
	removeFromCartWithConfirm,
	$cabinInfo,
	resetCabinId,
	removeModalGate,
	openRemoveModal,
	closeRemoveModal,
	removeAllModalGate,
	openRemoveAllModal,
	closeRemoveAllModal,
};
