import React, { FC, memo, PropsWithChildren, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useUnit } from 'effector-react';
import { cruiseModel } from 'entities/cruises/model';
import { CruiseDuration } from 'entities/cruises/ui';
// import { MoreToggle, Typo } from 'shared/ui/atoms';
import { ConfirmationLabel } from '../confirmation-block-wrapper';
import styles from './styles.module.scss';
import { MoreToggle } from 'layout/components/buttons/more-toggle';
import { Typo } from 'layout/components/typo/ui';

export interface CustomerProps extends PropsWithChildren {
	defaultExpandedRoute?: boolean;
}

export const CruiseInfo: FC<CustomerProps> = memo(({ children, defaultExpandedRoute = false }) => {
	const [expanded, setExpanded] = useState(defaultExpandedRoute);
	const [cruise] = useUnit([cruiseModel.$cruise, cruiseModel.$status]);

	const routeHeadline = cruise?.type === 'sea' ? cruise?.route?.full : cruise?.route?.short;

	return (
		<div>
			<ConfirmationLabel label="Круиз" />
			<div className={styles.cruiseInfo}>
				<div className={styles.cruiseInfo__ship}>{children}</div>
				<div className={styles.cruiseInfo__duration}>
					{cruise && (
						<CruiseDuration
							dateStart={cruise.date.start}
							dateEnd={cruise.date.end}
							days={cruise.length.days}
							nights={cruise.type === 'sea' && cruise.length.nights}
							sizeVariant="x-large"
						/>
					)}
				</div>
				<div className={styles.cruiseInfo__route}>
					{routeHeadline && <Typo design="headline-m">{routeHeadline}</Typo>}

					{cruise?.route?.full && (
						<div className={styles.cruiseInfo__fullRoute}>
							<AnimateHeight height={expanded ? 'auto' : 0} duration={400}>
								<Typo className={styles.cruiseInfo__fullRouteContent} design="text-m">
									{cruise.route.full}
								</Typo>
							</AnimateHeight>
							{!defaultExpandedRoute && (
								<MoreToggle
									onClick={() => setExpanded((prev) => !prev)}
									isExpanded={expanded}
									label="Полный маршрут"
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
});
