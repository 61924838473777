import React, { memo } from 'react';
import { useGate } from 'effector-react';
// import { OrderLayout } from 'widgets/layouts/order';
// import { Discounts } from 'widgets/order/order-confirmation/ui/discounts';
import { OrderShipInfo } from 'entities/order';
import { RequestCartCounters } from 'entities/order/request-cart-counters';
import { RequestIncludedInPrice } from 'entities/order/request-included-in-price';
// import { Alert, Typo } from 'shared/ui/atoms';
import { model } from '../model/confirmation';
import { Comment } from './comment';
import { CruiseInfo } from './cruise-info';
import { CustomerInfo } from './customer-info';
import { OrderConfirmationControls } from './order-confirmation-controls';
import { PriceInfo } from './price-info';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { OrderLayout } from 'layout/layouts/order';
import { Discounts } from './discounts';

export const OrderConfirmation = memo(() => {
	useGate(model.Gate);

	return (
		<OrderLayout closeSidebar controls={<OrderConfirmationControls />}>
			<div className={styles.confirmation}>
				<Alert className={styles.alert} type="warning">
					<span>
						Пожалуйста, проверьте корректность введенных данных и нажмите &quot;Забронировать&quot;.
						<br />
						Если Вы выбрали вариант оплаты &quot;онлайн на сайте&quot; произведите оплату путевки на
						следующем шаге.
					</span>
				</Alert>

				<div className={styles.aboutCruise}>
					<CruiseInfo>
						<OrderShipInfo sizeVariant="large" />
					</CruiseInfo>

					<CustomerInfo className={styles.aboutCruise__customer} />

					<RequestCartCounters />

					<PriceInfo />

					<Discounts className={styles.accordion} />

					<RequestIncludedInPrice />
				</div>

				<Comment />
				{/* <div className={styles.discountsWrapper}>
					<Excursions className={styles.accordion} />
				</div> */}

				{/* <Bonuses /> */}

				<Alert className={styles.alert} size="lg" type="warning" iconPlacement="center">
					<Typo design="input-text-s">
						<p>
							Срок оплаты забронированного тура составляет в среднем 3&ndash;5 дней после
							подтверждения заявки и зависит от выбранного Вами круиза.
						</p>
					</Typo>
				</Alert>
			</div>
		</OrderLayout>
	);
});
