import { useViewport } from 'layout/viewport';
import { MutableRefObject, useEffect, useState } from 'react';
// import { useViewport } from 'shared/lib/viewport';

interface UseModalScrollListeners {
	scrollerRef?: MutableRefObject<Nullable<HTMLDivElement>>;
	containerRef?: MutableRefObject<Nullable<HTMLDivElement>>;
}

export function useModalScrollListeners(options: UseModalScrollListeners = {}) {
	const { scrollerRef, containerRef } = options;

	const { vh } = useViewport();
	const [reachedEnd, setReachedEnd] = useState(false);

	useEffect(() => {
		const wrapElement = scrollerRef?.current;
		const containerElement = containerRef?.current;

		const onScroll = () => {
			const bottom = containerElement?.getBoundingClientRect()?.bottom;
			setReachedEnd(Boolean(bottom && bottom <= vh));
		};

		onScroll();
		wrapElement?.addEventListener('scroll', onScroll);

		return () => {
			wrapElement?.removeEventListener('scroll', onScroll);
		};
	}, [containerRef, scrollerRef, vh]);

	return { reachedEnd };
}
