import React from 'react';
// import { Price, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';

interface SurchargeProps {
	places?: number;
	total: number;
	surcharge: number;
}

export const Surcharge = ({ places, total, surcharge }: SurchargeProps) => (
	<div className={styles.notification}>
		<Typo design="text-s" className={styles.notification__text}>
			Доплата за {places}-местное размещение <br />в {total}-местной каюте
		</Typo>
		<Typo design="headline-s">
			+ <Price total={surcharge} as="span" design="headline-s" bold />
		</Typo>
	</div>
);
