import axios from 'axios';
import { API_BASE_URL } from 'config/commonConstants';
import { CheckPhoneRequest, CheckPhoneResponse, RecoverByEmailRequest, RecoverByPhoneRequest, RecoverByPhoneResponse, ResetPasswordRequest } from 'config/types/personal';
import { ACCOUNT_URL } from '../constants';


export const recoverPasswordByPhone = (data: RecoverByPhoneRequest) =>
	axios
		.post<RecoverByPhoneResponse>(`${ACCOUNT_URL}/password/phone`, data, { baseURL: API_BASE_URL })
		.then((res) => res.data);

export const recoverPasswordByEmail = (data: RecoverByEmailRequest) =>
	axios
		.post(`${ACCOUNT_URL}/password/email`, data, { baseURL: API_BASE_URL })
		.then((res) => res.data);

export const checkPhone = (data: CheckPhoneRequest) =>
	axios
		.post<CheckPhoneResponse>(`${ACCOUNT_URL}/password/phone/check`, data, {
			baseURL: API_BASE_URL,
		})
		.then((res) => res.data);

export const resetPassword = (data: ResetPasswordRequest) =>
	axios
		.post(`${ACCOUNT_URL}/password/reset`, data, { baseURL: API_BASE_URL })
		.then((res) => res.data);
