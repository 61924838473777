import React from 'react';
import classnames from 'classnames';
// import { message } from 'shared/lib/message';
// import { Button, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { message } from 'config/constants/message';
import { Button } from 'layout/components/buttons/button';
import { Typo } from 'layout/components/typo/ui';

interface ErrorProps {
	reload: () => void;
}

export const Error = ({ reload }: ErrorProps) => (
	<div>Error</div>
	// <div className={classnames(styles.error, styles.errorWrapper)}>
	// 	<Typo design="text-m" raw={message.cabinInfo.error} />

	// 	<Button type="button" btnType="text" onClick={reload}>
	// 		Обновить
	// 	</Button>
	// </div>
);
