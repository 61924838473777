import { ResetPasswordRequest } from "config/types/personal";
import { createEffect, createEvent, sample } from "effector";
import { createForm } from "effector-react-form";


import { getFailCode } from "api";
import { message } from "config/constants/message";
import { personalRequests } from "store/auth/api";
import { checkPhoneCodeFormModel } from './check-phone-code-form';

const handleBadResponse = createEvent<number | undefined>();

const resetPassword = createEvent<Omit<ResetPasswordRequest, 'resetToken'>>();
const resetPasswordFx = createEffect(personalRequests.resetPassword);

interface FormValues {
	password: string;
	repeatPassword: string;
}

const resetPasswordForm = createForm<FormValues>({
	initialValues: {
		password: '',
		repeatPassword: '',
	},
	onSubmit: ({ values }) =>
		resetPassword({
			password: values.password,
		}),
});

sample({
	source: checkPhoneCodeFormModel.$resetToken,
	filter: Boolean,
	clock: resetPassword,
	fn: (resetToken, { password }) => ({
		resetToken,
		password,
	}),
	target: resetPasswordFx,
});

sample({
	clock: resetPasswordFx.failData,
	fn: (error) => getFailCode(error),
	target: handleBadResponse,
});

sample({
	clock: handleBadResponse,
	fn: (code) => ({
		field: 'formError',
		error: message.profile.resetPassword[code || 'error'],
	}),
	target: resetPasswordForm.setOrDeleteError,
});

export const resetPasswordFormModel = {
	resetPasswordForm,
	resetPasswordFx,
	$resetPasswordPending: resetPasswordFx.pending,
};
