export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg';
export type BreakpointArray = Breakpoint[];

type BreakpointsList = {
	xs: number;
	sm: number;
	md: number;
	lg: number;
};

export const breakpoints: BreakpointsList = {
	xs: 360,
	sm: 640,
	md: 1024,
	lg: 1240,
};

export const pixRatio = (): number => window.devicePixelRatio || 1;

export const getBpFlag = (vw: number = document.documentElement.clientWidth): Breakpoint => {
	let bp: Breakpoint = 'xs';

	if (vw < breakpoints.sm) {
		bp = 'xs';
	}

	if (vw >= breakpoints.sm && vw < breakpoints.md) {
		bp = 'sm';
	}

	if (vw >= breakpoints.md && vw < breakpoints.lg) {
		bp = 'md';
	}

	if (vw >= breakpoints.lg) {
		bp = 'lg';
	}

	return bp;
};

export const checkBp = (arr: string[], bp?: string): boolean =>
	arr.includes(bp || (getBpFlag() as string));

export const getScrollBarWidth = (): number => {
	const isSSR = typeof window === 'undefined';
	if (isSSR) {
		return 0;
	}

	const tester = document.createElement('div');

	document.body.append(tester);

	tester.style.cssText = `
        position: absolute;
        z-index: -1;
        left: -10000px;
        top: -100000px;
        width: 50px;
        height: 50px;
        overflow-y: scroll;
        pointer-events: none;
    `;

	const sb = tester.offsetWidth - tester.clientWidth;

	tester.remove();

	return sb;
};

export const getVpHelpers = (): HTMLDivElement[] => {
	const isSSR = typeof window === 'undefined';
	if (isSSR) {
		return [];
	}

	const vpHelper = document.createElement('div');
	const vpHelperContainer = document.createElement('div');

	vpHelper.style.cssText = `
			position: fixed;
			z-index: -1;
			left: -101vw;
			top: -101vh;
			width: 100vw;
			height: 100vh;
			pointer-events: none;
			overflow-y: scroll;
	`;

	document.documentElement.append(vpHelper);

	vpHelperContainer.classList.add('container');
	vpHelper.append(vpHelperContainer);

	return [vpHelper, vpHelperContainer];
};
