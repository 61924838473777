import { verifyEmail } from './verify-email';
import { changeEmail } from './change-email';
import { changePassword } from './change-password';
import {
	changePhone,
	changePhoneVerify,
	changeAdditionalPhoneVerify,
	changeAdditionalPhone,
} from './change-phone';
import { login, loginBySms } from './login';
import { logout } from './logout';
import { getProfile, updateProfile } from './profile';
import {
	recoverPasswordByPhone,
	recoverPasswordByEmail,
	checkPhone,
	resetPassword,
} from './recover-password';
import { refreshToken } from './refresh-token';
import { register, resendSMS, verificationPhone, verificationLoginByPhone } from './register';

export const personalRequests = {
	register,
	login,
	loginBySms,
	logout,
	verificationPhone,
	verificationLoginByPhone,
	resendSMS,
	recoverPasswordByPhone,
	recoverPasswordByEmail,
	checkPhone,
	resetPassword,
	getProfile,
	updateProfile,
	changePassword,
	changePhone,
	changePhoneVerify,
	changeAdditionalPhone,
	changeAdditionalPhoneVerify,
	verifyEmail,
	changeEmail,
	refreshToken,
};
