import React, { FC, memo } from 'react';
import { useUnit } from 'effector-react';
// import { CruiseFeatures } from 'widgets/cruise/ui/cruise-features';
// import { ConfirmationLabel } from 'widgets/order/order-confirmation/ui/confirmation-block-wrapper';
import { cruiseModel } from 'entities/cruises/model';
import { Skeleton } from 'layout/components/special/skeleton';
import { CruiseFeatures } from 'layout/layouts/widgets/cruise/ui/cruise-features';
import { ConfirmationLabel } from 'layout/layouts/widgets/order/order-confirmation/ui/confirmation-block-wrapper';
// import { Skeleton } from 'shared/ui/atoms';

export interface RequestIncludedInPriceProps {
	className?: string;
}

export const RequestIncludedInPrice: FC<RequestIncludedInPriceProps> = memo(({ className }) => {
	const [cruise, cruiseStatus] = useUnit([cruiseModel.$cruise, cruiseModel.$status]);

	if (cruiseStatus === 'pending') {
		return <Skeleton height={256} width="100%" />;
	}

	if (!cruise?.includedInPrice?.items || cruise.includedInPrice.items.length === 0) {
		return null;
	}

	return (
		<div className={className}>
			<ConfirmationLabel label="В стоимость тура входит" />
			{cruise?.includedInPrice && (
				<CruiseFeatures
					{...cruise.includedInPrice}
					theme="white"
					withoutTitle
					itIsIncludes
					withoutPictures
				/>
			)}
		</div>
	);
});
