import React from 'react';
import { model } from '../model';
import { Form } from 'layout/components/inputs/form/form';
import { InputPassword } from 'layout/components/inputs/input/input-password';
import { useUnit } from 'effector-react';
import { useError, useForm } from 'effector-react-form';
import { FormField } from 'layout/components/inputs/form/field';

export const ResetPasswordFrom = () => {
	const form = model.resetPasswordForm;
	const pending = useUnit(model.$resetPasswordPending);
	const { controller, handleSubmit } = useForm({ form });
	const { error, isShowError } = useError({ form, name: 'formError' });

	return (
		<Form
			pending={pending}
			buttonContent="Сохранить"
			title="Смена пароля"
			handleSubmit={handleSubmit}
			error={error}
			isShowError={isShowError}
		>
			<FormField
				controller={controller}
				form={form}
				name="password"
				validation={{ validateType: 'password', required: true }}
			>
				<InputPassword
					placeholder="Придумайте новый пароль"
					size="lg"
					type="password"
					withLabel
					required
				/>
			</FormField>
			<FormField
				controller={controller}
				form={form}
				name="repeatPassword"
				validation={{
					validateType: 'repeatPassword',
					required: true,
					reference: {
						form,
						fieldName: 'password',
					},
				}}
			>
				<InputPassword
					placeholder="Повторите новый пароль"
					size="lg"
					type="password"
					withLabel
					required
				/>
			</FormField>
		</Form>
	);
};
