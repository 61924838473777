import { FC, useRef } from 'react';
import classnames from 'classnames';
import { AriaToggleButtonProps, useToggleButton } from 'react-aria';
import { useToggleState } from 'react-stately';
import style from './style.module.scss';

interface ToggleIosProps extends AriaToggleButtonProps {
	className?: string;
	size?: 'md' | 'lg';
}

export const ToggleIos: FC<ToggleIosProps> = ({ className, size = 'md', ...props }) => {
	const ref = useRef<HTMLButtonElement>(null);
	const state = useToggleState(props);
	const { buttonProps } = useToggleButton(props, state, ref);

	const { children } = props;
	const { isSelected } = state;

	return (
		<div
			className={classnames(
				style.toggleIosContainer,
				style[`toggleIosContainer_${size}`],
				className,
			)}
		>
			<div>{children}</div>
			<button
				{...buttonProps}
				ref={ref}
				className={classnames(style.toggleIos, { [style.toggleIos__active]: isSelected })}
			>
				<div className={classnames(style.circle, { [style.circle__active]: isSelected })} />
			</button>
		</div>
	);
};
