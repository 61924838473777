import React, { HTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';

interface QuestionButtonProps extends HTMLAttributes<HTMLButtonElement> {
	outlineIcon?: boolean;
}

export const QuestionButton = forwardRef<HTMLButtonElement, QuestionButtonProps>(
	({ className, outlineIcon = false, ...props }, ref) => (
		<button ref={ref} className={classNames(styles.questionButton, className)} {...props}>
			<Icon
				width={20}
				height={20}
				className={styles.icon}
				id={outlineIcon ? 'info-outlined' : 'info'}
			/>
		</button>
	),
);
