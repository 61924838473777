import React, { FC } from 'react';
import { SvgSprite } from './svg-sprite';

export type BonusIconId =
	| 'klk-without-text'
	| 'klk-without-text-grey'
	| 'rules'
	| 'crown'
	| 'faq'
	| 'premium';

export const BonusIcons: FC = () => (
	<SvgSprite>
		<symbol id="klk-without-text" viewBox="0 0 33 26">
			<g clipPath="url(#clip0_1632_11541)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.207 11.812a.9.9 0 01.396-1.21L25.868.837a.9.9 0 011.21.395l1.3 2.565a3.809 3.809 0 003.063 6.042l.862 1.701a.9.9 0 01-.395 1.21l-19.266 9.767a.9.9 0 01-1.21-.396l-1.051-2.074a4.04 4.04 0 00-.188-3.233 4.04 4.04 0 00-2.496-2.062l-1.49-2.939z"
					fill="#ED3300"
				/>
				<mask id="a" maskUnits="userSpaceOnUse" x="-1.288" y="5.915" width="30.315" height="21.171">
					<path fill="#fff" d="M-1.288 5.915h30.315v21.171H-1.288z" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M1.14 12.846a.9.9 0 01.749-1.03l21.927-3.472a.9.9 0 011.03.748l.422 2.666a4.043 4.043 0 00-1.04 3.398 4.043 4.043 0 002.039 2.91l.33 2.088a.9.9 0 01-.748 1.03L3.923 24.656a.9.9 0 01-1.03-.748l-.32-2.025a4.044 4.044 0 001.111-3.473 4.044 4.044 0 00-2.13-2.96l-.413-2.604z"
					/>
				</mask>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.14 12.846a.9.9 0 01.749-1.03l21.927-3.472a.9.9 0 011.03.748l.422 2.666a4.043 4.043 0 00-1.04 3.398 4.043 4.043 0 002.039 2.91l.33 2.088a.9.9 0 01-.748 1.03L3.923 24.656a.9.9 0 01-1.03-.748l-.32-2.025a4.044 4.044 0 001.111-3.473 4.044 4.044 0 00-2.13-2.96l-.413-2.604z"
					fill="#0171AD"
				/>
				<path
					d="M1.14 12.846l-1.333.211 1.334-.21zm24.128-1.088l.987.92a1.35 1.35 0 00.346-1.131l-1.333.21zm.999 6.308l1.333-.21a1.35 1.35 0 00-.679-.97l-.654 1.18zm.33 2.088l-1.333.211 1.333-.211zm-.748 1.03l-.21-1.334.21 1.334zM3.923 24.656l.21 1.334-.21-1.334zm-1.35-2.773l-.964-.945a1.35 1.35 0 00-.37 1.157l1.333-.212zm-1.02-6.432l-1.333.21c.067.423.33.789.71.987l.623-1.197zm.125-4.968a2.25 2.25 0 00-1.87 2.574l2.666-.422a.45.45 0 01-.374.515l-.422-2.667zM23.604 7.01L1.678 10.483 2.1 13.15l21.927-3.473-.423-2.667zm2.575 1.87a2.25 2.25 0 00-2.575-1.87l.423 2.667a.45.45 0 01-.515-.374l2.667-.422zm.422 2.667l-.422-2.666-2.667.422.422 2.666 2.667-.422zm-1.04 3.398a2.693 2.693 0 01.694-2.266l-1.975-1.842a5.393 5.393 0 00-1.386 4.53l2.667-.422zm1.36 1.94a2.693 2.693 0 01-1.36-1.94l-2.667.422a5.393 5.393 0 002.718 3.88l1.31-2.361zm1.01 3.058l-.33-2.088-2.668.423.331 2.087 2.667-.422zm-1.87 2.574a2.25 2.25 0 001.87-2.574l-2.667.422a.45.45 0 01.374-.515l.422 2.667zM4.134 25.99l21.927-3.473-.423-2.667-21.927 3.473.423 2.667zM1.56 24.12a2.25 2.25 0 002.574 1.87l-.423-2.667a.45.45 0 01.515.374l-2.666.422zm-.321-2.025l.32 2.024 2.667-.422-.32-2.025-2.667.423zM2.35 18.62c.139.878-.16 1.724-.742 2.317l1.926 1.891a5.394 5.394 0 001.483-4.63l-2.667.422zM.929 16.648a2.694 2.694 0 011.422 1.973l2.667-.422a5.394 5.394 0 00-2.84-3.946L.928 16.648zm-1.122-3.59l.413 2.604 2.667-.423-.413-2.604-2.667.422z"
					fill="#fff"
					mask="url(#a)"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1632_11541">
					<path fill="#fff" d="M0 0h33v26H0z" />
				</clipPath>
			</defs>
		</symbol>

		<symbol id="klk-without-text-grey" viewBox="0 0 33 26">
			<g clipPath="url(#clip0_1632_11541)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.207 11.812a.9.9 0 01.396-1.21L25.868.837a.9.9 0 011.21.395l1.3 2.565a3.809 3.809 0 003.063 6.042l.862 1.701a.9.9 0 01-.395 1.21l-19.266 9.767a.9.9 0 01-1.21-.396l-1.051-2.074a4.04 4.04 0 00-.188-3.233 4.04 4.04 0 00-2.496-2.062l-1.49-2.939z"
					fill="#BDC6D2"
				/>
				<mask id="a" maskUnits="userSpaceOnUse" x="-1.288" y="5.915" width="30.315" height="21.171">
					<path fill="#fff" d="M-1.288 5.915h30.315v21.171H-1.288z" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M1.14 12.846a.9.9 0 01.749-1.03l21.927-3.472a.9.9 0 011.03.748l.422 2.666a4.043 4.043 0 00-1.04 3.398 4.043 4.043 0 002.039 2.91l.33 2.088a.9.9 0 01-.748 1.03L3.923 24.656a.9.9 0 01-1.03-.748l-.32-2.025a4.044 4.044 0 001.111-3.473 4.044 4.044 0 00-2.13-2.96l-.413-2.604z"
					/>
				</mask>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.14 12.846a.9.9 0 01.749-1.03l21.927-3.472a.9.9 0 011.03.748l.422 2.666a4.043 4.043 0 00-1.04 3.398 4.043 4.043 0 002.039 2.91l.33 2.088a.9.9 0 01-.748 1.03L3.923 24.656a.9.9 0 01-1.03-.748l-.32-2.025a4.044 4.044 0 001.111-3.473 4.044 4.044 0 00-2.13-2.96l-.413-2.604z"
					fill="#BDC6D2"
				/>
				<path
					d="M1.14 12.846l-1.333.211 1.334-.21zm24.128-1.088l.987.92a1.35 1.35 0 00.346-1.131l-1.333.21zm.999 6.308l1.333-.21a1.35 1.35 0 00-.679-.97l-.654 1.18zm.33 2.088l-1.333.211 1.333-.211zm-.748 1.03l-.21-1.334.21 1.334zM3.923 24.656l.21 1.334-.21-1.334zm-1.35-2.773l-.964-.945a1.35 1.35 0 00-.37 1.157l1.333-.212zm-1.02-6.432l-1.333.21c.067.423.33.789.71.987l.623-1.197zm.125-4.968a2.25 2.25 0 00-1.87 2.574l2.666-.422a.45.45 0 01-.374.515l-.422-2.667zM23.604 7.01L1.678 10.483 2.1 13.15l21.927-3.473-.423-2.667zm2.575 1.87a2.25 2.25 0 00-2.575-1.87l.423 2.667a.45.45 0 01-.515-.374l2.667-.422zm.422 2.667l-.422-2.666-2.667.422.422 2.666 2.667-.422zm-1.04 3.398a2.693 2.693 0 01.694-2.266l-1.975-1.842a5.393 5.393 0 00-1.386 4.53l2.667-.422zm1.36 1.94a2.693 2.693 0 01-1.36-1.94l-2.667.422a5.393 5.393 0 002.718 3.88l1.31-2.361zm1.01 3.058l-.33-2.088-2.668.423.331 2.087 2.667-.422zm-1.87 2.574a2.25 2.25 0 001.87-2.574l-2.667.422a.45.45 0 01.374-.515l.422 2.667zM4.134 25.99l21.927-3.473-.423-2.667-21.927 3.473.423 2.667zM1.56 24.12a2.25 2.25 0 002.574 1.87l-.423-2.667a.45.45 0 01.515.374l-2.666.422zm-.321-2.025l.32 2.024 2.667-.422-.32-2.025-2.667.423zM2.35 18.62c.139.878-.16 1.724-.742 2.317l1.926 1.891a5.394 5.394 0 001.483-4.63l-2.667.422zM.929 16.648a2.694 2.694 0 011.422 1.973l2.667-.422a5.394 5.394 0 00-2.84-3.946L.928 16.648zm-1.122-3.59l.413 2.604 2.667-.423-.413-2.604-2.667.422z"
					fill="#fff"
					mask="url(#a)"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1632_11541">
					<path fill="#fff" d="M0 0h33v26H0z" />
				</clipPath>
			</defs>
		</symbol>

		<symbol id="rules" viewBox="0 0 20 20">
			<path
				d="M5 17H14.5C14.7761 17 15 16.7761 15 16.5V3.5C15 3.22386 14.7761 3 14.5 3H5.5C5.22386 3 5 3.22386 5 3.5V13"
				stroke="#0171AD"
				strokeWidth="1.5"
			/>
			<path
				d="M7 17H4C2.89543 17 2 16.1046 2 15V13.5C2 13.2239 2.22386 13 2.5 13H11.5C11.7761 13 12 13.2239 12 13.5V15C12 16.1046 12.8954 17 14 17H14.5"
				stroke="#0171AD"
				strokeWidth="1.5"
			/>
			<path
				d="M14 3H16C17.1046 3 18 3.89543 18 5V6.5C18 6.77614 17.7761 7 17.5 7H15"
				stroke="#0171AD"
				strokeWidth="1.5"
			/>
		</symbol>

		<symbol id="crown" viewBox="0 0 20 20">
			<path
				d="M15.728 11.3161L17.1375 6.08768C17.2756 5.6735 16.8537 5.29373 16.4563 5.47438L12.5057 7.27009C12.2355 7.39293 11.9183 7.25464 11.8245 6.97302L10.4744 2.92294C10.3225 2.46709 9.6777 2.46709 9.52575 2.92294L8.17573 6.97302C8.08186 7.25464 7.76473 7.39293 7.49448 7.27009L3.54393 5.47438C3.14648 5.29373 2.72463 5.6735 2.86269 6.08768L4.27217 11.3161C4.40829 11.7245 4.79043 11.9999 5.22086 11.9999H14.7793C15.2098 11.9999 15.5919 11.7245 15.728 11.3161Z"
				stroke="#0171AD"
				strokeWidth="1.5"
			/>
			<path
				d="M5 15C5 14.7239 5.22386 14.5 5.5 14.5H14.5C14.7761 14.5 15 14.7239 15 15V16.5C15 16.7761 14.7761 17 14.5 17H5.5C5.22386 17 5 16.7761 5 16.5V15Z"
				stroke="#0171AD"
				strokeWidth="1.5"
			/>
		</symbol>

		<symbol id="faq" viewBox="0 0 20 20">
			<path
				d="M12 7.75V4.5C12 4.22386 11.7761 4 11.5 4H2.5C2.22386 4 2 4.22386 2 4.5V12C2 12.2761 2.22386 12.5 2.5 12.5H3.375C3.65114 12.5 3.875 12.7239 3.875 13V13.6397C3.875 14.105 4.45467 14.318 4.75597 13.9636L5.85025 12.6762C5.94525 12.5644 6.08454 12.5 6.23122 12.5H8"
				stroke="#0171AD"
				strokeWidth="1.5"
			/>
			<path
				d="M18 12.25C18 9.90279 16.0972 8 13.75 8H12.25C9.90279 8 8 9.90279 8 12.25V12.6124C8 14.7594 9.74055 16.5 11.8876 16.5V16.5C11.9589 16.5 12.0266 16.5313 12.0728 16.5856L13.244 17.9636C13.5453 18.318 14.125 18.105 14.125 17.6397V16.7279C14.125 16.6021 14.2271 16.5 14.3529 16.5V16.5C16.3672 16.5 18 14.8672 18 12.8529V12.25Z"
				stroke="#0171AD"
				strokeWidth="1.5"
			/>
		</symbol>

		<symbol id="premium" viewBox="0 0 20 20">
			<path d="M9.10384 10.6251L9.99967 9.93758L10.8747 10.6251C11.0275 10.7501 11.1941 10.7534 11.3747 10.6351C11.5552 10.5173 11.6108 10.3612 11.5413 10.1667L11.1872 9.04175L12.1872 8.25008C12.34 8.12508 12.3852 7.96869 12.323 7.78091C12.2602 7.59369 12.1316 7.50008 11.9372 7.50008H10.7497L10.3955 6.39592C10.3261 6.20147 10.1941 6.10425 9.99967 6.10425C9.80523 6.10425 9.67329 6.20147 9.60384 6.39592L9.24967 7.50008H8.06217C7.86773 7.50008 7.73579 7.5973 7.66634 7.79175C7.5969 7.98619 7.63856 8.13897 7.79134 8.25008L8.79134 9.04175L8.43717 10.1667C8.36773 10.3612 8.41967 10.5173 8.59301 10.6351C8.7669 10.7534 8.93717 10.7501 9.10384 10.6251ZM6.10384 18.7917C5.82606 18.889 5.57245 18.8542 5.34301 18.6876C5.11412 18.5209 4.99967 18.2987 4.99967 18.0209V12.7292C4.4719 12.1459 4.06217 11.4792 3.77051 10.7292C3.47884 9.97925 3.33301 9.18064 3.33301 8.33342C3.33301 6.4723 3.97884 4.89591 5.27051 3.60425C6.56217 2.31258 8.13856 1.66675 9.99967 1.66675C11.8608 1.66675 13.4372 2.31258 14.7288 3.60425C16.0205 4.89591 16.6663 6.4723 16.6663 8.33342C16.6663 9.18064 16.5205 9.97925 16.2288 10.7292C15.9372 11.4792 15.5275 12.1459 14.9997 12.7292V18.0209C14.9997 18.2987 14.885 18.5209 14.6555 18.6876C14.4266 18.8542 14.1733 18.889 13.8955 18.7917L9.99967 17.5001L6.10384 18.7917ZM9.99967 13.3334C11.3886 13.3334 12.5691 12.8473 13.5413 11.8751C14.5136 10.9029 14.9997 9.7223 14.9997 8.33342C14.9997 6.94453 14.5136 5.76397 13.5413 4.79175C12.5691 3.81953 11.3886 3.33341 9.99967 3.33341C8.61079 3.33341 7.43023 3.81953 6.45801 4.79175C5.48579 5.76397 4.99967 6.94453 4.99967 8.33342C4.99967 9.7223 5.48579 10.9029 6.45801 11.8751C7.43023 12.8473 8.61079 13.3334 9.99967 13.3334ZM6.66634 16.6876L9.99967 15.8334L13.333 16.6876V14.1042C12.8469 14.382 12.3227 14.6006 11.7605 14.7601C11.1977 14.9201 10.6108 15.0001 9.99967 15.0001C9.38856 15.0001 8.80162 14.9201 8.23884 14.7601C7.67662 14.6006 7.15245 14.382 6.66634 14.1042V16.6876Z" />
		</symbol>
	</SvgSprite>
);
