import { CountryModel } from 'config/types/cabins/models/country-model';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { countriesRequests } from 'store/account/api/countries';
// import { countriesRequests } from 'shared/api/requests';
// import { CountryModel } from 'shared/lib/types';

const $countries = createStore<CountryModel[]>([]);
const fetchCountries = createEvent();
const fetchCountriesFx = createEffect(countriesRequests.getCountries);

sample({
	clock: fetchCountries,
	target: fetchCountriesFx,
});

sample({
	clock: fetchCountriesFx.doneData,
	target: $countries,
});

export const model = {
	$countries,
	fetchCountries,
};
