import React, { FC, HTMLAttributes } from 'react';
import classnames from 'classnames';
import { TabsTheme } from './tabs';
import styles from './styles.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { SizeType } from 'layout/components/buttons/button/button';
import { Button } from 'layout/components/buttons/button';

export interface TabButtonProps
	extends Pick<HTMLAttributes<HTMLButtonElement>, 'children' | 'onClick'>,
		DefaultStyledProps {
	id?: string | number;
	isActive?: boolean;
	href?: string;
	theme?: TabsTheme;
	size?: SizeType;
	contextClassName?: string;
	tabButtonClassName?: string;
}

export const TabButton: FC<TabButtonProps> = ({
	id,
	href,
	isActive,
	onClick,
	className,
	contextClassName,
	children,
	theme,
	size = 'middle',
}) => (
	<Button
		role="tab"
		id={`${id}-tab`}
		aria-controls={`${id}-panel`}
		aria-selected={isActive}
		size={size}
		btnType={theme === 'dashed' ? 'text' : 'primary'}
		type="button"
		onClick={onClick}
		className={classnames(className, {
			[styles.isActive]: isActive,
			[styles[`tabs__button_${theme}`]]: theme,
		})}
		contextClassName={contextClassName}
		href={href}
	>
		{children}
	</Button>
);
