// import { AccountRequestsStatuses } from 'shared/config';

import { AccountRequestsStatuses } from "store/auth/constants";

export const requestsStatuses = [
	{
		id: AccountRequestsStatuses.Canceled,
		name: 'canceled',
		label: 'Аннулировано',
		className: 'canceled',
	},
	{ id: AccountRequestsStatuses.Return, name: 'return', label: 'Возврат', className: 'return' },
	{
		id: AccountRequestsStatuses.AwaitingReturn,
		name: 'awaiting-return',
		label: 'Ожидает возврата',
		className: 'awaiting_return',
	},
	{
		id: AccountRequestsStatuses.AwaitingConfirmation,
		name: 'awaiting-confirmation',
		label: 'Ожидает подтверждения',
		className: 'awaiting_confirmation',
	},
	{
		id: AccountRequestsStatuses.WaitingList,
		name: 'waiting-list',
		label: 'Лист ожидания',
		className: 'waiting_list',
	},
	{
		id: AccountRequestsStatuses.CancellationRequest,
		name: 'cancellation-request',
		label: 'Запрошено на аннулирование',
		className: 'cancellation_request',
	},
	{
		id: AccountRequestsStatuses.InProcessing,
		name: 'in-processing',
		label: 'В обработке',
		className: 'in_processing',
	},
	{
		id: AccountRequestsStatuses.Confirmed,
		name: 'confirmed',
		label: 'Подтверждено',
		className: 'confirmed',
	},
	{
		id: AccountRequestsStatuses.Prepayment,
		name: 'prepayment',
		label: 'Предоплата',
		className: 'prepayment',
	},
	{ id: AccountRequestsStatuses.Paid, name: 'paid', label: 'Оплачено', className: 'paid' },
	{
		id: AccountRequestsStatuses.ForFree,
		name: 'for-free',
		label: 'Бесплатно',
		className: 'for_free',
	},
	{
		id: AccountRequestsStatuses.AwaitingPayment,
		name: 'awaiting-payment',
		label: 'Ожидает оплаты',
		className: 'awaiting_payment',
	},
	{
		id: AccountRequestsStatuses.SoldByAgency,
		name: 'confirmed',
		label: 'Продано у агентства',
		className: 'confirmed',
	},
];
