import axios, { GenericAbortSignal, ResponseType } from 'axios';
import { API_BASE_URL } from 'config/commonConstants';
import { objectToFormData } from 'utils/object';
import { getJWT } from './jwt';
import { isSafari, isSafariVersionHigher } from 'utils/tests';
import { goToReLogin, refreshToken, withoutLeadingSlash } from 'api';



type RestMethod = 'OPTIONS' | 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface FetchConfig {
	url: string;
	method?: RestMethod;
	headers?: Headers;
	body?: any | null;
	responseType?: ResponseType;
	signal?: GenericAbortSignal;
	authorization?: boolean;
}

const getData = (body: FetchConfig['body'], headers: Headers) => {
	if (body && headers?.get('Content-Type') === 'multipart/form-data') {
		return objectToFormData(body);
	}

	return body;
};

const getPayload = (method: RestMethod, body: any | null, headers: Headers) => {
	if (method === 'GET') {
		return {
			params: body,
		};
	}

	return { data: getData(body, headers) };
};

const getHeaders = (headers: Headers) => {
	const defaultHeaders = new Headers({ 'content-type': 'application/json' });

	return new Headers([...defaultHeaders, ...headers]);
};

const addAuthorization = (headers: Headers, body: any, authorization: boolean) => {
	const newHeaders = new Headers([...headers]);
	const newBody = body || {};

	if (authorization) {
		const access = getJWT();

		if (!isSafari() || isSafariVersionHigher('15.4')) {
			newHeaders.set('Authorization', `Bearer ${access}`);
		} else {
			newBody.token = access;
		}
	}

	return { headers: newHeaders, body: newBody };
};

export const fetcher = <T>(args: FetchConfig | string) => {
	const {
		url,
		method = 'GET',
		headers = new Headers({}),
		body = null,
		responseType = 'json',
		authorization = false,
	} = typeof args === 'string' ? { url: args } : args;

	const signal = typeof args === 'string' ? undefined : args.signal;
	const mergedHeaders = getHeaders(headers);

	const withAuth = addAuthorization(mergedHeaders, body, authorization);

	const payload = getPayload(method, withAuth.body, withAuth.headers);

	return axios<T>({
		method,
		url: withoutLeadingSlash(url),
		baseURL: API_BASE_URL,
		headers: Object.fromEntries(withAuth.headers),
		responseType,
		signal,
		...payload,
	})
		.then((response) => response.data)
		.catch((error) => {
			// logger.error(`[${url}]: ${error}`);

			throw error;
		});
};

/* *
 * Обрабатываем 401
 * в респонсе
 */
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		// eslint-disable-next-line no-console
		const access = getJWT();
		const refresh = getJWT('refresh');
		const isRefreshRequest = error.response.config.url.includes('/refresh');

		if (error.response.status !== 401) {
			throw error;
		}

		/* *
		 * Если закончился refresh-токен
		 */
		if (isRefreshRequest) {
			/* *
			 * И при этом пользватель находится в разделах ЛК, то редирект на логин
			 */
			if (window.location.pathname.includes('/account')) {
				goToReLogin();
			}

			throw error;
		}

		/* *
		 * Если закончился access-токен
		 */
		if (error.response.data.tokenExpired) {
			if (!access || !refresh) {
				goToReLogin();

				throw error;
			}

			return refreshToken(refresh)
				.then(() => {
					const { url, method, headers, data, params, responseType, signal } =
						error.response.config;
					const newHeaders = getHeaders(new Headers(headers));

					const withAuth = addAuthorization(newHeaders, data || params, true);

					const payload = getPayload(method, withAuth.body, withAuth.headers);

					return axios<any>({
						baseURL: API_BASE_URL,
						headers: Object.fromEntries(withAuth.headers),
						url,
						method,
						responseType,
						signal,
						...payload,
					})
						.then((response) => response)
						.catch((repeatRequestError) => {
							throw repeatRequestError;
						});
				})
				.catch((refreshTokenError) => {
					goToReLogin();

					return refreshTokenError;
				});
		}

		throw error;
	},
);
