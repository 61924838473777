import { fetcher } from 'api/setupAxios';
import { SEARCH_CRUISES_SHIP_TYPES_KEY, SEARCH_CRUISES_SHIPS_KEY, SEARCH_HANDBOOKS_ENABLE_MOCK, 
	SEARCH_HANDBOOKS_ENDPOINT, SEARCH_FILTER_HANDBOOKS_ENABLE_MOCK, SEARCH_FILTER_HANDBOOKS_ENDPOINT, 
	SEARCH_FILTERED_HANDBOOKS_KEYS, SEARCH_FILTERED_FILTER_KEYS, SEARCH_CRUISES_LIMIT, SEARCH_CRUISES_ENABLE_MOCK, 
	SEARCH_CRUISES_ENDPOINT } from 'config/constants/search';
import { CruiseModel, CruiseTableCabins } from 'config/types/cabins/models/cruise';
import { PageableList } from 'config/types/core';
import { FilterFieldProps, SearchFieldProps } from 'config/types/search';
import { getCruisesMock, getSearchFieldsMock } from 'mocks/search';
import { getSearchFiltersMock } from 'mocks/search/filters';
import { ParsedUrlQuery } from 'querystring';
// import { SearchFieldProps } from 'react-stately';
import { stringifyParsedUrlQuery } from 'utils/object';
// import { fetcher } from 'shared/api/config';
// import { getCruisesMock, getSearchFieldsMock } from 'shared/api/mock/search';
// import { getSearchFiltersMock } from 'shared/api/mock/search/filters';
// import {
// 	SEARCH_CRUISES_ENABLE_MOCK,
// 	SEARCH_CRUISES_ENDPOINT,
// 	SEARCH_CRUISES_LIMIT,
// 	SEARCH_CRUISES_SHIP_TYPES_KEY,
// 	SEARCH_CRUISES_SHIPS_KEY,
// 	SEARCH_FILTER_HANDBOOKS_ENABLE_MOCK,
// 	SEARCH_FILTER_HANDBOOKS_ENDPOINT,
// 	SEARCH_FILTERED_FILTER_KEYS,
// 	SEARCH_FILTERED_HANDBOOKS_KEYS,
// 	SEARCH_HANDBOOKS_ENABLE_MOCK,
// 	SEARCH_HANDBOOKS_ENDPOINT,
// } from 'shared/config';
// import {
// 	CruiseModel,
// 	FilterFieldProps,
// 	CruiseTableCabins,
// 	PageableList,
// 	SearchFieldProps,
// } from 'shared/lib/types';
// import { stringifyParsedUrlQuery } from 'shared/lib/utils';

export interface GetSearchFieldsRequest extends ParsedUrlQuery {
	[SEARCH_CRUISES_SHIP_TYPES_KEY]?: string;
	[SEARCH_CRUISES_SHIPS_KEY]?: string;
}

/* *
 * Получение фильтров с последующим объединением нескольких запросов в один
 * чтобы фильтры не ограничивали сами себя, но ограничивали другие фильтры
 */
const combineFilters =
	<T extends FilterFieldProps | SearchFieldProps>(
		cb: (params: ParsedUrlQuery) => Promise<any>,
		filteredKeys: (string | string[])[],
	) =>
	async (params: ParsedUrlQuery) => {
		const promises: Promise<T[]>[] = [cb(params)];
		const promisesKeys: (string | string[])[] = [''];

		for (const key in params) {
			let keys = filteredKeys.find((el) => el === key || el.includes(key));

			if (keys && !promisesKeys.flat().includes(key)) {
				const { ...filteredParams } = params;

				if (!Array.isArray(keys)) {
					keys = [keys];
				}

				keys.forEach((k) => {
					delete filteredParams[k];
				});

				promises.push(cb({ ...filteredParams }));
				promisesKeys.push(keys);
			}
		}

		const handbooks = await Promise.all(promises);

		return handbooks.reduce((prev, handbook, i) => {
			const index = prev.findIndex(({ keys }) => {
				const keysArray = Array.isArray(keys) ? keys : [keys];

				return JSON.stringify(keysArray) === JSON.stringify(promisesKeys[i]);
			});

			if (index >= 0) {
				prev[index] = handbook[index];
			}

			return prev;
		}, handbooks[0]);
	};

const getSearchFields = async (
	{ shipTypesIn, ...rest }: GetSearchFieldsRequest,
	needMock = SEARCH_HANDBOOKS_ENABLE_MOCK,
) => {
	if (needMock) {
		await new Promise((resolve) => {
			setTimeout(resolve, 300);
		});

		return getSearchFieldsMock({ shipTypesIn });
	}

	return fetcher<Array<SearchFieldProps>>({
		url: SEARCH_HANDBOOKS_ENDPOINT,
		method: 'GET',
		body: {
			shipTypesIn: typeof shipTypesIn === 'string' && shipTypesIn.length ? shipTypesIn : undefined,
			...rest,
		},
	});
};

const getFilterFields = async (
	query: ParsedUrlQuery,
	needMock = SEARCH_FILTER_HANDBOOKS_ENABLE_MOCK,
) => {
	if (needMock) {
		await new Promise((resolve) => {
			setTimeout(resolve, 300);
		});

		return getSearchFiltersMock();
	}

	return fetcher<Array<FilterFieldProps>>({
		url: `${SEARCH_FILTER_HANDBOOKS_ENDPOINT}?${stringifyParsedUrlQuery(query)}`,
		method: 'GET',
		body: {},
	});
};

const getFilteredSearchFields = combineFilters<SearchFieldProps>(
	getSearchFields,
	SEARCH_FILTERED_HANDBOOKS_KEYS,
);
const getFilteredFilterFields = combineFilters<FilterFieldProps>(
	getFilterFields,
	SEARCH_FILTERED_FILTER_KEYS,
);

export interface GetCruisesPaginationRequest {
	limit?: number;
	offset?: number;
	forTable?: boolean;
}

export interface GetCruisesRequest extends GetCruisesPaginationRequest {
	params?: string;
}

const getCruises = async (
	{ limit = SEARCH_CRUISES_LIMIT, offset = 0, forTable, params }: GetCruisesRequest,
	needMock = SEARCH_CRUISES_ENABLE_MOCK,
) => {
	if (needMock) {
		await new Promise((resolve) => {
			setTimeout(resolve, 300);
		});

		return getCruisesMock({
			limit,
			offset,
		});
	}

	params = `?${params}`;

	return fetcher<PageableList<CruiseModel, CruiseTableCabins>>({
		url: SEARCH_CRUISES_ENDPOINT + params,
		method: 'GET',
		body: {
			limit,
			offset,
			forTable,
		},
	});
};

export const searchRequests = {
	getSearchFields,
	getFilteredSearchFields,
	getFilterFields,
	getFilteredFilterFields,
	getCruises,
};
