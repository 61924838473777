import { reset, status } from 'patronum';
import { EffectState } from 'patronum/status';
import { createEffect, createEvent, Event, sample, Store } from 'effector';
import { createGate, Gate } from 'effector-react';
import { createForm, Form } from 'effector-react-form';
// import { verificationModel } from 'entities/verification';
// import { SendCallbackRequest, supportRequest } from 'shared/api';
// import { NOT_DIGITS } from 'shared/lib/constants';
// import { message } from 'shared/lib/message';
// import { CallbackBadResponse } from 'shared/lib/types';
// import { getFailCode, getFailData } from 'shared/lib/utils/api/axios';
// import { getValidation } from 'shared/ui/organisms/form/lib/validation';
import { callbackValidationScheme, SendCallbackRequestKey } from './lib';
import { verificationModel } from 'utils/verification';
import { NOT_DIGITS } from 'config/constants';
import { getFailCode, getFailData } from 'api';
import { message } from 'config/constants/message';
import { getValidation } from 'layout/components/inputs/form/lib/validation';
import { SendCallbackRequest, supportRequest } from 'store/support';
import { CallbackBadResponse } from 'config/types/forms/callback';

export interface CallbackFormModel {
	form: Form<Omit<SendCallbackRequest, 'captcha'>, any>;
	resetForm: Event<void>;
	$status: Store<EffectState>;
	$pending: Store<boolean>;
	Gate: Gate<unknown>;
}

export const callbackFactory = (): CallbackFormModel => {
	const sendCallbackFx = createEffect(supportRequest.sendCallback);

	const handleOnSubmit = createEvent<Omit<SendCallbackRequest, 'captcha'>>();
	const handleBadResponse = createEvent<number | undefined>();
	const handleBadResponseErrors = createEvent<CallbackBadResponse | undefined>();

	const form = createForm<Omit<SendCallbackRequest, 'captcha'>>({
		initialValues: {
			name: '',
			phone: '',
			agreeProcessData: true,
		},
		onSubmit: ({ values }) => handleOnSubmit(values),
		validate: ({ values }) => {
			const errors: Partial<Record<SendCallbackRequestKey, string>> = {};

			Object.entries(values).forEach(([key, value]) => {
				if (key === 'phone') {
					value = typeof value === 'string' ? value.replace(NOT_DIGITS, '') : value;
				}
				const validate = getValidation(callbackValidationScheme[key as SendCallbackRequestKey]);
				errors[key as SendCallbackRequestKey] = validate(value);
			});

			return errors;
		},
	});

	const $status = status({ effect: sendCallbackFx });

	sample({
		source: verificationModel.$captchaToken,
		clock: handleOnSubmit,
		fn: (captcha, data) => ({
			...data,
			phone: data.phone.replace(NOT_DIGITS, '').slice(0, 20),
			captcha,
		}),
		target: sendCallbackFx,
	});

	sample({
		clock: sendCallbackFx.failData,
		fn: (error) => getFailCode(error),
		target: handleBadResponse,
	});

	sample({
		clock: sendCallbackFx.failData,
		fn: (error) => getFailData(error),
		target: handleBadResponseErrors,
	});

	sample({
		clock: handleBadResponse,
		fn: (code) => ({
			field: 'formError',
			error: message.callback[code || 'error'],
		}),
		target: form.setOrDeleteError,
	});

	handleBadResponseErrors.watch((data) => {
		data?.errors?.forEach(({ field, code }) => {
			const error =
				code !== 'AC2'
					? message.callback[code]
					: callbackValidationScheme[field as SendCallbackRequestKey]?.messages?.validateType ?? '';

			form.setOrDeleteError({
				field,
				error,
			});
		});
	});

	const resetForm = createEvent();

	sample({
		clock: resetForm,
		target: form.reset,
	});

	reset({
		clock: resetForm,
		target: $status,
	});

	const load = createGate();

	return {
		form,
		resetForm,
		$status,
		$pending: sendCallbackFx.pending,
		Gate: load,
	};
};
