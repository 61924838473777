import React, { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
import DummySvgIcon from 'shared/assets/images/dummy.svg';
import styles from './styles.module.scss';

export interface CaseMessageProps extends PropsWithChildren {
	size?: 'small';
	className?: string;
}

export const CaseMessage: FC<CaseMessageProps> = ({ size, className, children }) => (
	<div className={classnames(styles.caseMessage, className, size && styles[size])} role="alert">
		{!size && <DummySvgIcon />}
		{children}
	</div>
);
