import React, { FC, memo, useEffect } from 'react';
// import Link from 'next/link';
import { useUnit, useEvent } from 'effector-react';
import { bonusCardModel } from 'entities/bonus-card/model';
// import { HEADER_CLUB_LINK, KLK_RULES_LINK } from 'shared/config';
// import { Button, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Link } from '@tanstack/react-router';
import { Button } from 'layout/components/buttons/button';
import { KLK_RULES_LINK } from 'config/constants/contacts';
import { HEADER_CLUB_LINK } from 'config/constants/header';

interface BonusCardGreetingProps {
	isLoading?: boolean;
}

export const BonusCardGreeting: FC<BonusCardGreetingProps> = memo(({ isLoading }) => {
	const { isOnboarded } = useUnit(bonusCardModel.$card) ?? {};
	const sendOnboarding = useEvent(bonusCardModel.onboardingBonusCard);

	useEffect(() => {
		let timer = 0;

		timer = window.setTimeout(() => {
			if (!isOnboarded) {
				sendOnboarding();
			}
		}, 100);

		return () => {
			window.clearTimeout(timer);
		};
	}, [isOnboarded, sendOnboarding]);

	if (isLoading) {
		return null;
	}

	return (
		<Typo className={styles.profile__greeting} as="aside" design="text-m">
			<Typo design="headline-s">Поздравляем!</Typo>
			<p>
				Вы стали участником Клуба Любителей Круизов. Вам вскоре будут начислены приветственные
				баллы,
				<br />
				нажмите{' '}
				<Link href={HEADER_CLUB_LINK.href ?? ''}>
					{/* <a>сюда</a> */}
					сюда
				</Link>
				, чтобы узнать на что их можно потратить.
			</p>
			<Button
				size="large"
				btnType="text"
				href={KLK_RULES_LINK}
				className={styles.profile__greetingBtn}
			>
				Подробнее о Клубе Любителей Круизов
			</Button>
		</Typo>
	);
});
