import { createEffect, createEvent, createStore, sample } from 'effector';
import { GetReferralsRequest, referralsRequests } from 'store/account/api/referrals';
import { ReferralModel } from 'store/auth/types/account/get-referrals-200-response-inner';
// import { GetReferralsRequest, referralsRequests } from 'shared/api';
// import { ReferralModel } from 'shared/lib/types/account/models';

const $referralTypes = createStore<ReferralModel[]>([]);

const fetchReferralTypes = createEvent<GetReferralsRequest>();
const fetchReferralTypesFx = createEffect(referralsRequests.getReferrals);

sample({
	clock: fetchReferralTypes,
	target: fetchReferralTypesFx,
});

sample({
	clock: fetchReferralTypesFx.doneData,
	target: $referralTypes,
});

export const model = {
	$referralTypes,
	fetchReferralTypes,
};
