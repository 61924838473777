/* eslint-disable react/jsx-no-useless-fragment */
import { useViewport } from 'layout/viewport';
import { FC, PropsWithChildren, memo } from 'react';
// import { useViewport } from 'shared/lib/viewport';

interface DeviceViewProps extends PropsWithChildren {
	withTablet?: boolean;
}

export const MobileView: FC<DeviceViewProps> = memo(({ withTablet, children }) => {
	const { isMob, isTablet, deviceType } = useViewport();
	const isMobile = isMob || (withTablet && (deviceType === 'tablet' || isTablet));

	if (!isMobile) {
		return null;
	}

	return <>{children}</>;
});

export const DesktopView: FC<DeviceViewProps> = memo(({ withTablet, children }) => {
	const { isMob, isTablet, deviceType } = useViewport();
	const isMobile = isMob || (withTablet && (deviceType === 'tablet' || isTablet));

	if (isMobile) {
		return null;
	}

	return <>{children}</>;
});
