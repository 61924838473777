import { useEffect, useState } from 'react';

export const useDelayUnmount = (isMounted: boolean, delay: number): boolean => {
	const [shouldRender, setShouldRender] = useState(false);

	useEffect(() => {
		let timer: number;

		if (isMounted && !shouldRender) {
			setShouldRender(true);
		} else if (!isMounted && shouldRender) {
			timer = window.setTimeout(() => setShouldRender(false), delay);
		}

		return () => window.clearTimeout(timer);
	}, [isMounted, delay, shouldRender]);

	return shouldRender;
};
