import { reset, status } from 'patronum';
import { OverlayTriggerState } from 'react-stately';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
// import { detailedRequest } from 'widgets/account/model';
// import { countriesModel } from 'entities/countries';
import { documentTypesModel, documentTypesValidationFactory } from 'entities/document-types';
import { getFailCode } from 'api';
import { ADULT_PLACE_TYPES } from 'config/constants/cabin';
import { message } from 'config/constants/message';
import { RequestPassenger } from 'config/types/prices/request-passenger';
import { detailedRequest } from 'entities/account/model';
import { parsePassportData, profileModel } from 'layout/components/auth/profile';
import { accountRequests } from 'store/account/api/requests';
import { UpdatePassengerRequestGenderEnum, UpdatePassengerRequest } from 'store/auth/types/account/update-passenger-request';
import { countriesModel } from 'store/countries';
import { isAdult } from 'utils/date';
import { trimFactory } from 'utils/form';
// import { parsePassportData, profileModel } from 'entities/profile';
// import { accountRequests } from 'shared/api/requests/account/requests';
// import { ADULT_PLACE_TYPES } from 'shared/config';
// import { message } from 'shared/lib/message';
// import { RequestPassenger } from 'shared/lib/types/account/request-passenger';
// import {
// 	UpdatePassengerRequest,
// 	UpdatePassengerRequestGenderEnum,
// } from 'shared/lib/types/account/update-passenger-request';
// import { getFailCode, isAdult, trimFactory } from 'shared/lib/utils';

interface FormValues {
	firstName: string;
	middleName: string;
	lastName: string;
	citizenship: string;
	gender: UpdatePassengerRequestGenderEnum | '';
	documentType: number | '';
	passportData: string;
	passportIssued: string;
	passportIssuedDate: string;
	child: boolean;
	birthday: string;
	childName: string;
	childBirthday: string;
	childDocument: string;
}

const Gate = createGate<RequestPassenger & { isFirstPassenger?: boolean }>();
const GateModal = createGate<OverlayTriggerState>();

const handleSubmit = createEvent<UpdatePassengerRequest>();

const save = createEvent();
const saveFx = createEffect(accountRequests.editPassenger);

const $draft = createStore<Nullable<UpdatePassengerRequest>>(null);

const form = createForm<FormValues>({
	initialValues: {
		firstName: '',
		middleName: '',
		lastName: '',
		citizenship: '',
		documentType: '',
		passportData: '',
		passportIssued: '',
		passportIssuedDate: '',
		gender: '',
		birthday: '',
		child: false,
		childName: '',
		childBirthday: '',
		childDocument: '',
	},
	onSubmit: ({ values }) => {
		const {
			child,
			childBirthday,
			childDocument,
			childName,
			gender,
			passportData,
			citizenship,
			...data
		} = values;

		const children = {
			name: child ? childName : null,
			birthday: child ? childBirthday : null,
			document: child ? childDocument : null,
		};

		let { type } = Gate.state.getState();

		if (child) {
			type = 1;
		} else if (type === 1) {
			type = 0;
		}

		type != undefined
			&& handleSubmit({
				...data,
				...parsePassportData(passportData),
				gender: gender as UpdatePassengerRequestGenderEnum,
				citizenship: Number(citizenship),
				documentType: Number(data.documentType),
				type,
				children,
			});
	},
	validate: ({ values }) => {
		const errors: Partial<Record<keyof FormValues, string>> = {};

		if (values.child) {
			if (!values.childBirthday) {
				errors.childBirthday = message.form.validation.required;
			}
			if (!values.childDocument) {
				errors.childDocument = message.form.validation.required;
			}
			if (!values.childName) {
				errors.childName = message.form.validation.required;
			}
		}

		if (values.birthday) {
			const { type, isFirstPassenger } = Gate.state.getState();

			// Проверяется возраст на соответствие типу только для первого пассажира
			if (isFirstPassenger) {
				const isAdultType = type && type in ADULT_PLACE_TYPES;
				const isAdultBirthday = isAdult(new Date(values.birthday));

				if (isAdultType !== isAdultBirthday) {
					errors.birthday = isAdultType
						? message.form.validation.birthday.adult
						: message.form.validation.birthday.child;
				}
			}
		}

		return errors;
	},
});

const $pattern = createStore<string>('');
const $prevDocumentType = createStore<FormValues['documentType']>('');
const setFromProfile = createEvent<boolean>();

/**
 * Загрузка стран и типов документов
 */
sample({
	clock: Gate.open,
	target: [countriesModel.fetchCountries, documentTypesModel.fetchDocumentTypes],
});

/**
 * Обработка сохранения формы
 */
sample({
	clock: handleSubmit,
	target: $draft,
});

sample({
	clock: handleSubmit,
	source: GateModal.state,
	fn: ({ open }) => open,
	target: createEffect((callback: () => void) => callback()),
});

sample({
	clock: save,
	source: {
		data: $draft,
		state: Gate.state,
	},
	fn: ({ data, state }) => ({
		data: data as UpdatePassengerRequest,
		id: `${state.id}`,
	}),
	target: saveFx,
});

sample({
	clock: saveFx.failData,
	fn: (error) => {
		const code = getFailCode(error) || 'error';

		return {
			field: 'formError',
			error: message.requests.editPassenger[code] || message.requests.editPassenger.error,
		};
	},
	target: form.setOrDeleteError,
});

/**
 * Установка началных значений формы
 */
sample({
	source: {
		passenger: Gate.state,
		countries: countriesModel.$countries,
	},
	fn: ({ passenger, countries }) => ({
		firstName: passenger.firstName || '',
		middleName: passenger.middleName || '',
		lastName: passenger.lastName || '',
		citizenship: String(
			passenger.citizenship?.id || countries.find(({ name }) => name === 'Россия')?.id || '',
		),
		documentType: passenger.documentType || ('' as const),
		gender: passenger.gender
			? (passenger.gender as unknown as UpdatePassengerRequestGenderEnum)
			: ('' as const),
		passportData: [passenger.passportSeries, passenger.passportNumber].filter(Boolean).join(' '),
		birthday: passenger.birthday || '',
		child:
			Boolean(
				passenger.children?.birthday || passenger.children?.document || passenger.children?.name,
			) || passenger.type === 1,
		childBirthday: passenger.children?.birthday || '',
		childName: passenger.children?.name || '',
		childDocument: passenger.children?.document || '',
		passportIssued: passenger.passportIssued || '',
		passportIssuedDate: passenger.passportIssuedDate || '',
	}),
	target: form.setValues,
});

sample({
	clock: countriesModel.$countries,
	source: form.$values,
	filter: (values) => !values.citizenship,
	fn: (_, countries) => ({
		field: 'citizenship',
		value: countries.find(({ name }) => name === 'Россия')?.id || '235',
	}),
	target: form.setValue,
});

/**
 * Установка значения из профиля
 */
sample({
	clock: setFromProfile,
	source: {
		profile: profileModel.$profile,
		values: form.$values,
	},
	fn: ({ profile, values }, fromProfile) => ({
		...values,
		firstName: fromProfile ? profile?.passportData.firstName || '' : '',
		middleName: fromProfile ? profile?.passportData.middleName || '' : '',
		lastName: fromProfile ? profile?.passportData.lastName || '' : '',
		citizenship: fromProfile ? String(profile?.passportData.citizenship) || '' : '',
		documentType: fromProfile ? profile?.passportData.documentType || ('' as const) : '',
		gender: fromProfile ? profile?.passportData.gender || ('' as const) : '',
		passportData: fromProfile
			? [profile?.passportData.passportSeries, profile?.passportData.passportNumber]
				.filter(Boolean)
				.join(' ')
			: '',
		birthday: fromProfile ? profile?.passportData.birthday || '' : '',
		child: values.child,
		passportIssued: '',
		passportIssuedDate: '',
	}),
	target: form.setValues,
});

/* *
 * Валидация по установленному паттерну
 */
const { $scheme: $documentTypesValidation } = documentTypesValidationFactory({
	$pattern,
	form,
	field: 'passportData',
	required: true,
});

/**
 * Нахождение паттерна выбранного документа
 */
sample({
	source: {
		activeId: form.$values.map(({ documentType }) => documentType),
		types: documentTypesModel.$documentTypes,
	},
	filter: Boolean,
	fn: ({ activeId, types }) => types.find(({ id }) => id === activeId)?.pattern || '',
	target: $pattern,
});

/**
 * Сброс ввёденного значения, при смене типа документа
 */
sample({
	source: {
		prev: $prevDocumentType,
		value: form.$values.map(({ passportData }) => passportData),
	},
	clock: form.$values.map(({ documentType }) => documentType),
	fn: ({ prev, value }, current) => ({
		field: 'passportData',
		value: prev !== current && prev !== '' ? '' : value,
	}),
	target: form.setValue,
});

sample({
	clock: form.$values.map(({ child }) => child),
	fn: (child) => (child ? 1 : 0),
	target: $prevDocumentType,
});

sample({
	clock: form.$values.map(({ documentType }) => documentType),
	fn: (current) => current,
	target: $prevDocumentType,
});

const $status = status({ effect: saveFx });

sample({
	clock: saveFx.doneData,
	target: detailedRequest.getDetailedRequest,
});

/* *
 * Set child value
 */
const setChildValue = createEvent<boolean>();

sample({
	clock: setChildValue,
	fn: (value) => ({
		field: 'child',
		value,
	}),
	target: form.setValue,
});

sample({
	clock: Gate.open,
	filter: ({ type }) => type === 1,
	fn: () => ({
		field: 'child',
		value: true,
	}),
	target: form.setValue,
});

reset({
	clock: GateModal.close,
	target: $status,
});

trimFactory(form, [
	'firstName',
	'middleName',
	'lastName',
	'passportIssued',
	'childName',
	'childDocument',
]);

export const model = {
	form,
	Gate,
	GateModal,
	$pattern,
	setFromProfile,
	setChildValue,
	$status,
	save,
	$documentTypesValidation,
};
