import { createEffect, createEvent, sample, Store } from 'effector';

export const sessionStorageFactory = <T>(
	name: string,
	$store: Store<T>,
	initialValue: T | string = '[]',
) => {
	const removeFromSessionFx = createEffect(() => {
		sessionStorage.removeItem(name);
	});

	const saveDataToSessionFx = createEffect((data: T) => {
		sessionStorage.setItem(name, JSON.stringify(data));
	});

	const parseDataFromSessionFx = createEffect(async () => {
		const data = sessionStorage.getItem(name) ?? initialValue;

		return JSON.parse(data as string) as T;
	});

	const parseDataFromSession = createEvent();

	sample({
		clock: $store,
		target: saveDataToSessionFx,
	});

	sample({
		clock: parseDataFromSession,
		target: parseDataFromSessionFx,
	});


	// TODO: включить типизцию (ругается с ней на clock)
	sample({
		clock: parseDataFromSessionFx.doneData,
		target: $store,
	} as any);

	return {
		parseDataFromSession,
		saveDataToSessionFx,
		parseDataFromSessionFx,
		removeFromSessionFx,
	};
};
