import { useRef } from 'react';
import { useFocus, useHover, useLongPress, useOverlay, usePress } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { useDelayUnmount } from './use-delay-unmount';

interface UsePopoverProps {
	/**
	 * Unmount delay
	 */
	delay?: number;
	/**
	 * Whether to close the overlay when the user interacts outside it.
	 */
	isDismissable?: boolean;
	/**
	 * For clickable triggers
	 */
	isClickable?: boolean;
}

export const usePopover = ({ delay, isDismissable, isClickable }: UsePopoverProps) => {
	const triggerRef = useRef(null);
	const popoverRef = useRef(null);

	const state = useOverlayTriggerState({});
	const { isOpen, setOpen } = state;

	const { overlayProps } = useOverlay(
		{
			isOpen,
			onClose: () => state.close(),
			isDismissable: isDismissable ?? false,
		},
		popoverRef,
	);

	const { hoverProps } = useHover({
		onHoverStart: () => setOpen(true),
		onHoverEnd: () => setOpen(false),
	});

	const { focusProps } = useFocus({
		onFocus: () => setOpen(true),
		onBlur: () => setOpen(false),
	});

	const { pressProps } = usePress({
		onPressStart: (e) => {
			setOpen(true);
		},
	});

	const { longPressProps } = useLongPress({
		onLongPress: (e) => {
			if (e.pointerType === 'touch') {
				setOpen(true);
			}
		},
		threshold: 300,
	});

	const shouldMount = useDelayUnmount(isOpen, delay ?? 600);

	return {
		triggerRef,
		popoverRef,
		overlayProps,
		hoverProps,
		focusProps,
		pressProps: isClickable ? longPressProps : pressProps,
		shouldMount,
		state,
	};
};
