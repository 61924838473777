import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { BonusCardGreeting, JoinToBonusProgram, bonusCardModel } from 'entities/bonus-card';
// import { profileModel } from 'entities/profile';
// import { Skeleton } from 'shared/ui/atoms';
import { MyBonuses } from './my-bonuses';
import styles from './styles.module.scss';
import { profileModel } from 'layout/components/auth/profile';
import { Skeleton } from 'layout/components/special/skeleton';

interface ProfileBonusesProps {
	isLoading?: boolean;
}

export const ProfileBonuses: FC<ProfileBonusesProps> = ({ isLoading }) => {
	const profile = useUnit(profileModel.$profile);
	const [bonusCardStatus, bonusCardError, onboarded] = useUnit([
		bonusCardModel.$status,
		bonusCardModel.$errorCode,
		bonusCardModel.$onboarded,
	]);

	if (isLoading || bonusCardStatus === 'pending') {
		return <ProfileBonusesSkeleton />;
	}

	if (!profile) {
		return null;
	}

	if (bonusCardError === 404) {
		return <JoinToBonusProgram title="Хотите оплатить баллами круиз?" />;
	}

	return (
		<>
			{onboarded === false && <BonusCardGreeting />}
			<MyBonuses />
		</>
	);
};

export const ProfileBonusesSkeleton = () => (
	<section className={styles.bonuses}>
		<Skeleton className={styles.bonuses__skeletonHead} />
		<Skeleton className={styles.bonuses__skeletonCard} />
	</section>
);
