import React, { FC, memo } from 'react';
import { getSrcset } from './lib';

/* *
 * Нативные картинки с нативным loading="lazy".
 * Можно применять для loop-слайдеров: когда слайдер клонируется настоящие слайды,
 * реакт-компоненты внутри них перестают работать, проще всего использовать нативные картинки
 */

export interface ImgProps extends Omit<JSX.IntrinsicElements['img'], 'loading'> {
	priority?: boolean;
}

export const Img: FC<ImgProps> = memo(
	({ src, srcSet, sizes, width, height, alt = '', priority, className, ...props }) => {
		const img = src && getSrcset(src);

		if (!img) {
			return null;
		}

		return (
			<img
				src={img.src}
				sizes={sizes ?? img.sizes}
				srcSet={srcSet ?? img.srcSet}
				alt={alt}
				width={width}
				height={height}
				className={className}
				loading={priority ? 'eager' : 'lazy'}
				{...props}
			/>
		);
	},
);
