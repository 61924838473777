import { createEffect, Event, sample, Store } from 'effector';
import { Form } from 'effector-react-form';

interface FactoryType<T> {
	$value: Store<Nullable<T>>;
	setValue: Event<Nullable<T>>;
	confirmValue: Event<void>;
	reset: Event<void>;
}

export function promoSessionStorageFactory<Values extends object, T>({
	factory,
	parseEvent,
	resetEvent,
	name,
	form,
}: {
	form: Form<Values>;
	factory: FactoryType<T>;
	parseEvent: Event<any>[];
	resetEvent?: Event<any>[];
	name: string;
}) {
	const setToSessionStoreFx = createEffect((value: string) => {
		sessionStorage.setItem(name, JSON.stringify(value));
	});

	const resetSessionStoreFx = createEffect(() => {
		sessionStorage.removeItem(name);
	});

	const getFromSessionStoreFx = createEffect(async () => {
		const data = sessionStorage.getItem(name);

		return data && data.length > 0 ? JSON.parse(data) : null;
	});

	sample({
		clock: factory.confirmValue,
		source: factory.$value,
		fn: (value) => value as string,
		target: setToSessionStoreFx,
	});

	// TODO: добавить типизацию (отключил из-за ошибки clock)
	if (resetEvent) {
		sample({
			clock: resetEvent,
			target: [factory.reset, resetSessionStoreFx],
		} as any);
	}

	sample({
		clock: parseEvent,
		target: getFromSessionStoreFx,
	});

	// TODO: добавить типизацию (отключил из-за ошибки clock)
	sample({
		clock: getFromSessionStoreFx.doneData,
		filter: Boolean,
		target: factory.setValue,
	} as any);

	sample({
		clock: getFromSessionStoreFx.doneData,
		fn: (value) => ({
			field: name,
			value,
		}),
		target: form.setValue,
	});
}
