import { EffectState } from 'patronum/status';
import { createEvent, createStore, createEffect, sample } from 'effector';
import { ProfileModel } from 'config/types/personal';
import { PROFILE_TYPES } from 'store/auth/constants';
import { getFilledEcommerceObject } from 'utils/analytics/ecommerce';


export interface ProfileDraftModel extends Omit<ProfileModel, 'office'> {
	officeId: ProfileModel['office']['id'];
	office: undefined;
}

const $profile = createStore<Nullable<ProfileModel>>(null);

const $accessToken = createStore<Nullable<string>>(null);

const $profileDraft = createStore<Nullable<ProfileDraftModel>>(null);
const resetDraft = createEvent();

const $profileDraftHasBeenChanged = createStore(false);

sample({
	source: { profile: $profile, draft: $profileDraft },
	filter: ({ profile, draft }) => Boolean(profile && draft),
	fn: ({ profile, draft }) => {
		const { officeId, office: draftOffice, ...draftWithoutOfficeId } = draft as ProfileDraftModel;
		const { office, ...profileWithoutOffice } = profile as ProfileModel;
		const strProfile = JSON.stringify(profileWithoutOffice);
		const strDraft = JSON.stringify(draftWithoutOfficeId);

		return strProfile !== strDraft || office.id !== officeId;
	},
	target: $profileDraftHasBeenChanged,
});

const pushProfilatorUserType = createEffect(
	({ type, id }: { type: number | undefined; id: number | undefined }) => {
		if (type === PROFILE_TYPES.TYPE_STAFF) {
			return;
		}

		let profilatorUserType = 'agency' as Nullable<string>;

		if (type === PROFILE_TYPES.TYPE_RETAIL) {
			profilatorUserType = 'privatePerson';
		}

		if (type === undefined) {
			profilatorUserType = null;
		}

		// @ts-ignore
		const datalayerUserType = window.dataLayer.find((el) => el.hasOwnProperty('userType'));
		// @ts-ignore
		const datalayerUserId = window.dataLayer.find((el) => el.hasOwnProperty('userId'));

		if (datalayerUserType) {
			datalayerUserType.userType = profilatorUserType;
		} else {
			// @ts-ignore
			window.dataLayer.push(
				getFilledEcommerceObject({
					userType: profilatorUserType,
				}),
			);
		}

		if (!datalayerUserId) {
			// @ts-ignore
			window.dataLayer.push(
				getFilledEcommerceObject({
					userId: id,
				}),
			);
		}
	},
);

sample({
	clock: $profile,
	fn: (profile) => {
		const dataLayerData = {
			type: profile?.type,
			id: profile?.id,
		};

		return dataLayerData;
	},
	target: pushProfilatorUserType,
});

sample({
	clock: [resetDraft, $profile],
	source: $profile,
	fn: (profile) => {
		if (!profile || !("id" in profile)) {
			return null;
		}

		return {
			...profile,
			office: undefined,
			officeId: profile.office.id,
		};
	},
	target: $profileDraft,
});

const $profileStatus = createStore<EffectState>('initial');

export const model = {
	$profile,
	$profileDraft,
	$profileStatus,
	$accessToken,
	$profileDraftHasBeenChanged,
	resetDraft,
};
