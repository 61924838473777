import { ProfileModel } from "config/types/personal";


export const getLabel = (profile: Nullable<ProfileModel>) => {
	if (profile && profile?.passportData.lastName && profile?.passportData.firstName) {
		const { lastName, firstName, middleName } = profile.passportData;

		return `${lastName} ${firstName[0]}.${middleName ? `${middleName[0]}.` : ''}`;
	}

	return 'Личный кабинет';
};
