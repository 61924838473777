import { MaskTokens } from 'maska';

/* *
 * Описание токенов из документа ФНС
 * https://www.consultant.ru/document/cons_doc_LAW_23381/6099bc5d3732d437ab4b349893ea121420d1e0cd/
 *
 * R - на месте одного символа R располагается целиком римское число,
 * заданное символами "I", "V", "X", "L", "C", набранными на верхнем регистре латинской клавиатуры;
 * возможно представление римских чисел с помощью символов "1", "У", "Х", "Л", "С" соответственно набранных на верхнем регистре русской клавиатуры;
 *
 * Б - любая русская заглавная буква;
 *
 * S - символ не контролируется (может содержать любую букву, цифру или вообще отсутствовать);
 *
 * 9 - любая десятичная цифра (обязательная);
 *
 * 0 - любая десятичная цифра (необязательная, может отсутствовать);
 */

export const DOCUMENT_TYPES_MASK_TOKENS: MaskTokens = {
	R: {
		pattern: /[1CILVXЛСУХ]/,
		transform: (char) => char.toUpperCase(),
		multiple: true,
	},
	Б: {
		pattern: /[А-я-]/,
		transform: (char) => char.toUpperCase(),
	},
	S: {
		pattern: /[\d\sA-Za-zА-я-]/,
		transform: (char) => char.toUpperCase(),
		multiple: true,
	},
	9: {
		pattern: /\d/,
		optional: false,
	},
	0: {
		pattern: /\d/,
		optional: true,
	},
};
