import ReactDOM from 'react-dom/client';
import React, { FC } from 'react';
import 'shared/styles/index.scss';
// import './App.css';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen'
import { loadProfileModel, useLoadProfileFromLocalStorage } from 'layout/components/auth/load-profile';
import { profileModel } from 'layout/components/auth/profile';

// Create a new router instance
const router = createRouter({ routeTree })

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

interface IAppProps {
  place?: "auth" | "modal" | "standart"
  store?: any
}

const App: FC<IAppProps> = (props) => {
  const u = useLoadProfileFromLocalStorage()

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;