import { OverlayTriggerState } from 'react-stately';
import { createEffect, createEvent, sample } from 'effector';
import { createGate } from 'effector-react';

export const modalFactory = () => {
	const ModalGate = createGate<OverlayTriggerState>();
	const openModal = createEvent();
	const closeModal = createEvent();
	const toggleModal = createEvent();
	const executeFx = createEffect((callback: () => void) => callback());

	sample({
		source: ModalGate.state,
		clock: openModal,
		fn: ({ open }) => open,
		target: executeFx,
	});

	sample({
		source: ModalGate.state,
		clock: closeModal,
		fn: ({ close }) => close,
		target: executeFx,
	});

	sample({
		source: ModalGate.state,
		clock: toggleModal,
		fn: ({ toggle }) => toggle,
		target: executeFx,
	});

	return {
		ModalGate,
		openModal,
		closeModal,
		toggleModal,
	};
};
