import React, { MouseEvent, MouseEventHandler, useMemo } from 'react';
import { useUnit } from 'effector-react';
// import { bookingSupportGuestModel, bookingSupportModel } from 'features/booking-support/model';
import { cartModel } from 'entities/cart';
import { cruiseModel } from 'entities/cruises/model';
// import { profileModel } from 'entities/profile';
// import { CartButton, CartButtonProps, Tooltip } from 'shared/ui/atoms';
import { AddToCartParams, model } from '../../model';
import styles from './styles.module.scss';
import { profileModel } from 'layout/components/auth/profile';
import { Tooltip } from 'layout/components/special/tooltip';
import { CartButtonProps, CartButton } from 'layout/components/buttons/cart-button';
import { bookingSupportModel, bookingSupportGuestModel } from 'entities/booking-support/model';

interface CartControlButtonProps extends Omit<CartButtonProps, 'id'> {
	cabin: AddToCartParams;
	onClickAdd?: () => void;
}

export const CartControlButton = ({ cabin, onClickAdd, ...props }: CartControlButtonProps) => {
	const [add, remove, cart, bookingDisable, cruise] = useUnit([
		model.addToCart,
		model.removeFromCart,
		cartModel.$cart,
		cruiseModel.$bookingDisable,
		cartModel.$activeCruise,
	]);

	const isAdded = useMemo(
		() => cart.some(({ id }) => id === cabin.id && cabin.cruiseId === cruise),
		[cart, cabin.id, cabin.cruiseId, cruise],
	);

	const profile = useUnit(profileModel.$profile);
	const authorized = Boolean(profile);

	const fastBookingModel = authorized ? bookingSupportModel : bookingSupportGuestModel;
	const fastBooking = useUnit(fastBookingModel.openModal);

	const { fullView, status, disabled } = props;
	const isDisabled = status === 'disabled' || disabled;

	/**
	 * Добавляем в корзину, если ещё не добавлено. Удаляем, если добавлено.
	 */
	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		if (bookingDisable) {
			fastBooking();

			return;
		}
		if (!isAdded) {
			add(cabin);
			onClickAdd?.();
		} else {
			remove(cabin);
		}
	};

	// const tooltipText = isAdded ? 'Убрать из заказа' : 'Добавить в заказ';
	const tooltipText = isAdded ? 'Убрать из заказа' : 'Бронировать сейчас';
	const cartButton = (
		<CartButton {...props} isAdded={isAdded} disabled={isDisabled} onClick={(e) => handleClick(e)} />
	);

	if (fullView || isDisabled) {
		return cartButton;
	}

	return (
		// <div>CartControlButton</div>
		<Tooltip
			placement="top"
			trigger={cartButton}
			classNamePopover={styles.popover}
			notUseOnFocus
			isDismissable
			isClickable
		>
			{tooltipText}
		</Tooltip>
	);
};
