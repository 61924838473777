import { createEffect, createEvent, createStore, sample } from 'effector';
// import { footerModel } from 'entities/footer';
// import { newsRequest } from 'shared/api';
// import {
// 	COMPACT_HEADER_MENU,
// 	GENERAL_EXTRAORDINARY_NEWS,
// 	HEADER_MENU,
// 	NEWS_CRUISES_SELECTION_CAT_ID,
// 	NEWS_MAX_PER_PAGE,
// 	NEWS_POPULAR_ROUTES_CAT_ID,
// 	NEWS_RECOMMENDATIONS_CAT_ID,
// } from 'shared/config';
// import { HeaderMenuItem, PostCard } from 'shared/lib/types';
import { getMappedData, getPageLink, mutateHeaderMenu } from '../lib';
import { newsRequest } from 'store/news';
import { PostCard } from 'config/types/posts';
import { HeaderMenuItem } from 'config/types/header';
import { COMPACT_HEADER_MENU, HEADER_MENU } from 'config/constants/header';
import { NEWS_CRUISES_SELECTION_CAT_ID, NEWS_MAX_PER_PAGE, NEWS_POPULAR_ROUTES_CAT_ID, NEWS_RECOMMENDATIONS_CAT_ID } from 'config/constants/news';
import { footerModel } from '../../footer';
import { GENERAL_EXTRAORDINARY_NEWS } from 'config/constants/cruises';

const getData = createEvent();
const getSelectionsAndRoutesFx = createEffect(newsRequest.getNews);

const $srcData = createStore<PostCard[]>([]);
const $menuItems = createStore<HeaderMenuItem[]>(HEADER_MENU);
const $compactMenuItems = createStore<HeaderMenuItem[]>(COMPACT_HEADER_MENU);

sample({
	clock: getData,
	fn: () => ({
		categoryId: [
			NEWS_CRUISES_SELECTION_CAT_ID,
			NEWS_POPULAR_ROUTES_CAT_ID,
			NEWS_RECOMMENDATIONS_CAT_ID,
		].join(','),
		perPage: NEWS_MAX_PER_PAGE,
		sortBy: 'priority' as const,
	}),
	target: getSelectionsAndRoutesFx,
});

sample({
	clock: getSelectionsAndRoutesFx.doneData,
	fn: ({ data }) => data ?? [],
	target: $srcData,
});

sample({
	clock: [
		$srcData,
		footerModel.$touristsHeaderPages,
		footerModel.$companiesHeaderPages,
		footerModel.$abousHeaderPages,
	],
	source: {
		data: $srcData,
		menu: $menuItems,
		tourists: footerModel.$touristsHeaderPages,
		companies: footerModel.$companiesHeaderPages,
		about: footerModel.$abousHeaderPages,
	},
	fn: ({ data, menu, tourists, companies, about }) =>
		mutateHeaderMenu(
			[...menu],
			getMappedData(
				data.filter((item) => item.id !== GENERAL_EXTRAORDINARY_NEWS),
				{
					tourists: tourists.map((elem) =>
						getPageLink({
							...elem,
							page: 'info',
						}),
					),
					companies: companies.map(getPageLink),
					about: about.map((elem) =>
						getPageLink({
							...elem,
							page: 'info',
						}),
					),
				},
			),
		),
	target: $menuItems,
});

sample({
	clock: $menuItems,
	fn: (menuItems) => menuItems.filter(({ compact }) => compact),
	target: $compactMenuItems,
});

export const model = {
	getData,
	$menuItems,
	$compactMenuItems,
};
