import { combineEvents } from 'patronum';
// import { GetServerSidePropsContext } from 'next/types';
import { createEvent, sample } from 'effector';
import { createGate } from 'effector-react';
// import { headerModel } from 'features/header';
import { bonusCardModel } from 'entities/bonus-card';
import { cartModel } from 'entities/cart';
import { cruiseModel } from 'entities/cruises/model';
// import { footerModel } from 'entities/footer';
import { orderCabinsModel } from 'entities/order';
import { footerModel } from 'layout/components/view/footer';
import { headerModel } from 'layout/components/view/header';
// import { GetServerSidePropsContext } from 'next';

/* *
 * SSR
 */
// TODO: подобрать тип данных взамен
// const getData = createEvent<GetServerSidePropsContext>();
const getData = createEvent<any>();

sample({
	clock: getData,
	target: [headerModel.getData, footerModel.getData],
});

/* *
 * CSR
 */
const Gate = createGate();

sample({
	clock: Gate.open,
	target: [
		orderCabinsModel.parseDataFromSession,
		cartModel.getCartPrices,
		bonusCardModel.getBonusCard,
	],
});

sample({
	clock: combineEvents({
		events: [Gate.open, cartModel.loadCruiseFromLSFx.doneData],
	}),
	source: {
		cruiseId: cartModel.$activeCruise,
	},
	filter: ({ cruiseId }) => Boolean(cruiseId),
	fn: ({ cruiseId }) => ({ id: cruiseId?.toString() ?? '' }),
	target: [cruiseModel.getCruise, cruiseModel.getCabins],
});

export const model = {
	getData,
	Gate,
};
