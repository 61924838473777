import { isSafari } from "./is-safari";


export const isSafariVersionHigher = (targetVersion: string) => {
	if (typeof window === 'undefined' || !isSafari()) {
		return null;
	}

	const { userAgent } = window.navigator;
	const safariVersionString = userAgent.match(/Version\/([\d.]+)/);

	if (safariVersionString) {
		const currentVersion = safariVersionString[1].split('.').map(Number.parseFloat);
		const targetVersionArray = targetVersion.split('.').map(Number.parseFloat);

		for (let i = 0; i < Math.max(currentVersion.length, targetVersionArray.length); i++) {
			const currentPart = currentVersion[i] || 0;
			const targetPart = targetVersionArray[i] || 0;

			if (currentPart !== targetPart) {
				return currentPart >= targetPart;
			}
		}

		return false; // Версии полностью совпадают
	}

	return false;
};
