import { FC, memo, useEffect } from 'react';
import { useEvent } from 'effector-react';
import { viewportModel } from './model';
import { getBpFlag, getScrollBarWidth, getVpHelpers } from 'utils/viewport';
import { isTouchDevice } from 'utils/tests';

/* *
 * --vh: высота вьюпорта (не прыгает на мобильном)
 * --vw: ширина вьюпорта без учета скролл-бара
 * --ow: ширина вьюпорта вместе со скроллбаром
 * --sgap: gap между контейнером и границей экрана
 */
const [helper, helperContainer] = getVpHelpers();

export const ViewportHelper: FC = memo(
	() => {
		const viewportModelUpdate = useEvent(viewportModel.update);

		useEffect(() => {
			let mounted = true;
			const isSSR = typeof window === 'undefined';

			if (isSSR || !mounted) {
				return;
			}

			const isTouch = isTouchDevice();

			document.documentElement.classList.toggle('is-touch', isTouch);
			document.documentElement.classList.toggle('is-mouse', !isTouch);

			const handleResize = () => {
				const vw = document.documentElement.clientWidth;
				const vh = helper ? helper.offsetHeight : document.documentElement.clientHeight;
				const vhInner = typeof window !== 'undefined' ? window.innerHeight : vh;
				const sb = getScrollBarWidth();
				const out = helperContainer.offsetLeft;
				const bp = getBpFlag(vw + sb);
				const isMob = ['xs', 'sm'].includes(bp);
				const isTablet = ['md'].includes(bp);

				const helperContainerStyle = getComputedStyle(helperContainer);
				const inner =
					helperContainer.clientWidth -
					(Number.parseFloat(helperContainerStyle.paddingLeft) +
						Number.parseFloat(helperContainerStyle.paddingRight));

				viewportModelUpdate({
					vw,
					vh,
					sb,
					out,
					bp,
					isMob,
					isTablet,
				});

				document.documentElement.style.setProperty('--vw', `${Math.max(320, vw)}px`);
				document.documentElement.style.setProperty('--vh', `${vh}px`);
				document.documentElement.style.setProperty('--vh-inner', `${vhInner}px`);
				document.documentElement.style.setProperty('--ow', `${vw + sb}px`);
				document.documentElement.style.setProperty('--sgap', `${out}px`);
				document.documentElement.style.setProperty('--cgap', `${(vw - inner) / 2}px`);
				document.documentElement.style.setProperty('--sb', `${sb}px`);
			};

			handleResize();
			window.addEventListener('load', handleResize, { passive: true });
			window.addEventListener('resize', handleResize, { passive: true });

			/* *
			 * Detect mouse/keyboard input
			 */
			let usingMouse = true;

			const handleKeydown = (e: KeyboardEvent) => {
				if (!usingMouse) {
					return;
				}

				const keyCode = 'which' in e ? e.which : null;

				if (e && e.key !== 'Tab' && keyCode !== 9) {
					return;
				}

				usingMouse = false;
				document.documentElement.classList.toggle('keyboard', true);
			};

			const handleMousedown = () => {
				if (usingMouse) {
					return;
				}
				usingMouse = true;
				document.documentElement.classList.toggle('keyboard', false);
			};

			if (!isTouch) {
				window.addEventListener('keydown', handleKeydown);
				window.addEventListener('mousedown', handleMousedown);
			}

			/* *
			 * Detect soft keyboard height
			 */
			const handleVisualViewport = () => {
				const vh = helper ? helper.offsetHeight : document.documentElement.clientHeight;
				const visvh = window.visualViewport?.height ?? vh;

				document.documentElement.style.setProperty('--kb', `${vh - visvh}px`);
			};

			if (isTouch) {
				handleVisualViewport();
				window.visualViewport?.addEventListener('resize', handleVisualViewport);
			}

			return () => {
				mounted = false;

				window.removeEventListener('load', handleResize);
				window.removeEventListener('resize', handleResize);
				window.removeEventListener('orientationchange', handleResize);
				window.removeEventListener('keydown', handleKeydown);
				window.removeEventListener('mousedown', handleMousedown);

				window.visualViewport?.removeEventListener('resize', handleVisualViewport);
			};
		}, [viewportModelUpdate]);

		return null;
	},
	() => true,
);
