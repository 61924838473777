export enum OrderRoutes {
	Tourists = '/order',
	// AddServices = '/order/add-services',
	Customer = '/order/customer',
	Confirmation = '/order/confirmation',
}

export const orderSteps = [
	{
		label: 'Данные туристов',
		route: OrderRoutes.Tourists,
	},
	// {
	// 	label: 'Доп. услуги',
	// 	route: OrderRoutes.AddServices,
	// 	onlyWithPaidExcursions: true,
	// },
	{
		label: 'Данные заказчика',
		route: OrderRoutes.Customer,
		guestOnly: true,
	},
	{
		label: 'Подтверждение заявки',
		route: OrderRoutes.Confirmation,
	},
];

export const REQUEST_ENDPOINT = '/api/account/v1/requests';
export const REQUEST_BITRIX_ENDPOINT = '/api/account/v1/requests/lead';

export const CUSTOMER_REQUIRED = true;
export const BONUS_PAYMENT_ENABLED = false;

export const MIN_YEAR = 1902;
export const MIN_BIRTHDAY_DATE = '01.01.1902';
