import { RefObject, useRef } from 'react';
import cn from 'classnames';
import { DismissButton, usePopover, AriaPopoverProps } from 'react-aria';
import { OverlayTriggerState } from 'react-stately';
// import { DefaultStyledProps, FCWithChildren } from 'shared/lib/types';
// import { useViewport } from 'shared/lib/viewport';
import { Overlay } from './overlay';
import style from './style.module.scss';
import { DefaultStyledProps, FCWithChildren } from 'config/commonTypes';
import { useViewport } from 'layout/viewport';

export interface PopoverProps
	extends Omit<AriaPopoverProps, 'popoverRef'>,
		Omit<DefaultStyledProps, 'style'> {
	state: OverlayTriggerState;
	popoverRef?: RefObject<HTMLDivElement>;
	minWidthFactor?: number;
	maxWidthFactor?: number;
	maxWidth?: number;
	fixedPosition?: boolean;
	correctMaxHeight?: boolean;
	disableFocusManagement?: boolean;
	portal?: HTMLElement;
}

export const Popover: FCWithChildren<PopoverProps> = (props) => {
	const ref = useRef<HTMLDivElement>(null);
	const {
		popoverRef = ref,
		triggerRef,
		state,
		children,
		isNonModal,
		className,
		minWidthFactor,
		maxWidthFactor,
		maxWidth,
		fixedPosition,
		correctMaxHeight,
		placement,
		disableFocusManagement,
		portal,
	} = props;

	const { popoverProps, underlayProps } = usePopover(
		{
			...props,
			popoverRef,
		},
		state,
	);

	const flip = Boolean(popoverProps?.style?.bottom);

	const { isMob, vw } = useViewport();

	const setArrowPosition = () => {
		if (triggerRef.current) {
			const left = typeof popoverProps.style?.left === 'number' ? popoverProps.style?.left : 16;
			const width = triggerRef.current?.getBoundingClientRect()?.width;
			const triggerLeft = triggerRef.current?.getBoundingClientRect()?.left ?? 0;

			popoverRef.current?.style.setProperty(
				'--arrow-left',
				width ? `${width / 2 + (triggerLeft - left)}px` : '50%',
			);
		}
	};

	const getSizeStyles = () => {
		const width = (triggerRef?.current as HTMLElement)?.offsetWidth || undefined;

		setArrowPosition();

		if (isMob) {
			return {
				width,
				maxWidth: vw - 32,
				left: popoverProps.style?.left,
			};
		}

		if (width && minWidthFactor) {
			return {
				width: 'auto',
				minWidth: width * minWidthFactor,
				maxWidth: width,
			};
		}

		if (width && maxWidthFactor) {
			return {
				width,
				maxWidth: width * maxWidthFactor,
			};
		}

		if (maxWidth) {
			return {
				width,
				maxWidth,
			};
		}

		return { minWidth: width };
	};

	const getPosition = () => {
		if (fixedPosition && placement !== 'top') {
			const top =
				typeof popoverProps.style?.top === 'number'
					? popoverProps.style.top - window.scrollY
					: popoverProps.style?.top;

			return {
				position: 'fixed' as const,
				top,
			};
		}

		return {
			position: popoverProps.style?.position,
			top: popoverProps.style?.top,
		};
	};

	const getMaxHeight = () => {
		const maxHeight =
			typeof popoverProps.style?.maxHeight === 'number' ? popoverProps.style?.maxHeight : 208;

		return {
			maxHeight: correctMaxHeight ? Math.max(maxHeight, 208) : popoverProps.style?.maxHeight,
		};
	};

	const defaultPortal = document.getElementById('portal-root');

	if (!defaultPortal) {
		return null;
	}

	return (
		<Overlay
			portalContainer={portal || defaultPortal}
			disableFocusManagement={disableFocusManagement}
		>
			{!isNonModal && <div {...underlayProps} className="fixed inset-0" />}
			<div
				{...popoverProps}
				ref={popoverRef}
				style={{
					...popoverProps.style,
					...getSizeStyles(),
					...getPosition(),
					...getMaxHeight(),
					zIndex: isNonModal ? undefined : popoverProps.style?.zIndex,
				}}
				className={cn(style.popover, isNonModal && style.isNonModal, className, { flip })}
			>
				{!isNonModal && <DismissButton onDismiss={state.close} />}
				{children}
				{!disableFocusManagement && <DismissButton onDismiss={state.close} />}
			</div>
		</Overlay>
	);
};
