/* eslint-disable react/no-array-index-key */
import React, { FC, memo } from 'react';
import { useUnit } from 'effector-react';
import { pricesModel } from 'entities/order/index';
// import { Typo } from 'shared/ui/atoms';
import { SummaryTable, SummaryTableItem } from '../summary-table';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';

/* *
 * bonusWrittenOf - количество баллов, которое пользователь указывает на последнем этапе
 */

export interface RequestCruiseFeaturesProps {
	bonusWrittenOf?: number;
}

export const AfterConfirmation: FC<RequestCruiseFeaturesProps> = memo(({ bonusWrittenOf }) => {
	const [availableDiscountsSum, availableDiscounts, status] = useUnit([
		pricesModel.$pricesPreviewAvailableDiscountsSum,
		pricesModel.$pricesPreviewAvailableDiscountsMerged,
		pricesModel.$getPricesPreviewStatus,
	]);
	const pending = status === 'pending';

	if (!pending && availableDiscounts.length === 0) {
		return null;
	}

	return (
		<div className={styles.box}>
			<Typo className={styles.head} design="headline-xs" freezeDesktop>
				Будет доступно после подтверждения
			</Typo>

			<SummaryTable>
				<SummaryTableItem
					label="Персональные скидки"
					price={availableDiscountsSum}
					priceType="discount"
					defaultExpanded
					pending={pending}
				>
					{availableDiscounts.map(({ id, title, amount }) => {
						if (!(title && amount)) {
							return null;
						}

						return (
							<SummaryTableItem
								key={id + encodeURI(title)}
								label={title}
								price={amount}
								priceType="discount"
								pending={pending}
							/>
						);
					})}
				</SummaryTableItem>

				{bonusWrittenOf && (
					<SummaryTableItem label="Списано баллами" price={bonusWrittenOf} priceType="discount" />
				)}
			</SummaryTable>
		</div>
	);
});
