import React, { FC, memo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
// import { MoreToggle, Typo } from 'shared/ui/atoms';
import { Cruise } from './cruise';
import { Customer } from './customer';
import { Ship } from './ship';
import styles from './styles.module.scss';
import { MoreToggle } from 'layout/components/buttons/more-toggle';
import { Typo } from 'layout/components/typo/ui';

export interface RequestCruiseDetailsProps {}

export const RequestCruiseDetails: FC<RequestCruiseDetailsProps> = memo(() => {
	const [expanded, setExpanded] = useState(true);

	return (
		<div className={styles.box}>
			<div className={styles.head}>
				<Typo design="headline-xs" freezeDesktop>
					Данные о круизе
				</Typo>

				<MoreToggle
					className={styles.head__toggle}
					onClick={() => setExpanded((prev) => !prev)}
					isExpanded={expanded}
				/>
			</div>

			<AnimateHeight height={expanded ? 'auto' : 0} duration={400}>
				<div className={styles.content}>
					<Customer />
					<Cruise>
						<Ship />
					</Cruise>
				</div>
			</AnimateHeight>
		</div>
	);
});
