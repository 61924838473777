// import { message } from 'shared/lib/message';
// import { ValidationOptions } from 'shared/ui/organisms/form/lib/validation';
import { message } from 'config/constants/message';
import { ValidationOptions } from 'layout/components/inputs/form/lib/validation';
import { CustomerFormKeys } from './types';

export const customerFormValidationScheme: Record<CustomerFormKeys, ValidationOptions> = {
	lastName: {
		required: true,
		validateType: 'max255',
	},
	firstName: {
		required: true,
		validateType: 'max255',
	},
	middleName: {
		required: false,
		validateType: 'max255',
	},
	phone: {
		required: true,
		validateType: 'phone',
	},
	email: {
		required: false,
		validateType: 'email',
	},
	officeId: {
		required: true,
	},
	isCallCenter: {
		required: true,
		messages: {
			validateType: message.form.validation.required,
		},
	},
	citizenship: {
		required: false,
	},
	birthday: {
		required: false,
	},
	gender: {
		required: false,
	},
	documentType: {
		required: false,
	},
	documentData: {
		required: false,
	},
	referrer: {
		required: false,
	},
	building: {
		required: false,
	},
	buildingNumber: {
		required: false,
	},
	city: {
		required: false,
	},
	country: {
		required: false,
	},
	district: {
		required: false,
	},
	office: {
		required: false,
	},
	raw: {
		required: false,
	},
	region: {
		required: false,
	},
	street: {
		required: false,
	},
	zip: {
		required: false,
	},
};
