import React, { HTMLAttributeAnchorTarget, HTMLAttributes, PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

export type TypoTagNameType = keyof Omit<
	JSX.IntrinsicElements,
	Exclude<keyof SVGElementTagNameMap, 'a'>
>;

export type TypoDesignToken =
	| 'headline-xxl'
	| 'headline-header-news'
	| 'headline-xl'
	| 'headline-l'
	| 'headline-m'
	| 'headline-sl'
	| 'headline-s'
	| 'headline-xs'
	| 'text-l'
	| 'text-m'
	| 'text-s'
	| 'text-xs'
	| 'input-text-m'
	| 'input-text-s'
	| 'button-l'
	| 'button-m-s'
	| 'button-s'
	| 'description-xl'
	| 'description-l'
	| 'description-m'
	| 'description-features';

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
	as?: TypoTagNameType;
	design?: TypoDesignToken;
	freezeDesktop?: boolean;
	raw?: string;
	href?: string;
	htmlFor?: string;
	target?: HTMLAttributeAnchorTarget;
}

export const Typo = React.forwardRef<HTMLElement, PropsWithChildren<TypographyProps>>(
	(
		{ as: Tag = 'div', design = 'text-regular', raw, className, freezeDesktop, children, ...rest },
		ref,
	) =>
		React.createElement(
			Tag,
			{
				ref,
				className: classnames(styles[design], className, freezeDesktop && styles.freezeDesktop),
				...rest,
				...(raw ? { dangerouslySetInnerHTML: { __html: raw } } : {}),
			},
			!raw ? children : null,
		),
);
