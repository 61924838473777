import { Outlet, createRootRoute, useLocation, useSearch } from '@tanstack/react-router';
import classNames from 'classnames';
import { useGate, useUnit } from 'effector-react';
import { cartModel } from 'entities/cart';
import { useCheckAuth } from 'layout/components/auth/load-profile';
import { LoginLayout } from 'layout/components/auth/login/login';
import { profileModel } from 'layout/components/auth/profile';
import { Icon } from 'layout/components/icons/icon';
import { Portal } from 'layout/components/portal';
import { ProfileMenu } from 'layout/layouts/header/navigation/profile-menu';
import { Main } from 'layout/layouts/main';
import { CartWidgetWAP } from 'layout/layouts/widgets/cart-widget';
import { model } from 'layout/layouts/widgets/cart-widget/model';
import { useRef } from 'react';
import { useModalState } from 'utils/hooks/use-modal-state';


import MainWrapper from 'config/contexts/main-wrapper/main-wrapper';
import { CartModal } from 'layout/layouts/widgets/cart-widget/ui/cart-modal';
import cartWidgetStyles from 'layout/layouts/widgets/cart-widget/ui/styles.module.scss';

export const Route = createRootRoute({
  component: RootComponent,
})

const inHeader = document.getElementById('app-header') as HTMLElement ?? null
const inPortal = document.getElementById('portal-root') as HTMLElement ?? null

function RootComponent() {

  const sParams: Record<string, string> = useSearch({ strict: false })

  const location = useLocation()

  const { state, emailToken, error } = sParams

  const profile = useCheckAuth()
  const account = useUnit(profileModel.$profile);

  const Layout = !error ? Main : LoginLayout;
  // app-card



  const cart = useUnit(cartModel.$cart);

  const ref = useRef<HTMLButtonElement>(null);
  const { state: cartModalState, buttonProps, overlayProps, shouldMount } = useModalState({
    triggerRef: ref,
  });

  useGate(model.widgetModal.ModalGate, cartModalState);

  const InPortal = <>
    <Portal>
      <CartWidgetWAP
        buttonProps={buttonProps}
        cart={cart}
        ref={ref}
        shouldMount={shouldMount}
        state={cartModalState}
      >
        <CartModal close={cartModalState.close} {...overlayProps} />
      </CartWidgetWAP>
    </Portal>
  </>

  const InHeader = <>
    <ProfileMenu
      compact={false}
      // compactActive={compactActive} 
      profile={account} />
    {

      location.pathname != "/login"
        ? <div className="cartButtonBlock">
          <button
            ref={ref}
            {...buttonProps}
            onClick={(event) => {
              buttonProps.onClick?.(event);
              // setAnalytics(ANALYTICS_TARGETS.header.header_cart_click);
            }}
            className={classNames(cartWidgetStyles.cartWidgetTrigger, { [cartWidgetStyles.isExpanded]: false })}
          >
            <Icon className={cartWidgetStyles.cartWidgetTrigger__icon} id="cart2" width={20} height={20} />
            {cart.length > 0 && <span className={cartWidgetStyles.cartWidgetTrigger__marker} />}
            Корзина
          </button>
        </div>
        : null
    }
  </>

  return (
    <MainWrapper
      InHeader={InHeader}
      InPortal={InPortal}
      RenderHeaderIn={inHeader}
      RenderPortalIn={inPortal}
    >
      <Layout>
        <Outlet />
      </Layout>
    </MainWrapper>
  )
}