import { useMenuSection, useSeparator } from 'react-aria';
import { TreeState } from 'react-stately';
import { Node } from '@react-types/shared/src/collections';
import { MenuItem } from '../menu-item';
import styles from './styles.module.scss';

interface MenuSectionProps<T> {
	section: Node<T>;
	state: TreeState<T>;
}

export const MenuSection = <T extends object>({ section, state }: MenuSectionProps<T>) => {
	const { itemProps, headingProps, groupProps } = useMenuSection({
		heading: section.rendered,
		'aria-label': section['aria-label'],
	});

	const { separatorProps } = useSeparator({
		elementType: 'li',
	});

	return (
		<>
			{section.key !== state.collection.getFirstKey() && (
				<li {...separatorProps} className={styles.section__withBorder} />
			)}
			<li {...itemProps} className={styles.section}>
				{section.rendered && (
					<span className={styles.section__title} {...headingProps}>
						{section.rendered}
					</span>
				)}
				<ul {...groupProps} className={styles.section__group}>
					{[...section.childNodes].map((node) => (
						<MenuItem key={node.key} item={node} state={state} />
					))}
				</ul>
			</li>
		</>
	);
};
