import { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { InputInterface } from '../input';
import { ruLang } from './lib';
import 'intl-tel-input/styles';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import IntlTelInput from 'intl-tel-input/react';
import 'intl-tel-input/styles';

interface InputPhoneProps extends InputInterface {
	onNumberChange: (value: string, isValid: boolean) => void;
	errorNotice?: Nullable<string>;
}

const errorMap = [
	'Неправильный номер',
	'Нерпавильный код страны',
	'Слишком короткий номер',
	'Слишком длинный номер',
	'Неправильный номер',
	'Неправильный номер',
];

export const InputPhone: FC<InputPhoneProps> = ({
	classNameWrap,
	size = 'md',
	theme = 'normal',
	withLabel,
	isRequired,
	placeholder,
	onNumberChange,
	...props
}) => {
	const [isValid, setIsValid] = useState(false);
	const [number, setNumber] = useState<Nullable<string>>(null);
	const [error, setError] = useState<Nullable<number>>(null);
	const [errorNotice, setErrorNotice] = useState<Nullable<string>>(null);

	const blurHandler = () => {
		if (error !== null) {
			const errorMessage = errorMap[error || 0] || 'Неправильный номер';
			setErrorNotice(errorMessage);
		}
	};

	useEffect(() => {
		if (number) {
			onNumberChange(number, isValid);
		}
		// eslint-disable-next-line
	}, [number]);

	return (
		<div
			className={classnames(
				classNameWrap,
				styles.container,
				styles.input,
				size && styles[size],
				theme && styles[theme],
				withLabel && styles.withLabel,
				errorNotice && error && styles.error,
			)}
		>
			<IntlTelInput
				onChangeNumber={setNumber}
				onChangeValidity={setIsValid}
				onChangeErrorCode={setError}
				initOptions={{
					initialCountry: 'ru',
					utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/js/utils.js',
					i18n: ruLang,
					separateDialCode: true,
					countryOrder: ['ru', 'by'],
				}}
				inputProps={{
					onBlur: blurHandler,
					// onChange: onChange,
				}}
			/>
			{withLabel && placeholder && (
				<Typo
					design="button-m-s"
					className={classnames(
						styles.input__label,
						{
							[styles.isRequired]: props.required || isRequired,
							[styles.isActive]: true,
						},
						theme && styles[theme],
					)}
				>
					<span>{placeholder}</span>
				</Typo>
			)}
			{!!(error !== null && errorNotice) && (
				<Typo
					// id={ariaId}
					design="input-text-s"
					className={classnames(styles.input__error, 'errormessage')}
				>
					{errorNotice}
				</Typo>
			)}
		</div>
	);
};
