import { getScrollBarWidth } from '../viewport';

interface IOptions {
	elsToCorrect?: string;
	elsForPadding?: string;
	iosFix?: boolean;
}

interface IIndexable {
	[key: string]: any;
}

type BodyState = {
	overflow: string | undefined;
};

const defaultOptions: IOptions = {
	elsToCorrect: '',
	elsForPadding: '',
	iosFix: true,
};

const isIOS =
	typeof window !== 'undefined' ? /iPad|iPhone|iPod/.test(window.navigator.userAgent) : false;

export class ScrollLocker {
	private readonly options: IOptions;

	private readonly isIOS: boolean;

	public state: boolean;

	public shift: number | undefined;

	private bodyState: BodyState;

	private timeout: number | undefined;

	private timeoutFinally: number | undefined;

	private sbWidth: number;

	private elsToCorrect: NodeListOf<HTMLElement> | undefined;

	private elsForPadding: NodeListOf<HTMLElement> | undefined;

	public constructor(options?: IOptions) {
		this.options = { ...defaultOptions, ...options };

		this.state = false;
		this.shift = undefined;
		this.timeout = undefined;
		this.timeoutFinally = undefined;
		this.sbWidth = getScrollBarWidth();
		this.isIOS = isIOS;

		this.elsToCorrect = undefined;
		this.elsForPadding = undefined;

		this.bodyState = {
			overflow: undefined,
		};

		this.lock = this.lock.bind(this);
		this.unlock = this.unlock.bind(this);
		this.indents = this.indents.bind(this);
		this.setPositionFixed = this.setPositionFixed.bind(this);
		this.resetPositionFixed = this.resetPositionFixed.bind(this);
		this.getData = this.getData.bind(this);
	}

	public lock({ fixed = true } = {}) {
		const { body } = document;

		if (!this.state) {
			this.state = true;
			this.sbWidth = getScrollBarWidth();

			this.bodyState = {
				overflow: body.style.overflow,
			};

			body.style.overflow = 'hidden';
			body.classList.add('scroll-locked');

			body.style.paddingRight = `${this.sbWidth}px`;

			this.indents(true);

			if (fixed) {
				this.setPositionFixed();
			}
		}
	}

	public unlock({ delay = 0, fixed = true } = {}) {
		const { body } = document;

		let resolver: ((value: unknown) => void) | null = null;

		if (this.timeout !== null) {
			window.clearTimeout(this.timeout);
		}

		this.timeout = window.setTimeout(() => {
			if (this.state) {
				body.style.overflow = this.bodyState.overflow || '';
				body.style.paddingRight = '';

				body.classList.remove('scroll-locked');

				this.indents(false);

				if (fixed) {
					this.resetPositionFixed();
				}
			}

			this.state = false;

			if (resolver) {
				resolver(true);
			}
		}, delay);

		return new Promise((resolve) => {
			resolver = resolve;
		});
	}

	public setPositionFixed() {
		const { body, documentElement } = document;
		const scrollY = documentElement.scrollTop || body.scrollTop;

		if (this.timeoutFinally) {
			window.clearTimeout(this.timeoutFinally);
		}

		this.shift = this.shift || scrollY;

		window.requestAnimationFrame(() => {
			documentElement.classList.add('ios-scroll-fixed');

			body.style.position = 'fixed';
			body.style.top = `-${this.shift}px`;
			body.style.left = '0px';
			body.style.width = '100%';
			body.style.height = 'auto';
			body.style.overflowX = 'hidden';
			body.style.overflowY = 'hidden';
			body.style.paddingRight = `${this.sbWidth}px`;
		});
	}

	public resetPositionFixed() {
		const { body, documentElement } = document;

		//// eslint-disable-next-line unicorn/consistent-destructuring
		documentElement.classList.remove('ios-scroll-fixed');

		body.style.position = '';
		body.style.top = '';
		body.style.left = '';
		body.style.bottom = '';
		body.style.width = '';
		body.style.height = '';
		body.style.overflowX = '';
		body.style.overflowY = '';
		body.style.paddingRight = '';

		if (this.shift) {
			window.scrollTo(0, this.shift);
		}

		this.timeoutFinally = window.setTimeout(() => {
			this.shift = undefined;
		}, 400);
	}

	public indents(flag: boolean) {
		if (this.options.elsToCorrect?.length) {
			this.elsToCorrect = document.querySelectorAll<HTMLElement>(this.options.elsToCorrect);
		}

		if (this.options.elsForPadding?.length) {
			this.elsForPadding = document.querySelectorAll<HTMLElement>(this.options.elsForPadding);
		}

		if (this.elsToCorrect) {
			this.elsToCorrect.forEach((el) => {
				const type: string = el?.dataset?.indent || 'margin';
				const prop = type !== 'right' ? `${type}Right` : type;

				(el.style as IIndexable)[prop] = flag ? `${this.sbWidth}px` : '';
			});
		}

		if (this.elsForPadding) {
			this.elsForPadding.forEach((el) => {
				el.style.paddingRight = flag ? `${this.sbWidth}px` : '';
			});
		}
	}

	public getData() {
		return {
			shift: this.shift,
		};
	}
}

const scrollLocker = new ScrollLocker();

export { scrollLocker };
