import axios from 'axios';
import { LoginResponse, RefreshTokenRequest } from 'config/types/personal';
import { ACCOUNT_URL } from '../constants';
import { API_BASE_URL } from 'config/commonConstants';


export const refreshToken = (data: RefreshTokenRequest) =>
	axios
		.post<LoginResponse>(`${ACCOUNT_URL}/refresh/`, data, { baseURL: API_BASE_URL })
		.then((res) => res.data);
