import React, { FC } from 'react';
// import { CruiseDetailsModel } from 'shared/lib/types';
// import { Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { CruiseDetailsModel } from 'config/types/cabins/models/cruise-details';
import { Typo } from 'layout/components/typo/ui';

interface CruiseFeatureProps extends CruiseDetailsModel.IncludedItem {}

export const CruiseFeature: FC<CruiseFeatureProps> = ({ icon = '', title = '' }) => {
	if (!title) {
		return null;
	}

	return <Typo design="text-m" raw={(icon ?? '') + title} className={styles.cruiseFeature} />;
};
