import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { Typo, TypoDesignToken } from 'layout/components/typo/ui';
import { getNumDeclension } from 'utils/string';
import { SECONDS_ACCUSATIVE_DECLENSION } from 'config/constants';

interface CountdownProps extends PropsWithChildren, DefaultStyledProps {
	text?: string;
	timeout?: number;
	design?: TypoDesignToken;
}

export const Countdown: FC<CountdownProps> = ({
	text,
	timeout = 0,
	design = 'text-m',
	className,
	children,
}) => {
	const timer = useRef(0);
	const [counter, setCounter] = useState(timeout);

	const tick = () => {
		window.clearTimeout(timer.current);
		timer.current = window.setTimeout(() => {
			setCounter((prev) => Math.max(0, prev - 1));
		}, 1000);
	};

	useEffect(() => {
		if (counter > 0) {
			tick();
		}
	}, [counter]);

	if (counter === 0) {
		return (
			<>
				{React.Children.map(children, (child) => {
					if (!React.isValidElement(child)) {
						return null;
					}

					return React.cloneElement(child, {
						...child.props,
						onClick: child.props.onClick
							? () => {
									setCounter(timeout);
									child.props.onClick();
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  }
							: undefined,
					});
				})}
			</>
		);
	}

	return (
		<Typo className={classnames(className, styles.countdown)} design={design} as="div">
			{text && text.length > 0 ? <span>{text} </span> : null}
			<b className={styles.countdown__value}>
				{counter} {getNumDeclension(counter, SECONDS_ACCUSATIVE_DECLENSION)}
			</b>
		</Typo>
	);
};
