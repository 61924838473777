import { status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
// import { profilatorModel } from 'features/load-profilator-data';
import { cruiseModel } from 'entities/cruises/model';
// import { verificationModel } from 'entities/verification';
// import {
// 	bookingRequests,
// 	officesRequests,
// 	SendBookingSupportPersonal,
// 	SendBookingSupportRequest,
// 	ecommerceRequests,
// } from 'shared/api';
// import { CURRENCY } from 'shared/config/currency';
// import { NOT_DIGITS, ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { message } from 'shared/lib/message';
// import { BookingSupportBadResponse, OfficeModel, EcommerceCruise } from 'shared/lib/types';
// import { setAnalytics, getFilledEcommerceObject } from 'shared/lib/utils';
// import { getFailCode, getFailData } from 'shared/lib/utils/api/axios';
// import { modalFactory } from 'shared/model';
// import { getValidation } from 'shared/ui/organisms/form/lib/validation';
import {
	bookingSupportValidationScheme,
	SendBookingSupportRequestKey,
	DOESNT_MATTER_KEY,
} from './lib';
import { getFailCode, getFailData } from 'api';
import { ANALYTICS_TARGETS, NOT_DIGITS } from 'config/constants';
import { message } from 'config/constants/message';
import { EcommerceCruise } from 'config/types/ecommerce';
import { OfficeModel } from 'config/types/offices';
import { profilatorModel } from 'layout/components/auth/load-profilator-data';
import { getValidation } from 'layout/components/inputs/form/lib/validation';
import { modalFactory } from 'layout/components/modal';
import { officesRequests } from 'store/account/api/offices';
import { SendBookingSupportRequest, SendBookingSupportPersonal, bookingRequests } from 'store/cruise/booking';
import { ecommerceRequests } from 'store/cruise/ecommerce';
import { getFilledEcommerceObject } from 'utils/analytics/ecommerce';
import { verificationModel } from 'utils/verification';
import { BookingSupportBadResponse } from 'config/types/forms/booking-support';

export type BookingSupportFormModel = ReturnType<typeof bookingSupportModelFactory>;

export type FormValues = Omit<SendBookingSupportRequest, 'cruiseId' | 'captcha'> &
	Partial<SendBookingSupportPersonal>;

interface BookingSupportDataValues {
	requestId?: number;
}
const initialValues: FormValues = {
	adultCount: '1',
	childrenCount: '',
	cabinCategories: [DOESNT_MATTER_KEY],
	comment: '',
	agreeProcessData: true,
};

const guestFormInitialValues: FormValues = {
	...initialValues,
	firstName: '',
	lastName: '',
	phone: '',
	email: '',
	officeId: '',
};

export const bookingSupportModelFactory = (isGuest?: boolean) => {
	const BookingSupportGate = createGate<{ authorized?: boolean }>();

	const { ModalGate, openModal, closeModal } = modalFactory();

	const sendBookingSupportFx = createEffect(bookingRequests.sendBookingSupport);

	const getOffices = createEvent();
	const getOfficesFx = createEffect(officesRequests.getOffices);

	const $authorized = createStore(false);
	const $requestData = createStore<Nullable<BookingSupportDataValues>>(null);

	const $offices = createStore<OfficeModel[]>([]);
	const $officesError = createStore<Nullable<number>>(null).reset(getOfficesFx.done);

	const handleOnSubmit = createEvent<FormValues>();
	const handleBadResponse = createEvent<number | undefined>();
	const handleBadResponseErrors = createEvent<
		BookingSupportBadResponse<SendBookingSupportRequestKey> | undefined
	>();

	const handleSendBookingSupportAnalyticsFx = createEffect(() => {}
		// setAnalytics(ANALYTICS_TARGETS.cruisepage.cruisepage_cabin_help_form_sent),
	);

	const form = createForm<FormValues>({
		initialValues: isGuest ? guestFormInitialValues : initialValues,
		onSubmit: ({ values }) => handleOnSubmit(values),
		validate: ({ values }) => {
			const errors: Partial<Record<SendBookingSupportRequestKey, string>> = {};

			Object.entries(values).forEach(([key, value]) => {
				const validate = getValidation(
					bookingSupportValidationScheme[key as SendBookingSupportRequestKey],
				);
				if (Array.isArray(value) || typeof value === 'string' || typeof value === 'boolean') {
					errors[key as SendBookingSupportRequestKey] = validate(value);
				}
			});

			return errors;
		},
	});

	const $status = status({ effect: sendBookingSupportFx }).reset(BookingSupportGate.close);

	sample({
		clock: handleOnSubmit,
		source: {
			authorized: $authorized,
			captcha: verificationModel.$captchaToken,
			cruise: cruiseModel.$cruise,
			profilator: profilatorModel.$profilator,
		},
		filter: ({ cruise }) => Boolean(cruise?.id),
		fn: ({ authorized, captcha, cruise, profilator }, clock) => {
			const data = { captcha, UTM: profilator, cruiseId: cruise?.id ?? 0, ...clock };

			if (data.phone) {
				data.phone = data.phone.replace(NOT_DIGITS, '');
			}

			data.cabinCategories = data.cabinCategories.filter((item) => item !== DOESNT_MATTER_KEY);

			return {
				data,
				authorized,
			};
		},
		target: sendBookingSupportFx,
	});

	sample({
		clock: sendBookingSupportFx.failData,
		fn: (error) => getFailCode(error),
		target: handleBadResponse,
	});

	sample({
		clock: sendBookingSupportFx.failData,
		fn: (error) => getFailData(error),
		target: handleBadResponseErrors,
	});

	sample({
		clock: handleBadResponse,
		filter: (code) => code !== 422,
		fn: (code) => ({
			field: 'formError',
			error: message.bookingSupport[code ?? 'AC3'] ?? message.bookingSupport.AC3,
		}),
		target: form.setOrDeleteError,
	});

	handleBadResponseErrors.watch((data) => {
		data?.errors?.forEach(({ field, code }) => {
			const error = code === 'AC1' ? message.form.validation.required : '';

			if (error) {
				form.setOrDeleteError({
					field,
					error,
				});
			}
		});
	});

	sample({
		clock: BookingSupportGate.open,
		filter: ({ authorized }) => !authorized,
		target: getOffices,
	});

	sample({
		clock: BookingSupportGate.close,
		target: form.reset,
	});

	sample({
		clock: BookingSupportGate.state,
		fn: ({ authorized }) => !!authorized,
		target: $authorized,
	});

	sample({
		clock: getOffices,
		source: $offices,
		filter: (source) => source.length === 0,
		target: getOfficesFx,
	});

	sample({
		clock: getOfficesFx.doneData,
		target: $offices,
	});

	sample({
		clock: getOfficesFx.failData,
		fn: (error) => getFailCode(error) || 400,
		target: $officesError,
	});

	const setDefaultOffice = createEvent();

	sample({
		clock: $offices,
		target: setDefaultOffice,
	});

	sample({
		clock: BookingSupportGate.open,
		source: $offices,
		filter: (source, { authorized }) => source.length > 0 && !authorized,
		target: setDefaultOffice,
	});

	sample({
		clock: setDefaultOffice,
		source: {
			offices: $offices,
			authorized: $authorized,
		},
		filter: ({ authorized }) => !authorized,
		fn: ({ offices }) => {
			const defaultOffice = offices.find(({ id }) => id === 1);

			return {
				field: 'officeId',
				value: defaultOffice?.id?.toString() ?? '',
			};
		},
		target: form.setValue,
	});

	const ecommerce = createEvent();

	const ecommerceFx = createEffect(ecommerceRequests.getCruiseInfo);

	sample({
		clock: ecommerce,
		source: cruiseModel.$cruise,
		fn: (source) => ({ cruiseId: source!.id.toString() }),
		target: ecommerceFx,
	});

	const pushEcommerce = createEffect(
		(
			data: EcommerceCruise & {
				cruiseId?: number;
				price?: number | null;
				quantity?: string;
				bookingSupportData: BookingSupportDataValues | null;
			},
		) => {
			// @ts-ignore
			window.dataLayer.push(
				getFilledEcommerceObject({
					ecommerce: {
						// @ts-ignore
						currencyCode: CURRENCY[data.currency ?? 1].toUpperCase(),
						purchase: {
							actionField: {
								id: data.bookingSupportData?.requestId,
							},
							products: [
								{
									id: data.cruiseId?.toString(),
									name: data.name,
									price: data.price,
									brand: data.brand,
									category: data.category,
									quantity: data.quantity,
									variant: data.variant,
								},
							],
						},
					},
				}),
			);
		},
	);

	sample({
		clock: ecommerceFx.doneData,
		source: {
			cruise: cruiseModel.$cruise,
			values: form.$values,
			bookingSupportData: $requestData,
		},
		fn: ({ cruise, values, bookingSupportData }, clock) => ({
			...clock,
			cruiseId: cruise?.id,
			price: cruise?.price?.min,
			quantity: values?.adultCount,
			bookingSupportData,
		}),
		target: pushEcommerce,
	});

	/**
	 * Обработка аналитики
	 */
	sample({
		clock: sendBookingSupportFx.doneData,
		target: [handleSendBookingSupportAnalyticsFx, ecommerce, $requestData],
	});

	return {
		form,
		$status,
		BookingSupportGate,
		getOffices,
		$offices,
		$officesError,
		ModalGate,
		openModal,
		closeModal,
	};
};

export const bookingSupportModel = bookingSupportModelFactory();
export const bookingSupportGuestModel = bookingSupportModelFactory(true);
