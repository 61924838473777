import { getFailCode } from 'api';
import { createStore, Effect, sample } from 'effector';
// import { getFailCode } from 'shared/lib/utils';

export const fxErrorCode = ({ effect }: { effect: Effect<any, any> }) => {
	const $errorCode = createStore<Nullable<number>>(null);

	sample({
		clock: effect.done,
		fn: () => null,
		target: $errorCode,
	});

	sample({
		clock: effect.failData,
		fn: (error) => getFailCode(error) ?? null,
		target: $errorCode,
	});

	return $errorCode;
};
