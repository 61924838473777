/* eslint-disable react/jsx-no-useless-fragment */
import React, { Key, ReactNode } from 'react';
// import { SelectProps } from 'react-stately';
import { getPropsFromController } from './lib/form-controller';
import { SelectProps } from '../selects/select';
// import { SelectProps } from 'shared/ui/molecules';
// import { getPropsFromController } from '../lib/form-controller';

type SelectInterface = Omit<SelectProps<{}>, 'children'>;

interface FormSelectProps extends SelectInterface {
	controller: any;
	form: any;
	validation?: any;
	children: ReactNode;
	name: string;
	wrap?: 'nowrap';
	onChangeFx?: (value?: Key | Key[]) => void;
}

export const FormSelect = ({
	controller,
	form,
	name,
	validation,
	wrap,
	onChangeFx,
	children,
	...props
}: FormSelectProps) => {
	const { value, onChange, error } = getPropsFromController({
		form,
		controller,
		name,
		validation,
	});

	const { defaultSelectedKeys = [] } = props;
	const isArray = Array.isArray(value);

	const childrenWithProps = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement<any>(child, {
				...props,
				selectedKeys: value && value.length > 0 ? (isArray ? value : [value]) : [],
				defaultSelectedKeys,
				onSelectionChange: (keys: Set<Key>) => {
					const array = [...keys];
					const selected = array[0]?.toString();

					onChange(isArray ? array : selected);

					if (onChangeFx) {
						onChangeFx(isArray ? array : selected);
					}
				},
				error,
			});
		}

		return child;
	});

	switch (wrap) {
		case 'nowrap':
			return <>{childrenWithProps}</>;
		default:
			return <div>{childrenWithProps}</div>;
	}
};
