export const getYear = (year: number | 'today' | undefined, shift: number) => {
	const currentYear = new Date().getFullYear();
	if (!year) {
		return currentYear + shift;
	}
	if (year === 'today') {
		return currentYear;
	}

	return year;
};
