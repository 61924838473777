/* eslint-disable import/no-extraneous-dependencies */
import { DateDuration, getWeeksInMonth, endOfMonth } from '@internationalized/date';
import classnames from 'classnames';
import { AriaCalendarGridProps, useCalendarGrid, useDateFormatter, useLocale } from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { capitalizeFirstLetter } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Typo } from 'shared/ui/atoms';
import { CalendarCell } from './calendar-cell';
import { CalendarWeek } from './calendar-week';
import style from './style.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { Typo } from 'layout/components/typo/ui';
import { useViewport } from 'layout/viewport';
import { capitalizeFirstLetter } from 'utils/string';

interface CalendarGridProps extends AriaCalendarGridProps, DefaultStyledProps {
	state: CalendarState | RangeCalendarState;
	offset?: DateDuration;
	hideWeekDays?: boolean;
	displayMonthTitle?: boolean;
	variant?: 'large';
}

export const CalendarGrid = ({
	state,
	className,
	hideWeekDays,
	displayMonthTitle,
	variant,
	offset = {},
	...props
}: CalendarGridProps) => {
	const { locale } = useLocale();
	const { isMob } = useViewport();

	const startDate = state.visibleRange.start.add(offset);
	const endDate = endOfMonth(startDate);

	const { gridProps } = useCalendarGrid({ startDate, endDate }, state);

	const weeksInMonth = getWeeksInMonth(startDate, locale);

	const monthDateFormatter = useDateFormatter({
		month: 'long',
		year: 'numeric',
		timeZone: state.timeZone,
	});

	const monthCaption = capitalizeFirstLetter(
		monthDateFormatter.format(startDate.toDate(state.timeZone)).replace(' ', ', '),
	);

	const monthCaptionWithoutYear = capitalizeFirstLetter(
		monthDateFormatter.format(startDate.toDate(state.timeZone)).split(' ')[0],
	);

	return (
		<table className={classnames(style.grid, className, `tabId-${offset?.months}`)} {...gridProps}>
			{!hideWeekDays && <CalendarWeek state={state} {...props} />}
			{displayMonthTitle && (
				<Typo className={style.monthCaption} as="caption" design="headline-sl" freezeDesktop>
					{isMob ? monthCaption : monthCaptionWithoutYear}
				</Typo>
			)}
			<tbody>
				{[...new Array(weeksInMonth).keys()].map((weekIndex) => (
					<tr key={weekIndex} className={style.gridRow}>
						{state.getDatesInWeek(weekIndex, startDate).map((date, i) => {
							if (date) {
								return (
									<CalendarCell
										key={date.day + date.month}
										variant={variant}
										state={state}
										date={date}
										currentMonth={startDate}
									/>
								);
							}

							// eslint-disable-next-line react/no-array-index-key
							return <Typo key={i} as="td" design="description-l" />;
						})}
					</tr>
				))}
			</tbody>
		</table>
	);
};
