import { getCabins } from './cabins';
import { getCity } from './cities';
import { getInfo } from './info';
import { getPlan } from './plan';

export type { GetCruiseInfoRequest } from './info';
export type { GetCruiseCabinsRequest } from './cabins';
export type { GetCityRequest } from './cities';
export type { GetCruisePlanRequest } from './plan';

export const cruisesRequests = {
	getInfo,
	getCity,
	getCabins,
	getPlan,
};
