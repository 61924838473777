import { SEARCH_CRUISES_SHIP_TYPES, SEARCH_CRUISES_SHIP_TYPES_KEY, SEARCH_CRUISES_SHIPS, SEARCH_CRUISES_SHIPS_KEY, SEARCH_SORT_DATE, SEARCH_SORT_KEY } from 'config/constants/search';
import { ParsedUrlQuery } from 'querystring';
import { stringifyParsedUrlQuery } from 'utils/object';
// import {
// 	SEARCH_CRUISES_SHIPS,
// 	SEARCH_CRUISES_SHIP_TYPES,
// 	SEARCH_CRUISES_SHIP_TYPES_KEY,
// 	SEARCH_SORT_DATE,
// 	SEARCH_SORT_KEY,
// 	SEARCH_CRUISES_SHIPS_KEY,
// } from 'shared/config';
// import { stringifyParsedUrlQuery } from 'shared/lib/utils';

export const getQueryString = (params: ParsedUrlQuery, sort = SEARCH_SORT_DATE) => {
	const queryObj: ParsedUrlQuery = { ...(params ?? {}), [SEARCH_SORT_KEY]: sort };

	if (queryObj[SEARCH_CRUISES_SHIP_TYPES_KEY] === SEARCH_CRUISES_SHIP_TYPES.default) {
		delete queryObj[SEARCH_CRUISES_SHIP_TYPES_KEY];
	}

	if (queryObj[SEARCH_CRUISES_SHIPS_KEY] === SEARCH_CRUISES_SHIPS.default) {
		delete queryObj[SEARCH_CRUISES_SHIPS_KEY];
	}

	if (queryObj[SEARCH_SORT_KEY] === SEARCH_SORT_DATE) {
		delete queryObj[SEARCH_SORT_KEY];
	}

	return stringifyParsedUrlQuery(queryObj);
};
