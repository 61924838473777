import { useMemo } from 'react';
import { reset, status } from 'patronum';
// import { GetServerSidePropsContext } from 'next/types';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate, useUnit } from 'effector-react';
import { accountRequests } from 'store/account/api/requests';
import { Excursion } from 'store/auth/types/account/excursion';
import { accountNavigationModel } from 'layout/layouts/header/account-navigation';
import { Request } from 'store/auth/types/account/request';
import { isSafari } from 'utils/tests';
import { getFailCode } from 'api';
import { headerModel } from 'layout/components/view/header';
import { footerModel } from 'layout/components/view/footer';
// import { accountNavigationModel } from 'features/account-navigation';
// import { headerModel } from 'features/header';
// import { footerModel } from 'entities/footer';
// import { accountRequests } from 'shared/api';
// import { Excursion, Request } from 'shared/lib/types/account/models';
// import { getFailCode, isSafari } from 'shared/lib/utils';


// TODO: подобрать тип данных взамен
// const getData = createEvent<GetServerSidePropsContext>();
const getData = createEvent<any>();

const $pageId = createStore<string>('');
const $childRequestId = createStore<string>('');

sample({
	clock: getData,
	filter: ({ params }) => !!params?.id,
	fn: ({ params }) => params?.id as string,
	target: $pageId,
});

sample({
	clock: getData,
	filter: ({ params }) => !!params?.exid,
	fn: ({ params }) => params?.exid as string,
	target: $childRequestId,
});

const gate = createGate();

const $detailedRequest = createStore<Nullable<Request>>(null);
const $detailedRequestServices = createStore<Nullable<Request['services']>>(null);

const getDetailedRequest = createEvent();
const resetSelection = createEvent<number>();
const getDetailedRequestFx = createEffect(accountRequests.getDetailedRequest);

const $detailedRequestStatus = status({ effect: getDetailedRequestFx });

const $requestExcursions = createStore<Nullable<Excursion[]>>(null);
const $savedExcursions = createStore<Nullable<Excursion[]>>(null);

const getRequestExcursions = createEvent<string>();
const getRequestExcursionsFx = createEffect(accountRequests.getDetailedRequestExcursions);

const $requestExcursionsStatus = status({ effect: getRequestExcursionsFx });

sample({
	clock: gate.open,
	target: [getDetailedRequest, accountNavigationModel.getCounts],
});

sample({
	clock: getDetailedRequest,
	source: $pageId,
	fn: (source) => source,
	target: [getDetailedRequestFx, getRequestExcursions],
});

sample({
	clock: getDetailedRequestFx.doneData,
	target: $detailedRequest,
} as any); // TODO: тут без any ругается на clock

sample({
	clock: $detailedRequest,
	filter: (source) => Boolean(source),
	fn: (source) => source?.services as Request['services'],
	target: $detailedRequestServices,
});

sample({
	clock: getRequestExcursions,
	target: getRequestExcursionsFx,
});

sample({
	clock: getRequestExcursionsFx.doneData,
	fn: (excursions) => excursions.filter(({ id }) => Boolean(id)),
	target: [$requestExcursions, $savedExcursions],
});

/* *
 * Обновление экскурсий в заявке
 */
const updateExcursion = createEvent<Excursion>();

sample({
	clock: updateExcursion,
	source: $requestExcursions,
	fn: (excursions, excursion) =>
		excursions?.map((item) => {
			if (item.id === excursion.id) {
				return excursion;
			}

			return item;
		}) ?? null,
	target: $requestExcursions,
});

sample({
	clock: resetSelection,
	source: { initialExcursions: $savedExcursions, requestExcursions: $requestExcursions },
	fn: ({ initialExcursions, requestExcursions }, id) => {
		const initialExcursion = initialExcursions?.find((item) => item.id === id);

		return requestExcursions?.map((excursion) => {
			if (excursion.id !== initialExcursion?.id) {
				return excursion;
			}

			return initialExcursion;
		}) as Excursion[];
	},
	target: $requestExcursions,
});

/* *
 * Скачивание доков
 */
const openDoc = createEvent<string>();
const openDocFx = createEffect(accountRequests.openDetailedRequestDoc);

const $openDocUrl = createStore<Nullable<string>>(null);
const $openDocStatus = status({ effect: openDocFx });
const $openDocFails = createStore<Record<string, Nullable<number>>>({});

const saveToFileFx = createEffect(({ data, fileName }: { data: Blob; fileName: string }) => {
	const url = URL.createObjectURL(data);
	const a = document.createElement('a');
	a.style.display = 'none';
	document.body.append(a);
	a.href = url;
	a.target = isSafari() ? '_self' : '_blank';
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
});

sample({
	clock: openDoc,
	target: [openDocFx, $openDocUrl],
});

sample({
	source: $openDocUrl,
	filter: (url) => Boolean(url),
	clock: openDocFx.doneData,
	fn: (url, data) => ({ fileName: `${url?.split('/').at(-1)}.pdf`, data }),
	target: saveToFileFx,
});

sample({
	clock: openDocFx.failData,
	source: {
		key: $openDocUrl,
		map: $openDocFails,
	},
	fn: ({ key, map }, error) => {
		if (!key) {
			return map;
		}

		return {
			...map,
			[key]: getFailCode(error) ?? null,
		};
	},
	target: $openDocFails,
});

/* *
 * Дочерние заявки
 */
const childRequestGate = createGate();
const getDetailedChildRequest = createEvent();

sample({
	clock: childRequestGate.open,
	target: [getDetailedChildRequest, accountNavigationModel.getCounts],
});

sample({
	clock: getDetailedChildRequest,
	source: $childRequestId,
	fn: (source) => source,
	target: getDetailedRequestFx,
});

reset({
	clock: childRequestGate.close,
	target: [$detailedRequest, $detailedRequestServices],
});

/* *
 * Хэдэр / футер
 */
sample({
	clock: getData,
	target: [headerModel.getData, footerModel.getData],
});

export const usePassengersServices = (passengerId: number) => {
	const services = useUnit($detailedRequestServices);

	const passengerServices = useMemo(() => {
		if (!services) {
			return [];
		}

		return services?.filter((service) =>
			service.excursion?.passengers?.some((passenger) => passenger.id === passengerId),
		);
	}, [passengerId, services]);

	return { passengerServices };
};

/* *
 * Errors
 */
const $errorCode = createStore<Nullable<number>>(null);

sample({
	clock: getDetailedRequestFx.failData,
	fn: (error) => getFailCode(error) ?? null,
	target: $errorCode,
});

sample({
	clock: getDetailedRequestFx.done,
	fn: () => null,
	target: $errorCode,
});

export const model = {
	$detailedRequest,
	$detailedRequestServices,
	getDetailedRequest,
	getDetailedChildRequest,
	getData,
	gate,
	$requestExcursions,
	openDoc,
	resetSelection,
	$openDocUrl,
	$openDocStatus,
	$openDocFails,
	$detailedRequestStatus,
	$requestExcursionsStatus,
	$savedExcursions,
	$errorCode,
	updateExcursion,
	childRequestGate,
};
