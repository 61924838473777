import { useRef } from 'react';
import classnames from 'classnames';
import { useMenuItem } from 'react-aria';
import { TreeState } from 'react-stately';
import { Node } from '@react-types/shared/src/collections';
import styles from './styles.module.scss';

interface MenuItemProps<T> {
	item: Node<T>;
	state: TreeState<T>;
}

export const MenuItem = <T extends object>({ item, state }: MenuItemProps<T>) => {
	const ref = useRef<Nullable<HTMLLIElement>>(null);
	const { menuItemProps, isFocused, isDisabled } = useMenuItem({ key: item.key }, state, ref);

	return (
		<li
			{...menuItemProps}
			className={classnames(
				styles.menuItem,
				isDisabled && styles.menuItem_disabled,
				isFocused && styles.menuItem_focused,
			)}
			ref={ref}
		>
			{item.rendered}
		</li>
	);
};
