/* eslint-disable complexity */
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { Form } from 'effector-react-form';
import { emailPattern } from './masks';
import { message } from 'config/constants/message';

export interface ValidationOptions {
	validateType?:
	| 'email'
	| 'phone'
	| 'password'
	| 'changePassword'
	| 'repeatPassword'
	| 'requiredRadio'
	| 'maxNumber100'
	| 'maxMinNumber100'
	| 'max250'
	| 'max255'
	| 'max5000'
	| 'max2000AccountSupport'
	| 'login'
	| 'minMax'
	| 'latinCyrillicDigits'
	| 'maskComplete';
	required?: boolean;
	reference?: {
		form: Form;
		fieldName: string;
	};
	messages?: Partial<Record<keyof ValidationOptions, string>>;
	options?: {
		min?: number;
		max?: number;
		mask?: string;
	};
}

export const getValidation =
	({ validateType, reference, required, messages, options }: ValidationOptions) =>
		(value: string | string[] | number | boolean) => {
			if (validateType === 'minMax') {
				const min = options?.min ?? 0;
				const max = options?.max ?? 0;
				try {
					if ((typeof value == "number") && (value > max || value < min)) {
						return messages?.validateType;
					}
				} catch (error) {
					return (error as ValidationError).errors[0] || undefined;
				}
			}

			if (validateType === 'requiredRadio') {
				if (value === '') {
					return message.form.validation.requiredRadio;
				}

				return;
			}

			if (typeof value === 'boolean') {
				if (required && !value) {
					return messages?.required || message.form.validation.sholudChecked;
				}

				return;
			}

			if (required) {
				const isEmptyArray = Array.isArray(value) && value.length === 0;
				const isEmptyString = String(value).trim().length === 0;

				if (isEmptyArray || isEmptyString || value === undefined) {
					return messages?.required || message.form.validation.required;
				}
			}

			if (validateType === 'max255') {
				try {
					yup
						.string()
						.max(255, messages?.validateType || message.form.validation.format.max255)
						.validateSync(value);
				} catch (error) {
					return (error as ValidationError).errors[0] || undefined;
				}
			}

			if (validateType === 'max250') {
				try {
					yup
						.string()
						.max(250, messages?.validateType || message.form.validation.format.max250)
						.validateSync(value);
				} catch (error) {
					return (error as ValidationError).errors[0] || undefined;
				}
			}

			if (validateType === 'max5000') {
				try {
					yup
						.string()
						.max(5000, messages?.validateType || message.form.validation.format.max5000)
						.validateSync(value);
				} catch (error) {
					return (error as ValidationError).errors[0] || undefined;
				}
			}

			if (validateType === 'max2000AccountSupport') {
				try {
					yup
						.string()
						.max(2000, messages?.validateType || message.form.validation.format.max2000AccountSupport)
						.validateSync(value);
				} catch (error) {
					return (error as ValidationError).errors[0] || undefined;
				}
			}

			if (validateType === 'maxNumber100') {
				try {
					yup
						.number()
						.max(100, messages?.validateType || message.form.validation.format.maxNumber100)
						.validateSync(value);
				} catch {
					return;
				}
			}

			if (validateType === 'maxMinNumber100') {
				try {
					if (!required && value === '') {
						return;
					}

					const number = Number(value);
					const isValid = number >= 1 && number <= 100;

					if (!isValid) {
						return messages?.validateType || message.form.validation.format.maxNumber100;
					}
				} catch (error) {
					return (error as ValidationError).errors[0] || undefined;
				}
			}

			if (validateType === 'email') {
				if (!required && typeof value === 'string' && value.length === 0) {
					return;
				}

				try {
					yup
						.string()
						.matches(emailPattern, messages?.validateType || message.form.validation.format.email)
						.max(250, message.form.validation.format.emailMax250)
						.validateSync(value);
				} catch (error) {
					return (error as ValidationError).errors[0] || undefined;
				}
			}

			if (validateType === 'phone') {
				try {
					yup
						.string()
						.matches(/^\+\d+$/, messages?.validateType || message.form.validation.format.phone)
						.max(21, messages?.validateType || message.form.validation.format.phone)
						.validateSync(value);
				} catch (error) {
					return (error as ValidationError).errors[0] || undefined;
				}
			}

			if (validateType === 'login') {
				if ((value as string).includes('@')) {
					try {
						yup
							.string()
							.email(message.form.validation.format.email)
							.max(250, message.form.validation.format.emailMax250)
							.validateSync(value);
					} catch (error) {
						return (error as ValidationError).errors[0] || undefined;
					}
				} else {
					try {
						yup
							.string()
							.matches(/^\+?\d+$/, message.form.validation.format.phone)
							.max(20, message.form.validation.format.login)
							.validateSync(value);
					} catch (error) {
						return (error as ValidationError).errors[0] || undefined;
					}
				}
			}

			if (validateType === 'latinCyrillicDigits') {
				try {
					yup
						.string()
						.max(255, message.form.validation.format.max255)
						.matches(/^[\d\sA-Za-zА-я\-]+$/, message.form.validation.format.latinCyrillicDigits)
						.validateSync(value);
				} catch (error) {
					return (error as ValidationError).errors[0] || undefined;
				}
			}

			if (validateType && ['password', 'changePassword'].includes(validateType)) {
				if (reference && validateType === 'changePassword') {
					const referenceValue = reference.form.$values.getState()[reference.fieldName];

					if (referenceValue === value) {
						return message.form.validation.changePassword;
					}
				}

				try {
					yup
						.string()
						.min(6, message.form.validation.format.password)
						.max(255, message.form.validation.format.password)
						.matches(
							/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*[ËА-яё])(?!.*\s)[\dA-Za-z].{5,}/,
							message.form.validation.format.password,
						)
						.validateSync(value);
				} catch (error) {
					return (error as ValidationError).errors[0] || undefined;
				}
			}

			/* *
			 * case 'SSSSSSSSSSSSSSSSSSSSSSSSS':
			 * Данный тип валидации сравнивает длину маски с длиной значения.
			 * Маска свидетелсьтва о рождении SSSSSSSSSSSSSSSSSSSSSSSSS взята из документа
			 * https://www.consultant.ru/document/cons_doc_LAW_142425/f337d3f1c825241b1e1773984b4c8351477ce46f/,
			 * однако проверять соответствие ее длине нужно не полностью, так как символы S опциональны.
			 * Для такой маски проверяем, чтобы было минимум 12 символов (соответствие формату R-ББ 999999)
			 *
			 * case '99 99 9999990':
			 * Валидация для серии и номера паспорта. Для этой маски также проверяем,
			 * чтобы было минимум 12 символов, так как еще один символ опционален.
			 * https://www.consultant.ru/document/cons_doc_LAW_142425/f337d3f1c825241b1e1773984b4c8351477ce46f/
			 */

			if (validateType === 'maskComplete') {
				const mask = options?.mask;

				if (mask && typeof value === 'string') {
					let minLength: number;

					switch (mask) {
						case 'SSSSSSSSSSSSSSSSSSSSSSSSS':
							minLength = 5;
							break;
						case '99 99 9999990':
							minLength = 10;
							break;
						default:
							minLength = mask.replace(/\s/gi, '').length;
					}

					if (value.replace(/\s/gi, '').length < minLength) {
						return messages?.validateType || message.form.validation.shouldComplete;
					}
				}
			}

			if (reference) {
				const referenceValue = reference.form.$values.getState()[reference.fieldName];

				if (validateType === 'repeatPassword' && referenceValue !== value) {
					return message.form.validation.repeatPassword;
				}
			}
		};
