import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { BonusCard, PrintBonusCard, bonusCardModel } from 'entities/bonus-card';
// import { profileModel } from 'entities/profile';
// import { Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { profileModel } from 'layout/components/auth/profile';
import { Typo } from 'layout/components/typo/ui';

interface MyBonusesProps {}

export const MyBonuses: FC<MyBonusesProps> = () => {
	const profile = useUnit(profileModel.$profile);
	const bonusCard = useUnit(bonusCardModel.$card);

	if (!profile || !bonusCard) {
		return null;
	}

	return (
		<section className={styles.bonuses}>
			<Typo className={styles.bonuses__head} design="headline-m">
				Мои бонусы
			</Typo>
			<BonusCard {...bonusCard} passportData={profile.passportData} />
			<PrintBonusCard {...bonusCard} passportData={profile.passportData} />
		</section>
	);
};
