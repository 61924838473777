/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import classnames from 'classnames';
import { useUnit } from 'effector-react';
// import { ChangePlaceTypeParams } from 'features/cart-widget/model';
import { cabinDeleteModel, CartItem, cartModel } from 'entities/cart';
// import {
// 	SEATS_DECLENSION,
// 	TOURISTS_DECLENSION,
// 	CABIN_STATUSES,
// 	ANALYTICS_TARGETS,
// } from 'shared/lib/constants';
// import { getNumDeclension, setAnalytics } from 'shared/lib/utils';
// import { Button, ChipRecord, Price, Skeleton, ToggleIos, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { ChipRecords } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { SEATS_DECLENSION, ANALYTICS_TARGETS, CABIN_STATUSES, TOURISTS_DECLENSION } from 'config/constants';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Skeleton } from 'layout/components/special/skeleton';
import { ToggleIos } from 'layout/components/special/toggle-ios';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';
import { getNumDeclension } from 'utils/string';
import { ChangePlaceTypeParams } from 'layout/layouts/widgets/cart-widget/model';
import { ChipRecord } from 'layout/components/view/chip-record';
import { ChipRecords } from 'layout/components/view/chip-records';

interface CabinHeaderProps extends Omit<CartItem, 'cruiseId' | 'isBonusPayment'> {
	changePlaceType: (params: ChangePlaceTypeParams) => void;
	size?: 'sm' | 'lg';
	price?: Nullable<number>;
	allowRemove?: boolean;
	isEditCartCabin?: boolean;
}

export const CabinHeader = ({
	places,
	name,
	category,
	id,
	status,
	changePlaceType,
	size = 'sm',
	allowRemove = true,
	isEditCartCabin,
}: CabinHeaderProps) => {
	const [totalPrice, openModal, statusesPending, pricePending] = useUnit([
		cartModel.$totalPrice,
		cabinDeleteModel.openCabinModal,
		cartModel.statusesPending,
		cartModel.pricePending,
	]);

	const cabinPrice = totalPrice?.cabins?.find((cabin) => cabin.id === id);

	const mainPlacesText = `${places.main.length} ${getNumDeclension(
		places.main.length,
		SEATS_DECLENSION,
	)}`;

	const additionalPlacesText =
		places.additional.length > 0 ? ` + ${places.additional.length} доп` : '';

	const placesAmount = places.main.length + places.additional.length;
	const touristsAmount = cabinPrice?.passengers?.filter(({ type }) => `${type}` !== '3').length;
	const alonePassenger = touristsAmount === 1;
	const showAlonePassengerToggle = placesAmount > 1;

	const toggle = showAlonePassengerToggle ? (
		<ToggleIos
			size="lg"
			className={styles.toggle}
			isSelected={alonePassenger}
			onChange={(isSelected) => {
				changePlaceType({
					id,
					passengers: isSelected ? [{ type: 0 }] : places.main.map(() => ({ type: 0 })),
				});

				if (isSelected) {
					// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_one_bed_on);
				} else {
					// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_one_bed_off);
				}
			}}
		>
			<Typo design="headline-xs">Одноместное размещение</Typo>
		</ToggleIos>
	) : null;

	const statusView =
		statusesPending || pricePending ? (
			<Skeleton width={127} height={24} />
		) : (
			<Typo className={classnames(styles.status, styles[status])} design="text-m">
				{CABIN_STATUSES[status]}
			</Typo>
		);

	const cabinTotalPrice = cabinPrice?.total && (
		<>
			<Price total={cabinPrice.total} bold />
			<span className={styles.records__text}> стоимость за каюту</span>
		</>
	);

	return (
		<header className={classnames(styles.header, styles[`header_${size}`])}>
			<div className={styles.header__row}>
				<div className={styles.statusWrapper}>
					<Typo design={isEditCartCabin ? 'headline-m' : 'headline-s'}>Каюта {name}</Typo>
					{size === 'sm' && (
						<Typo className={styles.category} design="headline-xs">
							<Icon id="cabin-cat" width={20} height={20} />
							<span>{category?.name}</span>
						</Typo>
					)}
					{statusView}
				</div>
				<div className={styles.statusWrapper}>
					{(allowRemove || !isEditCartCabin) && (
						<Button
							className={isEditCartCabin ? styles.button : undefined}
							onClick={() => {
								openModal({
									id,
									name,
								});
								// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_delete_cabin);
							}}
							btnType="text"
							disabled={!allowRemove}
							size="small"
						>
							Удалить каюту <Icon id="trash" width={20} height={20} />
						</Button>
					)}
					{size === 'sm' && (
						<Typo className={styles.places} design="headline-xs">
							<Icon id="user" width={20} height={20} />
							{mainPlacesText + additionalPlacesText}
						</Typo>
					)}
				</div>
			</div>

			{size === 'lg' && (
				<div className={styles.header__row}>
					<ChipRecords className={styles.records} theme="white">
						<ChipRecord>
							<strong>{places.main.length}</strong>{' '}
							{getNumDeclension(places.main.length, SEATS_DECLENSION)}{' '}
							{places.additional.length > 0 && (
								<>
									+ <strong>{places.additional.length}</strong> доп
								</>
							)}
						</ChipRecord>
						<ChipRecord value={touristsAmount} label={TOURISTS_DECLENSION} />

						{cabinPrice?.total && <ChipRecord value={cabinTotalPrice} />}
					</ChipRecords>
					{toggle && <>{toggle}</>}
				</div>
			)}
			{size === 'sm' && toggle && <div className={styles.header__row}>{toggle}</div>}
		</header>
	);
};
