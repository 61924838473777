import { sample } from 'effector';
import type { Form } from 'effector-react-form';

export function trimFactory<Values extends object>(form: Form<Values>, fields: (keyof Values)[]) {
	sample({
		clock: form.onBlurFieldBrowser,
		source: form.$values,
		fn: (values) => {
			const newValues = {
				...values,
			};

			fields.forEach((field) => {
				if (typeof newValues[field] === 'string') {
					newValues[field] = (newValues[field] as string).trim() as Values[keyof Values];
				}
			});

			return newValues;
		},
		target: form.setValues,
	});
}
