// import { dischargeNumber } from 'shared/lib/utils';

import { dischargeNumber } from "utils/number";

export const getPrice = (value: string | number) => {
	if (typeof value === 'string') {
		return Number(value) ? dischargeNumber(Number(value)) : value;
	}

	return dischargeNumber(value);
};
