import { debounce } from 'patronum';
import { combine, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
import { documentTypesModel, documentTypesValidationFactory } from 'entities/document-types';
import { orderCabinsModel } from 'entities/order';
import { CHILD_PLACE_TYPES } from 'config/constants/cabin';
import { PreviewPassenger } from 'config/types/prices';
import { parsePassportData, profileModel } from 'layout/components/auth/profile';
import { isAdult } from 'utils/date';
import { trimFactory } from 'utils/form';
import { stringifyObject, selectProps } from 'utils/object';
// import { parsePassportData, profileModel } from 'entities/profile';
// import { CHILD_PLACE_TYPES } from 'shared/config';
// import { PreviewPassenger } from 'shared/lib/types';
// import { isAdult, selectProps, stringifyObject, trimFactory } from 'shared/lib/utils';

interface FormValues extends PreviewPassenger {
	passportData?: string;
	childName?: string;
	childBirthday?: string;
	childDocument?: string;
}

interface GetFormParams {
	index: number;
	cabinId: number;
}

const comparedKeys = [
	'birthday',
	'firstName',
	'lastName',
	'middleName',
	'passportNumber',
	'passportSeries',
	'gender',
	'citizenship',
	'documentType',
];

export const getModel = ({ index, cabinId }: GetFormParams) => {
	const Gate = createGate();
	const form = createForm<FormValues>({
		initialValues: {
			passportData: '',
			childName: '',
			childDocument: '',
			childBirthday: '',
			citizenship: 235,
		},
		onChange: ({ values }) => {
			const { childName, childDocument, childBirthday, passportData, birthday, ...passenger } =
				values;
			const cabin = orderCabinsModel.$cabins.getState().find(({ id }) => id === cabinId);

			const cabinValues =
				cabin?.passengers && cabin?.passengers.length > index ? cabin?.passengers[index] : null;
			const changedDocumentType = cabinValues?.documentType !== passenger.documentType;

			const isAdultPassenger = !birthday || isAdult(new Date(birthday));
			const isValidBirthday = index > 0 || isAdultPassenger;

			const shouldChangeTypeToChild =
				index > 0 && !isAdultPassenger && values.type !== CHILD_PLACE_TYPES;

			setBirthdayErrorVisible(!isValidBirthday);

			setToCabins({
				...passenger,
				type: shouldChangeTypeToChild ? CHILD_PLACE_TYPES : passenger.type,
				birthday: isValidBirthday ? birthday : null,
				...(passportData && !changedDocumentType
					? parsePassportData(passportData)
					: parsePassportData('')),
				passportIssued: changedDocumentType ? '' : passenger.passportIssued,
				passportIssuedDate: changedDocumentType ? '' : passenger.passportIssuedDate,
				children: {
					name: childName,
					birthday: childBirthday,
					document: childDocument,
				},
			});
		},
	});

	const $pattern = createStore<string>('');

	trimFactory(form, ['lastName', 'firstName', 'middleName']);
	const { $scheme: $documentTypesValidation } = documentTypesValidationFactory({
		$pattern,
		form,
		field: 'passportData',
	});

	const setFromProfile = createEvent<boolean>();
	const setToCabins = createEvent<PreviewPassenger>();
	const setFromCabins = createEvent<Nullable<PreviewPassenger>>();

	/**
	 * Повторное получение цены при изменении документа для получения персональных скидок
	 */
	const getPriceForNewPassportData = createEvent();
	sample({
		source: form.$errorsInline,
		clock: form.onBlurFieldBrowser,
		filter: (errors, { name }) => name === 'passportData' && !errors.passportData,
		target: getPriceForNewPassportData,
	});
	debounce({
		source: getPriceForNewPassportData,
		timeout: 400,
		target: orderCabinsModel.getPrice,
	});

	const $isAlonePassenger = orderCabinsModel.$cabins.map((state) => {
		const cabin = state.find(({ id }) => id === cabinId);

		return cabin?.passengers?.filter((p) => p.type !== 3).length === 1;
	});

	const setBirthdayErrorVisible = createEvent<boolean>();
	const $birthdayErrorVisible = createStore(false)
		.on(setBirthdayErrorVisible, (_, visible) => visible)
		.reset($isAlonePassenger);

	const $isProfileData = combine(
		{
			cabins: orderCabinsModel.$cabins,
			profile: profileModel.$profile,
		},
		({ cabins, profile }) => {
			const cabin = cabins.find(({ id }) => id === cabinId);

			const values =
				cabin?.passengers && cabin?.passengers.length > index ? cabin?.passengers[index] : null;

			if (!profile?.passportData || !values) {
				return false;
			}

			return (
				JSON.stringify(stringifyObject(selectProps(values, comparedKeys))) ===
				JSON.stringify(stringifyObject(selectProps(profile.passportData, comparedKeys)))
			);
		},
	);

	sample({
		source: orderCabinsModel.$cabins,
		clock: [Gate.open, orderCabinsModel.$cabins],
		fn: (cabins) => {
			const cabin = cabins.find(({ id }) => id === cabinId);

			return cabin?.passengers ? cabin?.passengers[index] || null : null;
		},
		target: setFromCabins,
	});

	sample({
		clock: setFromCabins,
		filter: (clock) => Boolean(clock),
		fn: (clock) => ({
			...clock,
			childName: clock?.children?.name || undefined,
			childBirthday: clock?.children?.birthday || undefined,
			childDocument: clock?.children?.document || undefined,
			passportData: [clock?.passportSeries, clock?.passportNumber].filter(Boolean).join(' '),
		}),
		target: form.setValues,
	});

	sample({
		clock: setToCabins,
		fn: (passenger) => ({
			passengerIndex: index,
			passenger,
			cabinId,
		}),
		target: orderCabinsModel.updatePassenger,
	});

	sample({
		clock: setFromProfile,
		source: {
			profile: profileModel.$profile,
			values: form.$values,
		},
		fn: ({ profile, values }, fromProfile) => ({
			...values,
			firstName: fromProfile ? profile?.passportData.firstName || '' : '',
			middleName: fromProfile ? profile?.passportData.middleName || '' : '',
			lastName: fromProfile ? profile?.passportData.lastName || '' : '',
			citizenship: fromProfile
				? profile?.passportData.citizenship || values?.citizenship
				: undefined,
			documentType: fromProfile ? profile?.passportData.documentType || undefined : undefined,
			gender: fromProfile ? profile?.passportData.gender : undefined,
			passportNumber: fromProfile ? profile?.passportData.passportNumber || '' : '',
			passportSeries: fromProfile ? profile?.passportData.passportSeries : '',
			passportData: fromProfile
				? [profile?.passportData.passportSeries, profile?.passportData.passportNumber]
						.filter(Boolean)
						.join(' ')
				: '',
			birthday: fromProfile ? profile?.passportData.birthday || '' : undefined,
			children: values.children,
			passportIssued: '',
			passportIssuedDate: '',
		}),
		target: [form.setValues, setToCabins],
	});

	/**
	 * Нахождение паттерна выбранного документа
	 */
	sample({
		source: {
			activeId: form.$values.map(({ documentType }) => documentType),
			types: documentTypesModel.$documentTypes,
		},
		filter: Boolean,
		fn: ({ activeId, types }) => types.find(({ id }) => id === activeId)?.pattern || '',
		target: $pattern,
	});

	trimFactory(form, [
		'firstName',
		'middleName',
		'lastName',
		'passportIssued',
		'childName',
		'childDocument',
	]);

	/**
	 * Очищаем ошибку Серии и номера документа, если не выбран документ
	 */
	sample({
		clock: form.$values,
		filter: ({ documentType }) => !documentType,
		fn: () => ({
			field: 'passportData',
		}),
		target: form.setOrDeleteError,
	});

	return {
		Gate,
		form,
		setFromProfile,
		getPriceForNewPassportData,
		$pattern,
		$isProfileData,
		$documentTypesValidation,
		$birthdayErrorVisible,
		$isAlonePassenger,
	};
};
