// import { fetcher } from 'shared/api/config';
// import { cruiseMock } from 'shared/api/mock/cruise';
// import { CRUISES_ENDPOINT } from 'shared/config';
// import { CruiseDetailsModel } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { CRUISES_ENDPOINT } from "config/constants/cruises";
import { CruiseDetailsModel } from "config/types/cabins/models/cruise-details";
import { cruiseMock } from "mocks/cruise";

export interface GetCruiseInfoRequest {
	id?: string;
	needMock?: boolean;
}

export const getInfo = async ({ id, needMock = false }: GetCruiseInfoRequest) => {
	if (needMock) {
		await new Promise((resolve) => {
			setTimeout(resolve, 300);
		});

		return cruiseMock;
	}

	return fetcher<CruiseDetailsModel.CruiseInfo>({
		url: `${CRUISES_ENDPOINT}/${id}/info`,
		method: 'GET',
	});
};
