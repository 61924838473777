import { FC, useState } from 'react';
import { Input, InputInterface } from './input';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';

export const InputPassword: FC<InputInterface> = ({ ...props }) => {
	const [visible, setVisible] = useState(false);

	return (
		<div className={styles.inputPassword__wrapper}>
			<Input {...props} type={visible ? 'text' : 'password'} />
			<button
				className={styles.inputPassword__button}
				onClick={() => setVisible((prev) => !prev)}
				type="button"
			>
				<span className={styles.inputPassword__icon}>
					<Icon
						width={20}
						height={20}
						id={visible ? 'eye-open' : 'eye-close'}
						fill="var(--accent-brand-blue)"
					/>
				</span>
			</button>
		</div>
	);
};
