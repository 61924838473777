import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import { API_BASE_URL, API_GOOGLE_RECAPCHA_PUBLIC_KEY } from 'config/commonConstants';
import { FCWithChildren } from 'config/commonTypes';
import { SvgCommonSprite } from 'layout/components/icons/svg-sprite';
import { ViewportHelper } from 'layout/viewport';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { routerModel } from 'utils/router';
import { useCheckAuth } from '../../load-profile';
import { model as loginModel } from './../model__';
import { DEFAULT_BG_SRC } from './lib';
import style from './style.module.scss';

interface LoginLayoutProps {
	title?: string;
	imageSrc?: string;
}

export const LoginLayout: FCWithChildren<LoginLayoutProps> = ({ children, title, imageSrc }) => {
	const { hasToken } = useCheckAuth();
	const [authRedirectTarget, state] = useUnit([routerModel.$authRedirectTarget, loginModel.$state]);

	// const { executeRecaptcha } = useGoogleReCaptcha();
	// console.log('LoginLayout', executeRecaptcha ? 'yes recapcha' : 'no recapcha', API_GOOGLE_RECAPCHA_PUBLIC_KEY, API_BASE_URL)

	const isSuccessScreen = state === 'success-register';

	useEffect(() => {
		document.body.focus();
	}, []);

	useEffect(() => {
		if (hasToken && !authRedirectTarget && !isSuccessScreen) {
			window.location.href = '/';
		}
	}, [hasToken, authRedirectTarget, isSuccessScreen]);

	if (hasToken && !isSuccessScreen) {
		return null;
	}

	return (
		<>
			<SvgCommonSprite />
			<ViewportHelper />
			<main className={style.main}>
				{/* <p>success logged in</p> */}
				<div className={style.left}>{children}</div>
				<div className={style.right}>
					<img src={imageSrc || DEFAULT_BG_SRC} />
				</div>
			</main>
		</>
	);
};
