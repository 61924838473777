import { useOverlayTriggerState } from 'react-stately';
import { useGate, useUnit } from 'effector-react';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { setAnalytics } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { cartModel } from '../../model';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';
import { useViewport } from 'layout/viewport';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';

export const CartEmptyAlert = () => {
	const [reset, closeModal] = useUnit([cartModel.reset, cartModel.closeModal]);
	const state = useOverlayTriggerState({});

	const shouldMount = useDelayUnmount(state.isOpen, 600);
	useGate(cartModel.ModalGate, state);

	const { isMob } = useViewport();

	// return <div>CartEmptyAlert</div>
	return shouldMount ? (
		<Modal
			classNameModal={styles.modal}
			classNameModalContent={styles.content}
			closeButtonClassName={styles.close}
			state={state}
			isShown={state.isOpen}
			isDismissable
		>
			<Typo design={isMob ? 'headline-xl' : 'headline-m'}>Вы собираетесь очистить&nbsp;заказ</Typo>
			<Typo className={styles.text} design={isMob ? 'text-m' : 'text-s'}>
				Обратите внимание, что удаленные данные заказа невозможно восстановить. Уверены, что хотите
				очистить?
			</Typo>
			<div className={styles.buttons}>
				<Button
					onClick={() => {
						reset();
						// setAnalytics(ANALYTICS_TARGETS.header.header_cart_empty);
					}}
					size="large"
					btnType="outline"
				>
					Очистить
				</Button>
				<Button onClick={closeModal} size="large">
					Отменить
				</Button>
			</div>
		</Modal>
	) : null;
};
