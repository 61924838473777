import React, { FC, PropsWithChildren, RefObject, ReactNode, ReactElement } from 'react';
import { Placement } from '@react-types/overlays';
import classnames from 'classnames';
import { OverlayTriggerState } from 'react-stately';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { Button, Condition, Popover, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules/modal';
import style from './style.module.scss';
import { Popover } from 'layout/components/special/popover';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';
import { Modal } from 'layout/components/modal';
import { Button } from 'layout/components/buttons/button';
import { Typo } from 'layout/components/typo/ui';
import { Condition } from 'layout/components/switchers/condition';

export interface SelectModalPopoverProps {
	modalMode?: boolean;
	modalTitle?: ReactNode;
	modalFixedSize?: boolean;
	modalScrollReachedEnd?: boolean;
	modalScrollReachedStart?: boolean;
	modalHeader?: ReactElement;
	modalHeaderShadow?: boolean;
}

export interface SelectPopoverProps extends PropsWithChildren, SelectModalPopoverProps {
	state: OverlayTriggerState;
	triggerRef: RefObject<Element>;
	popoverRef?: RefObject<HTMLDivElement>;
	placement?: Placement;
	className?: string;
	classNameModalContent?: string;
	classNameModalFooter?: string;
	classNameModalHeader?: string;
	onApply?: () => void;
	onClose?: () => void;
	onReset?: () => void;
	applyBtnText?: ReactNode;
	resetBtnText?: ReactNode;
	portal?: HTMLElement;
}

export const SelectPopover: FC<SelectPopoverProps> = ({ modalMode, ...props }) => (
	<Condition
		value={modalMode}
		then={<SelectPopoverModal {...props} />}
		else={<SelectPopoverDrop {...props} />}
	/>
);

export const SelectPopoverDrop: FC<SelectPopoverProps> = ({
	state,
	triggerRef,
	placement,
	className,
	children,
	portal,
	popoverRef,
}) => {
	if (!state.isOpen) {
		return null;
	}

	return (
		<Popover
			state={state}
			triggerRef={triggerRef}
			placement={placement}
			popoverRef={popoverRef}
			className={classnames(className, style.selectPopover)}
			correctMaxHeight
			isNonModal
			portal={portal}
		>
			{children}
		</Popover>
	);
};

export const SelectPopoverModal: FC<SelectPopoverProps> = ({
	state,
	popoverRef,
	modalTitle,
	modalFixedSize,
	modalScrollReachedEnd,
	modalScrollReachedStart,
	modalHeader,
	modalHeaderShadow,
	classNameModalContent,
	classNameModalFooter,
	classNameModalHeader,
	onApply,
	onReset,
	onClose,
	applyBtnText = 'Применить',
	resetBtnText = 'Сбросить',
	children,
}) => {
	const shouldMount = useDelayUnmount(state.isOpen, 600);

	if (!shouldMount) {
		return null;
	}

	return (
		<Modal
			state={state}
			modalRef={popoverRef}
			isShown={state.isOpen}
			verticalAlign="bottom"
			className={style.selectModal__wrap}
			classNameModal={classnames(style.selectModal, modalFixedSize && style.fixedSize)}
			closeButtonClassName={style.selectModal__closeBtn}
			classNameModalContent={classnames(
				style.selectModal__content,
				modalFixedSize && style.fixedSize,
				classNameModalContent,
			)}
			classNameFooter={classnames(style.selectModal__footer, classNameModalFooter)}
			classNameHeader={classnames(style.selectModal__header, classNameModalHeader)}
			onClose={() => {
				onClose?.();
				state.close();
			}}
			footer={
				<>
					<Button
						onClick={() => {
							onApply?.();
							state.close();
						}}
						size="large"
						btnType="primary"
						type="button"
					>
						{applyBtnText}
					</Button>
					<Button
						onClick={() => {
							onReset?.();
						}}
						size="large"
						btnType="text"
						type="button"
					>
						{resetBtnText}
					</Button>
				</>
			}
			footerShadow
			footerShadowActive={!modalScrollReachedEnd}
			header={
				modalTitle || modalHeader ? (
					<>
						{modalTitle && (
							<Typo className={style.selectModal__title} design="headline-m">
								{modalTitle}
							</Typo>
						)}
						{modalHeader}
					</>
				) : undefined
			}
			headerShadow={Boolean(modalHeader) || modalHeaderShadow}
			headerShadowActive={!modalScrollReachedStart}
			isDismissable
		>
			{children}
		</Modal>
	);
};
