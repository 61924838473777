import classnames from 'classnames';
import style from './style.module.scss';
import { FCWithChildren } from 'config/commonTypes';
import { IconId } from 'layout/components/icons/svg-sprite';
import { Icon } from 'layout/components/icons/icon';

export interface AlertProps {
	type?: 'error' | 'info' | 'info-green' | 'warning' | 'default' | 'default-red' | 'warning-green';
	design?: 'fill' | 'text' | 'text-large';
	size?: 'lg' | 'sm';
	iconId?: Nullable<IconId>;
	iconPlacement?: 'center';
	className?: string;
}

export const Alert: FCWithChildren<AlertProps> = ({
	type = 'default',
	iconId,
	iconPlacement,
	size,
	design = 'fill',
	children,
	className,
}) => {
	if (!iconId) {
		switch (type) {
			case 'default':
			case 'default-red':
				iconId = 'info';
				break;
			default:
				iconId = 'warning';
				break;
		}
	}

	return (
		<div
			role="alert"
			className={classnames(
				style.alert,
				style[`alert_${type}`],
				style[`alert_${design}`],
				iconPlacement && style[`alert_${iconPlacement}`],
				size && style[size],
				className,
			)}
		>
			<Icon className={style.alert__icon} id={iconId} />
			{children}
		</div>
	);
};
