import React, { useState } from 'react'
import { RegistrationForm } from './register/ui/registration-form';
import { VerificationPhoneForm } from './verify/verify-phone';
import { AuthorizationForm } from './login/authorization-form';
import { LoginConfirmation, RecoverPasswordFrom, ResendEmail, ResetPasswordFrom } from './recover/recover-password';
import { SuccessRegister } from './register/ui/success-register';
import { SuccessAuthorization } from './login/success-authorization';
import { ScreenSwitcher } from './login/screen-switcher';
import { RegistrationAlert } from './register/ui/registration-alert';
import { Logo } from '../view/logo';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { AuthState } from 'config/types/personal/auth';
import { useGate, useUnit } from 'effector-react';
import { model, State } from './login/model__';

interface AuthorizationProps {
    emailToken?: string;
    initialState?: string;
    error?: string;
    className?: string;
    isModalMode?: boolean;
}

export const Authorization = ({ emailToken, initialState, className, isModalMode, error }: AuthorizationProps) => {
    useGate(model.Gate, {
        emailToken,
        state: initialState as State | null,
        error,
    });
    const [state, toState] = useUnit([model.$state, model.toState]);
    // const [state, setState] = useState<AuthState>("login");

    if (!state) {
        return null;
    }

    // const mod1 = document.querySelector('.modal.fade.js-cabin-modal.show')

    return <div className={classNames(styles.wrapper, styles[`wrapper_${state}`], className)}>
        <div className={styles.main}>
            {/* {!isModalMode && <Logo className={styles.logo} />} */}
            {state === 'register' && <RegistrationForm isModalMode={isModalMode} toState={toState} />}
            {state === 'verify' && <VerificationPhoneForm />}
            {(state === 'authorization' || state === 'success-reset-password') && (
                <AuthorizationForm
                    isModalMode={isModalMode}
                    isResetPassword={state === 'success-reset-password'}
                    recoverPassword={() => toState('recover')}
                />
            )}
            {state === 'recover' && <RecoverPasswordFrom />}
            {state === 'login-confirmation' && <LoginConfirmation />}
            {state === 'reset-password' && <ResetPasswordFrom />}
            {state === 'success-register' && <SuccessRegister />}
            {state === 'success-authorization' && <SuccessAuthorization />}
        </div>
        <ScreenSwitcher state={state} toState={toState} isModalMode={isModalMode} />
        {/* {state === 'register' && <RegistrationAlert isModalMode={isModalMode} />} */}
        {state === 'login-confirmation' && <ResendEmail />}
    </div>
}

export default Authorization