import React, { memo } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useUnit } from 'effector-react';
// import { OrderStepControls } from 'features/order/order-step-controls';
import { bonusCardModel } from 'entities/bonus-card';
import { pricesModel, requestModel, userRoleModel } from 'entities/order';
import { SuccessModal } from 'entities/order/success-modal';
// import { verificationModel } from 'entities/verification';
// import { OrderRoutes } from 'shared/config';
// import { DISCOUNT_POINTS } from 'shared/lib/constants';
// import { dischargeNumber, getNumDeclension } from 'shared/lib/utils';
// import { Alert, DesktopView, MobileView, Price, Typo, Condition, Skeleton } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { DISCOUNT_POINTS } from 'config/constants';
import { OrderRoutes } from 'config/constants/order';
import { Icon } from 'layout/components/icons/icon';
import { OrderStepControls } from 'layout/components/order/order-step-controls';
import { Skeleton } from 'layout/components/special/skeleton';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { Price } from 'layout/components/view/price';
import { DesktopView, MobileView } from 'layout/layouts/device-view';
import { dischargeNumber } from 'utils/number';
import { getNumDeclension } from 'utils/string';
import { verificationModel } from 'utils/verification';

export const OrderConfirmationControls = memo(() => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const setToken = useUnit(verificationModel.setCaptchaToken);

	const [
		isGuest,
		bonusCard,
		prices,
		pricesStatus,
		request,
		requestStatus,
		requestError,
		requestResponse,
	] = useUnit([
		userRoleModel.$isGuest,
		bonusCardModel.$card,
		pricesModel.$pricesPreview,
		pricesModel.$getPricesPreviewStatus,
		requestModel.request,
		requestModel.$requestStatus,
		requestModel.$requestError,
		requestModel.$requestResponse,
	]);

	const pending = pricesStatus === 'pending';
	const handleSubmit = async () => {
		if (executeRecaptcha && isGuest) {
			const token = await executeRecaptcha();
			setToken(token);
		}

		request();
	};

	const controls = (
		<OrderStepControls
			className={styles.controls}
			prevStep={2}
			prevHref={OrderRoutes.Customer}
			nextStepText="Забронировать"
			nextStepOnClick={handleSubmit}
			nextStepLoading={requestStatus === 'pending'}
		/>
	);

	const error = requestError ? (
		<Alert className={styles.error} size="lg" type="error" iconPlacement="center">
			<Typo design="input-text-s">
				<p>{requestError}</p>
			</Typo>
		</Alert>
	) : null;

	return (
		<>
			<DesktopView>
				{error}
				<div className={styles.totalPanel}>
					<Typo className={styles.totalPrice} design="headline-m" freezeDesktop>
						{prices?.total && (
							<div className={styles.totalPrice__total}>
								<span>Итого</span>
								<Price as="strong" total={prices.total} bold />
							</div>
						)}
						<Typo className={styles.bonuses} design="description-features">
							<Condition
								value={pending}
								then={<Skeleton display="inline-block" width={154} height={10} />}
								else={
									prices?.bonus ? (
										<div className={styles.bonus}>
											<div className={styles.bonus__card}>
												{bonusCard?.cardNumber && (
													<>
														<Icon id="klk" width={20} height={20} /> {`№ ${bonusCard?.cardNumber}`}
													</>
												)}
											</div>
											<div className={styles.bonus__point}>
												+ {dischargeNumber(prices.bonus)}{' '}
												{getNumDeclension(prices.bonus, DISCOUNT_POINTS)} после круиза
											</div>
										</div>
									) : undefined
								}
							/>
						</Typo>
					</Typo>
					{controls}
				</div>
			</DesktopView>

			<MobileView>
				{error && <div className={styles.notifications}>{error}</div>}
				{controls}
			</MobileView>

			{requestResponse?.status && (
				<SuccessModal status={requestResponse.status} number={requestResponse.number} />
			)}
		</>
	);
});
