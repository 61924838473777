// import { fetcher } from 'shared/api/config';
// import { SupportPages, ACCOUNT_URL } from 'shared/config';
// import { SupportType } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { SupportPages } from "config/constants/support";
import { SupportType } from "config/types/support";
import { ACCOUNT_URL } from "store/auth/constants";

export interface SendCallbackRequest {
	phone: string;
	name: string;
	agreeProcessData: boolean;
	captcha: Nullable<string>;
}

const sendCallback = ({ phone, name, captcha }: SendCallbackRequest) =>
	fetcher<any>({
		url: `${ACCOUNT_URL}/callback`,
		method: 'POST',
		body: {
			phone,
			name,
			captcha,
		},
	});

export interface SendSupportRequest {
	name: string;
	contact: string;
	message: string;
	type: string;
	agreeProcessData: boolean;
	captcha: Nullable<string>;
}

const sendSupport = ({ name, contact, message, type, captcha }: SendSupportRequest) =>
	fetcher<any>({
		url: `${ACCOUNT_URL}/support`,
		method: 'POST',
		body: {
			name,
			contact,
			message,
			type,
			captcha,
		},
	});

interface GetSupportTypesHandbookRequest {
	page?: SupportPages;
}

const getSupportTypesHandbook = ({ page }: GetSupportTypesHandbookRequest) =>
	fetcher<SupportType[]>({
		url: `${ACCOUNT_URL}/support/types${page ? `?page=${page}` : ''}`,
		method: 'GET',
	});

export interface SendSubscriptionRequest {
	name: string;
	email: string;
	type: string;
	agreeProcessData: boolean;
	captcha: Nullable<string>;
}

const sendSubscription = ({ name, email, type, captcha }: SendSubscriptionRequest) =>
	fetcher<any>({
		url: `${ACCOUNT_URL}/subscribe`,
		method: 'POST',
		body: {
			name,
			email,
			type,
			captcha,
		},
	});

export const supportRequest = {
	sendCallback,
	sendSubscription,
	sendSupport,
	getSupportTypesHandbook,
};
