import { DocumentTypeModel } from 'config/types/document-types';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { documentTypesRequests } from 'store/account/api/document-types';
// import { documentTypesRequests } from 'shared/api';
// import { DocumentTypeModel } from 'shared/lib/types';

const $documentTypes = createStore<DocumentTypeModel[]>([]);
const fetchDocumentTypes = createEvent();
const fetchDocumentTypesFx = createEffect(documentTypesRequests.getDocumentTypes);

sample({
	clock: fetchDocumentTypes,
	target: fetchDocumentTypesFx,
});

sample({
	clock: fetchDocumentTypesFx.doneData,
	target: $documentTypes,
});

export const model = {
	$documentTypes,
	fetchDocumentTypes,
};
