import React, { memo } from 'react';
import { useGate, useUnit } from 'effector-react';
// import { OrderLayout } from 'widgets/layouts/order';
// import { confirmationModel } from 'widgets/order/order-confirmation/model';
// import { EditCartCabin } from 'features/edit-cart-cabin';
import { cartModel } from 'entities/cart';
import { orderCabinsModel } from 'entities/order';
// import { message } from 'shared/lib/message';
// import { Alert, Button, Condition, Typo } from 'shared/ui/atoms';
// import { CaseMessage } from 'shared/ui/molecules';
import { model } from '../model';
import { OrderTouristControls } from './order-tourist-controls';
import styles from './styles.module.scss';
import { message } from 'config/constants/message';
import { Button } from 'layout/components/buttons/button';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { OrderLayout } from 'layout/layouts/order';
import { confirmationModel } from '../../order-confirmation/model';
import { CaseMessage } from 'layout/components/inputs/case-message';
import { EditCartCabin } from 'entities/edit-cart-cabin';

export const OrderTourists = memo(() => {
	const [cart, errorCode, getCartPrices, pricePending] = useUnit([
		cartModel.$cart,
		cartModel.$errorCode,
		cartModel.getCartPrices,
		cartModel.pricePending,
	]);

	useGate(model.Gate);
	useGate(orderCabinsModel.Gate);
	useGate(confirmationModel.LeadGate);

	return (
		<Condition
			value={Boolean(errorCode !== null) && !pricePending}
			then={
				<div className={styles.errorMessageWrap}>
					<CaseMessage size="small">
						<Typo design="text-m" raw={message.order.error.AC1} />
						<Button type="button" btnType="text" onClick={getCartPrices}>
							Обновить
						</Button>
					</CaseMessage>
				</div>
			}
			else={
				<OrderLayout controls={<OrderTouristControls />}>
					<div className={styles.cabinsList}>
						<div className={styles.alertWrapper}>
							<Alert className={styles.alert} type="warning-green">
								<Typo design="text-s">
									Бронирование производится бесплатно на срок до 3 дней. Сроки внесения оплаты будут
									указаны в брони.
									<br />
									Для получения дополнительных скидок имениннику, детям и пенсионерам внесите данные
									документов и даты рождения туристов.
								</Typo>
							</Alert>

							<Alert className={styles.alertWarning} type="warning">
								<span>
									Для оформления договора и оплаты будут необходимы паспортные данные Туриста. Их
									можно добавить позже или заполнить сейчас
								</span>
							</Alert>
						</div>

						{cart.map((cabin) => (
							<EditCartCabin {...cabin} key={cabin.id} allowRemove={cart.length > 1} />
						))}
					</div>
				</OrderLayout>
			}
		/>
	);
});
