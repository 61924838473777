import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { useUnit } from 'effector-react';
import { shipModel, ShipStar } from 'entities/ships';
// import { Skeleton, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Skeleton } from 'layout/components/special/skeleton';
import { Typo } from 'layout/components/typo/ui';

export interface ShipProps {
	sizeVariant?: 'large';
}

export const Ship: FC<ShipProps> = memo(({ sizeVariant }) => {
	const [ship, shipStatus] = useUnit([shipModel.$ship, shipModel.$status]);
	const pending = shipStatus === 'pending';

	if (pending) {
		return (
			<div className={styles.ship}>
				<Skeleton width={112} height={20} />
				<Skeleton width={148} height={20} />
			</div>
		);
	}

	if (!ship) {
		return null;
	}

	const { stars, isNew, name, type } = ship;

	const isLarge = sizeVariant === 'large';
	const freezeDesktop = !isLarge;

	return (
		<div className={classnames(styles.ship, sizeVariant && styles[sizeVariant])}>
			<Typo design={isLarge ? 'headline-m' : 'headline-s'} freezeDesktop={freezeDesktop}>
				{name}
			</Typo>
			<div className={styles.tags}>
				{type && (
					<Typo
						className={styles.type}
						design={isLarge ? 'headline-xs' : 'input-text-s'}
						freezeDesktop={freezeDesktop}
					>
						{type}
					</Typo>
				)}
				{stars && <ShipStar stars={stars} />}
				{isNew && (
					<Typo
						className={styles.isNew}
						design="description-features"
						freezeDesktop={freezeDesktop}
					>
						New
					</Typo>
				)}
			</div>
		</div>
	);
});
