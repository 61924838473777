import React, { forwardRef, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { BtnType } from 'layout/components/buttons/button/button';
import { Typo } from 'layout/components/typo/ui';
import { Button } from 'layout/components/buttons/button';
import { ErrorsBlock } from './errors-block';
import { Message } from 'config/commonTypes';
import { useReCaptchaVerify } from 'utils/verification';

export interface FormProps {
	title?: string | JSX.Element;
	text?: string | ReactNode;
	error?: Message;
	isShowError?: boolean;
	handleSubmit: (e: React.SyntheticEvent<HTMLFormElement>, captchaToken?: string) => void;
	children: ReactNode;
	buttonContent?: ReactNode | string;
	pending?: boolean;
	formClassName?: string;
	submitClassName?: string;
	footerClassName?: string;
	footer?: ReactNode;
	footerInForm?: boolean;
	theme?: BtnType;
	onRetry?: () => void;
	errorClassName?: string;
}

export const Form = forwardRef<HTMLFormElement, FormProps>(
	(
		{
			title,
			text,
			children,
			footer,
			isShowError,
			handleSubmit,
			buttonContent,
			pending,
			formClassName,
			submitClassName,
			footerClassName,
			footerInForm,
			onRetry,
			theme,
			error,
			errorClassName,
		},
		ref,
	) => {

		// для проверки нужно подключать reCAPTCHA в консоли администратора reCAPTCHA (google)
		const handleReCaptchaVerify = useReCaptchaVerify(handleSubmit);

		return (
			<div className={styles.wrapper}>
				{(title || text) && (
					<div className={styles.head}>
						{title && (
							<Typo className={styles.title} design="headline-xl">
								{title}
							</Typo>
						)}
						{text && (
							<Typo className={styles.text} design="text-m">
								{text}
							</Typo>
						)}
					</div>
				)}

				<form
					noValidate
					onSubmit={handleReCaptchaVerify}
					// onSubmit={handleSubmit}
					className={classnames(styles.form, formClassName)}
				>
					{children}
					{isShowError && error && (
						<>
							<ErrorsBlock className={errorClassName} error={error} />
							{onRetry && (
								<Button size="large" btnType="outline" type="button" onClick={onRetry}>
									Повторить попытку
								</Button>
							)}
						</>
					)}
					{buttonContent && (
						<Button
							className={classnames(styles.form__submit, submitClassName)}
							loading={pending}
							btnType={theme}
							size="large"
						>
							{buttonContent}
						</Button>
					)}
					{footer && footerInForm && (
						<div className={classnames(footerClassName, styles.footer)}>{footer}</div>
					)}
				</form>
				{footer && !footerInForm && (
					<div className={classnames(footerClassName, styles.footer)}>{footer}</div>
				)}
			</div>
		);
	},
);
