import React from 'react';
// import { AccountModal } from 'entities/account-modal';
// import { Alert, Typo } from 'shared/ui/atoms';
import { useLogoutModal } from '../../lib';
import { LogoutFooter } from '../logout-footer';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { AccountModal } from 'layout/components/auth/account-modal';

export const Logout = () => {
	const state = useLogoutModal();
	console.log('Logout state.isOpen',state.isOpen ? 'true' : 'false')

	return (
		<AccountModal
			state={state}
			classNameModalContent={styles.logout_content}
			footer={<LogoutFooter />}
		>
			{() => (
				<div className={styles.logout}>
					<Typo design="headline-xl">Выйти из профиля?</Typo>
					<Typo design="text-m">
						Вы не сможете просматривать баланс бонусов, информацию по заявкам, персональные
						рекомендации и спецпредложения.
					</Typo>
					{/* <Alert iconId="cart" type="info">
						<Typo design="text-m">
							Также при выходе из профиля ваша корзина очистится, и все сохраненные вами каюты
							пропадут.
						</Typo>
					</Alert> */}
				</div>
			)}
		</AccountModal>
	);
};
