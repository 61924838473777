import React, { FC, useRef } from 'react';
import cn from 'classnames';
import { AriaRadioProps, useFocusRing, useRadio, VisuallyHidden } from 'react-aria';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { Typo } from 'shared/ui/atoms/typo';
// import { useRadioGroupContext } from 'shared/ui/molecules';
import style from './style.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { Typo } from 'layout/components/typo/ui';
import { useRadioGroupContext } from '../radio-group';

interface RadioProps extends AriaRadioProps, DefaultStyledProps {
	error?: JSX.Element;
	theme?: 'secondary';
}

export const Radio: FC<RadioProps> = (props) => {
	const { className, children, error, theme } = props;
	const ref = useRef(null);
	const state = useRadioGroupContext();
	const { focusProps, isFocusVisible } = useFocusRing();
	const { inputProps, isSelected } = useRadio(props, state, ref);

	const checkedClassName = isSelected ? style.radio__checked : null;
	const focusedClassName = isFocusVisible ? style.radio__focused : null;

	return (
		<>
			<label className={cn(className, style.label, theme && style[theme])}>
				<VisuallyHidden>
					<input {...inputProps} {...focusProps} ref={ref} />
				</VisuallyHidden>
				<div
					role="radio"
					className={cn(style.radio, checkedClassName, focusedClassName)}
					aria-hidden="true"
				/>
				{children}
			</label>
			{error && (
				<Typo design="input-text-s" className={cn(style.error, 'errormessage')}>
					{error}
				</Typo>
			)}
		</>
	);
};
