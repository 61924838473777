import { KLK_PHONE } from "./contacts";

export const message: Record<string, any> = {
	defaultError: 'Что-то пошло не так, попробуйте позже.',
	form: {
		validation: {
			required: 'Заполните это поле',
			requiredRadio: 'Выберите один из пунктов',
			repeatPassword: 'Пароли не совпадают',
			changePassword: 'Новый пароль не должен совпадать со старым паролем',
			format: {
				login: 'Неверный формат',
				email: 'Неверный формат e-mail',
				emailMax250: 'Превышено максимальное количество символов: 250',
				phone: 'Неверный формат телефона',
				password:
					'Пароль должен содержать от 6 до 255 символов, хотя бы одну цифру, строчную и заглавную букву. Допустимые символы: латинские буквы, цифры, другие символы, кроме пробелов',
				latinCyrillicDigits:
					'Допустимые символы: кириллица, латинские буквы, пробелы, дефис, арабские и римские цифры',
				max250: 'Превышен лимит 250 символов',
				max255: 'Превышен лимит 255 символов',
				max2000: 'Превышен лимит 2 000 символов',
				max5000: 'Превышен лимит 5 000 символов',
				max2000AccountSupport: 'Превышено допустимое количество символов в сообщении',
				maxNumber100: 'Введите число до 100',
				maxMinNumber100: 'Введите число от 1 до 100',
			},
			shouldComplete: 'Необходимо заполнить полностью',
			sholudChecked: 'Необходимо согласиться с условиями',
			agreeProcessData: 'Подтвердите согласие на обработку данных чтобы подписаться на рассылку',
			birthday: {
				adult: 'Возраст первого туриста не может быть меньше 18 лет',
				aloneAdult: 'Одноместное размещение для туристов старше 18 лет',
				child: 'Возраст ребенка должен быть меньше 18 лет',
			},
		},
	},
	select: {
		quickSearchEmpty: 'Значение не найдено',
	},
	profile: {
		registration: {
			AC5: {
				title: 'Требуется подтверждение',
				text: 'Без согласия на обработку персональных данных регистрация не может быть завершена',
			},
			AC6: {
				email: {
					title: 'Аккаунт с таким e-mail уже существует.',
					text: 'Укажите другой e-mail или попробуйте восстановить доступ.',
				},
				phone: {
					title: 'Аккаунт с таким номером телефона уже существует.',
					text: 'Укажите другой номер телефона или попробуйте восстановить доступ.',
				},
			},
			AC10: {
				text: 'Код неверный. Отправить код повторно?',
			},
			AC11: {
				text: 'Регистрация сейчас невозможна, попробуйте позже.',
			},
			AC12: {
				text: 'Регистрация сейчас невозможна, попробуйте позже.',
			},
			400: {
				text: 'Неверный формат телефона',
			},
		},
		authorization: {
			401: 'Неверный логин или пароль',
			403: 'Ваш e-mail не подтвержден. Используйте авторизацию по номеру телефона или свяжитесь со службой технической поддержки на <a href="mailto:support@infoflot.com">support@infoflot.com</a> или по телефону <a href="tel:88007074418">8 (800) 707-44-18</a>.',
			404: 'Данный номер не зарегистрирован',
			429: 'Аккаунт заблокирован на 1 час',
			423: 'Ваш аккаунт не активен. Свяжитесь со службой технической поддержки на <a href="mailto:support@infoflot.com">support@infoflot.com</a> или по телефону <a href="tel:88007074418">8 (800) 707-44-18</a>.',
			error: 'Авторизация сейчас невозможна, попробуйте позже.',
		},
		verifyPhone: {
			400: 'Введен неверный код или срок действия истек.',
			401: 'Введен неверный код или срок действия истек.',
			423: 'Ваш аккаунт деактивирован. Обратитесь в службу поддержки по номеру 8 (800) 301-91-22',
			501: 'Ваш аккаунт найден но вы зарегистированы как агент. Агентам просьба пользоваться старым сайтом <a href="http://old.infoflot.com/">http://old.infoflot.com/</a>',
			error: 'Авторизация сейчас невозможна, попробуйте позже.',
		},
		checkPhone: {
			400: 'Введён неверный код или срок действия истёк',
			401: 'Введён неверный код или срок действия истёк',
			404: 'Пользователь с таким номером телефона не найден.',
			423: 'Ваш аккаунт деактивирован. Обратитесь в службу поддержки по номеру 8 (800) 301-91-22',
			error: 'Восстановление пароля сейчас невозможно, попробуйте позже.',
		},
		resetPassword: {
			404: 'Аккаунт не найден.',
			409: 'Новый пароль не должен совпадать со старым паролем.',
			422: 'Слабый пароль',
			423: 'Ваш аккаунт деактивирован. Обратитесь в службу поддержки по номеру 8 (800) 301-91-22',
			401: 'Смена пароля сейчас невозможна, попробуйте позже.',
			400: 'Восстановление пароля сейчас невозможно, попробуйте позже.',
			error: 'Восстановление пароля сейчас невозможно, попробуйте позже.',
		},
		pageErrors: {
			ac3: 'Недействительная ссылка: истек срок действия или вы воспользовались этой ссылкой раньше',
			error: '',
		},
		changePassword: {
			409: 'Новый пароль не должен совпадать со старым паролем',
			422: 'Неверный старый пароль',
			error: 'Что-то пошло не так, попробуйте позже.',
		},
		changePhone: {
			400: 'Неверный формат телефона',
			422: 'Этот номер телефона не доступен. Укажите другой номер телефона или обратитесь в службу поддержки по номеру 8 (800) 707-44-18',
			error: 'Что-то пошло не так, попробуйте позже.',
		},
		changeEmail: {
			422: `Введенный e-mail недоступен. Укажите другой или обратитесь в службу поддержки по номеру ${KLK_PHONE}`,
			error: 'Что-то пошло не так, попробуйте позже.',
		},
		changePhoneVerify: {
			400: 'Введён неверный код или срок действия истёк.',
			error: 'Что-то пошло не так, попробуйте позже.',
		},
		changeProfileData: {
			passportData: {
				AC2: {
					title: 'Пользователь с такими паспортными данные уже зарегистрирован.',
					text: 'Укажите другие данные или обратитесь в службу поддержки по номеру 8 (800) 301-91-22',
				},
				error: 'Что-то пошло не так, попробуйте позже.',
			},
		},
	},
	requests: {
		error: 'Вы всё делали правильно, но не удалось получить данные по заявке. Попробуйте еще раз',
		editPassenger: {
			404: 'Пассажир не найден',
			403: 'С таким статусом нельзя редактировать пассажиров',
			error: 'Отправка данных сейчас невозможна, попробуйте позже.',
		},
		payment: {
			paymentAmount: {
				allowedToPay: 'Вы можете оплатить полную сумму или внести предоплату',
				payed: 'Вы можете оплатить полную сумму или скорректировать по желанию',
				notAllowedToPay: (date: string) => `Вам нужно оплатить всю оставшуюся сумму до ${date}`,
			},
			errorCodes: {
				AC5: 'Не удалось перейти к оплате. Попробуйте ещё раз.',
				AC9: 'Оплата недоступна. Необходимо заполнить персональные данные в разделе Личные данные.',
				AC10: 'Оплата недоступна. Необходимо заполнить персональные данные в разделе Личные данные.',
				AC11: 'Оплата недоступна. Необходимо подтвердить e-mail в разделе Личные данные.',
				AC13: 'Оплата недоступна. Необходимо заполнить данные туристов на странице заявки.',
				error: 'Не удалось перейти к оплате. Попробуйте ещё раз.',
			},
		},
		createRequest: {
			422: 'Что-то пошло не так. Попробуйте позже.',
			400: 'Что-то пошло не так. Попробуйте позже.',
			498: 'Что-то пошло не так. Попробуйте позже.',
			error: 'Что-то пошло не так. Попробуйте позже.',
		},
	},
	callback: {
		successTitle: 'Заявка на обратный звонок успешно создана',
		successText: 'Наш сотрудник перезвонит вам в ближайшее время.',
		error: 'Вы всё сделали правильно, но заказ звонка завершился ошибкой.<br />Попробуйте ещё раз.',
		AC1: 'Заполните это поле',
		AC3: 'Вы всё сделали правильно, но заказ звонка завершился ошибкой. Попробуйте ещё раз.',
	},
	support: {
		successTitle: 'Ваш вопрос отправлен',
		successText: 'Наш сотрудник свяжется с вами в ближайшее время',
		error: 'Вы всё сделали правильно, но заказ звонка завершился ошибкой.<br />Попробуйте ещё раз.',
		AC2: 'Заполните это поле',
		AC3: 'Вы всё сделали правильно, но заказ звонка завершился ошибкой. Попробуйте ещё раз.',
		handbookError: 'Темы вопросов не загрузились.',
	},
	subscription: {
		successTitle: 'Поздравляем!',
		successText: 'Вы подписались на новостную рассылку круизного центра Инфофлот.',
		error: 'Вы всё сделали правильно, но подписка завершилась ошибкой. Попробуйте ещё раз.',
		AC1: 'Заполните это поле',
		AC3: 'Вы всё сделали правильно, но подписка завершилась ошибкой. Попробуйте ещё раз.',
		409: 'Вы уже подписаны на новости круизного центра Инфофлот',
	},
	search: {
		AC1: 'Мы не смогли найти круизы<br />по вашему запросу.',
		AC2: 'Вы всё делали правильно, но не успели загрузиться данные для фильтров поиска.<br />Попробуйте ещё раз',
		AC3: 'Вы всё делали правильно, но не удалось получить список круизов.<br />Попробуйте еще раз',
	},
	shipsSearch: {
		AC1: 'Мы не смогли найти теплоходы<br />по вашему запросу',
		AC2: 'Вы всё делали правильно, но не успели загрузиться данные для фильтров поиска. Попробуйте ещё раз',
		AC3: 'Вы всё делали правильно, но не удалось получить список теплоходов. Попробуйте еще раз',
	},
	cabinsSearch: {
		error: 'Мы не смогли найти каюты<br/> по вашему запросу',
		AC1: 'Поиск не дал результатов.<br/> Попробуйте искать по другим параметрам.',
	},
	routeAndExcursion: {
		empty: 'Информация о маршруте экскурсий<br />будет предоставлена позже.',
	},
	relatedCruises: {
		AC3: 'Вы все делали правильно, но не удалось получить список круизов.<br />Обновите страницу.',
	},
	inviteFriend: {
		error: 'Отправка данных сейчас невозможна, попробуйте позже.',
		AC3: 'Введенного номера карты не существует или равен карте юзера.',
	},
	sharePoints: {
		error: 'Отправка данных сейчас невозможна, попробуйте позже.',
		AC3: 'Введенного номера карты не существует или равен карте юзера.',
	},
	order: {
		passengerTypeDiscount: {
			child: 'Размер скидки на ребенка зависит от оператора круиза',
			pensioner:
				'Скидка пенсионерам применяется не всеми операторами. Для применения необходимо при оформлении заказа указать пол и дату рождения',
		},
		error: {
			AC1: 'Вы всё делали правильно, но не успели загрузиться данные для круиза. Попробуйте ещё раз',
		},
	},
	cabinInfo: {
		error: 'Вы все делали правильно, но не удалось загрузить данные. Обновите страницу.',
	},
	bookingSupport: {
		title: 'Заявка на подбор кают',
		titleSuccess: 'Заявка на подбор кают<br />успешно создана',
		waitingListTitle: 'Заявка на лист ожидания или подбор кают',
		waitingListTitleSuccess: 'Заявка на лист ожидания или подбор кают<br />спешно создана',
		textSuccess: 'Наш оператор свяжется с Вами и поможет в подборе кают',
		AC3: 'Что-то пошло не так.<br />Попробуйте позже.',
		AC5: 'Вы всё делали правильно, но не успели загрузиться данные. Попробуйте ещё раз',
		agreeProcessData:
			'Без согласия на обработку персональных данных  заявка не может быть создана.',
	},
	bonusSupport: {
		error: 'Отправка данных сейчас невозможна, попробуйте позже.',
	},
	bonusRecovery: {
		error: 'Отправка данных сейчас невозможна, попробуйте позже.',
		AC2: 'Ваш круиз произошел до официального открытия Клуба Любителей Круизов, мы не можем восстановить ваши данные.',
		AC5: 'По заданным вами параметрам не найдено ни одного подходящего круиза. Измените период или теплоход.',
	},
	news: {
		AC1: 'Не удалось получить список новостей. <br/>Попробуйте еще раз',
		AC2: 'К сожалению, актуальных новостей нет. <br/>Попробуйте позже.',
	},
	bonusForBuy:
		'Участникам Клуба любителей круизов начисляются баллы, которыми полностью или частично можно оплатить премиальные круизы.',
	bonusPayments:
		'Этот круиз можно оплатить бонусными баллами <br />Клуба любителей круизов <br />(1 рубль = 10 баллов)',
	bonusPaymentsNextStage: 'Про покупку за баллы подробнее узнаете на следующем этапе',
	changePriceMessage: {
		rub: 'Цены указаны в рублях на одного человека. Компания оставляет за собой право на дальнейшее изменение цен.',
		euro: 'Цены указаны в евро на одного человека. Компания оставляет за собой право на дальнейшее изменение цен.',
	},
};
