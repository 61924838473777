import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { model } from '../../model';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Button } from 'layout/components/buttons/button';
import { useUnit } from 'effector-react';
import { verificationModel } from 'utils/verification';

export const ResendEmail = () => {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const setToken = useUnit(verificationModel.setCaptchaToken);
	const [loginType, login, recoverByEmail, pending] = useUnit([
		model.$loginType,
		model.$login,
		model.recoverByEmail,
		model.$recoverByEmailPending,
	]);

	return loginType === 'email' ? (
		<Typo className={styles.wrapper} design="input-text-m">
			Не пришло письмо? Проверьте папку «Спам» <br /> или попробуйте еще раз{' '}
			<Button
				className={styles.button}
				loading={pending}
				onClick={async () => {
					if (executeRecaptcha) {
						const token = await executeRecaptcha('form');
						setToken(token);
						recoverByEmail({ login: login ?? '' });
					}
				}}
				size="large"
				btnType="text"
			>
				выслать письмо
			</Button>
		</Typo>
	) : null;
};
