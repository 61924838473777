/* eslint-disable-next-line import/no-extraneous-dependencies */
import { CalendarDate, CalendarDateTime, DateValue, parseDateTime } from '@internationalized/date';
import dayjs from 'dayjs';
import localeRu from 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { getNumDeclension } from '../string';
import { TIMEZONE_DEFAULT } from 'config/commonConstants';
import { DAYS_DECLENSION, HOURS_DECLENSION, MINUTES_DECLENSION } from 'config/constants';
import { padForNum } from 'utils/number';
import { Request } from 'store/auth/types/account/request';
import { MONTHS_SHORT } from 'config/constants/dates';

dayjs.locale(
	{
		...localeRu,
		monthsShort: MONTHS_SHORT,
	},
	{},
	true,
);
dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (
	date?: Nullable<Date | string>,
	tpl = 'DD.MM.YYYY',
	tz = TIMEZONE_DEFAULT,
	resetTimezone = false,
) => {
	if (!date) {
		return null;
	}

	const initDate = resetTimezone ? dayjs.utc(date) : dayjs(date);

	if (!initDate.isValid()) {
		return null;
	}

	return initDate.tz(tz).locale('ru').format(tpl);
};

export const diffDays = (a?: Nullable<Date | string>, b?: Nullable<Date | string>) => {
	if (!a || !b) {
		return null;
	}

	const dateA = dayjs(a);
	const dateB = dayjs(b);

	const diff = Math.ceil(dateB.diff(dateA, 'days', true));

	return `${diff} ${getNumDeclension(diff, DAYS_DECLENSION)}`;
};

export const dateToUTC = (date?: Nullable<Date>) => (date ? date.toUTCString().slice(0, -4) : null);

export const dateToISO = (date?: Nullable<Date>, withTime = true) => {
	const iso = date ? date.toISOString() : null;

	if (!iso || withTime) {
		return iso;
	}

	return iso.split('T')[0];
};

export const dateFromISO = (date?: Nullable<string>) => {
	if (!date) {
		return;
	}

	try {
		return parseDateTime(date.split('.')[0]);
	} catch {
		// eslint-disable-next-line
		return undefined;
	}
};

export const dateToCalendarDate = (date: Date) => {
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return new CalendarDate(year, month, day);
};

export const calendarDateToISO = (calendarDateTime?: DateValue, withTime = true) => {
	if (!calendarDateTime) {
		return null;
	}

	const {
		year,
		day,
		month,
		hour = 0,
		minute = 0,
		second = 0,
	} = calendarDateTime as CalendarDateTime;

	const date = `${year}-${padForNum(month, '00')}-${padForNum(day, '00')}`;
	const time = withTime
		? `T${padForNum(hour, '00')}:${padForNum(minute, '00')}:${padForNum(second, '00')}`
		: '';

	return `${date}${time}`;
};

export const getExpirationMessage = (value?: Nullable<string>) => {
	if (!value) {
		return null;
	}

	try {
		const now = new Date();
		const date = new Date(value);
		const minutesDiff = (date.getTime() - now.getTime()) / (1000 * 60);
		const hoursDiff = minutesDiff / 60;
		const daysDiff = hoursDiff / 24;

		if (minutesDiff < 0) {
			return {
				type: 'expired',
				message: null,
			};
		}

		if (hoursDiff >= 24) {
			const days = Math.trunc(daysDiff);

			return {
				type: 'days',
				message: `${days} ${getNumDeclension(days, DAYS_DECLENSION)}`,
			};
		}

		if (hoursDiff < 1) {
			const minutes = Math.trunc(minutesDiff);

			return {
				type: 'minutes',
				message: `00:${minutes}`,
			};
		}

		const hours = Math.trunc(hoursDiff);

		return {
			type: 'hours',
			message: `${hours} ${getNumDeclension(hours, HOURS_DECLENSION)}`,
		};
	} catch {
		return null;
	}
};

export const getExpirationStatus = (value?: Nullable<string>) => {
	if (!value) {
		return null;
	}

	try {
		const now = new Date();
		const date = dayjs.utc(value).tz(TIMEZONE_DEFAULT).locale('ru').toDate();
		const minutesDiff = (date.getTime() - now.getTime()) / (1000 * 60);
		const hoursDiff = minutesDiff / 60;

		if (minutesDiff < 0) {
			return 'Срок оплаты истёк';
		}

		if (hoursDiff < 1) {
			const minutes = Math.trunc(minutesDiff);

			return `Срок оплаты истекает через 00:${minutes} ${getNumDeclension(
				minutes,
				MINUTES_DECLENSION,
			)}`;
		}

		if (hoursDiff < 24) {
			const hours = Math.trunc(hoursDiff);

			return `Срок оплаты истекает через ${hours} ${getNumDeclension(hours, HOURS_DECLENSION)}`;
		}

		if (hoursDiff >= 24) {
			return `Срок оплаты <b>до ${formatDate(date, 'DD.MM.YYYY')}</b>`;
		}
	} catch {
		return null;
	}
};

export const diffTimetable = (
	a?: Nullable<Date | string>,
	b?: Nullable<Date | string>,
	displayNull?: boolean,
) => {
	if (!a || !b) {
		return null;
	}

	const dateA = dayjs(a);
	const dateB = dayjs(b);

	const diff = dateA.diff(dateB, 'minutes');

	if (diff === 0 && displayNull) {
		return '0 мин';
	}

	return `${Math.floor(diff / 60) ? `${Math.floor(diff / 60)} ч ` : ''}${
		diff % 60 ? `${diff % 60} мин` : '00 мин'
	}`;
};

export const getAge = (date?: Nullable<Date | string>, toDate?: string) => {
	if (!date) {
		return 0;
	}

	const now = toDate ? dayjs(toDate) : dayjs(new Date());
	const birthday = dayjs(date);

	return now.diff(birthday, 'year');
};

export const getDateRangeText = (dateStart?: Nullable<string>, dateEnd?: Nullable<string>) => {
	try {
		return [
			dateStart ? `с ${formatDate(dateStart, 'DD.MM.YYYY')}` : null,
			dateEnd ? `до ${formatDate(dateEnd, 'DD.MM.YYYY')}` : null,
		]
			.filter(Boolean)
			.join(' ');
	} catch {
		return null;
	}
};

export const getTimeRangeText = (
	dateStart?: Nullable<string>,
	dateEnd?: Nullable<string>,
	resetTimezone = false,
) =>
	[
		formatDate(dateStart, 'HH:mm', TIMEZONE_DEFAULT, resetTimezone),
		'–',
		formatDate(dateEnd, 'HH:mm', TIMEZONE_DEFAULT, resetTimezone),
	].join(' ');

export const getDaysBetween = (a?: Nullable<Date | string>, b?: Nullable<Date | string>) => {
	if (!a || !b) {
		return 0;
	}

	const dateA = dayjs(a);
	const dateB = dayjs(b);

	return dateB.diff(dateA, 'days');
};

export const getMonthsBetween = (a?: Nullable<Date | string>, b?: Nullable<Date | string>) => {
	if (!a || !b) {
		return 0;
	}

	const dateA = dayjs(a);
	const dateB = dayjs(b);

	return dateB.diff(dateA, 'month');
};

export const deltaDate = ({
	toDate,
	days,
	months,
	years,
}: {
	toDate: Date;
	days: number;
	months: number;
	years: number;
}) =>
	new Date(
		toDate.getFullYear() + years,
		toDate.getMonth() + months,
		Math.min(
			toDate.getDate() + days,
			new Date(toDate.getFullYear() + years, toDate.getMonth() + months + 1, 0).getDate(),
		),
	);

export const getTodayCalendarDate = (resetTime?: boolean) => {
	const now = new Date();

	if (resetTime) {
		now.setHours(3);
		now.setMinutes(0);
		now.setSeconds(0);
		now.setMilliseconds(0);
	}

	return dateFromISO(now.toISOString());
};

export const getCalendarDate = (year: number, month: number, day: number) =>
	new CalendarDate(year, month, day);

export const getCalendarDateFromEuDateString = (src: string) => {
	const segments = src.split('.').map(Number);

	if (!(segments.length === 3 && segments.every((s) => !Number.isNaN(s)))) {
		return;
	}

	return new CalendarDateTime(segments[2], segments[1], segments[0]);
};

export const getCalendarDatesMonthsDiff = (
	a?: CalendarDate,
	b?: CalendarDate,
	tz = TIMEZONE_DEFAULT,
) => getMonthsBetween(a?.toDate(tz), b?.toDate(tz));

export const dateIsOverdue = (date?: Nullable<Date | string>) => {
	if (!date) {
		return true;
	}

	return dayjs(date).add(3, 'hour').diff(dayjs(new Date()), 'minutes') < 0;
};

export const beginningDateIsOverdue = (date?: Nullable<Date | string>) => {
	if (!date) {
		return true;
	}

	return dayjs(date).diff(dayjs(new Date()), 'minutes') < 0;
};

export const getNextPaymentDate = ({
	expirationDate,
	prepaymentDate,
	payed,
	prepayment,
}: Pick<Request, 'expirationDate' | 'prepaymentDate' | 'payed' | 'prepayment'>) => {
	const prepaymentIsOverdue = dateIsOverdue(prepaymentDate);
	const needPrepayment = (payed ?? 0) < (prepayment ?? 0);

	return !prepaymentIsOverdue && needPrepayment ? prepaymentDate : expirationDate;
};

export const isAdult = (birthday: Date) => {
	const latestAdultBirthday = deltaDate({
		toDate: new Date(),
		years: -18,
		months: 0,
		days: 0,
	});

	const diff = Math.ceil(dayjs(latestAdultBirthday).diff(birthday, 'days', true));

	return diff >= 0;
};

export const isValidISO = (date?: Nullable<string>) => {
	if (!date) {
		return;
	}

	try {
		const dateParsed = new Date(Date.parse(date));

		return dateParsed.toISOString() === date;
	} catch {
		return false;
	}
};

export const getStringDate = (date?: Nullable<Date | string>, tpl = 'DD.MM.YYYY') =>
	dayjs(date).format(tpl);
