import { useOverlayTriggerState } from 'react-stately';
import { useGate, useUnit } from 'effector-react';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { useViewport } from 'shared/lib/viewport';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { cabinDeleteModel } from '../../model';
import styles from '../empty-alert/styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';
import { useViewport } from 'layout/viewport';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';

export const CabinDeleteConfirm = () => {
	const [removeFromCart, closeModal, cabinInfo] = useUnit([
		cabinDeleteModel.removeFromCartWithConfirm,
		cabinDeleteModel.closeModal,
		cabinDeleteModel.$cabinInfo,
	]);
	const state = useOverlayTriggerState({});

	const shouldMount = useDelayUnmount(state.isOpen, 600);
	useGate(cabinDeleteModel.ModalGate, state);

	const { isMob } = useViewport();

	// return <div>CabinDeleteConfirm</div>
	return shouldMount ? (
		<Modal
			classNameModal={styles.modal}
			classNameModalContent={styles.content}
			closeButtonClassName={styles.close}
			state={state}
			isShown={state.isOpen}
		>
			<Typo design={isMob ? 'headline-xl' : 'headline-m'} className={styles.title}>
				Каюта будет
				<br /> удалена&nbsp;из заказа
			</Typo>
			<Typo className={styles.text} design={isMob ? 'text-m' : 'text-s'}>
				{`Каюта ${cabinInfo?.name} будет удалена из заказа. Вы сможете заново добавить её вернувшись на страницу круиза`}
			</Typo>
			<div className={styles.buttons}>
				<Button onClick={removeFromCart} size="large" btnType="outline">
					Удалить
				</Button>
				<Button onClick={closeModal} size="large">
					Отменить
				</Button>
			</div>
		</Modal>
	) : null;
};
