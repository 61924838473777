import React, { FC, useRef } from 'react';
import { DateValue } from '@react-types/datepicker';
import { Placement } from '@react-types/overlays';
import classnames from 'classnames';
import { useDatePicker } from 'react-aria';
import { DatePickerStateOptions, useDatePickerState } from 'react-stately';
// import { useClickOutside } from 'shared/lib/hooks';
// import { Popover } from 'shared/ui/atoms/popover';
// import { Icon } from 'shared/ui/icons';
// import { Calendar, CalendarProps } from 'shared/ui/molecules';
import { Input, InputInterface } from '../input';
import {
	inputDateCalendarDateConstraint,
	inputDateCalendarValueToStringValue,
	inputDateStringValueToCalendarValue,
	inputDateValueParser,
} from './lib';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Popover } from 'layout/components/special/popover';
import { useClickOutside } from 'utils/hooks/use-click-outside';
import { Calendar, CalendarProps } from '../../calendar';

interface InputDateProps<T extends DateValue = DateValue> extends Omit<InputInterface, 'onChange'> {
	onChange?: (value: string) => void;
	datepicker?: DatePickerStateOptions<T>;
	placement?: Placement;
	calendar?: Pick<CalendarProps, 'dateSelector'>;
	onBlurValueParser?: (value: any) => any;
	hasError?: boolean;
	withoutTimezone?: boolean;
}

export const InputDate: FC<InputDateProps> = ({
	onChange,
	placeholder,
	secondPlaceholder,
	withLabel,
	size,
	icon = 'calendar',
	className,
	datepicker = {},
	placement = 'bottom left',
	calendar,
	onBlurValueParser,
	hasError,
	withoutTimezone = false,
	...props
}) => {
	const wrapRef = useRef<Nullable<HTMLDivElement>>(null);
	const triggerRef = useRef<Nullable<HTMLDivElement>>(null);
	const popoverRef = useRef(null);

	const calendarValue = inputDateCalendarDateConstraint(
		inputDateStringValueToCalendarValue(String(props.value)),
		datepicker,
	);

	const state = useDatePickerState({
		...datepicker,
		autoFocus: false,
		// @ts-ignore
		value: calendarValue ?? null,
		onChange: (datepickerCalendarValue) => {
			const datepickerFormattedValue = inputDateCalendarValueToStringValue(
				datepickerCalendarValue,
				withoutTimezone,
			);

			if (datepickerFormattedValue) {
				onChange?.(datepickerFormattedValue);
			}
		},
	});

	const { calendarProps, dialogProps } = useDatePicker(datepicker, state, triggerRef);

	const handleOpen = (condition = true) => {
		if (condition) {
			state.setOpen(true);
		}
	};

	const handleReset = () => {
		onChange?.('');
	};

	useClickOutside({
		ref: [wrapRef, popoverRef],
		handler: () => state.setOpen(false),
	});

	return (
		<div ref={wrapRef} className={classnames(className, styles.wrap)}>
			<Input
				{...props}
				moveIcon
				icon="chevron-down"
				iconLeft={icon}
				size={size}
				placeholder={placeholder}
				secondPlaceholder={secondPlaceholder}
				withLabel={withLabel}
				autoComplete="off"
				masked
				mask="99.99.9999"
				maskOptions={{
					tokens: {
						9: {
							pattern: /\d/,
						},
					},
				}}
				value={props.value}
				onChange={(e) => {
					onChange?.((e.target as HTMLInputElement).value);
				}}
				onBlur={(e) => {
					if (!state.isOpen && onBlurValueParser) {
						const constrainedValue = inputDateValueParser(
							onBlurValueParser?.((e.target as HTMLInputElement).value),
							withoutTimezone,
						);

						if (typeof constrainedValue === 'string') {
							onChange?.(constrainedValue);
						}

						props.onBlur?.(e);
					}
				}}
				onFocus={() => handleOpen()}
				onInput={() => handleOpen(!state.isOpen)}
				onPointerDown={() => {
					if (!props.disabled) {
						handleOpen(!state.isOpen);
					}
				}}
				wrapRef={triggerRef}
				classNameIcon={classnames(
					styles.inputDateIcon,
					state.isOpen && styles.inputDateIconOpen,
					props.value && styles.inputDateIconHide,
				)}
			/>
			{state.isOpen && (
				<Popover
					state={state}
					triggerRef={triggerRef}
					popoverRef={popoverRef}
					placement={placement}
					className={styles.popover}
					disableFocusManagement
					isNonModal
				>
					<div {...dialogProps}>
						<Calendar
							{...calendarProps}
							{...calendar}
							autoFocus={false}
							key={calendarValue?.toString()}
						/>
					</div>
				</Popover>
			)}
			{props.value && (
				<button type="button" className={styles.resetButton} onClick={handleReset}>
					<Icon id="close" />
				</button>
			)}
		</div>
	);
};
