import React, { FC, ReactElement, RefObject, useRef } from 'react';
import classnames from 'classnames';
import { useListBox, AriaListBoxOptions } from 'react-aria';
import { ListState } from 'react-stately';
import { LoadingState } from '@react-types/shared';
// import { message } from 'shared/lib/message';
// import { Option, OptionGroup, Typo } from 'shared/ui/atoms';
// import { Scroller } from 'shared/ui/molecules';
// import { MultiSelectListState } from 'shared/ui/molecules/select/lib';
import style from './style.module.scss';
import { message } from 'config/constants/message';
import { Typo } from 'layout/components/typo/ui';
import { Scroller } from 'layout/components/special/scroller';
import { MultiSelectListState } from 'layout/components/inputs/selects/select/lib';
import { Option, OptionGroup } from './option';

export type ListBoxViewType = 'default' | 'checkbox';

export interface ListBoxProps extends AriaListBoxOptions<unknown> {
	listBoxRef?: RefObject<HTMLUListElement>;
	state: ListState<unknown>;
	loadingState?: LoadingState;
	onLoadMore?: () => void;
	withSearch?: boolean;
	withScroll?: boolean;
	className?: string;
	classNameModalGroupOption?: string;
	errorMessage?: ReactElement;
	viewType?: ListBoxViewType;
	autoSize?: boolean;
	onScroll?: ({ y, x }: { y: number; x: number }, reachedEnd?: boolean) => void;
}

export const ListBox: FC<ListBoxProps> = (props) => {
	const ref = useRef<HTMLUListElement>(null);
	const {
		listBoxRef = ref,
		state,
		selectionMode,
		className,
		classNameModalGroupOption,
		withSearch,
		withScroll = true,
		autoSize,
		errorMessage,
		viewType = 'default',
		onScroll,
	} = props;
	const { listBoxProps } = useListBox(props, state, listBoxRef);

	const collection = [...state.collection];

	if (errorMessage || collection.length === 0) {
		const msg = errorMessage || message?.select?.quickSearchEmpty;

		return (
			<ul
				{...listBoxProps}
				ref={listBoxRef}
				className={classnames(style.listBox__error, withSearch && style.listBox__error_withSearch)}
			>
				<li>
					<Typo design="text-s">{msg}</Typo>
				</li>
			</ul>
		);
	}

	return (
		<Scroller
			className={classnames(style.listBoxScroller, {
				[style.withSearch]: withSearch,
				[style.withScroll]: withScroll,
				[style.withAutoSize]: autoSize,
			})}
			onScroll={onScroll}
		>
			<ul
				{...listBoxProps}
				ref={listBoxRef}
				className={classnames(className, style.listBox, {
					[style.withSearch]: withSearch,
				})}
			>
				{collection.map((item) => {
					if (item.type === 'section') {
						return (
							<OptionGroup
								key={item.key}
								section={item}
								classNameModalGroupOption={classNameModalGroupOption}
								state={state as MultiSelectListState<unknown>}
								selectionMode={selectionMode}
							/>
						);
					}

					return (
						<Option
							key={item.key}
							item={item}
							state={state}
							isCheckbox={viewType === 'checkbox' || selectionMode === 'multiple'}
						/>
					);
				})}
			</ul>
		</Scroller>
	);
};
