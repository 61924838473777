import { Form } from 'effector-react-form/types/ts';
import { combineEvents } from 'patronum';
import { createStore, sample, Store } from 'effector';
import { ValidationOptions, getValidation } from 'layout/components/inputs/form/lib/validation';
// import { getValidation, ValidationOptions } from 'shared/ui/organisms/form/lib/validation';

export const getDocumentTypesValidationScheme = (
	mask: string,
	required: boolean,
): ValidationOptions => ({
	required,
	validateType: 'maskComplete',
	messages: {
		validateType: `Веденные серия/номер документа не соответствуют маске ${mask}. Исправьте, пожалуйста.`,
	},
	options: {
		mask,
	},
});

interface DocumentTypesValidationFactoryProps {
	field: string;
	$pattern: Store<string>;
	form: Form<any, any>;
	required?: boolean;
}

export const documentTypesValidationFactory = ({
	field,
	$pattern,
	form,
	required = true,
}: DocumentTypesValidationFactoryProps) => {
	const $scheme = createStore<Nullable<ValidationOptions>>(null);
	const $fieldState = form.$fieldsInline.map((state) => state[field] ?? null);

	sample({
		clock: $pattern,
		fn: (mask) => getDocumentTypesValidationScheme(mask, required),
		target: $scheme,
	});

	const schemeUpdatesEvent = combineEvents({ events: [$scheme.updates, $fieldState.updates] });

	sample({
		clock: schemeUpdatesEvent,
		filter: ({ fieldState, scheme }) => Boolean(fieldState) && Boolean(scheme),
		source: { fieldState: $fieldState, scheme: $scheme },
		fn: ({ fieldState, scheme }) => ({
			field,
			state: {
				...fieldState,
				validate: getValidation(scheme as ValidationOptions),
			},
		}),
		target: form.setFieldState,
	});

	return { $scheme };
};
