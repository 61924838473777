import { API_GOOGLE_RECAPCHA_PUBLIC_KEY } from 'config/commonConstants'
import { RouterComponent } from 'layout/components/wrappers/router-component'
import { FC, PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

interface IMainWrapperProps {
    InHeader?: any
    InPortal?: any
    InCard?: any
    RenderHeaderIn?: any
    RenderPortalIn?: any
    RenderCardIn?: any
}

const MainWrapper: FC<PropsWithChildren<IMainWrapperProps>> = (props) => {

    return (
        <GoogleReCaptchaProvider reCaptchaKey={API_GOOGLE_RECAPCHA_PUBLIC_KEY || ''}>
            {props.InHeader && props.RenderHeaderIn ? createPortal(props.InHeader, props.RenderHeaderIn) : null}
            {props.InPortal && props.RenderPortalIn ? createPortal(props.InPortal, props.RenderPortalIn) : null}
            {props.InCard ? props.InCard : null}
            {props.children}
            <RouterComponent />
        </GoogleReCaptchaProvider>
    )
}

export default MainWrapper