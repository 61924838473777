import React, { PropsWithChildren, DOMAttributes, forwardRef, useRef } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { FocusableElement } from '@react-types/shared';
// import { useClientRect } from 'shared/lib/hooks';
// import { ControlsTheme } from 'shared/lib/types';
// import { Typo } from 'shared/ui/atoms/typo';
import style from './style.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { ControlsTheme } from 'config/commonConstants';
import { useClientRect } from 'utils/hooks/use-client-rect';

interface SelectWrapProps extends PropsWithChildren, DOMAttributes<FocusableElement> {
	size: 'sm' | 'md' | 'lg';
	className?: string;
	isFocused?: boolean;
	isDisabled?: boolean;
	isInvalid?: boolean;
	theme?: ControlsTheme;
	error?: JSX.Element;
}

export const SelectWrap = forwardRef<HTMLDivElement, SelectWrapProps>(
	(
		{
			className,
			isFocused,
			error,
			isDisabled,
			isInvalid,
			size,
			theme = 'secondary',
			children,
			...rest
		},
		ref,
	) => {
		const errorRef = useRef<Nullable<HTMLDivElement>>(null);
		const rect = useClientRect(errorRef);

		return (
			<div
				ref={ref}
				{...rest}
				className={classnames(
					className,
					style.selectWrap,
					error && style.selectWrap_error,
					style[size],
					style[theme],
					{
						[style.selectWrap__focused]: isFocused,
						[style.selectWrap__disabled]: isDisabled,
						[style.selectWrap__invalid]: isInvalid,
					},
				)}
				style={{
					marginBottom: rect && rect.height > 0 ? rect.height + 6 : 0,
				}}
			>
				{children}
				{error && (
					<AnimateHeight height={error ? 'auto' : 0} duration={0}>
						<Typo
							ref={errorRef}
							aria-select-errormessage="true"
							design="input-text-s"
							className={classnames(style.error, 'errormessage')}
						>
							{error}
						</Typo>
					</AnimateHeight>
				)}
			</div>
		);
	},
);
