import React from 'react';
// import Image from 'next/image';
// import { ShipModel } from 'shared/lib/types/ships/info';
// import { SlideImage } from 'shared/ui/atoms/slide-image';
// import { Slider } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { ShipModel } from 'config/types/ships';
import { Slider } from 'layout/components/special/slider';
import { Img } from 'layout/components/view/img';
import { SlideImage } from 'layout/components/view/slide-image';

export interface PicturesProps {
	pictures: ShipModel.ContentBlockPicture[];
	hasThumbnails?: boolean;
}

export const Pictures = ({ pictures, hasThumbnails = true }: PicturesProps) => (
	<div className={styles.picturesBlock}>
		{pictures.length === 1 && (
			<div className={styles.picture}>
				<Img
					// layout="fill" objectFit="cover"
					src={pictures[0].src} alt={pictures[0].alt ?? ''} />
			</div>
		)}
		{pictures.length > 1 && (
			<Slider
				hasControls
				hasThumbnails={hasThumbnails}
				className={styles.slider}
				items={pictures.map(({ src, alt = '' }) => ({ src, alt }))}
				Component={(props) => <SlideImage {...props} className={styles.fixAspect} />}
			/>
		)}
	</div>
);
