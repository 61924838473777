import { FC } from 'react';
import classnames from 'classnames';
import { useEvent } from 'effector-react';
// import { logoutModel } from 'features/logout';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { setAnalytics } from 'shared/lib/utils';
// import { Button } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { logoutModel } from 'layout/components/auth/logout';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';

interface AccountLogoutProps extends DefaultStyledProps {}

export const AccountLogout: FC<AccountLogoutProps> = ({ className }) => {
	const logOutWithModal = useEvent(logoutModel.showModal);

	const handleLogOutWithModal = () => {
		logOutWithModal();
		// setAnalytics(ANALYTICS_TARGETS.account.account_logout);
	};

	return (
		<Button
			size="large"
			btnType="text"
			onClick={handleLogOutWithModal}
			className={classnames(className, styles.logout)}
		>
			Выйти из профиля
			<Icon id="exit" width={16} height={16} />
		</Button>
	);
};
