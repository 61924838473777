import { status } from 'patronum';
// import { GetServerSidePropsContext } from 'next/types';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { News } from 'config/types/news';
import { newsRequest } from 'store/news';
import { accountNavigationModel } from 'layout/layouts/header/account-navigation';
import { headerModel } from 'layout/components/view/header';
import { footerModel } from 'layout/components/view/footer';
// import { accountNavigationModel } from 'features/account-navigation';
// import { headerModel } from 'features/header';
// import { footerModel } from 'entities/footer';
// import { newsRequest } from 'shared/api';
// import { News } from 'shared/lib/types';

// const getData = createEvent<GetServerSidePropsContext>();
// TODO: определить тип данных 
const getData = createEvent<any>();

const $operatorNotifications = createStore<Nullable<News>>(null);
const getOperatorNotifications = createEvent<number>();
const getOperatorNotificationsFx = createEffect(newsRequest.getOperatorNotifications);
const $status = status({ effect: getOperatorNotificationsFx });
const $lastLoadedPage = createStore<number>(1);

sample({
	clock: getData,
	fn: () => 1,
	target: [getOperatorNotifications, accountNavigationModel.getCounts],
});

sample({
	clock: getOperatorNotifications,
	target: getOperatorNotificationsFx,
});

sample({
	clock: getOperatorNotificationsFx.doneData,
	target: $operatorNotifications,
});

sample({
	clock: getOperatorNotificationsFx.fail,
	source: $operatorNotifications,
	fn: (source) => (source ? source.page : 1),
	target: $lastLoadedPage,
});

const loadMoreNotifications = createEvent<number>();
const loadMoreNotificationsFx = createEffect(newsRequest.getOperatorNotifications);

sample({
	clock: loadMoreNotifications,
	target: loadMoreNotificationsFx,
});

sample({
	clock: loadMoreNotificationsFx.doneData,
	source: $operatorNotifications,
	fn: (source, clock) => ({
		...clock,
		data: [...(source?.data ?? []), ...clock.data],
	}),
	target: $operatorNotifications,
});

/* *
 * Хэдэр / футер
 */
sample({
	clock: getData,
	target: [headerModel.getData, footerModel.getData],
});

export const model = {
	$operatorNotifications,
	$status,
	getData,
	getOperatorNotifications,
	loadMoreNotifications,
	$lastLoadedPage,
};
