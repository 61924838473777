import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { useGate, useUnit } from 'effector-react';
// import { guestDataModel } from 'widgets/order/order-customer/model';
import { userRoleModel } from 'entities/order';
// import { getPassportName, profileModel } from 'entities/profile';
// import { Condition, Skeleton, Typo } from 'shared/ui/atoms';
import { ConfirmationLabel } from '../confirmation-block-wrapper';
import styles from './styles.module.scss';
import { profileModel, getPassportName } from 'layout/components/auth/profile';
import { Skeleton } from 'layout/components/special/skeleton';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { guestDataModel } from '../../../order-customer/model';

export interface CustomerProps {
	className?: string;
}

export const CustomerInfo: FC<CustomerProps> = memo(({ className }) => {
	useGate(guestDataModel.GateCustomer);

	const [status, isGuest, customer] = useUnit([
		profileModel.$profileStatus,
		userRoleModel.$isGuest,
		guestDataModel.$prepearedCustomerData,
	]);
	const pending = !isGuest ? status === 'pending' : false;

	if (pending || !customer) {
		return null;
	}

	const { lastName, firstName, middleName } = getPassportName({
		lastName: customer.lastName,
		firstName: customer.firstName,
		middleName: customer.middleName,
	});

	return (
		<div className={classnames(styles.customerInfo, className)}>
			<ConfirmationLabel label="Покупатель" />
			<div className={styles.container}>
				<Typo className={styles.name} design="headline-m">
					<Condition
						value={pending}
						then={<Skeleton width={400} height={20} margin="2px 0" />}
						else={
							<>
								{lastName} {firstName} {middleName}
							</>
						}
					/>
				</Typo>

				<Typo className={styles.contacts} design="headline-s" freezeDesktop>
					<Condition
						value={pending}
						then={
							<>
								<Skeleton width={109} height={16} margin="2px 0" />
								<Skeleton width={97} height={16} margin="2px 0" />
							</>
						}
						else={
							<>
								{customer?.phone && <span className={styles.phone}>{customer.phone}</span>}
								{customer?.email && <span className={styles.email}>{customer.email}</span>}
							</>
						}
					/>
				</Typo>
			</div>
		</div>
	);
});
