import React, { FC, memo, MouseEventHandler, MouseEvent } from 'react';
import classnames from 'classnames';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { setAnalytics } from 'shared/lib/utils';
// import { Button } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { Button } from 'layout/components/buttons/button';

export interface OrderStepControlsProps {
	prevHref?: string;
	nextHref?: string;
	prevStep?: number;
	nextStepText?: string;
	nextStepDisabled?: boolean;
	nextStepOnClick?: MouseEventHandler;
	nextStepLoading?: boolean;
	className?: string;
}

export const OrderStepControls: FC<OrderStepControlsProps> = memo(
	({
		prevHref,
		nextHref,
		prevStep,
		nextStepText,
		nextStepDisabled,
		nextStepOnClick,
		nextStepLoading,
		className,
	}) => {
		const handleClick = (event: MouseEvent) => {
			nextStepOnClick?.(event);

			switch (nextHref) {
				case '/order/customer':
					// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_2nd_step);
					break;
				case '/order/confirmation':
					// setAnalytics(ANALYTICS_TARGETS.enquiry.enquiry_3rd_step);
					break;
				default:
					break;
			}
		};

		return (
			<div className={classnames(className, styles.stepControls)}>
				{prevStep && prevStep > 0 && (
					<Button
						className={styles.stepControls__backButton}
						size="large"
						btnType="outline"
						href={prevHref}
					>
						Вернуться на шаг {prevStep}
					</Button>
				)}
				<Button
					size="large"
					btnType="primary"
					href={nextStepOnClick ? undefined : nextHref}
					onClick={handleClick}
					disabled={nextStepDisabled && !nextStepOnClick}
					loading={nextStepLoading}
				>
					{nextStepText}
				</Button>
			</div>
		);
	},
);
