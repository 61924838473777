import React, { FC } from 'react';
import classnames from 'classnames';
// import { useRouter } from 'next/router';
import { useUnit } from 'effector-react';
import { userRoleModel } from 'entities/order';
// import { CUSTOMER_REQUIRED, orderSteps } from 'shared/config';
// import { Button, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { CUSTOMER_REQUIRED, orderSteps } from 'config/constants/order';
import { Button } from 'layout/components/buttons/button';
import { Typo } from 'layout/components/typo/ui';
import { useLocation, useRouter } from '@tanstack/react-router';

interface ProgressBarProps {}

export const ProgressBar: FC<ProgressBarProps> = () => {
	const location = useLocation();
	const asPath = location.pathname

	const [isGuest] = useUnit([userRoleModel.$isGuest]);

	const customerRequired = isGuest || CUSTOMER_REQUIRED;

	const filteredOrderSteps = orderSteps.filter(({ guestOnly }) => {
		const byUser = !guestOnly || (customerRequired && guestOnly);

		return byUser;
	});

	return (
		<div className={styles.progressBar}>
			{filteredOrderSteps.map(({ label, route }, index) => (
				<Typo
					key={label}
					className={classnames(styles.progressBar__crumb, {
						[styles.progressBar__crumb_current]: asPath.split('?')[0] === route,
					})}
					design="input-text-s"
				>
					<span>{index + 1}</span>
					<Button
						className={classnames(styles.progressBar__crumb, {
							[styles.progressBar__crumb_current]: asPath.split('?')[0] === route,
						})}
						btnType="textBlack"
						href={route}
					>
						{label}
					</Button>
				</Typo>
			))}
		</div>
	);
};
