// import { fetcher } from 'shared/api/config';
// import { shipMock } from 'shared/api/mock/ship';
// import { SHIPS_ENDPOINT } from 'shared/config';
// import { ShipModel } from 'shared/lib/types/ships';

import { fetcher } from "api/setupAxios";
import { SHIPS_ENDPOINT } from "config/constants/ships";
import { ShipModel } from "config/types/ships";
import { shipMock } from "mocks/ship";

export interface GetShipInfoRequest {
	id?: string;
	needMock?: boolean;
}

export const getInfo = async ({ id, needMock = false }: GetShipInfoRequest) => {
	if (needMock) {
		await new Promise((resolve) => {
			setTimeout(resolve, 300);
		});

		return shipMock;
	}

	return fetcher<ShipModel.ShipInfo>({
		url: `${SHIPS_ENDPOINT}/${id}/info`,
		method: 'GET',
	});
};
