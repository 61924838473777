import React, { FC, memo } from 'react';
import classnames from 'classnames';
// import Image from 'next/image';
import { useEvent, useUnit } from 'effector-react';
import { bonusCardModel } from 'entities/bonus-card';
// import { profileModel } from 'entities/profile';
// import {
// 	JOIN_TO_BONUS_PROGRAM_BANNER,
// 	JOIN_TO_BONUS_PROGRAM_BANNER_MOB,
// 	KLK_RULES_LINK,
// } from 'shared/config';
// import { Button, DesktopView, MobileView, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { profileModel } from 'layout/components/auth/profile';
import { JOIN_TO_BONUS_PROGRAM_BANNER, JOIN_TO_BONUS_PROGRAM_BANNER_MOB } from 'store/auth/constants';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { Button } from 'layout/components/buttons/button';
import { KLK_RULES_LINK } from 'config/constants/contacts';
import { DesktopView, MobileView } from 'layout/layouts/device-view';

interface JoinToBonusProgramProps {
	title: string;
	alignCenter?: boolean;
	largePaddings?: boolean;
	showWelcomeDetails?: boolean;
	showMoreLink?: boolean;
}

export const JoinToBonusProgram: FC<JoinToBonusProgramProps> = memo(
	({
		title,
		alignCenter = false,
		largePaddings = false,
		showWelcomeDetails = false,
		showMoreLink,
	}) => {
		const profile = useUnit(profileModel.$profile);
		const sendRegister = useEvent(bonusCardModel.registerBonusCard);
		const status = useUnit(bonusCardModel.$registerStatus);
		const bonusCardError = useUnit(bonusCardModel.$errorCode);

		const handleClick = () => {
			if (profile && bonusCardError === 404) {
				sendRegister();
			}
		};

		return (
			<section className={styles.joinToBonusProgram}>
				<div
					className={classnames(styles.joinToBonusProgram__banner, {
						[styles.centerAlign]: alignCenter,
						[styles.largePaddings]: largePaddings,
					})}
				>
					<DesktopView>
						<img
							className={styles.joinToBonusProgram__image}
							src={JOIN_TO_BONUS_PROGRAM_BANNER}
							// objectFit="cover"
							// layout="fill"
						/>
					</DesktopView>
					<MobileView>
						<img
							className={styles.joinToBonusProgram__image}
							src={JOIN_TO_BONUS_PROGRAM_BANNER_MOB}
							// objectFit="cover"
							// layout="fill"
						/>
					</MobileView>

					<Icon className={styles.joinToBonusProgram__bannerIcon} id="klk" width={51} height={40} />

					<div className={styles.joinToBonusProgram__bannerText}>
						<Typo design="headline-l" as="h2" raw={title} />
						{!showWelcomeDetails && (
							<Typo design="text-m">Присоединяйтесь к Клубу Любителей Круизов</Typo>
						)}
					</div>

					<Button
						size="large"
						btnType="outlineWhite"
						loading={status === 'pending'}
						href={!profile ? '/login?state=register' : undefined}
						onClick={handleClick}
						className={styles.joinToBonusProgram__bannerAction}
					>
						Присоединиться
					</Button>
				</div>

				{(!showWelcomeDetails || showMoreLink) && (
					<Button size="large" btnType="text" href={KLK_RULES_LINK}>
						Подробнее о Клубе Любителей Круизов
					</Button>
				)}
			</section>
		);
	},
);
