import { ReactNode, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
// import Link from 'next/link';
// import { Typo } from 'shared/ui/atoms';
// import { Icon, IconId } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { IconId } from 'layout/components/icons/svg-sprite';
import { Typo } from 'layout/components/typo/ui';
import { Icon } from 'layout/components/icons/icon';
import { Link } from '@tanstack/react-router';

interface AccountNavigationLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	href: string;
	iconId: IconId;
	children: ReactNode;
	isActive: boolean;
	isLock?: boolean;
}

export const AccountNavigationLink = ({
	href,
	children,
	isActive,
	isLock,
	iconId,
	...props
}: AccountNavigationLinkProps) => (
	<Link to={href}>
		<a
			{...props}
			className={classNames(
				styles.navigationLink,
				isActive && styles.active,
				isLock && styles.lock,
			)}
		>
			<Typo className={styles.title} as="span" design="input-text-m">
				<span className={styles.linkContent}>
					<span className={styles.icon}>
						<Icon id={iconId} width={20} height={20} />
					</span>
					{children}
				</span>
			</Typo>
			<Icon id="chevron-right" width={20} height={20} />
		</a>
	</Link>
);
