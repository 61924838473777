// import {
// 	EXTRAORDINARY_CRUISES_CATEGORY,
// 	EXTRAORDINARY_CRUISES_SUBCATEGORY,
// 	NEWS_CRUISES_SELECTION_CAT_ID,
// 	NEWS_CRUISES_SELECTION_SUBCATS,
// 	NEWS_POPULAR_ROUTES_CAT_ID,
// 	NEWS_POPULAR_ROUTES_SUBCATS,
// 	NEWS_RECOMMENDATIONS_CAT_ID,
// } from 'shared/config';
// import {
// 	HeaderMenuItem,
// 	HeaderMultiLevelItemType,
// 	LinkType,
// 	PageCard,
// 	PostCard,
// } from 'shared/lib/types';

import { LinkType } from "config/commonTypes";
import { EXTRAORDINARY_CRUISES_CATEGORY, EXTRAORDINARY_CRUISES_SUBCATEGORY } from "config/constants/cruises";
import { NEWS_CRUISES_SELECTION_CAT_ID, NEWS_CRUISES_SELECTION_SUBCATS, NEWS_POPULAR_ROUTES_CAT_ID, NEWS_POPULAR_ROUTES_SUBCATS, NEWS_RECOMMENDATIONS_CAT_ID } from "config/constants/news";
import { HeaderMenuItem, HeaderMultiLevelItemType } from "config/types/header";
import { PageCard, PostCard } from "config/types/posts";

export type MappedMenuData = Record<string, LinkType[]>;

export const getFilterBySubcategory =
	(subcategoryId: number) =>
	({ subcategory }: PostCard) =>
		subcategory?.id === subcategoryId;

export const getLink = ({ link, id, cardTitle = '' }: PostCard) => ({
	href: link || `/news/${id}`,
	label: cardTitle,
});

export const getPageLink = ({ link, id, pageTitle = '', slug, page, openInNewTab }: PageCard) => ({
	href: link || (page === 'news' ? `/news/${id}` : `/info/${slug}`),
	label: pageTitle,
	openInNewTab,
});

export const getSelections = (data: PostCard[]) =>
	data?.filter(({ category }) => category?.id === NEWS_CRUISES_SELECTION_CAT_ID) ?? [];

export const getPopRoutes = (data: PostCard[]) =>
	data?.filter(({ category }) => category?.id === NEWS_POPULAR_ROUTES_CAT_ID) ?? [];

export const getRecommendations = (data: PostCard[]) =>
	data?.filter(({ category }) => category?.id === NEWS_RECOMMENDATIONS_CAT_ID) ?? [];

export const getExtraordinary = (data: PostCard[]) =>
	data?.filter(
		({ category, subcategory }) =>
			category?.id === EXTRAORDINARY_CRUISES_CATEGORY &&
			subcategory?.id === EXTRAORDINARY_CRUISES_SUBCATEGORY,
	) ?? [];

export const getMappedData = (source: PostCard[], other: MappedMenuData = {}) => {
	const selectionsData = getSelections(source);
	const popularRoutesData = getPopRoutes(source);
	const recommendationsData = getRecommendations(source);
	const extraordinaryData = getExtraordinary(source);

	return {
		'rus:selections': selectionsData
			.filter(getFilterBySubcategory(NEWS_CRUISES_SELECTION_SUBCATS.rus))
			.map(getLink),
		'rivers:selections': selectionsData
			.filter(getFilterBySubcategory(NEWS_CRUISES_SELECTION_SUBCATS.rivers))
			.map(getLink),
		'sea:selections': selectionsData
			.filter(getFilterBySubcategory(NEWS_CRUISES_SELECTION_SUBCATS.sea))
			.map(getLink),
		'rus:popularRoutes': popularRoutesData
			.filter(getFilterBySubcategory(NEWS_POPULAR_ROUTES_SUBCATS.rus))
			.map(getLink),
		'rivers:popularRoutes': popularRoutesData
			.filter(getFilterBySubcategory(NEWS_POPULAR_ROUTES_SUBCATS.rivers))
			.map(getLink),
		'sea:popularRoutes': popularRoutesData
			.filter(getFilterBySubcategory(NEWS_POPULAR_ROUTES_SUBCATS.sea))
			.map(getLink),
		recommendations: recommendationsData.map(getLink),
		extraordinary: extraordinaryData.map(getLink),
		...other,
	};
};

export const mutateHeaderMenu = (menu: HeaderMenuItem[], data: MappedMenuData) => {
	const handleItem = (item: HeaderMultiLevelItemType) => {
		if (item.children) {
			item.children.map(handleItem);
		}

		if (item.id && data[item.id]) {
			item.children = data[item.id];
		}

		return item;
	};

	return menu.map(handleItem) as HeaderMenuItem[];
};
