import { createEvent, sample } from 'effector';
import { createGate } from 'effector-react';
import { cartModel } from 'entities/cart';
import { userRoleModel } from 'entities/order';
import { modalFactory } from 'layout/components/modal';
// import { modalFactory } from 'shared/model';

const Gate = createGate();

export interface ChangePlaceTypeParams {
	id: number;
	passengers: { type: number }[];
}

const changePlaceType = createEvent<ChangePlaceTypeParams>();

const widgetModal = modalFactory();

sample({
	clock: Gate.open,
	target: [cartModel.getCartPrices, userRoleModel.resetUserRoleDialog],
});

sample({
	clock: changePlaceType,
	target: cartModel.updatePassengerType,
});

export const model = {
	changePlaceType,
	Gate,
	widgetModal,
};
