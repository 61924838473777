import { createFileRoute } from '@tanstack/react-router'
import { Profile } from 'layout/components/profile'
import { ProfileVerification } from 'layout/components/profile/profile-verification'
import { AccountLayout } from 'layout/layouts/account'
import { Main } from 'layout/layouts/main'

export const Route = createFileRoute('/account/profile/')({
    component: () => <ProfileRoute />,
})


function ProfileRoute() {
    return (

        <AccountLayout sidebarContent={<ProfileVerification />}>
            <Profile />
        </AccountLayout>)
}