import React, { FC, memo, PropsWithChildren } from 'react';
import classnames from 'classnames';
// import { CurrencyType, DefaultStyledProps } from 'shared/lib/types';
// import { getCurrency } from 'shared/lib/utils';
// import { Typo, TypoDesignToken } from 'shared/ui/atoms/typo';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { CurrencyType } from 'config/types/cruise/models/cruise';
import { Icon } from 'layout/components/icons/icon';
import { TypoDesignToken, Typo } from 'layout/components/typo/ui';
import { getCurrency } from 'utils/currency';

export interface StrokedPriceProps extends PropsWithChildren, DefaultStyledProps {
	design?: TypoDesignToken;
	currency?: CurrencyType;
}

export const StrokedPrice: FC<StrokedPriceProps> = memo(
	({ className, children, design = 'description-features', currency }) => (
		<Typo as="s" design={design} className={classnames(className, styles.strokedPrice)}>
			{children}
			<Icon id={getCurrency('rur', currency)} className="currency" />
		</Typo>
	),
);
