import React from 'react';
import { useUnit } from 'effector-react';
import { CabinHeader } from 'entities/cabin';
import { CartItem, cartModel } from 'entities/cart';
// import { TOURISTS_GENITIVE_DECLENSION, SEATS_DECLENSION } from 'shared/lib/constants';
// import { getNumDeclension } from 'shared/lib/utils';
// import { Alert, Price, Typo } from 'shared/ui/atoms';
// import { CabinPlaces, PassengerPrice } from 'shared/ui/organisms';
import { model } from '../../model';
import { CartCabinSkeleton } from '../cart-cabin-skeleton';
import styles from './styles.module.scss';
import { SEATS_DECLENSION, TOURISTS_GENITIVE_DECLENSION } from 'config/constants';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { Price } from 'layout/components/view/price';
import { getNumDeclension } from 'utils/string';
import { CabinPlaces, PassengerPrice } from 'layout/components/cruise/cabin-places';

interface CartCabinProps extends CartItem {
	pending?: boolean;
	allowRemove?: boolean;
}

export const CartCabin = ({
	name,
	category,
	status,
	places,
	id,
	pending,
	allowRemove,
}: CartCabinProps) => {
	const [totalPrice, changePlaceType] = useUnit([cartModel.$totalPrice, model.changePlaceType]);
	const cabinPrice = totalPrice?.cabins?.find((cabin) => cabin.id === id);
	const totalPlaces = places.main.length + places.additional.length;

	const mainPlacesText = `${places.main.length} ${getNumDeclension(
		places.main.length,
		SEATS_DECLENSION,
	)}`;

	const passengers = cabinPrice?.passengers?.filter(
		({ type, total }) => type !== undefined && `${type}` !== '3' && total !== undefined,
	);

	const passengersText = passengers
		? `${passengers.length} ${getNumDeclension(passengers.length, TOURISTS_GENITIVE_DECLENSION)}`
		: '';

	const additionalPrice = cabinPrice?.passengers
		?.filter(({ type, total }) => `${type}` === '3' && total !== undefined)
		.reduce((acc, { total }) => acc + Number(total), 0);

	if (pending) {
		return <CartCabinSkeleton />;
	}

	return (
		<div className={styles.cabin}>
			<CabinHeader
				places={places}
				id={id}
				name={name}
				category={category}
				status={status}
				changePlaceType={changePlaceType}
				allowRemove={allowRemove}
			/>
			{cabinPrice && passengers !== undefined && (
				<>
					<CabinPlaces
						totalPlaces={totalPlaces}
						childrenAge={cabinPrice.childrenAge}
						childrenDiscount={cabinPrice.childrenDiscount}
						passengerPrices={passengers as PassengerPrice[]}
						changeType={({ index, value }) => {
							const updatedPlaces = cabinPrice.passengers
								?.filter(({ type }) => type !== undefined && `${type}` !== '3')
								.map(({ type }) => ({ type })) as { type: Nullable<number | string> }[];

							updatedPlaces[index] = { type: value === null ? value : Number(value) };

							changePlaceType({
								id,
								passengers: updatedPlaces
									.filter(({ type }) => type !== null)
									.map(({ type }) => ({
										type: Number(type),
									})),
							});
						}}
					/>
					{additionalPrice !== undefined && additionalPrice > 0 && (
						<Alert>
							<Typo design="text-s">
								В каюте {mainPlacesText}. Доплата за размещение {passengersText} в каюте –{' '}
								<Price
									total={additionalPrice}
									design="headline-s"
									className={styles.additionalPrice}
									bold
								/>
							</Typo>
						</Alert>
					)}
					<div className={styles.price}>
						<Typo design="headline-s" className={styles.price__text}>
							Итого
						</Typo>
						{cabinPrice.total && (
							<Price
								total={cabinPrice.total}
								design="headline-m"
								className={styles.additionalPrice}
								trunc={0}
								bold
							/>
						)}
					</div>
				</>
			)}
		</div>
	);
};
