import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import { SizeType } from 'layout/components/buttons/button/button';

export interface TabProps extends PropsWithChildren {
	id?: string | number;
	label?: React.ReactNode;
	href?: string;
	size?: SizeType;
	analyticsTarget?: string;
}

export const Tab: FC<TabProps> = ({ id, children }) => (
	<section
		role="tabpanel"
		id={`${id}-panel`}
		aria-labelledby={`${id}-tab`}
		className={styles.tab__panel}
	>
		{children}
	</section>
);
