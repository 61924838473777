import React, { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { ChipRecordProps } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { ChipRecordProps } from '../chip-record/ui';

export interface ChipRecordsProps
	extends PropsWithChildren,
		DefaultStyledProps,
		Pick<ChipRecordProps, 'theme' | 'align'> {}

export const ChipRecords: FC<ChipRecordsProps> = ({ className, theme, align, children }) => (
	<ul className={classnames(className, styles.chipRecords)}>
		{React.Children.map(children, (child) => {
			if (!React.isValidElement(child)) {
				return null;
			}

			return <li>{React.cloneElement(child, { ...child.props, theme, align })}</li>;
		})}
	</ul>
);
