// import { SendBookingSupportPersonal, SendBookingSupportRequest } from 'shared/api';
// import { message } from 'shared/lib/message';
// import { ValidationOptions } from 'shared/ui/organisms/form/lib/validation';

import { message } from "config/constants/message";
import { ValidationOptions } from "layout/components/inputs/form/lib/validation";
import { SendBookingSupportRequest, SendBookingSupportPersonal } from "store/cruise/booking";

export type SendBookingSupportRequestKey = keyof Omit<
	SendBookingSupportRequest & SendBookingSupportPersonal,
	'cruiseId' | 'captcha'
>;

export const bookingSupportValidationScheme: Record<
	SendBookingSupportRequestKey,
	ValidationOptions
> = {
	adultCount: {
		required: true,
		validateType: 'maxMinNumber100',
		messages: {
			validateType: message.form.validation.format.maxMinNumber100,
		},
	},
	childrenCount: {
		required: false,
		validateType: 'maxMinNumber100',
		messages: {
			validateType: message.form.validation.format.maxMinNumber100,
		},
	},
	cabinCategories: {
		required: false,
	},
	comment: {
		required: false,
		validateType: 'max5000',
		messages: {
			validateType: message.form.validation.format.max5000,
		},
	},
	firstName: {
		required: true,
		validateType: 'latinCyrillicDigits',
	},
	lastName: {
		required: true,
		validateType: 'latinCyrillicDigits',
	},
	phone: {
		required: true,
		validateType: 'phone',
	},
	email: {
		required: false,
		validateType: 'email',
	},
	officeId: {
		required: true,
	},
	agreeProcessData: {
		required: true,
		messages: {
			required: message.bookingSupport.agreeProcessData,
		},
	},
};
