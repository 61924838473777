import React, { memo } from 'react';
import { useForm } from 'effector-react-form';
// import { confirmationModel } from 'widgets/order/order-confirmation/model';
// import { InputMultiRow } from 'shared/ui/atoms';
// import { FormField } from 'shared/ui/organisms';
import { ConfirmationLabel } from '../confirmation-block-wrapper';
import styles from './styles.module.scss';
import { FormField } from 'layout/components/inputs/form/field';
import { InputMultiRow } from 'layout/components/inputs/input/input-multi-row';
import { confirmationModel } from '../../model';

export const Comment = memo(() => {
	const { form } = confirmationModel;
	const { controller } = useForm({ form });

	return (
		<div className={styles.comment}>
			<ConfirmationLabel label="Комментарий" />

			<FormField
				form={form}
				controller={controller}
				name="comment"
				placeholder="Комментарий"
				secondPlaceholder="Введите текст"
				size="lg"
				withLabel
			>
				<InputMultiRow id="comment-field" maxLength={5000} />
			</FormField>
		</div>
	);
});
