import { UrlObject } from 'url';
import { combineEvents, debounce } from 'patronum';
// import { NextRouter } from 'next/router';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { AnyContext, RootRoute, Router, RouterProps, RouterState, TrailingSlashOption } from '@tanstack/react-router';
import { FileRoutesByTo, FileRouteTypes, RootRouteChildren } from 'routeTree.gen';
// import { createBrowserRouter } from 'react-router-dom'

type Url = UrlObject | string;
interface TransitionOptions {
	shallow?: boolean;
	locale?: string | false;
	scroll?: boolean;
	unstable_skipClientCache?: boolean;
}

interface PushFunctionArgs {
	url: Url;
	as?: Url;
	options?: TransitionOptions;
}


// TODO: этот объект надо правильно составить, чтобы роутер возвращал везде такие же данные как в инфофлот проекте
// interface CustomRouter {
// 	push: (url: Url, as?: Url, options?: TransitionOptions) => void
// 	asPath: string
// 	path: PushFunctionArgs | String
// 	pathname: string
// 	queryString: string
// }

// const $router = createStore<Nullable<NextRouter>>(null);
// const setRouter = createEvent<NextRouter>();
// const $router = createStore<Nullable<RouterProps>>(null);
const $router = createStore<Nullable<RouterProps>>(null);

const setRouter = createEvent<RouterProps>();
// let d = createBrowserRouter([])



sample({
	clock: setRouter,
	target: $router,
});

const pushHistoryFx = createEffect(
	// ({ path, router }: { path: string | PushFunctionArgs; router: Nullable<NextRouter> }) => {
	// asA - параметр заставляющий использовать браузерный механзм редиректа
	({ path, router, asA }: { path: string | PushFunctionArgs; router: Nullable<RouterProps>, asA?: boolean }) => {
		if (!router?.router) {
			return;
		}

		const pathIsString = typeof path === 'string';

		const newPath = pathIsString ? path : path.url;
		const newAs = pathIsString ? path : path.as;
		const newOptions = pathIsString ? {} : path.options;

		// const {  } = router;
		// const {  } = router;
		const push = router.router.navigate

		// push(newPath, newAs, { ...newOptions });
		if (!!asA)
			window.location.href = newPath as string
		else
			push({ to: newPath as string })
	},
);

const pushQueryHistoryFx = createEffect(
	// ({ queryString, router }: { queryString: string; router: Nullable<NextRouter> }) => {
	// asA - параметр заставляющий использовать браузерный механзм редиректа
	({ queryString, router, asA }: { queryString: string; router: Nullable<RouterProps>, asA?: boolean }) => {
		if (!router?.router) {
			return;
		}

		const push = router?.router?.navigate ?? null
		const asPath = router?.router?.state?.location?.pathname ?? ''
		const pathname = asPath.split('?')[0];
		const url = `${pathname}${queryString ? `?${queryString}` : ''}`;

		// push(url, url, { shallow: true });
		if (!!asA)
			window.location.href = url as string
		else
			push({ to: url as string });
	},
);

const pushHistory = createEvent<string | PushFunctionArgs>();
const pushHistoryWithAsA = createEvent<string | PushFunctionArgs>();
const pushQueryHistory = createEvent<string>();

sample({
	clock: pushHistory,
	source: $router,
	fn: (router, path) => {
		return {
			path,
			router,
		}
	},
	target: pushHistoryFx,
});

sample({
	clock: pushHistoryWithAsA,
	source: $router,
	fn: (router, path) => {
		return {
			path,
			router,
			asA: true
		}
	},
	target: pushHistoryFx,
});

sample({
	clock: pushQueryHistory,
	source: $router,
	fn: (router, queryString) => {
		return {
			queryString,
			router,
		}
	},
	target: pushQueryHistoryFx,
});

const $history = createStore<string[]>([]);
const $prevPage = $history.map((history) => (history.length > 1 ? history.at(-2) : null));

sample({
	source: $history,
	clock: $router,
	fn: (history, router) => {
		const newHistory = [...history];
		//// eslint-disable-next-line unicorn/prefer-at
		const lastHistory = newHistory[newHistory.length - 1];

		// if (router && router.asPath.split('?')[0] !== lastHistory?.split('?')[0]) {
		// 	newHistory.push(router.asPath);
		// }
		if (router && router?.router?.state?.location?.pathname.split('?')[0] !== lastHistory?.split('?')[0]) {
			newHistory.push(router.router.state.location.pathname);
		}

		return newHistory.slice(-2);
	},
	target: $history,
});

const setAuthRedirectTarget = createEvent<string>();
const resetAuthRedirectTarget = createEvent<any>();
const debouncedResetAuthRedirectTarget = createEvent();

const $authRedirectTarget = createStore<Nullable<string>>(null).reset(resetAuthRedirectTarget);

sample({
	clock: setAuthRedirectTarget,
	target: $authRedirectTarget,
});

debounce({
	source: combineEvents({ events: [debouncedResetAuthRedirectTarget, $prevPage.updates] }),
	timeout: 3000,
	target: resetAuthRedirectTarget,
});

/* *
 * Сохранение authRedirectTarget в сессии
 */
const storeAuthRedirectTargetFx = createEffect((route: string) => {
	sessionStorage.setItem('authRedirectTarget', route);
});

const clearAuthRedirectTargetFx = createEffect(
	() => sessionStorage.removeItem('authRedirectTarget'),
	// eslint-disable-next-line
);

/**
 * События роутера
 */
const routeChangeComplete = createEvent<any>();

export const model = {
	$router,
	$prevPage,
	setRouter,
	push: pushHistory,
	pushAsA: pushHistoryWithAsA,
	pushQuery: pushQueryHistory,
	$authRedirectTarget,
	setAuthRedirectTarget,
	resetAuthRedirectTarget,
	debouncedResetAuthRedirectTarget,
	storeAuthRedirectTargetFx,
	clearAuthRedirectTargetFx,
	routeChangeComplete,
};
