// import { ChangeAddressKey } from 'features/personal/change-address/lib';

import { ChangeAddressKey } from "entities/personal/change-address/lib";

export const getAvailableCharactersParams = (name: ChangeAddressKey) => {
	let pattern;

	switch (name) {
		case 'zip':
			pattern = /^[\d\sA-Za-z\-]+$/;
			break;
		case 'region':
		case 'district':
		case 'city':
		case 'street':
			pattern = /^[\d\s,.A-Za-zА-я\-]+$/;
			break;
		case 'building':
		case 'buildingNumber':
		case 'office':
			pattern = /^[\d\s,./A-Za-zА-я\-]+$/;
			break;
		default:
			pattern = /./;
	}

	return {
		mask: 'a',
		masked: true,
		maskOptions: {
			tokens: {
				a: {
					pattern,
					multiple: true,
				},
			},
		},
	};
};
