import React from 'react';
import classnames from 'classnames';
// import { ShipStars } from 'shared/lib/types';
// import { Typo, TypoDesignToken } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { ShipStars } from 'config/types/search';
import { TypoDesignToken, Typo } from 'layout/components/typo/ui';

interface ShipStarProps {
	stars?: ShipStars;
	design?: TypoDesignToken;
	className?: string;
}

export const ShipStar = ({ stars, className, design = 'description-features' }: ShipStarProps) => {
	if (!stars || !stars.id) {
		return null;
	}
	if (stars.icon) {
		return (
			<Typo
				design={design}
				className={classnames(className, styles.star, styles.star_bg)}
				raw={stars.icon}
			/>
		);
	}
	if (stars.label) {
		return (
			<Typo
				design={design}
				className={classnames(className, styles.star, styles.star_bg, styles.star_label)}
			>
				{stars.label}
			</Typo>
		);
	}

	return null;
};
