import { useMemo } from 'react';
import { useUnit } from 'effector-react';
// import { OrderStepControls } from 'features/order/order-step-controls';
import { pricesModel, userRoleModel } from 'entities/order';
import { CUSTOMER_REQUIRED, OrderRoutes } from 'config/constants/order';
import { OrderStepControls } from 'layout/components/order/order-step-controls';
// import { CUSTOMER_REQUIRED, OrderRoutes } from 'shared/config';

export const OrderTouristControls = () => {
	const [isGuest, pricesStatus] = useUnit([
		userRoleModel.$isGuest,
		pricesModel.$getPricesPreviewStatus,
	]);
	const pending = pricesStatus === 'pending';

	const nextStep = useMemo(() => {
		// if (paidExcursionsCount) {
		// 	return {
		// 		nextStepText: 'Выбрать доп. услуги',
		// 		nextHref: OrderRoutes.AddServices,
		// 		nextStepDisabled: pending,
		// 	};
		// }

		const customerRequired = isGuest || CUSTOMER_REQUIRED;

		if (isGuest || customerRequired) {
			return {
				nextStepText: 'Продолжить',
				nextHref: OrderRoutes.Customer,
				nextStepDisabled: pending,
			};
		}

		return {
			nextStepText: 'Перейти к подтверждению',
			nextHref: OrderRoutes.Confirmation,
			nextStepDisabled: pending,
		};
	}, [isGuest, pending]);

	return <OrderStepControls {...nextStep} />;
};
