import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { model } from '../../model';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Form } from 'layout/components/inputs/form/form';
import { Countdown } from 'layout/components/special/countdown';
import { SMS_VERIFICATION_RESEND_TIMEOUT, SMS_VERIFICATION_RESEND_TIMEOUT_TEXT } from 'config/commonConstants';
import { Button } from 'layout/components/buttons/button/button';
import { requestFormModel } from '../../model/request-form';
import { verificationModel } from 'utils/verification';
import { useError, useForm } from 'effector-react-form';
import { useUnit } from 'effector-react';
import { encodePhone } from 'utils/string';
import { FormField } from 'layout/components/inputs/form/field';
import { InputPassword } from 'layout/components/inputs/input/input-password';

export const LoginConfirmation = () => {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const [login, loginType, pending, recoverByPhone, recoverByPhonePending, setToken] = useUnit([
		model.$login,
		model.$loginType,
		model.$checkPhonePending,
		requestFormModel.recoverByPhone,
		requestFormModel.$recoverByPhonePending,
		verificationModel.setCaptchaToken,
	]);
	const { handleSubmit, controller } = useForm({ form: model.checkPhoneForm });
	const { error, isShowError } = useError({
		form: model.checkPhoneForm,
		name: 'formError',
	});

	return (
		<div className={styles.wrapper}>
			<div className={styles.head}>
				<Typo className={styles.title} design="headline-xl">
					Восстановление пароля
				</Typo>
				<Typo className={styles.text} design="text-m">
					{loginType === 'email' &&
						'Вам было выслано письмо с инструкциями для смены пароля на адрес '}
					{loginType === 'phone' && 'Вам был отправлен код подтверждения на номер '}
					<Typo design="headline-s">
						{loginType === 'email' ? login : encodePhone(`+${login}`)}
					</Typo>
				</Typo>
			</div>

			{loginType === 'phone' && (
				<Form
					pending={pending}
					buttonContent="Продолжить"
					error={error}
					isShowError={isShowError}
					handleSubmit={handleSubmit}
					footer={
						<Countdown
							timeout={SMS_VERIFICATION_RESEND_TIMEOUT}
							text={SMS_VERIFICATION_RESEND_TIMEOUT_TEXT}
							design="input-text-m"
						>
							<Button
								size="large"
								btnType="text"
								loading={recoverByPhonePending}
								onClick={async () => {
									if (executeRecaptcha) {
										const token = await executeRecaptcha('form');
										setToken(token);
										recoverByPhone({ login: login ?? '' });
									}
								}}
							>
								Получить новый код
							</Button>
						</Countdown>
					}
				>
					<FormField
						controller={controller}
						form={model.checkPhoneForm}
						validation={{ required: true }}
						name="code"
					>
						<InputPassword size="lg" placeholder="Код из SMS" required withLabel />
					</FormField>
				</Form>
			)}
		</div>
	);
};
