import React, { FC } from 'react';
import { useUnit, useList } from 'effector-react';
// import {  } from 'effector-react/ssr';
// import { profileScreenModel } from 'widgets/account/model';
// import { profileModel } from 'entities/profile';
// import { RequestCardShort } from 'entities/request-card';
// import { AccountRoutes } from 'shared/config';
// import { Button, MobileView, Skeleton, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { profileModel } from 'layout/components/auth/profile';
import { profileScreenModel } from 'entities/account/model';
import { Button } from 'layout/components/buttons/button';
import { Skeleton } from 'layout/components/special/skeleton';
import { Typo } from 'layout/components/typo/ui';
import { MobileView } from 'layout/layouts/device-view';
import { AccountRoutes } from 'store/auth/constants';

interface ProfileRequestsProps {
	isLoading?: boolean;
}

export const ProfileRequests: FC<ProfileRequestsProps> = ({ isLoading }) => {
	const profile = useUnit(profileModel.$profile);

	const awaitingRequests = useList(profileScreenModel.$awaitingRequests, (request) => (
		<></>
		// TODO: пока отключил за ненадобностью
		// <RequestCardShort {...request} />
	));
	const awaitingRequestsCount = useUnit(profileScreenModel.$awaitingRequestsCount);

	if (isLoading) {
		return (
			<section className={styles.office}>
				<ProfileRequestsSkeleton />
			</section>
		);
	}

	if (!profile || awaitingRequestsCount === 0) {
		return null;
	}

	return (
		<section className={styles.requests}>
			<Typo className={styles.requests__head} as="header" design="text-m">
				<Typo as="h2" design="headline-m">
					Заявки, ожидающие оплаты
				</Typo>
				<p>
					В случае невозможности оплаты в указанный срок, обратитесь в офис за уточнением
					возможности продления.
				</p>
			</Typo>

			<div className={styles.requests__list}>{awaitingRequests}</div>

			<Button
				size="large"
				btnType="text"
				href={AccountRoutes.MyRequestsActive}
				className={styles.requests__footer}
			>
				Перейти к заявкам
			</Button>
		</section>
	);
};

export const ProfileRequestsSkeleton = () => (
	<div className={styles.requests}>
		<Typo className={styles.requests__head} design="text-m">
			<Skeleton className={styles.requests__headTitleSkeleton} />
			<div>
				<Skeleton display="inline-block" width="100%" />
				<MobileView>
					<Skeleton display="inline-block" width="100%" />
					<Skeleton display="inline-block" width="40%" />
				</MobileView>
			</div>
		</Typo>

		<div className={styles.requests__list}>
			<Skeleton className={styles.requests__listSkeleton} />
			<Skeleton className={styles.requests__listSkeleton} />
		</div>
	</div>
);
