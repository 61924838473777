import React, { CSSProperties, HTMLAttributes } from 'react';
import classnames from 'classnames';
import { IconId, PaymentStatusIconId, BonusIconId, ExcursionTypesId } from '../svg-sprite';
import styles from './styles.module.scss';

export interface IconProps extends Omit<HTMLAttributes<SVGElement>, 'id'> {
	id?: IconId | PaymentStatusIconId | BonusIconId | ExcursionTypesId;
	width?: CSSProperties['width'];
	height?: CSSProperties['height'];
	fill?: CSSProperties['fill'];
}

export const Icon = React.memo(({ id, style, width, height, fill, className }: IconProps) => {
	if (!id) {
		return null;
	}

	return (
		<svg
			aria-hidden="true"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMid meet"
			className={classnames(className, styles.svg)}
			style={{
				...style,
				width,
				height,
				fill,
			}}
		>
			<use xlinkHref={`#${id}`} />
		</svg>
	);
});
