import { Declensions } from "config/commonTypes";

export const getNumDeclension = (num: number, endings: Declensions) => {
	num %= 100;

	if (num >= 11 && num <= 19) {
		// eslint-disable-next-line prefer-destructuring
		return endings[2];
	}

	switch (num % 10) {
		case 1:
			// eslint-disable-next-line prefer-destructuring
			return endings[0];
		case 2:
		case 3:
		case 4:
			// eslint-disable-next-line prefer-destructuring
			return endings[1];
		default:
			// eslint-disable-next-line prefer-destructuring
			return endings[2];
	}
};
